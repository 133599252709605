import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Button,
  Message,
  Input,
  Label,
  ThemeAdmin,
  Loader,
  Tabs,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import TypeSettings from './typeSettings';
import { getIdFromProps, __, translate } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';


import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import SearchableSelect from '../../Components/SearchableSelect';
import { searchStocks } from '../../Utils/ApiSearchFunctions';
import { Link } from 'react-router-dom';

const Wrapper = styled.div``;

const InputWrapper = styled.div`
  margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(20)};
  width: ${rem(200)};
`;

const SearchInputWrapper = styled.div`
  margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(20)};
  width: ${rem(700)};
`;

const Row = styled.div`
  display: flex;
  height: ${rem(50)};
  justify-content: space-between;
  align-items: center;
  width: ${rem(400)};
`;

const Space = styled.div`
  width: ${rem(20)};
`;

const StyledLink = styled(Link)`
  color: black;
  font-size: ${rem(14)};
  margin-top: ${rem(12)};
`;

class ProductTypesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      loading: true,
      showManageGroups: false,
      selectedMainParam: -1,
      parameters: [],
      selectedVariantParam: -1,
      variants: [],
      activeLanguage: 'SK',
      typeName: '',
      typeTranslations: [],
      groupsToDelete: [],
      groups: [],
      codelists: [],
      units: [],
      stocks: [],
      activeTabId: 1,
      typeOfProduct: true,
      rowWasChanged: false,
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      await this.fetchProductTypeData(id);
    }

    const codelists = await AdminAPI.getCodelistsAction();
    const units = await AdminAPI.getCodelist('units');

    this.setState({
      loading: false,
      codelists: codelists.codelists || [],
      units: units.codelist || [],
    });
  }

  async fetchProductTypeData(id) {
    try {
      const params = await AdminAPI.getProductTypeParamsAction(id);
      const variants = await AdminAPI.getProductTypeVariantParamsAction(id);
      const groups = await AdminAPI.getProductTypeGroupsAction(id);
      const normalizedParams = this.normalizeParams(params, groups);
      const detail = await AdminAPI.getProductTypeAction(id);

      this.setState({
        groups,
        variants: variants || [],
        typeOfProduct: detail.type_of_product,
        skHeurekaId: detail.sk_heureka_id,
        czHeurekaId: detail.cz_heureka_id,
        parameters: normalizedParams,
        typeName: detail.name || detail._id,
        typeTranslations: detail.translations || [],
        stocks: detail.stocks
          ? detail.stocks.map(item => ({
              value: item._id,
              label: `${item._id} - ${item.name}`,
            }))
          : [],
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie typu produktu nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Typ produktu systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  normalizeParams(params, newGroups) {
    const groups = params.filter(p => p.group);
    const notGrouped = params.filter(p => !p.group);
    const paramsFromGroups = groups.map(group => {
      return group.params;
    });
    const flat = notGrouped.concat(paramsFromGroups.flat(1));
    const edited = flat.map(p => {
      return {
        ...p,
        group: newGroups.find(n => n.name === p.group)
          ? newGroups.find(n => n.name === p.group)._id
          : undefined,
      };
    });

    return edited;
  }

  async saveMainType() {
    const { selectedMainParam, parameters } = this.state;
    this.setState({
      saveLoading: true,
      success: false,
      error: false,
    });

    try {
      const paramId = parameters[selectedMainParam]._id;
      const typeId = getIdFromProps(this.props);
      if (!parameters[selectedMainParam].value) {
        parameters[selectedMainParam].value = '';
      }

      if (paramId) {
        const params = {};
        params.body = parameters[selectedMainParam];
        parameters[
          selectedMainParam
        ] = await AdminAPI.putProductTypeParamAction(typeId, paramId, params);
      } else {
        const params = {};
        params.body = parameters[selectedMainParam];
        parameters[
          selectedMainParam
        ] = await AdminAPI.postProductTypeParamAction(typeId, params);
      }

      this.setState({
        success: __('Parameter úspešne uložený'),
      });
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        rowWasChanged: false,
      });
    }
    this.setState({
      saveLoading: false,
    });

    window.scrollTo(0, 0);
  }

  async saveVariantType() {
    const { selectedVariantParam, variants } = this.state;
    this.setState({
      saveLoading: true,
      success: false,
      error: false,
    });

    try {
      const paramId = variants[selectedVariantParam]._id;
      const typeId = getIdFromProps(this.props);
      if (!variants[selectedVariantParam].value) {
        variants[selectedVariantParam].value = '';
      }

      if (
        variants[selectedVariantParam].unit &&
        variants[selectedVariantParam].unit.value
      ) {
        variants[selectedVariantParam].unit = [
          variants[selectedVariantParam].unit,
        ];
      } // oprava https://gitlab.bart.sk/oxyrion/admin.ui/issues/34

      if (paramId) {
        const params = {};
        params.body = variants[selectedVariantParam];
        variants[
          selectedVariantParam
        ] = await AdminAPI.putProductTypeVariantParamAction(
          typeId,
          paramId,
          params,
        );
      } else {
        const params = {};
        params.body = variants[selectedVariantParam];
        variants[
          selectedVariantParam
        ] = await AdminAPI.postProductTypeVariantsParamAction(typeId, params);
      }

      this.setState({
        success: __('Parameter úspešne uložený'),
      });
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        rowWasChanged: false,
      });
    }
    this.setState({
      saveLoading: false,
    });

    window.scrollTo(0, 0);
  }

  async saveGroups() {
    let { groups, groupsToDelete } = this.state;
    const groupsToPost = groups.filter(g => !g._id);
    const groupsToPut = groups.filter(g => g._id);
    const typeId = getIdFromProps(this.props);

    this.setState({
      groupsSaving: true,
    });

    try {
      await Promise.all(
        groupsToPost.map(g => {
          const params = {};
          params.body = { ...g, type_id: typeId };
          return AdminAPI.postProductTypeGroupAction(typeId, params);
        }),
      );

      await Promise.all(
        groupsToPut.map(g => {
          const params = {};
          params.body = {
            type_id: typeId,
            translations: g.translations,
            name: g.name,
          };
          return AdminAPI.putProductTypeGroupAction(typeId, g._id, params);
        }),
      );

      await Promise.all(
        groupsToDelete.map(id => {
          return AdminAPI.deleteProductTypeGroupAction(typeId, id);
        }),
      );

      groups = await AdminAPI.getProductTypeGroupsAction(typeId);

      this.setState({
        groups,
        groupsToDelete: [],
      });
    } catch (e) {
      this.setState({
        error: __('Niektoré zmeny skupín sa nepodarilo uložiť'),
      });
    } finally {
      this.setState({
        groupsSaving: false,
        showManageGroups: false,
      });
    }
  }

  handleMainChange(e, type) {
    let { typeName, czHeurekaId, skHeurekaId } = this.state;
    const { activeLanguage, typeTranslations } = this.state;

    if (type === 'name') {
      if (!typeTranslations.find(t => t.lang && t.lang === activeLanguage)) {
        typeTranslations.push({
          lang: activeLanguage,
          value: e.target.value,
        });
      } else {
        typeTranslations.find(t => t.lang === activeLanguage).value =
          e.target.value;
      }
      if (activeLanguage === 'SK') {
        typeName = e.target.value;
      }
    }

    if (type === 'heureka-id') {
      if (activeLanguage === 'SK') {
        skHeurekaId = e.target.value;
      }
      if (activeLanguage === 'CZ') {
        czHeurekaId = e.target.value;
      }
    }

    this.setState({
      typeName,
      typeTranslations,
      typeNameError: false,
      showMainSave: true,
      czHeurekaId,
      skHeurekaId,
    });
  }

  handleMainParamsChange(e, type) {
    const { parameters, selectedMainParam, activeLanguage } = this.state;

    if (parameters[selectedMainParam]) {
      if (type === 'name') {
        if (!parameters[selectedMainParam].translations) {
          parameters[selectedMainParam].translations = [];
        }
        if (
          !parameters[selectedMainParam].translations.find(
            t => t.lang === activeLanguage,
          )
        ) {
          parameters[selectedMainParam].translations.push({
            lang: activeLanguage,
            value: '',
          });
        }

        parameters[selectedMainParam].translations.find(
          t => t.lang === activeLanguage,
        ).value = e.target.value;
        if (activeLanguage === 'SK') {
          parameters[selectedMainParam].name = e.target.value;
        }
      } else if (type === 'unit') {
        parameters[selectedMainParam].unit = e.map(v => ({
          value: v.value,
          label: v.label,
        }));
      } else if (type === 'type') {
        parameters[selectedMainParam].type = e.target.value;
      } else if (type === 'group') {
        parameters[selectedMainParam].group = e.target.value;
      } else if (type === 'codelist') {
        parameters[selectedMainParam].codelist = e.target.value;
      } else if (type === 'system_name') {
        parameters[selectedMainParam].system_name = e.target.value;
      } else if (type === 'hide') {
        parameters[selectedMainParam].hide = e;
      } else if (type === 'export_to_farlesk') {
        parameters[selectedMainParam].export_to_farlesk = e;
      }
    }

    this.setState({
      parameters,
      rowWasChanged: true,
      error: false,
      success: false,
    });
  }

  handleVariantsParamsChange(e, type) {
    const { variants, selectedVariantParam, activeLanguage } = this.state;

    if (variants[selectedVariantParam]) {
      if (type === 'name') {
        if (!variants[selectedVariantParam].translations) {
          variants[selectedVariantParam].translations = [];
        }
        if (
          !variants[selectedVariantParam].translations.find(
            t => t.lang === activeLanguage,
          )
        ) {
          variants[selectedVariantParam].translations.push({
            lang: activeLanguage,
            value: '',
          });
        }
        variants[selectedVariantParam].translations.find(
          t => t.lang === activeLanguage,
        ).value = e.target.value;
        if (activeLanguage === 'SK') {
          variants[selectedVariantParam].name = e.target.value;
        }
      } else if (type === 'unit') {
        variants[selectedVariantParam].unit = e.map(v => ({
          value: v.value,
          label: v.label,
        }));
      } else if (type === 'type') {
        variants[selectedVariantParam].type = e.target.value;
      } else if (type === 'group') {
        variants[selectedVariantParam].group = e.target.value;
      } else if (type === 'codelist') {
        variants[selectedVariantParam].codelist = e.target.value;
      } else if (type === 'system_name') {
        variants[selectedVariantParam].system_name = e.target.value;
      } else if (type === 'hide') {
        variants[selectedVariantParam].hide = e;
      } else if (type === 'export_to_farlesk') {
        variants[selectedVariantParam].export_to_farlesk = e;
      }
    }

    this.setState({
      variants,
      rowWasChanged: true,
      error: false,
      success: false,
    });
  }

  handleGroupChange(e, lang, index) {
    const { groups } = this.state;

    if (!groups[index].translations) {
      groups[index].translations = [];
    }
    if (!groups[index].translations.find(t => t.lang && t.lang === lang)) {
      groups[index].translations.push({
        lang,
        value: e.target.value,
      });
    } else {
      groups[index].translations.find(t => t.lang && t.lang === lang).value =
        e.target.value;
    }
    if (lang === 'SK') {
      groups[index].name = e.target.value;
    }
    this.setState({
      groups,
    });
  }

  handleMainParamsTableChange(e, type, index) {
    const { parameters, selectedMainParam } = this.state;
    if (type === 'header') {
      parameters[selectedMainParam].headers[index].name = e.target.value;
    } else if (type === 'name') {
      parameters[selectedMainParam].value[index].name = e.target.value;
    } else if (type === 'unit') {
      parameters[selectedMainParam].value[index].unit = e.target.value;
    } else if (type === 'type') {
      parameters[selectedMainParam].value[index].type = e.target.value;
    }
    this.setState({
      parameters,
      rowWasChanged: true,
    });
  }

  handleVariantsParamsTableChange(e, type, index) {
    const { variants, selectedVariantParam } = this.state;
    if (type === 'header') {
      variants[selectedVariantParam].headers[index].name = e.target.value;
    } else if (type === 'name') {
      variants[selectedVariantParam].value[index].name = e.target.value;
    } else if (type === 'unit') {
      variants[selectedVariantParam].value[index].unit = e.target.value;
    } else if (type === 'type') {
      variants[selectedVariantParam].value[index].type = e.target.value;
    }
    this.setState({
      variants,
      rowWasChanged: true,
    });
  }

  changeLanguage(id) {
    this.setState({
      activeLanguage: id,
    });
    return true;
  }

  addNewGroup(group) {
    const { groups } = this.state;
    groups.push(group);
    this.setState({
      groups,
    });
  }

  deleteGroup(index) {
    const { groups, groupsToDelete } = this.state;

    groupsToDelete.push(groups[index]._id);

    groups.splice(index, 1);
    this.setState({
      groups,
      groupsToDelete,
    });
  }

  addMainParamsRow() {
    const { parameters, selectedMainParam } = this.state;
    if (!parameters[selectedMainParam].value) {
      parameters[selectedMainParam].value = [];
    }

    if (!Array.isArray(parameters[selectedMainParam].value)) {
      parameters[selectedMainParam].value = [
        parameters[selectedMainParam].value,
      ];
    }
    parameters[selectedMainParam].value.push({
      name: '',
    });

    this.setState({
      parameters,
      rowWasChanged: true,
    });
  }

  addVariantsParamsRow() {
    const { variants, selectedVariantParam } = this.state;
    if (!variants[selectedVariantParam].value) {
      variants[selectedVariantParam].value = [];
    }

    if (!Array.isArray(variants[selectedVariantParam].value)) {
      variants[selectedVariantParam].value = [
        variants[selectedVariantParam].value,
      ];
    }
    variants[selectedVariantParam].value.push({
      name: '',
    });

    this.setState({
      variants,
      rowWasChanged: true,
    });
  }

  deleteMainParamsRow(index) {
    const { parameters, selectedMainParam } = this.state;
    parameters[selectedMainParam].value.splice(index, 1);
    this.setState(parameters);
  }

  deleteVariantsParamsRow(index) {
    const { variants, selectedVariantParam } = this.state;
    variants[selectedVariantParam].value.splice(index, 1);
    this.setState(variants);
  }

  addMainParamsColl() {
    const { parameters, selectedMainParam } = this.state;
    if (!parameters[selectedMainParam].headers) {
      parameters[selectedMainParam].headers = [];
    }
    parameters[selectedMainParam].headers.push({
      name: '',
      type: 'number',
      unit: '',
    });
    this.setState({
      parameters,
      rowWasChanged: true,
    });
  }

  addVariantsParamsColl() {
    const { variants, selectedVariantParam } = this.state;
    if (!variants[selectedVariantParam].headers) {
      variants[selectedVariantParam].headers = [];
    }
    variants[selectedVariantParam].headers.push({
      name: '',
      type: 'number',
      unit: '',
    });
    this.setState({
      variants,
      rowWasChanged: true,
    });
  }

  deleteMainParamsColl(index) {
    const { parameters, selectedMainParam } = this.state;
    parameters[selectedMainParam].headers.splice(index, 1);

    this.setState(parameters);
  }

  deleteVariantsParamsColl(index) {
    const { variants, selectedVariantParam } = this.state;
    variants[selectedVariantParam].headers.splice(index, 1);

    this.setState(variants);
  }

  addNewMainParam() {
    const { parameters } = this.state;
    parameters.push({
      name: 'Nový parameter',
      translations: [],
      type: 'number',
    });

    this.setState({
      parameters,
      selectedMainParam: parameters.length - 1,
      rowWasChanged: true,
    });
  }

  addNewVariantsParam() {
    const { variants } = this.state;
    variants.push({
      name: 'Nový parameter',
      translations: [],
      type: 'number',
    });

    this.setState({
      variants,
      selectedVariantParam: variants.length - 1,
      rowWasChanged: true,
    });
  }

  undoMainParamsChanges() {
    const { parameters, paramBackup, selectedMainParam } = this.state;
    if (parameters[selectedMainParam]._id) {
      parameters[selectedMainParam] = paramBackup;
    } else {
      parameters.splice(parameters.length - 1, 1);
    }
    this.setState({
      parameters,
      paramBackup: {},
      selectedMainParam: -1,
      rowWasChanged: false,
      error: false,
    });
  }

  undoVariantsParamsChanges() {
    const { variants, paramBackup, selectedVariantParam } = this.state;

    if (variants[selectedVariantParam]._id) {
      variants[selectedVariantParam] = paramBackup;
    } else {
      variants.splice(variants.length - 1, 1);
    }
    this.setState({
      variants,
      paramBackup: {},
      selectedVariantParam: -1,
      rowWasChanged: false,
      error: false,
    });
  }

  changeRowMain(index) {
    const { rowWasChanged, parameters } = this.state;
    if (!rowWasChanged) {
      this.setState({
        selectedMainParam: index,
        paramBackup: Object.assign({}, parameters[index]),
      });
    } else {
      this.setState({
        error: __('Parameter je potrebné uložiť alebo zrušit vykonané zmeny'),
      });

      window.scrollTo(0, 0);
    }
  }

  changeRowVariants(index) {
    const { rowWasChanged, variants } = this.state;
    if (!rowWasChanged) {
      this.setState({
        selectedVariantParam: index,
        paramBackup: Object.assign({}, variants[index]),
      });
    } else {
      this.setState({
        error: __('Parameter je potrebné uložiť alebo zrušit vykonané zmeny'),
      });

      window.scrollTo(0, 0);
    }
  }

  async saveMain() {
    const {
      typeName,
      typeTranslations,
      typeOfProduct,
      stocks,
      skHeurekaId,
      czHeurekaId,
    } = this.state;
    if (typeName.length < 1) {
      this.setState({
        typeNameError: __('Názov nesmie byť prázdny'),
        showMainSave: false,
      });
    } else if (typeName === 'new') {
      this.setState({
        typeNameError: __('"new" je rezervované slovo'),
        showMainSave: false,
      });
    } else {
      const params = {};
      const id = getIdFromProps(this.props);
      try {
        if (id === 'new') {
          params.body = {
            system_name: typeName.toLocaleLowerCase().replace(' ', '_'),
            name: typeName,
            type_of_product: typeOfProduct,
            translations: typeTranslations,
            sk_heureka_id: skHeurekaId,
            cz_heureka_id: czHeurekaId,
            stocks: stocks.map(s => s.value),
            params: [],
          };
          const result = await AdminAPI.postProductTypeAction(params);
          this.props.history.push(
            `/${this.props.firm}/productTypes/${result._id}`,
          );
        } else {
          params.body = {
            name: typeName,
            type_of_product: typeOfProduct,
            system_name: id,
            sk_heureka_id: skHeurekaId,
            cz_heureka_id: czHeurekaId,
            stocks: stocks.map(s => s.value),
            translations: typeTranslations,
          };
          await AdminAPI.putProductTypeAction(id, params);
        }
        this.setState({
          showMainSave: false,
          success: __('Typ produktu sa bol uložený'),
          error: null,
        });
      } catch (e) {
        if (e.response.status === 409) {
          this.setState({
            success: null,
            error: __('Typ produktu s týmto názvom už existuje'),
          });
        } else {
          this.setState({
            success: null,
            error: __('Typ produktu sa nepodarilo uložiť'),
          });
        }
      }
    }
  }

  async deleteMainParam(forced = false) {
    const { parameters, selectedMainParam } = this.state;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteProductTypeParamAction(
        getIdFromProps(this.props),
        parameters[selectedMainParam]._id,
        { forced },
      );
      parameters.splice(selectedMainParam, 1);
      this.setState({
        parameters,
        selectedMainParam: -1,
        deleteLoading: false,
        showDeleteDialog: false,
        rowWasChanged: false,
        success: __('Parameter bol odstranený'),
      });
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({ showForceDeleteDialog: true });
      } else {
        this.setState({
          deleteError: __('Pri mazaní parametra nastala chyba'),
          showDeleteDialog: false,
        });
      }
    } finally {
      this.setState({
        deleteLoading: false,
      });
    }
  }

  async deleteVariantParam(forced = false) {
    const { variants, selectedVariantParam } = this.state;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteProductTypeVariantParamAction(
        getIdFromProps(this.props),
        variants[selectedVariantParam]._id,
        { forced },
      );
      variants.splice(selectedVariantParam, 1);
      this.setState({
        variants,
        selectedVariantParam: -1,
        deleteLoading: false,
        showDeleteDialog: false,
        rowWasChanged: false,
        success: __('Parameter bol odstranený'),
      });
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({ showForceDeleteDialog: true });
      } else {
        this.setState({
          deleteError: __('Pri mazaní parametra nastala chyba'),
          showDeleteDialog: false,
        });
      }
    } finally {
      this.setState({
        deleteLoading: false,
      });
    }
  }

  async deleteType() {
    this.setState({
      loading: true,
      showDeleteAllTypeDialog: false,
    });
    try {
      await AdminAPI.deleteProductTypeAction(getIdFromProps(this.props));
      this.props.history.push(`/${this.props.firm}/productTypes`);
    } catch (e) {
      this.setState({
        error: __('Pri mazaní nastala chyba'),
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleStocksChange(e) {
    this.setState({
      stocks: e,
      showMainSave: true,
    });
  }

  async changeParamType(to) {
    const { rowWasChanged } = this.state;

    if (to.id && to.id === 2) {
      if (rowWasChanged) {
        await this.saveMainType();
        if (!rowWasChanged) {
          return true;
        }
      }
      return true;
    } else if (to.id && to.id === 1) {
      if (rowWasChanged) {
        await this.saveVariantType();
        if (!rowWasChanged) {
          return true;
        }
      }
      return true;
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { typeTranslations } = this.state;
    return (
      <ControllBar
        history={history}
        name={translate('SK', typeTranslations, getIdFromProps(this.props))}
      >
        <ControlBarButton
          danger
          small
          onClick={() =>
            this.setState({
              showDeleteAllTypeDialog: true,
            })
          }
          icon="trash"
        >
          {__('Odstrániť typ')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      loading,
      typeName,
      typeNameError,
      typeTranslations,
      parameters,
      variants,
      selectedVariantParam,
      selectedMainParam,
      groups,
      activeLanguage,
      showManageGroups,
      rowWasChanged,
      showDeleteDialog,
      deleteLoading,
      deleteError,
      showMainSave,
      showForceDeleteDialog,
      showDeleteAllTypeDialog,
      groupsSaving,
      codelists,
      units,
      activeTabId,
      typeOfProduct,
      stocks,
      czHeurekaId,
      skHeurekaId,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showDeleteAllTypeDialog}
          small
          onClose={() =>
            this.setState({
              showDeleteAllTypeDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj si želáte odstrániť tento typ? Zmena môže narušiť existujúce produkty v katalógu',
            )}
            onDismiss={() =>
              this.setState({
                showDeleteAllTypeDialog: false,
                showDeleteDialog: false,
              })
            }
            onConfirm={() => this.deleteType()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={typeNameError}>
          <Message error message={typeNameError} />
        </AnimatedFormMessageWrapper>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <Wrapper>
          <div style={{ maxWidth: rem(400) }}>
            <LanguageSwitch
              onChange={id => {
                return this.changeLanguage(id);
              }}
              activeId={activeLanguage}
            />
          </div>
          <Label> {__('Názov typu*')}</Label>
          <Row>
            <InputWrapper>
              <Input
                placeHolder={__('Zadajte názov')}
                onChange={e => this.handleMainChange(e, 'name')}
                value={
                  typeTranslations.find(
                    t => t.lang && t.lang === activeLanguage,
                  )
                    ? typeTranslations.find(
                        t => t.lang && t.lang === activeLanguage,
                      ).value
                    : typeName
                }
                error={typeNameError}
              />
            </InputWrapper>
          </Row>

          <Label> {__('Heuréka category ID')}</Label>
          <Row>
            <InputWrapper>
              <Input
                placeholder={__('Zadajte heuréka kategory id')}
                onChange={e => this.handleMainChange(e, 'heureka-id')}
                value={activeLanguage === 'SK' ? skHeurekaId : czHeurekaId}
              />
            </InputWrapper>
          </Row>

          <Label> {__('Typ patrí pod')}</Label>
          <Row>
            <Label>
              <Space />
              <Checkbox
                checked={typeOfProduct}
                onChange={() =>
                  this.setState({
                    typeOfProduct: !typeOfProduct,
                    showMainSave: true,
                  })
                }
              />
              {__('Produkty')}
            </Label>
          </Row>

          <Label> {__('Sklady')}</Label>
          <Row style={{ width: rem(600), height: 'auto' }}>
            <SearchInputWrapper>
              <SearchableSelect
                value={stocks}
                loadOptions={query => searchStocks(query)}
                placeholder={__('Sklady')}
                isMulti
                handleOnChange={e => this.handleStocksChange(e)}
              />

              <StyledLink to={`/${this.props.firm}/stocks`}>
                {__('Zobraziť sklady')}
              </StyledLink>
            </SearchInputWrapper>
          </Row>

          <Row>
            {showMainSave && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: rem(32),
                  height: rem(50),
                }}
              >
                <Button small primary onClick={() => this.saveMain()}>
                  {getIdFromProps(this.props) === 'new'
                    ? __('Vytvoriť')
                    : __('Uložiť')}
                </Button>
              </div>
            )}
          </Row>

          <Tabs
            color={ThemeAdmin.color.primary}
            onChange={e => this.changeParamType(e)}
            activeTab={{
              id: activeTabId,
            }}
          >
            <Tabs.Tab id={1} title={__('Hlavné parametre')}>
              <TypeSettings
                parameters={parameters}
                selected={selectedMainParam}
                loading={loading}
                groups={groups}
                activeLanguage={activeLanguage}
                showManageGroups={showManageGroups}
                rowWasChanged={rowWasChanged}
                deleteLoading={deleteLoading}
                deleteError={deleteError}
                groupsSaving={groupsSaving}
                codelists={codelists}
                units={units}
                typeId={getIdFromProps(this.props)}
                changeRow={index => this.changeRowMain(index)}
                addNewGroup={group => this.addNewGroup(group)}
                saveGroups={() => this.saveGroups()}
                handleGroupChange={(e, lang, index) =>
                  this.handleGroupChange(e, lang, index)
                }
                deleteGroup={index => this.deleteGroup(index)}
                showForceDeleteDialog={showForceDeleteDialog}
                closeForcedDialog={() =>
                  this.setState({ showForceDeleteDialog: false })
                }
                deleteParam={forced => this.deleteMainParam(forced)}
                changeLanguage={lang => this.changeLanguage(lang)}
                addNewParam={() => this.addNewMainParam()}
                undoChanges={() => this.undoMainParamsChanges()}
                handleChange={(e, type) => this.handleMainParamsChange(e, type)}
                handleTableChange={(e, type, index) =>
                  this.handleMainParamsTableChange(e, type, index)
                }
                addRow={() => this.addMainParamsRow()}
                addColl={() => this.addMainParamsColl()}
                deleteColl={index => this.deleteMainParamsColl(index)}
                deleteRow={index => this.deleteMainParamsRow(index)}
                toggleDeleteDialog={to =>
                  this.setState({ showDeleteDialog: to })
                }
                showDeleteDialog={showDeleteDialog}
                firm={this.props.firm}
              />

              <ButtonRelativeWrapper>
                {parameters[selectedMainParam] && rowWasChanged && (
                  <Button
                    onClick={() => this.saveMainType()}
                    primary
                    loading={saveLoading}
                  >
                    {parameters &&
                    parameters[selectedMainParam] &&
                    parameters[selectedMainParam]._id
                      ? __('Uložiť')
                      : __('Vytvoriť')}
                  </Button>
                )}
              </ButtonRelativeWrapper>
            </Tabs.Tab>

            <Tabs.Tab id={2} title={__('Parametre variantov')}>
              <TypeSettings
                parameters={variants}
                selected={selectedVariantParam}
                loading={loading}
                groups={groups}
                activeLanguage={activeLanguage}
                showManageGroups={showManageGroups}
                rowWasChanged={rowWasChanged}
                deleteLoading={deleteLoading}
                deleteError={deleteError}
                groupsSaving={groupsSaving}
                codelists={codelists}
                units={units}
                typeId={getIdFromProps(this.props)}
                changeRow={index => this.changeRowVariants(index)}
                addNewGroup={group => this.addNewGroup(group)}
                saveGroups={() => this.saveGroups()}
                handleGroupChange={(e, lang, index) =>
                  this.handleGroupChange(e, lang, index)
                }
                deleteGroup={index => this.deleteGroup(index)}
                showForceDeleteDialog={showForceDeleteDialog}
                closeForcedDialog={() =>
                  this.setState({ showForceDeleteDialog: false })
                }
                deleteParam={forced => this.deleteVariantParam(forced)}
                changeLanguage={lang => this.changeLanguage(lang)}
                addNewParam={() => this.addNewVariantsParam()}
                undoChanges={() => this.undoVariantsParamsChanges()}
                handleChange={(e, type) =>
                  this.handleVariantsParamsChange(e, type)
                }
                handleTableChange={(e, type, index) =>
                  this.handleVariantsParamsTableChange(e, type, index)
                }
                addRow={() => this.addVariantsParamsRow()}
                addColl={() => this.addVariantsParamsColl()}
                deleteColl={index => this.deleteVariantsParamsColl(index)}
                deleteRow={index => this.deleteVariantsParamsRow(index)}
                toggleDeleteDialog={to =>
                  this.setState({ showDeleteDialog: to })
                }
                showDeleteDialog={showDeleteDialog}
                firm={this.props.firm}
                showGroups={false}
              />

              {variants[selectedVariantParam] && rowWasChanged && (
                <ButtonRelativeWrapper>
                  <Button
                    onClick={() => this.saveVariantType()}
                    primary
                    loading={saveLoading}
                  >
                    {variants &&
                    variants[selectedVariantParam] &&
                    variants[selectedVariantParam]._id
                      ? __('Uložiť')
                      : __('Vytvoriť')}
                  </Button>
                </ButtonRelativeWrapper>
              )}
            </Tabs.Tab>
          </Tabs>
        </Wrapper>
      </React.Fragment>
    );
  }
}

ProductTypesDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ProductTypesDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductTypesDetail);
