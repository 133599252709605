import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import { rem } from 'polished';
import { Button, Message, Input, Label, Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapperLeft,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';

import SettingsBox from '../../Components/SettingsBox';
import SearchableSelect from '../../Components/SearchableSelect';
import { searchShops } from '../../Utils/ApiSearchFunctions';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  max-width: ${rem(1000)};
  margin-bottom: ${rem(20)};
  flex-direction: column;
  ${({ theme }) => theme.media.l`
 flex-direction: row;
`};
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

class CashRegisterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      data: {},
      passwordLoading: false,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const id = getIdFromProps(this.props);
    await this.fetchCashRegistersData(id);
  }

  async fetchCashRegistersData(id) {
    try {
      const params = {
        firm: this.props.firm,
      };

      const data = await AdminAPI.getCashRegisterAction(id, params);

      this.setState({
        data,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async saveData(showSucces = true) {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    try {
      const params = {
        firm: this.props.firm,
      };

      params.body = {
        terminal: data.terminal,
        shop_id: data.shop && data.shop._id,
      };

      const result = await AdminAPI.putCashRegisterAction(data._id, params);

      this.setState({
        data: result,
        success: showSucces && __('Dáta uložené'),
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  async handleChange(field, value) {
    const { data } = this.state;

    if (field === 'shop') {
      if (value) {
        data[field] = {
          _id: value.value,
          name: value.label,
        };
      } else {
        data[field] = {};
      }
    } else if (field === 'terminal_ip_address') {
      if (!data.terminal) {
        data.terminal = {};
      }

      data.terminal.ip_address = value;
    } else if (field === 'terminal_ip_port') {
      if (!data.terminal) {
        data.terminal = {};
      }

      data.terminal.port = Number(value);
    } else {
      data[field] = value;
    }

    this.setState({ data, error: false });
  }

  async savePassword() {
    this.setState({ passwordLoading: true, success: false, error: false });
    const { data } = this.state;

    const params = {};
    params.body = {
      password: data.password,
    };

    try {
      await AdminAPI.putCashRegisterPasswordAction(data._id, params);
      this.setState({
        success: __('Heslo bolo zmenené'),
        passwordLoading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        error: __('Heslo sa nepodarilo zmeniť'),
        passwordLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Registračná pokladňa')} />;
  }

  render() {
    const {
      saveLoading,
      loading,
      notValidData,
      deleteError,
      error,
      data,
      success,
      passwordLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row style={{ height: rem(762) }}>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    disabled
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('ID')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data._id}
                    placeholder={__('ID')}
                    disabled
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('B2B ID')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.b2b_id}
                    placeholder={__('B2B ID')}
                    disabled
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Predvolený sklad')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.default_stock}
                    placeholder={__('Predvolený sklad')}
                    disabled
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Sklady')}</StyledLabel>
                  <SearchableSelect
                    value={
                      data.stocks &&
                      data.stocks.map(s => ({
                        value: s,
                        label: s,
                      }))
                    }
                    loadOptions={data.stocks.map(s => ({
                      value: s,
                      label: s,
                    }))}
                    isClearable={false}
                    isMulti
                    placeholder={__('Sklady')}
                    disabled
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
            <Space />
          </Row>

          <Row style={{ height: rem(762) }}>
            <SettingsBox addDisabled title={__('Detailné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('API kľúč')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.api_key}
                    placeholder={__('API kľúč')}
                    disabled
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Platobné metódy')}</StyledLabel>
                  <SearchableSelect
                    value={
                      data.payment_methods &&
                      data.payment_methods.map(s => ({
                        value: s,
                        label: s,
                      }))
                    }
                    loadOptions={data.payment_methods.map(s => ({
                      value: s,
                      label: s,
                    }))}
                    isClearable={false}
                    isMulti
                    placeholder={__('Platobné metódy')}
                    disabled
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('IP adresa')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.terminal_ip_adress}
                    placeholder={__('192.168.3.10')}
                    onChange={e =>
                      this.handleChange('terminal_ip_adress', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Výber predajne')}</StyledLabel>
                  <SearchableSelect
                    value={
                      data.shop && {
                        value: data.shop._id,
                        label: data.shop.name,
                      }
                    }
                    loadOptions={q => searchShops(q)}
                    isClearable={false}
                    isMulti={false}
                    placeholder={__('Výber predajne')}
                    handleOnChange={e => this.handleChange('shop', e)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Heslo')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.password}
                    placeholder={__('Heslo')}
                    onChange={e =>
                      this.handleChange('password', e.target.value)
                    }
                  />
                  <Button
                    primary
                    small
                    loading={passwordLoading}
                    onClick={() => this.savePassword()}
                    disabled={!data.password || data.password.length < 5}
                  >
                    {__('Zmeniť heslo')}
                  </Button>
                </InputWrapper>
              </Row>
            </SettingsBox>

            <Space />
          </Row>

          <Row style={{ height: rem(762) }}>
            <SettingsBox
              addDisabled
              title={__('Terminál')}
              style={{ width: rem(382) }}
            >
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('IP adresa')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(230) }}
                    value={data.terminal ? data.terminal.ip_address : ''}
                    placeholder={__('192.168.3.10')}
                    onChange={e =>
                      this.handleChange('terminal_ip_address', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Port')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(230) }}
                    value={data.terminal ? data.terminal.port : ''}
                    placeholder={__('8080')}
                    onChange={e =>
                      this.handleChange('terminal_ip_port', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>

            <Space />
          </Row>
        </ContentWrapper>

        <ButtonRelativeWrapperLeft>
          <Space />
          <Space />
          <Space />

          <Button
            loading={saveLoading}
            onClick={() => this.saveData(true)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapperLeft>
      </React.Fragment>
    );
  }
}

CashRegisterDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashRegisterDetail);
