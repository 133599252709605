import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ToningSystemDetail from '../../../Containers/ToningSystemDetail';
import ToningSystems from '../../../Containers/ToningSystems';

function ProductsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={ToningSystemDetail} />
      <Route path={`${match.url}`} component={ToningSystems} />
    </Switch>
  );
}

ProductsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsCard;
