import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { connect } from '../../Store';
import { LANGS } from '../../ContentConfig/languagesArray';
import { __, getIdFromProps } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import { Button, Input, Label, Loader, Message } from 'oxyrion-ui/lib';
import PDFTemplateEditor from '../../Components/Editor/pdfTemplateEditor';
import { Toggle, ToggleItem } from 'oxyrion-ui/lib/Toggle';
import SearchableSelect from '../../Components/SearchableSelect';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';

const initData = () => {
  return {
    title: '',
    html_data: '',
    json_data: null,
  };
};

class PDFTemplateDetail extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;

    this.state = {
      _id: null,
      newTag: '',
      loading: true,
      pdfTemplateGeneralData: {
        template_id: '',
        tags: [],
        name: '',
      },
      currentCopy: null,
      showInfo: true,
      selectedLang: null,
      newData: null,
      enableCopy: false,
      hasUnsaved: false,
      showCopyPopup: false,
      redirectId: '',
    };
  }

  async componentDidMount() {
    const o = {};
    LANGS.forEach(lang => {
      if (lang) {
        o[lang] = initData();
      }
    });

    this.setState(
      {
        translations: o,
        currentTranslationData: initData(),
      },
      async () => {
        const pdfTemplateId = getIdFromProps(this.props);

        if (pdfTemplateId !== 'new') {
          await this.fetchData(pdfTemplateId);
        } else {
          this.setState({ loading: false });
        }
      },
    );
  }

  async fetchData(pdfTemplateId) {
    try {
      const res = await AdminAPI.getPdfTemplateAction(pdfTemplateId);

      const pdfTemplateGeneralData = {
        template_id: res.template_id,
        tags: res.tags,
        name: res.name,
      };

      this.setState({
        _id: res._id,
        pdfTemplateGeneralData,
        translations: res.translations,
        currentTranslationData: null,
        oldPdfTemplateGeneralData: Object.assign({}, pdfTemplateGeneralData),
        oldTranslationData: Object.assign({}, res.translations),
        newData: null,
        loading: false,
        error: undefined,
        success: undefined,
      });
    } catch (e) {
      this.setState({
        error: __('Mailovú šablónu sa nepodarilo načítať'),
      });
      console.log(e);
    }
  }

  handleSearchables(e, name) {
    const { pdfTemplateGeneralData } = this.state;

    pdfTemplateGeneralData[name] = e;
    this.setState({
      pdfTemplateGeneralData,
    });
  }

  addNewTag() {
    const { newTag } = this.state;
    const { pdfTemplateGeneralData } = this.state;
    const trimmed = newTag.trim();

    if (!pdfTemplateGeneralData.tags) {
      pdfTemplateGeneralData.tags = [];
    }

    const a = pdfTemplateGeneralData.tags;

    if (
      trimmed &&
      !pdfTemplateGeneralData.tags.find(t => t.value === trimmed)
    ) {
      a.push({ value: trimmed, label: trimmed });
      this.handleSearchables(a, 'tags');
      this.setState({ newTag: '' });
    }
  }

  handleLangChange(lang, first) {
    const { translations, selectedLang, currentTranslationData } = this.state;

    if (selectedLang === lang) {
      return;
    }

    const newTranslations = translations;

    newTranslations[selectedLang] = currentTranslationData;

    this.setState({
      translations: newTranslations,
      selectedLang: lang,
      currentTranslationData: translations[lang],
      currentCopy: undefined,
      newData: newTranslations[lang].json_data,
      enableCopy: false,
      success: null,
      hasUnsaved: first,
    });
  }

  copyLangPdfTemplateData() {
    const { translations, currentTranslationData, currentCopy } = this.state;

    const lang = currentCopy;
    const newTranslations = translations;
    newTranslations[currentCopy] = Object.assign({}, currentTranslationData);

    this.setState(
      {
        translations: newTranslations,
        currentCopy: null,
      },
      () => {
        this.handleLangChange(lang, true);
      },
    );
  }

  handleLangPdfTemplateDataChange(value, field) {
    const { currentTranslationData } = this.state;

    currentTranslationData[field] = value;

    this.setState({
      currentTranslationData,
      hasUnsaved: true,
    });
  }

  handleGeneralPdfTemplateData(e, field) {
    const { pdfTemplateGeneralData } = this.state;
    let { newTag } = this.state;

    if (field === 'name') {
      pdfTemplateGeneralData.name = e.target.value;
    } else if (field === 'new_tag') {
      newTag = e.target.value;
      this.setState({ newTag });
    }

    this.setState({
      pdfTemplateGeneralData,
      hasUnsaved: true,
    });
  }

  async saveCurrentJson(json, html) {
    const { translations, selectedLang } = this.state;

    const newTranslations = translations;

    if (json && html) {
      newTranslations[selectedLang].json_data = json;
      newTranslations[selectedLang].html_data = html;
    }

    this.setState(
      {
        translations: newTranslations,
      },
      () => {
        this.savePdfTemplate();
      },
    );
  }

  async savePdfTemplate() {
    const { pdfTemplateGeneralData, translations, _id } = this.state;

    const params = {};

    params.body = Object.assign({}, pdfTemplateGeneralData, {
      translations,
    });

    try {
      let res = null;

      if (_id) {
        res = await AdminAPI.putPdfTemplateAction(_id, params);
        this.setState({
          pdfTemplateGeneralData: {
            template_id: res.template_id,
            tags: res.tags || [],
            name: res.name,
          },
          translations: res.translations,
          success: __('PDF šablóna úspešne uložená'),
          enableCopy: true,
          hasUnsaved: false,
        });
      } else {
        res = await AdminAPI.postPdfTemplateAction(params);
        this.props.history.push(`/${this.props.firm}/pdf-templates/${res._id}`);
        this.setState({
          _id: res._id,
          success: __('PDF šablóna úspešne uložená'),
          enableCopy: true,
          hasUnsaved: false,
        });
      }
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    }
  }

  async createCopy(json, html) {
    const { _id, showCopyPopup, hasUnsaved } = this.state;

    try {
      if (hasUnsaved) {
        await this.saveCurrentJson(json, html);
      }

      const result = await AdminAPI.postPdfTemplateCopyAction(_id);
      this.setState({
        redirectId: result._id,
        showCopyPopup: !showCopyPopup,
      });
    } catch (e) {
      console.log(e);
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { showInfo } = this.state;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('PDF šablóna')}>
          <ControlBarButton
            onClick={() =>
              this.setState({
                showInfo: !showInfo,
              })
            }
            primary
            small
            icon={showInfo ? 'arrow-top' : 'arrow-down'}
          >
            {showInfo ? __('Skryť detail') : __('Zobraziť detail')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      pdfTemplateGeneralData,
      nameError,
      error,
      loading,
      success,
      selectedLang,
      showInfo,
      currentTranslationData,
      currentCopy,
      enableCopy,
      hasUnsaved,
      newTag,
      newData,
      showCopyPopup,
      redirectId,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        {error && (
          <ErrorWrapper>
            <Message message={error} error />
          </ErrorWrapper>
        )}
        {success && (
          <ErrorWrapper>
            <Message message={success} />
          </ErrorWrapper>
        )}

        <PopUpWrapper
          display={showCopyPopup}
          small
          onClose={() =>
            this.setState({
              showCopyPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Bola vytvorená kópia šablóny, prajete si ju otvoriť?')}
            onDismiss={() =>
              this.setState({
                showCopyPopup: false,
              })
            }
            onConfirm={() => {
              this.props.history.push(`./${redirectId}`);
              this.setState({
                showCopyPopup: false,
                loading: true,
              });
              window.location.reload();
            }}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={showInfo}>
          <Wrapper>
            <SubWrapper>
              <StyledLabel> {__('Identifikátor')}</StyledLabel>
              <StyledInput
                placeholder={__('Identifikátor')}
                disabled
                onChange={e =>
                  this.handleGeneralPdfTemplateData(e, 'template_id')
                }
                value={pdfTemplateGeneralData.template_id}
              />
              <StyledLabel> {__('Názov PDF šablóny*')}</StyledLabel>
              <StyledInput
                placeholder={__('Zadajte názov')}
                onChange={e => this.handleGeneralPdfTemplateData(e, 'name')}
                value={pdfTemplateGeneralData.name}
                error={nameError}
              />

              <StyledLabel> {__('Tagy')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  value={pdfTemplateGeneralData.tags}
                  loadOptions={query => {
                    return this.searchTags(query);
                  }}
                  keyProps={
                    pdfTemplateGeneralData.tags &&
                    pdfTemplateGeneralData.tags.length
                  }
                  isMulti
                  placeholder={__('Zvoľte tagy')}
                  handleOnChange={e => this.handleSearchables(e, 'tags')}
                />
              </SearchableSelectWrapper>

              <TagWrapper>
                <StyledTagInput
                  placeholder={__('Zadajte nový tag')}
                  onChange={e =>
                    this.handleGeneralPdfTemplateData(e, 'new_tag')
                  }
                  value={newTag}
                />
                <Button primary small onClick={() => this.addNewTag()}>
                  {__('Pridať tag')}
                </Button>
              </TagWrapper>
            </SubWrapper>
          </Wrapper>
        </AnimatedFormMessageWrapper>

        <SubWrapperRight>
          <SubWrapperLang>
            {selectedLang && (
              <StyledToggle name="type">
                {LANGS.map(l => {
                  return (
                    <StyledToggleItem
                      checked={selectedLang === l}
                      value={l}
                      label={__(l)}
                      onClick={() =>
                        hasUnsaved
                          ? window.confirm(
                              'Máte neuložené zmeny, prajete si ich zahodiť?',
                            ) && window.location.reload()
                          : this.handleLangChange(l, false)
                      }
                    />
                  );
                })}
              </StyledToggle>
            )}
          </SubWrapperLang>
          {selectedLang && (
            <SubWrapperLangData>
              <SubWrapperFull>
                <StyledLabel> {__('Názov súboru')}</StyledLabel>
                <StyledInput
                  placeholder={__('Názov súboru')}
                  onChange={e =>
                    this.handleLangPdfTemplateDataChange(
                      e.target.value,
                      'title',
                    )
                  }
                  value={
                    (currentTranslationData && currentTranslationData.title) ||
                    ''
                  }
                  error={nameError}
                />

                {hasUnsaved && (
                  <CopyWrapper>
                    <div className="unsaved">{__('Neuložené zmeny')}</div>
                  </CopyWrapper>
                )}

                {enableCopy && (
                  <CopyWrapper>
                    <select
                      onChange={e => {
                        this.setState({ currentCopy: e.target.value });
                      }}
                    >
                      {[
                        <option selected value>
                          -
                        </option>,
                      ].concat(
                        LANGS.map(l => {
                          if (selectedLang !== l) {
                            return <option value={l}>{l}</option>;
                          }
                          return <div />;
                        }),
                      )}
                    </select>
                    <Button
                      primary
                      small
                      disabled={!LANGS.includes(currentCopy)}
                      onClick={() =>
                        enableCopy && this.copyLangPdfTemplateData()
                      }
                    >
                      {`${__('Kopírovať')}`}
                    </Button>
                  </CopyWrapper>
                )}
              </SubWrapperFull>
            </SubWrapperLangData>
          )}
        </SubWrapperRight>

        <PDFTemplateEditor
          jsonData={newData}
          setEditor={e => {
            this.editor = e;
          }}
          lang={selectedLang}
          onSave={(json, html) => this.saveCurrentJson(json, html)}
          onDesignLoad={() => {
            if (selectedLang === null) {
              this.handleLangChange('SK', false);
            }
          }}
          onCopy={(json, html) => this.createCopy(json, html)}
          hasUnsaved={updates =>
            this.setState({
              hasUnsaved: true,
              enableCopy: false,
              success: null,
            })
          }
        />
      </React.Fragment>
    );
  }
}

const StyledInput = styled(Input)`
  width: ${rem(400)};
`;

const StyledTagInput = styled(Input)`
  width: ${rem(200)};
`;

const ErrorWrapper = styled.div``;

const SearchableSelectWrapper = styled.div`
  width: ${rem(424)};
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(10)};
  justify-content: space-between;
  width: ${rem(424)};
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(42)};
  justify-content: flex-end;
  width: ${rem(424)};

  select {
    margin-right: 8px;
  }

  .unsaved {
    font-size: 14px;
    color: gray;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubWrapperRight = styled(SubWrapper)`
  margin-top: 8px;
  max-width: ${rem(420)};
  margin-bottom: ${rem(20)};

  ${({ theme }) => theme.media.l`
    margin-left: ${rem(16)};
    max-width: ${rem(900)};
  `}
`;

const SubWrapperLang = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SubWrapperLangData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #cccccc;
  padding: 0 16px 16px 16px;
  border-radius: 0 8px 8px 8px;

  ${({ theme }) => theme.media.l`
    flex-direction: row;
  `};
`;

const SubWrapperFull = styled(SubWrapper)`
  width: ${rem(424)};
  margin-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  padding-left: ${rem(8)};
  padding-right: ${rem(8)};
  flex-direction: column;
  margin-bottom: ${rem(12)};
  justify-content: flex-start;
  ${({ theme }) => theme.media.l`
    flex-direction: row;
    // justify-content: space-between
  `};
`;

const StyledLabel = styled(Label)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  min-height: 14px;
`;

const StyledToggle = styled(Toggle)`
  // height: ${rem(40)};
  label {
    padding: 8px 0;
    border: none;
    background-color: #eee;
    min-width: 40px;
  }

  input:checked:not(:disabled) + label {
    background-color: #cccccc;
    box-shadow: none;
    color: #000;
    border-radius: 8px 8px 0 0;
    font-weight: bold;
  }

  margin-top: ${rem(16)};
  ${({ theme }) => theme.media.l`
    margin-top: ${rem(0)};
  `};
`;
const StyledToggleItem = styled(ToggleItem)``;

PDFTemplateDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PDFTemplateDetail);
