import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Inspirations from '../../../Containers/Inspirations';
import InspirationDetail from '../../../Containers/InspirationDetail';

function InspirationsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={InspirationDetail} />
      <Route path={`${match.url}`} component={Inspirations} />
    </Switch>
  );
}

InspirationsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default InspirationsCard;
