import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/codelists';
import { __, formatDate, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';

class ProductTypes extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Typy produktov');
    this.showBulkOperations = false;
    this.accessors = ['name', 'created_date'];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/productTypes/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, selectedCustomer, lastSearch } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await AdminAPI.getProductTypesAction({
        onlyProductTypes: false,
      });

      const content = this.normalizeColumns(newData.product_types);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./productTypes/${item._id}`, `${item._id || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        created_date: _shapeData(
          item.created_date ? formatDate(moment(item.created_date)) : '',
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('Zoznam typov')}>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať typ')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductTypes);
