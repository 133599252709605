import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { PopUpList } from 'oxyrion-ui/lib';
import Icon from 'oxyrion-ui/lib/Icon';
import Theme from 'oxyrion-ui/lib/Themes/baseTheme';
import Select from 'oxyrion-ui/lib/Select';
import Message from 'oxyrion-ui/lib/Message';
import uniqid from 'uniqid';
import userMenuConfig from '../../ContentConfig/userMenu';
import { __, renderLink, getAvailableYears } from '../../Utils';
import {
  FlexBoxEnd,
  FlexBoxStart,
  FlexBox,
  AnimatedFormMessageWrapper,
} from '../ReusableComponents';
import CheckAccess from '../../ACL/CheckAccess';

const UserMenuWrapper = styled(FlexBoxEnd)`
  align-items: stretch;
  font-size: ${({ theme }) => theme.fontSize || '14px'}
  padding: 0 ${rem(20)} 0 0;
  position: ${({ display }) => (display ? '' : 'absolute')};
  top: ${({ display }) => (display ? '' : '-100vh')};
`;

const IconWrapper = styled.div`
  padding: 0 ${rem(8)};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
    & ul {
      display: block;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const UserPhoto = styled(FlexBoxStart)`
  border-radius: 50%;
  padding: 0 ${rem(8)};

  &:hover {
    img {
      box-shadow: 0 0 7px 5px ${({ theme }) => theme.separatorColor};
    }
    & ul {
      display: block;
    }
  }
`;

const FlagWrapper = styled(FlexBox)`
  justify-content: space-between;
  padding: 0 ${rem(5)};
  img {
    margin: ${({ margin }) => (margin ? rem(5) : 0)};
  }

  img:nth-of-type(3) {
    display: none;
  }

  &:hover {
    & ul {
      display: block;
    }
    img:nth-of-type(2) {
      display: none;
    }
    img:nth-of-type(3) {
      display: inline-block;
    }
  }
`;

const UserImage = styled.img`
  border-radius: 50%;
`;

const Item = styled.p`
  padding: 0 ${rem(10)};
  margin: ${rem(5)} 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSIze || '14px'}

  &: hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const Name = styled(Item)`
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  color: ${({ theme }) => theme.color.primary};
  padding: 0 ${rem(10)} ${rem(10)};
`;

const FlagItemWrapper = styled(FlexBox)`
  justify-content: space-between;
  padding: 0 ${rem(5)};
`;

const Li = styled.li`
  cursor: pointer;
  list-style: none;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
  a {
    font-size: ${({ theme }) => theme.fontSIze || '14px'};
    color: ${({ theme }) => theme.color.base};
    display: block;
    text-decoration: none;
    margin: ${rem(10)};
    &:visited {
      color: ${({ theme }) => theme.fontColor || theme.color.base};
    }
    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
    &:focus {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const CustomSelect = styled(Select)`
  min-width: ${rem(60)};
  padding: 0;
  select {
    padding: 0;
    padding-left: ${rem(2)};
    padding-right: ${rem(2)};
  }
`;

const HidingIconWrapper = styled(IconWrapper)`
  display: none;
  ${({ theme }) => theme.media.l`
  display: flex;
  `};
`;

const LogoutItem = styled.a`
padding: 0 ${rem(10)};
margin: ${rem(5)} 0;
cursor: pointer;
font-size: ${({ theme }) => theme.fontSIze || '14px'}
text-decoration: none;
color: black
&: hover {
  color: ${({ theme }) => theme.color.primary};
}
`;

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDisplay: false,
    };
  }

  render() {
    const {
      display,
      firm,
      top,
      userName,
      match,
      onPlusClick,
      pinPage,
      defaultPage,
      history,
      historyError,
      year,
    } = this.props;
    const years = getAvailableYears();
    return (
      <UserMenuWrapper display={display}>
        <HidingIconWrapper onClick={() => this.props.onRealoadClick()}>
          <img
            src="/images/icons/history.svg"
            height="24px"
            alt="history icon"
          />
          <PopUpList bordered top={top} right={rem(185)}>
            <AnimatedFormMessageWrapper display={historyError}>
              <Message error message={historyError} />
            </AnimatedFormMessageWrapper>
            {history &&
              history.map(item =>
                // <RouteLinkWraper to={item.url}>{item.title}</RouteLinkWraper>
                renderLink(item.url, item.title, false, rem(14)),
              )}
            <br />
            {renderLink(
              `/${firm}/history`,
              __('Celá história'),
              false,
              rem(14),
            )}
          </PopUpList>
        </HidingIconWrapper>
        <HidingIconWrapper onClick={() => pinPage()}>
          <Icon
            name="pin"
            color={defaultPage ? Theme.color.primary : 'black'}
          />
        </HidingIconWrapper>
        <FlagWrapper>
          <img src="/images/icons/add.svg" height="30px" alt="add icon" />
          <PopUpList bordered top={top}>
            <CheckAccess
              operation="postUserCalendarEventAction"
              Component={
                <Item onClick={() => onPlusClick('activity')}>
                  {__('Pridať aktivitu')}
                </Item>
              }
            />
            {/* <CheckAccess
              operation="postUserGraphTaskAction"
              Component={
                <Item onClick={() => onPlusClick('task')}>
                  {__('Pridať task')}
                </Item>
              }
            /> */}
            <CheckAccess
              operation="postCustomerContactAction"
              Component={
                <Item onClick={() => onPlusClick('contact')}>
                  {__('Pridať kontakt')}
                </Item>
              }
            />
            <CheckAccess
              operation="postLeadsAction"
              Component={
                <Item onClick={() => onPlusClick('lead')}>
                  {__('Pridať zákazníka')}
                </Item>
              }
            />
          </PopUpList>
        </FlagWrapper>
        <FlagWrapper>
          <CustomSelect
            name={__('Účtovný rok')}
            size="s"
            value={year}
            onChange={e => {
              this.props.onYearClick(Number(e.target.value));
            }}
          >
            {years.map(y => {
              return (
                <option key={y} value={y} selected={y === year}>
                  {y}
                </option>
              );
            })}
          </CustomSelect>
        </FlagWrapper>
        <FlagWrapper margin>
          <img src={userMenuConfig.flags[firm]} height="22px" alt="flag" />
          <img
            src="/images/icons/arrow-down.svg"
            height="4px"
            alt="control arrow"
          />
          <img
            src="/images/icons/arrow-up.svg"
            height="4px"
            alt="control arrow"
          />
          <PopUpList bordered top={top} right={rem(85)}>
            {userMenuConfig.firm.map(name => (
              <FlagItemWrapper key={uniqid()}>
                <img
                  src={userMenuConfig.flags[name]}
                  width="24px"
                  alt={`${name} flag`}
                />
                <Item
                  onClick={e => this.props.onCountryClick(e.target.innerHTML)}
                >
                  {name}
                </Item>
              </FlagItemWrapper>
            ))}
          </PopUpList>
        </FlagWrapper>
        <UserPhoto>
          <UserImage src="/images/icons/avatar.svg" height="34px" />
          <PopUpList
            bordered
            display={this.state.userDisplay}
            top={this.props.top}
            right={rem(20)}
          >
            <Name>{userName}</Name>
            {userMenuConfig.userItems.map(item => (
              <Li key={uniqid()}>
                <Link to={`${match.url}/${item.href}`}>{item.name}</Link>
              </Li>
            ))}
            <LogoutItem href={process.env.REACT_APP_LANDING_PAGE}>
              {__('App space')}
            </LogoutItem>
          </PopUpList>
        </UserPhoto>
      </UserMenuWrapper>
    );
  }
}

UserMenu.propTypes = {
  display: PropTypes.bool,
  top: PropTypes.string,
  firm: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onRealoadClick: PropTypes.func.isRequired,
  onPlusClick: PropTypes.func.isRequired,
  onCountryClick: PropTypes.func.isRequired,
  onLogOutClick: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired, //eslint-disable-line
  pinPage: PropTypes.func.isRequired,
  defaultPage: PropTypes.bool,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.url,
    }),
  ),
  historyError: PropTypes.string,
  year: PropTypes.number.isRequired,
  onYearClick: PropTypes.func.isRequired,
};

UserMenu.defaultProps = {
  display: true,
  top: '',
  defaultPage: false,
  historyError: '',
  history: [],
};

export default UserMenu;
