import React, { Component } from 'react';

import styled from 'styled-components';
import { Message, Input, Label, Textarea, Checkbox } from 'oxyrion-ui/lib';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  __,
  translate,
  updateTranslation,
  fetchCodelistByQuery,
} from '../../Utils';
import DateAndTimePicker from '../DateAndTimePicker';
import LanguageSwitch from '../LanguageSwitch';
import ImageLoader from '../ImageLoader';
import { AnimatedFormMessageWrapper } from '../ReusableComponents';
import Images from './Images';
import SearchableSelect from '../SearchableSelect';
import AttachmentsComponent from '../../Components/AttachmentsComponent';
import { LANGS } from '../../ContentConfig/languagesArray';

const Wrapper = styled.div`
  padding: ${rem(20)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: ${rem(1600)};
  ${({ theme }) => theme.media.m`
  flex-direction: row;
  `};
`;

const Cell = styled.div`
  padding: ${rem(5)};
`;

const Space = styled.div`
  width: ${rem(20)};
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(10)};
`;

const ColorSelect = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  background: ${(props) => props.color && props.color};
  margin-right: ${rem(5)};
  border: ${(props) => (props.selected ? rem(3) : rem(1))} solid black;
  cursor: pointer;
`;

const ColorsWrapper = styled.div`
  display: flex;
`;

const InputWrapper = styled.div``;

const PublicationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const colors = [
  '#da0404',
  'black',
  'white',
  '#70af00',
  '#d8d836',
  '#e2843b',
  '#3b63e2',
  '#804f0c',
];

class ArticleEdit extends Component {
  state = { error: false };
  updatePublished(lang, published = []) {
    const { article } = this.props;
    this.setState({
      error: false,
    });

    if (lang === 'all') {
      if (published.length > 0) {
        return [];
      }
      return LANGS;
    }

    if (published.indexOf(lang) > -1) {
      published.splice(published.indexOf(lang), 1);
      return published;
    }
    if (
      (article.name.translations.find((t) => t.lang === lang) &&
        article.name.translations.find((t) => t.lang === lang).value &&
        article.name.translations.find((t) => t.lang === lang).value.length >
          0) ||
      (article.name.value && article.name.value.length > 0)
    ) {
      published.push(lang);
      return published;
    }
    this.setState({
      error: __('Názov článku je povinný pre publikovanie článku'),
    });
    return published;
  }
  render() {
    const {
      article,
      onChange,
      uploadTitleImage,
      tittleImageLoading,
      selectedLang,
      onFileDownload,
      onFileDelete,
      getUploadParams,
      handleChangeStatus,
      deleteImage,
      onUsersFetch,
      addImages,
      selectLangImage,
    } = this.props;
    const { error } = this.state;

    return (
      <MainWrapper>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <Wrapper>
          <Cell>
            <Label>{__('Publikovaný: ')}</Label>
            <PublicationWrapper>
              {LANGS.map((l) => (
                <Label key={l}>
                  <Space />
                  <Space />
                  <Checkbox
                    checked={
                      article.published && article.published.indexOf(l) > -1
                    }
                    onChange={() =>
                      onChange(
                        Object.assign(article, {
                          published: this.updatePublished(l, article.published),
                        }),
                      )
                    }
                  />
                  {l}
                </Label>
              ))}
            </PublicationWrapper>

            <Label>{__('Publikovaný od: ')}</Label>
            <InputWrapper>
              <DateAndTimePicker
                value={article.published_from}
                onChange={(val) =>
                  onChange(Object.assign(article, { published_from: val }))
                }
                timePlaceHolder="Čas"
                datePlaceHolder="Dátum"
              />
            </InputWrapper>
            <Label>{__('Publikovaný do: ')}</Label>
            <DateAndTimePicker
              value={article.published_to}
              onChange={(val) =>
                onChange(Object.assign(article, { published_to: val }))
              }
              timePlaceHolder="Čas"
              datePlaceHolder="Dátum"
            />
            <Label>{__('Priorita:')}</Label>
            <Input
              style={{ width: '95%' }}
              value={article.priority}
              onChange={(val) =>
                onChange(
                  Object.assign(article, {
                    priority: Number(val.target.value),
                  }),
                )
              }
            />

            <Label>{__('Kategórie: ')}</Label>
            {article.type === 'news-dejmark' ? (
              <SearchableSelect
                value={article.categories}
                isMulti
                loadOptions={(query) => {
                  return fetchCodelistByQuery(query, 'news_categories_dejmark');
                }}
                isClearable={false}
                placeholder={__('Kategórie')}
                handleOnChange={(e) =>
                  onChange(Object.assign(article, { categories: e }))
                }
              />
            ) : (
              <SearchableSelect
                value={article.categories}
                isMulti
                loadOptions={(query) => {
                  return fetchCodelistByQuery(query, 'articles_categories');
                }}
                isClearable={false}
                placeholder={__('Kategórie')}
                handleOnChange={(e) =>
                  onChange(Object.assign(article, { categories: e }))
                }
              />
            )}

            <Label>{__('Autor: ')}</Label>
            <SearchableSelect
              value={
                article.owner && {
                  label: article.owner.display_name,
                  value: article.owner._id,
                }
              }
              loadOptions={(query) => {
                return onUsersFetch(query);
              }}
              isClearable={false}
              placeholder={__('Autor')}
              name={article.owner && article.owner._id}
              handleOnChange={(e) =>
                onChange(
                  Object.assign(article, {
                    owner: {
                      _id: e.value,
                      display_name: e.label,
                    },
                  }),
                )
              }
            />
          </Cell>
          <Cell>
            <LanguageSwitch
              onChange={(lang) => {
                this.props.changeLang(lang);
                return true;
              }}
              activeId={selectedLang}
            />

            <Label style={{ marginTop: rem(35) }}>{`${__(
              'Názov',
            )} - ${selectedLang}:`}</Label>
            <Input
              style={{ width: '95%' }}
              value={translate(
                selectedLang,
                article.name.translations,
                article.name.value,
              )}
              onChange={(val) =>
                onChange(
                  Object.assign(article, {
                    name: updateTranslation(
                      article.name,
                      selectedLang,
                      val.target.value,
                    ),
                  }),
                )
              }
            />

            <Label>{`${__('Perex')} - ${selectedLang}:`}</Label>
            <Textarea
              style={{ width: rem(400), height: rem(103) }}
              value={translate(
                selectedLang,
                article.perex.translations,
                article.perex.value,
              )}
              onChange={(val) =>
                onChange(
                  Object.assign(article, {
                    perex: updateTranslation(
                      article.perex,
                      selectedLang,
                      val.target.value,
                    ),
                  }),
                )
              }
            />

            <Label>{`${__('Meta title')} - ${selectedLang}:`}</Label>
            <Input
              style={{ width: '95%' }}
              value={translate(
                selectedLang,
                article.meta_title && article.meta_title.translations,
                article.meta_title && article.meta_title.value,
              )}
              onChange={(val) =>
                onChange(
                  Object.assign(article, {
                    meta_title: updateTranslation(
                      article.meta_title,
                      selectedLang,
                      val.target.value,
                    ),
                  }),
                )
              }
            />

            <Label>{`${__('Meta popis')} - ${selectedLang}:`}</Label>
            <Input
              style={{ width: '95%' }}
              value={translate(
                selectedLang,
                article.meta_description &&
                  article.meta_description.translations,
                article.meta_description && article.meta_description.value,
              )}
              onChange={(val) =>
                onChange(
                  Object.assign(article, {
                    meta_description: updateTranslation(
                      article.meta_description,
                      selectedLang,
                      val.target.value,
                    ),
                  }),
                )
              }
            />

            <Label>{`${__('Url')} - ${selectedLang}:`}</Label>
            <Input
              style={{ width: '95%' }}
              value={translate(
                selectedLang,
                article.url && article.url.translations,
                article.url && article.url.value,
              )}
              onChange={(val) =>
                onChange(
                  Object.assign(article, {
                    url: updateTranslation(
                      article.url,
                      selectedLang,
                      val.target.value,
                    ),
                  }),
                )
              }
            />

            {article._id && (
              <AttachmentsComponent
                attachments={
                  article.attachments &&
                  article.attachments.filter((a) => a.lang === selectedLang)
                }
                selectedLang={selectedLang}
                history={this.props.history}
                onFileDelete={(fullPath) => onFileDelete(fullPath)}
                onFileDownload={(item) => onFileDownload(item)}
                getUploadParams={(e, view) => getUploadParams(e, view)}
                onError={(_error) => this.setState({ error: _error })}
                handleChangeStatus={(e) => handleChangeStatus(e)}
              />
            )}
          </Cell>
          <Cell>
            {article._id && (
              <React.Fragment>
                <Label>{__('Titulný obrázok (5:3):')}</Label>
                <ImageLoader
                  path={article.thumbnail && article.thumbnail.fullPath}
                  video={article.video_label}
                  onChange={(image) => uploadTitleImage(image)}
                  loading={tittleImageLoading}
                />
                <Label>
                  <Checkbox
                    checked={article.video_label}
                    onChange={() =>
                      onChange(
                        Object.assign(article, {
                          video_label: !article.video_label,
                        }),
                      )
                    }
                  />
                  {__('Obsahuje video')}
                </Label>
              </React.Fragment>
            )}
            <Label>{__('Farba textu názvu:')}</Label>
            <ColorsWrapper>
              {colors.map((c) => {
                return (
                  <ColorSelect
                    key={c}
                    color={c}
                    selected={c === article.color}
                    onClick={() =>
                      onChange(
                        Object.assign(article, {
                          color: c,
                        }),
                      )
                    }
                  />
                );
              })}
            </ColorsWrapper>
          </Cell>
        </Wrapper>
        {article._id && (
          <React.Fragment>
            <Label>{__('Obrázky k článku')}</Label>
            <Images
              images={article.images}
              addImages={(images) => addImages(images, selectedLang)}
              deleteImage={(path) => deleteImage(path)}
              selectLangImage={(lang, path) => selectLangImage(lang, path)}
            />
          </React.Fragment>
        )}
      </MainWrapper>
    );
  }
}

ArticleEdit.propTypes = {
  onFileDelete: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  onUsersFetch: PropTypes.func.isRequired,
  getUploadParams: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  changeLang: PropTypes.func.isRequired,
  deleteImage: PropTypes.func,
  selectLangImage: PropTypes.func.isRequired,
  addImages: PropTypes.func,

  history: PropTypes.shape().isRequired,
  article: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.shape({
      value: PropTypes.string,
      translations: PropTypes.arrayOf({
        lang: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
    owner: PropTypes.shape({
      _id: PropTypes.string,
      display_name: PropTypes.string,
    }),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    perex: PropTypes.shape({
      value: PropTypes.string,
      translations: PropTypes.arrayOf({
        lang: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
    text: PropTypes.shape({
      value: PropTypes.string,
      translations: PropTypes.arrayOf({
        lang: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
    published: PropTypes.arrayOf(PropTypes.string),
    priority: PropTypes.number,
    published_from: PropTypes.string,
    published_to: PropTypes.string,
    thumbnail: PropTypes.shape({
      fullPath: PropTypes.string,
    }),
    images: PropTypes.arrayOf({
      fullPath: PropTypes.string,
    }),
    video_label: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  uploadTitleImage: PropTypes.func.isRequired,
  tittleImageLoading: PropTypes.bool,
  selectedLang: PropTypes.bool,
};

ArticleEdit.defaultProps = {
  tittleImageLoading: false,
  selectedLang: false,
  deleteImage: () => {},
  addImages: () => {},
};

export default ArticleEdit;
