import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, ThemeAdmin, Icon } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __, translateFieldType } from '../../Utils';
import TableV3 from '../../Components/TableV3';
import ParamDetail from '../../Components/ParamDetail';
import GroupManager from '../../Components/GroupManager';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';

import { P } from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';

const Wrapper = styled.div``;

const SectionHeader = styled.div`
  height: ${rem(50)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: #eee;
  margin-top: ${rem(20)};
  font-weight: 300;
  font-size: ${rem(16)};
`;

const StyledP = styled(P)`
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

class TypeSettings extends React.Component {
  state = {
    showManageGroups: false,
  };

  createColumns() {
    const { groups } = this.props;
    return [
      {
        accessor: 'name',
        Header: __('Názov'),
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'group',
        Header: __('Skupina'),
        Cell: props => {
          return (
            <StyledP>
              {groups.find(g => g._id === props.value)
                ? groups.find(g => g._id === props.value).name
                : '-'}
            </StyledP>
          );
        },
      },
      {
        accessor: 'type',
        Header: __('Typ'),
        Cell: props => {
          return <StyledP>{translateFieldType(props.value || '-')}</StyledP>;
        },
      },
      {
        accessor: 'system_name',
        Header: __('Systemový názov'),
        Cell: props => {
          return <StyledP>{props.value || '-'}</StyledP>;
        },
      },
      {
        accessor: 'hide',
        Header: __('Zobrazený v tabuľke variantov'),
        Cell: props => {
          return <StyledP>{props.value ? __('Nie') : __('Áno')}</StyledP>;
        },
      },
      {
        accessor: 'unit',
        Header: __('Jednotka'),
        Cell: props => {
          let value = '';
          if (Array.isArray(props.value)) {
            value = props.value.map((v, index) => {
              return `${v.label}${
                props.value.length !== index + 1 ? ', ' : ''
              }`;
            });
          } else {
            value = props.value || '-';
          }
          return <StyledP>{value.value ? value.value : value}</StyledP>;
        },
      },
    ];
  }

  render() {
    const {
      parameters,
      selected,
      groups,
      activeLanguage,
      rowWasChanged,
      deleteLoading,
      deleteError,
      groupsSaving,
      codelists,
      units,
      typeId,
      addNewGroup,
      saveGroups,
      handleGroupChange,
      deleteGroup,
      deleteParam,
      changeLanguage,
      addNewParam,
      undoChanges,
      handleChange,
      handleTableChange,
      addRow,
      addColl,
      deleteColl,
      deleteRow,
      loading,
      showForceDeleteDialog,
      closeForcedDialog,
      changeRow,
      showDeleteDialog,
      toggleDeleteDialog,
      showGroups,
    } = this.props;

    const { showManageGroups } = this.state;

    return (
      <React.Fragment>
        {showGroups && (
          <PopUpWrapper
            display={showManageGroups}
            message={__('Skupiny')}
            onClose={async () =>
              this.setState({
                showManageGroups: false,
                groups: await AdminAPI.getProductTypeGroupsAction(typeId),
              })
            }
          >
            <GroupManager
              items={groups}
              addNew={g => addNewGroup(g)}
              saveGroups={() => saveGroups()}
              groupsSaving={groupsSaving}
              handleChange={(e, lang, index) =>
                handleGroupChange(e, lang, index)
              }
              deleteItem={index => deleteGroup(index)}
            />
          </PopUpWrapper>
        )}
        <PopUpWrapper
          display={showDeleteDialog}
          small
          onClose={() => toggleDeleteDialog(false)}
        >
          <ConfirmDialog
            message={__('Prajete si vymazať parameter ?')}
            onDismiss={() => toggleDeleteDialog(false)}
            onConfirm={() => deleteParam(false)}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showForceDeleteDialog}
          small
          onClose={() => closeForcedDialog()}
        >
          <ConfirmDialog
            message={__(
              'Parameter sa použiva v produktoch. Naozaj si želáte trvalo odstániť tento parameter?',
            )}
            onDismiss={() => closeForcedDialog()}
            onConfirm={() => {
              closeForcedDialog();
              deleteParam(true);
            }}
          />
        </PopUpWrapper>

        <Wrapper>
          {typeId !== 'new' && (
            <React.Fragment>
              <SectionHeader style={{ marginBottom: rem(10) }}>
                {__(`Parametre`)}
                {!rowWasChanged && (
                  <Button small primary onClick={() => addNewParam()}>
                    {__('Pridať')}
                  </Button>
                )}
              </SectionHeader>
              <TableV3
                columns={this.createColumns()}
                minWidth={10}
                loading={loading}
                data={parameters}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: () => {
                        changeRow(rowInfo.index);
                      },
                      style: {
                        background:
                          rowInfo.index === selected
                            ? ThemeAdmin.color.primary
                            : rowInfo.index % 2 === 0
                            ? 'white'
                            : '#eee',
                        color: rowInfo.index === selected ? 'white' : 'black',
                        cursor: 'pointer',
                      },
                    };
                  }
                  return {};
                }}
                getTdProps={() => {
                  return { style: { padding: 0, margin: 'auto' } };
                }}
                getTheadThProps={(state, rowInfo, column) => {
                  if (column && column.sortable) {
                    return {
                      onClick: () => {},
                    };
                  }
                  return {};
                }}
                className="-highlight -striped"
              />
              <SectionHeader>
                {__(`Detail Parametra`)}

                {rowWasChanged && (
                  <Button small danger onClick={() => undoChanges()}>
                    {__('Zrušiť zmeny')}
                  </Button>
                )}
              </SectionHeader>
              {selected > -1 && (
                <React.Fragment>
                  {parameters[selected]._id && (
                    <div style={{ float: 'right', margin: rem(10) }}>
                      <Button
                        small
                        danger
                        onClick={() => toggleDeleteDialog(true)}
                      >
                        <Icon name="trash" color="white" />{' '}
                      </Button>
                    </div>
                  )}
                  <ParamDetail
                    units={units}
                    item={parameters[selected]}
                    groups={groups}
                    showGroups={showGroups}
                    codelists={codelists}
                    activeLanguage={activeLanguage}
                    onChange={(value, type) => handleChange(value, type)}
                    onTableChange={(value, type, index) =>
                      handleTableChange(value, type, index)
                    }
                    changeLanguage={id => changeLanguage(id)}
                    addRow={() => addRow()}
                    addColl={() => addColl()}
                    deleteColl={index => deleteColl(index)}
                    deleteRow={index => deleteRow(index)}
                    manageGroups={() =>
                      this.setState({
                        showManageGroups: true,
                      })
                    }
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Wrapper>
      </React.Fragment>
    );
  }
}

TypeSettings.propTypes = {
  parameters: PropTypes.arrayOf({}),
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  groups: PropTypes.arrayOf({}),
  activeLanguage: PropTypes.string,
  showManageGroups: PropTypes.bool,
  showGroups: PropTypes.bool,
  rowWasChanged: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  deleteError: PropTypes.bool,
  groupsSaving: PropTypes.bool,
  codelists: PropTypes.arrayOf({}),
  units: PropTypes.arrayOf({}),
  typeId: PropTypes.string,
  addNewGroup: PropTypes.func,
  saveGroups: PropTypes.func,
  handleGroupChange: PropTypes.func,
  deleteGroup: PropTypes.func,
  deleteParam: PropTypes.func,
  changeLanguage: PropTypes.func,
  addNewParam: PropTypes.func,
  undoChanges: PropTypes.func,
  handleChange: PropTypes.func,
  handleTableChange: PropTypes.func,
  addRow: PropTypes.func,
  addColl: PropTypes.func,
  deleteColl: PropTypes.func,
  deleteRow: PropTypes.func,
  changeRow: PropTypes.func,
  toggleDeleteDialog: PropTypes.func,
  firm: PropTypes.string,
};

TypeSettings.defaultProps = {
  parameters: [],
  selected: -1,
  groups: [],
  activeLanguage: 'SK',
  showManageGroups: false,
  rowWasChanged: false,
  deleteLoading: false,
  deleteError: false,
  groupsSaving: false,
  showGroups: true,
  loading: false,
  codelists: [],
  units: [],
  typeId: 'new',
  addNewGroup: () => {},
  saveGroups: () => {},
  handleGroupChange: () => {},
  deleteGroup: () => {},
  deleteParam: () => {},
  changeLanguage: () => {},
  addNewParam: () => {},
  undoChanges: () => {},
  handleChange: () => {},
  handleTableChange: () => {},
  addRow: () => {},
  addColl: () => {},
  deleteColl: () => {},
  deleteRow: () => {},
  changeRow: () => {},
  toggleDeleteDialog: () => {},
  firm: 'SK',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TypeSettings);
