import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmployerDetail from '../../../Containers/EmployerDetail';
import EmployersList from '../../../Containers/EmployersList';

function Employers(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={EmployerDetail} />
      <Route path={`${match.url}`} component={EmployersList} />
    </Switch>
  );
}

Employers.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default Employers;
