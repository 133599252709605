import React from 'react';
import styled from 'styled-components';
import { Input, Icon } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { FlexBoxStart } from '../ReusableComponents';

const CustomFlexBox = styled(FlexBoxStart)`
  margin-right: auto;
  align-items: center;
`;

const CustomInput = styled(Input)`
  display: ${({ display }) => (display ? 'block' : 'none')}
  position: absolute;
  left: 0;
  right: 0;
  height: 95%;
  padding: 0;
  margin: 0;
  padding: ${rem(1)}
  text-align: center

  input {
    height: 90%;
    margin: ${rem(1)} auto;
    width: 98%;
    padding: 0;
  }
  z-index: 3;
  ${({ theme }) => theme.media.m`
    display: block;
    position: relative;
    margin: 0 ${rem(20)} 0 ${rem(10)};
    width: 100%;
    z-index: 0;
    input {
      height: ${rem(20)};
      padding: 0 ${rem(10)};
    }
  `};
`;

const IconWrapper = styled.div`
  margin-left: ${rem(20)};
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  display: ${({ display }) => (display ? 'block' : 'none')};
  position: absolute;
  right: 0;
  z-index: 3;
  margin-right: ${rem(15)};
  cursor: pointer;
`;

class ControlbarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneMode: false,
      text: '',
    };
  }

  componentDidMount() {
    this.setState({
      text: this.props.defaultValue,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.defaultValue.length && nextProps.defaultValue.length > 0) {
      this.setState({
        text: nextProps.defaultValue,
      });
    }
  }

  handleOnChange(value) {
    const { onChange } = this.props;
    this.setState(
      {
        text: value,
      },
      () => {
        window.clearTimeout(this.currentFetchTimeout);
        this.currentFetchTimeout = window.setTimeout(() => {
          onChange(value);
        }, 300);
      },
    );
  }

  render() {
    const { phoneMode, text } = this.state;
    return (
      <CustomFlexBox>
        <IconWrapper onClick={() => this.setState({ phoneMode: true })}>
          <Icon name="search" color="white" />
        </IconWrapper>
        <CloseIconWrapper
          display={phoneMode}
          onClick={() => this.setState({ phoneMode: false })}
        >
          <Icon name="close" color="black" />
        </CloseIconWrapper>
        <CustomInput
          display={phoneMode}
          value={text}
          onChange={e => this.handleOnChange(e.target.value)}
        />
      </CustomFlexBox>
    );
  }
}

ControlbarSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

ControlbarSearch.defaultValue = {
  defaultValue: '',
};

export default ControlbarSearch;
