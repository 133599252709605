import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

const Mail = styled.a`
  text-decoration: none;
  margin: 0;
  padding: ${rem(5)} 0;
  display: block;
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  white-space: nowrap;
  :visited {
    color: ${({ theme }) => theme.color.primary};
  }
  :hover {
    text-decoration: underline;
  }
`;

class MailLink extends React.PureComponent {
  render() {
    const { mail } = this.props;
    return <Mail href={`mailto:${mail}`}>{mail}</Mail>;
  }
}

MailLink.propTypes = {
  mail: PropTypes.string.isRequired,
};

export default MailLink;
