import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../Store';
import NewsListBase from '../NewsListBase';

class BlogsList extends React.Component {
  render() {
    return <NewsListBase type="blogs" history={this.props.history} />;
  }
}

BlogsList.propTypes = {
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BlogsList);
