import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Dropzone from 'react-dropzone-uploader';
import { Label } from 'oxyrion-ui/lib';
import FileViewDownload from '../FileViewDownload';
import { __ } from '../../Utils';

const AttachmentsWrapper = styled.div`
  width: 100%;
  max-width: ${rem(600)};
`;

const AttachmentRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
const Delete = styled.div`
  cursor: pointer;
  margin-left: 24px;
  color: red;
  font-size: 12px;
  :hover {
    text-decoration: underline;
  }
`;

class AttachmentsComponent extends React.Component {
  render() {
    const {
      history,
      attachments,
      onFileDelete,
      onFileDownload,
      getUploadParams,
      onError,
      selectedLang,
      handleChangeStatus,
    } = this.props;
    return (
      <AttachmentsWrapper>
        <StyledLabel>{`${__('Prílohy')} ${selectedLang}:`}</StyledLabel>
        {attachments &&
          attachments.map(item => (
            <AttachmentRow>
              <FileViewDownload
                name={item.name}
                history={history}
                downloadFunction={() => {
                  return onFileDownload(item);
                }}
                error={error => {
                  onError(error);
                }}
              />
              <Delete
                onClick={() => onFileDelete(item.fullPath)}
                className="delete"
              >
                {__('Odstrániť')}
              </Delete>
            </AttachmentRow>
          ))}
        <Dropzone
          getUploadParams={e => getUploadParams(e, 'view')}
          onChangeStatus={e => handleChangeStatus(e)}
          inputContent={__('Nahrať prílohy')}
          maxFiles={10}
          canCancel={false}
          inputWithFilesContent={__('Nahrať dalšie prílohy')}
          submitButtonDisabled
          styles={{
            previewContainer: {
              padding: rem(10),
            },
            inputLabelWithFiles: {
              marginBottom: rem(12),

              color: '#ee6500',
            },
            dropzone: {
              minHeight: rem(60),
            },
            inputLabel: { color: '#ee6500', fontSize: rem(12) },
          }}
        />
      </AttachmentsWrapper>
    );
  }
}

AttachmentsComponent.propTypes = {
  history: PropTypes.shape().isRequired,
  onFileDelete: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  getUploadParams: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  selectedLang: PropTypes.shape().isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AttachmentsComponent;
