import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewsLetterDetail from '../../../Containers/NewsLetterDetail';
import NewsLetterList from '../../../Containers/NewsLetterList';
import NewsLetterContactsLogouts from '../../../Containers/NewsLetterContactsLogouts';

function NewsLetter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route
        path={`${match.url}/:id/logouts`}
        component={NewsLetterContactsLogouts}
      />
      <Route path={`${match.url}/:id`} component={NewsLetterDetail} />
      <Route path={`${match.url}`} component={NewsLetterList} />
    </Switch>
  );
}

NewsLetter.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default NewsLetter;
