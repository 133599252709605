import { __ } from '../Utils';

export default [
  {
    name: __('Kód'),
    clickable: true,
    sorterValue: 'code',
    otherProps: { maxWidth: 100 },
  },
  {
    name: __('Kód výrobcu'),
    clickable: false,
    otherProps: { maxWidth: 120 },
  },
  {
    name: __('Značka'),
    clickable: true,
    sorterValue: 'brand',
    otherProps: { maxWidth: 200 },
  },
  {
    name: __('1. Vrstva'),
    clickable: false,
  },
  {
    name: __('2. Vrstva'),
    clickable: false,
  },

  {
    name: __('3. Vrstva'),
    clickable: false,
  },

  {
    name: __('4. Vrstva'),
    clickable: false,
  },
  {
    name: __('HSF'),
    clickable: false,
    otherProps: { maxWidth: 100 },
  },
];
