import React from 'react';
import { Icon } from 'oxyrion-ui/lib';
import moment from 'moment';
import styled from 'styled-components';
import { rem } from 'polished';

import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import API from '../../API';
import tableHeaders from '../../ContentConfig/products';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import hasAllImages from '../../ContentConfig/catalog_product_images';
import hasAllVariants from '../../ContentConfig/catalog_product_variants';
import hasCompleteDocumentation from '../../ContentConfig/catalog_product_complete_doc';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import API2 from '../../API2';

const LastSyncLabel = styled.div`
  font-size: ${rem(10)};
`;

class Products extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Produktový katalóg');
    this.accessors = [
      'name',
      'has_all_variants',
      'has_all_images',
      'has_title_image',
      'has_meta_description_over_160',
      'all_variants_retired',
      'has_complete_documentation',
      'has_sale',
      'has_sale_clearance',
      'has_expired_documentation',
      'type',
      'brand',
      'subbrand',
      'created_date',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('name');
    this.state = {
      ...this.state,
      showFilter: false,
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'hasAllImages',
          label: __('Obrázky'),
          values: hasAllImages,
          type: 'select',
        },
        {
          name: 'hasAllVariants',
          label: __('Varianty farieb'),
          values: hasAllVariants,
          type: 'select',
        },
        {
          name: 'hasCompleteDocumentation',
          label: __('Dokumentácia'),
          values: hasCompleteDocumentation,
          type: 'select',
        },
      ],
    };
  }

  handleProductOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/products/${id}`);
  }
  applyFilter() {
    this.fetchData();
  }
  async componentWillMount() {
    const { filters } = this.state;
    try {
      const productTypes = await AdminAPI.getProductTypesAction({});
      const productsBrands = await API2.getBrandsAction('SK');
      const typeValues = productTypes.product_types.map(type => {
        return {
          name: type.name,
          value: type._id,
          key: type.system_name,
        };
      });
      const typeFilter = {
        name: 'type',
        label: __('Typ'),
        values: typeValues,
        type: 'select',
      };
      const brandValues = productsBrands.fullBrands.map(brand => {
        return {
          name: brand.brand,
          value: brand.brand,
          key: brand.brand,
        };
      });
      const brandFilter = {
        name: 'brand',
        label: __('Značka'),
        values: brandValues,
        type: 'select',
      };
      filters.push(typeFilter);
      filters.push(brandFilter);
      this.setState({
        filters,
      });
    } catch (e) {
      console.log(e);
    }
  }
  fetchCustomers(query) {
    try {
      const { firm } = this.props;
      return API2.getCustomersAction(firm, { q: query }).then(res => ({
        options: res.items.map(item => ({
          value: item._id,
          label: item.name,
        })),
      }));
    } catch (e) {
      return [];
    }
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, filters } = this.state;
      this.setState({ loading: true });
      let params;
      const filterToSend = {
        lang: this.props.firm,
      };

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      if (active === null) {
        params = Object.assign({}, filterToSend, parameters, { sorter });
      } else {
        params = Object.assign({}, filterToSend, parameters, {
          sorter,
          active,
        });
      }
      const [newData, productTypes, { items: syncs }] = await Promise.all([
        AdminAPI.getCatalogProductsAction(params),
        AdminAPI.getProductTypesAction(params),
        API.getSynchronizationsAction(this.props.firm, {
          limit: 1,
          status: 'success',
          type: 'xpaint_catalog_products',
          sorter: '-start_date_time',
        }),
      ]);

      const content = this.normalizeColumns(
        newData.items,
        productTypes.product_types,
      );

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        lastSync: syncs[0],
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  async sycnProducts(force) {
    if (!force) {
      this.setState({
        showSyncAlert: true,
      });

      return;
    }

    try {
      this.setState({
        productsSyncLoading: true,
      });

      await API2.updateFilterSearchCollectionAction(this.props.firm);

      this.setState({
        success: __('Produkty boli zosychronizované'),
        showSyncAlert: false,
        productsSyncLoading: false,
        lastSync: {
          start_date_time: new Date(),
        },
      });
    } catch (e) {
      this.setState({
        error: __('Synchronizácia neprebehla úspešn'),
        productsSyncLoading: false,
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data, productTypes = []) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `./products/${item._id}`,
            `${this.getItemName(item) || item.name}`,
          ),
          () => this.handleProductOnClick(item._id),
        ),
        has_all_variants:
          item.type === 'FARBA'
            ? _shapeData(
                item.has_all_variants ? (
                  <Icon name="done" color="green" />
                ) : (
                  <Icon name="close" color="red" size="l" />
                ),
              )
            : _shapeData(''),
        has_all_images: _shapeData(
          item.has_all_images ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        has_title_image: _shapeData(
          item.has_title_image ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        has_meta_description_over_160: _shapeData(
          !item.has_meta_description_over_160 ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        all_variants_retired: _shapeData(
          item.all_variants_retired ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        has_complete_documentation: _shapeData(
          item.has_complete_documentation ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        has_sale: _shapeData(
          item.has_sale ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        has_sale_clearance: _shapeData(
          item.has_sale_clearance ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        has_expired_documentation: _shapeData(
          <div
            title={`${__(
              'Expirovaná dokumentácia',
            )}: ${item.has_expired_documentation.map(i => ` ${i.name}`)}`}
          >
            {item.has_expired_documentation.length || '-'}
          </div>,
        ),
        type: _shapeData(
          productTypes.length && productTypes.find(p => p._id === item.type)
            ? productTypes.find(p => p._id === item.type).name
            : item.type,
        ),
        brand: _shapeData(item.brand),
        subbrand: _shapeData(item.subbrand),
        created_date: _shapeData(formatDate(item.created_date)),
        _id: item._id,
      });
      return acumulator;
    }, []);
    return result;
  }

  getItemName(item) {
    if (!item.nameTranslations) {
      return item.name;
    }

    if (item.nameTranslations.find(nt => nt.lang === 'SK')) {
      return (
        item.nameTranslations.find(nt => nt.lang === 'SK').name || item.name
      );
    }
    return item.name;
  }

  renderControlBar() {
    const { history } = this.props;
    const { lastSearchValue, showFilter, lastSync } = this.state;
    return (
      <ControllBar
        history={history}
        name={__('Produktový katalóg')}
        defaultValue={lastSearchValue}
        onChange={val => this.loadSearchResult(val)}
      >
        <LastSyncLabel>
          <ControlBarButton
            primary
            small
            onClick={() => this.sycnProducts(false)}
            showIcon={false}
          >
            {__('Synch. produktov eshopu')}
          </ControlBarButton>
          {lastSync &&
            `Posledná synch. pre eshop: ${formatDate(
              moment(lastSync.end_date_time),
              FORMAT_HUMANE_DATETIME,
            )}`}
        </LastSyncLabel>

        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleProductOnClick('new')}
          icon="plus"
        >
          {__('Pridať produkt')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const { showSyncAlert, productsSyncLoading } = this.state;

    return (
      <React.Fragment>
        <PopUpWrapper
          display={showSyncAlert}
          small
          message={__('Ručna sychronizácia produktov')}
          onClose={() =>
            this.setState({
              showSyncAlert: productsSyncLoading,
            })
          }
          onEscClose={() =>
            this.setState({ showSyncAlert: productsSyncLoading })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj chcete spustiť aktualizáciu produktov pre eshop? Počas synchronizácie neodchádzajte ',
            )}
            onDismiss={() =>
              this.setState({
                showSyncAlert: false,
              })
            }
            reverse
            onConfirm={() => this.sycnProducts(true)}
            loading={productsSyncLoading}
          />
        </PopUpWrapper>
        {super.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Products);
