import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/translations';
import { __, formatDate, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';

class Translations extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'app' });
    this.headers = tableHeaders;
    this.segmentName = __('Preklady aplikácií');
    this.showBulkOperations = false;
    this.accessors = ['app', 'lang', 'created_date', 'updated_date'];
    this.setSorter('app');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
    };
  }

  handleTranslationOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/translations/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, selectedCustomer, lastSearch } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await AdminAPI.getTranslationsAction(params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  translateApp(app) {
    if (app === 'B2B') {
      return 'PORTAL';
    }

    return app;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        app: _shapeData(
          renderLink(
            `./translations/${item._id}`,
            `${this.translateApp(item.app) || '-'}`,
          ),
          () => this.handleSystemOnClick(item._id),
        ),
        lang: _shapeData(item.lang),
        created_date: _shapeData(
          item.created_date ? formatDate(moment(item.created_date)) : '',
        ),
        updated_date: _shapeData(
          item.updated_date ? formatDate(moment(item.updated_date)) : '',
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('Preklady aplikácií')}>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleTranslationOnClick('new')}
          icon="plus"
        >
          {__('Pridať nový preklad')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Translations);
