import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Publikované pre'),
    clickable: false,
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Krajina'),
    clickable: false,
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Typ'),
    clickable: false,
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Priorita'),
    clickable: false,
    otherProps: { minWidth: 100 },
  },
  {
    name: __('Publikovaný od'),
    clickable: true,
    sorterValue: 'published_from',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Publikovaný do'),
    clickable: true,
    sorterValue: 'published_to',
    otherProps: { minWidth: 250 },
  },
];
