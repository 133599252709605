import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import Icon from 'oxyrion-ui/lib/Icon';

import ControllBar from '../../Components/ControllBar';
import { __, formatDate, FORMAT_HUMANE_DATETIME } from '../../Utils';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import headers from '../../ContentConfig/priceList';
import BaseTable from '../BaseTable';

import { connect } from '../../Store';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ModifyPriceList from '../../Containers/ModifyPriceList';
import FileViewDownload from '../../Components/FileViewDownload';
import CheckAccess from '../../ACL/CheckAccess';
import API2 from '../../API2';

const DeleteWrapper = styled.div`
  g {
    fill: ${({ theme }) => theme.color.danger};
  }
  text-align: right;
`;

const Span = styled.span`
  cursor: pointer;
`;

const Wrapper = styled.div`
  th:last-of-type {
    text-align: right;
  }
`;

class PriceList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = headers;
    this.showBulkOperations = false;
    this.segmentName = __('Cenníky');
    this.setSorter('name');
    this.accessors = ['name', 'type', 'dateFrom', 'delete'];
    this.state = {
      ...this.state,
      status: null,
      statusMessage: __('Všetky'),
      showCustomerSelection: false,
      onlyOwned: null,
      onlyOwnedMessage: __('Moje príležitosti'),
      showCreateOpportunityForm: false,
      isClosed: true,
      contacts: [],
    };
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter } = this.state;
      this.setState({ loading: true });
      const params = Object.assign({}, parameters, { sorter });
      const newData = await API2.getPricelistsAction(this.props.firm, params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  }

  setSorter(sorter) {
    if (sorter !== null) {
      // const { history } = this.props;
      // if (sorter && history && history.length) {
      //   history.push({
      //     search: buildQuery({ sorter }),
      //   });
      // }
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  handleNameOnClick(id) {
    const { history, match } = this.props;
    history.push(`${match.url}/${id}`);
  }

  handleCustomerOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/customers/${id}`);
  }

  toogleDeletePriceList(show = undefined, id) {
    if (typeof show !== 'undefined') {
      this.setState({ showDeletePriceList: show, id });
    } else {
      this.setState({
        showDeletePriceList: !this.state.showDeletePriceList,
        id,
      });
    }
  }

  renderDeleteButton(id) {
    return (
      <DeleteWrapper>
        <Span onClick={() => this.toogleDeletePriceList(true, id)}>
          <Icon name="close" color={baseTheme.color.danger} size="m" />
        </Span>
      </DeleteWrapper>
    );
  }

  async deletePriceList() {
    try {
      const { firm } = this.props;
      const { id } = this.state;
      this.setState({ deletePriceListLoading: true });
      await API2.deletePricelistAction(firm, id);
      this.setState({ deletePriceListLoading: false, id: null });
      this.toogleDeletePriceList(false);
      this.fetchData();
    } catch (error) {
      this.setState({
        deletePriceListError: __('Nepodarilo sa vymazať cenník.'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, id = null) => {
      return {
        value: value || '-',
        handleOnClick: handleOnClick || null,
        id,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          <FileViewDownload
            name={item.name}
            history={this.props.history}
            downloadFunction={async () => {
              return API2.getPricelistAction(this.props.firm, item._id);
            }}
            error={error => {
              this.setState({ error });
            }}
          />,
        ),
        type: _shapeData(
          item.file && item.file.mimeType.match('pdf') ? 'PDF' : 'XLSX',
        ),
        dateFrom: _shapeData(
          item.dateFrom
            ? formatDate(moment(item.dateFrom), FORMAT_HUMANE_DATETIME)
            : '-',
        ),
        delete: _shapeData(this.renderDeleteButton(item._id)),
      });
      return acumulator;
    }, []);
    return result;
  }

  tooglePriceListForm(show = undefined) {
    if (typeof show !== 'undefined') {
      this.setState({ showPriceListForm: show });
    } else {
      this.setState({ showPriceListForm: this.state.showPriceListForm });
    }
  }

  onSubmit() {
    this.tooglePriceListForm(false);
    this.fetchData();
  }

  renderControlBar() {
    const {
      showPriceListForm,
      deletePriceListError,
      deletePriceListLoading,
      showDeletePriceList,
    } = this.state;
    const { firm, history } = this.props;
    return (
      <React.Fragment>
        <PopUpWrapper
          noMinHeight
          message={__('Nový cenník')}
          display={showPriceListForm}
          onClose={() => this.tooglePriceListForm(false)}
          statusName={showPriceListForm}
          onEscClose={() => this.setState({ showPriceListForm: false })}
        >
          <ModifyPriceList firm={firm} onSubmit={() => this.onSubmit()} />
        </PopUpWrapper>
        <PopUpWrapper
          display={showDeletePriceList}
          onClose={() => this.toogleDeletePriceList(false)}
          statusName={showDeletePriceList}
          onEscClose={() => this.setState({ showDeletePriceList: false })}
          small
        >
          <ConfirmDialog
            message={__('Prajete si vymazať cenník ?')}
            onDismiss={() => this.toogleDeletePriceList(false)}
            onConfirm={() => this.deletePriceList()}
            error={deletePriceListError}
            loading={deletePriceListLoading}
          />
        </PopUpWrapper>
        <ControllBar
          history={history}
          name={this.segmentName}
          defaultValue={this.state.lastSearchValue}
          onChange={val => this.loadSearchRrsult(val)}
        >
          <CheckAccess
            operation="postPricelistAction"
            Component={
              <ControlBarButton
                small
                primary
                basic
                icon="add-pricelist"
                onClick={() => this.tooglePriceListForm(true)}
              >
                {__('Pridať cenník')}
              </ControlBarButton>
            }
          />
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    return <Wrapper>{super.render()}</Wrapper>;
  }
}

PriceList.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PriceList);
