import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import MarketingLists from '../../../Containers/MarketingLists';
import MarketingListDetail from '../../../Containers/MarketingListDetail';

function MarketingListsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={MarketingListDetail} />
      <Route path={`${match.url}`} component={MarketingLists} />
    </Switch>
  );
}

MarketingListsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default MarketingListsCard;
