import React from 'react';
import { __ } from '../Utils';
import CheckAccess from '../ACL/CheckAccess';

export default [
  {
    name: __('Názov ( Zobraziť / Stiahnuť )'),
    clickable: false,
    sorterValue: 'name',
    otherProps: { minWidth: 200 },
  },
  { name: __('Typ'), clickable: false, sorterValue: 'mimeType' },
  {
    name: __('Dátum platnosti od'),
    clickable: false,
    sorterValue: 'dateFrom',
  },
  {
    name: (
      <CheckAccess
        operation="deletePricelistAction"
        Component={__('Vymazať')}
      />
    ),
    clickable: false,
    sorterValue: '',
  },
];
