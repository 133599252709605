import React from 'react';
import PropTypes from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { rem } from 'polished';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const Wrapper = styled.div`
  margin: ${props => rem(props.margin)};
  border: 1px solid #eee;

  .demo-editor {
    min-height: 33rem;
    max-height: 40rem;
    overflow: auto;
  }
`;

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    const html = props.text || '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.text === undefined || this.props.text === '') &&
      nextProps.text !== undefined
    ) {
      const html = nextProps.text || '';
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
  }

  onEditorStateChange = editorState => {
    const { onChange } = this.props;

    this.setState(
      {
        editorState,
      },
      () =>
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent()))),
    );
  };

  render() {
    const { editorState } = this.state;
    const { options } = this.props;

    return (
      <React.Fragment>
        <Wrapper>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
              options,
            }}
          />
        </Wrapper>
      </React.Fragment>
    );
  }
}
TextEditor.propTypes = {
  text: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  margin: PropTypes.number,
};

TextEditor.defaultProps = {
  text: '',
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'image',
    'remove',
    'history',
  ],
  margin: 25,
};
export default TextEditor;
