import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Perex'),
  },
  {
    name: __('Publikované'),
  },
  {
    name: __('Publikované od'),
    clickable: true,
    sorterValue: 'published_from',
  },
  {
    name: __('Publikované do'),
    clickable: true,
    sorterValue: 'published_to',
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
  },
  {
    name: __('Dátum úpravy'),
    clickable: true,
    sorterValue: 'updated_date',
  },
  {
    name: __('Prorita'),
    clickable: true,
    sorterValue: 'priority',
  },
];
