import React from 'react';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/paint-systems';
import { __, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import AdminAPI from '../../AdminAPI';

class PaintSystemsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'code' });
    this.headers = tableHeaders;
    this.accessors = [
      'code',
      'producer_code',
      'brand',
      'layer0',
      'layer1',
      'layer2',
      'layer3',
      'total_hsf',
    ];
    this.segmentName = __('Vzorkovníky');
    this.showBulkOperations = false;
    this.setSorter('code');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/paint-systems/${id}`);
  }

  async loadSearchRrsult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearch: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearch: value.toLowerCase() });
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { lastSearch, sorter } = this.state;
      //   const { firm } = this.props;
      this.setState({ loading: true });
      const params = parameters;

      if (!params.q) {
        params.q = lastSearch;
      }

      if (sorter) {
        params.sorter = sorter;
      }

      const newData = await AdminAPI.getPaintSystemsAction(params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        code: _shapeData(
          renderLink(`./paint-systems/${item._id}`, `${item.code || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        producer_code: _shapeData(item.producer_code),
        brand: _shapeData(item.brand),
        layer0: _shapeData(item.layers[0] && item.layers[0].product_name),
        layer1: _shapeData(item.layers[1] && item.layers[1].product_name),
        layer2: _shapeData(item.layers[2] && item.layers[2].product_name),
        layer3: _shapeData(item.layers[3] && item.layers[3].product_name),
        layer4: _shapeData(item.layers[4] && item.layers[4].product_name),
        total_hsf: _shapeData(item.total_hsf),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        history={history}
        name={__('Zoznam náterových systémov')}
        defaultValue={this.state.lastSearchValue}
        onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          small
          primary
          basic
          onClick={() => this.handleAddNewClick()}
        >
          {__('Vytvoriť náterový systém')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  handleAddNewClick() {
    const { history, firm } = this.props;
    history.push(`/${firm}/paint-systems/new`);
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PaintSystemsList);
