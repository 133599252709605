import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Message, Input, Label, Button, Icon } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import GreyBox from '../../Components/GreyBox';
import SearchableSelect from '../../Components/SearchableSelect';
import LanguageSwitch from '../../Components/LanguageSwitch';
import API2 from '../../API2';

const StyledInput = styled(Input)`
  width: ${rem(250)};
`;

const Wrapper = styled.div`
  padding: ${rem(8)};
`;

const InputWrapper = styled.div`
  margin-right: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(8)};
`;

const TaskWrapper = styled.div`
  margin: ${rem(12)};
  max-width: ${rem(800)};
`;
const TaskTitleWrapper = styled.div`
  display: flex;
  width: ${rem(500)};
  justify-content: space-between;
  margin-bottom: ${rem(16)};
  font-size: ${rem(18)};
  font-weight: 800;
  cursor: pointer;
`;
const TaskTitle = styled.div``;
const TaskIconWrapper = styled.div``;
const TaskContentWrapper = styled.div`
  padding: ${rem(12)};
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  right: ${rem(24)};
  bottom: 0px;
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(250)};
`;

const LanguageWrapper = styled.div``;

// const HELPDESK_TYPES = [
//   {
//     lang: 'SK',
//     values: [
//       {
//         _id: 1,
//         type: 'Servis stroja',
//         subtype: 'Servis',
//         solvers: [],
//       },
//       {
//         _id: 2,
//         type: 'Servis stroja',
//         subtype: 'Servisná prehliadka',
//         solvers: [],
//       },
//       {
//         _id: 3,
//         type: 'Reklamácia',
//         subtype: 'Preprava',
//         solvers: [],
//       },
//       {
//         _id: 4,
//         type: 'Reklamácia',
//         subtype: 'Produkt',
//         solvers: [],
//       },
//       {
//         _id: 5,
//         type: 'Reklamácia',
//         subtype: 'Aplikácia',
//         solvers: [],
//       },
//       {
//         _id: 6,
//         type: 'Reklamácia',
//         subtype: 'Tónovanie',
//         solvers: [],
//       },
//       {
//         _id: 7,
//         type: 'Marketing',
//         subtype: 'Inštalácia',
//         solvers: [],
//       },
//       {
//         _id: 8,
//         type: 'Marketing',
//         subtype: 'Marketingový materiál',
//         solvers: [],
//       },
//     ],
//   },

//   {
//     lang: 'CZ',
//     values: [
//       {
//         _id: 1,
//         type: 'Servis stroja',
//         subtype: 'Servis',
//         solvers: [],
//       },
//       {
//         _id: 2,
//         type: 'Servis stroja',
//         subtype: 'Servisná prehliadka',
//         solvers: [],
//       },
//       {
//         _id: 3,
//         type: 'Reklamácia',
//         subtype: 'Preprava',
//         solvers: [],
//       },
//       {
//         _id: 4,
//         type: 'Reklamácia',
//         subtype: 'Produkt',
//         solvers: [],
//       },
//       {
//         _id: 5,
//         type: 'Reklamácia',
//         subtype: 'Aplikácia',
//         solvers: [],
//       },
//       {
//         _id: 6,
//         type: 'Reklamácia',
//         subtype: 'Tónovanie',
//         solvers: [],
//       },
//       {
//         _id: 7,
//         type: 'Marketing',
//         subtype: 'Inštalácia',
//         solvers: [],
//       },
//       {
//         _id: 8,
//         type: 'Marketing',
//         subtype: 'Marketingový materiál',
//         solvers: [],
//       },
//     ],
//   },
// ];

class HelpdeskSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tasks: [],
      openedTasks: [],
      newTask: undefined,
      deleteLoading: false,
      activeLanguage: 'SK',
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 10,
      }).then(res => {
        return res.users.map(item => ({
          value: item._id,
          label: item.display_name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchData() {
    try {
      const result = await AdminAPI.getHelpdeskSolversAction({});

      const tasks = [
        {
          lang: 'SK',
          values: [
            {
              _id: 1,
              type: 'Servis stroja',
              subtype: 'Servis',
              solvers: [],
            },
            {
              _id: 2,
              type: 'Servis stroja',
              subtype: 'Servisná prehliadka',
              solvers: [],
            },
            {
              _id: 3,
              type: 'Reklamácia',
              subtype: 'Preprava',
              solvers: [],
            },
            {
              _id: 4,
              type: 'Reklamácia',
              subtype: 'Produkt',
              solvers: [],
            },
            {
              _id: 5,
              type: 'Reklamácia',
              subtype: 'Aplikácia',
              solvers: [],
            },
            {
              _id: 6,
              type: 'Reklamácia',
              subtype: 'Tónovanie',
              solvers: [],
            },
            {
              _id: 7,
              type: 'Marketing',
              subtype: 'Inštalácia',
              solvers: [],
            },
            {
              _id: 8,
              type: 'Marketing',
              subtype: 'Marketingový materiál',
              solvers: [],
            },
          ],
        },

        {
          lang: 'CZ',
          values: [
            {
              _id: 1,
              type: 'Servis stroja',
              subtype: 'Servis',
              solvers: [],
            },
            {
              _id: 2,
              type: 'Servis stroja',
              subtype: 'Servisná prehliadka',
              solvers: [],
            },
            {
              _id: 3,
              type: 'Reklamácia',
              subtype: 'Preprava',
              solvers: [],
            },
            {
              _id: 4,
              type: 'Reklamácia',
              subtype: 'Produkt',
              solvers: [],
            },
            {
              _id: 5,
              type: 'Reklamácia',
              subtype: 'Aplikácia',
              solvers: [],
            },
            {
              _id: 6,
              type: 'Reklamácia',
              subtype: 'Tónovanie',
              solvers: [],
            },
            {
              _id: 7,
              type: 'Marketing',
              subtype: 'Inštalácia',
              solvers: [],
            },
            {
              _id: 8,
              type: 'Marketing',
              subtype: 'Marketingový materiál',
              solvers: [],
            },
          ],
        },
      ];

      const raw = [
        {
          lang: 'SK',
          values: [
            {
              _id: 1,
              type: 'Servis stroja',
              subtype: 'Servis',
              solvers: [],
            },
            {
              _id: 2,
              type: 'Servis stroja',
              subtype: 'Servisná prehliadka',
              solvers: [],
            },
            {
              _id: 3,
              type: 'Reklamácia',
              subtype: 'Preprava',
              solvers: [],
            },
            {
              _id: 4,
              type: 'Reklamácia',
              subtype: 'Produkt',
              solvers: [],
            },
            {
              _id: 5,
              type: 'Reklamácia',
              subtype: 'Aplikácia',
              solvers: [],
            },
            {
              _id: 6,
              type: 'Reklamácia',
              subtype: 'Tónovanie',
              solvers: [],
            },
            {
              _id: 7,
              type: 'Marketing',
              subtype: 'Inštalácia',
              solvers: [],
            },
            {
              _id: 8,
              type: 'Marketing',
              subtype: 'Marketingový materiál',
              solvers: [],
            },
          ],
        },

        {
          lang: 'CZ',
          values: [
            {
              _id: 1,
              type: 'Servis stroja',
              subtype: 'Servis',
              solvers: [],
            },
            {
              _id: 2,
              type: 'Servis stroja',
              subtype: 'Servisná prehliadka',
              solvers: [],
            },
            {
              _id: 3,
              type: 'Reklamácia',
              subtype: 'Preprava',
              solvers: [],
            },
            {
              _id: 4,
              type: 'Reklamácia',
              subtype: 'Produkt',
              solvers: [],
            },
            {
              _id: 5,
              type: 'Reklamácia',
              subtype: 'Aplikácia',
              solvers: [],
            },
            {
              _id: 6,
              type: 'Reklamácia',
              subtype: 'Tónovanie',
              solvers: [],
            },
            {
              _id: 7,
              type: 'Marketing',
              subtype: 'Inštalácia',
              solvers: [],
            },
            {
              _id: 8,
              type: 'Marketing',
              subtype: 'Marketingový materiál',
              solvers: [],
            },
          ],
        },
      ];

      result.items.forEach(i => {
        i.types.forEach(t => {
          i.firm.forEach(firm => {
            tasks
              .find(t1 => t1.lang === firm)
              .values.find(t1 => t1.type === t.type && t1.subtype === t.subtype)
              .solvers.push({
                value: i.user_id,
                label: i.user_name,
                dbId: i._id,
              });

            raw
              .find(t1 => t1.lang === firm)
              .values.find(t1 => t1.type === t.type && t1.subtype === t.subtype)
              .solvers.push({
                value: i.user_id,
                label: i.user_name,
                dbId: i._id,
              });
          });
        });
      });

      this.setState({
        tasks,
        rawData: raw,
      });
    } catch (e) {
      console.log(e);

      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Helpdesk riešitelia nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async saveData(type) {
    const { tasks, activeLanguage, rawData } = this.state;

    if (!type) {
      return;
    }

    this.setState({
      saveLoading: type._id,
    });

    try {
      const params = {};
      const dataToSave = [];

      const previousData = rawData.find(r => r.lang === activeLanguage).values;

      let previousSolvers = previousData
        .find(p => p.type === type.type && p.subtype === type.subtype)
        .solvers.map(s1 => s1.dbId);

      type.solvers.forEach(s => {
        previousSolvers = previousSolvers.filter(ps => ps !== s.dbId);
        dataToSave.push({
          _id: s.dbId,
          user_id: s.value,
          firm: [activeLanguage],
          types: [
            {
              type: type.type,
              subtype: type.subtype,
            },
          ],
        });
      });

      params.body = {
        data: dataToSave,
        to_delete: previousSolvers,
      };

      await AdminAPI.putHelpdeskSolversAction(params);

      this.fetchData();

      this.setState({
        success: __('Dáta boli uložené'),
        tasks,
        saveLoading: undefined,
        error: false,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: undefined,
        success: false,
      });
    }
  }

  handleDataChange(id, field, value) {
    const { tasks, activeLanguage } = this.state;

    tasks.find(t => t.lang === activeLanguage).values.find(t => t._id === id)[
      field
    ] = value;

    this.setState({
      tasks,
    });
  }

  toggleTask(id) {
    const { openedTasks } = this.state;

    if (openedTasks.indexOf(id) > -1) {
      openedTasks.splice(openedTasks.indexOf(id), 1);
    } else {
      openedTasks.push(id);
    }

    this.setState({ openedTasks });
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Helpdesk úlohy')} />
      </React.Fragment>
    );
  }
  renderTaskContent(type) {
    const { saveLoading } = this.state;

    if (!type) {
      return <div />;
    }

    return (
      <GreyBox sidePadding={0}>
        <TaskContentWrapper>
          <Row>
            <InputWrapper>
              <Label>{__('Názov úlohy')}</Label>
              <StyledInput
                placeholder={__('Názov')}
                disabled
                value={`${type.type} - ${type.subtype}`}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Riešitelia')}</Label>
              <SearchableSelectWrapper>
                <SearchableSelect
                  value={type.solvers}
                  isMulti
                  loadOptions={query => this.fetchUsers(query)}
                  placeholder={__('Vyhľadať zamestnanca')}
                  handleOnChange={e =>
                    this.handleDataChange(type._id, 'solvers', e)
                  }
                />
              </SearchableSelectWrapper>
            </InputWrapper>
          </Row>

          <ButtonWrapper>
            <Button
              primary
              small
              onClick={() => this.saveData(type)}
              loading={saveLoading === type._id}
            >
              {__('Uložiť')}
            </Button>
          </ButtonWrapper>
        </TaskContentWrapper>
      </GreyBox>
    );
  }

  render() {
    const {
      loading,
      error,
      success,
      openedTasks,
      tasks,
      activeLanguage,
    } = this.state;
    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <LanguageWrapper>
          <LanguageSwitch
            onChange={id => {
              this.setState({
                activeLanguage: id,
              });

              return true;
            }}
            activeId={activeLanguage}
          />
        </LanguageWrapper>

        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          {tasks.find(t => t.lang === activeLanguage) &&
            tasks
              .find(t => t.lang === activeLanguage)
              .values.map(t => (
                <TaskWrapper>
                  <TaskTitleWrapper onClick={() => this.toggleTask(t._id)}>
                    <TaskTitle>{`${t.type} - ${t.subtype}`}</TaskTitle>
                    <TaskIconWrapper>
                      <Icon
                        name={
                          openedTasks.indexOf(t._id) > -1
                            ? 'arrow-top'
                            : 'arrow-down'
                        }
                      />
                    </TaskIconWrapper>
                  </TaskTitleWrapper>
                  <AnimatedFormMessageWrapper
                    display={openedTasks.indexOf(t._id) > -1}
                  >
                    {this.renderTaskContent(t)}
                  </AnimatedFormMessageWrapper>
                </TaskWrapper>
              ))}
        </Wrapper>
      </React.Fragment>
    );
  }
}

HelpdeskSettings.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(HelpdeskSettings);
