import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from 'oxyrion-ui/lib/';
import EmailEditor from 'react-email-editor';
import defaultDesign from './defaultHTMLDesign.json';
import { __ } from '../../Utils';

const ButtonWrapper = styled.div`
  display: flex;
  /* flex-direction: table-column;
  flex-direction: column; */
  justify-content: flex-end;
  /* margin-top: ${rem(32)}; */
  /* position: absolute; */
  /* right: ${rem(72)};  */
  /* bottom: ${rem(150)}; */
  /* width: ${rem(352)}; */
  background-color: rgb(249, 249, 249); 
`;

const SaveButton = styled(Button)`
  margin: ${rem(10)};
  padding: ${rem(10)};
`;

const FinalSaveButton = styled(SaveButton)`
  color: white;
`;

const Container = styled.div`
  position: relative;
  padding-bottom: ${rem(24)};
  margin-top: -1px;
  border-top: 1px solid orange;
`;

class NewsletterEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.keyProps !== nextProps.keyProps) {
      this.isEditorLoaded = true;
      this.isComponentMounted = true;
      this.loadDesign();
    }
  }

  onLoad = () => {
    this.editorRef = this.editor.editor
    this.isEditorLoaded = true;
    this.loadDesign();
  };

  onSave = () => {
    this.editorRef.exportHtml(data => {
      this.props.onSave({ json_data: data.design, html_data: data.html });
    });
  };

  loadDesign = () => {
    const { jsonData, htmlData } = this.props;

    setTimeout(() => {
      if (!this.isEditorLoaded || !this.isComponentMounted) {
        this.loadDesign();
      } else {
        if (jsonData && jsonData.length) {
          this.editorRef.loadDesign(JSON.parse(jsonData));
        } else {
          const designData = defaultDesign;
          if (htmlData && htmlData.length) {
            designData.body.rows[0].columns[0].contents[0].values.html = htmlData;
          }
          this.editorRef.loadDesign(designData);
        }

        this.props.setEditor(this.editorRef);
      }
    }, 100);
  };

  render() {
    return (
      <Container>
        <ButtonWrapper>
          <FinalSaveButton small primary onClick={this.onSave}>
            {__('Uložiť')}
          </FinalSaveButton>
        </ButtonWrapper>
        <EmailEditor
          ref={editor => {
            this.editor = editor;
          }}
          key={this.props.keyProps}
          onLoad={this.onLoad}
          minHeight={rem(window.innerHeight - 170)}
        />
      </Container>
    );
  }
}

NewsletterEditor.propTypes = {
  keyProps: PropTypes.string,
  setEditor: PropTypes.func,
  jsonData: PropTypes.string,
  htmlData: PropTypes.string,
  onSave: PropTypes.func,
};

NewsletterEditor.defaultProps = {
  keyProps: '',
  setEditor: () => {},
  jsonData: '',
  htmlData: '',
  onSave: () => {},
};

export default NewsletterEditor;
