import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
// import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/marketingLists';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';

class MarketingLists extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Filtre');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'owner',
      'firm',
      'customers_count',
      'emails_count',
      'phones_count',
      'type',
      'created_date',
      'updated_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
      types: [],
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/lists/${id}`);
  }

  handleOwnerOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/users/${id}`);
  }

  async componentDidMount() {
    super.componentDidMount();

    const result = await AdminAPI.getCodelist('typy_marketingoveho_zoznamu');

    this.setState({
      types: result.codelist || [],
    });
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, selectedCustomer, lastSearch } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await AdminAPI.getMarketingListsAction(params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const { types } = this.state;

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./lists/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        owner: _shapeData(
          renderLink(
            `./users/${item.owner._id}`,
            `${item.owner.display_name || '-'}`,
          ),
          () => this.handleOwnerOnClick(item._id),
        ),
        firm: _shapeData(item.firm),
        customers_count: _shapeData(item.customers_count || '0'),
        emails_count: _shapeData(item.emails_count || '0'),
        phones_count: _shapeData(item.phones_count || '0'),
        type: _shapeData(
          types.find(t => t.value === item.type)
            ? types.find(t => t.value === item.type).label
            : item.type,
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('Marketingové zoznamy')}>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať zoznam')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(MarketingLists);
