import React from 'react';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/recipes';
import { __, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

class Recipes extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.accessors = [
      'card_name',
      'brand',
      'system_name',
      'range',
      'shadeCount',
    ];
    this.segmentName = __('Vzorkovníky');
    this.showBulkOperations = false;
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/recipes/${id}`);
  }

  async loadSearchRrsult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearch: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearch: value.toLowerCase() });
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { selectedCustomer, lastSearch } = this.state;
      const { firm } = this.props;
      this.setState({ loading: true });
      const params = parameters;

      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await API2.getFormulasAction(firm, params);

      const groupedData = this.groupData(newData.items);

      const content = this.normalizeColumns(groupedData);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  groupData(data) {
    return data.reduce((acum, item) => {
      const nextRows = item.categories.map(i => {
        return Object.assign({}, item, {
          range: i.name,
          shadeCount: i.occurrences,
          card_name: ' ',
          system_name: ' ',
          nav_brand_id: ' ',
        });
      });
      const firstValue = item;
      firstValue.range = __('Všetky odtiene');
      firstValue.shadeCount = item.shades ? item.shades.length : 0;

      return [...acum, firstValue, ...nextRows];
    }, []);
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        card_name: _shapeData(
          renderLink(`./recipes/${item._id}`, `${item.card_name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        brand: _shapeData(item.nav_brand_id),
        system_name: _shapeData(item.system_name),
        range: _shapeData(item.range),
        shadeCount: _shapeData(item.shadeCount),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        history={history}
        name={__('Zoznam receptúr')}
        defaultValue={this.state.lastSearchValue}
        onChange={val => this.loadSearchRrsult(val)}
      >
        {/* <CheckAccess
          operation="postColorCardAction"
          Component={ */}
        <ControlBarButton
          small
          primary
          basic
          onClick={() => this.handleAddNewClick()}
        >
          {__('Vytvoriť receptúru')}
        </ControlBarButton>
        {/* }
        /> */}
      </ControllBar>
    );
  }

  handleAddNewClick() {
    const { history, firm } = this.props;
    history.push(`/${firm}/recipes/new`);
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Recipes);
