import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Checkbox, Loader } from 'oxyrion-ui/lib/';
import EmailEditor from 'react-email-editor';
import Button from 'oxyrion-ui/lib/Button';
import emptyTemplate from './empty_template.json';
import { __ } from '../../Utils/index';
import AdminAPI from '../../AdminAPI';
import SearchableSelect from '../SearchableSelect';
import PopUpWrapper from '../PopUpWrapper';

const Container = styled.div`
  position: relative;
  padding-bottom: ${rem(24)};
  margin-top: -1px;
  border-top: 1px solid orange;
`;

class EmailTemplateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false;

    this.state = {
      isRefreshing: false,
      currentVariables: [],
      showAddPreset: false,
      showVariables: false,
      addToEnd: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.jsonData !== nextProps.jsonData ||
      this.props.lang !== nextProps.lang
    ) {
      this.isEditorLoaded = true;
      this.isComponentMounted = true;

      this.loadTemplate(nextProps.jsonData);
    }
  }

  onLoad = () => {
    this.editorRef = this.editor.editor;
    this.isEditorLoaded = true;
    this.loadTemplate();
  };

  onDesignLoad = () => {
    if (this.props.onDesignLoad) {
      this.props.onDesignLoad();
    }
    this.setState({ isRefreshing: false });

    if (this.isEditorLoaded && this.isComponentMounted) {
      this.editorRef.addEventListener('design:updated', (updates) => {
        this.props.hasUnsaved(updates);
      });
    }
  };

  loadTemplate = (jsonData) => {
    this.setState({ isRefreshing: true });
    if (jsonData) {
      if (!this.isEditorLoaded || !this.isComponentMounted) {
        this.loadTemplate(jsonData);
      } else {
        this.editorRef.loadDesign(JSON.parse(jsonData));
        this.props.setEditor(this.editorRef);

        this.editorRef.exportHtml((data) => {
          const { html } = data;
          const e = html && html.match(/\{\{.*?\}\}/g);
          this.setState({ currentVariables: e || [] });
        });
      }
    } else {
      this.resetDesign();
    }
  };

  saveDesign = () => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    this.editorRef.exportHtml((data) => {
      const { design, html } = data;
      this.props.onSave(JSON.stringify(design), html);
      const e = html && html.match(/\{\{.*\}\}/g);

      this.setState({ currentVariables: e || [] });
    });
  };

  saveDesignAndCopy = () => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    this.editorRef.exportHtml((data) => {
      const { design, html } = data;
      this.props.onCopy(JSON.stringify(design), html);
      const e = html && html.match(/\{\{.*\}\}/g);

      this.setState({ currentVariables: e || [] });
    });
  };

  resetDesign = (unsaved = false) => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    this.editorRef.loadDesign(emptyTemplate);
    this.props.setEditor(this.editorRef);
    this.setState({ currentVariables: [] });

    if (unsaved) {
      this.props.hasUnsaved();
    }
  };

  handleSearchables(e, addToEnd) {
    if (addToEnd) {
      this.editorRef.exportHtml((data) => {
        const { design } = data;

        const oldRows = design.body.rows;
        const newRows = JSON.parse(e.json_data).body.rows;

        const newDesign = Object.assign({}, design);
        newDesign.body.rows = oldRows.concat(newRows);

        this.loadTemplate(JSON.stringify(newDesign));
        this.setState({ showAddPreset: false });
        this.props.hasUnsaved();
      });
    } else {
      this.loadTemplate(e.json_data);
      this.setState({ showAddPreset: false });
      this.props.hasUnsaved();
    }
  }

  fetchPresetTemplates(query) {
    try {
      return AdminAPI.getPresetEmailTemplatesAction({
        q: query,
        limit: 10,
      }).then((res) => {
        return res.items.map((item) => ({
          value: item.name,
          label: item.name,
          json_data: item.json_data,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  render() {
    const {
      isRefreshing,
      currentVariables,
      showAddPreset,
      showVariables,
      addToEnd,
    } = this.state;

    return (
      <Container>
        <PopUpWrapper
          display={showAddPreset}
          small
          onClose={() =>
            this.setState({
              showAddPreset: false,
            })
          }
          message={__('Aplikovať prednastavenú šablónu')}
        >
          <PopUpContentWrapper>
            <div
              className="checkbox-wrapper"
              onClick={() =>
                this.setState({
                  addToEnd: !addToEnd,
                })
              }
            >
              <Checkbox checked={addToEnd} />
              <div className="label">{__('Prilepiť za content')}</div>
            </div>
            <div className="text">
              {addToEnd ? (
                <div>
                  {__('Zvolená šablóna sa doplní na koniec súčasného editora')}
                </div>
              ) : (
                <div>{__('Zvolením šablóny sa prepíše súčasný editor')}</div>
              )}
            </div>
            <SearchableSelect
              loadOptions={(query) => {
                return this.fetchPresetTemplates(query);
              }}
              placeholder={__('Vyhľadať prednastavenú šablónu')}
              handleOnChange={(e) => this.handleSearchables(e, addToEnd)}
            />
          </PopUpContentWrapper>
        </PopUpWrapper>

        {!this.props.lang && (
          <LoaderOverlay>
            <DisabledText> {__('Vyberte šablónu')}</DisabledText>
          </LoaderOverlay>
        )}

        {isRefreshing && (
          <LoaderOverlay>
            <Loader size="xl" />
          </LoaderOverlay>
        )}

        <ButtonsWrapper>
          <VariablesWrapper>
            <ResetButton
              small
              primary
              onClick={() => {
                this.setState({ showAddPreset: true });
              }}
            >
              {`${__('Aplikovať šablónu')}`}
            </ResetButton>
            <ResetButton
              small
              danger
              onClick={() => {
                this.resetDesign(true);
              }}
            >
              {`${__('Vyčistiť editor')}`}
            </ResetButton>

            {showVariables ? (
              <CopyVariablesWrapper>
                <CopyVariable>{`${__('Premenné')}:`}</CopyVariable>
                {currentVariables.map((cv) => (
                  <CopyVariable
                    onClick={() => {
                      navigator.clipboard.writeText(`${cv}`);
                    }}
                    title={__(`Skopírovať do clipboardu`)}
                  >{`${cv}`}</CopyVariable>
                ))}
              </CopyVariablesWrapper>
            ) : (
              <ResetButton
                small
                primary
                onClick={() => {
                  this.setState({ showVariables: true });
                }}
              >
                {`${__('Zobraziť premenné')}`}
              </ResetButton>
            )}
          </VariablesWrapper>

          <VariablesWrapper>
            <SaveButton
              small
              primary
              onClick={() => {
                this.saveDesignAndCopy();
              }}
            >
              {`${__('Vytvoriť kópiu')}`}
            </SaveButton>
            <SaveButton
              small
              primary
              onClick={() => {
                this.saveDesign();
              }}
            >
              {`${__('Uložiť')}`}
            </SaveButton>
          </VariablesWrapper>
        </ButtonsWrapper>

        <EmailEditor
          ref={(editor) => {
            this.editor = editor;
          }}
          key={this.props.keyProps}
          onLoad={this.onLoad}
          minHeight={rem(window.innerHeight - 150)}
          onDesignLoad={this.onDesignLoad}
        />
      </Container>
    );
  }
}

const DisabledText = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: #555555;
  font-size: ${rem(12)};
  padding: ${rem(8)};
  border-radius: ${rem(8)};
`;

const PopUpContentWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: ${rem(24)};
  height: 33vh;

  .checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: ${rem(16)};
    font-size: ${rem(14)};
    cursor: pointer;
  }

  .text {
    margin-bottom: ${rem(16)};
    font-size: ${rem(12)};
    color: gray;
  }
`;

const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
`;

const SaveButton = styled(Button)`
  margin: ${rem(8)};
  padding: ${rem(10)};
  min-width: 160px;
`;

const ResetButton = styled(SaveButton)`
  margin-left: 0;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const VariablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${rem(12)};
  padding: ${rem(8)};
`;

const CopyVariable = styled.a`
  cursor: copy;
  margin: 0 ${rem(8)} ${rem(8)} ${rem(8)};
`;

const CopyVariablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

EmailTemplateEditor.propTypes = {
  onSave: PropTypes.func.isRequired,
  keyProps: PropTypes.string,
  setEditor: PropTypes.func,
  lang: PropTypes.string,
  jsonData: PropTypes.string,
  onDesignLoad: PropTypes.func,
  hasUnsaved: PropTypes.func,
};

EmailTemplateEditor.defaultProps = {
  keyProps: '',
  setEditor: () => {},
  lang: null,
  jsonData: null,
  onDesignLoad: null,
  hasUnsaved: () => {},
};

export default EmailTemplateEditor;
