import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { Input, Label, Select, Icon } from 'oxyrion-ui/lib';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { __ } from '../../Utils';
import { connect } from '../../Store';
import FieldTypes from '../../ContentConfig/fieldTypes';
import LanguageSwitch from '../LanguageSwitch';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Checkbox from 'oxyrion-ui/lib/Checkbox';

const SubWrapper = styled.div`
  width: 100%;
  padding: ${rem(3)};

  ${({ theme }) => theme.media.m`
  width: 50%;
  `};
`;

const StyledInput = styled(Input)`
  margin-top: ${rem(10)};
  width: calc(100% - 30px);
  max-width: ${rem(300)};
`;

const Row = styled.div`
  display: flex;
`;

const StyledInputSmall = styled(Input)`
  width: ${rem(200)};
`;

const TableInput = styled(Input)`
  max-width: ${rem(80)};
`;

const StyledSelect = styled(Select)`
  width: 60%;
`;

const StyledSelectSmall = styled(Select)`
  width: ${props => (props.width ? rem(props.width) : rem(200))};
  margin-right: ${rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;

const Wrapper = styled.div`
  margin: ${rem(10)};
  padding-bottom: ${rem(130)};
`;

const Add = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 40px;
  height: 20px;
  background: #eeeeee;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const Delete = styled.div`
  width: ${rem(22)};
  height: ${rem(22)};
  background: red;
  position: relative;
  right: ${rem(22)};
  top: ${rem(6)};
  border-radius: ${rem(11)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Space = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
`;

class ParamDetail extends React.Component {
  getUnitsValues(unit) {
    if (!unit) {
      return [];
    }
    if (Array.isArray(unit)) {
      return unit
        .filter(u => u)
        .map(u => {
          return u;
        });
    } else if (unit.value) {
      return [unit];
    }
    return {
      label: unit,
      value: unit,
    };
  }

  render() {
    const {
      item,
      onChange,
      groups,
      changeLanguage,
      activeLanguage,
      onTableChange,
      addRow,
      addColl,
      deleteRow,
      deleteColl,
      manageGroups,
      codelists,
      units,
      showGroups,
    } = this.props;

    let values = [];

    if (item.type === 'table' && item.value) {
      values = Array.isArray(item.value) ? item.value : [item.value];
    }

    return (
      <Wrapper>
        <SubWrapper>
          <Label> {__('Typ')}</Label>
          <StyledSelect
            size="s"
            onChange={e => onChange(e, 'type')}
            value={item.type}
          >
            {FieldTypes.map(f => {
              return (
                <option
                  selected={item.type === f.value}
                  value={f.value}
                  key={f.key}
                >
                  {f.display_name}
                </option>
              );
            })}
          </StyledSelect>
          {showGroups && (
            <React.Fragment>
              <Label> {__('Skupina')}</Label>
              <Row>
                <StyledSelect
                  size="s"
                  onChange={e => onChange(e, 'group')}
                  value={item.group}
                >
                  <option selected={!item.group} value="" key="">
                    {' - '}
                  </option>
                  {groups.map(g => {
                    return (
                      <option
                        selected={item.group === g._id}
                        value={g._id}
                        key={g._id}
                      >
                        {g.name}
                      </option>
                    );
                  })}
                </StyledSelect>
                <Add onClick={() => manageGroups()}>
                  <Icon name="edit" size="l" />
                </Add>
              </Row>
            </React.Fragment>
          )}
          <Space />
          <React.Fragment>
            <Label>
              <Checkbox
                checked={item.hide}
                onChange={e => onChange(e.target.checked, 'hide')}
              />
              {__('Skryť v tabuľke parametrov')}
            </Label>
          </React.Fragment>
          <Space />
          <React.Fragment>
            <Label>
              <Checkbox
                checked={item.export_to_farlesk}
                onChange={e => onChange(e.target.checked, 'export_to_farlesk')}
              />
              {__('Exportovať pre farlesk')}
            </Label>
          </React.Fragment>
          <Space />
          <Label> {__('Názov')}</Label>
          <LanguageSwitch
            onChange={id => {
              return changeLanguage(id);
            }}
            activeId={activeLanguage}
          />
          <StyledInput
            placeholder={__(item.name)}
            onChange={e => onChange(e, 'name')}
            value={
              item.translations &&
              item.translations.find(t => t.lang === activeLanguage)
                ? item.translations.find(t => t.lang === activeLanguage).value
                : item.name
            }
          />
          <Label>
            {__(
              'Systemový názov - využíva sa v prípade parametra s určitými pravidlami/závalisloťami',
            )}
          </Label>
          <StyledInput
            // disabled={this.props.user.email !== 'michal.hricisin@dejmark.com'}
            placeholder={__('Momentálne nepotrebné')}
            onChange={e => onChange(e, 'system_name')}
            value={item.system_name}
          />
        </SubWrapper>
        {item.type !== 'table' ? (
          <React.Fragment>
            {(item.type === 'codelist' ||
              item.type === 'multiSelect' ||
              item.type === 'checkboxesGroup' ||
              item.type === 'selectGroup') && (
              <React.Fragment>
                <Label> {__('Číselník')}</Label>
                <StyledSelect
                  style={{ maxWidth: rem(300) }}
                  size="s"
                  onChange={e => onChange(e, 'codelist')}
                >
                  <option> - </option>
                  {codelists &&
                    codelists.map(f => {
                      return (
                        <option
                          selected={item.codelist === f._id}
                          value={f._id}
                          key={f._id}
                        >
                          {f.name}
                        </option>
                      );
                    })}
                </StyledSelect>
              </React.Fragment>
            )}
            <Label> {__('Jednotka')}</Label>
            <ReactMultiSelectCheckboxes
              options={units}
              value={this.getUnitsValues(item.unit)}
              placeholderButtonLabel={__('Bez jednotky')}
              onChange={e => onChange(e, 'unit')}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th />
                  {item.headers &&
                    item.headers.map((header, index) => {
                      return (
                        <Th style={{ maxWidth: rem(80) }}>
                          <TableInput
                            value={header.name}
                            onChange={e => onTableChange(e, 'header', index)}
                          />
                          {!item._id && (
                            <Delete onClick={() => deleteColl(index)}>
                              <Icon name="close" color="white" />
                            </Delete>
                          )}
                        </Th>
                      );
                    })}
                  <Th
                    style={{
                      fontSize: rem(17),
                      fontWeight: 400,
                    }}
                  >
                    <Add style={{ width: rem(130) }} onClick={() => addColl()}>
                      {__('+ Pridať stĺpec')}
                    </Add>
                  </Th>
                </Tr>
              </Thead>
            </Table>
            <Table>
              <Tbody>
                {values.map((row, index) => {
                  return (
                    <Tr>
                      <Td>
                        <StyledInputSmall
                          value={row.name}
                          onChange={e => onTableChange(e, 'name', index)}
                        />
                        {!item._id && (
                          <Delete onClick={() => deleteRow(index)}>
                            <Icon name="close" color="white" />
                          </Delete>
                        )}
                      </Td>
                      <Td>
                        <StyledSelectSmall
                          size="s"
                          onChange={e => onTableChange(e, 'type', index)}
                          value={row.type}
                        >
                          {FieldTypes.filter(f => f.value !== 'table').map(
                            f => {
                              return (
                                <option
                                  selected={row.type === f.value}
                                  value={f.value}
                                  key={f.key}
                                >
                                  {f.display_name}
                                </option>
                              );
                            },
                          )}
                        </StyledSelectSmall>
                      </Td>
                      {/* {item.headers &&
                      item.headers.map(header => {
                        return <Td />;
                      })} */}
                      <Td>
                        {/* <TableInput
                          value={row.unit}
                          onChange={e => onTableChange(e, 'unit', index)}
                        /> */}
                        <StyledSelect
                          style={{ maxWidth: rem(300) }}
                          size="s"
                          onChange={e => onTableChange(e, 'unit', index)}
                        >
                          <option> - </option>
                          {units &&
                            units.map(f => {
                              return (
                                <option
                                  selected={item.unit === f.label}
                                  value={f.label}
                                  key={f.label}
                                >
                                  {f.label}
                                </option>
                              );
                            })}
                        </StyledSelect>
                      </Td>
                    </Tr>
                  );
                })}
                <Tr style={{ border: 0 }}>
                  <Add
                    style={{ width: rem(130), marginTop: rem(10) }}
                    onClick={() => addRow()}
                  >
                    {__('+ Pridať riadok')}
                  </Add>
                </Tr>
              </Tbody>
            </Table>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

ParamDetail.propTypes = {
  item: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      display_name: PropTypes.string,
    }),
  ),
  changeLanguage: PropTypes.func.isRequired,
  activeLanguage: PropTypes.string,
  onTableChange: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  addColl: PropTypes.func.isRequired,
  manageGroups: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  deleteColl: PropTypes.func.isRequired,
  codelists: PropTypes.arrayOf({}),
  units: PropTypes.arrayOf({}),
  showGroups: PropTypes.bool,
};

ParamDetail.defaultProps = {
  groups: [],
  activeLanguage: 'SK',
  showGroups: true,
  codelists: [],
  units: [],
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ParamDetail);
