import { __ } from '../Utils';

export default [
  {
    key: 1,
    value: 'TVT 4000',
    display_name: __('TVT 4000'),
  },
  {
    key: 2,
    value: 'TVT 4001',
    display_name: __('TVT 4001'),
  },
  {
    key: 3,
    value: 'TVT 4002',
    display_name: __('TVT 4002'),
  },
  {
    key: 4,
    value: 'TVT 4003',
    display_name: __('TVT 4003'),
  },
  {
    key: 5,
    value: 'TVT 4004',
    display_name: __('TVT 4004'),
  },
  {
    key: 6,
    value: 'TVT 4005',
    display_name: __('TVT 4005'),
  },
  {
    key: 7,
    value: 'TVT 4006',
    display_name: __('TVT 4006'),
  },
  {
    key: 8,
    value: 'TVT 4007',
    display_name: __('TVT 4007'),
  },
  {
    key: 9,
    value: 'TVT 4008',
    display_name: __('TVT 4008'),
  },
  {
    key: 10,
    value: 'TVT 4009',
    display_name: __('TVT 4009'),
  },
  {
    key: 11,
    value: 'TVT 4010',
    display_name: __('TVT 4010'),
  },
  {
    key: 12,
    value: 'TVT 4011',
    display_name: __('TVT 4011'),
  },
  {
    key: 13,
    value: 'TVT 4012',
    display_name: __('TVT 4012'),
  },
  {
    key: 14,
    value: 'TVT 4013',
    display_name: __('TVT 4013'),
  },
  {
    key: 15,
    value: 'TVT 4014',
    display_name: __('TVT 4014'),
  },
  {
    key: 16,
    value: 'TVT 4015',
    display_name: __('TVT 4015'),
  },
  {
    key: 17,
    value: 'TVT 4016',
    display_name: __('TVT 4016'),
  },
];
