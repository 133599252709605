import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'oxyrion-ui/lib';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import { __ } from '../../Utils';
import AdminAPI from '../../AdminAPI';
import { LoaderWrapper } from '../../Components/ReusableComponents';

const Wrapper = styled.div``;

const ScrollWrapper = styled.div`
  overflow: auto;
`;

const ListRow = styled(Tr)`
  border-bottom: 1px solid #cacaca;
  cursor: pointer;
  :hover {
    background: #f1eeee;
  }
`;

class MarketingDetailedList extends Component {
  state = {
    marketingLists: [],
    limit: 100,
    offset: 0,
    loading: false,
    types: [],
  };

  async componentDidMount() {
    this.fetchMarketingListContacts();

    const result = await AdminAPI.getCodelist('typy_marketingoveho_zoznamu');

    this.setState({
      types: result.codelist || [],
    });
  }

  fetchMarketingListContacts() {
    const { marketingLists, limit, offset } = this.state;
    const { firm } = this.props;

    try {
      AdminAPI.getMarketingListsAction({
        limit,
        offset,
        firm,
      }).then(res => {
        if (!res.items) {
          this.setState({
            marketingLists: [],
            hasMoreItems: false,
          });
        } else {
          this.setState({
            marketingLists: marketingLists.concat(res.items),
            hasMoreItems: res.items.length === limit,
            offset: res.next_offset,
          });
        }
      });
    } catch (e) {
      this.setState({
        error: __('Kontakty marketingového zoznamu sa nepodarilo načítať'),
      });
    }
  }

  render() {
    const { marketingLists, loading, types } = this.state;
    const { onListSelect } = this.props;

    return (
      <Wrapper>
        <ScrollWrapper id="scrollableDiv">
          <InfiniteScroll
            dataLength={marketingLists.length}
            next={() => this.fetchMarketingListContacts()}
            height={window.innerHeight - 100}
            scrollThreshold={0.8}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            hasMore={this.state.hasMoreItems}
            loader={
              <LoaderWrapper>
                <Loader size="xl" />
              </LoaderWrapper>
            }
            scrollableTarget="scrollableDiv"
          >
            {!marketingLists.length && loading && (
              <LoaderWrapper>
                <Loader size="xl" />
              </LoaderWrapper>
            )}
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Názov')}</Th>
                  <Th>{__('Typy zákazníkov')}</Th>
                  <Th>{__('Podtypy zákazníkov')}</Th>
                  <Th>{__('Typ')}</Th>
                  <Th>{__('Kategória segmentu')}</Th>
                  <Th>{__('Skupina zákazníkov')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {marketingLists.map(c => {
                  return (
                    <ListRow
                      onClick={() =>
                        onListSelect({
                          label: c.name,
                          value: c._id,
                        })
                      }
                    >
                      <Td> {c.name}</Td>
                      <Td> {c.customer_types.join(', ')}</Td>
                      <Td>{c.customer_subTypes.join(', ')}</Td>
                      <Td>
                        {types.find(t => t.value === c.type)
                          ? types.find(t => t.value === c.type).label
                          : c.type}
                      </Td>
                      <Td> {c.segments.join(', ')}</Td>
                      <Td>
                        {c.groups
                          .map(g => {
                            if (g === 'actuals') {
                              return __('Aktuálni');
                            }

                            return __('Potenciálni');
                          })
                          .join(', ')}
                      </Td>
                    </ListRow>
                  );
                })}
              </Tbody>
            </Table>
          </InfiniteScroll>
        </ScrollWrapper>
      </Wrapper>
    );
  }
}

MarketingDetailedList.propTypes = {
  firm: PropTypes.string,
  onListSelect: PropTypes.func,
};

MarketingDetailedList.defaultProps = {
  firm: 'SK',
  onListSelect: () => {},
};

export default MarketingDetailedList;
