import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
// import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/notifications';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';

import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';

class Inspirations extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('xPaint notifikácie');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'published_from',
      'published_to',
      'published_for',
      'updated_date',
      'created_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      states: [],
      lastSearch: '',
      types: [],
    };
  }

  handleItemClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/eshop-notifications/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, lastSearch, selectedState } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedState && selectedState.value) {
        params.state = selectedState.value;
      }

      const newData = await AdminAPI.getNotificationsAction(params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(
            `./eshop-notifications/${item._id}`,
            `${item.name || '-'}`,
          ),
          () => this.handleItemClick(item._id),
        ),
        published_from: _shapeData(
          formatDate(moment(item.published_from), FORMAT_HUMANE_DATETIME),
        ),
        published_to: _shapeData(
          formatDate(moment(item.published_to), FORMAT_HUMANE_DATETIME),
        ),
        published_for: _shapeData(item.published_for),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Notifikácie')}
      >
        <ControlBarButton
          primary
          small
          onClick={() => this.handleItemClick('new')}
          icon="plus"
        >
          {__('Pridať notifikáciu')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Inspirations);
