import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Input, Icon, Button, Message } from 'oxyrion-ui/lib';
import { connect } from '../../../Store';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../../Components/ReusableComponents';
import AdminAPI from '../../../AdminAPI';
import { __ } from '../../../Utils';
import PopUpWrapper from '../../../Components/PopUpWrapper';
import ConfirmDialog from '../../../Components/ConfirmDialog';

const MainWrapper = styled.div`
  padding-bottom: ${rem(100)};
`;

const SaveButtonWrapper = styled.div`
  margin-left: ${rem(32)};
  width: ${rem(220)};
  height: ${rem(38)};
  display: flex;
  justify-content: space-between;
`;

const PropertiesWrapper = styled.div`
  margin: ${rem(12)};
  width: ${rem(800)};
`;

const PropertyRow = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(24)};
  width: ${rem(50)};
`;

const ButtonWrapper = styled.div`
  margin-left: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditButton = styled.div`
  width: ${rem(32)};
  margin-left: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CategoryButton = styled.div`
  margin-left: ${rem(4)};
  width: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

class ProductsProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      properties: [],
      propertyToEditId: '',
      newInnovatintValue: '',
      newOxyrionValue: '',
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const result = await AdminAPI.getInnovatintShadePropertiesAction({});

      this.setState({
        properties: result.items || [],
        loading: false,
        deleteLoading: false,
        propertyToDeleteId: '',
        success: false,
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo načítať'),
      });
    }
  }

  async saveProperty(id) {
    const { properties } = this.state;

    const propertyToSave = properties.find(p => p._id === id);

    try {
      const params = {};
      params.body = {
        innovatint_value: propertyToSave.innovatint_value,
        oxyrion_value: propertyToSave.oxyrion_value,
      };

      await AdminAPI.putInnovatintShadePropertyAction(id, params);

      this.setState({
        saveLoading: false,
        propertyToEditId: '',
        success: __('Dáta boli uložené'),
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  async addNewProperty(newInnovatintValue, newOxyrionValue) {
    const { properties } = this.state;
    this.setState({
      saveLoading: true,
    });
    try {
      const params = {};
      params.body = {
        innovatint_value: newInnovatintValue,
        oxyrion_value: newOxyrionValue,
      };

      const result = await AdminAPI.postInnovatintShadePropertyAction(params);

      properties.push(result);

      this.setState({
        saveLoading: false,
        success: __('Dáta boli uložené'),
        properties,
        newInnovatintValue: '',
        newOxyrionValue: '',
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  async deleteProperty() {
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteInnovatintShadePropertyAction(
        this.state.propertyToDeleteId,
      );
      this.fetchData();
    } catch (e) {
      this.setState({
        deleteError: __('Kategóriu sa nepodarilo odstrániť'),
        deleteLoading: false,
      });
    }
  }

  handleEditChange(id) {
    this.setState({
      propertyToEditId: id,
    });
  }

  handleValueChange(field, value, id) {
    const { properties } = this.state;

    properties.find(p => p._id === id)[field] = value;

    this.setState({
      properties,
      success: false,
      error: false,
    });
  }

  render() {
    const {
      loading,
      properties,
      error,
      success,
      newInnovatintValue,
      newOxyrionValue,
      saveLoading,
      propertyToEditId,
      propertyToDeleteId,
      deleteError,
      deleteLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <MainWrapper>
        <PopUpWrapper
          display={propertyToDeleteId}
          small
          onClose={() => this.setState({ propertyToDeleteId: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť vlastnosť ?')}
            onDismiss={() => this.setState({ propertyToDeleteId: false })}
            onConfirm={() => this.deleteProperty()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <PropertiesWrapper>
          {properties.map(p => (
            <PropertyRow key={p._id}>
              <Input
                value={p.innovatint_value}
                disabled={p._id !== propertyToEditId}
                onChange={e =>
                  this.handleValueChange(
                    'innovatint_value',
                    e.target.value,
                    p._id,
                  )
                }
              />
              <IconWrapper>
                <Icon name="arrow-right" />
              </IconWrapper>
              <Input
                value={p.oxyrion_value}
                disabled={p._id !== propertyToEditId}
                onChange={e =>
                  this.handleValueChange('oxyrion_value', e.target.value, p._id)
                }
              />
              {propertyToEditId.length === 0 ? (
                <EditButton onClick={() => this.handleEditChange(p._id)}>
                  <Icon name="edit" size="m" />
                </EditButton>
              ) : (
                <div />
              )}

              {propertyToEditId.length === 0 && (
                <CategoryButton
                  onClick={() =>
                    this.setState({
                      propertyToDeleteId: p._id,
                    })
                  }
                >
                  <Icon name="delete" size="l" color="red" />
                </CategoryButton>
              )}
              {propertyToEditId === p._id ? (
                <SaveButtonWrapper>
                  <Button
                    onClick={() => this.saveProperty(p._id)}
                    small
                    loading={saveLoading}
                    primary
                  >
                    {__('Uložiť')}
                  </Button>

                  <Button
                    onClick={() =>
                      this.setState({
                        propertyToEditId: '',
                      })
                    }
                    small
                    loading={saveLoading}
                    danger
                  >
                    {__('Zrušiť')}
                  </Button>
                </SaveButtonWrapper>
              ) : (
                <div />
              )}
            </PropertyRow>
          ))}

          <PropertyRow>
            <Input
              value={newInnovatintValue}
              placeholder={__('Nová vlastnosť')}
              onChange={e =>
                this.setState({
                  newInnovatintValue: e.target.value,
                  success: false,
                  error: false,
                })
              }
            />
            <IconWrapper>
              <Icon name="arrow-right" />
            </IconWrapper>
            <Input
              value={newOxyrionValue}
              placeholder={__('Oxyrion preklad')}
              onChange={e =>
                this.setState({
                  newOxyrionValue: e.target.value,
                  success: false,
                  error: false,
                })
              }
            />
            <ButtonWrapper>
              <Button
                onClick={() =>
                  this.addNewProperty(newInnovatintValue, newOxyrionValue)
                }
                small
                primary
                loading={saveLoading}
                disabled={!newInnovatintValue.length || !newOxyrionValue.length}
              >
                {__('Pridať')}
              </Button>
            </ButtonWrapper>
          </PropertyRow>
        </PropertiesWrapper>
      </MainWrapper>
    );
  }
}

ProductsProperties.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductsProperties);
