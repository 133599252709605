import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CashRegistersList from '../../../Containers/CashRegistersList';
import CashRegisterDetail from '../../../Containers/CashRegisterDetail';

function CashRegistersCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={CashRegisterDetail} />
      <Route path={`${match.url}`} component={CashRegistersList} />
    </Switch>
  );
}

CashRegistersCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CashRegistersCard;
