import React from 'react';
import BaseTable from '../BaseTable';
import PropTypes from 'prop-types';
import tableHeaders from '../../ContentConfig/crmNotifications';
import { connect } from '../../Store';
import {
  __,
  FORMAT_HUMANE_DATETIME,
  formatDate,
  renderLink,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';
import moment from 'moment';

class CRMNotificationsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('CRM Notifikácie');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'send_date',
      'published_country',
      'users',
      'number_of_views',
      'created_date',
      'owner',
    ];
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearch: '',
      updatedItems: {
        deleted_items: [],
        added_items: [],
      },
    };

    this.handleNotificationOnClick = this.handleNotificationOnClick.bind(this);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, selectedFilter } = this.state;
      const { firm } = this.props;

      this.setState({ loading: true });

      const params = Object.assign(
        this.buildParamsByFilter(selectedFilter),
        parameters,
        { sorter },
      );

      const res = await API2.getNotificationsAction(firm, params);

      const content = this.normalizeColumns(res.items);

      this.setState({
        content,
        loading: false,
        limit: res.limit,
        offset: res.offset,
        total: res.total,
        nextOffset: res.next_offset,
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  }

  handleNotificationOnClick(id) {
    const { history, firm } = this.props;

    history.push(`/${firm}/crm-notifications/${id}`);
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(`./crm-notifications/${item._id}`, `${item.name}`),
          () => this.handleNotificationOnClick(item._id),
        ),
        type: _shapeData(item.type),
        send_date: _shapeData(
          formatDate(moment(item.send_date), FORMAT_HUMANE_DATETIME),
        ),
        published_country: _shapeData(item.published_country),
        users: _shapeData(
          item.users === 'all' ? __('Všetci') : item.users,
        ),
        number_of_views: _shapeData(item.number_of_views),
        owner: _shapeData(item.owner.display_name),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  handleItemClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/crm-notifications/${id}`);
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('CRM Notifikácie')}>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleNotificationOnClick('new')}
          icon="plus"
        >
          {__('Vytvoriť notifikáciu')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

CRMNotificationsList.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CRMNotificationsList);
