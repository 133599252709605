import React from 'react';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/employers';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';
import styled from 'styled-components';
import { rem } from 'polished';

const LANGS = ['Všetky', 'SK', 'CZ'];

const BarTitle = styled.div`
  margin-right: ${rem(12)};
`;

class EmployersList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: null });
    this.headers = tableHeaders;
    this.showBulkOperations = false;
    this.segmentName = __('Zamestnanci');
    this.accessors = [
      'name',
      'surname',
      'role',
      'email',
      'phone',
      'created_date',
      'updated_date',
    ];
    this.setSorter(null);
    this.state = {
      ...this.state,
      lastSearch: '',
      activeCountry: 'Všetky',
      showAlert: true,
      offset: 0,
      defaultSorter: null,
    };
  }

  setSorter() {}

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, lastSearch, activeCountry } = this.state;
      const { firm } = this.props;

      const params = Object.assign({}, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearch;
      }
      if (activeCountry && activeCountry !== 'Všetky') {
        params.publish = activeCountry;
      }

      const newData = await AdminAPI.getEmployersAction(firm, params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  handleRedirectToDetail(id) {
    const { history, firm } = this.props;

    history.push(`/${firm}/employers/${id}`);
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(renderLink(`employers/${item._id}`, item.name), () =>
          this.handleRedirectToDetail(item._id),
        ),
        surname: _shapeData(item.surname),
        role: _shapeData(item.role),
        email: _shapeData(item.email),
        phone: _shapeData(item.phone_number),
        created_date:
          item.created_date &&
          _shapeData(formatDate(item.created_date, FORMAT_HUMANE_DATETIME)),
        updated_date:
          item.updated_date &&
          _shapeData(formatDate(item.updated_date, FORMAT_HUMANE_DATETIME)),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleCountryChange(activeCountry) {
    this.setState(
      {
        activeCountry: __(activeCountry),
      },
      () => {
        this.fetchData();
      },
    );
  }

  renderControlBar() {
    const { history } = this.props;
    const { activeCountry } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Zamestnanci')}
        onChange={val => this.loadSearchRrsult(val)}
      >
        <BarTitle>{__('Krajina pôsobenia')}:</BarTitle>
        <ControlButton name={activeCountry}>
          <LinkWrap>
            {LANGS.map(l => (
              <div onClick={e => this.handleCountryChange(e.target.innerHTML)}>
                {l}
              </div>
            ))}
          </LinkWrap>
        </ControlButton>

        <ControlBarButton
          primary
          small
          onClick={() => this.handleRedirectToDetail('new')}
          icon="plus"
        >
          {__('Pridať zamestnanca')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EmployersList);
