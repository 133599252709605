import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Icon from 'oxyrion-ui/lib/Icon';
import Message from 'oxyrion-ui/lib/Message';
import Loader from 'oxyrion-ui/lib/Loader';
import moment from 'moment';
import {
  FlexBoxStart,
  AnimatedFormMessageWrapper,
  H2,
} from '../ReusableComponents';
import { __, FORMAT_HUMANE_DATE } from '../../Utils';

const StyledDropZone = styled(Dropzone)`
  width: 100%;
  min-height: ${rem(130)}
  box-sizing: border-box;
  outline-offset: -5px;
  outline: ${rem(0)} ${({ theme }) => theme.color.primary} solid;
  padding: ${rem(10)} ${rem(15)} ${rem(10)} ${rem(15)};
`;

const LoaderWrapper = styled.div`
display: ${({ display }) => (display ? 'flex' : 'none')}
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
`;

const DropZoneItemContainer = styled(FlexBoxStart)`
  flex-wrap: wrap;
  align-items: baseline;
`;

const DropZoneItem = styled.img`
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: none;
`;

const FullScreenLink = styled.a`
  text-align: center; 
  display: none;
  width: 10%;
  position: absolute;
  top: -2%;
  left: -8%;
  padding: ${rem(2)}
  background-color: ${({ theme }) => theme.separatorColor};
  border: ${({ theme }) => `solid ${rem(1)} ${theme.separatorColor}`};
  border-radius: 50%;
`;

const ContentWrapper = styled.div`
  text-align: center;
  margin: ${rem(20)};
  width: 25%;
  position: relative;
  ${({ theme }) => theme.media.l`
    width: 10%;
  `};
  :hover {
    img {
      opacity: 0.5;
    }
    ${IconWrapper} {
      display: block;
      cursor: pointer;
    }
    ${FullScreenLink} {
      display: flex;
    }
  }
`;

const CustomIcon = styled(Icon)`
  position: absolute;
  // width: ${rem(30)}
  left: ${({ left }) => (left ? '-10%' : 'unset')};
  right ${({ left }) => (!left ? '-10%' : 'unset')};
  top: -2%;
  border: ${({ theme }) => `solid ${rem(1)} ${theme.separatorColor}`}
  border-radius: 50%;
  background-color: ${({ theme }) => theme.separatorColor};
  padding: ${rem(2)};
`;

const FileIconWrapper = styled.div`
  svg {
    width: 100px;
    height: 100px;
  }
`;

const Name = styled.p`
  display: block;
  word-wrap: break-word;
`;

const SettingsPanel = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const AddImageWrapper = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #4e4e4e;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  cursor: pointer;
`;

class FileInput extends React.Component {
  removeItem(e, file) {
    e.stopPropagation();
    const { removeItem } = this.props;
    removeItem(file);
  }

  itemOnClick(e, file) {
    e.stopPropagation();
    const { onClick } = this.props;
    onClick(file);
  }

  groupByDate(files) {
    return files.reduce((acum, item) => {
      const date = moment(item.dateTime).format('DD.MM.YYYY');
      if (Object.keys(acum).includes(date)) {
        acum[date].push(item);
      } else {
        acum[date] = [];
        acum[date].push(item);
      }
      return acum;
    }, {});
  }

  renderFromMimeType(file) {
    const { imagePath, videoPath, defaultPath } = this.props;
    if (file.fullPath) {
      if (file.mimeType.match('image/*')) {
        return (
          <DropZoneItem
            src={
              imagePath
                ? `${imagePath}/${encodeURIComponent(file.fullPath)}`
                : `${defaultPath}/${encodeURIComponent(file.fullPath)}`
            }
            onClick={e => this.itemOnClick(e, file)}
          />
        );
      }

      if (file.mimeType.match('video/*')) {
        return (
          <video controls width="100%" onClick={e => e.stopPropagation()}>
            <source
              src={
                videoPath
                  ? `${videoPath}/${encodeURIComponent(file.fullPath)}`
                  : `${defaultPath}/${encodeURIComponent(file.fullPath)}`
              }
              type="video/mp4"
            />
            <source
              src={
                videoPath
                  ? `${videoPath}/${encodeURIComponent(file.fullPath)}`
                  : `${defaultPath}/${encodeURIComponent(file.fullPath)}`
              }
              type="video/ogg"
            />
            {__('Tento prehliadač nepodporuje prehrávanie videa.')}
          </video>
        );
      }
    }

    return (
      <FileIconWrapper onClick={e => this.itemOnClick(e, file)}>
        <Icon name="file" siezr="m" />
      </FileIconWrapper>
    );
  }

  render() {
    const {
      onDrop,
      files,
      maxSize,
      error,
      message,
      hideMessage,
      acceptedFiles,
      settingsElement,
      disabled,
      showDate,
    } = this.props;
    const groupedByDate = this.groupByDate(files);
    const dates = Object.keys(groupedByDate).sort((a, b) =>
      moment(a, FORMAT_HUMANE_DATE).isBefore(moment(b, FORMAT_HUMANE_DATE)),
    );

    return (
      <StyledDropZone
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
        activeStyle={{
          outlineWidth: '5px',
        }}
        disabled={disabled}
        maxSize={maxSize}
        accept={acceptedFiles}
      >
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>
        {!files.length && !hideMessage && (
          <AddImageWrapper>{message}</AddImageWrapper>
        )}
        {dates.map(key => (
          <React.Fragment>
            {showDate && <H2>{key}</H2>}
            <DropZoneItemContainer>
              {groupedByDate[key].map((file, index) => (
                <ContentWrapper key={index.toString()}>
                  <LoaderWrapper display={file.loading}>
                    <Loader size="xl" />
                  </LoaderWrapper>
                  {this.renderFromMimeType(file)}
                  <Name>{file.name}</Name>
                  {this.props.removeItem && (
                    <IconWrapper onClick={e => this.removeItem(e, file)}>
                      <CustomIcon name="close" size="m" />
                    </IconWrapper>
                  )}

                  {settingsElement && (
                    <SettingsPanel onClick={e => e.stopPropagation()}>
                      {settingsElement(file.fullPath)}
                    </SettingsPanel>
                  )}

                  {file.mimeType.match('image/*') && (
                    <IconWrapper>
                      <FullScreenLink
                        target="_blank"
                        onClick={e => e.stopPropagation()}
                        href={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${file.fullPath}`}
                      >
                        <Icon name="search" size="m" />
                      </FullScreenLink>
                    </IconWrapper>
                  )}
                </ContentWrapper>
              ))}
            </DropZoneItemContainer>
          </React.Fragment>
        ))}
      </StyledDropZone>
    );
  }
}

FileInput.propTypes = {
  onDrop: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  settingsElement: PropTypes.func,
  onClick: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.object),
  maxSize: PropTypes.number,
  error: PropTypes.string,
  message: PropTypes.string,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  imagePath: PropTypes.string,
  videoPath: PropTypes.string,
  defaultPath: PropTypes.string,
  hideMessage: PropTypes.bool,
  disabled: PropTypes.bool,
  showDate: PropTypes.bool,
};

FileInput.defaultProps = {
  files: [],
  maxSize: 50000000,
  onClick: () => { },
  error: '',
  message: __('Nahrajte dokumenty kliknutím alebo presunutím súborov.'),
  acceptedFiles: null,
  imagePath: null,
  videoPath: null,
  defaultPath: null,
  settingsElement: null,
  hideMessage: false,
  disabled: false,
  showDate: true,
};

export default FileInput;
