import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Toggle, ToggleItem } from 'oxyrion-ui/lib/Toggle';
import Input from 'oxyrion-ui/lib/Input';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Message from 'oxyrion-ui/lib/Message';
import Loader from 'oxyrion-ui/lib/Loader';
import { Button, Label } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import SearchableSelect from '../../Components/SearchableSelect';
import EmailTemplateEditor from '../../Components/Editor/emailTemplateEditor';
import AttachmentsComponent from '../../Components/AttachmentsComponent';
import { LANGS } from '../../ContentConfig/languagesArray';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';

const initData = () => {
  return {
    from: '',
    from_name: '',
    to: '',
    to_name: '',
    cc: '',
    bcc: '',
    subject: '',
    html_data: '',
    json_data: null,
  };
};

class EmailTemplateDetail extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;

    this.state = {
      _id: null,
      newTag: '',
      loading: true,
      emailTemplateGeneralData: {
        template_id: '',
        tags: [],
        name: '',
      },
      currentCopy: null,
      showInfo: true,
      selectedLang: null,
      newData: null,
      enableCopy: false,
      hasUnsaved: false,
      showCopyPopup: false,
      redirectId: '',
    };
  }

  async componentDidMount() {
    const o = {};
    LANGS.forEach((lang) => {
      o[lang] = initData();
    });

    this.setState(
      {
        translations: o,
        currentTranslationData: initData(),
      },
      async () => {
        const emailTemplateId = getIdFromProps(this.props);

        if (emailTemplateId !== 'new') {
          await this.fetchData(emailTemplateId);
        } else {
          this.setState({ loading: false });
        }
      },
    );
  }

  async fetchData(emailTemplateId) {
    try {
      const res = await AdminAPI.getEmailTemplateAction(emailTemplateId);

      const emailTemplateGeneralData = {
        template_id: res.template_id,
        tags: res.tags,
        name: res.name,
      };

      this.setState({
        _id: res._id,
        emailTemplateGeneralData,
        translations: res.translations,
        currentTranslationData: null,
        oldEmailTemplateGeneralData: Object.assign(
          {},
          emailTemplateGeneralData,
        ),
        oldTranslationData: Object.assign({}, res.translations),
        newData: null,
        loading: false,
        error: undefined,
        success: undefined,
      });
    } catch (e) {
      this.setState({
        error: __('Mailovú šablónu sa nepodarilo načítať'),
      });
      console.log(e);
    }
  }

  handleSearchables(e, name) {
    const { emailTemplateGeneralData } = this.state;

    emailTemplateGeneralData[name] = e;
    this.setState({
      emailTemplateGeneralData,
    });
  }

  addNewTag() {
    const { newTag } = this.state;
    const { emailTemplateGeneralData } = this.state;
    const trimmed = newTag.trim();

    if (!emailTemplateGeneralData.tags) {
      emailTemplateGeneralData.tags = [];
    }

    const a = emailTemplateGeneralData.tags;

    if (
      trimmed &&
      !emailTemplateGeneralData.tags.find((t) => t.value === trimmed)
    ) {
      a.push({ value: trimmed, label: trimmed });
      this.handleSearchables(a, 'tags');
      this.setState({ newTag: '' });
    }
  }

  handleGeneralEmailTemplateData(e, field) {
    const { emailTemplateGeneralData } = this.state;
    let { newTag } = this.state;
    if (field === 'name') {
      emailTemplateGeneralData.name = e.target.value;
    } else if (field === 'new_tag') {
      newTag = e.target.value;
      this.setState({ newTag });
    }
    this.setState({
      emailTemplateGeneralData,
      hasUnsaved: true,
    });
  }

  handleLangEmailTemplateDataChange(e, field) {
    const { currentTranslationData } = this.state;

    switch (field) {
      case 'from':
        currentTranslationData.from = e.target.value;
        break;
      case 'from_name':
        currentTranslationData.from_name = e.target.value;
        break;
      case 'to':
        currentTranslationData.to = e.target.value;
        break;
      case 'to_name':
        currentTranslationData.to_name = e.target.value;
        break;
      case 'cc':
        currentTranslationData.cc = e.target.value;
        break;
      case 'bcc':
        currentTranslationData.bcc = e.target.value;
        break;
      case 'subject':
        currentTranslationData.subject = e.target.value;
        break;
      default:
        break;
    }

    this.setState({
      currentTranslationData,
      hasUnsaved: true,
    });
  }

  async saveCurrentJson(json, html) {
    const { translations, selectedLang } = this.state;

    const newTranslations = translations;

    if (json && html) {
      newTranslations[selectedLang].json_data = json;
      newTranslations[selectedLang].html_data = html;
    }

    this.setState(
      {
        translations: newTranslations,
      },
      () => {
        this.saveEmailTemplate();
      },
    );
  }

  async createCopy(json, html) {
    const { _id, showCopyPopup, hasUnsaved } = this.state;

    try {
      if (hasUnsaved) {
        await this.saveCurrentJson(json, html);
      }

      const result = await AdminAPI.postEmailTemplateCopyAction(_id);
      this.setState({
        redirectId: result._id,
        showCopyPopup: !showCopyPopup,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async saveEmailTemplate() {
    const { emailTemplateGeneralData, translations, _id } = this.state;

    const params = {};

    params.body = Object.assign({}, emailTemplateGeneralData, {
      translations,
    });

    try {
      let res = null;

      if (_id) {
        res = await AdminAPI.putEmailTemplateAction(_id, params);
        this.setState({
          emailTemplateGeneralData: {
            template_id: res.template_id,
            tags: res.tags || [],
            name: res.name,
          },
          translations: res.translations,
          success: __('Emailová šablóna úspešne uložená'),
          enableCopy: true,
          hasUnsaved: false,
        });
      } else {
        res = await AdminAPI.postEmailTemplateAction(params);
        this.props.history.push(
          `/${this.props.firm}/email-templates/${res._id}`,
        );
        this.setState({
          _id: res._id,
          success: __('Emailová šablóna úspešne uložená'),
          enableCopy: true,
          hasUnsaved: false,
        });
      }
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    }
  }

  handleLangChange(lang, first) {
    const { translations, selectedLang, currentTranslationData } = this.state;

    if (selectedLang === lang) {
      return;
    }

    const newTranslations = translations;

    newTranslations[selectedLang] = currentTranslationData;

    this.setState({
      translations: newTranslations,
      selectedLang: lang,
      currentTranslationData: translations[lang],
      currentCopy: undefined,
      newData: newTranslations[lang].json_data,
      enableCopy: false,
      success: null,
      hasUnsaved: first,
    });
  }

  copyLangEmailTemplateData() {
    const { translations, currentTranslationData, currentCopy } = this.state;

    const lang = currentCopy;
    const newTranslations = translations;
    newTranslations[currentCopy] = Object.assign({}, currentTranslationData);

    this.setState(
      {
        translations: newTranslations,
        currentCopy: null,
      },
      () => {
        this.handleLangChange(lang, true);
      },
    );
  }

  showError(error) {
    this.setState({ error });
  }

  async deleteFile(fullPath) {
    const { translations, selectedLang } = this.state;

    try {
      const result = await AdminAPI.deleteEmailTemplateAttachmentAction(
        selectedLang,
        getIdFromProps(this.props),
        {
          fullPath: encodeURIComponent(fullPath),
        },
      );

      translations[selectedLang].attachments = result.attachments || [];

      this.setState({
        translations,
      });
    } catch (e) {
      this.setState({
        error: __('Prílohu sa nepodarilo odstrániť'),
      });
    }
  }

  downloadFile(item) {
    const { selectedLang } = this.state;
    return AdminAPI.getEmailTemplateAttachmentDownloadAction(
      selectedLang,
      getIdFromProps(this.props),
      {
        fullPath: item.fullPath,
      },
    );
  }

  getUploadParams = ({ file }) => {
    const { selectedLang } = this.state;
    const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/{companyId}/email-templates/{emailTemplateId}/attachment`;
    const headers = AdminAPI.appendAuthHeaders();
    const formData = new FormData();
    formData.append('file', file);

    let urlFinal = fileUploadURL.replace(
      '{emailTemplateId}',
      getIdFromProps(this.props),
    );

    urlFinal = urlFinal.replace('{companyId}', selectedLang);

    return {
      url: urlFinal,
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };

  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    if (status === 'preparing') {
      this.setState({
        uploading: true,
      });

      window.addEventListener('beforeunload', this.handler);
    }

    if (status === 'done') {
      window.removeEventListener('beforeunload', this.handler);

      const res = await AdminAPI.getEmailTemplateAction(
        getIdFromProps(this.props),
      );

      this.setState(
        {
          translations: res.translations,
        },
        () => remove(),
      );
    }
  };

  renderControlBar() {
    const { history } = this.props;
    const { showInfo } = this.state;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Emailová šablóna')}>
          <ControlBarButton
            onClick={() =>
              this.setState({
                showInfo: !showInfo,
              })
            }
            primary
            small
            icon={showInfo ? 'arrow-top' : 'arrow-down'}
          >
            {showInfo ? __('Skryť detail') : __('Zobraziť detail')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }
  render() {
    const {
      emailTemplateGeneralData,
      nameError,
      error,
      loading,
      success,
      selectedLang,
      showInfo,
      currentTranslationData,
      currentCopy,
      enableCopy,
      hasUnsaved,
      translations,
      showCopyPopup,
      redirectId,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        {error && (
          <ErrorWrapper>
            <Message message={error} error />
          </ErrorWrapper>
        )}
        {success && (
          <ErrorWrapper>
            <Message message={success} />
          </ErrorWrapper>
        )}

        <PopUpWrapper
          display={showCopyPopup}
          small
          onClose={() =>
            this.setState({
              showCopyPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Bola vytvorená kópia šablóny, prajete si ju otvoriť?')}
            onDismiss={() =>
              this.setState({
                showCopyPopup: false,
              })
            }
            onConfirm={() => {
              this.props.history.push(`./${redirectId}`);
              this.setState({
                showCopyPopup: false,
                loading: true,
              });
              window.location.reload();
            }}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={showInfo}>
          <Wrapper>
            <SubWrapper>
              <StyledLabel> {__('Identifikátor')}</StyledLabel>
              <StyledInput
                placeholder={__('Identifikátor')}
                disabled
                onChange={(e) =>
                  this.handleGeneralEmailTemplateData(e, 'template_id')
                }
                value={this.state.emailTemplateGeneralData.template_id}
              />
              <StyledLabel> {__('Názov mailovej šablóny*')}</StyledLabel>
              <StyledInput
                placeholder={__('Zadajte názov')}
                onChange={(e) => this.handleGeneralEmailTemplateData(e, 'name')}
                value={this.state.emailTemplateGeneralData.name}
                error={nameError}
              />

              <StyledLabel> {__('Tagy')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  value={emailTemplateGeneralData.tags}
                  loadOptions={(query) => {
                    return this.searchTags(query);
                  }}
                  keyProps={
                    emailTemplateGeneralData.tags &&
                    emailTemplateGeneralData.tags.length
                  }
                  isMulti
                  placeholder={__('Zvoľte tagy')}
                  handleOnChange={(e) => this.handleSearchables(e, 'tags')}
                />
              </SearchableSelectWrapper>

              <TagWrapper>
                <StyledTagInput
                  placeholder={__('Zadajte nový tag')}
                  onChange={(e) =>
                    this.handleGeneralEmailTemplateData(e, 'new_tag')
                  }
                  value={this.state.newTag}
                />
                <Button primary small onClick={() => this.addNewTag()}>
                  {__('Pridať tag')}
                </Button>
              </TagWrapper>
            </SubWrapper>

            <SubWrapperRight>
              <SubWrapperLang>
                {selectedLang && (
                  <StyledToggle name="type">
                    {LANGS.map((l) => {
                      return (
                        <StyledToggleItem
                          checked={selectedLang === l}
                          value={l}
                          label={__(l)}
                          onClick={() =>
                            hasUnsaved
                              ? window.confirm(
                                  'Máte neuložené zmeny, prajete si ich zahodiť?',
                                ) && window.location.reload()
                              : this.handleLangChange(l, false)
                          }
                        />
                      );
                    })}
                  </StyledToggle>
                )}
              </SubWrapperLang>
              {selectedLang && (
                <SubWrapperLangData>
                  <SubWrapperFull>
                    <StyledLabel> {__('Od (Email)')}</StyledLabel>
                    <StyledInput
                      placeholder={__('from')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'from')
                      }
                      value={currentTranslationData.from}
                      error={nameError}
                    />

                    <StyledLabel> {__('Od (Meno)')}</StyledLabel>
                    <StyledInput
                      placeholder={__('from_name')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'from_name')
                      }
                      value={currentTranslationData.from_name}
                      error={nameError}
                    />

                    <StyledLabel> {__('Pre (Email)')}</StyledLabel>
                    <StyledInput
                      placeholder={__('to')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'to')
                      }
                      value={currentTranslationData.to}
                      error={nameError}
                    />

                    <StyledLabel> {__('Pre (Meno)')}</StyledLabel>
                    <StyledInput
                      placeholder={__('to_name')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'to_name')
                      }
                      value={currentTranslationData.to_name}
                      error={nameError}
                    />
                  </SubWrapperFull>

                  <SubWrapperFull>
                    <StyledLabel> {__('Kópia (cc)')}</StyledLabel>
                    <StyledInput
                      placeholder={__('cc')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'cc')
                      }
                      value={currentTranslationData.cc}
                      error={nameError}
                    />

                    <StyledLabel> {__('Skrytá Kópia (bcc)')}</StyledLabel>
                    <StyledInput
                      placeholder={__('bcc')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'bcc')
                      }
                      value={currentTranslationData.bcc}
                      error={nameError}
                    />

                    <StyledLabel> {__('Predmet')}</StyledLabel>
                    <StyledInput
                      placeholder={__('subject')}
                      onChange={(e) =>
                        this.handleLangEmailTemplateDataChange(e, 'subject')
                      }
                      value={currentTranslationData.subject}
                      error={nameError}
                    />

                    {hasUnsaved && (
                      <CopyWrapper>
                        <div className="unsaved">{__('Neuložené zmeny')}</div>
                      </CopyWrapper>
                    )}

                    {enableCopy && (
                      <CopyWrapper>
                        <select
                          onChange={(e) => {
                            this.setState({ currentCopy: e.target.value });
                          }}
                        >
                          {[
                            <option selected value>
                              -
                            </option>,
                          ].concat(
                            LANGS.map((l) => {
                              if (selectedLang !== l) {
                                return <option value={l}>{l}</option>;
                              }
                              return <div />;
                            }),
                          )}
                        </select>
                        <Button
                          primary
                          small
                          disabled={!LANGS.includes(currentCopy)}
                          onClick={() =>
                            enableCopy && this.copyLangEmailTemplateData()
                          }
                        >
                          {`${__('Kopírovať')}`}
                        </Button>
                      </CopyWrapper>
                    )}
                  </SubWrapperFull>
                </SubWrapperLangData>
              )}
            </SubWrapperRight>
            <SubWrapperAttachments>
              {selectedLang && (
                <AttachmentsComponent
                  attachments={translations[selectedLang].attachments}
                  history={this.props.history}
                  selectedLang=""
                  onFileDelete={(fullPath) => this.deleteFile(fullPath)}
                  onFileDownload={(item) => this.downloadFile(item)}
                  getUploadParams={(e, view) => this.getUploadParams(e, view)}
                  onError={(fileError) => this.showError(fileError)}
                  handleChangeStatus={(e) => this.handleChangeStatus(e)}
                />
              )}
            </SubWrapperAttachments>
          </Wrapper>
        </AnimatedFormMessageWrapper>

        <EmailTemplateEditor
          jsonData={this.state.newData}
          setEditor={(e) => {
            this.editor = e;
          }}
          lang={selectedLang}
          onCopy={(json, html) => this.createCopy(json, html)}
          onSave={(json, html) => this.saveCurrentJson(json, html)}
          onDesignLoad={() => {
            if (selectedLang === null) {
              this.handleLangChange('SK', false);
            }
          }}
          hasUnsaved={(updates) =>
            this.setState({
              hasUnsaved: true,
              enableCopy: false,
              success: null,
            })
          }
        />
      </React.Fragment>
    );
  }
}

const StyledInput = styled(Input)`
  width: ${rem(400)};
`;

const StyledTagInput = styled(Input)`
  width: ${rem(200)};
`;

const ErrorWrapper = styled.div``;

const SearchableSelectWrapper = styled.div`
  width: ${rem(424)};
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(10)};
  justify-content: space-between;
  width: ${rem(424)};
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(42)};
  justify-content: flex-end;
  width: ${rem(424)};

  select {
    margin-right: 8px;
  }

  .unsaved {
    font-size: 14px;
    color: gray;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubWrapperRight = styled(SubWrapper)`
  margin-top: 8px;
  max-width: ${rem(420)};

  ${({ theme }) => theme.media.l`
    margin-left: ${rem(16)};
    max-width: ${rem(900)};
  `}
`;

const SubWrapperAttachments = styled(SubWrapper)`
  margin-top: 8px;
  max-width: ${rem(420)};
  min-width: ${rem(150)};
  width: 100%;

  ${({ theme }) => theme.media.l`
    margin-left: ${rem(52)};
    max-width: ${rem(900)};
  `}
`;

const SubWrapperLang = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SubWrapperLangData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #cccccc;
  padding: 0 16px 16px 16px;
  border-radius: 0 8px 8px 8px;

  ${({ theme }) => theme.media.l`
    flex-direction: row;
  `};
`;

const SubWrapperFull = styled(SubWrapper)`
  width: ${rem(424)};
  margin-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  padding-left: ${rem(8)};
  padding-right: ${rem(8)};
  flex-direction: column;
  margin-bottom: ${rem(12)};
  justify-content: flex-start;
  ${({ theme }) => theme.media.l`
    flex-direction: row;
    // justify-content: space-between
  `};
`;

const StyledLabel = styled(Label)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  min-height: 14px;
`;

const StyledToggle = styled(Toggle)`
  // height: ${rem(40)};
  label {
    padding: 8px 0;
    border: none;
    background-color: #eee;
    min-width: 40px;
  }

  input:checked:not(:disabled) + label {
    background-color: #cccccc;
    box-shadow: none;
    color: #000;
    border-radius: 8px 8px 0 0;
    font-weight: bold;
  }

  margin-top: ${rem(16)};
  ${({ theme }) => theme.media.l`
    margin-top: ${rem(0)};
  `};
`;
const StyledToggleItem = styled(ToggleItem)``;

EmailTemplateDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EmailTemplateDetail);
