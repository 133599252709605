import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import Loader from 'oxyrion-ui/lib/Loader';
import {
  ColumnFlexBox,
  DivRouterLinkWrapper,
  FlexBox,
  AnimatedFormMessageWrapper,
} from '../ReusableComponents';
import Message from 'oxyrion-ui/lib/Message';

const Wrapper = styled(ColumnFlexBox)`
  align-items: baseline;
  justify-content: flex-start;
  margin: ${rem(15)};
  min-height: ${rem(100)};
  // min-width: 15%;
  font-size: ${rem(14)};
`;

const Name = styled.p`
  display: block;
  color: ${({ theme }) => theme.color.primary};
  margin: ${rem(5)};
`;

const LoaderWrapper = styled(FlexBox)`
  width: 100%;
  margin: ${rem(10)} 0;
`;

const ItemWrapper = styled(DivRouterLinkWrapper)`
  justify-content: flex-start;
  font-size: ${rem(11)};
  margin: 0;
  a {
    font-size: unset;
    margin: ${rem(5)};
  }
`;

class GlobalSearchList extends React.PureComponent {
  state = {};
  render() {
    const { data, header, path, loading, error, onItemClick } = this.props;
    return (
      <Wrapper>
        <Name>{header}</Name>
        <AnimatedFormMessageWrapper display={!!error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>
        {loading && (
          <LoaderWrapper>
            <Loader size="xs" />
          </LoaderWrapper>
        )}
        {data.map(item => (
          <ItemWrapper color="black">
            <Link onClick={() => onItemClick()} to={`${path}/${item.id}`}>
              {' '}
              {item.name || '-'}
            </Link>
          </ItemWrapper>
        ))}
      </Wrapper>
    );
  }
}

GlobalSearchList.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  path: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

GlobalSearchList.defaultProps = {
  loading: false,
  error: '',
};

export default GlobalSearchList;
