import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label, Loader, Icon } from 'oxyrion-ui/lib';
import { formatColorToRGB, __ } from '../../Utils';
import SearchableSelect from '../../Components/SearchableSelect';
import { searchCatalogProductShades } from '../../Utils/ApiSearchFunctions';

const Wrapper = styled.div`
  width: ${rem(400)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const ProductsWrapper = styled.div`
  max-height: ${rem(400)};
  overflow: auto;
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const ProductRow = styled(Row)`
  justify-content: space-between;
  margin-left: 8px;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
`;

const ProductName = styled.div`
  font-size: 14px;
  color: #ee6501;
  font-weight: bold;
`;

const ShadesWrapper = styled.div``;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(12)};
  background: #ff4646;
  border-radius: 4px;
`;

const AddWrapper = styled.div`
  cursor: pointer;
  font-size: ${rem(12)};
  font-weight: bold;
  :hover {
    text-decoration: underline;
  }
  border-bottom: 1px solid #6b6b6b;
  padding-bottom: 12px;
  width: 100%;
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const AddHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 4px;
  padding-top: 12px;
  margin-right: 4px;
  margin-bottom: 8px;
`;

const AddLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`;

const SearchableSelectsWrapper = styled.div`
  padding-bottom: 12px;
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const ShadeLabel = styled.div`
  background: ${props => props.color};
  padding: ${rem(8)};
  margin: ${rem(2)}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${rem(200)};
`;

const Shade = styled.div`
  display: flex;
  align-items: center;
`;

const ShadeName = styled.div``;

const CheckIconWrapper = styled.div`
  background: ${props => props.color};
  font-size: ${rem(10)};
  border-radius: 4px;
  padding: ${rem(8)};
  margin-left: ${rem(12)};
  color: white;
  opacity: 0.7;
`;

const ShadeCheckWrapper = styled(AddWrapper)`
  border: 0;
  color: #ee6501;
  display: flex;
`;

class ShadeSelector extends React.PureComponent {
  state = {
    showAddShade: false,
    checkLoading: false,
    productToShadeAdd: null,
  };

  handleProductToAddShade(e) {
    let { productToShadeAdd } = this.state;

    productToShadeAdd = e;

    this.setState({ productToShadeAdd });
  }

  async checkShades() {
    const { checkShades } = this.props;

    this.setState({
      checkLoading: true,
    });

    await checkShades();

    this.setState({
      checkLoading: false,
    });
  }

  renderShadeSelector(productToShadeAdd) {
    const { type, usedProducts, firm, handleShadeSelect } = this.props;

    if (type === 'catalog') {
      return (
        <SearchableSelect
          loadOptions={query =>
            searchCatalogProductShades(
              query,
              productToShadeAdd.value,
              usedProducts.shades
                ? usedProducts.shades.map(s => s.color_code)
                : [],
              firm,
            )
          }
          isClearable={false}
          placeholder={__('Zvoľte odtieň')}
          value={null}
          keyProps={JSON.stringify({
            data: usedProducts.shades,
          })}
          handleOnChange={e => {
            handleShadeSelect(e, productToShadeAdd.value);
          }}
        />
      );
    }

    return (
      <SearchableSelect
        loadOptions={query =>
          searchCatalogProductShades(
            query,
            productToShadeAdd.value,
            usedProducts.find(
              u => u.variant._id === productToShadeAdd.variantId,
            ).shades
              ? usedProducts
                  .find(u => u.variant._id === productToShadeAdd.variantId)
                  .shades.map(s => s.color_code)
              : [],
            firm,
          )
        }
        isClearable={false}
        placeholder={__('Zvoľte odtieň')}
        value={null}
        keyProps={JSON.stringify({
          data: usedProducts.find(
            u => u.variant._id === productToShadeAdd.variantId,
          ).shades,
        })}
        handleOnChange={e => {
          handleShadeSelect(e, productToShadeAdd.variantId);
        }}
      />
    );
  }

  renderShadesRows() {
    const { type, usedProducts, removeShadeFromProduct } = this.props;

    if (type === 'variant') {
      return usedProducts
        .filter(p => p.shades && p.shades.length)
        .map(p => (
          <ProductRow key={p.catalogProduct._id}>
            <ProductName
              style={{
                margin: rem(2),
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {p.variant.name}
            </ProductName>
            <ShadesWrapper>
              {p.shades.map((item, index) => (
                <Shade key={item.color_code}>
                  <ShadeLabel
                    color={item.color && formatColorToRGB(item.color)}
                  >
                    <ShadeName>
                      {item.color_base_name || item.color_code}
                    </ShadeName>
                    <IconWrapper
                      onClick={() =>
                        removeShadeFromProduct(
                          item.color_code,
                          p.variant._id,
                          index,
                        )
                      }
                    >
                      <Icon name="close" color="white" />
                    </IconWrapper>
                  </ShadeLabel>
                  {item.canBeToned === 1 && (
                    <CheckIconWrapper color="green">OK</CheckIconWrapper>
                  )}

                  {item.canBeToned === 0 && (
                    <IconWrapper
                      onClick={() =>
                        removeShadeFromProduct(
                          item.color_code,
                          p.variant._id,
                          index,
                        )
                      }
                      color="#d61515"
                    >
                      <Icon name="close" color="white" size="l" />
                    </IconWrapper>
                  )}
                </Shade>
              ))}
            </ShadesWrapper>
          </ProductRow>
        ));
    }
    return usedProducts
      .filter(p => p.shades && p.shades.length)
      .map(p => (
        <ProductRow key={p._id}>
          <ProductName
            style={{
              margin: rem(2),
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {p.name}
          </ProductName>
          <ShadesWrapper>
            {p.shades.map((item,index) => (
              <Shade key={item.color_code}>
                <ShadeLabel color={item.color && formatColorToRGB(item.color)}>
                  <ShadeName>
                    {item.color_base_name || item.color_code}
                  </ShadeName>
                  <IconWrapper
                    onClick={() =>
                      removeShadeFromProduct(item.color_code, p._id, index)
                    }
                  >
                    <Icon name="close" color="white" />
                  </IconWrapper>
                </ShadeLabel>
                {item.canBeToned === 1 && (
                  <CheckIconWrapper color="green">OK</CheckIconWrapper>
                )}

                {item.canBeToned === 0 && (
                  <IconWrapper
                    onClick={() =>
                      removeShadeFromProduct(item.color_code, p.variant._id, index)
                    }
                    color="#d61515"
                  >
                    <Icon name="close" color="white" size="l" />
                  </IconWrapper>
                )}
              </Shade>
            ))}
          </ShadesWrapper>
        </ProductRow>
      ));
  }

  render() {
    const { label, fetchLocalProducts } = this.props;
    const { showAddShade, checkLoading, productToShadeAdd } = this.state;

    return (
      <Wrapper>
        <StyledLabel>{label}</StyledLabel>
        <ProductsWrapper>{this.renderShadesRows()}</ProductsWrapper>
        <Row>
          {!showAddShade ? (
            <AddWrapper
              onClick={() =>
                this.setState({
                  showAddShade: true,
                })
              }
            >
              {__('+ Pridať odtieň')}
            </AddWrapper>
          ) : (
            <InputWrapper
              style={{
                background: '#dadada',
                padding: rem(4),
              }}
            >
              <AddHeader>
                <AddLabel>{__('Pridať odtieň')}</AddLabel>

                <IconWrapper
                  onClick={() =>
                    this.setState({
                      showAddShade: false,
                      productToShadeAdd: null,
                    })
                  }
                >
                  <Icon name="close" size="l" color="white" />
                </IconWrapper>
              </AddHeader>

              <SearchableSelectsWrapper style={{ border: 0 }}>
                <SearchableSelect
                  value={productToShadeAdd}
                  loadOptions={query => fetchLocalProducts(query)}
                  placeholder={__('Zvoľte produkt')}
                  handleOnChange={e => this.handleProductToAddShade(e)}
                />
                <Space />

                {productToShadeAdd &&
                  this.renderShadeSelector(productToShadeAdd)}
              </SearchableSelectsWrapper>
            </InputWrapper>
          )}
        </Row>
        <Row>
          <ShadeCheckWrapper onClick={() => this.checkShades()}>
            {checkLoading && <Loader />}
            {checkLoading && <Space />}

            {__('Kontrola dostupnosti odtieňov v produktoch')}
          </ShadeCheckWrapper>
        </Row>
      </Wrapper>
    );
  }
}

ShadeSelector.propTypes = {
  usedProducts: PropTypes.arrayOf(PropTypes.shape),
  removeShadeFromProduct: PropTypes.func,
  fetchLocalProducts: PropTypes.func,
  handleShadeSelect: PropTypes.func,
  checkShades: PropTypes.func,
  firm: PropTypes.string,
  label: PropTypes.string,
  type: 'catalog' || 'variant',
};

ShadeSelector.defaultProps = {
  usedProducts: [],
  removeShadeFromProduct: () => {},
  fetchLocalProducts: () => {},
  handleShadeSelect: () => {},
  checkShades: () => {},
  firm: 'SK',
  label: '',
  type: 'variant',
};

export default ShadeSelector;
