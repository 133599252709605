import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Img from 'react-image';
import Dropzone from 'react-dropzone-uploader';
import {
  Button,
  Loader,
  Input,
  Label,
  Radio,
  Textarea,
  Message,
} from 'oxyrion-ui/lib';
import AdminAPI from '../../AdminAPI';
import { __, getIdFromProps, formatBytes } from '../../Utils';
import { connect } from '../../Store';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { LANGS } from '../../ContentConfig/languagesArray';

const acceptedMimeTypesViewImage = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];
const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/shop/{shopId}/image`;

const Wrapper = styled.div``;

const Image = styled(Img)`
  max-width: ${rem(200)};
`;
const ImagesWrapper = styled.div`
  /* width: ${rem(350)}; */
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
`;
const ImageInfo = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;
const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(12)};
`;
const Row = styled.div`
  margin-bottom: ${rem(15)};
  display: flex;
`;
const ImageBox = styled(Row)`
  border-bottom: ${rem(1)} solid #eee;
  padding: ${rem(12)};
`;

const ImageInfoWrapper = styled.div`
  margin-left: ${rem(24)};
`;

const Space = styled.div`
  width: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const ImageDelete = styled.div`
  font-size: 12px;
  color: red;
  display: flex;
  cursor: pointer;
  margin-left: ${rem(8)};
`;

const InputWrapper = styled.div`
  width: ${rem(350)};
`;
const VirtualTourInputWrapper = styled.div`
  max-width: ${rem(800)};
`;
const CoordinatesWrapper = styled.div`
  width: ${rem(350)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DayInputWrapper = styled.div`
  width: ${rem(500)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rem(8)};
`;
const RadioInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const RadioInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
`;
const MainLabel = styled(Label)`
  margin-right: ${rem(10)};
  font-size: ${rem(16)};
`;

const StyledInput = styled(Input)`
  width: ${rem(350)};
`;
const ShortInput = styled(Input)`
  width: ${rem(150)};
  margin-right: ${rem(8)};
`;
const CustomLabel = styled(Label)`
  margin: 0 ${rem(10)} 0 ${rem(5)};
`;
const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1550px) {
    flex-direction: row;
  }
`;
const VirtualTourWrapper = styled.div`
  margin: ${rem(16)};
  max-width: ${rem(800)};
  overflow: scroll;
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(12)};
`;
const Halfer = styled.div`
  width: 100%;
  margin-bottom: ${rem(20)};

  @media only screen and (min-width: 1550px) {
    width: auto;
    margin-right: ${rem(16)};
  }
`;

class EshopShopDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      shop: {
        name: '',
        country: 'SK',
        description: '',
        address: '',
        phone: '',
        navigation: '',
        notice: '',
        virtual_tour: '',
        openning_hours: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
        coordinates: {
          longitude: 0,
          latitude: 0,
        },
      },
      showConfirmDialog: false,
      saveLoading: false,
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);
    if (id === 'new') {
      this.setState({
        loading: false,
      });
    } else {
      await this.fetchShop();
    }
  }

  async onImageUpload({ file }) {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);
    return {
      url: fileUploadURL.replace('{shopId}', this.state.shop._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  }
  async onImageDelete(fullPath) {
    const { shop } = this.state;
    try {
      await AdminAPI.deleteShopImageAction(
        shop._id,
        encodeURIComponent(fullPath),
      );
      shop.images = shop.images.filter(img => img.fullPath !== fullPath);
      this.setState({
        shop,
        success: __('Obrázok sa podarilo úspešne odstrániť'),
      });
    } catch (e) {
      this.setState({
        error: __('Obrázok sa nepodarilo odstrániť'),
      });
    }
  }

  getDayLabel(day) {
    switch (day) {
      case 'monday':
        return 'Pondelok';
      case 'tuesday':
        return 'Utorok';
      case 'wednesday':
        return 'Streda';
      case 'thursday':
        return 'Štvrtok';
      case 'friday':
        return 'Piatok';
      case 'saturday':
        return 'Sobota';
      case 'sunday':
        return 'Nedeľa';
      default:
        return '';
    }
  }
  changeCountry(country) {
    const { shop } = this.state;
    if (shop.country !== country) {
      shop.country = country;
      this.setState({ shop });
    }
  }
  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    const { shop } = this.state;
    if (status === 'done') {
      const { images } = await AdminAPI.getShopImageAction(shop._id);
      shop.images = images;
      this.setState(shop);
    }
  };
  async saveShop() {
    const { shop } = this.state;

    this.setState({ saveLoading: true });
    if (!this.validateTextInputs()) {
      this.setState({
        error: __('Musíte vyplniť všetky polia'),
        saveLoading: false,
      });
      return;
    }

    const params = {};
    params.body = {
      shop,
    };

    let createdShop;

    try {
      if (shop._id) {
        createdShop = await AdminAPI.putShopAction(shop._id, params);
      } else {
        createdShop = await AdminAPI.postShopAction(params);
      }
      const successMessage = shop._id
        ? __('Predajňa úspešne upravená')
        : __('Predajňa úspešne vytvorená');
      this.setState({
        shop: createdShop,
        saveLoading: false,
        error: false,
        success: successMessage,
      });
      this.redirectToShop(createdShop._id);
    } catch (e) {
      this.setState({
        error: __('Predajňu sa nepodarilo vytvoriť'),
        success: false,
        saveLoading: false,
      });
    }
  }
  validateTextInputs() {
    const { shop } = this.state;
    let filled = true;
    const requiredFields = ['name', 'address', 'country', 'description'];
    Object.keys(shop)
      .filter(k => requiredFields.includes(k))
      .map(key => {
        if (!shop[key] || shop[key] === '') {
          filled = false;
        }
        return true;
      });
    Object.keys(shop.openning_hours).map(key => {
      if (!shop.openning_hours[key] || shop.openning_hours[key] === '') {
        filled = false;
      }
      return true;
    });
    Object.keys(shop.coordinates).map(key => {
      if (!shop.coordinates[key] || shop.coordinates[key] === '') {
        filled = false;
      }
      return true;
    });
    return filled;
  }

  redirectToShop(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/eshop-shops/${id}`);
  }
  changeOpenningHours(day, text) {
    const { shop } = this.state;
    shop.openning_hours[day] = text;
    this.setState({ shop });
  }
  async deleteShop() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteShopAction(getIdFromProps(this.props));
      history.push(`/${firm}/eshop-shops`);
    } catch (e) {
      this.setState({
        error: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }
  async fetchShop() {
    const id = getIdFromProps(this.props);
    this.setState({
      loading: true,
    });
    const shop = await AdminAPI.getShopAction(id);

    this.setState({
      shop,
      loading: false,
    });
  }

  changeAttribute(attribute, value) {
    const newShop = Object.assign({}, this.state.shop);
    switch (attribute) {
      case 'name':
        newShop.name = value;
        break;
      case 'phone':
        newShop.phone = value;
        break;
      case 'longitude':
        newShop.coordinates.longitude = value;
        break;
      case 'latitude':
        newShop.coordinates.latitude = value;
        break;
      case 'navigation':
        newShop.navigation = value;
        break;
      case 'notice':
        newShop.notice = value;
        break;
      case 'country':
        newShop.country = value;
        break;
      case 'address':
        newShop.address = value;
        break;
      case 'virtual_tour':
        newShop.virtual_tour = value;
        break;
      case 'description':
        newShop.description = value;
        break;
      default:
        break;
    }
    this.setState({
      shop: newShop,
    });
  }
  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Predajňa')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }
  render() {
    const {
      loading,
      shop,
      deleteLoading,
      saveLoading,
      showConfirmDialog,
      deleteError,
      error,
      success,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <PopUpWrapper
            display={showConfirmDialog}
            small
            onClose={() => this.setState({ showConfirmDialog: false })}
          >
            <ConfirmDialog
              message={__('Naozaj si prajete odstrániť predajňu?')}
              onDismiss={() => this.setState({ showConfirmDialog: false })}
              onConfirm={() => this.deleteShop()}
              error={deleteError}
              loading={deleteLoading}
            />
          </PopUpWrapper>
          <ContentWrapper>
            <Halfer>
              <Row>
                <MainLabel>{__('Základné info o predajni')}</MainLabel>
              </Row>
              <ColumnWrapper>
                <CoordinatesWrapper>
                  <InputWrapper>
                    <StyledLabel>{__('Url cesta predajne')}</StyledLabel>
                    <ShortInput value={shop.shopId && shop.shopId} disabled />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel>{__('123 Kuriér ID')}</StyledLabel>
                    <ShortInput
                      value={shop.courier123SenderId && shop.courier123SenderId}
                      disabled
                    />
                  </InputWrapper>
                </CoordinatesWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Názov predajne*')}</StyledLabel>
                  <StyledInput
                    value={shop.name && shop.name}
                    placeholder={__('Zadajte názov predajne')}
                    onChange={e => this.changeAttribute('name', e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Krajina')}</StyledLabel>

                  <RadioInputsWrapper>
                    {LANGS.map(l => {
                      return (
                        <RadioInputWrapper>
                          <Radio
                            checked={shop && shop.country === l}
                            value={l}
                            name="country"
                            onChange={() => this.changeCountry(l)}
                          />

                          <CustomLabel>{__(l)}</CustomLabel>
                        </RadioInputWrapper>
                      );
                    })}
                  </RadioInputsWrapper>
                  {/* <StyledInput
                    value={shop.country && shop.country}
                    placeholder={__('Krajina predajne')}
                    onChange={e =>
                      this.changeAttribute('country', e.target.value)
                    }
                  /> */}
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Adresa*')}</StyledLabel>
                  <Textarea
                    value={shop.address && shop.address}
                    placeholder={__(
                      'Adresa predajne, vrátane mesta, PSČ a ulice',
                    )}
                    onChange={e =>
                      this.changeAttribute('address', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Telefónne číslo*')}</StyledLabel>
                  <StyledInput
                    value={shop.phone && shop.phone}
                    placeholder={__('Zadajte tel. číslo predajne')}
                    onChange={e =>
                      this.changeAttribute('phone', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Popis*')}</StyledLabel>
                  <Textarea
                    value={shop.description && shop.description}
                    placeholder={__('Popis predajne')}
                    onChange={e =>
                      this.changeAttribute('description', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  {/* <StyledLabel>{__('Koordináty*')}</StyledLabel> */}
                  <CoordinatesWrapper>
                    <InputWrapper>
                      <StyledLabel>{__('Zemepisná šírka*')}</StyledLabel>
                      <ShortInput
                        value={shop.coordinates && shop.coordinates.latitude}
                        placeholder={__('Zadajte zemepisnú šírku')}
                        onChange={e =>
                          this.changeAttribute('latitude', e.target.value)
                        }
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <StyledLabel>{__('Zemepisná dĺžka*')}</StyledLabel>
                      <ShortInput
                        value={shop.coordinates && shop.coordinates.longitude}
                        placeholder={__('Zadajte zemepisnú dĺžku')}
                        onChange={e =>
                          this.changeAttribute('longitude', e.target.value)
                        }
                      />
                    </InputWrapper>
                  </CoordinatesWrapper>
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Odkaz na navigáciu*')}</StyledLabel>
                  <StyledInput
                    value={shop.navigation && shop.navigation}
                    placeholder={__('Link na navigáciu predajne')}
                    onChange={e =>
                      this.changeAttribute('navigation', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Oznam*')}</StyledLabel>
                  <Textarea
                    value={shop.notice && shop.notice}
                    placeholder={__('Oznam')}
                    onChange={e =>
                      this.changeAttribute('notice', e.target.value)
                    }
                  />
                </InputWrapper>
              </ColumnWrapper>

              <Row
                style={{
                  justifyContent: 'space-between',
                  maxWidth: 720,
                  marginBottom: rem(40),
                  marginTop: rem(12),
                }}
              >
                <ColumnWrapper>
                  <Button
                    loading={saveLoading}
                    onClick={() => {
                      this.saveShop();
                    }}
                    primary
                  >
                    {getIdFromProps(this.props) === 'new'
                      ? __('Uložiť')
                      : __('Upraviť')}
                  </Button>
                </ColumnWrapper>
              </Row>
            </Halfer>
            <Halfer style={{ marginTop: rem(12) }} />

            <Halfer
              style={{
                width: '50%',
              }}
            >
              <Row>
                <MainLabel>{__('Otváracie hodiny predajne')}</MainLabel>
              </Row>
              <ColumnWrapper>
                {Object.keys(shop.openning_hours).map(key => (
                  <DayInputWrapper>
                    <StyledLabel>{this.getDayLabel(key)}</StyledLabel>
                    <StyledInput
                      value={shop.openning_hours && shop.openning_hours[key]}
                      placeholder={__('Zadajte otváracie hodiny')}
                      onChange={e =>
                        this.changeOpenningHours(key, e.target.value)
                      }
                    />
                  </DayInputWrapper>
                ))}
              </ColumnWrapper>
              <InputWrapper style={{ width: rem(500) }}>
                {shop && shop.images && (
                  <StyledLabel>{__('Obrázky predajne')}</StyledLabel>
                )}
                <ImageUploadWrapper>
                  <ImagesWrapper>
                    {shop &&
                      shop.images &&
                      shop.images.map(img => (
                        <ImageBox>
                          <Image
                            src={[`${baseURL}/images/250x0/${img.fullPath}`]}
                            loader={<Loader color="#70af00" />}
                          />
                          <ImageInfoWrapper>
                            <Label>{__('Typ súboru')}</Label>
                            <ImageInfo>{img.mimeType}</ImageInfo>
                            <Space />

                            <Label>{__('Veľkosť súboru')}</Label>
                            <ImageInfo>{formatBytes(img.size)}</ImageInfo>
                            <Space />
                            <ImageDelete
                              onClick={() => this.onImageDelete(img.fullPath)}
                            >
                              {__('Odstrániť ')}
                            </ImageDelete>
                          </ImageInfoWrapper>
                        </ImageBox>
                      ))}
                  </ImagesWrapper>
                  {shop && shop._id && (
                    <Dropzone
                      files={shop.images ? [shop.images] : []}
                      getUploadParams={e => this.onImageUpload(e)}
                      onChangeStatus={this.handleChangeStatus}
                      accept={acceptedMimeTypesViewImage.join(',')}
                      inputContent={
                        shop && shop.images
                          ? __('Nahrať iný obrázok')
                          : __('Nahrať obrázok')
                      }
                      canCancel={false}
                      initialFiles={shop.images ? [shop.images] : []}
                      styles={{
                        inputLabel: { color: '#ee6500' },
                      }}
                    />
                  )}
                </ImageUploadWrapper>
              </InputWrapper>
            </Halfer>
            <Halfer
              style={{
                width: '100%',
              }}
            >
              <Row>
                <MainLabel>{__('Virtuálna prehliadka')}</MainLabel>
              </Row>
              {shop.virtual_tour && shop.virtual_tour !== '' && (
                <VirtualTourWrapper
                  dangerouslySetInnerHTML={{ __html: shop.virtual_tour }}
                />
              )}
              <VirtualTourInputWrapper>
                <StyledLabel>{__('Kód virtuálnej prehliadky*')}</StyledLabel>
                <Textarea
                  value={shop.virtual_tour && shop.virtual_tour}
                  placeholder={__('Sem vložte HTML kód virtuálnej prehliadky')}
                  onChange={e =>
                    this.changeAttribute('virtual_tour', e.target.value)
                  }
                />
              </VirtualTourInputWrapper>
            </Halfer>
          </ContentWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

EshopShopDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
EshopShopDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopShopDetail);
