import React from 'react';
import { rem } from 'polished';
// import { Icon } from 'oxyrion-ui/lib';
import { Loader } from 'oxyrion-ui/lib';
import PropTypes from 'prop-types';
import Img from 'react-image';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { __, formatColorToRGB } from '../../../../Utils';
import Variants from '../../Components/VariantsExtended';
// import AddToCartWidget from '../../../../Conmponents/AddToCartWidget';
import { catalogDescTranslate } from '../../CatalogUtils';
import placeholder from '../Gallery/placeholder-image.png';
// import PopUpWrapper from '../../../../Components/PopUpWrapper';
// import Toning from '../../../Toning';
// import SearchableSelect from '../../../../Components/SearchableSelect';
import API2 from '../../../../API2';

const Wrapper = styled.div`
  padding: ${rem(10)};
  margin: ${rem(10)};
  border-radius: ${rem(4)};
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.xl`
  // flex-direction: row;
`};

  background-image: linear-gradient(
    to right,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #f0f0f0
  );
`;

const Name = styled.div`
  margin-bottom: ${rem(6)};
  font-size: ${rem(18)};
  font-weight: bold;
  color: #434245;
`;

const Description = styled.div`
  font-size: ${rem(14)};
  color: #434245;
  font-weight: 300;
  margin-bottom: 16px;
`;

const NameAndDescWrapper = styled.div`
  margin-top: ${rem(12)};
  margin-left: ${rem(8)};
`;

const BottomWrapper = styled(Link)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${rem(16)};
  margin-bottom: ${rem(5)};
  align-items: center;
  /* ${({ theme }) => theme.media.xl`
   justify-content: flex-start;
`}; */
color: #ee6500;
  cursor: pointer;
  text-decoration: underline;
`;

const VariantsWrapper = styled.div`
  width: 100%;
  margin-top: ${rem(12)};
  padding-right: ${rem(8)};
  /* height: ${rem(300)};
  overflow: scroll; */
  z-index: 0;
`;

const Image = styled(Img)`
  height: ${rem(90)};
  max-width: ${rem(160)};
  z-index: 2;
`;

const ImageWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(150)};
`;

const NoVariantsMessage = styled.div`
  text-align: center;
  color: #949191;
`;

const VariantsTitle = styled(Name)``;

const MainInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  transition: all 0.3s linear;
  justify-content: space-between;
`;

// const SelectWrapper = styled.div`
//   width: ${rem(250)};
//   margin-left: ${rem(10)};
// `;

const ImageAndNameWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const ShowMoreVariants = styled.div`
  font-size: ${rem(12)};
  margin-top: ${rem(8)};
  color: #ee6500;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

class CatalogProductCard extends React.PureComponent {
  state = {
    selectedVariantIndex: -1,
    variantToAddId: null,
    editedVariants: undefined, // initialized, when toning is laoded
    selectedColor: undefined,
    selectedColorCode: null,
    variantsCountToShow: 3,
    thinnersCountToShow: 3,
  };
  getColor(text) {
    if (text === 'available') {
      return '#1d70b8';
    } else if (text === 'part-available') {
      return '#E3900A';
    } else if (text === 'not-available') {
      return '#E30A12';
    }

    return 'black';
  }

  async loadPrices(colorId) {
    const { data, firm } = this.props;

    try {
      const colorcard = await API2.getColorcardAction(
        firm,
        colorId.color.colorcard_id,
      );

      const result = await API2.getCatalogProductPricesAction(
        this.props.firm,
        data._id,
        {
          colorCardId: colorId.color.colorcard_id,
          shadeId: colorId.value,
          toningSystemId: colorcard.system_id,
        },
      );

      const variantsWithColor = data.variants.filter(
        v => v.finished_shade === colorId.value,
      );

      this.processDataFromToning({
        prices: (result.prices || []).concat(variantsWithColor),
        shadeProperties: result.shade_properties || [],
        colorants: result.coloraunts || [],
        shadeOfBase: result.shade_of_base || '',
        bases: result.bases || [],
        color: colorId.color,
        availableBases: [],
        selectedColorId: colorId.value,
        selectedColorCardId: colorId.color.colorcard_id,
        selectedToningSystem: colorcard.system_id,
        finished_shade: variantsWithColor.length > 0,
      });
    } catch (e) {
      console.log(e);
    }
  }

  searchColorForSelect(query, colocardsIds) {
    try {
      return API2.getColorauntsAction(this.props.firm, {
        q: query,
        colocardsIds,
      }).then(res => {
        return {
          options: res.items.map(item => ({
            value: item.color_code,
            color: item,
            label: `${item.color_code} ${
              item.color_name ? ` - ${item.color_name}` : ''
            }`,
          })),
        };
      });
    } catch (e) {
      return [];
    }
  }

  fetchShades(query, productId) {
    const { selectedColorCard } = this.state;

    if (!productId) {
      return [];
    }
    try {
      const params = { q: query, limit: 200 };

      if (selectedColorCard && selectedColorCard.value) {
        params.colorcardId = selectedColorCard.value;
      }
      return API2.getCatalogProductShadesAction(
        this.props.firm,
        productId,
        params,
      ).then(res => {
        return {
          options: res.shades.map(item => ({
            value: item.color_code,
            color: item,
            label: `${item.color_base_name || item.color_name}`,
          })),
        };
      });
    } catch (e) {
      return [];
    }
  }

  processDataFromToning(toningData) {
    const { data } = this.props;
    const { selectedColorCode } = this.state;

    const editedVariants = data.variants
      .filter(
        v =>
          toningData.bases.indexOf(v.base) > -1 ||
          (toningData.finished_shade &&
            toningData.finished_shade.length &&
            v.finished_shade === toningData.selectedColorId),
      )
      .map(d => {
        const p = toningData.prices.find(td => td._id === d.variantId);
        if (p) {
          return Object.assign(d, {
            toningPrice: p.price,
            toningPrices: {
              price: p.price,
              price_vat: p.price_vat,
              price_wd: p.price_wd,
              price_wd_vat: p.price_wd_vat,
            },
            finishedShade: d.finished_shade === toningData.selectedColorId,
          });
        }
        return Object.assign(d, {
          toningPrice: __('Hotový odtieň'),
          finishedShade: d.finished_shade === toningData.selectedColorId,
        });
      });

    this.setState({
      editedVariants,
      // showToning: false,
      selectedColor: toningData,
      selectedColorCode: selectedColorCode || {
        value: toningData.selectedColorId,
        color: toningData.color,
        label: `${toningData.color.color_code} ${
          toningData.color.color_name ? ` - ${toningData.color.color_name}` : ''
        }`,
      },
    });
  }

  openAddVariantToCard(id) {
    this.setState({
      variantToAddId: id,
    });
  }

  openAdThinnerToCard(id) {
    this.setState({
      thinnerToAddId: id,
    });
  }

  render() {
    const {
      data,
      // onProductClick,
      cartItemsIds,
      cartItems,
      variantLoading,
      productLink,
    } = this.props;

    const {
      // selectedVariantIndex,
      // variantToAddId,
      thinnerToAddId,
      // showToning,
      editedVariants,
      selectedColor,
      // selectedColorCode,
      // variantsCountToShow,
      thinnersCountToShow,
    } = this.state;

    const variants = editedVariants || data.variants;

    return (
      <Wrapper
        style={{
          border: `solid ${rem(5)} ${
            selectedColor && selectedColor.color
              ? formatColorToRGB(selectedColor.color.color)
              : 'none'
          }`,
        }}
      >
        {/* <AddToCartWidget
          history={this.props.history}
          callback={() => {
            this.setState({
              variantToAddId: undefined,
              thinnerToAddId: undefined,
            });
          }}
          closeCallBack={() =>
            this.setState({
              variantToAddId: null,
              thinnerToAddId: null,
            })
          }
          product={
            normalizeVariants(variants).find(v => v._id === variantToAddId) ||
            (data.thinners && data.thinners.find(t => t._id === thinnerToAddId))
          }
          paretnId={data._id}
          colorCode={selectedColor && selectedColor.selectedColorId}
          toningSystemId={selectedColor && selectedColor.selectedToningSystem}
          colorCardId={selectedColor && selectedColor.selectedColorCardId}
          addProduct={p => {
            this.setState({
              variantToAddId: null,
            });

            return this.props.addProduct(p);
          }}
        /> */}
        {/* <PopUpWrapper
          display={showToning}
          message={__('Tónovanie produktu')}
          onClose={() =>
            this.setState({
              showToning: false,
            })
          }
          onEscClose={() =>
            this.setState({
              showToning: false,
            })
          }
        >
          <ToningWrapper height={window.innerHeight - 100}>
            <Toning
              confirmButtonTitle={__('Vložiť do objednávky')}
              includeSteps={[2, 3]}
              inputProduct={data}
              reload={showToning}
              outputCallback={input => this.processDataFromToning(input)}
            />
          </ToningWrapper>
        </PopUpWrapper> */}

        <MainInfoWrapper>
          <ImageAndNameWrapper>
            <ImageWrapper target="_blank" to={productLink}>
              <Image
                src={[
                  `${baseURL}/images/370x0/${data.image_src}`,
                  `${baseURL}/images/370x0/${data.alt_image_src}`,
                  placeholder,
                ]}
                loader={<Loader color="#1d70b8" />}
              />
            </ImageWrapper>

            <NameAndDescWrapper>
              <Name>{data.name}</Name>
              <Description>
                {catalogDescTranslate(
                  this.props.firm,
                  data.shortDescriptionTranslations,
                  data.shortDescription,
                )}
              </Description>
            </NameAndDescWrapper>
          </ImageAndNameWrapper>
          {/* {data.color_cards.length ? (
            <ShadeWrapper>
              <ShadeSelectWrapper>
                <ToningButtonWrapper
                  onClick={() =>
                    this.setState({
                      showToning: true,
                    })
                  }
                >
                  <Icon name="painting_roller" color="#ee6500" />
                  <div style={{ marginLeft: rem(8) }}>{__('Odtieň')}</div>
                </ToningButtonWrapper>
                <SelectWrapper>
                  <ColorCodeInput
                    placeholder={__('Poznám kód odtieňa')}
                    autoload={false}
                    value={selectedColorCode || null}
                    // onBlur={e => this.searchColor(e.target.value)}
                    // onKeyDown={e => this.handleKeyDown(e)}
                    loadOptions={query => this.fetchShades(query, data._id)}
                    handleOnChange={e => {
                      this.setState(
                        {
                          selectedColorCode: e,
                          editedVariants: !e ? undefined : editedVariants,
                          selectedColor: !e ? undefined : selectedColor,
                        },
                        () => {
                          this.loadPrices(e);
                        },
                      );
                    }}
                  />
                </SelectWrapper>
              </ShadeSelectWrapper>
              {selectedColor && (
                <SelectedColorWrapper>
                  <SelectedColorLabel>
                    {__('Zvolený odtieň: ')}
                  </SelectedColorLabel>
                  <ColorWrapper
                    style={{
                      background: formatColorToRGB(selectedColor.color.color),
                    }}
                  >
                    {selectedColor.selectedColorId}
                  </ColorWrapper>
                </SelectedColorWrapper>
              )}
            </ShadeWrapper>
          ) : (
            <div />
          )} */}
        </MainInfoWrapper>
        <VariantsWrapper>
          {(data.variants && data.variants.length > 0) || variantLoading ? (
            <React.Fragment>
              <Variants
                hasToningPrice={
                  editedVariants && editedVariants.filter(e => e.toningPrice)
                }
                insertVariants={d => this.props.insertVariants(d)}
                multiple={this.props.multiple}
                data={variants}
                loading={variantLoading}
              />
              {/* {variants && variantsCountToShow < variants.length && (
                <ShowMoreVariants
                  onClick={() =>
                    this.setState({
                      variantsCountToShow: variants.length,
                    })
                  }
                >
                  {`${__('Zobraziť všetky varianty')} (${variants.length})`}
                </ShowMoreVariants>
              )} */}
            </React.Fragment>
          ) : (
            <NoVariantsMessage>{__('Neobsahuje varianty')}</NoVariantsMessage>
          )}
          {data.thinners && data.thinners.length > 0 && (
            <React.Fragment>
              <VariantsTitle style={{ marginTop: rem(16), marginBottom: 0 }}>
                {__('Riedidlá:')}
              </VariantsTitle>
              <Variants
                role={this.props.role}
                hasToningPrice={false}
                selectable={false}
                data={
                  data.thinners
                    ? data.thinners.slice(0, thinnersCountToShow)
                    : []
                }
                cartItemsIds={cartItemsIds}
                cartItems={cartItems}
                loading={variantLoading}
                variantLoadingId={thinnerToAddId}
                addToCart={thinnerId => this.openAdThinnerToCard(thinnerId)}
              />
              {thinnersCountToShow < data.thinners.length && (
                <ShowMoreVariants
                  onClick={() =>
                    this.setState({
                      thinnersCountToShow: data.thinners.length,
                    })
                  }
                >
                  {`${__('Zobraziť všetky riedidlá')} (${
                    data.thinners.length
                  })`}
                </ShowMoreVariants>
              )}
            </React.Fragment>
          )}
        </VariantsWrapper>

        <BottomWrapper target="_blank" to={productLink}>
          {__('Detail produktu')}
        </BottomWrapper>
      </Wrapper>
    );
  }
}

CatalogProductCard.propTypes = {
  // onProductClick: PropTypes.func.isRequired,
  // updateCartItems: PropTypes.func,
  productLink: PropTypes.string,
  // addProduct: PropTypes.func,
  settings: PropTypes.shape({}),
  cartItemsIds: PropTypes.arrayOf(PropTypes.shape()),
  role: PropTypes.string,
  cartItems: PropTypes.arrayOf(PropTypes.shape()),
  variantLoading: PropTypes.string,
  insertVariants: PropTypes.func,
  data: PropTypes.shape({
    image_src: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    shortDescriptionTranslations: PropTypes.arrayOf,
    availability: PropTypes.string, // available, part-available, not-available
    variants_count: PropTypes.number,
    price_from: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
  }).isRequired,

  firm: PropTypes.string,
};

CatalogProductCard.defaultProps = {
  firm: 'SK',
  productLink: '',
  cartItemsIds: [],
  cartItems: [],
  settings: {},
  insertVariants: () => {},
  // updateCartItems: () => {},
  // addProduct: () => {},
  variantLoading: false,
  role: '',
};

export default CatalogProductCard;
