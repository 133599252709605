import React from 'react';
import BaseTable from '../BaseTable';
import {
  __,
  FORMAT_HUMANE_DATETIME,
  formatDate,
  renderLink,
} from '../../Utils';
import tableHeaders from '../../ContentConfig/pdfTemplates';
import ControllBar from '../../Components/ControllBar';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { LANGS } from '../../ContentConfig/languagesArray';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { connect } from '../../Store';
import AdminAPI from '../../AdminAPI';

class PDFTemplateList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.showBulkOperations = false;
    this.segmentName = __('PDF šablóny');
    this.accessors = [
      'name',
      'template_id',
      'created_date',
      'updated_date',
      'tags',
    ];
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearch: '',
      activeCountry: 'SK',
      showAlert: true,
    };
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, lastSearch } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      const newData = await AdminAPI.getPdfTemplatesAction(params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  handleTemplatesOnClick(id) {
    const { history, firm } = this.props;
    const { activeCountry } = this.state;

    history.push(`/${firm}/pdf-templates/${id}?lang=${activeCountry}`);
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `pdf-templates/${item._id}?lang=${this.state.activeCountry}`,
            item.name,
          ),
          () => this.handleTemplatesOnClick(item._id),
        ),
        template_id: _shapeData(item.template_id),
        created_date: _shapeData(
          formatDate(item.created_date, FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(item.updated_date, FORMAT_HUMANE_DATETIME),
        ),
        tags: _shapeData(
          item.tags &&
            item.tags.map((t, index) => {
              if (index !== 0) {
                return `,${t.label}`;
              }
              return `${t.label}`;
            }),
        ),
        sender: _shapeData(item.sender),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleCountryChange(activeCountry) {
    this.setState(
      {
        activeCountry: __(activeCountry),
      },
      () => {
        this.fetchData();
      },
    );
  }

  renderControlBar() {
    const { history } = this.props;
    const { activeCountry } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('PDF šablóny')}
        onChange={(val) => this.loadSearchResult(val)}
      >
        <ControlButton name={activeCountry}>
          <LinkWrap>
            {LANGS.map((l) => (
              <div
                onClick={(e) => this.handleCountryChange(e.target.innerHTML)}
              >
                {l}
              </div>
            ))}
          </LinkWrap>
        </ControlButton>

        <ControlBarButton
          primary
          small
          onClick={() => this.handleTemplatesOnClick('new')}
          icon="plus"
        >
          {__('Nová šablóna')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PDFTemplateList);
