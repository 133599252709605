import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Publikovaný od'),
    clickable: true,
    sorterValue: 'published_from',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Publikovanný do'),
    clickable: true,
    sorterValue: 'published_to',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Počet produktov'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Dátum aktualizácie'),
    clickable: true,
    sorterValue: 'updated_date',
    otherProps: { minWidth: 250 },
  },
];
