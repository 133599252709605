import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Icon,
  Select,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';

import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';

const Wrapper = styled.div`
  padding: ${rem(10)};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: ${rem(200)};
`;

const Row = styled.div`
  display: flex;
  height: ${rem(50)};
  justify-content: space-between;
  align-items: center;
  width: ${rem(400)};
`;

const AddNew = styled.div`
  cursor: pointer;
  margin-left: ${rem(10)};
  color: #555555;
  font-weight: bold;
`;

const Delete = styled.div`
  cursor: pointer;
  color: red;
  margin-left: ${rem(30)};
  height: ${rem(32)};
  width: ${rem(32)};
  border-radius: ${rem(2)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e94140;
`;

class CustomerTypeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      loading: true,
      data: {
        name: '',
        type: '',
        subtypes: [],
        description: '',
      },
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      await this.fetchCustomerTypeData(id);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchCustomerTypeData(id) {
    try {
      const result = await AdminAPI.getCustomerTypeAction(id);

      this.setState({
        data: result,
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Typ zákazníka sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  handleMainChange(e, type) {
    const { data } = this.state;

    if (!data[type]) {
      data[type] = '';
    }

    data[type] = e.target.value;

    this.setState({
      data,
    });
  }

  handleSubtypeChange(value, index) {
    const { data } = this.state;

    data.subtypes[index] = value;

    this.setState({
      data,
    });
  }

  async saveMain() {
    const { data } = this.state;
    if (data.name.length < 1) {
      this.setState({
        typeNameError: __('Názov nesmie byť prázdny'),
      });
    } else {
      const params = {};
      const id = getIdFromProps(this.props);
      try {
        if (id === 'new') {
          params.body = data;
          const result = await AdminAPI.postCustomerTypeAction(params);
          this.props.history.push(
            `/${this.props.firm}/customerTypes/${result._id}`,
          );
        } else {
          params.body = data;
          await AdminAPI.putCustomerTypeAction(id, params);
        }
        this.setState({
          success: __('Typ zákazníka uložený'),
        });
      } catch (e) {
        this.setState({
          error: __('Typ zákazníka sa nepodarilo uložiť'),
        });
      }
    }
  }

  async deleteType() {
    this.setState({
      loading: true,
      showDeleteAllTypeDialog: false,
    });
    try {
      await AdminAPI.deleteCustomerTypeAction(getIdFromProps(this.props));
      this.props.history.push(`/${this.props.firm}/customerTypes`);
    } catch (e) {
      this.setState({
        error: __('Pri mazaní nastala chyba'),
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  addSubtype() {
    const { data } = this.state;

    if (!data.subtypes.length) {
      data.subtypes = [];
    }

    data.subtypes.push('');

    this.setState({
      data,
    });
  }

  deleteSubtype(index) {
    const { data } = this.state;

    data.subtypes.splice(index, 1);

    this.setState({
      data,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;
    return (
      <ControllBar history={history} name={data.name || ''}>
        <ControlBarButton
          danger
          small
          onClick={() =>
            this.setState({
              showDeleteAllTypeDialog: true,
            })
          }
          icon="trash"
        >
          {__('Odstrániť typ')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      loading,
      typeNameError,
      deleteLoading,
      deleteError,
      showDeleteAllTypeDialog,
      data,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showDeleteAllTypeDialog}
          small
          onClose={() =>
            this.setState({
              showDeleteAllTypeDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj si želáte odstrániť tento typ? Zmena môže narušiť existujúcu funkcionalitu',
            )}
            onDismiss={() =>
              this.setState({
                showDeleteAllTypeDialog: false,
                showDeleteDialog: false,
              })
            }
            onConfirm={() => this.deleteType()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={typeNameError}>
          <Message error message={typeNameError} />
        </AnimatedFormMessageWrapper>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <Wrapper>
          <Label> {__('Názov typu*')}</Label>
          <Row>
            <Input
              placeholder={__('Zadajte názov')}
              onChange={e => this.handleMainChange(e, 'name')}
              value={data.name || ''}
              error={typeNameError}
            />
          </Row>

          <Label> {__('Typ')}</Label>
          <Row>
            <Select
              placeholder={__('Zvoľte typ')}
              onChange={e => this.handleMainChange(e, 'type')}
              value={data.type}
              size="s"
            >
              <option>-</option>
              <option value="company">company</option>
              <option value="person">person</option>
            </Select>
          </Row>

          <Label> {__('Podtypy')}</Label>
          {data.subtypes &&
            data.subtypes.map((s, index) => (
              <Row>
                <InputWrapper>
                  <Input
                    placeholder={__('Podtyp')}
                    onChange={e =>
                      this.handleSubtypeChange(e.target.value, index)
                    }
                    value={s}
                    error={typeNameError}
                  />
                  <Delete onClick={() => this.deleteSubtype(index)}>
                    <Icon color="white" name="delete" />
                  </Delete>
                </InputWrapper>
              </Row>
            ))}

          <Row>
            <InputWrapper>
              <AddNew onClick={() => this.addSubtype()}>
                {__('+ Pridať')}
              </AddNew>
            </InputWrapper>
          </Row>

          <ButtonRelativeWrapper>
            <Button
              onClick={() => this.saveMain()}
              primary
              loading={saveLoading}
            >
              {__('Uložiť')}
            </Button>
          </ButtonRelativeWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

CustomerTypeDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

CustomerTypeDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CustomerTypeDetail);
