import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import 'react-table/react-table.css';
import Catalog from './Catalog';
import CatalogSettings from './CatalogSettings';
import Settings from './Settings';
import UserMenu from './UserMenu';
import Marketing from './Marketing';
import Prices from './Prices';
import NavBar from '../Containers/NavBar';
import navbarContent from '../ContentConfig/navbar';
import StoreSynchorizer from './StoreSynchorizer';
import History from '../Containers/History';
import EshopSettings from './EshopSettings';
import Synchronizations from './System';
import CashRegisters from './CashRegisters';
import Dejmark from './Dejmark';

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      mounted: false,
    };
  }
  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentDidUpdate(newProps) {
    const { mounted, redirect } = this.state;
    if (newProps.firm !== this.props.firm && !mounted) {
      this.setState({ redirect: true });
    } else if (redirect === true) {
      this.setState({ redirect: false });
    } else if (mounted) {
      this.setState({ mounted: false });
    }
  }
  render() {
    const { year, firm, user, settings, userHistory } = this.props;

    const defaultRedirect = settings.startPageAdmin
      ? `/${firm}${settings.startPageAdmin}`
      : `/${firm}/products`;
    return (
      <Switch>
        {this.state.redirect ? <Redirect to={defaultRedirect} /> : ''}
        <Route
          path="/:firm"
          render={({ match, history }) => {
            return match.params.firm === 'SK' || match.params.firm === 'CZ' ? (
              <StoreSynchorizer firm={match.params.firm} year={year}>
                <NavBar
                  content={navbarContent}
                  user={user}
                  settings={settings}
                  firm={match.params.firm}
                  match={match}
                  history={userHistory}
                  year={year}
                />
                <CashRegisters
                  match={match}
                  firm={match.params.firm}
                  year={year}
                />
                <Catalog match={match} firm={match.params.firm} year={year} />
                <Settings match={match} firm={match.params.firm} year={year} />
                <Marketing match={match} firm={match.params.firm} year={year} />
                <Prices match={match} firm={match.params.firm} year={year} />
                <CatalogSettings
                  match={match}
                  firm={match.params.firm}
                  year={year}
                />
                <EshopSettings
                  match={match}
                  firm={match.params.firm}
                  year={year}
                />
                <Synchronizations match={match} />
                <Dejmark match={match} />

                <UserMenu />
                <Route
                  path={`${match.url}/history`}
                  render={({ match, history }) => <History history={history} />}
                />
              </StoreSynchorizer>
            ) : (
              <Redirect to={defaultRedirect} />
            );
          }}
        />
        <Route path="" render={() => <Redirect to={defaultRedirect} />} />
      </Switch>
    );
  }
}

Router.propTypes = {
  year: PropTypes.number.isRequired,
  firm: PropTypes.string.isRequired,
  user: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    forename: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    role: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default Router;
