import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import { Input, Button, Message } from 'oxyrion-ui/lib';
import ArrowNumberInput from 'oxyrion-ui/lib/ArrowNumberInput';
import { connect } from '../../../Store';
import { __ } from '../../../Utils';
import GreyBox from '../../GreyBox';
import SearchableSelect from '../../SearchableSelect';
import CalatalogListExtended from '../../../Containers/ProductCatalog/CatalogListExtended';
import PopUpWrapper from '../../PopUpWrapper';

const Wrapper = styled.div`
  max-width: ${rem(1200)};
  padding-bottom: ${rem(300)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(24)} ${rem(0)} ${rem(24)} ${rem(24)};
`;

const SearchableSelectWrapper = styled.div`
  min-width: ${rem(380)};
`;

const InputWrapper = styled.div`
  max-width: ${rem(100)};
  padding-right: ${rem(30)};
`;

const CatalogListWrapper = styled.div`
  width: 100%;
  min-height: 1300px;
  background: #fff;
`;

const MessageWrapper = styled.div`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
`;

const headers = [
  {
    label: __('Produkt'),
  },
  {
    label: __('Počet kusov'),
  },
  {
    label: __('Zľavnený produkt'),
  },
  {
    label: __('Cena zľavneného produktu'),
  },
];

class NplusOneCampaign extends React.Component {
  state = {
    showProductsSelector: false,
    error: null,
    items: [
      {
        product: null,
        quantity: 1,
        benefitProduct: null,
        price: null,
      },
    ],
  };

  componentDidMount() {
    if (this.props.defaultData && this.props.defaultData.length > 0) {
      this.setState({
        items: this.props.defaultData.concat([
          {
            product: null,
            quantity: 1,
            benefitProduct: null,
            price: null,
          },
        ]),
      });
    }
  }

  onSaveClicked() {
    const { onSaveClicked } = this.props;
    const { items } = this.state;

    const itemsToSave = items.filter(
      i => i && i.product && i.quantity >= 1 && i.benefitProduct,
    );

    if (!itemsToSave || itemsToSave.length === 0) {
      this.setState({
        error: __('Ku kampani musí byť priradený minimálne jeden produkt'),
      });
      return;
    }

    onSaveClicked(itemsToSave);
  }

  handleRowChange(index, field, value) {
    let { items } = this.state;

    if (field === 'product' && !value) {
      items.splice(index, 1);

      if (items.length === 0) {
        items = [
          {
            product: null,
            quantity: 1,
            benefitProduct: null,
            price: null,
          },
        ];
      }
    } else {
      items[index][field] = value;
    }
    this.setState({
      items,
    });
  }

  addVariants(variant) {
    const { items, showProductsSelector, productSelectorIndex } = this.state;
    if (showProductsSelector === 'product') {
      const isNew = items.length === productSelectorIndex + 1;

      let newProducts = [];
      if (isNew) {
        newProducts = items.filter(i => i.product);
        newProducts.push({
          product: variant,
          benefitProduct: variant,
          quantity: 1,
          price: null,
        });

        newProducts.push({
          product: null,
          quantity: 1,
          benefitProduct: null,
          price: null,
        });
      } else {
        newProducts = items;
        newProducts[productSelectorIndex].product = variant;
      }
      this.setState({
        items: newProducts,
        showProductsSelector: false,
        error: false,
      });
      return;
    }

    if (showProductsSelector === 'benefitProduct') {
      items[productSelectorIndex].benefitProduct = variant;
      this.setState({
        items,
        showProductsSelector: false,
        productSelectorIndex: null,
        error: false,
      });
    }
  }

  render() {
    const { items, showProductsSelector, error } = this.state;
    const { readOnly, firm, loading } = this.props;

    return (
      <Wrapper>
        <PopUpWrapper
          display={showProductsSelector}
          large
          titleZIndex={1000}
          customWidth={1200}
          message={__('Výber produktov zo zoznamu')}
          onClose={() =>
            this.setState({
              showProductsSelector: false,
            })
          }
          onEscClose={() =>
            this.setState({
              showProductsSelector: false,
            })
          }
        >
          {showProductsSelector && (
            <CatalogListWrapper>
              <CalatalogListExtended
                history={this.props.history}
                firm={this.props.firm}
                insertVariants={v => this.addVariants(v)}
              />
            </CatalogListWrapper>
          )}
        </PopUpWrapper>

        <GreyBox title={__('Základné nastavenia')}>
          {error && (
            <MessageWrapper>
              <Message error message={error} />
            </MessageWrapper>
          )}
          <Table>
            <Thead>
              <Tr>
                {headers.map(h => (
                  <Th>{h.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => (
                <Tr>
                  <Td>
                    <SearchableSelectWrapper
                      onClick={() =>
                        !readOnly &&
                        this.setState({
                          showProductsSelector: 'product',
                          productSelectorIndex: index,
                        })
                      }
                    >
                      <SearchableSelect
                        value={i.product}
                        loadOptions={() => {}}
                        placeholder={__('Produkt')}
                        keyProps={JSON.stringify(i.product)}
                        disabled={readOnly}
                        handleOnChange={() =>
                          this.handleRowChange(index, 'product', null)
                        }
                      />
                    </SearchableSelectWrapper>
                  </Td>
                  <Td>
                    <ArrowNumberInput
                      disabled={readOnly}
                      handleOnChange={value =>
                        !readOnly &&
                        this.handleRowChange(index, 'quantity', value)
                      }
                      handelOnIncrease={() =>
                        !readOnly &&
                        this.handleRowChange(index, 'quantity', i.quantity + 1)
                      }
                      handleOnDecrease={() =>
                        !readOnly &&
                        this.handleRowChange(
                          index,
                          'quantity',
                          i.quantity - 1 > 1 ? i.quantity - 1 : 1,
                        )
                      }
                      value={i.quantity}
                    />
                  </Td>
                  <Td>
                    <SearchableSelectWrapper
                      onClick={() =>
                        !readOnly &&
                        i.product &&
                        this.setState({
                          showProductsSelector: 'benefitProduct',
                          productSelectorIndex: index,
                        })
                      }
                    >
                      <SearchableSelect
                        value={i.benefitProduct}
                        loadOptions={() => {}}
                        placeholder={__('Produkt')}
                        keyProps={JSON.stringify(i.benefitProduct)}
                        disabled={readOnly || !i.product}
                        handleOnChange={() =>
                          this.handleRowChange(index, 'benefitProduct', null)
                        }
                      />
                    </SearchableSelectWrapper>
                  </Td>
                  <Td>
                    <InputWrapper>
                      <Input
                        disabled={readOnly}
                        type="number"
                        min="0"
                        onChange={e =>
                          this.handleRowChange(index, 'price', e.target.value)
                        }
                        value={i.price}
                        placeholder={`${__('Cena')} v ${
                          firm === 'SK' ? '€' : 'kč'
                        }`}
                      />
                    </InputWrapper>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </GreyBox>

        <ButtonWrapper>
          <Button
            primary
            loading={loading}
            disabled={readOnly}
            onClick={() => this.onSaveClicked()}
          >
            {__('Uložiť')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

NplusOneCampaign.propTypes = {
  readOnly: PropTypes.bool,
  firm: PropTypes.string,
  loading: PropTypes.bool,
  onSaveClicked: PropTypes.func.isRequired,
  defaultData: PropTypes.shape,
};

NplusOneCampaign.defaultProps = {
  readOnly: false,
  firm: 'SK',
  loading: false,
  defaultData: null,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(NplusOneCampaign);
