import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Input from 'oxyrion-ui/lib/Input';
import { rem } from 'polished';
import { Button, Label } from 'oxyrion-ui/lib';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/marketingListsContacts';
import { __, renderLink, renderLinkOnNewTab } from '../../Utils';
import { connect } from '../../Store';
import PopUpWrapper from '../../Components/PopUpWrapper';
import TableV3 from '../../Components/TableV3';
import { P } from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';

const MainLabel = styled(Label)`
  margin-right: ${rem(10)};
  font-size: ${rem(16)};
`;

const InputWrapper = styled.div`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
  /* min-width: ${rem(200)}; */
`;
const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: ${rem(10)};
  justify-content: space-between;
`;

const UpdateWrapper = styled.div`
  max-width: 100%;
  padding: ${rem(10)};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Header = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: 4px;
`;

const OwnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemoveWrapper = styled.div`
  font-size: ${rem(12)};
  color: red;
  cursor: pointer;
  text-decoration: underline;
`;

class MarketingListsContacts extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: null });
    this.headers = tableHeaders;
    this.segmentName = __('Kontakty marketingového zoznamu');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'surname',
      'email',
      'phone',
      'company',
      'city',
      'owners',
      'remove',
      'active',
    ];
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
      showRefreshDialog: false,
      shouldUpdate: false,
      rawContent: [],
      updatedItems: {
        deleted_items: [],
        added_items: [],
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.refreshTable !== nextProps.refreshTable) {
      this.setState({
        loading: true,
      });

      this.fetchData({}, nextProps.id);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.content !== this.state.content ||
      nextState.loading !== this.state.loading ||
      nextState.showRefreshDialog !== this.state.showRefreshDialog ||
      nextState.updateLoading !== this.state.updateLoading ||
      nextState.shouldUpdate !== this.state.shouldUpdate ||
      nextState.lastSearch !== this.state.lastSearch
    ) {
      return true;
    }

    if (nextProps.refreshTable !== this.props.refreshTable) {
      return true;
    }

    return false;
  }

  handleContactsOnClick(id) {
    const { history, firm } = this.props;
    history.push(`../${firm}/contacts/${id}`);
  }

  handleCompanyOnClick(id) {
    const { history, firm } = this.props;
    history.push(`./${firm}/customer/${id}`);
  }

  handleUserClick(id) {
    const { history, firm } = this.props;
    history.push(`./${firm}/users/${id}`);
  }

  async fetchData(parameters = {}, id) {
    try {
      const { sorter, lastSearch, active } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (lastSearch && lastSearch.length > 0) {
        params.q = lastSearch;
      }
      params.limit = this.props.limit;

      const newData = await AdminAPI.getMarketingListContactsAction(
        id || this.props.id,
        params,
      );

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        rawContent: newData.items,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  updateContent(rawContent) {
    const content = this.normalizeColumns(rawContent);

    this.setState({
      content,
      rawContent,
    });
  }

  async updateActiveState(contactId) {
    const { rawContent } = this.state;

    try {
      const params = {};

      params.body = {
        status: !rawContent.find(r => r._id === contactId).active,
      };

      const result = await AdminAPI.putMarketingListContactStatusAction(
        this.props.id,
        contactId,
        params,
      );

      rawContent.find(r => r._id === contactId).active = result.active;
      this.updateContent(rawContent);
    } catch (e) {
      console.log(e);
    }
  }

  async updateTable(forced = false) {
    const { saveConditions, id } = this.props;
    const { updatedItems } = this.state;

    this.setState({
      updateLoading: true,
    });

    const params = {};

    if (forced) {
      const dataToSend = {
        added_items: updatedItems.added_items.map(u => ({
          contact_id: u._id,
          active: u.active,
        })),
        deleted_items: updatedItems.deleted_items,
      };

      params.body = dataToSend;
    }

    params.forced = forced;

    if (await saveConditions()) {
      try {
        await AdminAPI.putMarketingListContactsAction(id, params);
        if (forced) {
          this.setState({
            loading: true,
          });
          this.fetchData({}, this.props.id);
        }
      } catch (e) {
        switch (e.response.status) {
          case 409:
            this.setState({
              updatedItems: e.details.payload,
              showRefreshDialog: true,
            });
            break;
          default:
            throw e;
        }
      }
    }

    this.setState({
      updateLoading: false,
    });
  }

  async applyChanges() {
    this.setState({
      applyLoading: true,
    });

    await this.updateTable(true);

    this.setState({
      applyLoading: false,
      showRefreshDialog: false,
    });
  }

  normalizeColumns(data) {
    const { onRemoveClicked } = this.props;

    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(item.name),
        surname: _shapeData(
          renderLinkOnNewTab(
            `${process.env.REACT_APP_CRM_DOMAIN}/${this.props.firm}/contacts/${item._id}`,
            `${item.surname || '-'}`,
          ),
          () => this.handleContactsOnClick(item._id),
        ),
        email: _shapeData(item.email),
        phone: _shapeData(item.phone),
        city: _shapeData(item.city),
        company: _shapeData(
          item.company &&
            renderLinkOnNewTab(
              `${process.env.REACT_APP_CRM_DOMAIN}/${this.props.firm}/customers/${item.company._id}`,
              `${item.company.display_name || '-'}`,
            ),
          () => this.handleCompanyOnClick(item._id),
        ),
        owners: _shapeData(
          <OwnersWrapper>
            {item.owners &&
              item.owners.map(o => {
                return (
                  <div>
                    {renderLink(
                      `../users/${o._id}`,
                      `${o.display_name || '-'}`,
                    )}
                  </div>
                );
              })}
          </OwnersWrapper>,
        ),
        active: _shapeData(
          <Checkbox
            checked={item.active}
            disabled={this.props.readonly}
            onChange={() => {
              this.updateActiveState(item._id);
            }}
          />,
        ),
        _id: item._id,
        remove: _shapeData(
          onRemoveClicked && (
            <RemoveWrapper onClick={() => onRemoveClicked(item._id)}>
              {__('Odstrániť')}
            </RemoveWrapper>
          ),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  createColumnsForPopup() {
    return [
      {
        accessor: 'name',
        Header: () => <Header>{__('Meno')}</Header>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'surname',
        Header: () => <Header>{__('Priezvisko')}</Header>,
        Cell: props => {
          return (
            <P>
              {renderLink(
                `../customers/${props.original._id}`,
                `${props.value || '-'}`,
              )}
            </P>
          );
        },
      },
      {
        accessor: 'email',
        Header: () => <Header>{__('Email')}</Header>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'company',
        Header: () => <Header>{__('Spoločnosť')}</Header>,
        Cell: props => {
          return (
            <P>
              {renderLink(
                `../customers/${props.value._id}`,
                `${props.value.display_name || '-'}`,
              )}
            </P>
          );
        },
      },
      {
        accessor: 'city',
        Header: () => <Header>{__('Mesto')}</Header>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'owners',
        Header: () => <Header>{__('Vlastník')}</Header>,
        Cell: props => {
          return (
            <P>
              <OwnersWrapper>
                {props.value &&
                  props.value.map(p => {
                    return (
                      <div>
                        {renderLink(
                          `../customers/${p._id}`,
                          `${p.display_name || '-'}`,
                        )}
                      </div>
                    );
                  })}
              </OwnersWrapper>
            </P>
          );
        },
      },
      {
        accessor: 'active',
        Header: () => <div />,
        maxWidth: 40,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.value}
                onChange={() => {
                  this.changeUpdatedActiveState(props.original._id);
                }}
              />
            </P>
          );
        },
      },
    ];
  }

  changeUpdatedActiveState(id) {
    const { updatedItems } = this.state;

    updatedItems.added_items.find(
      a => a._id === id,
    ).active = !updatedItems.added_items.find(a => a._id === id).active;

    this.setState({
      updatedItems,
      shouldUpdate: !this.state.shouldUpdate,
    });
  }

  renderControlBar() {
    return <div />;
  }

  render() {
    const {
      showRefreshDialog,
      updatedItems,
      updateLoading,
      applyLoading,
      showUpdateButton,
    } = this.state;

    return (
      <Wrapper>
        <PopUpWrapper
          display={showRefreshDialog}
          message={__('Zmena zoznamu ')}
          onClose={() =>
            this.setState({
              showRefreshDialog: false,
            })
          }
        >
          <UpdateWrapper>
            <InfoWrapper>
              <Label>{__('Adresy k odobratiu:')}</Label>
              <div style={{ color: 'red' }}>
                {updatedItems.deleted_items.length}
              </div>
            </InfoWrapper>

            <InfoWrapper>
              <Label>{__('Adresy k dodaniu:')}</Label>
              <div style={{ color: 'green' }}>
                {updatedItems.added_items.length}
              </div>
            </InfoWrapper>

            <TableV3
              columns={this.createColumnsForPopup()}
              minWidth={10}
              data={updatedItems.added_items || []}
              getTdProps={() => {
                return { style: { padding: 0, margin: 'auto' } };
              }}
              getTheadThProps={(state, rowInfo, column) => {
                if (column && column.sortable) {
                  return {
                    onClick: e => {
                      this.sortByV2(e.target.innerHTML);
                    },
                  };
                }
                return {};
              }}
              className="-highlight -striped"
              offset={300}
            />

            <Row style={{ marginTop: rem(15) }}>
              <Button
                onClick={() =>
                  this.setState({
                    showRefreshDialog: false,
                  })
                }
              >
                {__('Zrušiť')}
              </Button>
              <Button
                loading={applyLoading}
                disabled={applyLoading}
                primary
                onClick={() => this.applyChanges()}
              >
                {__('Aplikovať')}
              </Button>
            </Row>
          </UpdateWrapper>
        </PopUpWrapper>

        <Row>
          <MainLabel>{this.props.title}</MainLabel>
          {showUpdateButton && (
            <Button
              small
              primary
              loading={updateLoading}
              disabled={updateLoading}
              onClick={() => {
                this.updateTable();
              }}
            >
              {__('Aktualizovať zoznam')}
            </Button>
          )}
        </Row>
        <InputWrapper>
          <Input
            value={this.state.lastSearch}
            placeholder={__('Hľadať podľa mena a priezviska')}
            onChange={e => {
              this.setState(
                {
                  lastSearch: e.target.value,
                },
                () => this.fetchData(),
              );
            }}
          />
        </InputWrapper>
        {super.render()}
      </Wrapper>
    );
  }
}

MarketingListsContacts.propTypes = {
  saveConditions: PropTypes.func.isRequired,
  refreshTable: PropTypes.bool,
  showUpdateButton: PropTypes.bool,
  title: PropTypes.string,
  limit: PropTypes.number,
};

MarketingListsContacts.defaultProps = {
  refreshTable: false,
  showUpdateButton: true,
  title: __('Zoznam adries'),
  limit: 30,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(MarketingListsContacts);
