import React from 'react';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/securityAlerts';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';

const LANGS = ['SK', 'CZ', 'AT', 'EN'];

class EmailTemplateList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'code' });
    this.headers = tableHeaders;
    this.showBulkOperations = false;
    this.segmentName = __('Bezpečnostné upozornenia');
    this.accessors = ['name', 'code', 'type', 'class', 'created_date'];
    this.setSorter('code');
    this.state = {
      ...this.state,
      lastSearch: '',
      activeCountry: 'SK',
      showAlert: true,
    };
  }
  async fetchData(parameters = {}) {
    try {
      const { sorter, lastSearch, activeCountry } = this.state;
      this.setState({ loading: true });
      let params;

      params = Object.assign({}, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearch;
      }
      if (activeCountry) {
        params.lang = activeCountry;
      }

      const newData = await AdminAPI.getSecurityAlertsAction(params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  handleTemplatesOnClick(id) {
    const { history, firm } = this.props;
    const { activeCountry } = this.state;

    history.push(`/${firm}/security-alerts/${id}?lang=${activeCountry}`);
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `security-alerts/${item._id}?lang=${this.state.activeCountry}`,
            item.name,
          ),
          () => this.handleTemplatesOnClick(item._id),
        ),
        code: _shapeData(item.code),
        type: _shapeData(item.type),
        class: _shapeData(item.class),
        created_date:
          item.created_date &&
          _shapeData(formatDate(item.created_date, FORMAT_HUMANE_DATETIME)),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleCountryChange(activeCountry) {
    this.setState(
      {
        activeCountry: __(activeCountry),
      },
      () => {
        this.fetchData();
      },
    );
  }

  renderControlBar() {
    const { history } = this.props;
    const { activeCountry } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Bezpečnostné upozornenia')}
        onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlButton name={activeCountry}>
          <LinkWrap>
            {LANGS.map(l => (
              <div onClick={e => this.handleCountryChange(e.target.innerHTML)}>
                {l}
              </div>
            ))}
          </LinkWrap>
        </ControlButton>

        <ControlBarButton
          primary
          small
          onClick={() => this.handleTemplatesOnClick('new')}
          icon="plus"
        >
          {__('Nové upozornenie')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EmailTemplateList);
