import React, { Component } from 'react';
import { rem } from 'polished';
import { Input, Button, Label, Icon } from 'oxyrion-ui/lib';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { __ } from '../../Utils';
// import DropZone from 'react-dropzone';

const Wrapper = styled.div`
  max-width: ${rem(400)};
  margin-left: ${rem(30)};
  margin-bottom: ${rem(30)};
  align-items: center;
`;
const LinkRow = styled.div`
  margin-bottom: ${rem(10)};
  display: flex;
`;
const ButtonWrapper = styled.div`
  float: right;
`;

const DeleteWrapper = styled.div`
  width: ${rem(38)};
  height: ${rem(38)};
  margin-left: ${rem(25)};
  border-radius: ${rem(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #e84141;
`;

// const StyledDropZone = styled(DropZone)`
// width: ${rem(38)}
// height: ${rem(38)}
// position: relative;
// margin-left: ${rem(25)}
// border-radius: ${rem(5)};
// display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     background: #0080006e;
// `;

// const ArrowWrapper = styled.div`
//   transform: rotate(-90deg);
//   margin-left: 2px;
// `;

// const Text = styled.div`
//   position: absolute;
//   font-size: 9px;
//   bottom: 2px;
//   color: white;
// `;

class VideoUpLoader extends Component {
  render() {
    const {
      videos,
      onChange,
      addNewLink,
      onDelete,
      // uploadVideo,
      // uploadLoading,
    } = this.props;

    return (
      <Wrapper>
        <Label>{__('Videá: ')}</Label>
        {videos.map((l, index) => {
          return (
            <LinkRow>
              <Input
                placeholder={__('Odkaz na video')}
                value={l.link}
                onChange={e => onChange(e, index)}
              />
              {/* <StyledDropZone
                onDrop={accepted => uploadVideo(accepted, index)}
                acceptedFiles="videos/*"
                activeStyle={{
                  outlineWidth: rem(5),
                }}
              >
                {uploadLoading === index ? (
                  <Loader />
                ) : (
                  <React.Fragment>
                    <ArrowWrapper>
                      <Icon name="arrow" color="white" />
                    </ArrowWrapper>
                    <Text>{__('nahrať')}</Text>
                  </React.Fragment>
                )}
              </StyledDropZone> */}
              <DeleteWrapper onClick={() => onDelete(index)}>
                <Icon name="delete" color="white" />
              </DeleteWrapper>
            </LinkRow>
          );
        })}
        <ButtonWrapper>
          <Button small primary icon="plus" onClick={() => addNewLink()}>
            {__('Pridať video')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

VideoUpLoader.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  addNewLink: PropTypes.func,
  onDelete: PropTypes.func,
  // uploadVideo: PropTypes.func,
  // uploadLoading: PropTypes.number,
};

VideoUpLoader.defaultProps = {
  videos: [],
  onChange: () => {},
  addNewLink: () => {},
  onDelete: () => {},
  // uploadVideo: () => {},
  // uploadLoading: -1,
};

export default VideoUpLoader;
