import React, { Component } from 'react';
import Message from 'oxyrion-ui/lib/Message';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import Icon from 'oxyrion-ui/lib/Icon';
import { rem } from 'polished';
import { Tbody, Thead, Tr, Th, Td, Table } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import AddNewB2CDiscount from '../../Components/AddNewB2CDiscount';
import { __ } from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  DivIconWrapper,
  H2,
} from '../../Components/ReusableComponents';
import CheckAccess from '../../ACL/CheckAccess';
import { connect } from '../../Store';
import customerTypes from '../../ContentConfig/customerTypes';
import LanguageSwitch from '../../Components/LanguageSwitch';
import API2 from '../../API2';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
  margin: ${rem(30)} 0;
  td,
  th {
    padding-left: 0;
  }
  tbody tr:last-of-type {
    border-bottom: none;
  }
`;

const TableWrapper = styled.div`
  margin: ${rem(10)};
`;

const CustomTh = styled(Th)`
  padding-right: ${rem(10)};
  :last-of-type {
    width: 100%;
  }
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
`;

class B2CDiscounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLang: 'SK',
      unregisteredDiscounts: [],
      firmDiscount: [],
      customerDiscounts: [],
      from: 0,
      customerFrom: 0,
      over: 0,
      buttonLoading: false,
      unregisteredButtonLoading: false,
      customerButtonLoading: false,
      unregisteredDiscountError: '',
      discountError: '',
      customerDiscountError: '',
      customerTypesCompany: customerTypes
        .filter(c => c.type === 'company')
        .map(type => {
          return type.name;
        }),
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData(params = {}) {
    try {
      const { selectedLang } = this.state;
      const parameters = Object.assign({}, params, {
        type: 'discount_customer_type',
        sorter: 'created_date',
      });
      const result = await API2.getDiscountsAction(selectedLang, parameters);

      const firmDiscount = [];
      const customerDiscounts = [];
      const unregisteredDiscounts = [];

      result.discounts.forEach(discount => {
        if (!discount.customer_types) {
          unregisteredDiscounts.push(discount);
        } else if (
          this.state.customerTypesCompany.indexOf(
            discount.customer_types[0].type,
          ) > -1
        ) {
          firmDiscount.push(discount);
        } else {
          customerDiscounts.push(discount);
        }
      });

      this.setState({
        firmDiscount,
        customerDiscounts,
        unregisteredDiscounts,
        discountError: '',
      });
    } catch (e) {
      this.setState({
        discountError: __('Nepodarilo sa načítať zľavy'),
        firmDiscount: [],
        customerDiscounts: [],
        unregisteredDiscounts: [],
      });
    }
  }

  async addFirmDiscount(from, to, percent) {
    try {
      const { selectedLang } = this.state;

      this.setState({
        buttonLoading: true,
        discountError: '',
      });
      const params = {
        name: 'unnamed',
        valid_from: new Date().toISOString(),
        purchase_from: {
          amount: Number(from),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        purchase_to: {
          amount: Number(to),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        discount: Number(percent),
        type: 'discount_customer_type',
        customer_types: [
          {
            type: 'B2B',
          },
        ],
      };
      await API2.postDiscountAction(selectedLang, { body: params });
    } catch (e) {
      this.setState({ discountError: __('Nepodarilo sa pridať zľavu') });
    }
    setTimeout(() => {
      this.fetchData();
      this.setState({
        buttonLoading: false,
      });
    }, 1000);
  }

  async addUnregisteredDiscount(over, percent) {
    try {
      const { selectedLang } = this.state;
      this.setState({
        unregisteredButtonLoading: true,
        unregisteredDiscountError: '',
      });
      const params = {
        name: 'unnamed',
        valid_from: new Date().toISOString(),
        purchase_from: {
          amount: Number(over),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        discount: Number(percent),
        type: 'discount_customer_type',
      };
      await API2.postDiscountAction(selectedLang, { body: params });
    } catch (e) {
      this.setState({ discountError: __('Nepodarilo sa pridať zľavu') });
    }

    setTimeout(() => {
      this.fetchData();
      this.setState({
        unregisteredButtonLoading: false,
      });
    }, 1000);
  }

  async addCustomerDiscount(from, to, percent) {
    try {
      const { selectedLang } = this.state;

      this.setState({
        customerButtonLoading: true,
        customerDiscountError: '',
      });
      const params = {
        name: 'unnamed',
        valid_from: new Date().toISOString(),
        purchase_from: {
          amount: Number(from),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        purchase_to: {
          amount: Number(to),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        discount: Number(percent),
        type: 'discount_customer_type',
        customer_types: [
          {
            type: 'B2C-C',
          },
        ],
      };
      await API2.postDiscountAction(selectedLang, { body: params });
    } catch (e) {
      this.setState({ discountError: __('Nepodarilo sa pridať zľavu') });
    }

    setTimeout(() => {
      this.fetchData();
      this.setState({
        customerButtonLoading: false,
      });
    }, 1000);
  }

  async removeUnregisteredDiscount(id) {
    try {
      const { selectedLang } = this.state;
      await API2.deleteDiscountAction(selectedLang, id);

      setTimeout(() => {
        this.fetchData();
      }, 500);
    } catch (e) {
      this.setState({
        unregisteredDiscountError: __('Nepodarilo sa vykonať akciu'),
      });
    }
  }

  async removeFirmDiscount(id) {
    try {
      const { selectedLang } = this.state;
      await API2.deleteDiscountAction(selectedLang, id);

      setTimeout(() => {
        this.fetchData();
      }, 500);
    } catch (e) {
      this.setState({ discountError: __('Nepodarilo sa vykonať akciu') });
    }
  }

  async removeCustomerDiscount(id) {
    try {
      const { selectedLang } = this.state;
      await API2.deleteDiscountAction(selectedLang, id);

      setTimeout(() => {
        this.fetchData();
      }, 500);
    } catch (e) {
      this.setState({
        customerDiscountError: __('Nepodarilo sa vykonať akciu'),
      });
    }
  }

  changeLanguage(id) {
    this.setState(
      {
        selectedLang: id,
      },
      () => {
        this.fetchData();
      },
    );

    return true;
  }

  render() {
    const {
      customerDiscounts,
      unregisteredDiscounts,
      firmDiscount,
      from,
      customerFrom,
      over,
      buttonLoading,
      unregisteredButtonLoading,
      customerButtonLoading,
      unregisteredDiscountError,
      customerDiscountError,
      discountError,
      selectedLang,
    } = this.state;
    return (
      <React.Fragment>
        <Wrapper>
          <Row>
            <LanguageSwitch
              onChange={id => {
                return this.changeLanguage(id);
              }}
              activeId={selectedLang}
            />
          </Row>

          <H2 margin={`0 0 0 ${rem(10)}`}>{__('Neregistrovaný zákazník')}</H2>
          <TableWrapper>
            <AnimatedFormMessageWrapper display={unregisteredDiscountError}>
              <Message error message={unregisteredDiscountError} />
            </AnimatedFormMessageWrapper>
            <Table>
              <Thead>
                <Tr>
                  <CustomTh>{__('Nákup nad')}</CustomTh>
                  <CustomTh>{__('Zľava v %')}</CustomTh>
                  <CustomTh />
                </Tr>
              </Thead>
              <Tbody>
                {unregisteredDiscounts.map(item => (
                  <Tr>
                    <Td>{item.purchase_from.amount}</Td>
                    <Td>{`${item.discount} %`}</Td>
                    <Td>
                      <CheckAccess
                        operation="getCompetitorRevenuesAction"
                        Component={
                          <DivIconWrapper
                            onClick={() =>
                              this.removeUnregisteredDiscount(item._id)
                            }
                          >
                            <Icon name="close" color={baseTheme.color.danger} />
                          </DivIconWrapper>
                        }
                      />
                    </Td>
                  </Tr>
                ))}
                <CheckAccess
                  operation="getCompetitorRevenuesAction"
                  Component={
                    <AddNewB2CDiscount
                      unregistered={true}
                      over={over}
                      onSubmit={(toValue, percentValue) =>
                        this.addUnregisteredDiscount(toValue, percentValue)
                      }
                      buttonLoading={unregisteredButtonLoading}
                    />
                  }
                />
              </Tbody>
            </Table>
          </TableWrapper>
        </Wrapper>
        <Wrapper>
          <H2 margin={`0 0 0 ${rem(10)}`}>{__('Súkromná osoba')}</H2>
          <TableWrapper>
            <AnimatedFormMessageWrapper display={customerDiscountError}>
              <Message error message={customerDiscountError} />
            </AnimatedFormMessageWrapper>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Ročný obrat od')}</Th>
                  <Th>{__('Ročný obrat do')}</Th>
                  <Th>{__('Zľava v %')}</Th>
                  <CustomTh />
                </Tr>
              </Thead>
              <Tbody>
                {customerDiscounts.map(item => (
                  <Tr>
                    <Td>{item.purchase_from.amount}</Td>
                    <Td>{item.purchase_to.amount}</Td>
                    <Td>{`${item.discount} %`}</Td>
                    <Td>
                      <CheckAccess
                        operation="getCompetitorRevenuesAction"
                        Component={
                          <DivIconWrapper
                            onClick={() =>
                              this.removeCustomerDiscount(item._id)
                            }
                          >
                            <Icon name="close" color={baseTheme.color.danger} />
                          </DivIconWrapper>
                        }
                      />
                    </Td>
                  </Tr>
                ))}
                <AddNewB2CDiscount
                  from={customerFrom}
                  onSubmit={(fromValue, toValue, percentValue) =>
                    this.addCustomerDiscount(fromValue, toValue, percentValue)
                  }
                  buttonLoading={customerButtonLoading}
                />
              </Tbody>
            </Table>
          </TableWrapper>
        </Wrapper>
        <Wrapper>
          <H2 margin={`0 0 0 ${rem(10)}`}>{__('Živnostník/Firma')}</H2>
          <TableWrapper>
            <AnimatedFormMessageWrapper display={discountError}>
              <Message error message={discountError} />
            </AnimatedFormMessageWrapper>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Ročný obrat od')}</Th>
                  <Th>{__('Ročný obrat do')}</Th>
                  <Th>{__('Zľava v %')}</Th>
                  <CustomTh />
                </Tr>
              </Thead>
              <Tbody>
                {firmDiscount.map(item => (
                  <Tr>
                    <Td>{item.purchase_from.amount}</Td>
                    <Td>{item.purchase_to.amount}</Td>
                    <Td>{`${item.discount} %`}</Td>
                    <Td>
                      <CheckAccess
                        operation="getCompetitorRevenuesAction"
                        Component={
                          <DivIconWrapper
                            onClick={() => this.removeFirmDiscount(item._id)}
                          >
                            <Icon name="close" color={baseTheme.color.danger} />
                          </DivIconWrapper>
                        }
                      />
                    </Td>
                  </Tr>
                ))}
                <AddNewB2CDiscount
                  from={from}
                  onSubmit={(fromValue, toValue, percentValue) =>
                    this.addFirmDiscount(fromValue, toValue, percentValue)
                  }
                  buttonLoading={buttonLoading}
                />
              </Tbody>
            </Table>
          </TableWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(B2CDiscounts);
