import BaseList from '../../BaseList-B2bCatalog';

class BaseCatalogLayout extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      checkedFilter: [],
      defaultFilter: [],
      doNotLoadFilters: false,
      showFilter: true,
      showSearch: true,
      showPager: true,
      buttonsSwitch: [],
      actualPage: 1,
      limit: 24,
      maxFilterWidth: 140,
      offset: 0,
      previousLevel: 0,
      loading: true,
      items: [],
      tableItems: [],
      filters: [],
      notFoundMessage: '',
      defaultSearchText: '',
    };
  }

  render() {
    return super.render();
  }
}

export default BaseCatalogLayout;
