import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label, Checkbox, Icon } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';
import SearchableSelect from '../../Components/SearchableSelect';
import {
  searchCatalogProducts,
  searchCatalogProductVariants,
} from '../../Utils/ApiSearchFunctions';

const Wrapper = styled.div`
  width: ${rem(400)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const ProductRow = styled(Row)`
  justify-content: space-between;
  margin-left: 8px;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
`;

const ProductName = styled.div`
  font-size: 14px;
  color: #ee6501;
  font-weight: bold;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(12)};
  background: #ff4646;
  border-radius: 4px;
`;

const AddWrapper = styled.div`
  cursor: pointer;
  font-size: ${rem(12)};
  font-weight: bold;
  :hover {
    text-decoration: underline;
  }
  border-bottom: 1px solid #6b6b6b;
  padding-bottom: 12px;
  width: 100%;
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const AddHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 4px;
  padding-top: 12px;
  margin-right: 4px;
  margin-bottom: 8px;
`;

const AddLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`;

const SearchableSelectsWrapper = styled.div`
  padding-bottom: 12px;
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

class ProductSelector extends React.PureComponent {
  state = {
    showAddProduct: false,
    productToAdd: null,
    variantToAdd: null,
  };

  handleProductToAddChange(e) {
    let { productToAdd } = this.state;
    const { onCatalogProductSelect, type } = this.props;

    productToAdd = e;

    if (type === 'catalog') {
      onCatalogProductSelect(productToAdd);
    } else {
      this.setState({ productToAdd });
    }
  }

  handleVariantToAddChange(e) {
    const { productToAdd } = this.state;

    const { type, onVariantSelect } = this.props;

    if (type === 'variant') {
      onVariantSelect({
        catalogProduct: {
          _id: productToAdd.value,
          name: productToAdd.label,
          type: productToAdd.type,
        },
        variant: e.raw,
        published: true,
      });
    }
    this.setState({
      productToAdd: e.hasMore ? productToAdd : null,
      variantToAdd: null,
    });
  }

  renderProductsByType(usedProducts, type) {
    const { handleProductPublicationChange, removeProduct } = this.props;
    if (type === 'variant') {
      return usedProducts.map(p => (
        <ProductRow>
          <ProductName>{p.variant.name}</ProductName>
          <CheckboxWrapper>
            <Checkbox
              checked={p.published}
              onChange={e => handleProductPublicationChange(e, p.variant._id)}
            />

            <IconWrapper onClick={() => removeProduct(p.variant._id)}>
              <Icon name="close" color="white" />
            </IconWrapper>
          </CheckboxWrapper>
        </ProductRow>
      ));
    }
    return usedProducts.map(p => (
      <ProductRow>
        <ProductName>{p.name}</ProductName>
        <CheckboxWrapper>
          <Checkbox
            checked={p.published}
            onChange={e => handleProductPublicationChange(e, p._id)}
          />

          <IconWrapper onClick={() => removeProduct(p._id)}>
            <Icon name="close" color="white" />
          </IconWrapper>
        </CheckboxWrapper>
      </ProductRow>
    ));
  }

  render() {
    const { usedProducts, firm, label, type } = this.props;
    const { showAddProduct, productToAdd, variantToAdd } = this.state;

    return (
      <Wrapper>
        <StyledLabel>{label}</StyledLabel>

        {this.renderProductsByType(usedProducts, type)}

        <Row>
          {!showAddProduct ? (
            <AddWrapper
              onClick={() =>
                this.setState({
                  showAddProduct: true,
                })
              }
            >
              {__('+ Pridať produkt')}
            </AddWrapper>
          ) : (
            <InputWrapper
              style={{
                background: '#dadada',
                padding: rem(4),
              }}
            >
              <AddHeader>
                <AddLabel>{__('Pridať produkt')}</AddLabel>

                <IconWrapper
                  onClick={() =>
                    this.setState({
                      showAddProduct: false,
                      productToAdd: null,
                    })
                  }
                >
                  <Icon name="close" size="l" color="white" />
                </IconWrapper>
              </AddHeader>

              <SearchableSelectsWrapper>
                <SearchableSelect
                  value={productToAdd}
                  loadOptions={query =>
                    searchCatalogProducts(query, usedProducts.map(up => up._id))
                  }
                  // autoload
                  placeholder={__('Zvoľte katalogový produkt')}
                  handleOnChange={e => this.handleProductToAddChange(e)}
                  label={__('Použité produkty')}
                  // keyProps={JSON.stringify({
                  //   id: productToAdd && productToAdd.value,
                  //   length: usedProducts && usedProducts.length,
                  // })}
                />
                <Space />

                {productToAdd && (
                  <SearchableSelect
                    value={variantToAdd}
                    loadOptions={query =>
                      searchCatalogProductVariants(
                        query,
                        productToAdd.value,
                        {
                          addedIds: usedProducts.map(u => u.variant._id),
                        },
                        firm,
                      )
                    }
                    isClearable={false}
                    placeholder={__('Zvoľte položku')}
                    keyProps={JSON.stringify({
                      id: productToAdd && productToAdd.value,
                      length: usedProducts && usedProducts.length,
                    })}
                    handleOnChange={e => this.handleVariantToAddChange(e)}
                  />
                )}
              </SearchableSelectsWrapper>
            </InputWrapper>
          )}
        </Row>
      </Wrapper>
    );
  }
}

ProductSelector.propTypes = {
  usedProducts: PropTypes.arrayOf(PropTypes.shape),
  onCatalogProductSelect: PropTypes.func,
  handleProductPublicationChange: PropTypes.func,
  removeProduct: PropTypes.func,
  onVariantSelect: PropTypes.func,
  firm: PropTypes.string,
  type: 'catalog' || 'variant',
  label: PropTypes.string,
};

ProductSelector.defaultProps = {
  usedProducts: [],
  onCatalogProductSelect: () => {},
  onVariantSelect: () => {},
  handleProductPublicationChange: () => {},
  removeProduct: () => {},
  firm: 'SK',
  type: 'variant',
  label: '',
};

export default ProductSelector;
