import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import ArticleTitle from './ArticleTitle';
import ArticleContent from './ArticleContent';
import { translate, formatDate, FORMAT_HUMANE_DATETIME } from '../../Utils';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem(5)};
`;

class Article extends React.Component {
  render() {
    const {
      name,
      perex,
      thumbnail,
      content,
      firm,
      updated,
      videos,
    } = this.props;

    return (
      <Wrapper>
        <ArticleTitle
          name={name ? translate(firm, name.translations, name.value) : ''}
          perex={perex ? translate(firm, perex.translations, perex.value) : ''}
          image={thumbnail && thumbnail.fullPath}
          updated={formatDate(moment(updated), FORMAT_HUMANE_DATETIME)}
        />
        <ArticleContent content={content} videos={videos} />
      </Wrapper>
    );
  }
}
Article.propTypes = {
  updated: PropTypes.string,
  name: PropTypes.shape({
    value: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  perex: PropTypes.shape({
    value: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  text: PropTypes.shape({
    value: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  thumbnail: PropTypes.shape({
    fullPath: PropTypes.string,
  }),
  content: PropTypes.element,
  firm: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.string),
};

Article.defaultProps = {
  name: null,
  perex: null,
  text: null,
  thumbnail: null,
  video_label: false,
  content: null,
  updated: null,
  firm: 'SK',
  videos: [],
};

export default Article;
