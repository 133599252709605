import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'oxyrion-ui/lib/Select';
import 'react-dropzone-uploader/dist/styles.css';
import { rem } from 'polished';
import { Button, Message, Input, Label, Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapperLeft,
} from '../../Components/ReusableComponents';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import DateAndTimerPicker from '../../Components/DateAndTimePicker';
import TextEditor from '../../Components/TextEditor';
import GreyBox from '../../Components/GreyBox';
import AdminAPI from '../../AdminAPI';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  /* padding-left: ${rem(20)}; */
  margin-bottom: ${rem(20)};
  flex-direction: column;
  ${({ theme }) => theme.media.l`
 flex-direction: row;
`};
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const TextEditorWrapper = styled.div`
  width: 100%;
  max-width: ${rem(1400)};
  margin-left: ${rem(12)};
  background: white;
`;

const types = [
  {
    id: 'strip',
    name: 'Lišta',
    disabled: false,
  },
  {
    id: 'banner',
    name: 'Banner',
    disabled: true,
  },
  {
    id: 'popup',
    name: 'Popup',
    disabled: true,
  },
];

const apps = [
  {
    id: 'xFarby',
    name: 'xFarby',
  },
  {
    id: 'xBarvy',
    name: 'xBarvy',
  },
  {
    id: 'xFarben',
    name: 'xFarben',
  },
  {
    id: 'xFestek',
    name: 'xFestek',
  },
];

class EshopNotificationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      data: {
        name: '',
      },
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData(invokeLoader) {
    if (invokeLoader) {
      this.setState({
        loading: true,
      });
    }
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      this.setState({
        loading: true,
      });
      await this.fetchNotificationData(id);
    }
  }

  async fetchNotificationData(id) {
    try {
      const data = await AdminAPI.getNotificationAction(id);

      data.published_from = data.published_from && moment(data.published_from);
      data.published_to = data.published_to && moment(data.published_to);

      this.setState({
        data,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Inšpirácia sa nenašla'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteNotification() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteNotificationAction(getIdFromProps(this.props));
      history.push(`/${firm}/eshop-notifications`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri mazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    if (!data.type || data.type === '-') {
      isValid = false;
    }

    if (!data.published_for || data.published_for === '-') {
      isValid = false;
    }

    return isValid;
  }

  async saveData(checkFile = false, showSucces = true) {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data, checkFile)) {
      try {
        const params = {};
        params.body = {
          name: data.name,
          type: data.type,
          published_for: data.published_for,
          published_from: data.published_from && new Date(data.published_from),
          published_to: data.published_to && new Date(data.published_to),
          content: data.content,
        };

        if (getIdFromProps(this.props) === 'new') {
          const result = await AdminAPI.postNotificationAction(params);

          this.props.history.push(
            `/${this.props.firm}/eshop-notifications/${result._id}`,
          );

          result.published_from =
            data.published_from && moment(data.published_from);
          result.published_to = data.published_to && moment(data.published_to);

          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        } else {
          const result = await AdminAPI.putNotificationAction(
            getIdFromProps(this.props),
            params,
          );

          result.published_from =
            data.published_from && moment(data.published_from);
          result.published_to = data.published_to && moment(data.published_to);

          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        }
      } catch (e) {
        this.setState({
          error: __('Dáta sa nepodarilo uložiť'),
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        error: __('Nie sú vyplnené všetky potrebné hodnoty'),
        saveLoading: false,
      });
    }
  }

  handleChange(field, value) {
    const { data } = this.state;

    data[field] = value;

    this.setState({ data });
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Notifikácia')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      saveLoading,
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť notifikáciu?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteNotification()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row>
            <GreyBox
              maxWidth={400}
              addDisabled
              title={__('Základné informácie')}
            >
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(290) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Typ notifikácie*')}</StyledLabel>
                  <Select
                    style={{ minWidth: '200px' }}
                    size="s"
                    value={data.type}
                    onChange={e => this.handleChange('type', e.target.value)}
                  >
                    <option value={null} key="-" selected={!data.type}>
                      -
                    </option>
                    {types.map(t => (
                      <option
                        key={t.id}
                        value={t.id}
                        disabled={t.disabled}
                        selected={t.id === data.type}
                      >
                        {t.name}
                      </option>
                    ))}
                  </Select>
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Publikovať na*')}</StyledLabel>
                  <Select
                    style={{ minWidth: '200px' }}
                    size="s"
                    value={data.published_for}
                    onChange={e =>
                      this.handleChange('published_for', e.target.value)
                    }
                  >
                    <option value={null} key="-" selected={!data.published_for}>
                      -
                    </option>
                    {apps.map(t => (
                      <option
                        key={t.id}
                        value={t.id}
                        disabled={t.disabled}
                        selected={t.id === data.published_for}
                      >
                        {t.name}
                      </option>
                    ))}
                  </Select>
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Publikované od')}</StyledLabel>
                  <DateAndTimerPicker
                    value={data.published_from}
                    datePlaceHolder={__('Dátum')}
                    timePlaceHolder={__('Čas')}
                    onChange={e => this.handleChange('published_from', e)}
                    minDate={moment()}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Publikované do')}</StyledLabel>
                  <DateAndTimerPicker
                    value={data.published_to}
                    datePlaceHolder={__('Dátum')}
                    timePlaceHolder={__('Čas')}
                    onChange={e => this.handleChange('published_to', e)}
                    minDate={data.published_from || moment()}
                  />
                </InputWrapper>
              </Row>
            </GreyBox>
            <Space />
            <Space />

            {data.type === 'strip' && (
              <GreyBox title={__('Obsah notifikácie')}>
                <TextEditorWrapper>
                  <TextEditor
                    onChange={text => this.handleChange('content', text)}
                    text={data.content || ''}
                  />
                </TextEditorWrapper>
              </GreyBox>
            )}
          </Row>
        </ContentWrapper>

        <ButtonRelativeWrapperLeft>
          <Button onClick={() => this.loadData(true)} danger>
            {__('Zahodiť zmeny')}
          </Button>
          <Space />
          <Space />
          <Space />

          <Button
            loading={saveLoading}
            onClick={() => this.saveData(true)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapperLeft>
      </React.Fragment>
    );
  }
}

EshopNotificationDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopNotificationDetail);
