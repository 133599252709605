import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Krajina'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Vytvorené'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Naposledy zmenené'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Popis'),
    clickable: false,
    otherProps: { minWidth: 500 },
  },
];
