import React from 'react';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/shops';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';

class EshopShopsList extends BaseTable {
  constructor(props) {
    super({ ...props });
    this.headers = tableHeaders;
    this.segmentName = __('Predajne');
    this.accessors = [
      'name',
      'country',
      'created_date',
      'updated_date',
      'description',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({
        loading: true,
      });
      const params = Object.assign({}, parameters);
      // if (!params.q) {
      //   params.q = lastSearch;
      // }
      const newData = await AdminAPI.getShopsAction(params);

      this.setState({
        content: this.normalizeColumns(newData.items),
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  handleSyncOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/eshop-shops/${id}`);
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || value === 0 ? value : placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./eshop-shops/${item._id}`, `${item.name || '-'}`, () =>
            this.handleSyncOnClick(item._id),
          ),
        ),
        country: _shapeData(item.country),
        created_date: _shapeData(
          formatDate(item.created_date, FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(item.updated_date, FORMAT_HUMANE_DATETIME),
        ),
        description: _shapeData(item.description),
      });
      return acumulator;
    }, []);
    return result;
  }
  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }
  renderControlBar() {
    const { history } = this.props;
    // const { lastSearchValue } = this.state;
    return (
      <ControllBar
        history={history}
        name={__('Pobočky')}
        // defaultValue={lastSearchValue}
        // onChange={val => this.loadSearchResult(val)}
      >
        <ControlBarButton
          primary
          small
          onClick={() => this.handleNewOnClick('new')}
          icon="plus"
        >
          {__('Vytvoriť novú')}
        </ControlBarButton>
      </ControllBar>
    );
  }
  handleNewOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/eshop-shops/${id}`);
  }
  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopShopsList);
