import { __ } from '../Utils';

export default [
  {
    name: __('Produkty'),
    dropdown: [
      {
        name: __('Exporty'),
        href: '/products-exports',
      },
      {
        name: __('Katalóg'),
        href: '/products',
      },
      {
        name: __('Náterové systémy'),
        href: '/paint-systems',
      },
      {
        name: __('Produktové balíčky'),
        href: '/products-packages',
      },
      {
        name: __('Publikácia produktov'),
        href: '/products-publication',
      },
      {
        name: __('Receptúry'),
        href: '/recipes',
      },
      {
        name: __('Tónovacie systémy'),
        href: '/toningSystems',
      },
      {
        name: __('Vzorkovníky'),
        href: '/colourCards',
      },
      {
        name: __('Skladom u dodávateľa'),
        href: '/on-external-stock',
      },
      {
        name: __('Názvy položiek pre Eshop'),
        href: '/eshop-products-names',
      },
    ],
  },
  {
    name: __('Marketing'),
    dropdown: [
      {
        name: __('Fotobanka'),
        href: '/photos',
      },
      {
        name: __('Grafika'),
        href: '/graphics',
      },
      {
        name: __('Inšpirácie'),
        href: '/inspirations',
      },
      {
        name: __('Inšpirácie - Objekty'),
        href: '/inspirations-objects',
      },
      {
        name: __('Kampane'),
        href: '/campaigns',
      },
      {
        name: __('Marketingový materiál'),
        href: '/marketing-materials',
      },
      {
        name: __('Marketingové zoznamy'),
        href: '/lists',
      },
      {
        name: __('Newsletter'),
        href: '/newsletter',
      },
      {
        name: __('Novinky'),
        href: '/news/news',
      },
      {
        name: __('Referencie'),
        href: '/references',
      },
      {
        name: __('Tlačoviny'),
        href: '/prints',
      },
      {
        name: __('Videá'),
        href: '/videos',
      },
    ],
  },
  {
    name: __('Ceny'),
    dropdown: [
      {
        name: __('Zľavy B2C'),
        href: '/discounts',
      },
      {
        name: __('Marže Tónovanie'),
        href: '/margins',
      },
    ],
  },
  {
    name: __('Nastavenia'),
    dropdown: [
      {
        name: __('Bonusy'),
        href: '/bonuses-settings',
      },
      {
        name: __('Číselníky'),
        href: '/codelists',
      },
      {
        name: __('Emailové šablóny'),
        href: '/email-templates',
      },
      {
        name: __('Emailové šablóny - Prednastavené'),
        href: '/preset-email-templates',
      },
      {
        name: __('PDF šablóny'),
        href: '/pdf-templates',
      },
      {
        name: __('Filtre'),
        href: '/filters',
      },
      {
        name: __('Helpdesk'),
        href: '/helpdesk',
      },
      {
        name: __('Innovatint Import'),
        href: '/innovatint-import',
      },
      {
        name: __('Kategórie produktov'),
        href: '/categories',
      },
      {
        name: __('Nákupné ceny'),
        href: '/purchase-prices',
      },
      {
        name: __('Objednávka'),
        href: '/order-settings',
      },
      {
        name: __('Používatelia'),
        href: '/users',
      },
      {
        name: __('Preklady'),
        href: '/translations',
      },
      {
        name: __('Preklady entit'),
        href: '/translations-entities',
      },
      {
        name: __('Produktové skupiny'),
        href: '/products-groups',
      },
      {
        name: __('Registračné pokladne'),
        href: '/settings/cash-registers',
      },
      {
        name: __('Reporty'),
        href: '/report-tasks',
      },
      {
        name: __('Sklady'),
        href: '/stocks',
      },
      {
        name: __('Triedenie odtieňov'),
        href: '/shade-categories',
      },
      {
        name: __('Typy produktov'),
        href: '/productTypes',
      },
      {
        name: __('Typy zákazníkov'),
        href: '/customerTypes',
      },
      {
        name: __('Značky'),
        href: '/brands',
      },
      {
        name: __('Bezpečnostné upozornenia'),
        href: '/security-alerts',
      },
      {
        name: __('CRM Notifikácie'),
        href: '/crm-notifications',
      },
    ],
  },
  {
    name: __('Dokumentácia'),
    dropdown: [
      {
        name: __('Cenníky'),
        href: '/pricelists',
      },
    ],
  },

  {
    name: __('Eshop'),
    dropdown: [
      {
        name: __('Bannery'),
        href: '/banners/xPaint',
      },
      {
        name: __('Blogové články'),
        href: '/news/blogs',
      },
      {
        name: __('Notifikácie'),
        href: '/eshop-notifications',
      },
      {
        name: __('Obľubené produkty'),
        href: '/favorit-products',
      },
      {
        name: __('Pobočky'),
        href: '/eshop-shops',
      },
      {
        name: __('Spôsoby platby'),
        href: '/payment-methods',
      },
      {
        name: __('Spôsoby doručenia'),
        href: '/delivery-methods',
      },
      {
        name: __('Výpredaje'),
        href: '/sales',
      },
    ],
  },
  {
    name: __('Sklad'),
    dropdown: [
      {
        name: __('Lehoty dodania'),
        href: '/delivery-terms',
      },
    ],
  },
  {
    name: __('Systém'),
    dropdown: [
      {
        name: __('Synchronizácie'),
        href: '/synchronizations',
      },
    ],
  },
  {
    name: __('Pokladne'),
    dropdown: [
      {
        name: __('Zoznam pokladni'),
        href: '/cash-registers',
      },
      {
        name: __('Pokladníci'),
        href: '/cashiers',
      },
    ],
  },
  {
    name: __('Korporátky'),
    dropdown: [
      {
        name: __('Flugger'),
        dropdown: [
          {
            name: __('Bannery flugger'),
            href: '/banners/flugger',
          },
        ],
      },
    ],
  },
  {
    name: __('Dejmark.sk'),
    dropdown: [
      {
        name: __('Portfólio značiek'),
        href: '/brand-portfolio',
      },
      {
        name: __('O nás'),
        href: '/about-us',
      },
      {
        name: __('Zamestnanci'),
        href: '/employers',
      },
      {
        name: __('Štruktúra tímu'),
        href: '/company-info',
      },
      {
        name: __('Bannery'),
        href: '/banners/dejmark',
      },
      {
        name: __('Aktuality'),
        href: '/news/news-dejmark',
      },
    ],
  },
];
