import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewsDetail from '../../../Containers/NewsDetail';
import BlogsList from '../../../Containers/BlogsList';

function Blogs(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={NewsDetail} />
      <Route path={`${match.url}`} component={BlogsList} />
    </Switch>
  );
}

Blogs.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default Blogs;
