import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import { Message, Loader, Label } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import { LANGS } from '../../ContentConfig/languagesArray';
import GreyBox from '../../Components/GreyBox';

const Apps = ['crm', 'portal', 'cashregister', 'xFarby'];

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  padding: ${rem(12)};
  margin-bottom: ${rem(100)};
`;

const Col = styled.div`
  margin-right: ${rem(24)};
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};
`;

const StyledLabeledCheckbox = styled(LabeledCheckbox)`
  margin-left: ${rem(8)};
  margin-right: ${rem(8)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const StockWrapper = styled(GreyBox)`
  padding: ${rem(20)};
  margin-bottom: ${rem(16)};

  .name {
  }

  .main_info {
  }
`;

class StocksSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stocks: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const stocksResult = await AdminAPI.getStocksAction();

      this.setState({
        stocks: stocksResult.items || [],
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Značky sa nenašieli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Nastavenie skladov')} />;
  }

  render() {
    const { error, success, stocks, loading } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <Col>
            {stocks.map(item => {
              return (
                <React.Fragment>
                  <StockWrapper key={item.value}>
                    <div className="name">{`${item._id} - ${item.name}`}</div>
                    <div className="main_info">
                      <Label>{__('Krajiny')}</Label>
                      <Row>
                        {LANGS.map(lang => (
                          <StyledLabeledCheckbox
                            checked={item.firm && item.firm.includes(lang)}
                            labelText={lang}
                            onChange={e => console.log(e)}
                          />
                        ))}
                      </Row>

                      <Label>{__('Zdroj')}</Label>
                      <Row>
                        {Apps.map(app => (
                          <StyledLabeledCheckbox
                            checked={item.source && item.source.includes(app)}
                            labelText={app}
                            onChange={e => console.log(e)}
                          />
                        ))}
                      </Row>

                      <Label>{__('Zobrazené v')}</Label>
                      <Row>
                        {Apps.map(app => (
                          <StyledLabeledCheckbox
                            checked={
                              item.display_in && item.display_in.includes(app)
                            }
                            labelText={app}
                            onChange={e => console.log(e)}
                          />
                        ))}
                      </Row>
                    </div>
                  </StockWrapper>
                  <Space />
                </React.Fragment>
              );
            })}
          </Col>
        </Wrapper>
      </React.Fragment>
    );
  }
}

StocksSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(StocksSettings);
