import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SearchableSelect from '../../Components/SearchableSelect';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { rem } from 'polished';
import { Label } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  display: flex;
`;

const DragWrapperLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
  max-width: ${rem(400)};
`;

const DragWrapper = styled.div`
  display: flex;
  position: relative;

  .drag_and_drop_wrapper {
    background: #f7f7f7;
    border-radius: ${rem(4)};
    padding: ${rem(8)};
    margin-bottom: ${rem(20)};
    overflow: auto;
    flex-direction: column;
  }
`;

const SearchWrapper = styled.div`
  width: ${rem(400)};
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  border-radius: 4px;
  padding: ${rem(10)};
  background: white;
  margin-right: ${rem(12)};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  position: relative;

  .code {
    font-weight: bold;
  }
  .description {
    font-size: ${rem(10)};
  }
`;

class SearchAndOrderItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  componentDidUpdate(oldProps) {
    if (this.props.initialSelected && this.props.initialSelected !== oldProps.initialSelected) {
      this.setState({
        selectedItems: this.props.initialSelected.map((m, index) =>
          Object.assign(m, { id: index }),
        ),
      });
    }
  }

  onSelectChange(e) {
    const { onChange } = this.props;

    this.setState({
      selectedItems: e.map((m, index) => Object.assign(m, { id: index })),
    });

    onChange(e);
  }

  handleRLDDChange(items) {
    this.setState(
      {
        selectedItems: items,
      },
      () => {
        this.props.onChange(this.state.selectedItems);
      },
    );
  }

  render() {
    const { selectedItems } = this.state;
    const { loadOptions } = this.props;

    return (
      <Wrapper>
        <SearchWrapper>
          <SearchableSelect
            loadOptions={loadOptions}
            value={selectedItems}
            handleOnChange={e => this.onSelectChange(e)}
            {...this.props}
          />
        </SearchWrapper>

        {selectedItems && selectedItems.length > 0 && (
          <DragWrapperLabel>
            <Label>{__('Poradie')}</Label>
            <DragWrapper>
              <RLDD
                cssClasses="drag_and_drop_wrapper"
                layout="grid"
                items={selectedItems}
                itemRenderer={item => {
                  return (
                    <Item>
                      <div className="code">{item.raw.code}</div>
                      <div className="description">{item.raw.name}</div>
                    </Item>
                  );
                }}
                onChange={newProps => this.handleRLDDChange(newProps)}
              />
            </DragWrapper>
          </DragWrapperLabel>
        )}
      </Wrapper>
    );
  }
}

SearchAndOrderItems.propTypes = {
  initialSelected: PropTypes.arrayOf(PropTypes.string),
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

SearchAndOrderItems.defaultProps = {
  initialSelected: [],
};

export default SearchAndOrderItems;
