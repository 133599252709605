import React from 'react';
import {
  Button,
  Checkbox,
  Input,
  Label,
  Select,
  Textarea,
} from 'oxyrion-ui/lib';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../Store';
import { __, getWordShapes } from '../../Utils';
import { ButtonRelativeWrapper } from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import SettingsBox from '../../Components/SettingsBox';
import AdminGroupForm from '../../Components/AdminGroupForm';
import API from '../../API';
import MetaDescriptionTextArea from '../../Components/MetaDescriptionTextArea';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import API2 from '../../API2';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.m`
  flex-direction: row;
  `};
`;

const SubWrapper = styled.div`
  width: 100%;
  padding: ${rem(3)};

  ${({ theme }) => theme.media.m`
  width: 50%;
  `};
`;

const StyledInput = styled(Input)`
  width: calc(100% - 30px);
`;

const StyledSelect = styled(Select)`
  width: calc(100% - 10px);
`;

const StyledTextarea = styled(Textarea)`
  margin-top: ${rem(10)};
  width: calc(100% - 30px);
`;

const TranslationsWrapper = styled.div`
  width: 95%;
`;

const DeliveryTerms = styled.div`
  font-size: ${rem(10)};
  margin-left: ${rem(6)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  padding-right: ${rem(5)};
  font-weight: 500;
`;

const AIButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${rem(12)} ${rem(0)} ${rem(6)} ${rem(0)};
`;

const BackButtonWrapper = styled.div`
  padding: ${rem(0)} ${rem(0)} ${rem(0)} ${rem(6)};
`;

const types = [
  {
    name: __('dni'),
    value: 'days',
  },
  {
    name: __('mesiace'),
    value: 'months',
  },
];

class BaseInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      AILoading: false,
      previousDescription: { SK: '', CZ: '', AT: '' },
    };
  }

  componentDidMount() {
    this.fetchTranslationsLogs();
  }

  fetchColorCards(query) {
    try {
      return API2.getColorcardsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
          colorCard: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchToningSystems(query) {
    try {
      return API2.getToningSystemsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.systems.map(item => ({
          value: item._id,
          label: item.name,
          toningSystem: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchTranslationsLogs() {
    try {
      const [
        logName,
        logShortDesc,
        logDesc,
        logMetaTitle,
        logMetaDesc,
      ] = await Promise.all([
        API.getEntintyTranslationsChangesLogAction('catalog-product', 'name', {
          entityItemIds: this.props.id,
        }),
        API.getEntintyTranslationsChangesLogAction(
          'catalog-product',
          'shortDescription',
          {
            entityItemIds: this.props.id,
          },
        ),
        API.getEntintyTranslationsChangesLogAction(
          'catalog-product',
          'description',
          {
            entityItemIds: this.props.id,
          },
        ),
        API.getEntintyTranslationsChangesLogAction(
          'catalog-product',
          'metaTitle',
          {
            entityItemIds: this.props.id,
          },
        ),
        API.getEntintyTranslationsChangesLogAction(
          'catalog-product',
          'metaDescription',
          {
            entityItemIds: this.props.id,
          },
        ),
      ]);

      this.setState({
        logName: logName.items[0],
        logShortDesc: logShortDesc.items[0],
        logDesc: logDesc.items[0],
        logMetaTitle: logMetaTitle.items[0],
        logMetaDesc: logMetaDesc.items[0],
      });
    } catch (e) {
      this.setState({
        logName: {},
      });
    }
  }

  changeLogError = (changeLog, lang) => {
    if (!changeLog || !changeLog.changes_log) {
      return false;
    }

    const d = changeLog.changes_log.find(f => f.lang === lang);
    if (!d) {
      return true;
    }

    return d.checked || false;
  };

  async generateAIDescription(productName, previousContent) {
    const { activeLanguage, baseOnChange } = this.props;
    const { previousDescription } = this.state;

    if (!productName) {
      return;
    }

    try {
      if (!previousDescription[activeLanguage]) {
        previousDescription[activeLanguage] = previousContent;
      }

      this.setState({
        AILoading: true,
        previousDescription,
      });

      const response = await API2.generateAIContentAction({
        lang: activeLanguage,
        body: {
          productName,
          content: 'DESCRIPTION',
          length: 'LONG',
        },
      });

      baseOnChange(
        { target: { value: response.content } },
        'description',
        activeLanguage,
      );
      this.setState({
        AILoading: false,
      });
    } catch (e) {
      previousDescription[activeLanguage] = '';

      this.setState({
        AILoading: false,
        previousDescription,
      });
    }
  }

  backPreviousDescription() {
    const { activeLanguage, baseOnChange } = this.props;
    const { previousDescription } = this.state;

    baseOnChange(
      { target: { value: previousDescription[activeLanguage] } },
      'description',
      activeLanguage,
    );

    previousDescription[activeLanguage] = '';
  }

  render() {
    const {
      notGrouped,
      groups,
      baseOnChange,
      onChange,
      handleUnitChange,
      baseInfo,
      changeLanguageForBaseInfo,
      activeLanguage,
      saveCard,
      productTypes,
      brands,
      subBrands,
      variantParams,
      onDeliveryTermsChange,
    } = this.props;

    const {
      logName,
      logShortDesc,
      logDesc,
      logMetaTitle,
      logMetaDesc,
      AILoading,
      previousDescription,
    } = this.state;

    const fullBrand =
      brands.find(b => b.value.brand === baseInfo.brand) &&
      brands.find(b => b.value.brand === baseInfo.brand).value;

    const delivery_terms =
      baseInfo.delivery_terms || (fullBrand && fullBrand.delivery_terms);

    return (
      <Wrapper>
        <SubWrapper>
          <Label> {__('Typ*')}</Label>
          <StyledSelect
            size="s"
            onChange={e => baseOnChange(e, 'type')}
            value={baseInfo.type}
            // disabled={!this.props.isNew}
          >
            {productTypes.map(item => {
              return (
                <option
                  selected={baseInfo.type === item.value}
                  value={item.value}
                  key={item.key}
                >
                  {item.display_name}
                </option>
              );
            })}
          </StyledSelect>
          <Wrapper>
            <SubWrapper>
              <Label> {__('Produktová skupina*')}</Label>
              <StyledSelect
                size="s"
                onChange={e => baseOnChange(e, 'brand')}
                value={baseInfo.brand}
              >
                <option value="" key="" selected={baseInfo.brand === ''}>
                  -
                </option>
                {brands.map(item => {
                  return (
                    <option
                      selected={baseInfo.type === item.value.brand}
                      value={item.value.brand}
                      key={item.key}
                    >
                      {item.display_name}
                    </option>
                  );
                })}
              </StyledSelect>
            </SubWrapper>
            <SubWrapper>
              <Label> {__('Produktová podskupina*')}</Label>
              <StyledSelect
                size="s"
                onChange={e => baseOnChange(e, 'subBrand')}
                value={baseInfo.subbrand}
              >
                <option value="" key="" selected={baseInfo.subbrand === ''}>
                  -
                </option>
                {subBrands.map(item => {
                  return (
                    <option
                      selected={baseInfo.type === item.value}
                      value={item.value}
                      key={item.key}
                    >
                      {item.display_name}
                    </option>
                  );
                })}
              </StyledSelect>
            </SubWrapper>
          </Wrapper>

          <Label> {__('Lehota dodania')}</Label>

          <Wrapper>
            <SubWrapper>
              <StyledSelect
                size="s"
                onChange={e => onDeliveryTermsChange(e.target.value, 'type')}
              >
                {types.map(s => (
                  <option
                    selected={delivery_terms && s.value === delivery_terms.type}
                    value={s.value}
                  >
                    {s.name}
                  </option>
                ))}

                <option disabled selected={!delivery_terms}>
                  -
                </option>
              </StyledSelect>
            </SubWrapper>
            <SubWrapper>
              <Input
                style={{ width: rem(50) }}
                min="1"
                type="number"
                disabled={!delivery_terms}
                onChange={e => onDeliveryTermsChange(e.target.value, 'value')}
                value={(delivery_terms && delivery_terms.value) || 0}
              />
            </SubWrapper>
          </Wrapper>

          {fullBrand && fullBrand.delivery_terms && (
            <DeliveryTerms>
              {`${__('Dodacia lehota pre túto produktovú skupinu je')} ${
                fullBrand.delivery_terms.value
              } ${getWordShapes(
                fullBrand.delivery_terms.value,
                fullBrand.delivery_terms.type === 'days'
                  ? [__('deň'), __('dní'), __('dní')]
                  : [__('mesiac'), __('mesiace'), __('mesiacov')],
              )}`}
            </DeliveryTerms>
          )}

          <Wrapper>
            <SubWrapper>
              <Label> {__('Klučový parameter pre varianty')}</Label>
              <StyledSelect
                size="s"
                onChange={e => baseOnChange(e, 'key_variant_param')}
                value={baseInfo.key_variant_param}
              >
                <option value="" key="">
                  -
                </option>
                {variantParams.map(item => {
                  return (
                    <option
                      selected={baseInfo.key_variant_param === item.system_name}
                      value={item.system_name}
                      key={item.system_name}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </StyledSelect>
            </SubWrapper>
            <SubWrapper>
              <Label> {__('Exportovať pre Farlesk')}</Label>
              <LabeledCheckbox
                checked={baseInfo.exportable}
                labelText={__('Exportovať pre Farlesk')}
                onChange={e => baseOnChange(e.target.checked, 'exportable')}
              />
            </SubWrapper>
          </Wrapper>
          <Wrapper>
            <SubWrapper>
              <Label> {__('Zoraďovací parameter pre varianty')}</Label>
              <StyledSelect
                size="s"
                onChange={e => baseOnChange(e, 'sort_variant_param')}
                value={baseInfo.sort_variant_param}
              >
                <option value="" key="">
                  -
                </option>
                {variantParams.map(item => {
                  return (
                    <option
                      selected={
                        baseInfo.sort_variant_param === item.system_name
                      }
                      value={item.system_name}
                      key={item.system_name}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </StyledSelect>
            </SubWrapper>
            <SubWrapper>
              <Label> {__('Exportovať pre Obnova s.r.o')}</Label>
              <LabeledCheckbox
                checked={baseInfo.obnova_exportable}
                labelText={__('Exportovať pre Obnova s.r.o')}
                onChange={e =>
                  baseOnChange(e.target.checked, 'obnova_exportable')
                }
              />
            </SubWrapper>
          </Wrapper>

          <Wrapper style={{ justifyContent: 'end', 'margin-left': rem(5) }}>
            <SubWrapper>
              <Label> {__('Exportovať pre SEKO Trenčín')}</Label>
              <LabeledCheckbox
                checked={baseInfo.exportable_for_seko}
                labelText={__('Exportovať pre SEKO Trenčín')}
                onChange={e =>
                  baseOnChange(e.target.checked, 'exportable_for_seko')
                }
              />
            </SubWrapper>
          </Wrapper>

          <Wrapper style={{ justifyContent: 'end', 'margin-left': rem(5) }}>
            <SubWrapper>
              <Label> {__('Exportovať pre Kopelent')}</Label>
              <LabeledCheckbox
                checked={baseInfo.exportable_for_kopelent}
                labelText={__('Exportovať pre Kopelent')}
                onChange={e =>
                  baseOnChange(e.target.checked, 'exportable_for_kopelent')
                }
              />
            </SubWrapper>
          </Wrapper>

          <TranslationsWrapper>
            <LanguageSwitch
              onChange={id => {
                return changeLanguageForBaseInfo(id);
              }}
              activeId={activeLanguage}
            />
            <SettingsBox addDisabled title="" style={{ width: '95%' }}>
              <Label> {__('Url*')}</Label>
              <StyledInput
                placeholder={baseInfo.url_by_lang.SK}
                onChange={e => baseOnChange(e, 'url_by_lang', activeLanguage)}
                value={
                  activeLanguage &&
                  baseInfo.url_by_lang &&
                  baseInfo.url_by_lang[activeLanguage]
                    ? baseInfo.url_by_lang[activeLanguage]
                    : ''
                }
                error={
                  baseInfo.url_by_lang.error ||
                  this.changeLogError(logName, activeLanguage)
                }
              />

              {activeLanguage &&
                baseInfo.url_history &&
                baseInfo.url_history[activeLanguage] &&
                baseInfo.url_history[activeLanguage].length > 0 && (
                  <Label> {__('Aktívne url:')}</Label>
                )}

              {activeLanguage &&
                baseInfo.url_history &&
                baseInfo.url_history[activeLanguage] &&
                baseInfo.url_history[activeLanguage].map(item => (
                  <CheckboxWrapper>
                    <Checkbox
                      id={item.url}
                      checked={item.active}
                      onChange={e =>
                        baseOnChange(e, 'url_history', activeLanguage)
                      }
                    />
                    <StyledLabel> {item.url}</StyledLabel>
                  </CheckboxWrapper>
                ))}

              <Label> {__('Názov produktu*')}</Label>
              <StyledInput
                placeholder={__('Zadajte názov')}
                onChange={e => baseOnChange(e, 'name', activeLanguage)}
                value={
                  activeLanguage &&
                  baseInfo.nameTranslations &&
                  baseInfo.nameTranslations.find(
                    t => t.lang === activeLanguage,
                  ) &&
                  baseInfo.nameTranslations.find(t => t.lang === activeLanguage)
                    .name &&
                  baseInfo.nameTranslations.find(t => t.lang === activeLanguage)
                    .name.length > 0
                    ? baseInfo.nameTranslations.find(
                        t => t.lang === activeLanguage,
                      ).name
                    : baseInfo.name.value
                }
                error={
                  baseInfo.name.error ||
                  this.changeLogError(logName, activeLanguage)
                }
              />
              <Label> {__('Kratký popis')}</Label>
              <StyledTextarea
                placeholder={__(
                  `Sem zadajte kratký popis pre ${activeLanguage} verziu`,
                )}
                onChange={e =>
                  baseOnChange(e, 'shortDescription', activeLanguage)
                }
                value={
                  activeLanguage && baseInfo.shortDescriptionTranslations
                    ? baseInfo.shortDescriptionTranslations.find(
                        t => t.lang === activeLanguage,
                      ) &&
                      baseInfo.shortDescriptionTranslations.find(
                        t => t.lang === activeLanguage,
                      ).description
                    : baseInfo.shortDescription
                }
                error={this.changeLogError(logShortDesc, activeLanguage)}
              />
              <Label> {__('Rozsiahly popis')}</Label>

              <StyledTextarea
                placeholder={__(
                  `Sem zadajte popis pre ${activeLanguage} verziu`,
                )}
                onChange={e => baseOnChange(e, 'description', activeLanguage)}
                value={
                  activeLanguage
                    ? baseInfo.translations.find(
                        t => t.lang === activeLanguage,
                      ) &&
                      baseInfo.translations.find(t => t.lang === activeLanguage)
                        .description
                    : baseInfo.description
                }
                error={this.changeLogError(logDesc, activeLanguage)}
              />
              <AIButtonWrapper>
                <Button
                  small
                  primary
                  loading={AILoading}
                  disabled={AILoading}
                  onClick={() =>
                    this.generateAIDescription(
                      activeLanguage &&
                        baseInfo.nameTranslations &&
                        baseInfo.nameTranslations.find(
                          t => t.lang === activeLanguage,
                        ) &&
                        baseInfo.nameTranslations.find(
                          t => t.lang === activeLanguage,
                        ).name &&
                        baseInfo.nameTranslations.find(
                          t => t.lang === activeLanguage,
                        ).name.length > 0
                        ? baseInfo.nameTranslations.find(
                            t => t.lang === activeLanguage,
                          ).name
                        : baseInfo.name.value,
                      activeLanguage
                        ? baseInfo.translations.find(
                            t => t.lang === activeLanguage,
                          ) &&
                            baseInfo.translations.find(
                              t => t.lang === activeLanguage,
                            ).description
                        : baseInfo.description,
                    )
                  }
                >
                  {__('Vygenerovať pomocou AI')}
                </Button>
                {!AILoading && previousDescription[activeLanguage] && (
                  <BackButtonWrapper>
                    <Button
                      small
                      primary
                      onClick={() => this.backPreviousDescription()}
                    >
                      {__('Vrátiť späť')}
                    </Button>
                  </BackButtonWrapper>
                )}
              </AIButtonWrapper>

              <Label> {__('Meta title')}</Label>

              <StyledInput
                placeholder={`${__(
                  'Zadajte meta title pre',
                )} ${activeLanguage} ${__('verziu')}`}
                onChange={e => baseOnChange(e, 'metatitle', activeLanguage)}
                value={
                  activeLanguage && baseInfo.metaTitleTranslations
                    ? baseInfo.metaTitleTranslations.find(
                        t => t.lang === activeLanguage,
                      ) &&
                      baseInfo.metaTitleTranslations.find(
                        t => t.lang === activeLanguage,
                      ).metaTitle
                    : baseInfo.metaTitle
                }
                error={this.changeLogError(logMetaTitle, activeLanguage)}
              />

              <Label> {__('Meta description')}</Label>

              <MetaDescriptionTextArea
                value={
                  activeLanguage && baseInfo.metaDescriptionTranslations
                    ? baseInfo.metaDescriptionTranslations.find(
                        t => t.lang === activeLanguage,
                      ) &&
                      baseInfo.metaDescriptionTranslations.find(
                        t => t.lang === activeLanguage,
                      ).metaDescription
                    : baseInfo.metaDescription
                }
                placeholder={__(
                  `Sem zadajte meta description popis pre ${activeLanguage} verziu`,
                )}
                onChange={e =>
                  baseOnChange(e, 'metaDescription', activeLanguage)
                }
                error={this.changeLogError(logMetaDesc, activeLanguage)}
              />
            </SettingsBox>
          </TranslationsWrapper>
          <AdminGroupForm
            groups={[{ group: '', params: notGrouped }]}
            onChange={(value, groupIndex, fieldIndex, rowIndex, columnIndex) =>
              onChange(value, -1, fieldIndex, rowIndex, columnIndex)
            }
            handleUnitChange={(
              groupIndex,
              fieldIndex,
              rowIndex,
              columnIndex,
              value,
            ) => handleUnitChange(-1, fieldIndex, rowIndex, columnIndex, value)}
          />
        </SubWrapper>
        <SubWrapper>
          <AdminGroupForm
            groups={groups}
            onChange={(value, groupIndex, fieldIndex, rowIndex, columnIndex) =>
              onChange(value, groupIndex, fieldIndex, rowIndex, columnIndex)
            }
            handleUnitChange={(
              groupIndex,
              fieldIndex,
              rowIndex,
              columnIndex,
              value,
            ) =>
              handleUnitChange(
                groupIndex,
                fieldIndex,
                rowIndex,
                columnIndex,
                value,
              )
            }
          />
        </SubWrapper>

        <ButtonRelativeWrapper>
          <Button onClick={() => saveCard('', '')} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </Wrapper>
    );
  }
}

BaseInfo.propTypes = {
  firm: PropTypes.string,
  productTypes: PropTypes.arrayOf(PropTypes.string),
  brands: PropTypes.arrayOf(PropTypes.string),
  subBrands: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  handleUnitChange: PropTypes.func,
  activeLanguage: PropTypes.string,
  baseOnChange: PropTypes.func,
  isNew: PropTypes.bool,
  changeLanguageForBaseInfo: PropTypes.func,
  saveCard: PropTypes.func.isRequired,
  baseInfo: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    brand: PropTypes.string,
    subbrand: PropTypes.string,
    descriptions: PropTypes.string,
    shortDescription: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      description: PropTypes.string,
    }),
    colorcards: PropTypes.arrayOf({}),
    toningSystems: PropTypes.arrayOf({}),
  }).isRequired,
  notGrouped: PropTypes.arrayOf({
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    units: PropTypes.string,
  }).isRequired,
  groups: PropTypes.arrayOf({
    groupName: PropTypes.string,
    params: PropTypes.arrayOf({
      name: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      units: PropTypes.string,
    }),
  }),
  variantParams: PropTypes.arrayOf({}),
  onDeliveryTermsChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

BaseInfo.defaultProps = {
  groups: [],
  onChange: () => {},
  handleUnitChange: () => {},
  baseOnChange: () => {},
  changeLanguageForBaseInfo: () => {},
  activeLanguage: 'SK',
  isNew: false,
  brands: [],
  subBrands: [],
  productTypes: [],
  firm: 'SK',
  variantParams: [],
  id: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BaseInfo);
