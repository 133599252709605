import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { PopUpList, Icon } from 'oxyrion-ui/lib';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';

const Button = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary}
  border: solid ${rem(1)} ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.baseBorderRadius}
  background-color: white;
  padding: ${rem(5)} ${rem(10)};
  height: 30px;
  font-size:  ${({ theme }) => theme.fontSize || '14px'};
  font-family: ${({ theme }) => theme.fontFamily};

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.color.primary};
    border: solid ${rem(1)} ${({ theme }) => theme.color.primary};
  }
`;

const Wrapper = styled.div`
  position: relative;
  ul {
    z-index: 3;
  }
`;

class ControlButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', e => this.handleOutsideClick(e));
  }

  handleOutsideClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ clicked: false });
    }
  }

  render() {
    const { name, right, left } = this.props;
    return (
      <Wrapper>
        <Button
          onClick={() => this.setState({ clicked: !this.state.clicked })}
          innerRef={ref => (this.wrapperRef = ref)}
        >
          {name}
          {!this.state.clicked ? (
            <Icon name="arrow-down" size="xs" color={baseTheme.color.primary} />
          ) : (
            <Icon name="arrow-top" size="xs" color={baseTheme.color.primary} />
          )}
        </Button>
        <PopUpList
          display={this.state.clicked}
          top={rem(30)}
          right={right ? '0' : 'unset'}
          left={left ? '0' : 'unset'}
          minWidth="120px"
        >
          {this.props.children}
        </PopUpList>
      </Wrapper>
    );
  }
}

ControlButton.propTypes = {
  name: PropTypes.string.isRequired,
  right: PropTypes.bool,
  left: PropTypes.bool,
};

ControlButton.defaultProps = {
  right: true,
  left: false,
};

export default ControlButton;
