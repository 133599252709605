import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Label, Input, Loader } from 'oxyrion-ui/lib';
import { LoaderWrapper } from '../ReusableComponents';
import { __ } from '../../Utils';
import AdminAPI from '../../AdminAPI';

const Wrapper = styled.div`
  margin: ${rem(10)} auto;
  width: 90%;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${rem(8)};
`;

const GroupWrapper = styled.div``;

const NameWrapper = styled.div`
  width: ${rem(200)};
  font-size: ${rem(12)};
  display: flex;
  align-items: center;
  padding-left: ${rem(16)};
  font-weight: bold;
`;
const ValueWrapper = styled.div`
  width: ${rem(130)};
`;

const CustomerName = styled.div`
  font-weight: bold;
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.color.primary};
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(16)};
  padding: ${rem(8)};
  display: flex;
  justify-content: space-between;
`;

class MarginEdit extends React.Component {
  state = {
    data: null,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.customerId &&
      this.props.customerId !== prevProps.customerId
    ) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { customerId } = this.props;

    this.setState({
      data: null,
    });

    try {
      const result = await AdminAPI.getCustomerMarginAction(customerId);
      this.setState({
        data: result,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async saveData() {
    const { data } = this.state;
    const { callback } = this.props;
    try {
      this.setState({
        saveLoading: true,
      });

      const body = {
        customer_id: data.customer_id,
        product_groups: data.margins.product_groups.map(p => ({
          _id: p._id,
          value: Number(p.value),
        })),
        toning_systems: data.margins.toning_systems.map(p => ({
          _id: p._id,
          value: Number(p.value),
        })),
      };

      if (data.margin_id) {
        await AdminAPI.putCustomerMarginAction(data.customer_id, { body });
      } else {
        await AdminAPI.postCustomerMarginAction({ body });
      }

      this.setState({
        saveLoading: false,
      });

      callback();
    } catch (e) {
      console.log(e);
    }
  }

  handleDataChange(value, type, id) {
    const { data } = this.state;

    data.margins[type].find(m => m._id === id).value = value;

    this.setState({
      data,
    });
  }

  render() {
    const { data } = this.state;
    const { callback } = this.props;

    if (!data) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper>
        <Row>
          <CustomerName>{data.customer_name}</CustomerName>
        </Row>
        <GroupWrapper>
          <Row>
            <Label>{__('Skupiny produktov')}</Label>
          </Row>
          {data.margins.product_groups.map(g => (
            <Row>
              <NameWrapper>{g.name}</NameWrapper>
              <ValueWrapper>
                <Input
                  type="number"
                  value={g.value}
                  onChange={e =>
                    this.handleDataChange(
                      e.target.value,
                      'product_groups',
                      g._id,
                    )
                  }
                />
              </ValueWrapper>
            </Row>
          ))}
        </GroupWrapper>

        <GroupWrapper>
          <Label>{__('Tónovacie systémy')}</Label>
          {data.margins.toning_systems.map(g => (
            <Row>
              <NameWrapper>{g.name}</NameWrapper>
              <ValueWrapper>
                <Input
                  type="number"
                  value={g.value}
                  onChange={e =>
                    this.handleDataChange(
                      e.target.value,
                      'toning_systems',
                      g._id,
                    )
                  }
                />
              </ValueWrapper>
            </Row>
          ))}
        </GroupWrapper>
        <ButtonWrapper>
          <Button danger onClick={() => callback()}>
            {__('Zahodiť')}
          </Button>
          <Button
            loading={this.state.saveLoading}
            primary
            onClick={() => this.saveData()}
          >
            {__('Uložiť')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

MarginEdit.propTypes = {
  customerId: PropTypes.string,
  callback: PropTypes.func,
};

MarginEdit.defaultProps = {
  customerId: null,
  callback: () => {},
};

export default MarginEdit;
