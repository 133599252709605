import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import Icon from 'oxyrion-ui/lib/Icon';
import { Tr, Td } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  max-width: ${rem(95)};
  margin-right: ${rem(10)};
`;

const DesktopButton = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.m`
  display: flex;
`};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex
    ${({ theme }) => theme.media.m`
  display: none;
`};
`;

class AddNewB2CDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: props.from,
      to: !props.from && props.over,
      percent: '',
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.from !== this.props.from) {
      this.setState({ from: this.props.from });
    }
    if (oldProps.over !== this.props.over) {
      this.setState({ to: this.props.over });
    }
  }

  handleOnChange(field, value) {
    this.setState({ [field]: value });
  }

  handlePercentInput(value) {
    if (value > 100 || value < 0) {
      this.setState({ percentError: true, percent: value });
    } else {
      this.setState({ percentError: false, percent: value });
    }
  }

  checkData() {
    const { unregistered } = this.props;
    const { from, to, percent, fromError, toError, percentError } = this.state;

    if (unregistered) {
      return (
        !fromError && !toError && !percentError && to !== '' && percent !== ''
      );
    }

    return (
      !fromError &&
      !toError &&
      !percentError &&
      from !== '' &&
      to !== '' &&
      percent !== ''
    );
  }

  submit() {
    const { onSubmit, unregistered } = this.props;
    const { from, to, percent } = this.state;

    if (this.checkData()) {
      if (unregistered) {
        this.setState({ to: parseInt(to, 10) + 1, percent: '' });
        onSubmit(to, percent);
      } else {
        this.setState({ from: to, to: '', percent: '' });
        onSubmit(from, to, percent);
      }
    }
  }

  render() {
    const { from, to, percent, percentError, fromError, toError } = this.state;
    const { unregistered } = this.props;
    return (
      <Tr>
        {!unregistered && (
          <Td>
            <ValueInputWrapper>
              <StyledInput
                width="100%"
                type="number"
                error={fromError}
                value={from}
                placeholder={__('Od')}
                onChange={e => {
                  this.handleOnChange('from', e.target.value);
                }}
              />
            </ValueInputWrapper>
          </Td>
        )}
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              error={toError}
              value={to}
              placeholder={unregistered ? __('Nad') : __('Do')}
              onChange={e => {
                this.handleOnChange('to', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              min="0"
              max="100"
              value={percent}
              placeholder={__('%')}
              error={percentError}
              onChange={e => {
                this.handlePercentInput(e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <DesktopButton
            primary
            small
            disabled={
              !unregistered
                ? toError ||
                  fromError ||
                  percentError ||
                  from === '' ||
                  to === '' ||
                  percent === ''
                : toError || percentError || to === '' || percent === ''
            }
            loading={this.props.buttonLoading}
            onClick={() => this.submit()}
          >
            {__('Pridať')}
          </DesktopButton>
          <StyledIcon
            name="plus"
            onClick={() => this.submit()}
            color="#ee6500"
          />
        </Td>
      </Tr>
    );
  }
}

AddNewB2CDiscount.propTypes = {
  from: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  over: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  unregistered: PropTypes.bool,
  buttonLoading: PropTypes.bool,
};

AddNewB2CDiscount.defaultProps = {
  from: '',
  over: '',
  unregistered: false,
  buttonLoading: false,
};

export default AddNewB2CDiscount;
