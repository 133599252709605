import React from 'react';
import API2 from '../../API2';
import headers from '../../ContentConfig/users';
import { __, renderLink, roleDecoder } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import BaseTable from '../BaseTable';

class Users extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'display_name' });
    this.headers = headers;
    this.segmentName = __('Produkty');
    this.accessors = [
      'name',
      'forename',
      'surname',
      'email',
      'role',
      'seller_code',
      'isNotificationEnabled',
    ];
    this.showBulkOperations = false;
    this.setSorter('display_name');
    this.state = {
      ...this.state,
    };
  }

  handleProductOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/users/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter } = this.state;
      this.setState({ loading: true });
      const params = Object.assign({}, parameters, { sorter });
      const newData = await API2.getAllUsersAction(params);
      const content = this.normalizeColumns(newData.users);
      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null) => {
      return {
        value: value || '-',
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, user) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./users/${user._id}`, `${user.display_name}`),
          () => this.handleProductOnClick(user._id),
        ),
        forename: _shapeData(user.forename),
        surname: _shapeData(user.surname),
        email: _shapeData(user.email),
        role: _shapeData(roleDecoder(user.role)),
        seller_code: _shapeData(
          user.seller_code ? user.seller_code.join(', ') : '-',
        ),
        isNotificationEnabled: _shapeData(
          user.isNotificationEnabled
            ? __('Áno')
            : __('Nie'),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar
        history={history}
        name={__('Používatelia')}
        defaultValue={this.state.lastSearchValue}
        onChange={(val) => this.loadSearchRrsult(val)}
      />
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Users);
