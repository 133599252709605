import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Segment } from 'oxyrion-ui/lib';
import { FlexBox, H2, SegmentWrapper, Close } from '../ReusableComponents';

const MainWrapper = styled.div`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 100;
  background-color: #0008;
`;

const Wrapper = styled.div`
  background-color: white;
  position: relative;
  left: 0;
  right: 0;
  height: auto;
  max-height: 95vh;
  min-height: ${rem(200)};

  overflow: ${({ scroll }) => (scroll ? 'auto' : 'visible')};
  -webkit-overflow-scrolling: touch;
  max-width: ${({ theme }) => theme.maxWidth || '1920px'};
  max-width: ${({ theme }) => theme.maxWidth || '1920px'}
    ${({ theme }) => theme.media.m`
    width: ${({ small, customWidth }) =>
      small ? '40%' : customWidth ? rem(customWidth) : '80%'}
  `}
    ${({ theme }) => theme.media.l`
    width: ${({ small, customWidth }) =>
      small ? '30%' : customWidth ? rem(customWidth) : '75%'}
  `};
  min-height: ${({ noMinHeight }) => noMinHeight && 'auto'};
`;

const Flex = styled(FlexBox)`
  justify-content: space-between;
  padding: 0;
`;

const CustomH2 = styled(H2)`
  padding-left: ${rem(10)};
`;

const CustomSegmentWrapper = styled(SegmentWrapper)`
  position: sticky;
  top: 0;
  z-index: 1000;
  div {
    padding: ${rem(5)};
  }
`;

class PopUpWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      escClosed: false,
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', e => this.escFunction(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { onEscClose, display } = this.props;
    const { escClosed } = this.state;
    if (
      onEscClose &&
      escClosed &&
      escClosed !== prevState.escClosed &&
      display &&
      display !== prevState.display
    ) {
      this.props.onEscClose();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.escFunction(e));
  }

  escFunction(event) {
    this.setState({
      escClosed: false,
    });
    document.removeEventListener('keydown', e => this.escFunction(e));

    if (event.keyCode === 27) {
      // console.log('---e');
      // console.log(e.keyCode);
      this.setState({
        escClosed: true,
      });
    }
  }

  render() {
    const {
      message,
      onClose,
      display,
      small,
      large,
      noMinHeight,
      scroll,
      customWidth,
      titleZIndex,
    } = this.props;
    return (
      <MainWrapper display={display} tabindex="-1">
        <Wrapper
          display={display}
          small={small}
          large={large}
          noMinHeight={noMinHeight}
          scroll={scroll}
          customWidth={customWidth}
        >
          <CustomSegmentWrapper zIndex={titleZIndex}>
            <Segment secondary>
              <Flex>
                <CustomH2>{message}</CustomH2>
                <Close
                  onClick={() => onClose()}
                  src="/images/icons/close_transparent.svg"
                  height="32px"
                />
              </Flex>
            </Segment>
          </CustomSegmentWrapper>
          {this.props.children}
        </Wrapper>
      </MainWrapper>
    );
  }
}

PopUpWrapper.propTypes = {
  display: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onEscClose: PropTypes.func,
  small: PropTypes.bool,
  large: PropTypes.bool,
  scroll: PropTypes.bool,
  noMinHeight: PropTypes.bool,
  width: PropTypes.string,
  customWidth: PropTypes.number,
  titleZIndex: PropTypes.number,
};

PopUpWrapper.defaultProps = {
  display: false,
  small: false,
  large: false,
  scroll: true,
  message: '',
  noMinHeight: false,
  customWidth: null,
  titleZIndex: 2,
};

export default PopUpWrapper;
