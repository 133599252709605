import React from 'react';
import styled from 'styled-components';
import { Select, Button } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import { __ } from '../../../Utils';
import { connect } from '../../../Store';
import AdminAPI from '../../../AdminAPI';

const ExportRow = styled.div`
  padding: ${rem(24)};

  .label {
    font-size: ${rem(16)};
    font-weight: bold;
    margin-bottom: ${rem(12)};
  }

  .row {
    display: flex;
    align-items: center;
  }

  .space {
    width: ${rem(12)};
    height: ${rem(12)};
  }
`;

class Exports extends React.Component {
  state = {
    teLang: 'SK',
    exportLoading: false,
  };

  async downloadFile() {
    try {
      this.setState({
        exportLoading: true,
      });

      const file = await AdminAPI.getCatalogProductsExportAction(
        this.state.teLang,
      );

      window.location.href = file.url;
      this.setState({
        exportLoading: false,
      });
    } catch (e) {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    const { teLang, exportLoading } = this.state;

    return (
      <div>
        <ExportRow>
          <div className="row">
            <Select
              style={{ width: '300px' }}
              size="s"
              onChange={e =>
                this.setState({
                  teLang: e.target.value,
                })
              }
            >
              <option selected={teLang === 'SK'} value="SK">
                SK
              </option>
              <option selected={teLang === 'CZ'} value="CZ">
                CZ
              </option>
            </Select>
            <div className="space" />
            <Button
              loading={exportLoading}
              onClick={() => this.downloadFile()}
              small
              primary
            >
              {__('Exportovať')}
            </Button>
          </div>
        </ExportRow>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Exports);
