import React from 'react';
import styled from 'styled-components';
import PageNavigator from 'oxyrion-ui/lib/PageNavigator';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from 'oxyrion-ui/lib/Loader';
import Img from 'react-image';
import { rem } from 'polished';
import { __ } from '../../Utils';
import { connect } from '../../Store';
import API from '../../API';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(100)}, auto));
  grid-gap: 0rem;
  padding: ${rem(8)};
`;

const ImageWrapper = styled.div`
  padding: ${rem(2)};
  min-height: ${rem(80)};
  min-width: ${rem(80)};
`;

const Image = styled(Img)`
  height: ${rem(80)};
  max-width: ${rem(130)};
  cursor: pointer;
`;

const Alert = styled.div`
  position: absolute;
  right: ${rem(4)};
  height: ${rem(46)};
  width: ${rem(300)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: green;
  border-radius: ${rem(4)};
`;

const PagerWrapper = styled.div`
  padding: ${rem(16)};
  display: flex;
  justify-content: flex-end;
`;

const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

class ImagesManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      showAlert: false,
      limit: 100,
      offset: 0,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  onPageClick(page) {
    const { limit } = this.state;
    this.setState({
      offset: (page - 1) * limit,
    });
  }
  onIncrease() {
    const { offset, limit } = this.state;
    this.setState({
      offset: offset + limit,
    });
  }
  onDecrease() {
    const { limit, offset } = this.state;
    this.setState({
      offset: offset - limit,
    });
  }

  showAlert() {
    this.setState({
      showAlert: true,
    });

    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 1500);
  }

  async fetchData() {
    try {
      const images = await API.getAllImagesPathsAction(this.props.firm);

      this.setState({
        images,
        total: images.length,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  render() {
    const { images, showAlert, offset, limit, total } = this.state;

    return (
      <React.Fragment>
        {showAlert && <Alert>{__('Cestá k obrázku bola skopírovaná')}</Alert>}

        <Wrapper>
          {images.slice(offset, offset + limit).map(i => (
            <ImageWrapper title={`${baseURL}/images/0x0${i}`}>
              <CopyToClipboard
                onCopy={() => {
                  this.showAlert();
                }}
                text={`${baseURL}/images/0x0${i}`}
              >
                <Image
                  src={[`${baseURL}/images/70x0${i}`]}
                  loader={<Loader color="#70af00" />}
                />
              </CopyToClipboard>
            </ImageWrapper>
          ))}
        </Wrapper>
        <PagerWrapper>
          <PageNavigator
            activePage={offset / limit + 1}
            end={total / limit + 1}
            onPageClick={page => this.onPageClick(page)}
            onDecrease={() => this.onDecrease()}
            onIncrease={() => this.onIncrease()}
          />
        </PagerWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ImagesManager);
