import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/cashRegisters';

import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';

class CashRegisters extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Cash Registers');
    this.showBulkOperations = false;
    this.accessors = ['id', 'name', 'created_date', 'shop'];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      states: [],
      lastSearch: '',
      types: [],
      limit: 100,
    };
  }

  handleItemClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/cash-registers/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, limit } = this.state;
      this.setState({ loading: true });

      const params = Object.assign({}, parameters, { sorter, limit });
      params.firm = this.props.firm;

      const newData = await AdminAPI.getCashRegistersAction(params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: _shapeData(
          renderLink(`./cash-registers/${item._id}`, `${item._id || '-'}`),
          () => this.handleItemClick(item._id),
        ),
        name: _shapeData(item.name),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        shop: _shapeData(item.shop && item.shop.name),
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Registračné pokladne')}
      />
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashRegisters);
