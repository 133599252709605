import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProductsPackageDetail from '../../../Containers/ProductsPackageDetail';
import ProductsPackagesList from '../../../Containers/ProductsPackagesList';

function ProductsPackages(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={ProductsPackageDetail} />
      <Route path={`${match.url}`} component={ProductsPackagesList} />
    </Switch>
  );
}

ProductsPackages.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsPackages;
