import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Input from 'oxyrion-ui/lib/Input';
import styled from 'styled-components';
import { rem } from 'polished';

const StyledInputSmall = styled(Input)`
  max-width: ${rem(50)};
  padding: 0;
  min-width: ${rem(35)};
  margin-right: ${rem(10)};
`;

class CustomNumberInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      innerValue: '',
    };
  }

  componentDidMount() {
    this.setState({
      innerValue: this.props.value,
    });
  }


  handleOnChange(e) {
    const { value } = e.target;

    const re = new RegExp('^[0-9]*[.,]?[0-9]*$');
    if (re.test(value)) {
      const nreValue = value.replace(',', '.');
      e.target.value = nreValue;

      this.setState(
        {
          innerValue: nreValue,
        },
        () => this.props.onChange(this.state.innerValue),
      );
    }
  }

  // updateUpData() {
  //   this.props.onChange(this.state.innerValue);
  // }

  render() {
    const { width } = this.props;
    const { innerValue } = this.state;

    return (
      <StyledInputSmall
        width={width}
        type="text"
        value={innerValue}
        onChange={e => this.handleOnChange(e)}
      />
    );
  }
}

CustomNumberInput.propTypes = {
  width: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.number, PropTypes.string, PropTypes.any]),
  onChange: PropTypes.func,
};

CustomNumberInput.defaultProps = {
  width: '100%',
  value: '',
  onChange: () => {},
};

export default CustomNumberInput;
