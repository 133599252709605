import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Message from 'oxyrion-ui/lib/Message';
import Loader from 'oxyrion-ui/lib/Loader';
import { Label } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';

import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';
import EmailTemplateEditor from '../../Components/Editor/emailTemplateEditor';

class PresetEmailTemplateDetail extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;

    this.state = {
      _id: null,
      loading: true,
      presetEmailTemplateGeneralData: {
        name: '',
        json_data: null,
        html_data: null,
      },
      selectedLang: null,
      newData: null,
      currentJson: null,
      currentHtml: null,
      showInfo: true,
    };
  }

  async componentDidMount() {
    const presetEmailTemplateId = getIdFromProps(this.props);

    if (presetEmailTemplateId !== 'new') {
      await this.fetchData(presetEmailTemplateId);
    } else {
      this.setState({ loading: false });
    }
  }

  async fetchData(presetEmailTemplateId) {
    try {
      const res = await AdminAPI.getPresetEmailTemplateAction(
        presetEmailTemplateId,
      );

      this.setState({
        _id: res._id,
        presetEmailTemplateGeneralData: {
          name: res.name,
          json_data: res.json_data,
          html_data: res.html_data,
        },
        selectedLang: null,
        currentJson: res.json_data,
        currentHtml: res.html_data,
        loading: false,
        error: undefined,
        success: undefined,
        hasUnsaved: false,
      });
    } catch (e) {
      this.setState({
        error: __('Mailovú šablónu sa nepodarilo načítať'),
      });
      console.log(e);
    }
  }

  handleGeneralPresetEmailTemplateData(e, field) {
    const { presetEmailTemplateGeneralData } = this.state;
    if (field === 'name') {
      presetEmailTemplateGeneralData.name = e.target.value;
    }
    this.setState({
      presetEmailTemplateGeneralData,
      hasUnsaved: true,
    });
  }

  saveCurrentJson(currentJson, currentHtml) {
    this.setState({ currentJson, currentHtml }, () => {
      this.savePresetEmailTemplate();
    });
  }

  async savePresetEmailTemplate() {
    const {
      presetEmailTemplateGeneralData,
      currentJson,
      currentHtml,
      _id,
    } = this.state;

    const params = {};

    params.body = Object.assign({}, presetEmailTemplateGeneralData, {
      json_data: currentJson,
      html_data: currentHtml,
    });

    try {
      let res = null;

      if (_id) {
        res = await AdminAPI.putPresetEmailTemplateAction(_id, params);

        this.setState({
          presetEmailTemplateGeneralData: {
            name: res.name,
            json_data: res.json_data,
            html_data: res.html_data,
          },
          success: __('Prednastavená emailová šablóna úspešne uložená'),
          hasUnsaved: false,
        });
      } else {
        res = await AdminAPI.postPresetEmailTemplateAction(params);
        this.props.history.push(
          `/${this.props.firm}/preset-email-templates/${res._id}`,
        );

        this.setState({
          presetEmailTemplateGeneralData: {
            name: res.name,
            json_data: res.json_data,
            html_data: res.html_data,
          },
          success: __('Prednastavená emailová šablóna úspešne uložená'),
          hasUnsaved: false,
        });
      }
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    }
  }

  handleLangChange(lang) {
    const { json_data } = this.state.presetEmailTemplateGeneralData;

    this.setState({
      selectedLang: lang,
      newData: json_data,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { showInfo } = this.state;
    return (
      <React.Fragment>
        <ControllBar
          history={history}
          name={__('Prednastavená emailová šablóna')}
        >
          <ControlBarButton
            onClick={() =>
              this.setState({
                showInfo: !showInfo,
              })
            }
            primary
            small
            icon={showInfo ? 'arrow-top' : 'arrow-down'}
          >
            {showInfo ? __('Skryť detail') : __('Zobraziť detail')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      nameError,
      error,
      loading,
      success,
      showInfo,
      selectedLang,
      newData,
      hasUnsaved,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        {error && (
          <ErrorWrapper>
            <Message message={error} error />
          </ErrorWrapper>
        )}
        {success && (
          <ErrorWrapper>
            <Message message={success} />
          </ErrorWrapper>
        )}

        <AnimatedFormMessageWrapper display={showInfo}>
          <Wrapper>
            <SubWrapper>
              <StyledLabel>
                {__('Názov prednastavenej mailovej šablóny*')}
              </StyledLabel>
              <StyledInput
                placeholder={__('Zadajte názov')}
                onChange={e =>
                  this.handleGeneralPresetEmailTemplateData(e, 'name')
                }
                value={this.state.presetEmailTemplateGeneralData.name}
                error={nameError}
              />

              {hasUnsaved && (
                <div className="unsaved">{__('Neuložené zmeny')}</div>
              )}

              {/* <SaveAllButton>
                <Button primary onClick={() => this.savePresetEmailTemplate()}>
                  {__('Uložiť prednastavenú šablónu')}
                </Button>
              </SaveAllButton> */}
            </SubWrapper>
          </Wrapper>
        </AnimatedFormMessageWrapper>

        <EmailTemplateEditor
          jsonData={newData}
          setEditor={e => {
            this.editor = e;
          }}
          lang={selectedLang}
          onSave={(json, html) => this.saveCurrentJson(json, html)}
          onDesignLoad={() => this.handleLangChange('Prednastavená šablóna')}
          hasUnsaved={updates => this.setState({ hasUnsaved: true })}
        />
      </React.Fragment>
    );
  }
}

const StyledInput = styled(Input)`
  width: ${rem(400)};
`;

const ErrorWrapper = styled.div``;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .unsaved {
    padding-left: ${rem(8)};
    margin-top: 8px;
    font-size: 14px;
    color: gray;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding-left: ${rem(8)};
  padding-right: ${rem(8)};
  flex-direction: column;
  margin-bottom: ${rem(12)};
  justify-content: flex-start;
  ${({ theme }) => theme.media.l`
    flex-direction: row;
    // justify-content: space-between
  `};
`;

const StyledLabel = styled(Label)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  min-height: 14px;
`;

PresetEmailTemplateDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PresetEmailTemplateDetail);
