import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
// import 'react-table-hoc-fixed-columns/lib/styles.css';
import styled from 'styled-components';
import { rem } from 'polished';
import { Loader } from 'oxyrion-ui/lib';
import IconTd from '../IconTd';
import { LoaderWrapper } from '../ReusableComponents';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Wrapper = styled.div`
  //   width: 100%;
  //   .ReactTable.-striped .rt-tr.-odd {
  //     background-color: #eee;
  //   }
  //   .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  //     background-color: #c1d6e8;
  //   }

  //   .ReactTable .rt-thead {
  //     overflow-y: scroll;
  //     overflow-x: hidden;
  //   }

  //   .ReactTable .rt-tbody {
  //     overflow-y: scroll;
  //   }
  //
`;

class TableV2 extends React.PureComponent {
  useCustomheader(columns) {
    return columns.map(i => ({
      Header: () => <IconTd>{i.name}</IconTd>,
      ...i,
    }));
  }

  render() {
    const props = Object.assign({}, this.props, {
      columns: this.useCustomheader(this.props.columns),
    });

    const { data, loading } = this.props;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper>
        <ReactTableFixedColumns
          {...props}
          resizable={false}
          pageSize={data.length}
          getTdProps={() => {
            return { style: { padding: 0, margin: 'auto' } };
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return Object.assign(
              {},
              {
                style: {
                  boxShadow: 'unset',
                  // padding: `${rem(10)} 0`,
                  borderBottom: `solid ${rem(0.5)} #eee`,
                  top: 0,
                },
              },
              this.props.getTheadProps(state, rowInfo, column, instance),
            );
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return Object.assign(
              {},
              {
                style: {
                  outline: 'none',
                },
                onClick: () => {},
              },
              this.props.getTheadThProps(state, rowInfo, column, instance),
            );
          }}
          style={{
            maxHeight: `${window.innerHeight - this.props.offset}px`, // This will force the table body to overflow and scroll, since there is not enough room
          }}
          showPagination={false}
        />
      </Wrapper>
    );
  }
}

TableV2.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      clickable: PropTypes.bool,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      handleOnClick: PropTypes.func,
    }),
  ).isRequired,
  loading: PropTypes.bool,
  sorter: PropTypes.string,
  emptyMessage: PropTypes.string,
  rowOnChange: PropTypes.func.isRequired,
  allRowsOnChange: PropTypes.func.isRequired,
  checkedRows: PropTypes.arrayOf(PropTypes.string),
  treshold: PropTypes.number,
  showBulkOperations: PropTypes.bool,
  disableSticky: PropTypes.bool,
  allRowsChecked: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  getTheadProps: PropTypes.func,
  offset: PropTypes.number,
};

TableV2.defaultProps = {
  loading: false,
  sorter: '',
  treshold: 1,
  emptyMessage: 'Ľutujeme, zoznam je prázdny',
  offset: 160,
  checkedRows: [],
  showBulkOperations: false,
  disableSticky: false,
  allRowsChecked: false,
  fixedHeader: false,
  getTheadProps: () => {},
};

export default TableV2;
