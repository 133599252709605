import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CatalogCheckbox from '../CatalogCheckbox';
import { translateCodelist } from '../../../../Utils';
import Select from 'oxyrion-ui/lib/Select';

const Wrapper = styled.div`
  margin-bottom: ${rem(2)};
`;

const StyledSelect = styled(Select)`
  max-width: ${rem(200)};
`;

const Title = styled.div`
  display: flex;
  font-size: ${rem(12)};
  font-weight: 700;
  color: #434245;
  margin-bottom: ${rem(10)};
`;

class NodeFilter extends React.Component {
  onFilterSelectChange(value) {
    const { onFilterSelectChange, data } = this.props;

    const filterValue = data.values.find(v => v.value === value);

    onFilterSelectChange(
      data.product_value._id,
      filterValue ? filterValue.value : '',
    );
  }

  render() {
    const { data, firm, onFilterCheckboxChange, checkedValues } = this.props;
    return (
      <Wrapper>
        {data.type === 'checkbox' ? (
          <React.Fragment>
            <Title>{data.name}</Title>
            {data.values.map(a => {
              return (
                <CatalogCheckbox
                  label={translateCodelist(firm, a.translations, a.label)}
                  onChange={e =>
                    onFilterCheckboxChange(data.product_value._id, e, a.logic)
                  }
                  value={a.id || a.value}
                  checked={
                    checkedValues.find(c => c.id === data.product_value._id) &&
                    checkedValues.find(c => c.id === data.product_value._id)
                      .values &&
                    checkedValues
                      .find(c => c.id === data.product_value._id)
                      .values.indexOf(a.id || a.value) > -1
                  }
                />
              );
            })}
          </React.Fragment>
        ) : (
          <StyledSelect
            size="xs"
            description={data.name}
            placeholder={data.name}
            onChange={e => this.onFilterSelectChange(e.target.value)}
          >
            <option
              value={undefined}
              //   selected={}
            >
              {data.name}
            </option>

            {data.values.map(item => {
              return (
                <option
                  value={item.value}
                  selected={
                    checkedValues.find(c => c.id === data.product_value._id) &&
                    checkedValues.find(c => c.id === data.product_value._id)
                      .values &&
                    checkedValues
                      .find(c => c.id === data.product_value._id)
                      .values.indexOf(item.id || item.value) > -1
                  }
                >
                  {item.label}
                </option>
              );
            })}
          </StyledSelect>
        )}
      </Wrapper>
    );
  }
}

NodeFilter.propTypes = {
  data: PropTypes.shape({}),
  checkedValues: PropTypes.arrayOf({}),
  onFilterCheckboxChange: PropTypes.func.isRequired,
  onFilterSelectChange: PropTypes.func.isRequired,
  firm: PropTypes.string,
};

NodeFilter.defaultProps = {
  data: [],
  checkedValues: [],
  firm: 'SK',
};

export default NodeFilter;
