import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProductsPublication from '../../../Containers/ProductsPublication';

function ProductsPublicationCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={ProductsPublication} />
    </Switch>
  );
}

ProductsPublicationCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsPublicationCard;
