import React from 'react';
import PropTypes from 'prop-types';
import Input from 'oxyrion-ui/lib/Input';

class OwnStateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleOnChange(value) {
    const { onChange } = this.props;
    this.setState(
      {
        value,
      },
      () => {
        onChange(value);
      },
    );
  }

  render() {
    const { value } = this.state;
    return (
      <Input
        {...this.props}
        value={value}
        onChange={e => {
          this.handleOnChange(e.target.value);
        }}
      />
    );
  }
}

OwnStateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

OwnStateInput.defaultProps = {
  value: null,
};

export default OwnStateInput;
