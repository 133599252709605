import { __ } from '../../../Utils';

export const catalogDescTranslate = (lang, translations = [], defaultValue) => {
  const translation = translations.find(t => t.lang === lang);

  if (translation && translation.description.length > 0) {
    return translation.description;
  }
  return defaultValue;
};

export const getVariantLabel = count => {
  if (count === 1) {
    return __('1 variant');
  }

  if (count < 5) {
    return `${count} ${__('varianty')}`;
  }

  return `${count} ${__('variant')}`;
};

export const normalizeVariants = data => {
  if (!data) {
    return [];
  }

  return data
    .map(d =>
      [d].concat(
        d.complementarsProducts
          ? d.complementarsProducts.map(c =>
              Object.assign(c, {
                type: 'compProduct',
                parentId: d.variantId,
              }),
            )
          : [],
      ),
    )
    .flat();
};

export const normalizeVariantsAndCheckSelected = (data, selectedArray) => {
  return data
    .map(d =>
      [d].concat(
        d.complementarsProducts &&
          selectedArray.indexOf(d.variantId || d._id) > -1
          ? d.complementarsProducts.map(c =>
              Object.assign(c, {
                type: 'compProduct',
                parentId: d.variantId,
              }),
            )
          : [],
      ),
    )
    .flat();
};
