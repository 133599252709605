import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import Loader from 'oxyrion-ui/lib/Loader';
import styled from 'styled-components';
import NodeFilter from '../NodeFilter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${rem(5)};
  min-width: ${rem(230)};
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: ${rem(1)};
`;

const Level = styled.div`
  margin-bottom: ${rem(8)};
`;

const LevelHeader = styled.div`
  cursor: pointer;
  display: flex;
  font-size: ${rem(12)};
  font-weight: 700;
  margin-bottom: ${rem(10)};
  font-weight: ${props => (props.selected ? 'bold' : 'regular')};
  color: ${props => (props.selected ? '#ee6500' : '#434245')};
  &:hover {
    text-decoration: underline;
  }
`;

class CombinedFilter extends React.Component {
  renderFilterLevel(d, levelCount) {
    const { onFilterChange, checkedValues } = this.props;
    const selected =
      d.filter_value &&
      d.filter_value.value &&
      d.filter_value.field &&
      checkedValues.find(cv => cv.id === d.filter_value.field) &&
      checkedValues
        .find(cv => cv.id === d.filter_value.field)
        .values.indexOf(d.filter_value.value) > -1;
    return (
      <Level
        style={{
          marginLeft: levelCount < 2 ? rem(4) : rem(16),
          marginBottom: d.filter ? rem(16) : rem(2),
        }}
      >
        {d.filter_value && (
          <LevelHeader
            onClick={() =>
              onFilterChange(
                d.filter_value.field,
                d.filter_value.value,
                'OR',
                '',
                false,
                levelCount,
              )
            }
            selected={selected}
          >
            {d.filter_value.desc}
          </LevelHeader>
        )}
        {this.renderFilterCore(d, levelCount + 1, selected)}
      </Level>
    );
  }

  renderFilterCore(d, nextLevel, selected) {
    const { data, checkedValues, onFilterChange } = this.props;

    if (d.childrens) {
      return d.childrens.map(c => {
        if (selected || nextLevel === 1) {
          return this.renderFilterLevel(c, nextLevel);
        }
        return <d />;
      });
    } else if (d.filter) {
      if (selected || nextLevel === 1) {
        return d.filter.map(f => {
          const v = data.node_filters.find(n => n._id === f);

          return (
            <NodeFilter
              data={v}
              onFilterCheckboxChange={(field, value, logic) =>
                onFilterChange(field, value, logic, 'checkbox', true, nextLevel)
              }
              onFilterSelectChange={(field, value, logic) =>
                onFilterChange(field, value, logic, 'select', true, nextLevel)
              }
              checkedValues={checkedValues}
            />
          );
        });
      }
      return <d />;
    }
    return <div />;
  }

  prepareData(structure, filtersShowOnly) {
    if (!filtersShowOnly || filtersShowOnly.length === 0) {
      return structure;
    }

    // zatial vyriesene iba do urovne 2 - ak bude treba viac = rekurzia
    const newStructure = structure &&
      structure.childrens && {
        _id: structure._id,
        childrens: structure.childrens
          .filter(
            ch =>
              !filtersShowOnly.find(f => f.id === ch.filter_value.field) ||
              (filtersShowOnly.find(f => f.id === ch.filter_value.field) &&
                filtersShowOnly
                  .find(f => f.id === ch.filter_value.field)
                  .values.indexOf(ch.filter_value.value) > -1),
          )
          .map(a => {
            return {
              filter_value: a.filter_value,
              childrens: a.childrens.filter(
                ch =>
                  !filtersShowOnly.find(f => f.id === ch.filter_value.field) ||
                  (filtersShowOnly.find(f => f.id === ch.filter_value.field) &&
                    filtersShowOnly
                      .find(f => f.id === ch.filter_value.field)
                      .values.indexOf(ch.filter_value.value) > -1),
              ),
            };
          }),
      };

    return newStructure;
  }

  render() {
    const { data, loading, filtersShowOnly } = this.props;

    const structure = this.prepareData(data.structure, filtersShowOnly);

    return (
      <Wrapper>
        <LoadingWrapper>{loading && <Loader color="#434245" />}</LoadingWrapper>
        {data.structure &&
          data.structure &&
          this.renderFilterLevel(structure, 0)}
      </Wrapper>
    );
  }
}

CombinedFilter.propTypes = {
  data: PropTypes.arrayOf({}),
  checkedValues: PropTypes.arrayOf({
    id: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  onFilterChange: PropTypes.func,
  loading: PropTypes.bool,
  firm: PropTypes.string,
  disabled: PropTypes.bool,
};

CombinedFilter.defaultProps = {
  checkedValues: [],
  data: [],
  loading: false,
  disabled: false,
  firm: 'SK',
  onFilterChange: () => {},
};
export default CombinedFilter;
