import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PurchasePricesSettings from '../../../Containers/PurchasePricesSettings';

function ProductsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={PurchasePricesSettings} />
    </Switch>
  );
}

ProductsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsCard;
