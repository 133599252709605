import React, { Component } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { Textarea } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  outline: none;
  color: ${({ sorter, theme }) => sorter && theme.color.primary};
  font-size: ${rem(12)};
  .error-text {
    color: red;
    font-size: ${rem(12)};
  }
`;

const CustomTextArea = styled(Textarea)`
  width: 97%;
  .dzGgfV {
    background-color: ${props => props.error && '#ffb6b6'};
  }
`;

class MetaDescriptionTextArea extends Component {
  render() {
    const { value, onChange, placeholder, error } = this.props;
    const lengthError = value && value.length >= 160;
    return (
      <Wrapper>
        <CustomTextArea
          error={error || lengthError}
          onChange={e => onChange(e)}
          value={value}
          placeholder={placeholder}
        />
        {lengthError && (
          <div className="error-text">
            {__('Meta description by mal mať max 160 znakov')}
          </div>
        )}
      </Wrapper>
    );
  }
}

MetaDescriptionTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};

MetaDescriptionTextArea.defaultProps = {
  value: '',
  onChange: () => console.error('onChange not specified'),
  placeholder: __('Meta description (max 160 znakov)'),
  error: false,
};

export default MetaDescriptionTextArea;
