import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import Loader from 'oxyrion-ui/lib/Loader';
import styled from 'styled-components';
import CatalogCheckbox from '../CatalogCheckbox';
import { translateCodelist } from '../../../../Utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${rem(10)};
  margin-right: ${rem(5)};
  margin-top: ${rem(2)};
`;

const Title = styled.div`
  display: flex;

  font-size: ${rem(12)};
  font-weight: 700;
  color: #434245;
  margin-bottom: ${rem(4)};
`;

const GroupWrapper = styled.div`
  margin-bottom: ${rem(6)};
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: ${rem(1)};
`;

class Filter extends React.Component {
  render() {
    const {
      data,
      checkedValues,
      onFilterChange,
      loading,
      disabled,
    } = this.props;

    return (
      <Wrapper>
        <LoadingWrapper>{loading && <Loader color="#434245" />}</LoadingWrapper>
        {data.map(d => {
          return (
            <GroupWrapper>
              <Title>{d.title}</Title>
              {d.values.map(v => {
                return (
                  <CatalogCheckbox
                    label={translateCodelist(
                      this.props.firm,
                      v.translations,
                      v.label,
                    )}
                    onChange={e => onFilterChange(d.id, e, d.logic)}
                    value={v.id || v.value}
                    disabled={disabled}
                    checked={
                      checkedValues.find(c => c.id === d.id) &&
                      checkedValues.find(c => c.id === d.id).values &&
                      checkedValues
                        .find(c => c.id === d.id)
                        .values.indexOf(v.id || v.value) > -1
                    }
                  />
                );
              })}
            </GroupWrapper>
          );
        })}
      </Wrapper>
    );
  }
}

Filter.propTypes = {
  data: PropTypes.arrayOf({
    title: PropTypes.string,
    id: PropTypes.string,
    values: PropTypes.arrayOf({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  checkedValues: PropTypes.arrayOf({
    id: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  onFilterChange: PropTypes.func,
  loading: PropTypes.bool,
  firm: PropTypes.string,
};

Filter.defaultProps = {
  checkedValues: [],
  data: [],
  loading: false,
  firm: 'SK',
  onFilterChange: () => {},
};
export default Filter;
