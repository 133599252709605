import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import ReactPlayer from 'react-player';
import { __ } from '../../../Utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${rem(10)};
  max-width: ${rem(1400)};
  margin-top: ${rem(20)};
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(10)};
  position: relative;
`;

const VideoLabel = styled.div`
  margin-bottom: ${rem(20)};
  margin-top: ${rem(30)};
  border-bottom: ${rem(1)} solid #eee;
  width: 100%;
`;

class ArticleContent extends React.Component {
  state = {
    playing: -1,
  };
  render() {
    const { content, videos } = this.props;
    return (
      <Wrapper>
        <form>
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </form>
        {videos && videos.length && videos.length > 0 ? (
          <VideoLabel>{__('Videá k článku')}</VideoLabel>
        ) : (
          <div />
        )}
        {videos && videos.length && videos.length > 0 ? (
          videos.map((v, index) => {
            return (
              <VideoWrapper>
                <ReactPlayer
                  url={v}
                  playing={index === this.state.playing}
                  forceVideo
                  controls
                  config={{
                    youtube: {
                      playerVars: { showinfo: 1 },
                    },
                  }}
                />
              </VideoWrapper>
            );
          })
        ) : (
          <div />
        )}
      </Wrapper>
    );
  }
}

ArticleContent.propTypes = {
  content: PropTypes.element,
  videos: PropTypes.arrayOf(PropTypes.string),
};

ArticleContent.defaultProps = {
  content: <div />,
  videos: [],
};

export default ArticleContent;
