import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CashiersList from '../../../Containers/Cashiers/CashiersList';
import CashierDetail from '../../../Containers/Cashiers/CashierDetail';

function CashiersCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={CashierDetail} />
      <Route path={`${match.url}`} component={CashiersList} />
    </Switch>
  );
}

CashiersCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CashiersCard;
