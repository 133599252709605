import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Button } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';
import { FlexBox, H2, AnimatedFormMessageWrapper } from '../ReusableComponents';

const Wrapper = styled.div`
  padding: ${rem(20)} ${rem(10)};
  color: black;
  z-index: 100;
`;

const AdditionalContent = styled.div`
  padding: ${rem(5)} ${rem(10)} ${rem(30)};
`;

const ButtonWrapper = styled(FlexBox)`
  justify-content: space-around;
  width: 80%;
  margin: auto;
`;

const CustomH2 = styled(H2)`
  margin: 0 ${rem(20)} ${rem(20)};
  text-align: center;
`;

// const Space = styled.div`
//   width: ${rem(15)};
// `;

function ConfirmDialog(props) {
  const {
    onConfirm,
    onDismiss,
    message,
    additionalContent,
    error,
    confirmMessage,
    dismissMessage,
    loading,
    dismissLoading,
    reverse,
  } = props;

  let buttons = [];

  if (reverse) {
    buttons = [
      <Button
        loading={dismissLoading}
        small
        danger
        basic
        size="s"
        onClick={() => onDismiss()}
      >
        {dismissMessage}
      </Button>,
      <Button loading={loading} small basic primary onClick={() => onConfirm()}>
        {confirmMessage}
      </Button>,
    ];
  } else {
    buttons = [
      <Button
        loading={loading}
        small
        danger
        basic
        size="s"
        onClick={() => onConfirm()}
      >
        {confirmMessage}
      </Button>,
      <Button
        loading={dismissLoading}
        small
        basic
        primary
        onClick={() => onDismiss()}
      >
        {dismissMessage}
      </Button>,
    ];
  }

  return (
    <React.Fragment>
      <AnimatedFormMessageWrapper display={error}>
        {error && <Message error message={error} />}
      </AnimatedFormMessageWrapper>
      <Wrapper>
        <CustomH2>{message}</CustomH2>
        {additionalContent ? (
          <AdditionalContent>{additionalContent}</AdditionalContent>
        ) : (
          ''
        )}
        <ButtonWrapper>{buttons}</ButtonWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

ConfirmDialog.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string,
  dismissMessage: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  additionalContent: PropTypes.string,
  dismissLoading: PropTypes.bool,
  reverse: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  error: null,
  confirmMessage: __('Áno'),
  dismissMessage: __('Nie'),
  loading: false,
  additionalContent: '',
  dismissLoading: false,
  reverse: false,
};

export default ConfirmDialog;
