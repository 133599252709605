import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { __ } from '../../Utils';
import headers from '../../ContentConfig/eshopProductsNames';
import BaseTable from '../BaseTable';
import { connect } from '../../Store';
import AdminAPI from '../../AdminAPI';
import OwnStateInput from '../../Components/OwnStateInput';

const Wrapper = styled.div`
  th:last-of-type {
    text-align: right;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
`;

class EshopProductsNames extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = headers;
    this.showBulkOperations = false;
    this.segmentName = __('Názvy poloziek pre eshop');
    this.setSorter('name');
    this.accessors = [
      'productId',
      'name',
      'name_SK',
      'name_CZ',
      'name_AT',
      'heurekaNameTooltip',
    ];
    this.state = {
      ...this.state,
      status: null,
      statusMessage: __('Všetky'),
      showCustomerSelection: false,
      onlyOwned: null,
      showCreateOpportunityForm: false,
      isClosed: true,
      contacts: [],
    };
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter } = this.state;
      this.setState({ loading: true });
      const params = Object.assign({}, parameters, { sorter });
      const newData = await AdminAPI.getCatalogProductVariantsAction(
        this.props.firm,
        params,
      );

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        items: newData.items,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  }

  async updateProduct(catalog_product_id, productId, translations) {
    try {
      await AdminAPI.putCatalogProductVariantsAction(
        this.props.firm,
        catalog_product_id,
        productId,
        {
          body: {
            translations: translations.map(t => ({
              lang: t.lang,
              variantEshopName: t.variantEshopName,
            })),
          },
        },
      );
    } catch (e) {
      console.log('ERROR: ', e);
    }
  }

  handleInputChange(productId, lang, e) {
    const { items } = this.state;

    const product = items.find(c => c._id === productId);
    console.log('Product: ', product);
    if (product) {
      const langFind = product.translations.find(t => t.lang === lang);

      if (langFind) {
        langFind.variantEshopName = e.target.value;
        this.updateProduct(
          product.catalog_product_id,
          productId,
          product.translations,
        );
      }
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, id = null) => {
      return {
        value: value || '-',
        handleOnClick: handleOnClick || null,
        id,
      };
    };

    const result = data.reduce((acumulator, item) => {
      // eslint-disable-next-line camelcase
      const SK_Translation = item.translations.find(t => t.lang === 'SK');
      // eslint-disable-next-line camelcase
      const CZ_Translation = item.translations.find(t => t.lang === 'CZ');
      // eslint-disable-next-line camelcase
      const AT_Translation = item.translations.find(t => t.lang === 'AT');

      acumulator.push({
        productId: _shapeData(item._id),
        name: _shapeData(item.name),
        name_SK: _shapeData(
          <InputWrapper>
            <OwnStateInput
              key={`${item._id}SK`}
              value={SK_Translation ? SK_Translation.variantEshopName : ''}
              placeholder={__('SK preklad')}
              name={item._id}
              type="text"
              onBlur={value => {
                this.handleInputChange(item._id, 'AT', value);
              }}
              onChange={() => {}}
            />
          </InputWrapper>,
        ),
        name_CZ: _shapeData(
          <InputWrapper>
            <OwnStateInput
              key={`${item._id}CZ`}
              value={CZ_Translation ? CZ_Translation.variantEshopName : ''}
              placeholder={__('CZ preklad')}
              name={item._id}
              type="text"
              onBlur={value => {
                this.handleInputChange(item._id, 'AT', value);
              }}
              onChange={() => {}}
            />
          </InputWrapper>,
        ),
        name_AT: _shapeData(
          <InputWrapper>
            <OwnStateInput
              key={`${item._id}AT`}
              value={AT_Translation ? AT_Translation.variantEshopName : ''}
              placeholder={__('AT preklad')}
              name={item._id}
              type="text"
              onBlur={value => {
                this.handleInputChange(item._id, 'AT', value);
              }}
              onChange={() => {}}
            />
          </InputWrapper>,
        ),
        heurekaNameTooltip: _shapeData(
          SK_Translation.heurekaNameTooltip ||
            __('Neplatné Id heureka kategórie'),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  render() {
    return <Wrapper>{super.render()}</Wrapper>;
  }
}

EshopProductsNames.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopProductsNames);
