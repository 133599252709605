import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EshopBannersList from '../../../Containers/EshopBannersList';
import EshopBannersDetail from '../../../Containers/EshopBannersDetail';

function EshopShopsCard(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/:pageId/:id`} component={EshopBannersDetail} />
      <Route path={`${match.url}/:pageId/`} component={EshopBannersList} />
    </Switch>
  );
}

EshopShopsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default EshopShopsCard;
