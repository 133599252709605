import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';

const Wrapper = styled.div`
  display: flex;
  padding: ${rem(10)};
  border-bottom: ${rem(1)} #eee solid;
  margin-right: ${rem(10)};
  margin-left: ${rem(10)};
  max-width: ${rem(1400)};
`;

const ImageWrapper = styled.div`
  margin-right: ${rem(20)};
`;

const TitleWrapper = styled.div``;
const Title = styled.div`
  font-size: ${rem(24)};
  margin-bottom: ${rem(10)};
`;
const Perex = styled.div`
  font-size: ${rem(12)};
  color: #000000ba;
`;

const StyledImage = styled.img`
  width: ${rem(110)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(7)};
`;

const Date = styled.div`
  font-size: ${rem(12)};
  color: #989696;
  margin-top: ${rem(5)};
`;

class ArticleTitle extends React.Component {
  render() {
    const { name, perex, image, updated } = this.props;
    return (
      <Wrapper>
        <ImageWrapper>
          {image && (
            <StyledImage
              src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${image}`}
            />
          )}
          {updated && <Date>{updated}</Date>}
        </ImageWrapper>

        <TitleWrapper>
          <Title>{name}</Title>
          <Perex>{perex}</Perex>
        </TitleWrapper>
      </Wrapper>
    );
  }
}

ArticleTitle.propTypes = {
  name: PropTypes.string,
  perex: PropTypes.string,
  image: PropTypes.string,
  updated: PropTypes.string,
};

ArticleTitle.defaultProps = {
  name: null,
  perex: null,
  image: null,
  video_label: false,
  updated: null,
};

export default ArticleTitle;
