import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Input, Loader, Select, Button } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';

const types = [
  {
    name: __('dni'),
    value: 'days',
  },
  {
    name: __('mesiace'),
    value: 'months',
  },
];

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  padding: ${rem(4)};
  margin-bottom: ${rem(100)};
`;

const Col = styled.div`
  margin-right: ${rem(24)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};
`;

const InputWrapper = styled.div`
  margin-bottom: ${rem(2)};
  margin-right: ${rem(32)};
  min-width: ${rem(200)};
`;

class DeliveryTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      brands: [],
      changedRows: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const brands = await API2.getBrandsAction(this.props.firm);
      this.setState({
        brands: brands.fullBrands || [],
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Značky sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleTypeChange(value, id) {
    const { brands, changedRows } = this.state;

    if (!brands.find(b => b._id === id).delivery_terms) {
      brands.find(b => b._id === id).delivery_terms = {
        type: value,
        valye: 1,
      };
    } else {
      brands.find(b => b._id === id).delivery_terms.type = value;
    }

    changedRows.push(id);

    this.setState({
      brands,
      changedRows,
    });
  }

  handleValueChange(value, id) {
    const { brands, changedRows } = this.state;

    brands.find(b => b._id === id).delivery_terms.value = value;

    changedRows.push(id);

    this.setState({
      brands,
      changedRows,
    });
  }

  async saveBrand(brandData) {
    let { changedRows } = this.state;

    try {
      this.setState({
        saveLoadingId: brandData._id,
      });

      await API2.putBrandAction(this.props.firm, brandData._id, {
        data: {
          delivery_terms: {
            value: Number(brandData.delivery_terms.value),
            type: brandData.delivery_terms.type,
          },
        },
      });

      changedRows = changedRows.filter(c => c !== brandData._id);

      this.setState({
        saveLoadingId: false,
        changedRows,
        success: `${__('Dodacia leho pre značku')} ${brandData.brand} ${__(
          'bola úspšene uložená',
        )}`,
      });
    } catch (e) {
      this.setState({
        saveLoadingId: false,
        error: __('Lehotu dodania sa nepodarilo uložiť'),
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Lehoty dodania značiek')} />
    );
  }

  render() {
    const {
      error,
      success,
      brands,
      loading,
      changedRows,
      saveLoadingId,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <Wrapper>
          <Col>
            {brands.map(item => {
              return (
                <Row key={item.value}>
                  <InputWrapper>
                    <Input value={item.brand} disabled />
                  </InputWrapper>

                  <InputWrapper>
                    <Select
                      size="s"
                      onChange={e =>
                        this.handleTypeChange(e.target.value, item._id)
                      }
                    >
                      {types.map(s => (
                        <option
                          selected={
                            item.delivery_terms &&
                            s.value === item.delivery_terms.type
                          }
                          value={s.value}
                        >
                          {s.name}
                        </option>
                      ))}

                      <option disabled selected={!item.delivery_terms}>
                        -
                      </option>
                    </Select>
                  </InputWrapper>

                  <InputWrapper style={{ minWidth: rem(70) }}>
                    <Input
                      style={{ width: rem(50) }}
                      min="1"
                      type="number"
                      disabled={!item.delivery_terms}
                      onChange={e =>
                        this.handleValueChange(e.target.value, item._id)
                      }
                      value={
                        (item.delivery_terms && item.delivery_terms.value) || 0
                      }
                    />
                  </InputWrapper>

                  {changedRows.includes(item._id) && (
                    <Button
                      loading={saveLoadingId === item._id}
                      small
                      primary
                      onClick={() => this.saveBrand(item)}
                    >
                      {__('Uložiť')}
                    </Button>
                  )}
                </Row>
              );
            })}
          </Col>
        </Wrapper>
      </React.Fragment>
    );
  }
}

DeliveryTerms.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(DeliveryTerms);
