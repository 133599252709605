import React from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { __ } from '../../Utils';
import { FlexBoxEnd, FlexBoxStart } from '../ReusableComponents';

const Wrapper = styled(FlexBoxEnd)`
  width: 100%;
  padding: ${rem(9)} 0

  & div:first-of-type {
    width: 100%;
  }
`;

const IconWrapper = styled(FlexBoxStart)`
  cursor: pointer;
  padding: 0 ${rem(9)};
  margin: 0;
  margin-left: ${rem(20)};
`;

const InputWrapper = styled.div`
  transition: opacity 0.3s
  ${({ display }) => {
    if (display) {
      return css`
        opacity: 1;
      `;
    }
    return css`
      position: absolute;
      top: -200vh;
    `;
  }}
  opacity: ${({ display }) => (display ? '1' : '0')};
`;

class Search extends React.Component {
  async onClick() {
    await this.props.onSearchClick();
    if (this.props.clicked) {
      this.input.focus();
    }
  }

  render() {
    const { clicked, searchValue, onClose } = this.props;
    return (
      <Wrapper>
        <InputWrapper display={clicked}>
          <Input
            width="100%"
            type="text"
            placeholder={__('Zadajte predmet vyhľadávania')}
            inputRef={ref => {
              this.input = ref;
            }}
            value={searchValue}
            onChange={e => this.props.onInputChange(e.target.value)}
          />
        </InputWrapper>
        {clicked ? (
          <IconWrapper onClick={() => onClose()}>
            <img src="/images/icons/close.svg" height="24px" alt="close" />
          </IconWrapper>
        ) : (
          <IconWrapper onClick={() => this.onClick()}>
            <img src="/images/icons/search.svg" height="24px" alt="search" />
          </IconWrapper>
        )}
      </Wrapper>
    );
  }
}

Search.propTypes = {
  clicked: PropTypes.bool.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default Search;
