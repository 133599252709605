import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import painterSVG from './painter.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem(4)};
  cursor: pointer;
`;

const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(16)};
  height: ${rem(16)};
  border: solid ${props => (props.checked ? '#ee6500' : '#e4e4e4')} ${rem(0.5)};
  &:hover {
    border: solid #ee6500 ${rem(0.5)};
  }
  border-radius: ${rem(3)};
  margin-right: ${rem(5)};
  background: ${props => (props.checked ? '#ee6500' : '#fff')};
`;

const Label = styled.div`
  font-size: ${rem(9)};
  color: #434245;
`;

class CatalogCheckbox extends React.Component {
  render() {
    const {
      label,
      checked,
      onChange,
      value,
      defaultValue,
      disabled,
    } = this.props;
    return (
      <Wrapper onClick={() => (disabled ? () => {} : onChange(value))}>
        <Checkbox checked={checked === -1 ? defaultValue : checked}>
          <img src={painterSVG} alt="alt" />
        </Checkbox>
        <Label>{label}</Label>
      </Wrapper>
    );
  }
}

CatalogCheckbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CatalogCheckbox.defaultProps = {
  label: '',
  value: '1',
  defaultValue: false,
  disabled: false,
  checked: -1,
  onChange: () => {},
};

export default CatalogCheckbox;
