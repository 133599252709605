import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Input,
  Checkbox,
  Loader,
  Select,
  Icon,
} from 'oxyrion-ui/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../Store';
import { fetchCodelistByQuery, __ } from '../../Utils';
import {
  ButtonRelativeWrapper,
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import GreyBox from '../../Components/GreyBox';
import SearchableSelect from '../../Components/SearchableSelect';
import LanguageSwitch from '../../Components/LanguageSwitch';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import { fetchMultimedia } from '../../Utils/ApiSearchFunctions';
import API2 from '../../API2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: ${rem(300)};
  ${({ theme }) => theme.media.xl`
     flex-direction: row;
  `};
`;

const Row = styled.div`
  display: flex;
`;

const InputWrapper = styled.div``;

const StyledInput = styled(Input)`
  width: ${rem(100)};
`;

const SearchInputWrapper = styled.div`
  width: ${rem(300)};
`;

const GreyBoxContentWrapper = styled.div`
  padding: ${rem(8)};
`;

const Space = styled.div`
  height: ${rem(24)};
  width: ${rem(24)};
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColorCardsTableWrapper = styled.div``;

const ColorCardRow = styled.div`
  height: ${rem(48)};
  border-top: ${rem(1)} solid #b7b7b7;
  align-items: center;
  display: flex;
  font-weight: ${props => (props.highlight ? 'bold' : 'normal')}
  justify-content: space-between;
`;

const ColorCardName = styled.div``;

const CheckboxesWrapper = styled.div`
  display: flex;
`;

const ColorCardHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CheckboxWrapper = styled.div`
  width: ${rem(100)};
`;

const BasesWrapper = styled.div``;

const BaseRow = styled.div`
  display: flex;
  height: ${rem(50)};
  align-items: center;
`;

const BaseName = styled.div`
  width: ${rem(200)};
  margin-left: ${rem(10)};
`;

const BaseInput = styled(Input)`
  width: ${rem(60)};
`;

const LoaderCustomWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoVariants = styled.div`
  font-size: ${rem(12)};
  color: #969696;
  margin-left: ${rem(20)};
`;

const ColorCardPurpose = styled.div`
  display: flex;
  margin-right: ${rem(8)};
`;

const PlusWrapper = styled.div`
  height: ${rem(34)};
  width: ${rem(34)};
  margin-left: ${rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Halfer = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.xl`
  width: 50%;
`};
`;

const SearchableSelectWrapper = styled.div`
  width: 100%;
  max-width: ${rem(400)};
`;

const TextureWrapper = styled.div`
  width: ${rem(300)};
  height: ${rem(150)};
  background: ${props => props.color};
`;

const TexturesWrapper = styled.div`
  display: flex;
`;

const TextureImage = styled.img`
  width: ${rem(300)};
  height: ${rem(150)};
`;

const purposeTypes = [
  {
    value: 'tinting',
    label: __('Tónované odtiene'),
  },
  {
    value: 'finished',
    label: __('Hotové odtiene'),
  },
];

const photoBasePath = `https://api.oxyrion.com/v1/images/300x150`;

class Toning extends React.Component {
  state = {
    selectedChannel: 'CRM',
    selectedToningSystem: null,
    colorcards: [],
    publication: [],
    publishedAllCZ: false,
    publishedAllAT: false,
    publishedAllHU: false,
    publishedAllSK: false,
    toningSystemLoading: false,
    colorcardsLoading: false,
    saveLoading: false,
    publicationChanged: false,
    colourCardToAdd: undefined,
    customerToAdd: undefined,
    margins: [],
  };

  async componentDidMount() {
    const { selectedToningSystemId, productId } = this.props;

    if (selectedToningSystemId && selectedToningSystemId.length) {
      try {
        const result = await Promise.all([
          API2.getToningSystemAction(this.props.firm, selectedToningSystemId),
          AdminAPI.getCatalogProductMarginsAction(productId),
        ]);
        const toningSystem = result[0];
        const { margins } = result[1];

        this.setState({
          margins,
        });

        this.handleToningSystemChange(
          {
            value: toningSystem._id,
            label: toningSystem.name,
            import_id: toningSystem.import_id,
          },
          false,
        );
      } catch (e) {
        this.props.raiseErrorMessage('Tónovanie sa nepodarilo načitať');
      }
    }
  }

  fetchColorCards(query) {
    const { selectedToningSystem } = this.state;
    try {
      return API2.getColorcardsAction(this.props.firm, {
        q: query,
        limit: 40,
        toningSystemId: selectedToningSystem && selectedToningSystem.value,
      }).then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
          colorCard: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchCustomers(query) {
    const { margins } = this.state;

    const ids = margins.map(c => c.customer.value);

    try {
      return API2.getCustomersAction(this.props.firm, { q: query }).then(res => {
        return res.items
          .filter(i => ids.indexOf(i._id) === -1)
          .map(item => ({
            value: item._id,
            label: item.name,
            customer: item,
          }));
      });
    } catch (e) {
      return [];
    }
  }

  async addColorCard() {
    const { colourCardToAdd, colorcards } = this.state;
    const { productId } = this.props;
    try {
      if (!colourCardToAdd || !colourCardToAdd.colorCard) {
        this.setState({
          colourCardToAddError: true,
        });

        return;
      }

      const existingColorCard = colorcards.find(
        c => c._id === colourCardToAdd.value,
      );

      const params = {};
      params.body = {
        _id: colourCardToAdd.value,
        purpose:
          existingColorCard && existingColorCard.purpose === 'finished'
            ? 'tinting'
            : 'finished',
      };

      const result = await AdminAPI.putCatalogProductColocardAction(
        productId,
        params,
      );

      colorcards.push({
        _id: colourCardToAdd.value,
        name: colourCardToAdd.label,
        purpose: result.purpose || 'tinting',
        new: true,
      });

      this.setState({
        // colorcards: colorcards.sort((a, b) => (a.name < b.name ? -1 : 1)),
        colorcards,
        colourCardToAdd: undefined,
      });
    } catch (e) {
      if (e.response.status === 409) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        this.props.raiseErrorMessage(
          'Vzorkovník sa už v tónovaní nachádza pre tónované aj hotové odtiene',
        );
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        this.props.raiseErrorMessage('Vzorkovník sa nepodarilo pridať');
      }
    }
  }

  handleOnChange(value) {
    this.setState({ colourCardToAdd: value, colourCardToAddError: false });
  }

  async handleToningSystemChange(e, saveToningSystem = true) {
    const { productId, externalProducts } = this.props;
    const { selectedChannel } = this.state;

    this.setState({
      toningSystemLoading: true,
      selectedToningSystem: e,
      toningSystemToChange: undefined,
    });

    try {
      if (e) {
        if (saveToningSystem) {
          const params = {};
          params.body = {
            toning_system_id: e.value,
          };

          await AdminAPI.putCatalogProductToningSystemAction(productId, params);
        }

        const result = await AdminAPI.getCatalogProductColorcardsAction(
          productId,
          {
            toningSystemId: e.value,
          },
        );

        const publication = await this.fetchPublication(
          selectedChannel,
          result.items,
          externalProducts,
        );

        const {
          publishedAllCZ,
          publishedAllSK,
          publishedAllAT,
          publishedAllHU,
        } = this.checkAllSelected(publication.items || []);

        this.setState({
          colorcards: result.items,
          toningSystemLoading: false,
          publishedAllCZ,
          publishedAllSK,
          publishedAllAT,
          publishedAllHU,
          publication: publication.items || [],
        });
      } else {
        this.setState({
          colorcards: [],
          toningSystemLoading: false,
          selectedToningSystem: null,

          publication: [],
        });
      }
    } catch (a) {
      this.props.raiseErrorMessage('Tónovací systém sa nepodarilo načítať');
    }
  }

  async handleChannelChange(channel) {
    const { colorcards, publicationChanged, publication } = this.state;
    const { productId, externalProducts } = this.props;
    this.setState({
      colorcardsLoading: true,
    });

    try {
      if (publicationChanged && publication.length) {
        const params = {};
        params.body = publication;

        await AdminAPI.putCatalogProductColorcardsPublicationAction(
          productId,
          params,
        );
      }

      const newPublication = await this.fetchPublication(
        channel,
        colorcards,
        externalProducts,
      );

      const {
        publishedAllCZ,
        publishedAllSK,
        publishedAllAT,
        publishedAllHU,
      } = this.checkAllSelected(newPublication.items || []);

      this.setState({
        selectedChannel: channel,
        publishedAllCZ,
        publishedAllSK,
        publishedAllAT,
        publishedAllHU,
        publication: newPublication.items || [],
        colorcardsLoading: false,
      });
      return true;
    } catch (e) {
      this.setState({
        colorcardsLoading: false,
      });
      return false;
    }
  }

  searchNoTintinChoosesParam(query, values) {
    if (values.codelist) {
      return values.codelist.map(c => ({
        label: c.label,
        value: c._id,
        _id: c._id,
      }));
    }

    return [];
  }

  normalizeMultiselectValue(values, codelist = []) {
    return (Array.isArray(values) ? values : [values]).map(value => {
      const item = codelist.find(c => c._id === value._id);

      if (!item) {
        return value;
      }

      return {
        value: item._id,
        label: item.label,
        _id: item._id,
      };
    });
  }

  searchToningSystem(query) {
    try {
      return API2.getToningSystemsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.systems.map(t => ({
          label: t.name,
          value: t._id,
          import_id: t.import_id,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchPublication(chanel, colorcards, externalProducts) {
    const { productId } = this.props;

    const colorcardsIds = colorcards.map(c => c._id);

    try {
      const result = await AdminAPI.getCatalogProductColorcardsPublicationAction(
        productId,
        {
          chanel,
          externalProducts:
            externalProducts && externalProducts.map(e => Number(e.value)),
        },
      );

      return {
        items: result.items.filter(i => colorcardsIds.indexOf(i._id) > -1),
        // items: [],
      };
    } catch (e) {
      this.props.raiseErrorMessage('Publikáciu sa nepodarilo načítať');

      return [];
    }
  }

  checkAllSelected(publication) {
    let publishedAllCZ = true;
    let publishedAllSK = true;
    let publishedAllAT = false;
    let publishedAllHU = false;

    publication.forEach(p => {
      if (p.langs) {
        p.langs.forEach(l => {
          if (l.lang === 'AT' && !l.published) {
            publishedAllAT = false;
          } else {
            publishedAllAT = false;
          }

          if (l.lang === 'HU' && !l.published) {
            publishedAllHU = false;
          } else {
            publishedAllHU = false;
          }

          if (l.lang === 'SK' && !l.published) {
            publishedAllSK = false;
          }

          if (l.lang === 'CZ' && !l.published) {
            publishedAllCZ = false;
          }
        });
      }
    });

    return {
      publishedAllCZ,
      publishedAllSK,
      publishedAllAT,
      publishedAllHU,
    };
  }

  fetchExternalProducts(query) {
    const { selectedToningSystem } = this.state;

    if (!selectedToningSystem) {
      return [];
    }
    try {
      return API2.getToningSystemExternalProductsAction(
        selectedToningSystem.value,
        {
          q: query,
          limit: 10,
        },
      ).then(res => {
        return res.products.map(item => ({
          value: item.external_product_id,
          label: item.name,
          code: item.code,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  publishAll(value, lang) {
    const { publication } = this.state;
    let {
      publishedAllCZ,
      publishedAllSK,
      publishedAllAT,
      publishedAllHU,
    } = this.state;

    const newPublication = publication.map(p =>
      Object.assign(p, {
        langs: p.langs.map(l => {
          if (l.lang === lang) {
            return Object.assign(l, { published: value });
          }
          return l;
        }),
      }),
    );

    if (lang === 'SK') {
      publishedAllSK = value;
    } else if (lang === 'CZ') {
      publishedAllCZ = value;
    } else if (lang === 'HU') {
      publishedAllHU = value;
    } else if (lang === 'AT') {
      publishedAllAT = value;
    }

    this.setState({
      publication: newPublication,
      publishedAllSK,
      publishedAllCZ,
      publishedAllHU,
      publishedAllAT,
      publicationChanged: true,
    });
  }

  handlePublicationChange(value, id, lang) {
    const { publication } = this.state;

    if (!publication.find(p => p._id === id).langs) {
      publication.find(p => p._id === id).langs = [];
    }

    if (!publication.find(p => p._id === id).langs.find(l => l.lang === lang)) {
      publication
        .find(p => p._id === id)
        .langs.push({
          lang,
          published: false,
        });
    }

    publication
      .find(p => p._id === id)
      .langs.find(l => l.lang === lang).published = value;

    const {
      publishedAllCZ,
      publishedAllSK,
      publishedAllAT,
      publishedAllHU,
    } = this.checkAllSelected(publication);

    this.setState({
      publication,
      publishedAllCZ,
      publishedAllSK,
      publishedAllAT,
      publishedAllHU,
      publicationChanged: true,
    });
  }

  async saveData() {
    const { publication } = this.state;
    const { productId } = this.props;

    this.setState({
      saveLoading: true,
    });

    try {
      if (/*publicationChanged &&*/ publication.length) {
        const params = {};
        params.body = publication;
        await AdminAPI.putCatalogProductColorcardsPublicationAction(
          productId,
          params,
        );
      }

      await this.props.saveCard();

      this.setState({
        saveLoading: false,
        publicationChanged: false,
      });
    } catch (e) {
      this.props.raiseErrorMessage('Publikáciu sa nepodarilo uložiť');
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' && this.state.colourCardToAdd) {
      this.addColorCard();
    }
  }

  async handleExternalProductsChange(e, importId) {
    const { handleExternalProductsChange } = this.props;
    const { selectedChannel, colorcards } = this.state;

    this.setState({
      colorcardsLoading: true,
    });

    handleExternalProductsChange(e, importId);

    const publication = await this.fetchPublication(
      selectedChannel,
      colorcards,
      e,
    );

    this.setState({
      publication: publication.items || [],
      colorcardsLoading: false,
    });
  }

  async editColorCard(id, purpose) {
    const { colorcards } = this.state;
    const { productId } = this.props;

    colorcards.find(c => c._id === id).purpose = purpose;

    try {
      const params = {};

      params.body = {
        purpose,
      };

      await AdminAPI.putCatalogProductColocardPurposeAction(
        productId,
        id,
        params,
      );
    } catch (e) {
      this.props.raiseErrorMessage('Vzorkovník sa nepodarilo upraviť');
    }

    this.setState({
      colorcards,
    });
  }

  async deleteMargin(customerId) {
    const { productId } = this.props;
    try {
      const result = await AdminAPI.deleteMarginAction(productId, customerId);

      this.setState({
        margins: result.margins,
      });
    } catch (e) {
      this.props.raiseErrorMessage('Maržu sa nepodarilo odstrániť');
    }
  }

  async addMargin() {
    const { customerToAdd } = this.state;
    const { productId } = this.props;

    const objToAdd = {
      customer: {
        value: customerToAdd.value,
        label: customerToAdd.label,
      },
      margin: customerToAdd.margin,
    };

    try {
      const params = {};
      params.body = objToAdd;

      const result = await AdminAPI.postCatalogProductMarginAction(
        productId,
        params,
      );

      this.setState({
        margins: result.margins,
        customerToAdd: undefined,
      });
    } catch (e) {
      this.props.raiseErrorMessage('Maržu sa nepodarilo pridať');
    }
  }

  handleCustomerToAddChange(e) {
    let { customerToAdd } = this.state;

    customerToAdd = e;

    this.setState({
      customerToAdd,
    });
  }

  render() {
    const {
      variants,
      onCoeficientChange,
      externalProduct,
      handleExternalProduct,
      noTintigParam,
      noTintinChoosesParam,
      externalProducts,
      basesSettings,
      handleBaseSettingsChange,
      favoritColorcards,
      handleFavoritColorcardsChange,
      handleFarleskExportColorcardsChange,
      farleskExportColorcards,
      colorcardsTexture,
      handleColorcardsTexture,
      showAllBases,
      handlesAllBasesChange,
    } = this.props;
    const {
      selectedChannel,
      selectedToningSystem,
      colorcards,
      publication,
      publishedAllCZ,
      publishedAllSK,
      publishedAllAT,
      publishedAllHU,
      toningSystemLoading,
      colorcardsLoading,
      saveLoading,
      toningSystemToChange,
      colourCardToAdd,
      colourCardToAddError,
    } = this.state;

    const params = [];

    if (noTintigParam) {
      params.push(noTintigParam);
    }

    if (noTintinChoosesParam) {
      params.push(noTintinChoosesParam);
    }

    const toningReadOnly = noTintigParam && noTintigParam.value;

    return (
      <React.Fragment>
        <PopUpWrapper
          display={toningSystemToChange}
          small
          onClose={() =>
            this.setState({
              toningSystemToChange: undefined,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Zmenou tónovacieho systému dôjde k premazaniu aktuálneho nastavenia vzorkovníkov. Chcete pokračovať?',
            )}
            onConfirm={() =>
              this.setState({
                toningSystemToChange: undefined,
              })
            }
            confirmMessage={__('Nie')}
            dismissMessage={__('Áno')}
            loading={toningSystemLoading}
            onDismiss={() =>
              this.handleToningSystemChange(toningSystemToChange)
            }
          />
        </PopUpWrapper>
        <Wrapper>
          <GreyBox sidePadding={0}>
            <GreyBoxContentWrapper>
              <Row>
                <InputWrapper>
                  <CheckboxWrapper style={{ width: rem(200) }}>
                    <Label>
                      <Checkbox
                        checked={noTintigParam && noTintigParam.value}
                        onChange={e =>
                          this.props.handleNoTintingValueChange({
                            value: e.target.checked,
                            fieldIndex: 0,
                          })
                        }
                      />
                      {__('Netónuje sa')}
                    </Label>
                  </CheckboxWrapper>
                </InputWrapper>
                {noTintigParam && noTintigParam.value && noTintinChoosesParam && (
                  <InputWrapper>
                    <Label>{noTintinChoosesParam.name}</Label>
                    <SearchInputWrapper>
                      <SearchableSelect
                        isMulti
                        value={
                          noTintinChoosesParam.value &&
                          this.normalizeMultiselectValue(
                            noTintinChoosesParam.value,
                            noTintinChoosesParam.values &&
                              noTintinChoosesParam.values.codelist,
                          )
                        }
                        loadOptions={query =>
                          this.searchNoTintinChoosesParam(
                            query,
                            noTintinChoosesParam.values,
                          )
                        }
                        placeholder={noTintinChoosesParam.name}
                        isClearable={false}
                        handleOnChange={e =>
                          this.props.handleNoTintingValueChange({
                            value: e,
                            fieldIndex: 1,
                          })
                        }
                      />
                    </SearchInputWrapper>
                  </InputWrapper>
                )}
              </Row>
              <Space />

              <Row>
                <InputWrapper>
                  <Label>{__('Tónovací systém')}</Label>
                  <SearchInputWrapper>
                    <SearchableSelect
                      disabled={toningReadOnly}
                      value={selectedToningSystem}
                      loadOptions={query => this.searchToningSystem(query)}
                      placeholder={__('Tónovací systém')}
                      isClearable={false}
                      handleOnChange={e =>
                        this.setState({ toningSystemToChange: e })
                      }
                    />
                  </SearchInputWrapper>
                </InputWrapper>
              </Row>
              <AnimatedFormMessageWrapper
                display={toningSystemLoading && !toningReadOnly}
              >
                <LoaderWrapper>
                  <Loader size="l" />
                </LoaderWrapper>
              </AnimatedFormMessageWrapper>
              <AnimatedFormMessageWrapper
                display={
                  selectedToningSystem &&
                  !toningSystemLoading &&
                  !toningReadOnly
                }
              >
                <Row>
                  <SearchInputWrapper>
                    <InputWrapper>
                      <Label>{__('Produktová štruktúra')}</Label>
                      <SearchableSelect
                        value={externalProducts}
                        loadOptions={query => this.fetchExternalProducts(query)}
                        placeholder={__('Produktová štruktúra')}
                        handleOnChange={e =>
                          this.handleExternalProductsChange(e)
                        }
                        keyProps={`my_unique_select_key__${JSON.stringify(
                          selectedToningSystem && selectedToningSystem.value,
                        )}`}
                        isMulti
                      />
                    </InputWrapper>
                  </SearchInputWrapper>
                  <Space />
                  <Space />
                  <InputWrapper>
                    <Label>{__('Product code')}</Label>
                    <StyledInput
                      value={
                        externalProduct &&
                        selectedToningSystem &&
                        externalProduct[selectedToningSystem.import_id]
                          ? externalProduct[selectedToningSystem.import_id].code
                          : 'bez kódu'
                      }
                      disabled
                    />
                  </InputWrapper>
                  <Space />
                  <Space />
                  <InputWrapper>
                    <Label>{__('Product ID')}</Label>
                    <StyledInput
                      value={
                        externalProduct &&
                        selectedToningSystem &&
                        externalProduct[selectedToningSystem.import_id]
                          ? externalProduct[selectedToningSystem.import_id]
                              .value
                          : ''
                      }
                      disabled
                    />
                  </InputWrapper>
                  <Space />
                  <Space />
                  <SearchInputWrapper>
                    <InputWrapper>
                      <Label>{__('Product Name')}</Label>
                      <SearchableSelect
                        value={
                          externalProduct &&
                          selectedToningSystem &&
                          externalProduct[selectedToningSystem.import_id] &&
                          externalProduct[selectedToningSystem.import_id]
                        }
                        loadOptions={query => this.fetchExternalProducts(query)}
                        placeholder={__('Product Name')}
                        handleOnChange={e =>
                          handleExternalProduct(
                            e,
                            selectedToningSystem.import_id,
                          )
                        }
                        keyProps={`product_name_${JSON.stringify(
                          selectedToningSystem && selectedToningSystem.value,
                        )}`}
                      />
                    </InputWrapper>
                  </SearchInputWrapper>
                </Row>
                <Space />
                <Space />
                <SpaceBetween>
                  <Label>{__('Publikácia vzorkovníkov')}</Label>
                  <LanguageSwitch
                    onChange={channel => {
                      return this.handleChannelChange(channel);
                    }}
                    activeId={selectedChannel}
                    values={['CRM', 'Portal', 'xPaint']}
                  />
                </SpaceBetween>
                <Space />
                {colorcardsLoading && (
                  <LoaderCustomWrapper>
                    <Loader size="l" />
                  </LoaderCustomWrapper>
                )}
                {!colorcardsLoading && colorcards && (
                  <ColorCardsTableWrapper>
                    <ColorCardHeaderWrapper>
                      <CheckboxesWrapper>
                        <CheckboxWrapper>
                          <Label>
                            <Checkbox
                              checked={publishedAllSK}
                              onChange={e =>
                                this.publishAll(e.target.checked, 'SK')
                              }
                            />
                            {__('Všetky')}
                          </Label>
                        </CheckboxWrapper>
                        <CheckboxWrapper>
                          <Label>
                            <Checkbox
                              checked={publishedAllCZ}
                              onChange={e =>
                                this.publishAll(e.target.checked, 'CZ')
                              }
                            />
                            {__('Všetky')}
                          </Label>
                        </CheckboxWrapper>
                        {!['CRM', 'Portal'].includes(selectedChannel) && (
                          <CheckboxWrapper>
                            <Label>
                              <Checkbox
                                checked={publishedAllAT}
                                onChange={e =>
                                  this.publishAll(e.target.checked, 'AT')
                                }
                              />
                              {__('Všetky')}
                            </Label>
                          </CheckboxWrapper>
                        )}
                        {!['CRM', 'Portal'].includes(selectedChannel) && (
                          <CheckboxWrapper>
                            <Label>
                              <Checkbox
                                checked={publishedAllHU}
                                onChange={e =>
                                  this.publishAll(e.target.checked, 'HU')
                                }
                              />
                              {__('Všetky')}
                            </Label>
                          </CheckboxWrapper>
                        )}
                      </CheckboxesWrapper>
                    </ColorCardHeaderWrapper>
                    {colorcards.map(c => {
                      const colorcardPublication =
                        publication.find(p => p._id === c._id) &&
                        publication.find(p => p._id === c._id).langs;

                      return (
                        <ColorCardRow highlight={c.new}>
                          <ColorCardName>{c.name}</ColorCardName>

                          <CheckboxesWrapper>
                            <ColorCardPurpose>
                              <Select
                                size="s"
                                value={c.purpose}
                                disabled={
                                  colorcards.filter(a => a._id === c._id)
                                    .length === 2
                                }
                                onChange={e => {
                                  this.editColorCard(c._id, e.target.value);
                                }}
                              >
                                {purposeTypes.map(p => {
                                  return (
                                    <option
                                      selected={p.value === c.purpose}
                                      value={p.value}
                                    >
                                      {p.label}
                                    </option>
                                  );
                                })}
                              </Select>
                            </ColorCardPurpose>
                            <CheckboxWrapper>
                              <Label>
                                <Checkbox
                                  onChange={e =>
                                    this.handlePublicationChange(
                                      e.target.checked,
                                      c._id,

                                      'SK',
                                    )
                                  }
                                  checked={
                                    colorcardPublication &&
                                    colorcardPublication.find(
                                      cp => cp.lang === 'SK',
                                    ) &&
                                    colorcardPublication.find(
                                      cp => cp.lang === 'SK',
                                    ).published
                                  }
                                />
                                {__('SK')}
                              </Label>
                            </CheckboxWrapper>

                            <CheckboxWrapper>
                              <Label>
                                <Checkbox
                                  onChange={e =>
                                    this.handlePublicationChange(
                                      e.target.checked,
                                      c._id,
                                      'CZ',
                                    )
                                  }
                                  checked={
                                    colorcardPublication &&
                                    colorcardPublication.find(
                                      cp => cp.lang === 'CZ',
                                    ) &&
                                    colorcardPublication.find(
                                      cp => cp.lang === 'CZ',
                                    ).published
                                  }
                                />
                                {__('CZ')}
                              </Label>
                            </CheckboxWrapper>

                            {!['CRM', 'Portal'].includes(selectedChannel) && (
                              <CheckboxWrapper>
                                <Label>
                                  <Checkbox
                                    onChange={e =>
                                      this.handlePublicationChange(
                                        e.target.checked,
                                        c._id,
                                        'AT',
                                      )
                                    }
                                    checked={
                                      colorcardPublication &&
                                      colorcardPublication.find(
                                        cp => cp.lang === 'AT',
                                      ) &&
                                      colorcardPublication.find(
                                        cp => cp.lang === 'AT',
                                      ).published
                                    }
                                  />
                                  {__('AT')}
                                </Label>
                              </CheckboxWrapper>
                            )}

                            {!['CRM', 'Portal'].includes(selectedChannel) && (
                              <CheckboxWrapper>
                                <Label>
                                  <Checkbox
                                    onChange={e =>
                                      this.handlePublicationChange(
                                        e.target.checked,
                                        c._id,
                                        'HU',
                                      )
                                    }
                                    checked={
                                      colorcardPublication &&
                                      colorcardPublication.find(
                                        cp => cp.lang === 'HU',
                                      ) &&
                                      colorcardPublication.find(
                                        cp => cp.lang === 'HU',
                                      ).published
                                    }
                                  />
                                  {__('HU')}
                                </Label>
                              </CheckboxWrapper>
                            )}
                          </CheckboxesWrapper>
                        </ColorCardRow>
                      );
                    })}
                    <ColorCardRow>
                      <SearchableSelectWrapper
                        onKeyDown={e => this.handleKeyDown(e)}
                      >
                        <SearchableSelect
                          value={
                            colourCardToAdd &&
                            colourCardToAdd.value &&
                            colourCardToAdd
                          }
                          loadOptions={query => this.fetchColorCards(query)}
                          placeholder={__('Zadajte názov vzorkovníka')}
                          keyProps={JSON.stringify({
                            colourCardToAdd:
                              colourCardToAdd && colourCardToAdd.value,
                          })}
                          error={colourCardToAddError}
                          handleOnChange={e => this.handleOnChange(e)}
                        />
                      </SearchableSelectWrapper>
                      <PlusWrapper onClick={() => this.addColorCard()}>
                        <Icon name="plus" size="l" />
                      </PlusWrapper>
                    </ColorCardRow>
                  </ColorCardsTableWrapper>
                )}
              </AnimatedFormMessageWrapper>
            </GreyBoxContentWrapper>
          </GreyBox>
          <Space />
          <Halfer>
            <GreyBox sidePadding={0}>
              <GreyBoxContentWrapper>
                <BasesWrapper>
                  <BaseRow>
                    <BaseName>
                      <Label>{__('Báza')}</Label>
                    </BaseName>
                    <Label>{__('Koeficient tónovania')}</Label>
                  </BaseRow>
                  {!variants ||
                    (variants.length === 0 && (
                      <NoVariants>
                        {__('Produkt momentálne nemá žiadne varianty')}
                      </NoVariants>
                    ))}
                  {variants.map(v => (
                    <BaseRow>
                      <BaseName>{`${v.name}`}</BaseName>
                      <Space />
                      <BaseInput
                        disabled={toningReadOnly}
                        onChange={e =>
                          onCoeficientChange(Number(e.target.value), v.No)
                        }
                        type="number"
                        placeholder="1"
                        value={
                          v.variantParams.find(
                            vp => vp.system_name === 'coeficient',
                          ) &&
                          Number(
                            v.variantParams.find(
                              vp => vp.system_name === 'coeficient',
                            ).value || 1,
                          )
                        }
                      />
                    </BaseRow>
                  ))}
                </BasesWrapper>
              </GreyBoxContentWrapper>
            </GreyBox>
            <Space />
            <GreyBox sidePadding={0}>
              <GreyBoxContentWrapper>
                {basesSettings.map(b => (
                  <BaseRow>
                    <BaseName>{`${b.name}`}</BaseName>
                    <Space />
                    <SearchInputWrapper>
                      <SearchableSelect
                        disabled={toningReadOnly}
                        value={b.color}
                        loadOptions={query =>
                          fetchCodelistByQuery(query, 'bases_colors')
                        }
                        placeholder={__('Farba')}
                        isClearable={false}
                        handleOnChange={e =>
                          handleBaseSettingsChange(b.name, e, 'value')
                        }
                      />
                    </SearchInputWrapper>
                    <Space />
                    <Checkbox
                      checked={b.is_default}
                      disabled={!b.color}
                      onChange={e =>
                        handleBaseSettingsChange(b.name, e, 'is_default')
                      }
                    />
                  </BaseRow>
                ))}

                <BaseRow>
                  <BaseName>{`${__(
                    'Zobraziť všetky bázy na eshope',
                  )}`}</BaseName>
                  <Space />
                  <Checkbox
                    checked={showAllBases}
                    onChange={e => handlesAllBasesChange(e.target.checked)}
                  />
                </BaseRow>
              </GreyBoxContentWrapper>
            </GreyBox>
            <Space />
            <GreyBox title={__('Obľubené vzorkovníky')} sidePadding={0}>
              <GreyBoxContentWrapper>
                <SearchableSelect
                  disabled={toningReadOnly}
                  value={favoritColorcards}
                  loadOptions={query => this.fetchColorCards(query)}
                  placeholder={__('Obľubené vzorkovníky')}
                  isClearable={false}
                  isMulti
                  handleOnChange={e =>
                    handleFavoritColorcardsChange(e, 'favoritColorcards')
                  }
                />
              </GreyBoxContentWrapper>
            </GreyBox>
            <Space />
            <GreyBox
              title={__('Exportované vzorkovníky pre Farlesk')}
              sidePadding={0}
            >
              <GreyBoxContentWrapper>
                <SearchableSelect
                  disabled={toningReadOnly}
                  value={farleskExportColorcards}
                  loadOptions={query => this.fetchColorCards(query)}
                  placeholder={__('Obľubené vzorkovníky')}
                  isClearable={false}
                  isMulti
                  handleOnChange={e =>
                    handleFarleskExportColorcardsChange(
                      e,
                      'farleskExportColorcards',
                    )
                  }
                />
              </GreyBoxContentWrapper>
            </GreyBox>
            <Space />
            <GreyBox title={__('Textúra pre vzorkovníky')} sidePadding={0}>
              <GreyBoxContentWrapper>
                <SearchableSelect
                  disabled={toningReadOnly}
                  value={colorcardsTexture}
                  loadOptions={query => {
                    return fetchMultimedia(query);
                  }}
                  placeholder={__('Textúra pre vzorkovníky')}
                  handleOnChange={e =>
                    handleColorcardsTexture(e, 'favoritColorcards')
                  }
                />
                <Space />
                {colorcardsTexture && (
                  <TexturesWrapper>
                    <TextureWrapper color={'#373737'}>
                      <TextureImage
                        src={`${photoBasePath}/${colorcardsTexture.imageFullPath}`}
                      />
                    </TextureWrapper>
                    <Space />
                    <TextureWrapper color={'white'}>
                      <TextureImage
                        src={`${photoBasePath}/${colorcardsTexture.imageFullPath}`}
                      />
                    </TextureWrapper>
                    <Space />
                    <TextureWrapper color={'#853434'}>
                      <TextureImage
                        src={`${photoBasePath}/${colorcardsTexture.imageFullPath}`}
                      />
                    </TextureWrapper>
                  </TexturesWrapper>
                )}
              </GreyBoxContentWrapper>
            </GreyBox>
          </Halfer>
        </Wrapper>
        <ButtonRelativeWrapper>
          <Button loading={saveLoading} onClick={() => this.saveData()} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

Toning.propTypes = {
  externalProducts: PropTypes.arrayOf({}),
  variants: PropTypes.arrayOf({}),
  onCoeficientChange: PropTypes.func,
  handleExternalProductsChange: PropTypes.func,
  saveCard: PropTypes.func,
  raiseErrorMessage: PropTypes.func,
  productId: PropTypes.string,
  externalProduct: PropTypes.shape({}),
  handleExternalProduct: PropTypes.func,
  selectedToningSystemId: PropTypes.string,
  firm: PropTypes.string,
  noTintigParam: PropTypes.shape({}),
  noTintinChoosesParam: PropTypes.shape({}),
  handleNoTintingValueChange: PropTypes.func,
  basesSettings: PropTypes.arrayOf({}),
  handleBaseSettingsChange: PropTypes.func,
  favoritColorcards: PropTypes.arrayOf({}),
  farleskExportColorcards: PropTypes.arrayOf({}),
  handleFavoritColorcardsChange: PropTypes.func,
  handleFarleskExportColorcardsChange: PropTypes.func,
};

Toning.defaultProps = {
  firm: 'SK',

  externalProducts: [],
  variants: [],
  onCoeficientChange: () => {},
  saveCard: () => {},
  handleExternalProductsChange: () => {},
  raiseErrorMessage: () => {},
  productId: null,
  externalProduct: null,
  handleExternalProduct: () => {},
  selectedToningSystemId: null,
  noTintigParam: null,
  noTintinChoosesParam: null,
  handleNoTintingValueChange: () => {},
  handleBaseSettingsChange: () => {},
  basesSettings: [],
  handleFavoritColorcardsChange: () => {},
  favoritColorcards: [],
  farleskExportColorcards: [],
  handleFarleskExportColorcardsChange: () => {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Toning);
