import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PaintSystemDetail from '../../../Containers/PaintSystemDetail';
import PaintSystemsList from '../../../Containers/PaintSystemsList';

function PaintingSystemCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={PaintSystemDetail} />
      <Route path={`${match.url}`} component={PaintSystemsList} />
    </Switch>
  );
}

PaintingSystemCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default PaintingSystemCard;
