import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CRMNotificationsList from '../../../Containers/CRMNotificationsList';
import CRMNotificationDetail from '../../../Containers/CRMNotificationDetail';

function CRMNotifications(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={CRMNotificationDetail} />
      <Route path={`${match.url}`} component={CRMNotificationsList} />
    </Switch>
  );
}

CRMNotifications.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CRMNotifications;
