import React from 'react';
import { Route } from 'react-router-dom';
import ProductsCard from './Products';
import ColourCards from './ColourCards';
import ToningSystems from './ToningSystems';
import Recipes from './Recipes';
import PaintSystems from './PaintSystems';
import ProductsPublicationCard from './ProductsPublicationCard';
import ProductsPackages from './ProductsPackages';
import Exports from '../../Containers/Exports';
import OnExternalStock from '../../Containers/OnExternalStock';
import EshopProductsNames from '../../Containers/EshopProductsNames';

function Catalog(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.url}/products`} component={ProductsCard} />
      <Route
        path={`${match.url}/products-publication`}
        component={ProductsPublicationCard}
      />
      <Route path={`${match.url}/colourCards`} component={ColourCards} />
      <Route path={`${match.url}/toningSystems`} component={ToningSystems} />
      <Route path={`${match.url}/recipes`} component={Recipes} />
      <Route path={`${match.url}/paint-systems`} component={PaintSystems} />
      <Route path={`${match.url}/products-exports`} component={Exports} />
      <Route
        path={`${match.url}/products-packages`}
        component={ProductsPackages}
      />
      <Route
        path={`${match.url}/on-external-stock`}
        component={OnExternalStock}
      />
      <Route
        path={`${match.url}/eshop-products-names`}
        component={EshopProductsNames}
      />
    </React.Fragment>
  );
}

export default Catalog;
