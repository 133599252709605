import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CategoriesSettings from '../../../Containers/CategoriesSettings';

function CategoriesCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={CategoriesSettings} />
    </Switch>
  );
}

CategoriesCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CategoriesCard;
