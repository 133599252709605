import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from 'oxyrion-ui/lib/';
import EmailEditor from 'react-email-editor';

import { __, FORMAT_HUMANE_DATETIME, formatDate } from '../../Utils';
import PopUpWrapper from '../PopUpWrapper';
import ConfirmDialog from '../ConfirmDialog';
import defaultJson from './default_json.json';

const ButtonWrapper = styled.div`
  display: flex;
  /* flex-direction: table-column;
  flex-direction: column; */
  justify-content: flex-end;
  /* margin-top: ${rem(32)}; */
  /* position: absolute; */
  /* right: ${rem(72)};  */
  /* bottom: ${rem(150)}; */
  /* width: ${rem(352)}; */
  background-color: rgb(249, 249, 249); 
`;

const SaveButton = styled(Button)`
  margin: ${rem(10)};
  padding: ${rem(10)};
`;

const FinalSaveButton = styled(SaveButton)`
  color: white;
`;

const Container = styled.div`
  position: relative;
  padding-bottom: ${rem(24)};
  margin-top: -1px;
  border-top: 1px solid orange;
`;

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: rgb(249, 249, 249);
`;

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
    this.state = {
      showPopup: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  // componentWillUnmount() {
  //   this.isComponentMounted = false;
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props.keyProps !== nextProps.keyProps) {
      this.isEditorLoaded = true;
      this.isComponentMounted = true;
      this.loadTemplate();
    }
  }

  onLoad = () => {
    this.editorRef = this.editor.editor
    this.isEditorLoaded = true;
    this.loadTemplate();
  };

  loadTemplate = () => {
    const { jsonData, lang: selectedLang } = this.props;
    setTimeout(() => {
      if (!this.isEditorLoaded || !this.isComponentMounted) {
        this.loadTemplate();
      } else {
        const json = jsonData || defaultJson;
        const newBodyRows = json.body.rows.filter(
          dbr => dbr.values.id !== '__currentFooter',
        ); // odreze sa stary footer
        json.body.rows = newBodyRows.concat(defaultJson.body.rows); // prida sa default

        let stringifiedDefaultJSON = JSON.stringify(json); // stringifyuje sa

        const footerText1 =
          selectedLang === 'SK'
            ? 'Tento email dostávate, pretože ste sa prihlásili na odber tipov a rád spoločnosti {{senderCompany}}.'
            : 'Tento email dostáváte, protože jste se přihlásili k odběru tipů a rad společnosti {{senderCompany}}.';

        const footerText2 =
          selectedLang === 'SK'
            ? 'Správa vam bola poslaná od {{senderName}}, {{senderEmail}} na email:'
            : 'Zpráva vam byla poslána od {{senderName}}, {{senderEmail}} na email:';

        const unsubscribeText =
          selectedLang === 'SK'
            ? 'Z odberu tipov, rád a know how sa môžete'
            : 'Z odběru tipů, rad a know how se můžete';

        const logoutText = selectedLang === 'SK' ? 'odhlásiť' : 'odhlásit';

        stringifiedDefaultJSON = stringifiedDefaultJSON.replace(
          '{{logout_text}}',
          logoutText,
        );
        stringifiedDefaultJSON = stringifiedDefaultJSON.replace(
          '{{footer_text_1}}',
          footerText1,
        );
        stringifiedDefaultJSON = stringifiedDefaultJSON.replace(
          '{{footer_text_2}}',
          footerText2,
        );
        stringifiedDefaultJSON = stringifiedDefaultJSON.replace(
          '{{unsubscribe_text_1}}',
          unsubscribeText,
        ); // nahradia sa labels

        this.editorRef.loadDesign(JSON.parse(stringifiedDefaultJSON));
        this.props.setEditor(this.editorRef);
      }
    }, 500);
  };

  saveDesign = action => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    this.editorRef.exportHtml(data => {
      const { design, html } = data;
      this.props.onSave(JSON.stringify(design), html, action);
    });

    this.setState({
      showPopup: false,
      showTestPopup: false,
    });
  };

  render() {
    const { showPopup, showTestPopup } = this.state;
    const { sendDate, checkData, loadingField, disabled } = this.props;

    return (
      <Container>
        <PopUpWrapper
          display={showPopup}
          small
          onClose={() =>
            this.setState({
              showPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={
              sendDate
                ? `${__('Newsletter bude odoslaný dňa:')} ${formatDate(
                    sendDate,
                    FORMAT_HUMANE_DATETIME,
                  )}. Prajete si pokračovať?`
                : __('Newsletter bude ihneď odoslaný. Prajete si pokračovať?')
            }
            onDismiss={() =>
              this.setState({
                showPopup: false,
              })
            }
            onConfirm={() => this.saveDesign('publish')}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showTestPopup}
          small
          onClose={() =>
            this.setState({
              showTestPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Newsletter bude odoslaný na kontrolu. Prajete si pokračovať?',
            )}
            onDismiss={() =>
              this.setState({
                showTestPopup: false,
              })
            }
            onConfirm={() => this.saveDesign('test')}
          />
        </PopUpWrapper>
        <SpaceBetweenWrapper>
          <SaveButton
            small
            primary
            loading={loadingField === 'test'}
            disabled={disabled}
            onClick={() => {
              if (checkData()) {
                this.setState({
                  showTestPopup: true,
                });
              } else {
                alert(
                  'Pred odoslaním je potrebné vyplniť všetky povinné polia',
                );
              }
            }}
          >
            {__('Odoslať testovací email')}
          </SaveButton>
          <ButtonWrapper>
            <SaveButton
              small
              primary
              loading={loadingField === 'stay'}
              disabled={disabled}
              onClick={() => {
                if (checkData()) {
                  this.saveDesign('stay');
                } else {
                  alert(
                    'Pred odoslaním je potrebné vyplniť všetky povinné polia',
                  );
                }
              }}
            >
              {__('Uložiť a pokračovať v editácii')}
            </SaveButton>
            <SaveButton
              small
              primary
              disabled={disabled}
              loading={loadingField === 'leave'}
              onClick={() => {
                if (checkData()) {
                  this.saveDesign('leave');
                } else {
                  alert(
                    'Pred odoslaním je potrebné vyplniť všetky povinné polia',
                  );
                }
              }}
            >
              {__('Uložiť a odísť')}
            </SaveButton>
            <FinalSaveButton
              style={{ backgroundColor: !disabled && '#2ecc71' }}
              small
              primary
              disabled={disabled}
              loading={loadingField === 'publish'}
              onClick={() => {
                if (checkData()) {
                  this.setState({
                    showPopup: true,
                  });
                } else {
                  alert(
                    'Pred odoslaním je potrebné vyplniť všetky povinné polia',
                  );
                }
              }}
            >
              {__('Uložiť a odoslať')}
            </FinalSaveButton>
          </ButtonWrapper>
        </SpaceBetweenWrapper>

        <EmailEditor
          ref={editor => {
            this.editor = editor;
          }}
          key={this.props.keyProps}
          onLoad={this.onLoad}
          minHeight={rem(window.innerHeight - 150)}
        />
      </Container>
    );
  }
}

Editor.propTypes = {
  jsonData: PropTypes.shape(),
  onSave: PropTypes.func.isRequired,
  sendDate: PropTypes.shape(),
  checkData: PropTypes.func.isRequired,
  loadingField: PropTypes.string,
  disabled: PropTypes.bool,
  keyProps: PropTypes.string,
  setEditor: PropTypes.func,
  lang: PropTypes.string,
};

Editor.defaultProps = {
  sendDate: null,
  disabled: false,
  loadingField: null,
  jsonData: defaultJson,
  keyProps: '',
  setEditor: () => {},
  lang: 'SK',
};

export default Editor;
