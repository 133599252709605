import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Prints from '../../../Containers/GPV/Prints';
// import MarketingMaterialDetail from '../../../Containers/MarketingMaterialDetail';

function PrintsCard(props) {
  const { match } = props;
  return (
    <Switch>
      {/* <Route path={`${match.url}/:id`} component={MarketingMaterialDetail} /> */}
      <Route path={`${match.url}`} component={Prints} />
    </Switch>
  );
}

PrintsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default PrintsCard;
