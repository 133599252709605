import { __ } from '../Utils';

export default [
  {
    name: __('Technický názov'),
    clickable: true,
    otherProps: { minWidth: 300 },
  },
  {
    name: __('Názov'),
    clickable: true,
  },
  {
    name: __('Typ'),
    clickable: false,
  },
  {
    name: __('Naviazaný na hodnotu'),
    clickable: false,
  },
  {
    name: __('Poradové číslo'),
    clickable: true,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
];
