import React from 'react';
import styled from 'styled-components';
import { Button, ThemeAdmin, Tabs } from 'oxyrion-ui/lib';
import Message from 'oxyrion-ui/lib/Message';
import { rem } from 'polished';
import { __ } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import PopUpWrapper from '../../Components/PopUpWrapper';
import TikkurilaExport from './TikkurilaExport';
import FarleskProductsExport from './FarleskProductsExport';
import ProductShadesExport from './ProductShadesExport';
import ProductTypesFarleskExportSettings from '../ProductTypesFarleskExportSettings';
import MetaPixelProductsExport from './MetaPixelProductsExport';
import ConfirmDialog from '../../Components/ConfirmDialog';
import { AnimatedFormMessageWrapper } from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import ObnovaProductsExport from './ObnovaProductsExport';
import SEKOProductsExport from './SEKOProductsExport';
import HomolaProductsExport from './HomolaProductsExport';
import KopelentProductsExport from './KopelentProductsExport';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(12)};
`;

class Exports extends React.Component {
  state = {
    activeTab: 'tab1',
    showParamsSettings: false,
  };

  async onParamsSettingsSave(data) {
    try {
      const params = {};

      params.body = {
        params: data,
      };

      await AdminAPI.putProductsParamsExportFarleskAction(params);

      this.setState({
        success: __('Nastavenie parametrov úspešne uložené'),
        showParamsSettings: false,
      });
    } catch (e) {
      this.setState({
        error: __('Parametre sa nepodarilo uložiť'),
        showParamsSettings: false,
      });
    }
  }

  onParamsSettingsDissmiss(changedData) {
    if (changedData.length > 0) {
      this.setState({
        showConfirmCloseDialog: true,
        showParamsSettings: false,
      });
    } else {
      this.setState({
        showParamsSettings: false,
      });
    }
  }

  onParamsSettingsDismissConfirmed() {
    this.setState({
      showParamsSettings: false,
      showConfirmCloseDialog: false,
    });
  }

  render() {
    const { history } = this.props;
    const {
      activeTab,
      showParamsSettings,
      showConfirmCloseDialog,
      error,
      success,
    } = this.state;

    return (
      <div>
        <PopUpWrapper
          display={showConfirmCloseDialog}
          small
          onClose={() => this.showConfirmDialog()}
          statusName={showConfirmCloseDialog}
          onEscClose={() => this.setState({ showConfirmCloseDialog: false })}
        >
          <ConfirmDialog
            message={__('Neuložené zmeny budú zahodené. Naozaj chcete odísť?')}
            onDismiss={() =>
              this.setState({
                showConfirmCloseDialog: false,
                showParamsSettings: true,
              })
            }
            onConfirm={() => this.onParamsSettingsDismissConfirmed()}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showParamsSettings}
          large
          titleZIndex={1000}
          customWidth={1200}
          message={__('Správa exportu parametrov produktov')}
          onClose={() => this.onParamsSettingsDissmiss([''])}
          onEscClose={() => this.onParamsSettingsDissmiss([''])}
        >
          <ProductTypesFarleskExportSettings
            onSave={data => this.onParamsSettingsSave(data)}
            onDissmiss={changedData =>
              this.onParamsSettingsDissmiss(changedData)
            }
          />
        </PopUpWrapper>
        <ControllBar history={history} name={__('Exporty')} />
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <Tabs
          color={ThemeAdmin.color.primary}
          onChange={to => {
            this.setState({
              activeTab: to,
            });

            return true;
          }}
          activeTab={{
            id: activeTab,
          }}
        >
          <Tabs.Tab id="tab1" title={__('Farlesk export')}>
            <ButtonWrapper>
              <Button
                primary
                small
                onClick={() =>
                  this.setState({
                    showParamsSettings: true,
                  })
                }
              >
                {__('Upraviť export produktov')}
              </Button>
            </ButtonWrapper>
            <FarleskProductsExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab5" title={__('Obnova export')}>
            <ObnovaProductsExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab2" title={__('Meta Pixel export')}>
            <MetaPixelProductsExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab3" title={__('Tikkurila export')}>
            <TikkurilaExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab4" title={__('Produkty a odtiene')}>
            <ProductShadesExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab6" title={__('SEKO export')}>
            <SEKOProductsExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab7" title={__('Homola export')}>
            <HomolaProductsExport />
          </Tabs.Tab>

          <Tabs.Tab id="tab8" title={__('Kopelent export')}>
            <KopelentProductsExport />
          </Tabs.Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Exports);
