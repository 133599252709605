import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { rem } from 'polished';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Select from 'oxyrion-ui/lib/Select';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Textarea,
} from 'oxyrion-ui/lib';
import productsCanals from '../../ContentConfig/productsCanals';
import { connect } from '../../Store';
import {
  getIdFromProps,
  __,
  formatBytes,
  fetchCodelistByQuery,
  validateYouTubeUrl,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import FileViewer from '../../Components/FileViewer';
import SearchableSelect from '../../Components/SearchableSelect';
import ProductSelector from '../../Components/ProductSelector';
import ShadeSelector from '../../Components/ShadeSelector';
import MetaDescriptionTextArea from '../../Components/MetaDescriptionTextArea';
import API2 from '../../API2';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  max-width: ${rem(500)};
  margin-bottom: ${rem(20)};
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  // margin-right: ${rem(16)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const CustomTextArea = styled(Textarea)`
  width: 96%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  // margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

const ChannelName = styled.div`
  font-size: 14px;
`;

const acceptedMimeTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'application/postscript',
  'video/quicktime',
  'video/x-flv',
  'video/mp4',
  'video/3gpp',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/*',
  'image/svg+xml',
];

const acceptedMimeTypesViewImage = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];

const videoTypes = [
  {
    value: 'local',
    label: 'Lokálne',
  },
  {
    value: 'youtube',
    label: 'Youtube',
  },
];

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/multimedia/{multimediaId}/files`;

class FilterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      types: [],
      categories: [],
      brands: [],
      showAddProduct: false,
      surfaces: [],
      activeLang: 'SK',
      data: {
        name: '',
      },
    };

    this.handler = ev => {
      ev.preventDefault();
      return (ev.returnValue = __(
        'Prebieha nahrávanie súboru, naozaj chcete odísť?',
      ));
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getUploadParams = ({ file }, type) => {
    const { activeLang } = this.state;

    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type);
    formData.append('lang', activeLang);

    return {
      url: fileUploadURL.replace('{multimediaId}', this.state.data._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };

  getTranslatedData(data, lang) {
    if (!data) {
      return '';
    }

    const obj = data.find(d => d.lang === lang);
    return obj ? obj.value : '';
  }

  getImageForLang(files, lang, hasSame, type) {
    if (!files) {
      return null;
    }
    const langLocal = hasSame ? 'SK' : lang;

    const images = files.filter(i => i.lang === langLocal);

    const image = images.find(i => i.type === type);

    if (image) {
      return image.value;
    }
    return null;
  }

  async loadData(invokeLoader) {
    if (invokeLoader) {
      this.setState({
        loading: true,
      });
    }
    const id = getIdFromProps(this.props);
    const { data } = this.state;

    this.fetchCodelists();

    if (id !== 'new') {
      this.setState({ loading: true });
      await this.fetchMaterialData(id);
    } else {
      const query = this.useQuery(this.props);
      data.type = query.type || '';

      this.setState({
        data,
      });

      this.fetchCategoriesCodelist(query.type);
    }
  }

  async fetchCodelists() {
    try {
      const result = await Promise.all([
        AdminAPI.getCodelist('multimedia_types'),
        API2.getBrandsAction(this.props.firm),
      ]);

      this.setState({
        types: result[0].codelist || [],
        brands: result[1].brands || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchCategoriesCodelist(type) {
    try {
      const result = await AdminAPI.getCodelist(`${type}_types`);

      this.setState({
        categories: result.codelist || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  // fetchLocalProducts(query) {
  //   const { data } = this.state;

  //   if (!data.used_products) {
  //     return [];
  //   }

  //   return data.used_products
  //     .filter(
  //       u =>
  //         u.variant.name.toUpperCase().includes(query.toUpperCase()) &&
  //         u.catalogProduct.type === 'FARBA',
  //     )
  //     .map(res => ({
  //       value: res.catalogProduct._id,
  //       label: res.variant.name,
  //       variantId: res.variant._id,
  //     }));
  // }

  fetchLocalProducts(query) {
    const { data } = this.state;

    if (!data.used_products) {
      return [];
    }
    return data.used_products
      .filter(u => u.name.toUpperCase().includes(query.toUpperCase()))
      .map(res => ({
        value: res._id,
        label: res.name,
      }));
  }

  useQuery(props) {
    return queryString.parse(props.location.search);
  }

  async fetchMaterialData(id) {
    try {
      const { activeLang } = this.state;

      const data = await AdminAPI.getGraphicPrintVideoAction(id);

      if (data.type === 'video') {
        const mainFile = data.files.find(
          f => f.type === 'main' && f.lang === activeLang,
        );
        if (mainFile && mainFile.value.youtube) {
          data.videoType = 'youtube';
        } else {
          data.videoType = 'local';
        }
      }

      await this.fetchCategoriesCodelist(data.type);

      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Multimédium sa nenašlo'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteList() {
    const { history, firm } = this.props;
    const { data } = this.state;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteGraphicPrintVideoAction(getIdFromProps(this.props));
      history.push(
        `/${firm}/${data.type}${data.type === 'photos' ? ' ' : 's'}`,
      );
    } catch (e) {
      this.setState({
        deleteError: __('Pri mazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    if (!data.category || data.category.length === 0 || data.category === '-') {
      isValid = false;
    }

    // if (checkFile && !data.files) {
    //   isValid = false;
    // }

    return isValid;
  }

  async saveData(checkFile = false, showSucces = true) {
    const { data, activeLang } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data, checkFile)) {
      try {
        const params = {};
        params.body = Object.assign({}, data);

        if (data.used_products) {
          params.body.used_products = data.used_products.map(u => ({
            _id: u._id,
            name: u.name,
            published: u.published,
            shades: u.shades && u.shades,
          }));
        }

        if (getIdFromProps(this.props) === 'new') {
          const result = await AdminAPI.postGraphicPrintVideoAction(params);
          this.props.history.push(
            `/${this.props.firm}/multimedia/${result._id}`,
          );

          if (result.type === 'video') {
            const mainFile = result.files.find(
              f => f.type === 'main' && f.lang === activeLang,
            );

            if (mainFile && mainFile.value.youtube) {
              result.videoType = 'youtube';
            } else {
              result.videoType = 'local';
            }
          }

          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        } else {
          const result = await AdminAPI.putGraphicPrintVideoAction(
            getIdFromProps(this.props),
            params,
          );

          if (result.type === 'video') {
            const mainFile = result.files.find(
              f => f.type === 'main' && f.lang === activeLang,
            );

            if (mainFile && mainFile.value.youtube) {
              result.videoType = 'youtube';
            } else {
              result.videoType = 'local';
            }
          }
          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        error: __('Nie sú vyplnené všetky potrebné hodnoty'),
        saveLoading: false,
      });
    }
  }

  removeProduct(id) {
    const { data } = this.state;

    data.used_products = data.used_products.filter(u => u._id !== id);

    this.setState({
      data,
    });
  }

  handleProductPublicationChange(e, id) {
    const { data } = this.state;

    data.used_products.find(u => u._id === id).published = e.target.checked;

    this.setState({
      data,
    });
  }

  async handleChange(field, value) {
    const { data } = this.state;

    if (field === 'type') {
      await this.fetchCategoriesCodelist(value);
    }

    data[field] = value;

    this.setState({ data, error: false });
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    if (!data[field]) {
      data[field] = [];
    }

    if (!data[field].find(d => d.lang === lang)) {
      data[field].push({
        lang,
        value,
      });
    } else {
      data[field].find(d => d.lang === lang).value = value;
    }

    this.setState({
      data,
      error: false,
    });
  }

  handleVariantToAddChange(e) {
    const { data } = this.state;

    if (!data.used_products) {
      data.used_products = [];
    }

    data.used_products.push(e);

    this.setState({
      data,
    });
  }

  addProduct(e) {
    const { data } = this.state;

    if (!data.used_products) {
      data.used_products = [];
    }

    data.used_products.push({
      _id: e.value,
      name: e.label,
      published: true,
      shades: [],
    });

    this.setState({
      data,
    });
  }

  handleProductToAddChange(e) {
    let { productToAdd } = this.state;

    productToAdd = e;

    this.setState({ productToAdd });
  }

  handleProductToAddShade(e) {
    let { productToShadeAdd } = this.state;

    productToShadeAdd = e;

    this.setState({ productToShadeAdd });
  }

  removeShadeFromProduct(colorCode, productId, index) {
    const { data } = this.state;

    data.used_products.find(u => u._id === productId).shades.splice(index, 1);

    this.setState({
      data,
    });
  }

  handleShadeSelect(e, productId) {
    const { data } = this.state;

    if (!data.used_products.find(u => u._id === productId).shades) {
      data.used_products.find(u => u._id === productId).shades = [];
    }

    data.used_products.find(u => u._id === productId).shades.push(e.raw);

    this.setState({
      data,
    });
  }

  handleProductChange(value) {
    const { data } = this.state;

    if (!data.product) {
      data.product = {};
    }

    data.product = value;

    this.setState({
      data,
      error: false,
    });
  }

  handlePublicationChange(value) {
    const { data, activeLang } = this.state;

    if (!data.publication) {
      data.publication = {};
    }

    if (!data.publication[activeLang]) {
      data.publication[activeLang] = [];
    }

    if (data.publication[activeLang].includes(value)) {
      data.publication[activeLang].splice(
        data.publication[activeLang].indexOf(value),
        1,
      );
    } else {
      data.publication[activeLang].push(value);
    }

    this.setState({
      data,
      error: false,
    });
  }

  transXPaintLabel(value) {
    const { activeLang } = this.state;

    if (value !== 'xFarby') {
      return value;
    }

    if (activeLang === 'CZ') {
      return 'xBarvy';
    }
    if (activeLang === 'AT') {
      return 'xFarben';
    }
    if (activeLang === 'HU') {
      return 'xFestek';
    }

    return 'xFarby';
  }

  handleFilePathChange(value) {
    const { data, activeLang } = this.state;

    const langLocal = data.same_image ? 'SK' : activeLang;

    if (this.getImageForLang(data.files, activeLang, data.same_image, 'main')) {
      data.files
        .filter(i => i.lang === langLocal)
        .find(i => i.type === 'main').value.fullPath = value;
    } else {
      data.files = [
        {
          value: { fullPath: value },
          type: 'main',
          lang: langLocal,
        },
      ];
    }
    this.setState({ data });
  }

  handleChangeActiveLang(lang) {
    const { data } = this.state;

    if (data.type === 'video') {
      const mainFile = data.files.find(
        f => f.type === 'main' && f.lang === lang,
      );
      if (mainFile && mainFile.value.youtube) {
        data.videoType = 'youtube';
      } else {
        data.videoType = 'local';
      }
    }

    this.setState({
      activeLang: lang,
      data,
    });
    return true;
  }

  async saveYoutubeVideo() {
    const { data, activeLang } = this.state;

    this.setState({
      youtubeVideoLoading: true,
    });

    const file = data.files.find(
      f => f.type === 'main' && f.lang === activeLang,
    );

    if (!validateYouTubeUrl(file.value.fullPath)) {
      this.setState({
        error: __('Youtube link nie je správny'),
        youtubeVideoLoading: false,
      });
    }

    try {
      await AdminAPI.postYoutubeVideoAction(data._id, {
        body: {
          lang: file.lang,
          type: file.type,
          fullPath: file.value.fullPath,
        },
      });

      this.setState({
        success: __('Link bol uložený'),
        youtubeVideoLoading: false,
      });
    } catch (e) {
      this.setState({
        youtubeVideoLoading: false,
      });
    }
  }

  async checkShades() {
    const { data } = this.state;

    this.setState({
      checkLoading: true,
    });

    await this.saveData(false, false);

    const promises = [];
    data.used_products
      .filter(u => u.shades && u.shades.length)
      .forEach(product => {
        product.shades.forEach(shade => {
          promises.push(this.checkShadePrice(product.catalogProduct, shade));
        });
      });

    const result = await Promise.all(promises);

    data.used_products.map(product =>
      Object.assign(product, {
        shades:
          product.shades &&
          product.shades.length &&
          product.shades.map(shade2 => {
            const toned = result.find(
              r =>
                r.catalogProductId === product._id &&
                r.shadeId === shade2.color_code,
            );

            return Object.assign(shade2, {
              canBeToned: toned && toned.prices && toned.prices.length ? 1 : 0,
            });
          }),
      }),
    );

    this.setState({
      data,
      checkLoading: false,
    });
  }

  async checkShadePrice(catalogProduct, shade) {
    try {
      const result = await API2.getCatalogProductPricesAction(
        this.props.firm,
        catalogProduct._id,
        {
          shadeId: shade.color_code,
          colorCardId: shade.colorcard_id,
        },
      );

      return Object.assign(result, {
        catalogProductId: catalogProduct._id,
        shadeId: shade.color_code,
      });
    } catch (e) {
      return null;
    }
  }

  async removeFile(type) {
    const { data, activeLang } = this.state;

    let lang = 'SK';

    if (type === 'main' && !data.same_image) {
      lang = activeLang;
    } else if (!data.same_view_image) {
      lang = activeLang;
    }

    try {
      await AdminAPI.deleteMultimediaFileAction(data._id, {
        lang,
        type,
      });
      data.files.find(f => f.lang === lang && f.type === type).value = null;
      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  // called every time a file's `status` changes
  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    if (status === 'preparing') {
      this.setState({
        uploading: true,
      });

      if (getIdFromProps(this.props) === 'new') {
        cancel();
        await this.saveData();
        restart();
      }

      window.addEventListener('beforeunload', this.handler);
    }

    if (status === 'done') {
      const data = await AdminAPI.getGraphicPrintVideoAction(
        this.state.data._id,
      );

      window.removeEventListener('beforeunload', this.handler);
      this.setState(
        {
          data,
          uploading: false,
        },
        () => remove(),
      );
    }
  };

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Multimédia')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      saveLoading,
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
      activeLang,
      categories,
      fileLoading,
      uploading,
      brands,
      types,
      youtubeVideoLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    const file = this.getImageForLang(
      data.files,
      activeLang,
      data.same_image,
      'main',
    );

    const viewFile = this.getImageForLang(
      data.files,
      activeLang,
      data.same_view_image,
      'view',
    );

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť multimédium?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteList()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{ width: rem(450) }}>
                  <StyledLabel>{__('Typ*')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.type}
                    onChange={e => {
                      this.handleChange('type', e.target.value);
                    }}
                  >
                    <option
                      selected={!data.category || data.category === ''}
                      value={null}
                    >
                      {'-'}
                    </option>

                    {types ? (
                      types.map(c => {
                        return (
                          <option
                            selected={c.value === data.category}
                            value={c.value}
                          >
                            {c.label}
                          </option>
                        );
                      })
                    ) : (
                      <option>{__('Neobsahuje možnosti na výber')}</option>
                    )}
                  </Select>
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{ width: rem(450) }}>
                  <StyledLabel>{__('Kategória*')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.category}
                    onChange={e => {
                      this.handleChange('category', e.target.value);
                    }}
                  >
                    <option
                      selected={!data.category || data.category === ''}
                      value={null}
                    >
                      {'-'}
                    </option>

                    {categories ? (
                      categories.map(c => {
                        return (
                          <option
                            selected={c.value === data.category}
                            value={c.value}
                          >
                            {c.label}
                          </option>
                        );
                      })
                    ) : (
                      <option>{__('Neobsahuje možnosti na výber')}</option>
                    )}
                  </Select>
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(450) }}>
                  <StyledLabel>{__('Značka')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.brand}
                    onChange={e => {
                      this.handleChange('brand', e.target.value);
                    }}
                  >
                    <option
                      selected={!data.brand || data.brand === ''}
                      value={null}
                    >
                      {'-'}
                    </option>

                    {brands &&
                      brands.map(c => {
                        return (
                          <option selected={c === data.brand} value={c}>
                            {c}
                          </option>
                        );
                      })}
                  </Select>
                </InputWrapper>
              </Row>

              {data.type === 'video' && (
                <Row>
                  <InputWrapper style={{ width: rem(450) }}>
                    <StyledLabel>{__('Povrch')}</StyledLabel>
                    <SearchableSelect
                      value={data.surfaces}
                      loadOptions={query =>
                        fetchCodelistByQuery(query, 'povrch_ns')
                      }
                      isClearable={false}
                      isMulti
                      placeholder={__('Povrch')}
                      handleOnChange={e => this.handleChange('surfaces', e)}
                    />
                  </InputWrapper>
                </Row>
              )}

              {data.type === 'video' && (
                <Row>
                  <InputWrapper style={{ width: rem(450) }}>
                    <StyledLabel>{__('Objekt/Priestor')}</StyledLabel>
                    <SearchableSelect
                      value={data.rooms}
                      loadOptions={query =>
                        fetchCodelistByQuery(query, 'objekt_priestor')
                      }
                      isClearable={false}
                      isMulti
                      placeholder={__('Objekt/Priestor')}
                      handleOnChange={e => this.handleChange('rooms', e)}
                    />
                  </InputWrapper>
                </Row>
              )}
            </SettingsBox>
            <Space />
          </Row>
          {data.category && data.name && (
            <div>
              <Row>
                <LanguageSwitch
                  disabled={uploading}
                  onChange={lang => this.handleChangeActiveLang(lang)}
                  activeId={activeLang}
                />
              </Row>

              <Row>
                <SettingsBox addDisabled>
                  <Row>
                    <InputWrapper>
                      <StyledLabel>{`${__(
                        'Publikácia',
                      )} - ${activeLang}`}</StyledLabel>
                      <Row style={{ margin: 0 }}>
                        {productsCanals.map(c => (
                          <CheckboxWrapper>
                            <Checkbox
                              onChange={() => this.handlePublicationChange(c)}
                              checked={
                                data.publication && data.publication[activeLang]
                                  ? data.publication[activeLang].includes(c)
                                  : false
                              }
                            />
                            <ChannelName>
                              {this.transXPaintLabel(c)}
                            </ChannelName>
                            <Space />
                          </CheckboxWrapper>
                        ))}
                      </Row>
                    </InputWrapper>
                  </Row>
                  <Row>
                    <InputWrapper style={{ width: rem(450) }}>
                      <StyledLabel>{__('Názov v lokálnom jazyku')}</StyledLabel>
                      <StyledInput
                        style={{
                          width: rem(430),
                        }}
                        value={this.getTranslatedData(
                          data.name_translations,
                          activeLang,
                        )}
                        placeholder={__('Názov')}
                        onChange={e =>
                          this.handleLocalChange(
                            'name_translations',
                            activeLang,
                            e.target.value,
                          )
                        }
                      />
                    </InputWrapper>
                  </Row>

                  <Row>
                    <InputWrapper>
                      <StyledLabel>{__('Krátky popis')}</StyledLabel>
                      <CustomTextArea
                        onChange={e =>
                          this.handleLocalChange(
                            'short_description_translations',
                            activeLang,
                            e.target.value,
                          )
                        }
                        value={this.getTranslatedData(
                          data.short_description_translations,
                          activeLang,
                        )}
                        placeholder={__('Krátky popis')}
                      />
                    </InputWrapper>
                  </Row>
                  <Row>
                    <InputWrapper>
                      <StyledLabel>{__('Rozsiahly popis')}</StyledLabel>
                      <CustomTextArea
                        onChange={e =>
                          this.handleLocalChange(
                            'long_description_translations',
                            activeLang,
                            e.target.value,
                          )
                        }
                        value={this.getTranslatedData(
                          data.long_description_translations,
                          activeLang,
                        )}
                        placeholder={__('Rozsiahly popis')}
                      />
                    </InputWrapper>
                  </Row>

                  <Row>
                    <InputWrapper>
                      <StyledLabel>{__('Meta title')}</StyledLabel>
                      <CustomTextArea
                        onChange={e =>
                          this.handleLocalChange(
                            'meta_title_translations',
                            activeLang,
                            e.target.value,
                          )
                        }
                        value={this.getTranslatedData(
                          data.meta_title_translations,
                          activeLang,
                        )}
                        placeholder={__('Meta title')}
                      />
                    </InputWrapper>
                  </Row>
                  <Row>
                    <InputWrapper>
                      <StyledLabel>{__('Meta description')}</StyledLabel>
                      <MetaDescriptionTextArea
                        onChange={e =>
                          this.handleLocalChange(
                            'meta_description_translations',
                            activeLang,
                            e.target.value,
                          )
                        }
                        value={this.getTranslatedData(
                          data.meta_description_translations,
                          activeLang,
                        )}
                        placeholder={__('Meta description')}
                      />
                    </InputWrapper>
                  </Row>
                  <Row>
                    <InputWrapper>
                      <StyledLabel>{__('Náhľadový obrázok')}</StyledLabel>

                      <Row>
                        <InputWrapper>
                          {/* <StyledLabel>{__('Náhľad náhľadového súboru')}</StyledLabel> */}
                          <FileViewer loading={fileLoading} file={viewFile} />
                        </InputWrapper>
                      </Row>

                      <Dropzone
                        getUploadParams={e => this.getUploadParams(e, 'view')}
                        onChangeStatus={this.handleChangeStatus}
                        accept={acceptedMimeTypesViewImage.join(',')}
                        maxFiles={1}
                        multiple={false}
                        inputContent={
                          viewFile
                            ? __('Zmeniť náhľadový obrázok')
                            : __('Nahrať náhľadový obrázok')
                        }
                        canCancel={false}
                        styles={{
                          inputLabel: { color: '#ee6500', fontSize: rem(12) },
                          dropzone: {
                            border: 0,
                          },
                        }}
                      />
                    </InputWrapper>
                  </Row>

                  <Row
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <Label>
                      {__('Náhľadový obrázok je rovnaký pre všetky krajiny')}
                    </Label>
                    <Checkbox
                      onChange={() =>
                        this.handleChange(
                          'same_view_image',
                          !data.same_view_image,
                        )
                      }
                      checked={data.same_view_image}
                    />
                  </Row>

                  {data.type === 'video' && (
                    <Row>
                      <InputWrapper style={{ width: rem(450) }}>
                        <StyledLabel>{__('Typ videa')}</StyledLabel>
                        <Select
                          size="s"
                          value={data.videoType}
                          onChange={e => {
                            this.handleChange('videoType', e.target.value);
                          }}
                        >
                          {videoTypes ? (
                            videoTypes.map(c => {
                              return (
                                <option
                                  selected={c.value === data.videoType}
                                  value={c.value}
                                >
                                  {c.label}
                                </option>
                              );
                            })
                          ) : (
                            <option>
                              {__('Neobsahuje možnosti na výber')}
                            </option>
                          )}
                        </Select>
                      </InputWrapper>
                    </Row>
                  )}

                  {(data.type !== 'video' || data.videoType === 'local') && (
                    <Row>
                      <InputWrapper>
                        <StyledLabel>{__('Súbor*')}</StyledLabel>
                        <Row>
                          <InputWrapper>
                            {/* <StyledLabel>{__('Náhľad súboru')}</StyledLabel> */}
                            <FileViewer loading={fileLoading} file={file} />
                          </InputWrapper>
                        </Row>

                        <Dropzone
                          getUploadParams={e => this.getUploadParams(e, 'main')}
                          onChangeStatus={this.handleChangeStatus}
                          accept={acceptedMimeTypes.join(',')}
                          maxFiles={1}
                          multiple={false}
                          inputContent={file ? 'Zmeniť súbor' : 'Nahrať súbor'}
                          canCancel={false}
                          styles={{
                            inputLabel: {
                              color: '#ee6500',
                              fontSize: rem(12),
                            },
                            dropzone: {
                              minHeight: 100,
                              maxHeight: 200,
                              border: 0,
                              alignItems: 'flex-start',
                              textAlign: 'start',
                            },
                          }}
                        />
                      </InputWrapper>
                    </Row>
                  )}

                  <Row
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <Label>
                      {__('Multimédium je rovnaké pre všetky krajiny')}
                    </Label>
                    <Checkbox
                      onChange={() =>
                        this.handleChange('same_image', !data.same_image)
                      }
                      checked={data.same_image}
                    />
                  </Row>

                  {(data.type !== 'video' || data.videoType === 'local') && (
                    <Row>
                      <InputWrapper>
                        <StyledLabel>{__('Typ súboru')}</StyledLabel>
                        <StyledInput
                          style={{ width: rem(430) }}
                          value={file ? file.mimeType : ''}
                          disabled
                          placeholder={__('Typ súboru')}
                        />
                      </InputWrapper>
                    </Row>
                  )}

                  {data.type === 'video' && data.videoType === 'youtube' && (
                    <Row>
                      <InputWrapper>
                        <StyledLabel>{__('Cesta k youtube videu')}</StyledLabel>
                        <StyledInput
                          style={{ width: rem(430) }}
                          value={file ? file.fullPath : ''}
                          onChange={e =>
                            this.handleFilePathChange(e.target.value)
                          }
                          placeholder={__('Cesta k youtube videu')}
                        />
                      </InputWrapper>

                      <Button
                        loading={youtubeVideoLoading}
                        onClick={() => this.saveYoutubeVideo(file)}
                        primary
                        small
                      >
                        {__('Uložiť')}
                      </Button>
                    </Row>
                  )}

                  {(data.type !== 'video' || data.videoType === 'local') && (
                    <Row>
                      <InputWrapper>
                        <StyledLabel>{__('Veľkosť súboru')}</StyledLabel>
                        <StyledInput
                          style={{ width: rem(430) }}
                          value={file ? formatBytes(file.size) : ''}
                          disabled
                          placeholder={__('Veľkosť súboru')}
                        />
                      </InputWrapper>
                    </Row>
                  )}
                </SettingsBox>
                <Space />
              </Row>
            </div>
          )}
          {data.type === 'video' && (
            <Row>
              <SettingsBox addDisabled>
                <ProductSelector
                  firm={this.props.firm}
                  usedProducts={data.used_products}
                  type="catalog"
                  // onVariantSelect={e => this.handleVariantToAddChange(e)}
                  onCatalogProductSelect={e => this.addProduct(e)}
                  removeProduct={id => this.removeProduct(id)}
                  handleProductPublicationChange={(e, variantId) =>
                    this.handleProductPublicationChange(e, variantId)
                  }
                  label={__('Použité produkty')}
                />

                <ShadeSelector
                  firm={this.props.firm}
                  usedProducts={data.used_products}
                  fetchLocalProducts={q => this.fetchLocalProducts(q)}
                  handleShadeSelect={(e, variantId) =>
                    this.handleShadeSelect(e, variantId)
                  }
                  removeShadeFromProduct={(colorCode, variantId, index) =>
                    this.removeShadeFromProduct(colorCode, variantId, index)
                  }
                  type="catalog"
                  checkShades={() => this.checkShades()}
                  label={__('Použité odtiene')}
                />
              </SettingsBox>
            </Row>
          )}
        </ContentWrapper>

        <ButtonRelativeWrapper>
          <Button onClick={() => this.loadData(true)} danger>
            {__('Zahodiť zmeny')}
          </Button>
          <Space />
          <Space />
          <Space />
          <Button
            loading={saveLoading}
            onClick={() => this.saveData(true)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

FilterDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FilterDetail);
