import { __ } from '../Utils';

export default [
  {
    name: `${__('Všetky')}`,
    value: 'all',
  },
  {
    name: `${__('Marketingové zoznamy')}`,
    value: 'marketing_lists',
  },
  {
    name: `${__('Grafika')}`,
    value: 'graphics',
  },
  {
    name: `${__('Tlačoviny')}`,
    value: 'prints',
  },
  {
    name: `${__('Videá')}`,
    value: 'videos',
  },
  {
    name: `${__('Fotobanka')}`,
    value: 'photos',
  },
  {
    name: `${__('Marketingové materiály')}`,
    value: 'marketing_materials',
  },
  {
    name: `${__('Náterové systémy')}`,
    value: 'paint_systems',
  },

  {
    name: `${__('Katalóg produktov')}`,
    value: 'catalog_products',
  },
];
