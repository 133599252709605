import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone-uploader';
import { rem } from 'polished';
import Img from 'react-image';
import { Label, Button, Input, Loader, Checkbox } from 'oxyrion-ui/lib';
import MetaDescriptionTextArea from '../../Components/MetaDescriptionTextArea';
import SearchableSelect from '../../Components/SearchableSelect';
import TextEditor from '../../Components/TextEditor';
import { searchCategories } from '../../Utils/ApiSearchFunctions';
import { __, formatBytes } from '../../Utils';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import AdminAPI from '../../AdminAPI';

const acceptedMimeTypesViewImage = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];

const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;
const TextEditorWrapper = styled.div`
  width: 100%;
  /* max-width: ${rem(1400)}; */
  /* margin-left: ${rem(12)}; */
  background: white;
`;
const Image = styled(Img)`
  max-width: ${rem(230)};
`;

const ImageInfo = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;

const ImageInfoWrapper = styled.div`
  margin-left: ${rem(24)};
`;

const ImageDelete = styled.div`
  font-size: 12px;
  color: red;
  display: flex;
  cursor: pointer;
  margin-left: ${rem(8)};
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const ImageRow = styled(Row)`
  border-bottom: ${rem(1)} solid #eee;
  padding: ${rem(12)};
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const SettingsPart = styled.div`
  margin-top: ${rem(33)};
  max-width: 700px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(12)};
  display: flex;
  justify-content: space-between;
`;
export const ErrorText = styled(Label)`
  color: #e94141;
  margin-bottom: ${rem(4)};
`;
const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${rem(12)};
`;
const CategoryDetail = styled.div`
  /* width: 90%; */
`;

const SettingsBox = styled.div`
  background: #eee;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  margin-right: ${rem(24)};

  .title {
    margin-left: 5px;
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: bold;
  }
`;
const CategoryName = styled.div`
  position: relative;
`;

const CategoryCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem(8)};
`;

const CheckboxWrapper = styled.div`
  margin-left: ${rem(8)};
  font-weight: normal;
`;

class CategoryEditor extends Component {
  state = {
    category: {},
  };

  componentDidMount() {
    this.loadCategyVideo(this.props.category);
  }

  componentDidUpdate(prevProps) {
    const {
      showConflictConfirmDialog,
      showDeleteDialog,
      urlError,
    } = this.props;
    if (prevProps.showDeleteDialog !== showDeleteDialog) {
      this.setState({
        showDeleteDialog,
      });
    }
    if (
      !this.compareShowConflicConfirmDialog(
        showConflictConfirmDialog,
        prevProps.showConflictConfirmDialog,
      )
    ) {
      this.setState({
        showConflictConfirmDialog,
      });
    }
    if (prevProps.urlError !== urlError) {
      this.setState({
        urlError,
      });
    }
  }

  async loadCategyVideo(category) {
    const newCategory = Object.assign({}, category);

    if (this.props.category && this.props.category.video_id) {
      const video = await AdminAPI.getMultimediaByIdAction(
        this.props.selectedLang,
        this.props.category.video_id,
      );

      newCategory.video = {
        value: video._id,
        label: video.name,
      };
    }

    this.setState({
      category: newCategory,
    });
  }

  compareShowConflicConfirmDialog(dialog1, dialog2) {
    if (dialog1 === dialog2) {
      return true;
    }
    if (dialog1 === null || dialog2 === null) {
      return false;
    }
    if (dialog1.value === dialog2.value && dialog1.method === dialog2.method) {
      return true;
    }
    return false;
  }
  handleCategoryChange(field, value) {
    let { category } = this.state;

    if (!category) {
      category = {
        industry_category: false,
        name: null,
        path: null,
        parent: null,
        video: null,
      };
    }

    category[field] = value;

    this.setState({
      category,
    });
  }

  async fetchMultimedia(query) {
    try {
      return AdminAPI.getMultimediaAction(this.props.selectedLang, {
        q: query,
        limit: 10,
        type: 'video',
      }).then(res => {
        return res.multimedia.map(item => ({
          value: item._id,
          label: item.name,
        }));
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    if (status === 'done') {
      await this.props.onImageUploaded(this.state.category._id);
    }
  };

  render() {
    const {
      category,
      showConflictConfirmDialog,
      showDeleteDialog,
      urlError,
    } = this.state;
    const {
      categoryType,
      saveLoading,
      deleteLoading,
      allCategories,
      selectedChannel,
      selectedLang,
    } = this.props;
    return (
      <SettingsPart>
        {categoryType === 'menu' && (
          <PopUpWrapper
            display={
              showConflictConfirmDialog && showConflictConfirmDialog.value
            }
            small
            onClose={() =>
              this.setState({
                showConflictConfirmDialog: {
                  value: false,
                },
              })
            }
          >
            <ConfirmDialog
              message={__(
                `Kategória s toutou url už existuje. Chcete ju aj tak uložiť?`,
              )}
              onDismiss={() =>
                this.setState({
                  showConflictConfirmDialog: {
                    value: false,
                  },
                })
              }
              loading={deleteLoading}
              onConfirm={() => {
                if (showConflictConfirmDialog.type === 'post') {
                  this.props.onCreate(category, true);
                } else {
                  this.props.onSave(category._id, category, true);
                }
              }}
            />
          </PopUpWrapper>
        )}
        {categoryType === 'menu' && (
          <PopUpWrapper
            display={showDeleteDialog}
            small
            onClose={() =>
              this.setState({
                showDeleteDialog: false,
              })
            }
          >
            <ConfirmDialog
              message={__(
                `Kategória obsahuje podkategórie, ktoré budú taktiež odstranené. Chcete pokračovať?`,
              )}
              onDismiss={() =>
                this.setState({
                  showDeleteDialog: false,
                })
              }
              loading={deleteLoading}
              onConfirm={() => this.props.onDelete(category._id, true)}
            />
          </PopUpWrapper>
        )}
        <SettingsBox>
          <div className="title">{`${
            category && category._id
              ? __('Nastavenie kategórie')
              : __('Nová kategória')
          }`}</div>
          <CategoryDetail>
            <CategoryCheckbox>
              <Label>{__('Priemyselná kategória')}</Label>
              <CheckboxWrapper>
                <Checkbox
                  onChange={() =>
                    this.handleCategoryChange(
                      'industry_category',
                      !this.state.category.industry_category,
                    )
                  }
                  checked={this.state.category.industry_category}
                />
              </CheckboxWrapper>
            </CategoryCheckbox>
            <CategoryName>
              <Label>{__('Lokálny názov kategórie')}</Label>
              <StyledInput
                value={category && category.name ? category.name : ''}
                onChange={e =>
                  this.handleCategoryChange('name', e.target.value)
                }
                placeholder={__('Názov kategórie')}
              />
            </CategoryName>
            <CategoryName>
              <Label>{__('Cesta (url) kategórie')}</Label>
              <StyledInput
                value={category && category.path ? category.path : ''}
                onChange={e =>
                  this.handleCategoryChange(
                    'path',
                    e.target.value.toLowerCase(),
                  )
                }
                onBlur={() => {
                  if (
                    category &&
                    category.path !== '' &&
                    categoryType === 'menu'
                  ) {
                    this.props.onUrlCheck(category.path);
                  }
                }}
                error={urlError}
                placeholder={__('url')}
              />
              {urlError && (
                <ErrorText>
                  {__('Kategória s touto url adresou už existuje')}
                </ErrorText>
              )}
            </CategoryName>
            <CategoryName>
              <Label>{__('Heuréka category ID')}</Label>
              <StyledInput
                value={
                  category && category.heureka_id ? category.heureka_id : ''
                }
                onChange={e =>
                  this.handleCategoryChange('heureka_id', e.target.value)
                }
                placeholder={__('Heuréka category ID')}
              />
              {urlError && (
                <ErrorText>
                  {__('Kategória s touto url adresou už existuje')}
                </ErrorText>
              )}
            </CategoryName>
            <CategoryName>
              <Label>{__('Popis')}</Label>
              <TextEditorWrapper>
                <TextEditor
                  margin={0}
                  options={['link', 'inline']}
                  onChange={text =>
                    this.handleCategoryChange('description', text)
                  }
                  text={category.description}
                />
              </TextEditorWrapper>
            </CategoryName>
            <CategoryName>
              <Label>{__('Meta title')}</Label>
              <StyledInput
                value={
                  category && category.meta_title ? category.meta_title : ''
                }
                onChange={e =>
                  this.handleCategoryChange('meta_title', e.target.value)
                }
                error={urlError}
                placeholder={__('Meta title')}
              />
            </CategoryName>
            <CategoryName>
              <Label>{__('Meta description')}</Label>
              <MetaDescriptionTextArea
                value={category ? category.meta_description : ''}
                onChange={e =>
                  this.handleCategoryChange('meta_description', e.target.value)
                }
              />
            </CategoryName>
            {categoryType === 'menu' && (
              <CategoryName>
                <Label>{__('Rodičovská kategória')}</Label>
                <SearchableSelect
                  keyProps={JSON.stringify(category && category._id)}
                  value={
                    category &&
                    category.parent &&
                    allCategories &&
                    allCategories.find(a => a._id === category.parent) && {
                      label: allCategories.find(a => a._id === category.parent)
                        .name,
                      value: category.parent,
                    }
                  }
                  isClearable
                  loadOptions={query =>
                    searchCategories(
                      selectedChannel,
                      selectedLang,
                      query,
                      category && category._id,
                    )
                  }
                  placeholder={__('Zvoľte rodiča kategórie')}
                  handleOnChange={e =>
                    this.handleCategoryChange('parent', e && e.value)
                  }
                />
              </CategoryName>
            )}
            <CategoryName>
              <Label>{__('Video pre kategóriu')}</Label>
              <SearchableSelect
                loadOptions={query => {
                  return this.fetchMultimedia(query);
                }}
                value={
                  this.state.category.video && this.state.category.video.label
                }
                placeholder={__('Video pre kategóriu')}
                handleOnChange={e => this.handleCategoryChange('video', e)}
              />
            </CategoryName>

            <InputWrapper style={{ width: rem(625) }}>
              {category && category.image && (
                <StyledLabel>{__('Obrázok')}</StyledLabel>
              )}
              <ImageUploadWrapper>
                {category && category.image && (
                  <ImageRow>
                    <Image
                      src={[
                        `${baseURL}/images/400x0/${category.image.fullPath}`,
                      ]}
                      loader={<Loader color="#70af00" />}
                    />
                    <ImageInfoWrapper>
                      <Label>{__('Typ súboru')}</Label>
                      <ImageInfo>{category.image.mimeType}</ImageInfo>
                      <Space />

                      <Label>{__('Veľkosť súboru')}</Label>
                      <ImageInfo>{formatBytes(category.image.size)}</ImageInfo>
                      <Space />
                      <ImageDelete
                        onClick={() =>
                          this.props.onImageDelete(
                            category._id,
                            category.image.fullPath,
                          )
                        }
                      >
                        {__('Odstrániť ')}
                      </ImageDelete>
                    </ImageInfoWrapper>
                  </ImageRow>
                )}
                {category && category._id && (
                  <Dropzone
                    files={category.image ? [category.image] : []}
                    getUploadParams={e => this.props.onUpload(e)}
                    onChangeStatus={this.handleChangeStatus}
                    accept={acceptedMimeTypesViewImage.join(',')}
                    inputContent={
                      category.image
                        ? __('Nahrať iný obrázok')
                        : __('Nahrať obrázok')
                    }
                    canCancel={false}
                    initialFiles={category.image ? [category.image] : []}
                    styles={{
                      inputLabel: { color: '#ee6500' },
                    }}
                  />
                )}
              </ImageUploadWrapper>
            </InputWrapper>
            <CategoryName>
              <ButtonWrapper>
                {category ? (
                  <Button
                    small
                    danger
                    onClick={() =>
                      this.setState({
                        category: null,
                      })
                    }
                  >
                    {__('Zahodiť zmeny')}
                  </Button>
                ) : (
                  <div />
                )}

                {category && category._id ? (
                  <div style={{ display: 'flex' }}>
                    <Button
                      small
                      danger
                      onClick={() => this.props.onDelete(category._id, false)}
                      loading={deleteLoading}
                    >
                      {__('Odstrániť')}
                    </Button>
                    <Space />
                    <Button
                      small
                      primary
                      onClick={() => this.props.onSave(category._id, category)}
                      loading={saveLoading}
                      disabled={!category || !category.name || !category.path}
                    >
                      {__('Upraviť')}
                    </Button>
                  </div>
                ) : (
                  <Button
                    small
                    primary
                    onClick={() => this.props.onCreate(category)}
                    loading={saveLoading}
                    disabled={!category || !category.name || !category.path}
                  >
                    {__('Vytvoriť')}
                  </Button>
                )}
              </ButtonWrapper>
            </CategoryName>
          </CategoryDetail>
        </SettingsBox>
      </SettingsPart>
    );
  }
}
CategoryEditor.propTypes = {
  category: PropTypes.shape({
    _id: string,
    name: string,
    path: string,
    parent: PropTypes.shape({}),
    image: PropTypes.shape({}),
    ancestors: PropTypes.arrayOf(PropTypes.shape({})),
    video_id: string,
  }),
  showConflictConfirmDialog: PropTypes.shape({}),
  showDeleteDialog: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUrlCheck: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onImageUploaded: PropTypes.func.isRequired,
  urlError: PropTypes.bool,
  saveLoading: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  selectedChannel: PropTypes.string,
  selectedLang: PropTypes.string,
  allCategories: PropTypes.arrayOf(PropTypes.shape({})),
  categoryType: PropTypes.string.isRequired,
};

CategoryEditor.defaultProps = {
  category: {
    parent: null,
    ancestors: [],
    video_id: null,
  },
  showConflictConfirmDialog: null,
  showDeleteDialog: false,
  selectedChannel: 'xFarby',
  selectedLang: 'SK',
  urlError: false,
  saveLoading: false,
  deleteLoading: false,
  allCategories: [],
};

export default CategoryEditor;
