import React from 'react';
import { Button, Checkbox, Label } from 'oxyrion-ui/lib';
import Select from 'oxyrion-ui/lib/Select';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseTable from '../BaseTable';
import API from '../../API';
import tableHeaders from '../../ContentConfig/translationsEntities';
import { __ } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import OwnStateTextArea from '../../Components/OwnStateTextArea';
import GreyBox from '../../Components/GreyBox';

const SelectWrapper = styled.div`
  margin-right: ${rem(40)};
  width: ${rem(225)};
`;

const CategoryTitle = styled.div`
  margin-right: ${rem(12)};
`;

const TextAreaWrapper = styled.div`
  width: 96%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const Row = styled.div`
  margin-bottom: ${rem(20)};
  display: flex;
  /* max-width: $)}; */
  /* overflow: auto; */
`;

const InputWrapper = styled.div`
  width: ${rem(400)};
`;

const CheckBoxesWrapper = styled.div`
  display: flex;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(10)};
  margin-left: ${rem(10)};
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(5)};
`;

const CheckBoxBarWrapper = styled.div`
  background: white;
  margin-right: ${rem(10)};
`;

const ButtonWrapper = styled.div`
  .button-info {
    font-size: ${rem(10)};
  }
`;

class TranslationsEntities extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: null });
    this.headers = tableHeaders;
    this.segmentName = __('Produktový katalóg');
    this.accessors = [
      'entityName',
      // 'attributeName',
      'SK_value',
      'CZ_value',
      'EN_value',
      'HU_value',
      'AT_value',
      'save_button',
    ];
    this.setSorter(null);
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      loading: false,
      onlyATEmpty: true,
      selectedEntity: null,
      selectedAttribute: null,
      entitesCategory: [],
      entitesAttributesCategory: [],
      entitesTranslations: [],
      offset: 0,
      limit: 25,
      translationsFilters: [
        {
          channel: 'SK',
          value: true,
        },
        {
          channel: 'CZ',
          value: false,
        },
        {
          channel: 'EN',
          value: false,
        },
        {
          channel: 'HU',
          value: false,
        },
        {
          channel: 'AT',
          value: true,
        },
      ],
      availabilityCheckbox: true,
    };

    this.changedValues = [];
  }

  async componentWillMount() {
    try {
      this.fetchEntities();
    } catch (e) {
      console.log(e);
    }
  }

  async fetchData(parameters = {}) {
    const {
      limit,
      offset,
      selectedAttribute,
      selectedEntity,
      availabilityCheckbox,
      onlyATEmpty,
    } = this.state;

    if (selectedEntity && selectedAttribute) {
      // if (this.changedValues.length) {
      //   alert(
      //     __(
      //       'Naozaj chcete opustiť túto sekciu? Neuložene zmeny budu stratené.',
      //     ),
      //   );

      //   return;
      // }
      try {
        this.setState({ loading: true });

        const params = Object.assign(
          {},
          {
            limit,
            offset,
            onlyEmptyAt: onlyATEmpty,
            isAvailable: availabilityCheckbox,
          },
          parameters,
        );

        const newData = await API.getEntityTranslationsAction(
          selectedEntity,
          selectedAttribute,
          params,
        );
        const changeLogs = await API.getEntintyTranslationsChangesLogAction(
          selectedEntity,
          selectedAttribute,
          '',
        );
        const content = this.normalizeColumns(
          newData.items,
          selectedAttribute,
          changeLogs.items,
        );

        this.setState({
          content,
          data: newData.items,
          loading: false,
          limit: newData.limit,
          offset: newData.offset,
          total: newData.total,
          nextOffset: newData.next_offset,
        });
      } catch (e) {
        console.log(e);
        this.setState({
          error: __('Pri načítavaní dát sa vyskytla chyba'),
        });
      }
    }
  }

  normalizeColumns(data, attributeName, changeLogs) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const _changeError = (changeLog, lang) => {
      if (!changeLog || !changeLog.changes_log) {
        return false;
      }

      const d = changeLog.changes_log.find(f => f.lang === lang);
      if (!d) {
        return true;
      }

      return d.checked || false;
    };

    const result = data.reduce((acumulator, item) => {
      const changeLog = changeLogs.find(f => f.entity_item_id === item._id);

      acumulator.push({
        _id: _shapeData(item._id),
        entityName: _shapeData(item.name || ''),
        // attributeName: _shapeData(attributeName),
        SK_value: _shapeData(
          <TextAreaWrapper>
            <OwnStateTextArea
              onChange={() => {}}
              onBlur={value =>
                this.handleValueChange(
                  item._id,
                  'SK',
                  item.translations.SK
                    ? item.translations.SK.value
                    : item.default_sk_value || '',
                  value,
                )
              }
              value={item.translations.SK ? item.translations.SK.value : ''}
              error={_changeError(changeLog, 'SK')}
            />
          </TextAreaWrapper>,
        ),
        CZ_value: _shapeData(
          <TextAreaWrapper>
            <OwnStateTextArea
              onChange={() => {}}
              onBlur={value =>
                this.handleValueChange(
                  item._id,
                  'CZ',
                  item.translations.CZ ? item.translations.CZ.value : '',
                  value,
                )
              }
              value={item.translations.CZ ? item.translations.CZ.value : ''}
              error={_changeError(changeLog, 'CZ')}
            />
          </TextAreaWrapper>,
        ),
        EN_value: _shapeData(
          <TextAreaWrapper>
            <OwnStateTextArea
              onChange={() => {}}
              onBlur={value =>
                this.handleValueChange(
                  item._id,
                  'EN',
                  item.translations.EN ? item.translations.EN.value : '',
                  value,
                )
              }
              value={item.translations.EN ? item.translations.EN.value : ''}
              error={_changeError(changeLog, 'EN')}
            />
          </TextAreaWrapper>,
        ),
        HU_value: _shapeData(
          <TextAreaWrapper>
            <OwnStateTextArea
              onChange={() => {}}
              onBlur={value =>
                this.handleValueChange(
                  item._id,
                  'HU',
                  item.translations.HU ? item.translations.HU.value : '',
                  value,
                )
              }
              value={item.translations.HU ? item.translations.HU.value : ''}
              error={_changeError(changeLog, 'HU')}
            />
          </TextAreaWrapper>,
        ),
        AT_value: _shapeData(
          <TextAreaWrapper>
            <OwnStateTextArea
              onChange={() => {}}
              onBlur={value =>
                this.handleValueChange(
                  item._id,
                  'AT',
                  item.translations.AT ? item.translations.AT.value : '',
                  value,
                )
              }
              value={item.translations.AT ? item.translations.AT.value : ''}
              error={_changeError(changeLog, 'AT')}
            />
          </TextAreaWrapper>,
        ),
        save_button: _shapeData(
          <ActionButtonsWrapper>
            {/* <Button onClick={() => this.saveRowData(item._id)} small primary>
              {__('Uložiť')}
            </Button> */}
            {changeLog && changeLog.changes_log.length && (
              <ButtonWrapper>
                <Button onClick={() => this.deleteLog(item._id)} small primary>
                  {__('Preklad je v poriadku')}
                </Button>
                <div className="button-info">
                  {__('Zmenil sa preklad niektorej hodnoty')}
                </div>

                <div className="button-info">
                  {__(
                    'Ak sa význam a obsah zhoduje v jednotlivých jazykoch môžete toto upozornenie zrušiť',
                  )}
                </div>
              </ButtonWrapper>
            )}
          </ActionButtonsWrapper>,
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async saveRowData(entityId) {
    try {
      const {
        entitesTranslations,
        selectedEntity,
        selectedAttribute,
        translationsFilters,
      } = this.state;

      const storedIndex = this.changedValues.findIndex(
        ch => ch._id === entityId,
      );
      if (storedIndex >= 0) {
        const entity = entitesTranslations.find(
          f => f.entity_id === selectedEntity,
        );

        await Promise.all([
          API.putEntityTranslationAction(
            entity.entity_id,
            entityId,
            selectedAttribute,
            { data: { translations: this.changedValues[storedIndex].value } },
          ),
          this.changedValues[storedIndex].value.length !==
          translationsFilters.length
            ? API.putEntintyTranslationsChangesLogAction(
                entity.entity_id,
                selectedAttribute,
                {
                  data: {
                    entity_item_id: entityId,
                    changes_log: this.changedValues[storedIndex].value.map(
                      m => ({
                        lang: m.lang,
                        changed: true,
                      }),
                    ),
                  },
                },
              )
            : API.deleteEntintyTranslationsChangesLogAction(
                entity.entity_id,
                selectedAttribute,
                {
                  entityItemId: entityId,
                },
              ),
        ]);

        this.changedValues.splice(storedIndex, 1);
        // this.fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteLog(entityId) {
    try {
      const {
        entitesTranslations,
        selectedEntity,
        selectedAttribute,
      } = this.state;

      const entity = entitesTranslations.find(
        f => f.entity_id === selectedEntity,
      );

      await API.deleteEntintyTranslationsChangesLogAction(
        entity.entity_id,
        selectedAttribute,
        {
          entityItemId: entityId,
        },
      );

      this.fetchData();
    } catch (e) {
      console.log(e);
    }
  }

  async saveAllEntities() {
    try {
      const {
        entitesTranslations,
        selectedEntity,
        selectedAttribute,
      } = this.state;

      if (this.changedValues.length) {
        const entity = entitesTranslations.find(
          f => f.entity_id === selectedEntity,
        );
        await API.putEntityTranslationsAction(
          entity.entity_id,
          selectedAttribute,
          { data: { translations: this.changedValues } },
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleAvailabilityChange(value) {
    this.setState(
      {
        availabilityCheckbox: !value,
      },
      () => this.fetchData(),
    );
  }

  handleDataChange(value) {
    const { translationsFilters } = this.state;

    translationsFilters.find(
      p => p.channel === value.channel,
    ).value = !value.value;

    this.headers.find(h => h._id === value.channel).otherProps.show =
      value.value;

    this.setState({
      translationsFilters,
    });
  }

  handleValueChange(entityId, lang, prevValue, value) {
    try {
      if (prevValue === value) {
        return;
      }
      const storedIndex = this.changedValues.findIndex(
        ch => ch._id === entityId,
      );

      if (storedIndex >= 0) {
        const langIndex = this.changedValues[storedIndex].value.findIndex(
          l => l.lang === lang,
        );
        if (langIndex >= 0) {
          this.changedValues[storedIndex].value[langIndex] = {
            lang,
            value,
          };
        } else {
          this.changedValues[storedIndex].value.push({
            lang,
            value,
          });
        }
      } else {
        this.changedValues.push({
          _id: entityId,
          value: [
            {
              lang,
              value,
            },
          ],
        });
      }

      this.saveRowData(entityId);
    } catch (e) {
      console.log(e);
    }
  }

  async fetchEntities() {
    try {
      const res = await API.getEntitiesForTranslations();

      this.setState({
        entitesTranslations: res.items,
        entitesCategory: res.items.map(item => ({
          value: item.entity_id,
          label: item.entity_name,
        })),
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleEntityCategoryChange(value) {
    const { entitesTranslations, selectedAttribute } = this.state;
    try {
      if (value !== '-') {
        this.setState(
          {
            selectedEntity: value,
          },
          () => {
            this.fetchEntityAttribute();
            if (
              entitesTranslations
                .find(f => f.entity_id === value)
                .trans_attributes.find(a => a._id === selectedAttribute)
            ) {
              this.fetchData({ offset: 0 });
            }
          },
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleEntityAttributeChange(value) {
    try {
      if (value !== '-') {
        this.setState(
          {
            selectedAttribute: value,
          },
          () => this.fetchData({ offset: 0 }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchEntityAttribute() {
    const { entitesTranslations, selectedEntity } = this.state;
    try {
      this.setState({
        entitesAttributesCategory: entitesTranslations.find(
          f => f.entity_id === selectedEntity,
        ).trans_attributes,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleFilterChange(value) {
    this.setState(
      {
        onlyATEmpty: value,
      },
      () =>
        this.fetchData({
          offset: 0,
        }),
    );
  }

  setSorter(sorter) {}

  renderControlBar() {
    const { history } = this.props;
    const {
      selectedEntity,
      selectedAttribute,
      entitesCategory,
      entitesAttributesCategory,
      translationsFilters,
      availabilityCheckbox,
      onlyATEmpty,
    } = this.state;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Preklady entit a atribútov')}>
          {selectedEntity === 'catalog-product' && (
            <CheckBoxBarWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  checked={availabilityCheckbox}
                  onChange={() =>
                    this.handleAvailabilityChange(availabilityCheckbox)
                  }
                  // disabled={selectedEntity !== 'catalog-product'}
                />
                <Label>{__('Iba produkty eshopu')}</Label>
              </CheckBoxWrapper>
            </CheckBoxBarWrapper>
          )}

          {selectedEntity === 'catalog-product' && (
            <CheckBoxBarWrapper>
              <CheckBoxWrapper>
                <Checkbox
                  checked={onlyATEmpty}
                  onChange={e => this.handleFilterChange(e.target.checked)}
                  // disabled={selectedEntity !== 'catalog-product'}
                />
                <Label>{__('Iba nevyplnené hodnoty pre AT')}</Label>
              </CheckBoxWrapper>
            </CheckBoxBarWrapper>
          )}

          <CategoryTitle>{__('Entita')}:</CategoryTitle>
          <SelectWrapper>
            <Select
              size="s"
              value={selectedEntity}
              onChange={e => this.handleEntityCategoryChange(e.target.value)}
            >
              <option>-</option>
              {entitesCategory.map(l => {
                return (
                  <option selected={l.value === selectedEntity} value={l.value}>
                    {l.label}
                  </option>
                );
              })}
            </Select>
          </SelectWrapper>
          <CategoryTitle>{__('Atribút')}:</CategoryTitle>
          <SelectWrapper>
            <Select
              size="s"
              value={selectedAttribute}
              onChange={e => this.handleEntityAttributeChange(e.target.value)}
            >
              <option>-</option>
              {entitesAttributesCategory.map(l => {
                return <option value={l._id}>{l.name}</option>;
              })}
            </Select>
          </SelectWrapper>
        </ControllBar>
        <GreyBox title={__('Zobrazenie verzie prekladu')}>
          <Row>
            <InputWrapper>
              <CheckBoxesWrapper>
                {translationsFilters.map(c => {
                  return (
                    <CheckBoxWrapper>
                      <Checkbox
                        checked={c.value}
                        onChange={() => this.handleDataChange(c)}
                      />
                      <Label>{c.channel}</Label>
                    </CheckBoxWrapper>
                  );
                })}
              </CheckBoxesWrapper>
            </InputWrapper>
          </Row>
        </GreyBox>
      </React.Fragment>
    );
  }

  render() {
    // const { showSyncAlert, productsSyncLoading } = this.state;

    return (
      <React.Fragment>
        {/* <PopUpWrapper
          display={showSyncAlert}
          small
          message={__('Zmeny nie sú uložené')}
          onClose={() =>
            this.setState({
              showSyncAlert: productsSyncLoading,
            })
          }
          onEscClose={() =>
            this.setState({ showSyncAlert: productsSyncLoading })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj chcete opustiť túto sekciu? Neuložene zmeny budu stratené. Uložiť všetko?',
            )}
            onDismiss={() => {
              this.changedValues = [];
              this.fetchData();
              this.setState({
                showSyncAlert: false,
              });
            }}
            reverse
            onConfirm={() => {
              this.saveAllEntities();
              this.setState({
                showSyncAlert: false,
              });
              this.changedValues = [];
            }}
            loading={productsSyncLoading}
          />
        </PopUpWrapper> */}
        {super.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TranslationsEntities);
