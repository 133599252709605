import React from 'react';
import Select from 'oxyrion-ui/lib/Select';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/filters';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { __, renderLink, formatDate } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';

const publicationChannels = [
  {
    channel: 'Všetky',
  },
  {
    channel: 'CRM',
  },
  {
    channel: 'B2B',
  },
  {
    channel: 'xFarby',
  },
];

const CategoryTitle = styled.div`
  margin-right: ${rem(12)};
`;

const SelectWrapper = styled.div`
  margin-right: ${rem(40)};
  width: ${rem(150)};
`;

class FilterLists extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Filtre');
    this.showBulkOperations = false;
    this.accessors = [
      'technical_name',
      'name',
      'type',
      'product_value',
      'order_number',
      'created_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
      selectedPublicationChannel: 'xFarby',
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/filters/${id}`);
  }

  handlePublicationChange(e) {
    this.setState(
      {
        selectedPublicationChannel: e,
      },
      () => this.fetchData(),
    );
  }

  async fetchData(parameters = {}) {
    try {
      const {
        sorter,
        active,
        selectedCustomer,
        lastSearch,
        selectedPublicationChannel,
      } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedPublicationChannel !== 'Všetky') {
        params.publicationChannel = selectedPublicationChannel;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await AdminAPI.getFiltersAction(params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        technical_name: _shapeData(
          renderLink(
            `./filters/${item._id}`,
            `${item.technical_name || item.name}`,
          ),
          () => this.handleSystemOnClick(item._id),
        ),
        name: _shapeData(item.name),
        type: _shapeData(item.type),
        product_value: _shapeData(
          item.product_value ? item.product_value.name : '',
        ),
        order_number: _shapeData(item.order_number),
        created_date: _shapeData(formatDate(item.created_date)),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { selectedPublicationChannel } = this.state;

    return (
      <ControllBar history={history} name={__('Zoznam filtrov')}>
        <CategoryTitle>{__('Publikované v')}:</CategoryTitle>
        <SelectWrapper>
          <Select
            size="s"
            value={selectedPublicationChannel}
            onChange={e => this.handlePublicationChange(e.target.value)}
          >
            {publicationChannels.map(l => {
              return (
                <option
                  selected={l.channel === selectedPublicationChannel}
                  value={l.channel}
                >
                  {l.channel}
                </option>
              );
            })}
          </Select>
        </SelectWrapper>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať filter')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FilterLists);
