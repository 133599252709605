import { __ } from '../Utils';

export default [
  {
    name: __('Meno'),
    clickable: false,
    // otherProps: { minWidth: 300 },
  },
  {
    name: __('Priezvisko'),
    clickable: false,
  },
  {
    name: __('Rola'),
    clickable: false,
  },
  {
    name: __('Email'),
    clickable: false,
  },
  {
    name: __('Telefónne číslo'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
  },
  {
    name: __('Dátum aktualizácie'),
    clickable: false,
  },
];
