import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { rem } from 'polished';
import styled from 'styled-components';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {
  Button,
  Checkbox,
  Label,
  Icon,
  Input,
  Select,
  FileInput,
  Message,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __, normalizeCodelist } from '../../Utils';
import {
  ButtonRelativeWrapper,
  DHeader,
  P,
  AnimatedFormMessageWrapper,
  ClickableIconWrapper,
} from '../../Components/ReusableComponents';
import TableV3 from '../../Components/TableV3';
import languages from '../../ContentConfig/languages';
import DatePicker from '../../Components/DatePicker';
import AdminAPI from '../../AdminAPI';
import MultimediaImagesWidget from '../../Components/MultimediaImagesWidget';
import SearchableSelect from '../../Components/SearchableSelect';
import SearchAndOrderItems from '../../Components/SearchAndOrderItems';

const StyledReactMultiSelectCheckboxes = styled(ReactMultiSelectCheckboxes)`
  width: ${rem(130)};
`;

const SecurityLabel = styled.div`
  /* display: flex; */
  .code {
    font-weight: bold;
  }
  .description {
    font-size: ${rem(10)};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${rem(300)};
  flex-direction: column;
`;

const AddNewRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const PartWrapper = styled.div`
  width: ${rem(140)};
  margin-right: ${rem(30)};
`;

const NoLabel = styled.div`
  height: ${rem(34)};
`;

const PlusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Save = styled(Button)``;

const MultimediaImagesWrapper = styled.div``;

const SecurityAlertsWrapper = styled.div``;

const ImagesHeader = styled.div`
  height: ${rem(50)};
  padding-left: ${rem(20)};
  justify-content: start;
  display: flex;
  align-items: center;
  background: #eee;
  margin-top: ${rem(20)};
  font-weight: 300;
  font-size: ${rem(16)};
`;

const StyledFileInput = styled(FileInput)`
  width: ${rem(300)};
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.noMargin ? rem(0) : rem(5))};
`;

const StyledLabel = styled(Label)`
  width: ${rem(150)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${rem(5)};
  font-weight: 500;
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(400)};
`;

const SearchableAndOrderSelectWrapper = styled.div`
  width: ${rem(1000)};
`;

const PictogramItem = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
`;

const PictogramName = styled.div`
  margin-left: ${rem(10)};
`;

const acceptedMimeTypes = ['application/pdf'];

class Documentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      fileTypes: [],
      securityAlerts: [],
      securityAlertsSelected: [],
      pictogramsSelected: [],
      signalWordSelected: '',
      photoIndex: 0,
      type: 'data_sheet',
      published: [],
      showPhotogallery: false,
      altLang: 'SK',
      editMode: false,
      file: { fileValue: '', file: null, fileError: false },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  getBase64(f) {
    const reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = () => {
      const newFile = {
        name: f.name,
        mimeType: f.type,
        file_updated_date: f.lastModifiedDate,
        base64: reader.result.replace('data:application/pdf;base64,', ''),
      };

      this.setState({
        file: newFile,
      });
    };
    reader.onerror = error => {
      console.log('Error: ', error);
    };
  }

  createColumns() {
    const { handleFileDateChange } = this.props;
    return [
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'type',
        Header: () => <DHeader>{__('Typ')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'file_updated_date',
        Header: () => <DHeader>{__('Dátum poslednej aktualizácie')}</DHeader>,
        Cell: props => {
          return (
            <DatePicker
              placeholderText={__('nezadaná')}
              dateFormat="DD.MM.YYYY"
              selected={props.value && moment(props.value)}
              onChange={e => {
                handleFileDateChange(e, props.index);
              }}
            />
          );
        },
      },
      {
        accessor: 'link',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.downloadPDF(props.row._index)}
            >
              <Icon name="pdf" size="l" />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
      {
        accessor: 'SK',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.original.langs.indexOf('SK') > -1}
                onChange={() => this.updatePublished('SK', props.row._index)}
              />
              <Label>{__('SK')}</Label>
            </P>
          );
        },
        width: 70,
      },
      {
        accessor: 'CZ',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.original.langs.indexOf('CZ') > -1}
                onChange={() => this.updatePublished('CZ', props.row._index)}
              />
              <Label>{__('CZ')}</Label>
            </P>
          );
        },
        width: 70,
      },
      {
        accessor: 'EN',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.original.langs.indexOf('EN') > -1}
                onChange={() => this.updatePublished('EN', props.row._index)}
              />
              <Label>{__('EN')}</Label>
            </P>
          );
        },
        width: 70,
      },
      {
        accessor: 'AT',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.original.langs.indexOf('AT') > -1}
                onChange={() => this.updatePublished('AT', props.row._index)}
              />
              <Label>{__('AT')}</Label>
            </P>
          );
        },
        width: 70,
      },
      {
        accessor: 'HU',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.original.langs.indexOf('HU') > -1}
                onChange={() => this.updatePublished('HU', props.row._index)}
              />
              <Label>{__('HU')}</Label>
            </P>
          );
        },
        width: 70,
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.editFile(props.row._index)}
            >
              <Icon name="edit" size="l" />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.deleteFile(props.row._index)}
            >
              <Icon name="close" size="l" />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
    ];
  }

  updatePublished(lang, index) {
    const { editPublicationFile } = this.props;

    editPublicationFile(index, lang);
  }

  downloadPDF(index) {
    const { downloadPDF } = this.props;

    downloadPDF(index);
  }

  deleteFile(index) {
    const { removeFile } = this.props;
    removeFile(index);
  }

  editFile(index) {
    const { files } = this.props;
    const file = files[index];
    this.setState({
      name: file.name,
      type: file.type,
      file: file.file,
      editedId: file._id,
      published: file.langs.map(l => ({
        value: l,
        label: l,
      })),
      editMode: true,
    });
  }

  removeFile() {
    this.setState({
      file: { fileValue: '', file: null, fileError: false },
    });
  }

  handleNewFileChange(e, field) {
    let {
      name,
      type,
      published,
      securityAlertsSelected,
      pictogramsSelected,
      signalWordSelected,
    } = this.state;
    const { handleSecurityAlertsChange } = this.props;
    if (field === 'name') {
      name = e.target.value;
    } else if (field === 'type') {
      type = e.target.value;
    } else if (field === 'lang') {
      published = e;
    } else if (field === 'file') {
      if (e.length) {
        const f = e[0];
        if (!acceptedMimeTypes.includes(f.type)) {
          this.setState({ error: __('Zlý formát súboru.') });
        } else {
          this.getBase64(f);
        }
      }
    } else if (field === 'security-alerts') {
      securityAlertsSelected = e;
    } else if (field === 'pictograms') {
      pictogramsSelected = e;
    } else if (field === 'signal-word') {
      signalWordSelected = e;
    }

    if (['security-alerts', 'pictograms', 'signal-word'].includes(field)) {
      handleSecurityAlertsChange({
        sentences:
          securityAlertsSelected && securityAlertsSelected.map(v => v.value),
        pictograms: pictogramsSelected && pictogramsSelected.map(v => v.value),
        signal_word: signalWordSelected && signalWordSelected.value,
      });
    }

    this.setState({
      name,
      type,
      published,
      securityAlertsSelected,
      pictogramsSelected,
      signalWordSelected,
      error: undefined,
    });
  }

  async fetchData() {
    const { selectedSecurityAlerts } = this.props;
    try {
      const [fileTypes, securityAlerts] = await Promise.all([
        AdminAPI.getCodelistsDocumentationAction(),
        this.fetchSecurityAlerts('', 1000),
      ]);

      const normalizedFilesTypes = normalizeCodelist(
        fileTypes.codelist,
        this.props.firm,
      );

      let securityAlertsSelected = [];
      let pictogramsToSelect = [];
      let signalWordToSelect = '';
      if (selectedSecurityAlerts) {
        securityAlertsSelected =
          selectedSecurityAlerts.sentences &&
          selectedSecurityAlerts.sentences.map(m => {
            const idx = securityAlerts.findIndex(f => f.value === m);
            if (idx !== -1) {
              return securityAlerts[idx];
            }
            return false;
          });

        pictogramsToSelect = this.fetchPictograms().filter(
          f =>
            selectedSecurityAlerts.pictograms &&
            selectedSecurityAlerts.pictograms.includes(f.value),
        );

        signalWordToSelect = this.fetchSignalWords().filter(
          f => f.value === selectedSecurityAlerts.signal_word,
        );
      }

      this.setState({
        fileTypes: normalizedFilesTypes,
        securityAlertsSelected,
        pictogramsSelected: pictogramsToSelect,
        signalWordSelected: signalWordToSelect,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchSecurityAlerts(query, limit = 1000) {
    try {
      return AdminAPI.getSecurityAlertsActions(this.props.firm, {
        limit,
        offset: 0,
        q: query,
      }).then(res => {
        return res.items.map(item => ({
          value: item._id,
          raw: item,
          label: (
            <SecurityLabel>
              <div className="code">{item.code}</div>
              <div className="description">{item.name}</div>
            </SecurityLabel>
          ),
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchPictograms() {
    return [
      {
        value: 'compressed_gas',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/compressed-gas.svg"
              height="42px"
              alt="compressed_gas"
            />
            <PictogramName>{__('Stlačený plyn')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'corrosive',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/corrosive.svg"
              height="42px"
              alt="corrosive"
            />
            <PictogramName>{__('Leptavý')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'environmental-hazard',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/environmental-hazard.svg"
              height="42px"
              alt="environmental-hazard"
            />
            <PictogramName>
              {__('Nebezpečenstvo pre životné prostredie')}
            </PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'explosive',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/explosive.svg"
              height="42px"
              alt="explosive"
            />
            <PictogramName>{__('Výbušný')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'flammable',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/flammable.svg"
              height="42px"
              alt="flammable"
            />
            <PictogramName>{__('Horľavý')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'harmful',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/harmful.svg"
              height="42px"
              alt="harmful"
            />
            <PictogramName>{__('Škodlivý')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'health-hazard',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/health-hazard.svg"
              height="42px"
              alt="health-hazard"
            />
            <PictogramName>{__('Hazard so zdravím')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'oxidizing',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/oxidizing.svg"
              height="42px"
              alt="oxidizing"
            />
            <PictogramName>{__('Oxidačné')}</PictogramName>
          </PictogramItem>
        ),
      },
      {
        value: 'toxic',
        label: (
          <PictogramItem>
            <img
              src="/images/security_alerts/toxic.svg"
              height="42px"
              alt="toxic"
            />
            <PictogramName>{__('Toxický')}</PictogramName>
          </PictogramItem>
        ),
      },
    ];
  }

  fetchSignalWords() {
    return [
      {
        value: 'attention',
        label: __('Pozor'),
      },
      {
        value: 'danger',
        label: __('Nebezpečenstvo'),
      },
      {
        value: 'warning',
        label: __('Varovanie'),
      },
    ];
  }

  resetFile = () => {
    const { fileTypes } = this.state;

    return {
      name: '',
      type: fileTypes[0].value,
      published: [],
      editedId: undefined,
      editMode: false,
      file: { fileValue: '', file: null, fileError: false },
    };
  };

  addFile() {
    const { name, type, published, file, editedId } = this.state;
    const { addNewFile, saveFile } = this.props;

    if (name.length > 1 && file) {
      if (editedId) {
        saveFile(
          {
            name,
            type,
            file,
            translations: [
              {
                lang: 'SK',
              },
            ],
            langs:
              published.length > 0
                ? published.map(pub => {
                    return pub.value;
                  })
                : [],
          },
          editedId,
        );
      } else {
        addNewFile({
          name,
          type,
          file,
          translations: [
            {
              lang: 'SK',
            },
          ],
          langs:
            published.length > 0
              ? published.map(pub => {
                  return pub.value;
                })
              : [],
        });
      }
      this.setState(this.resetFile);
    } else {
      this.setState({
        error: __('Nevyplnená hodnota'),
      });
    }
  }

  async upload(accepted) {
    const { addPhoto } = this.props;
    if (accepted) {
      accepted.map(async i => {
        const reader = new FileReader();
        reader.readAsDataURL(i);
        reader.onload = () => {
          const newFile = {
            name: i.name,
            mimeType: i.type,
            base64: reader.result.replace('data:application/pdf;base64,', ''),
          };
          addPhoto(newFile);
        };
        reader.onerror = error => {
          console.log('Error: ', error);
        };
        return i;
      });
    }
  }

  async remove(item) {
    const { deletePhoto } = this.props;
    deletePhoto(item.fullPath);
  }

  render() {
    const {
      loading,
      files,
      sortFiles,
      saveCard,
      docSaveLoading,
      titleImage,
      changeMainImage,
      multimedia,
    } = this.props;
    const {
      name,
      type,
      published,
      file,
      error,
      fileTypes,
      editMode,
      securityAlertsSelected,
      pictogramsSelected,
      signalWordSelected,
    } = this.state;

    return (
      <Wrapper>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
          <NoLabel />
        </AnimatedFormMessageWrapper>
        <TableV3
          columns={this.createColumns()}
          minWidth={10}
          loading={loading}
          data={files}
          getTdProps={() => {
            return { style: { padding: 0, margin: 'auto' } };
          }}
          getTheadThProps={(state, rowInfo, column) => {
            if (column && column.sortable) {
              return {
                onClick: e => {
                  sortFiles(e.target.innerHTML);
                },
              };
            }
            return {};
          }}
          className="-highlight -striped"
        />
        <AddNewRow>
          <PartWrapper>
            <Label>{__('Názov')}</Label>
            <Input
              placeholder={__('Názov')}
              onChange={e => this.handleNewFileChange(e, 'name')}
              value={name}
              error={error}
            />
          </PartWrapper>
          <PartWrapper>
            <Label>{__('Typ')}</Label>
            <Select
              size="s"
              placeholder={__('Typ')}
              value={type}
              onChange={e => this.handleNewFileChange(e, 'type')}
            >
              {fileTypes.map(item => {
                return (
                  <option selected={item.value === type} value={item.value}>
                    {item.display_name}
                  </option>
                );
              })}
            </Select>
          </PartWrapper>
          <PartWrapper>
            <Label>{__('Jazyky')}</Label>
            <StyledReactMultiSelectCheckboxes
              options={languages}
              value={published}
              placeholderButtonLabel={__('Vyberte')}
              onChange={e => this.handleNewFileChange(e, 'lang')}
            />
          </PartWrapper>
          <PartWrapper style={{ width: 300 }}>
            <Label>{__('Vyberte súbor')}</Label>
            <StyledFileInput
              item={file.name}
              onChange={e => this.handleNewFileChange(e.target.files, 'file')}
              onRemoveFile={() => this.removeFile()}
            />
          </PartWrapper>

          <PartWrapper>
            <NoLabel />
            <PlusWrapper>
              {editMode || docSaveLoading ? (
                <>
                  <div onClick={() => this.addFile()}>
                    <Save small primary loading={docSaveLoading}>
                      {__('Uložiť')}
                    </Save>
                  </div>
                  <div onClick={() => this.setState(this.resetFile())}>
                    <Icon name="close" size="l" />
                  </div>{' '}
                </>
              ) : (
                <div onClick={() => this.addFile()}>
                  <Icon name="plus" size="l" />
                </div>
              )}
            </PlusWrapper>
          </PartWrapper>
        </AddNewRow>

        <SecurityAlertsWrapper>
          <ImagesHeader>{__('Bezpečnostné upozornenia')}</ImagesHeader>

          <InputRow noMargin={0}>
            <StyledLabel>{__('Vety')}</StyledLabel>
            <SearchableAndOrderSelectWrapper>
              <SearchAndOrderItems
                loadOptions={query => {
                  return this.fetchSecurityAlerts(query, 20);
                }}
                onChange={e => this.handleNewFileChange(e, 'security-alerts')}
                initialSelected={securityAlertsSelected}
                placeholder={__('Vyberte vetu')}
                isMulti
              />
            </SearchableAndOrderSelectWrapper>
          </InputRow>

          <InputRow noMargin={0}>
            <StyledLabel>{__('Piktogramy')}</StyledLabel>
            <SearchableSelectWrapper>
              <SearchableSelect
                value={pictogramsSelected}
                isMulti
                loadOptions={() => {
                  return this.fetchPictograms();
                }}
                placeholder={__('Vyberte piktogram')}
                handleOnChange={e => this.handleNewFileChange(e, 'pictograms')}
              />
            </SearchableSelectWrapper>
          </InputRow>

          <InputRow noMargin={0}>
            <StyledLabel>{__('Signálne slovo')}</StyledLabel>
            <SearchableSelectWrapper>
              <SearchableSelect
                value={signalWordSelected}
                loadOptions={() => {
                  return this.fetchSignalWords();
                }}
                placeholder={__('Signálne slovo')}
                handleOnChange={e => this.handleNewFileChange(e, 'signal-word')}
              />
            </SearchableSelectWrapper>
          </InputRow>
        </SecurityAlertsWrapper>

        <MultimediaImagesWrapper>
          <ImagesHeader>{__('Obrázky - fotobanka')}</ImagesHeader>
          <MultimediaImagesWidget
            titleImage={titleImage}
            changeMainImage={id => changeMainImage(id)}
            multimedia={multimedia}
            onMultimediumAdd={m => this.props.addMultimedium(m)}
            removeMultimedium={id => this.props.removeMultimedium(id)}
            firm={this.props.firm}
          />
        </MultimediaImagesWrapper>
        {/* <PhotoGallery
          photos={photos}
          isOpen={showPhotogallery}
          onClose={() => this.setState({ showPhotogallery: false })}
          photoIndex={photoIndex}
          baseUrl={photoBasePath}
        /> */}
        <ButtonRelativeWrapper>
          <Button onClick={() => saveCard()} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </Wrapper>
    );
  }
}

Documentation.propTypes = {
  photos: PropTypes.arrayOf(),
  firm: PropTypes.string,
  titleImage: PropTypes.string,
  altImage: PropTypes.string,
  altText: PropTypes.string,
  changeAltText: PropTypes.string,
  changeMainImage: PropTypes.func,
  changeAltImage: PropTypes.func,
  files: PropTypes.arrayOf({
    name: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string,
    SK: PropTypes.bool,
    CZ: PropTypes.bool,
    EN: PropTypes.bool,
    HU: PropTypes.bool,
  }),
  handleFileDateChange: PropTypes.func.isRequired,
  addNewFile: PropTypes.func.isRequired,
  addPhoto: PropTypes.func.isRequired,
  deletePhoto: PropTypes.func.isRequired,
  editPublicationFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  sortFiles: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func.isRequired,
  saveCard: PropTypes.func.isRequired,
  saveFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  docSaveLoading: PropTypes.bool,
  multimedia: PropTypes.arrayOf({}),
  addMultimedium: PropTypes.func,
  removeMultimedium: PropTypes.func,
  altTextTranslations: PropTypes.arrayOf({
    lang: PropTypes.string,
    value: PropTypes.string,
  }),
  handleSecurityAlertsChange: PropTypes.func,
  selectedSecurityAlerts: PropTypes.object.isRequired,
};

Documentation.defaultProps = {
  files: [],
  altImage: '',
  titleImage: '',
  altText: '',
  altTextTranslations: [],
  loading: false,
  photos: [],
  multimedia: [],
  docSaveLoading: false,
  changeMainImage: () => {},
  changeAltImage: () => {},
  changeAltText: () => {},
  addMultimedium: () => {},
  removeMultimedium: () => {},
  firm: 'SK',
  handleSecurityAlertsChange: () => {},
  selectedSecurityAlerts: {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Documentation);
