import React from 'react';
import TableV3 from '../../Components/TableV3';
import { connect } from '../../Store';
import { __, FORMAT_HUMANE_DATETIME, formatDate } from '../../Utils';
import { DHeader, P } from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import AdminAPI from '../../AdminAPI';
import moment from 'moment';

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: 'SK',
      tableData: [],
      loading: false,
      limit: 100,
      offset: 0,
      total: 0,
      nextOffset: 0,
      sorter: '-created_date',
    };
  }

  async componentWillMount() {
    const { catalogProductId } = this.props;

    if (catalogProductId === 'new') {
      return;
    }

    await this.fetchData();
  }

  async fetchData() {
    const { catalogProductId } = this.props;
    const { offset, limit, activeLanguage, sorter } = this.state;

    this.setState({ loading: true });

    try {
      const params = Object.assign({}, { limit, offset, sorter });

      const responseData = await AdminAPI.getCatalogProductsReviewsAction(
        activeLanguage,
        catalogProductId,
        params,
      );

      this.setState({
        limit: responseData.limit,
        offset: responseData.offset,
        total: responseData.total,
        nextOffset: responseData.next_offset,
        tableData: this.normalizeData(responseData.items),
        loading: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 404:
          this.setState({
            error: __('Recenzie sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  normalizeData(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return value || placeholder;
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        customer_name: _shapeData(item.customer_name),
        rating: _shapeData(`${item.rating} / 5`),
        text: _shapeData(item.text),
        approval: _shapeData(item.approval),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  createColumns() {
    return [
      {
        accessor: 'customer_name',
        Header: () => <DHeader>{__('Meno zákazníka')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 300,
      },
      {
        accessor: 'rating',
        Header: () => <DHeader>{__('Hodnotenie')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'text',
        Header: () => <DHeader>{__('Recenzia')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'approval',
        Header: () => <DHeader>{__('Stav')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'created_date',
        Header: () => <DHeader>{__('Dátum')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 150,
      },
    ];
  }

  changeLanguage(id) {
    this.setState(
      {
        activeLanguage: id,
      },
      () => {
        this.fetchData();
      },
    );

    return true;
  }

  render() {
    const { tableData, activeLanguage, loading } = this.state;

    return (
      <React.Fragment>
        <LanguageSwitch
          onChange={id => {
            return this.changeLanguage(id);
          }}
          activeId={activeLanguage}
        />
        {tableData && tableData.length > 0 && (
          <TableV3
            columns={this.createColumns()}
            minWidth={5}
            loading={loading}
            noDataText={__('Žiadne recenzie')}
            data={tableData}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: () => {},
                };
              }
              return {};
            }}
            className="-highlight -striped"
          />
        )}
      </React.Fragment>
    );
  }
}

Reviews.propTypes = {};

Reviews.defaultProps = {
  firm: 'SK',
  catalogProductId: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Reviews);
