import React from 'react';
import { Route } from 'react-router-dom';
import Discounts from './Discounts';
import Margins from './Margins';

function Settings(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.url}/discounts`} component={Discounts} />
      <Route path={`${match.url}/margins`} component={Margins} />
    </React.Fragment>
  );
}

export default Settings;
