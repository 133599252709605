import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import StocksSettings from '../../../Containers/StocksSettings';

function Stocks(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={StocksSettings} />
    </Switch>
  );
}

Stocks.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default Stocks;
