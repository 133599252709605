import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmailTemplateList from '../../../Containers/EmailTemplateList';
import EmailTemplateDetail from '../../../Containers/EmailTemplateDetail';

function EmailTemplates(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={EmailTemplateDetail} />
      <Route path={`${match.url}`} component={EmailTemplateList} />
    </Switch>
  );
}

EmailTemplates.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default EmailTemplates;
