import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Campaigns from '../../../Containers/Campaigns';
import CampaignDetail from '../../../Containers/CampaignDetail';

function CampaignsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={CampaignDetail} />
      <Route path={`${match.url}`} component={Campaigns} />
    </Switch>
  );
}

CampaignsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CampaignsCard;
