import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PDFTemplateList from '../../../Containers/PDFTemplateList';
import PDFTemplateDetail from '../../../Containers/PDFTemplateDetail';

const PDFTemplates = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={PDFTemplateDetail} />
      <Route path={`${match.url}`} component={PDFTemplateList} />
    </Switch>
  );
};

PDFTemplates.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default PDFTemplates;
