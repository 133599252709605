import React from 'react';
// import cookie from 'react-cookies';
import CatalogProductCard from '../Components/CatalogProductCardExtended';
import { __ } from '../../../Utils';
import { connect } from '../../../Store';
import BaseList from '../BaseList';
import API2 from '../../../API2';

class Catalog extends BaseList {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      title: __('Produktový katalóg'),
      notFoundMessage: __('Ľutujeme produkty sa nenašli '),
      isProduct: true,
      cartItemsIds: [],
      customHeaderWidth: '1200px',
      customFilterHeight: '80%',
      maxFilterWidth: 250,
      cartItems: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const {
      actualPage,
      checkedFilter,
      offset,
      nextOffset,
      total,
      searchText,
      scrollPosition,
    } = this.loadActualData();

    const newSearchText =
      searchText &&
      searchText !== null &&
      searchText !== 'null' &&
      searchText !== 'undefined'
        ? searchText
        : '';

    let newCheckedFilter =
      checkedFilter && checkedFilter !== 'undefined'
        ? JSON.parse(checkedFilter)
        : [];

    if (
      newCheckedFilter.find((n) => n.id === 'type') &&
      newCheckedFilter.find((n) => n.id === 'type').values &&
      (newCheckedFilter.find((n) => n.id === 'type').values.indexOf('položka') >
        -1 ||
        newCheckedFilter
          .find((n) => n.id === 'type')
          .values.indexOf('NATEROVY_SYSTEM') > -1)
    ) {
      // nie velmi pekne riesenie ale odstranit to problem
      newCheckedFilter = [];
    }
    this.setState(
      {
        actualPage:
          actualPage &&
          actualPage !== null &&
          actualPage !== 'null' &&
          actualPage > 0
            ? Number(actualPage)
            : 1,
        offset:
          offset && offset !== null && offset !== 'null' && offset > 0
            ? Number(offset)
            : 0,
        searchText: newSearchText,
        defaultSearchText: newSearchText,
        nextOffset:
          nextOffset &&
          nextOffset !== null &&
          nextOffset !== 'null' &&
          nextOffset > 0
            ? Number(nextOffset)
            : 0,
        total:
          total && total !== null && total !== 'null' && total > 0
            ? Number(total)
            : 0,
        checkedFilter: newCheckedFilter,
      },
      async () => {
        this.fetchFilter(!checkedFilter || checkedFilter.length === 0);
        // await this.fetchCartProducts();
        await this.fetchData({
          offset:
            offset && offset !== null && offset !== 'null' && offset > 0
              ? Number(offset)
              : 0,
          total,
        });
        window.scrollTo({
          top: scrollPosition ? Number(scrollPosition) : 0,
        });
        this.saveActualData(1, 0, null, [], null);
      },
    );
  }

  async fetchData(parameters = {}) {
    const { limit, checkedFilter, searchText } = this.state;

    try {
      const params = parameters;

      if (checkedFilter) {
        params.filters = {};
        const str = JSON.stringify({ filter: checkedFilter });
        params.filters = str;
      }

      if (searchText && searchText.length > 0) {
        params.q = searchText;
      }

      const newData = await API2.getUltraLiteCatalogProductsAction(
        this.props.firm,
        Object.assign(params, { limit }),
      );

      this.fetchFullData(Object.assign(params, { limit }));

      this.setState({
        items: newData.products,
        loading: false,
        variantLoading: true,
        filtersDisabled: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchFullData(params) {
    try {
      const newFullData = await API2.getCatalogProductsAction(
        this.props.firm,
        params,
      );

      this.setState({
        items: newFullData.products,
        variantLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateCartItems(cartItems) {
    this.setState({
      cartItems,
      cartItemsIds: cartItems.map((item) => {
        return item.product_id;
      }),
    });
  }

  openProduct(id) {
    this.props.history.push(`/${this.props.firm}/catalog/${id}`);
  }

  renderItemsList() {
    const { items, cartItems, cartItemsIds, variantLoading } = this.state;
    const { carts } = this.props;

    return (
      // <ListOfProducts>
      items.map((p) => {
        return (
          <CatalogProductCard
            role={this.props.user.role}
            history={this.props.history}
            settings={this.props.settings}
            key={p._id}
            productLink={`${process.env.REACT_APP_CRM_DOMAIN}/${
              this.props.firm
            }/catalog/${p._id}`}
            onProductClick={() => this.openProduct(p._id)}
            updateCartItems={(i) => this.updateCartItems(i)}
            firm={this.props.user.firm}
            data={p}
            cartItems={cartItems}
            cartItemsIds={cartItemsIds}
            carts={carts}
            variantLoading={variantLoading}
            addProduct={(productToAdd) => this.props.addProduct(productToAdd)}
            insertVariants={(d) => this.props.insertVariants(d)}
            multiple={this.props.multiple}
          />
        );
      })
      // </ListOfProducts>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Catalog);
