import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../Utils';
import DatePicker from '../DatePicker';

const StyledDatePicker = styled(DatePicker)``;

const StyledTimePicker = styled(DatePicker)`
  max-width: calc(100% - 40px);
  margin-left: ${rem(40)};
`;

const Wrapper = styled.div`
  display: flex;
  width: 95%;
  max-width: ${props => rem(props.maxWidth)};
`;

class DateAndTimerPicker extends React.Component {
  render() {
    return (
      <Wrapper maxWidth={this.props.maxWidth}>
        <StyledDatePicker
          placeholderText={__(this.props.datePlaceHolder)}
          dateFormat="dd DD.MM.YYYY"
          error={this.props.error}
          disabled={this.props.disabled}
          selected={this.props.value}
          minDate={this.props.minDate}
          onChange={e => {
            this.props.onChange(e);
          }}
        />

        <StyledTimePicker
          placeholderText={__(this.props.timePlaceHolder)}
          disabled={this.props.disabled}
          error={this.props.error}
          showTimeSelect
          minTime={this.props.minTime}
          maxTime={this.props.maxTime}
          showTimeSelectOnly
          timeIntervals={this.props.timeIntervals}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          timeCaption="Time"
          selected={this.props.value}
          onChange={e => {
            this.props.onChange(e);
          }}
        />
      </Wrapper>
    );
  }
}

DateAndTimerPicker.propTypes = {
  timePlaceHolder: PropTypes.string,
  datePlaceHolder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  minDate: PropTypes.shape({}),
  maxWidth: PropTypes.number,
  timeIntervals: PropTypes.number,
};

DateAndTimerPicker.defaultProps = {
  timePlaceHolder: '',
  datePlaceHolder: '',
  error: false,
  disabled: false,
  value: undefined,
  minDate: null,
  maxWidth: 480,
  timeIntervals: 30,
};

export default DateAndTimerPicker;
