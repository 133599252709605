import { __ } from '../Utils';

export default [
  {
    value: 'SK',
    label: __('SK'),
  },
  {
    value: 'CZ',
    label: __('CZ'),
  },
  {
    value: 'EN',
    label: __('EN'),
  },
  {
    value: 'AT',
    label: __('AT'),
  },
];
