import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label, Checkbox } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(10)};
  /* max-width: ${rem(700)}; */
  background: #f7f7f7;
  border-radius: ${rem(4)};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${rem(8)};
`;

const ContentWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddMore = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
`;

class SettingsBox extends Component {
  render() {
    const {
      selectedAll,
      onSelectAll,
      allLabel,
      children,
      title,
      addMoreText,
      addMore,
      addDisabled,
    } = this.props;

    return (
      <Wrapper>
        <ContentWrapper>
          <Label>{title}</Label>
          {children}
        </ContentWrapper>
        <Row>
          {!addDisabled ? (
            <AddMore onClick={() => addMore()}>{`+ ${addMoreText}`}</AddMore>
          ) : (
            <div />
          )}
          {onSelectAll ? (
            <CheckboxWrapper>
              <Checkbox onChange={() => onSelectAll()} checked={selectedAll} />
              <Label onClick={() => onSelectAll()}>{allLabel}</Label>
            </CheckboxWrapper>
          ) : (
            <div />
          )}
        </Row>
      </Wrapper>
    );
  }
}

SettingsBox.propTypes = {
  children: PropTypes.element,
  onSelectAll: PropTypes.func,
  selectedAll: PropTypes.bool,
  allLabel: PropTypes.string,
  title: PropTypes.string,
  addMoreText: PropTypes.string,
  addMore: PropTypes.func,
  addDisabled: PropTypes.bool,
};

SettingsBox.defaultProps = {
  children: <div />,
  onSelectAll: undefined,
  addMore: undefined,
  selectedAll: false,
  addDisabled: false,
  title: '',
  allLabel: __('Všetko'),
  addMoreText: __('Pridať'),
};

export default SettingsBox;
