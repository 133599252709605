import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EshopShopsList from '../../../Containers/EshopShopsList';
import EshopShopDetail from '../../../Containers/EshopShopDetail';

function EshopShopsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={EshopShopDetail} />
      <Route path={`${match.url}`} component={EshopShopsList} />
    </Switch>
  );
}

EshopShopsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default EshopShopsCard;
