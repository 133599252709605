import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import BrandPortfolioList from '../../../Containers/EshopBrandPortfolio/BrandPortfolioList';
import BrandPortfolioDetail from '../../../Containers/EshopBrandPortfolio/BrandPortfolioDetail';

function BrandPortfolio(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={BrandPortfolioDetail} />
      <Route path={`${match.url}`} component={BrandPortfolioList} />
    </Switch>
  );
}

BrandPortfolio.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default BrandPortfolio;
