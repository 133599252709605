import { __ } from '../Utils';

export default [
  {
    name: __('Id pokladne'),
    clickable: false,
  },
  {
    name: __('Názov'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
  {
    name: __('Predajňa'),
    clickable: false,
  },
];
