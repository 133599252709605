import React, { Component } from 'react';
import styled from 'styled-components';
import validator from 'validator';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { Input, Icon } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  /* max-width: ${rem(600)}; */
  width: 100%;
`;

const AddedEmailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${rem(2)};
  max-width: ${rem(500)};
`;
const Email = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(24)};
  color: ${({ theme }) => theme.color.primary};
  margin-right: ${rem(4)};
  font-size: ${rem(12)};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${rem(4)};
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddWrapper = styled.div`
  margin-left: ${rem(32)};
  cursor: pointer;
  height: ${rem(40)};
  min-width: ${rem(40)};
  width: ${rem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.primary};
  border-radius: ${rem(4)};
`;

const StyledInput = styled(Input)`
  width: ${rem(550)};
`;

const Error = styled.div`
  font-size: 10px;
  color: #b30000;
  margin: 2px;
`;

class EmailsSelector extends Component {
  state = {
    emailToAdd: '',
  };

  keyPress(e) {
    const emailToAdd = e.target.value;

    if (e.keyCode === 13 && validator.isEmail(emailToAdd)) {
      this.props.onAdd(emailToAdd);
      this.setState({
        emailToAdd: '',
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    const { defaultEmails, emails, placeholder, onAdd, onDelete } = this.props;
    const { emailToAdd, error } = this.state;

    return (
      <Wrapper>
        <InputWrapper>
          <StyledInput
            placeholder={placeholder}
            type="email"
            value={emailToAdd}
            error={error}
            onKeyDown={e => this.keyPress(e)}
            onChange={e =>
              this.setState({
                emailToAdd: e.target.value,
                error: false,
              })
            }
          />

          <AddWrapper
            onClick={() => {
              if (validator.isEmail(emailToAdd)) {
                onAdd(emailToAdd);
                this.setState({
                  emailToAdd: '',
                });
              } else {
                this.setState({
                  error: true,
                });
              }
            }}
          >
            <Icon name="plus" color="white" />
          </AddWrapper>
        </InputWrapper>
        {error && <Error>{__('Email nie je validný')}</Error>}
        <AddedEmailsWrapper>
          {defaultEmails.map(d => (
            <Email>{d},</Email>
          ))}
          {emails.map((e, index) => (
            <Email>
              {e}
              <IconWrapper onClick={() => onDelete(e)}>
                <Icon size="s" name="close" color={'#ee6500'} />
              </IconWrapper>
              {index < emails.length - 1 ? ',' : ''}
            </Email>
          ))}
        </AddedEmailsWrapper>
      </Wrapper>
    );
  }
}

EmailsSelector.propTypes = {
  defaultEmails: PropTypes.arrayOf(PropTypes.string),
  emails: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  placeholder: PropTypes.string,
};

EmailsSelector.defaultProps = {
  defaultEmails: [],
  emails: [],
  onAdd: () => {},
  onDelete: () => {},
  placeholder: __('Ak potrebujete, zadajte ďalší email pre notifikáciu'),
};

export default EmailsSelector;
