import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { rem } from 'polished';
import Img from 'react-image';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Textarea,
  Checkbox,
} from 'oxyrion-ui/lib';
import inspirationsObjectsPublication from '../../ContentConfig/inspirationsObjectsPublication';
import { connect } from '../../Store';
import { getIdFromProps, __, getYears, formatBytes } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapperLeft,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import SearchableSelect from '../../Components/SearchableSelect';
import MetaDescriptionTextArea from '../../Components/MetaDescriptionTextArea';
import { LANGS } from '../../ContentConfig/languagesArray';

const acceptedMimeTypesViewImage = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/inspirations-objects/{inspirationsObjectId}/images`;

const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

const years = getYears(2000, moment().year());

const initData = () => {
  return {
    path: '',
    name: '',
    description: '',
    meta_description: '',
    meta_title: '',
  };
};
class InspirationsObjectDetail extends React.Component {
  constructor(props) {
    const translations = {};
    LANGS.forEach(lang => {
      translations[lang] = initData();
    });

    const publication = {};
    LANGS.forEach(lang => {
      publication[lang] = [];
    });

    super(props);
    this.state = {
      loading: false,
      success: false,
      activeLang: 'SK',
      existingInspirations: [],
      data: {
        path: '',
        object: null,
        icon_name: null,
        translations,
        publication,
      },
    };

    this.handler = ev => {
      ev.preventDefault();
      return (ev.returnValue = __(
        'Prebieha nahrávanie súboru, naozaj chcete odísť?',
      ));
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getUploadParams = ({ file }) => {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);

    return {
      url: fileUploadURL.replace('{inspirationsObjectId}', this.state.data._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };

  async loadData(invokeLoader) {
    const {
      inspirationsObjects,
    } = await AdminAPI.getInspirationsObjectsAction();

    if (invokeLoader) {
      this.setState({
        loading: true,
        existingInspirations: inspirationsObjects,
      });
    }
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      this.setState({
        loading: true,
        existingInspirations: inspirationsObjects,
      });
      await this.fetchInspirationObjectData(id);
    }
  }

  searchYear(query) {
    return years
      .filter(u => u.toString().includes(query))
      .map(u => ({
        value: u,
        label: u,
      }));
  }

  async fetchInspirationObjectData(id) {
    try {
      const data = await AdminAPI.getInspirationsObjectAction(id);

      this.setState({
        data,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Miestnosť/Objekt sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteInspirationObject() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteInspirationObjectAction(getIdFromProps(this.props));
      history.push(`/${firm}/inspirations-objects`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  async saveData(checkFile = false, showSucces = true) {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (!data.object || !data.icon_name) {
      this.setState({
        error: __('Je potrebné vyplniť objekt a ikonu'),
      });
      return;
    }

    try {
      const params = {};
      params.body = {
        translations: data.translations,
        images: data.images || [],
        object: data.object || [],
        icon_name: data.icon_name.value || null,
        publication: data.publication,
      };

      if (getIdFromProps(this.props) === 'new') {
        const result = await AdminAPI.postInspirationsObjectAction(params);
        this.props.history.push(
          `/${this.props.firm}/inspirations-objects/${result._id}`,
        );
        this.setState({
          data: result,
          success: showSucces && __('Dáta uložené'),
          saveLoading: false,
        });
      } else {
        const result = await AdminAPI.putInspirationsObjectAction(
          getIdFromProps(this.props),
          params,
        );
        this.setState({
          data: result,
          success: showSucces && __('Dáta uložené'),
          saveLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  async handleChange(field, value) {
    const { data } = this.state;

    data[field] = value;

    this.setState({ data, error: false });
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    const newTranslations = {
      ...data.translations,
      [lang]: {
        ...data.translations[lang],
        [field]: value,
      },
    };

    const newData = {
      ...data,
      translations: newTranslations,
    };

    this.setState({
      data: newData,
      error: false,
    });
  }

  handlePublicationChange(value) {
    const { data, activeLang } = this.state;

    if (!data.publication) {
      data.publication = {};
    }

    if (!data.publication[activeLang]) {
      data.publication[activeLang] = [];
    }

    if (data.publication[activeLang].includes(value)) {
      data.publication[activeLang].splice(
        data.publication[activeLang].indexOf(value),
        1,
      );
    } else {
      data.publication[activeLang].push(value);
    }

    this.setState({
      data,
      error: false,
    });
  }

  handleTitleImageChange(fullPath) {
    const { data } = this.state;

    data.images = data.images.map(i => {
      if (i.fullPath === fullPath && i.firm === this.props.firm) {
        return Object.assign(i, { title_image: true });
      }
      return Object.assign(i, { title_image: false });
    });

    this.setState({
      data,
    });
  }
  handleShowImageChange(fullPath) {
    const { data } = this.state;

    data.images = data.images.map(i => {
      if (i.fullPath === fullPath) {
        return Object.assign(i, {
          hide_in_product_gallery: !i.hide_in_product_gallery,
        });
      }
      return Object.assign(i, {
        hide_in_product_gallery: i.hide_in_product_gallery,
      });
    });

    this.setState({
      data,
    });
  }

  async deleteImage(fullPath) {
    const { data } = this.state;

    try {
      const result = await AdminAPI.deleteInspirationsObjectImageAction(
        data._id,
        encodeURIComponent(fullPath),
      );

      this.setState({
        data: result,
      });
    } catch (e) {
      this.setState({
        error: __('Obrázok sa nepodarilo odstrániť'),
      });
    }
  }

  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    if (status === 'preparing') {
      this.setState({
        uploading: true,
      });

      if (getIdFromProps(this.props) === 'new') {
        cancel();
        await this.saveData();
        restart();
      }

      window.addEventListener('beforeunload', this.handler);
    }

    if (status === 'done') {
      const data = await AdminAPI.getInspirationsObjectAction(
        this.state.data._id,
      );

      window.removeEventListener('beforeunload', this.handler);
      this.setState(
        {
          data,
          uploading: false,
        },
        () => remove(),
      );
    }
  };

  fetchIcons = async () => {
    try {
      return await AdminAPI.getIconsAction().then(res => {
        return res.icons.map(item => ({
          value: item.icon_name,
          label: item.icon_name,
        }));
      });
    } catch (e) {
      return [];
    }
  };

  fetchCodelist = async query => {
    const { existingInspirations } = this.state;

    try {
      const res = await AdminAPI.getCodelist('objekt_priestor').then(res => {
        return res.codelist
          .filter(c => c.label.toUpperCase().includes(query.toUpperCase()))
          .map(item => ({
            value: item.value || item._id,
            label: item.label,
            _id: item._id,
          }));
      });

      const filtered = res.filter(
        ({ _id }) =>
          !existingInspirations.find(({ object }) => object._id === _id),
      );

      return filtered;
    } catch (e) {
      return [];
    }
  };

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Miestnosť/Objekt')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      saveLoading,
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
      activeLang,
      uploading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť Objekt/Miestnosť?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteInspirationObject()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row style={{ height: rem(762) }}>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Objekt/Priestor *')}</StyledLabel>
                  <SearchableSelect
                    value={[data.object]}
                    loadOptions={query => this.fetchCodelist(query)}
                    isClearable={false}
                    placeholder={__('Objekt/Priestor')}
                    handleOnChange={e => this.handleChange('object', e)}
                    disabled={this.state.data._id}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Ikona *')}</StyledLabel>
                  <SearchableSelect
                    value={data.icon_name}
                    loadOptions={() => this.fetchIcons()}
                    placeholder={__('Ikona')}
                    handleOnChange={e => this.handleChange('icon_name', e)}
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
            <Space />
          </Row>

          <div>
            <Row>
              <LanguageSwitch
                disabled={uploading}
                onChange={lang => {
                  this.setState({
                    activeLang: lang,
                  });
                  return true;
                }}
                activeId={activeLang}
              />
            </Row>

            <Row>
              <SettingsBox addDisabled>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{`${__(
                      'Publikácia',
                    )} - ${activeLang}`}</StyledLabel>
                    <Row style={{ margin: 0 }}>
                      {inspirationsObjectsPublication.map(c => (
                        <CheckboxWrapper>
                          <Checkbox
                            onChange={() => this.handlePublicationChange(c)}
                            checked={
                              data.publication && data.publication[activeLang]
                                ? data.publication[activeLang].includes(c)
                                : false
                            }
                          />
                          <ChannelName>{c}</ChannelName>
                          <Space />
                        </CheckboxWrapper>
                      ))}
                    </Row>
                  </InputWrapper>
                </Row>
                <Space />
                <Space />
                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Cesta')}</StyledLabel>
                    <StyledInput
                      style={{ width: rem(330) }}
                      value={data.translations[activeLang].path}
                      placeholder={__('Cesta')}
                      onChange={e =>
                        this.handleLocalChange(
                          'path',
                          activeLang,
                          e.target.value,
                        )
                      }
                    />
                  </InputWrapper>
                </Row>
                <Row>
                  <InputWrapper style={{ width: rem(350) }}>
                    <StyledLabel>{__('Názov v lokálnom jazyku')}</StyledLabel>
                    <StyledInput
                      style={{
                        width: rem(330),
                      }}
                      value={data.translations[activeLang].name}
                      placeholder={__('Názov')}
                      onChange={e =>
                        this.handleLocalChange(
                          'name',
                          activeLang,
                          e.target.value,
                        )
                      }
                    />
                  </InputWrapper>
                </Row>

                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Popis objektu miestnosti')}</StyledLabel>
                    <CustomTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'description',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={data.translations[activeLang].description}
                      placeholder={__('Rozsiahly popis')}
                    />
                  </InputWrapper>
                </Row>

                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Meta title')}</StyledLabel>
                    <CustomTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'meta_title',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={data.translations[activeLang].meta_title}
                      placeholder={__('Meta title')}
                    />
                  </InputWrapper>
                </Row>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Meta description')}</StyledLabel>
                    <MetaDescriptionTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'meta_description',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={data.translations[activeLang].meta_description}
                      placeholder={__('Meta description')}
                    />
                  </InputWrapper>
                </Row>
              </SettingsBox>
              <Space />
            </Row>
          </div>

          {data._id && (
            <Row>
              <SettingsBox addDisabled>
                <InputWrapper style={{ width: rem(400) }}>
                  <StyledLabel>{__('Obrázok')}</StyledLabel>

                  {data.images &&
                    data.images.map(image => (
                      <ImageRow>
                        <Image
                          src={[`${baseURL}/images/400x0/${image.fullPath}`]}
                          loader={<Loader color="#70af00" />}
                        />
                        <ImageInfoWrapper>
                          <Label>{__('Typ súboru')}</Label>
                          <ImageInfo>{image.mimeType}</ImageInfo>
                          <Space />

                          <Label>{__('Veľkosť súboru')}</Label>
                          <ImageInfo>{formatBytes(image.size)}</ImageInfo>
                          <Space />
                          <Label>{__('Titulný')}</Label>
                          <ImageInfo>
                            <Checkbox
                              onChange={() =>
                                this.handleTitleImageChange(image.fullPath)
                              }
                              checked={image.title_image}
                            />
                          </ImageInfo>
                          <Label>{__('Zobraziť v galérii produktu')}</Label>
                          <ImageInfo>
                            <Checkbox
                              onChange={() =>
                                this.handleShowImageChange(image.fullPath)
                              }
                              checked={!image.hide_in_product_gallery}
                            />
                          </ImageInfo>

                          <Space />
                          <Space />
                          <ImageDelete
                            onClick={() => this.deleteImage(image.fullPath)}
                          >
                            {__('Odstrániť ')}
                          </ImageDelete>
                        </ImageInfoWrapper>
                      </ImageRow>
                    ))}

                  <Dropzone
                    files={data.images}
                    getUploadParams={e => this.getUploadParams(e)}
                    onChangeStatus={this.handleChangeStatus}
                    accept={acceptedMimeTypesViewImage.join(',')}
                    inputContent={__('Nahrať obrázok')}
                    canCancel={false}
                    initialFiles={data.images}
                    styles={{
                      inputLabel: { color: '#ee6500' },
                    }}
                  />
                </InputWrapper>
              </SettingsBox>
            </Row>
          )}
        </ContentWrapper>

        <ButtonRelativeWrapperLeft>
          <Button onClick={() => this.loadData(true)} danger>
            {__('Zahodiť zmeny')}
          </Button>
          <Space />
          <Space />
          <Space />

          <Button
            loading={saveLoading}
            onClick={() => this.saveData(true)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapperLeft>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const Image = styled(Img)`
  max-width: ${rem(230)};
`;

const ImageInfo = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;

const ImageInfoWrapper = styled.div`
  margin-left: ${rem(24)};
`;

const ImageDelete = styled.div`
  font-size: 12px;
  color: red;
  display: flex;
  cursor: pointer;
  margin-left: ${rem(8)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  margin-bottom: ${rem(20)};
  flex-direction: column;
  ${({ theme }) => theme.media.l`
 flex-direction: row;
`};
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const ImageRow = styled(Row)`
  border-bottom: ${rem(1)} solid #eee;
  padding: ${rem(12)};
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const CustomTextArea = styled(Textarea)`
  width: 100%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  // margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

const ChannelName = styled.div`
  font-size: 14px;
`;

InspirationsObjectDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(InspirationsObjectDetail);
