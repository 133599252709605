import { __ } from '../Utils';

export default [
  {
    name: __('Názov kampane'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 200 },
  },
  {
    name: __('Krajina'),
    clickable: false,
  },
  {
    name: __('Platí pre'),
    clickable: false,
  },
  // { name: __('Zľava'), clickable: false, sorterValue: '' },
  { name: __('Stav'), clickable: true, sorterValue: 'state' },
  { name: __('Počet zákazníkov'), clickable: false },
  { name: __('Platí od'), clickable: true, sorterValue: 'valid_from' },
  { name: __('Platí do'), clickable: true, sorterValue: 'valid_to' },
  {
    name: __('Publikovaná od'),
    clickable: true,
    sorterValue: 'published_from',
  },
  { name: __('Publikovaná do'), clickable: true, sorterValue: 'published_to' },
  { name: __('Newsletter'), clickable: false, sorterValue: 'published_to' },
];
