import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Loader } from 'oxyrion-ui/lib/';
import EmailEditor from 'react-email-editor';
import Button from 'oxyrion-ui/lib/Button';
import emptyTemplate from './empty_template.json';
import { __ } from '../../Utils/index';

const Container = styled.div`
  position: relative;
  padding-bottom: ${rem(24)};
  margin-top: -1px;
  border-top: 1px solid orange;
`;

class ProductDescriptionEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false;

    this.state = {
      isRefreshing: false,
      currentVariables: [],
      showAddPreset: false,
      showVariables: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.jsonData !== nextProps.jsonData ||
      this.props.lang !== nextProps.lang
    ) {
      this.isEditorLoaded = true;
      this.isComponentMounted = true;

      this.loadTemplate(nextProps.jsonData);
    }
  }

  onLoad = () => {
    this.editorRef = this.editor.editor;
    this.isEditorLoaded = true;
    this.loadTemplate();
  };

  onDesignLoad = () => {
    if (this.props.onDesignLoad) {
      this.props.onDesignLoad();
    }
    this.setState({ isRefreshing: false });

    if (this.isEditorLoaded && this.isComponentMounted) {
      this.editorRef.addEventListener('design:updated', (updates) => {
        this.props.hasUnsaved(updates);
      });
    }
  };

  loadTemplate = (jsonData) => {
    this.setState({ isRefreshing: true });
    if (jsonData) {
      if (!this.isEditorLoaded || !this.isComponentMounted) {
        this.loadTemplate(jsonData);
      } else {
        this.editorRef.loadDesign(JSON.parse(jsonData));
        this.props.setEditor(this.editorRef);

        this.editorRef.exportHtml((data) => {
          const { html } = data;
          const e = html && html.match(/\{\{.*?\}\}/g);
          this.setState({ currentVariables: e || [] });
        });
      }
    } else {
      this.resetDesign();
    }
  };

  saveDesign = () => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    this.editorRef.exportHtml((data) => {
      const { design, html } = data;
      this.props.onSave(JSON.stringify(design), html);
      const e = html && html.match(/\{\{.*\}\}/g);

      this.setState({ currentVariables: e || [] });
    });
  };

  resetDesign = (unsaved = false) => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    this.editorRef.loadDesign(emptyTemplate);
    this.props.setEditor(this.editorRef);
    this.setState({ currentVariables: [] });
    this.loadDefaultStyle();

    if (unsaved) {
      this.props.hasUnsaved();
    }
  };

  loadDefaultStyle = () => {
    this.editorRef.setBodyValues({
      backgroundColor: '#fff',
      contentWidth: 'inherit',
    });
  };

  render() {
    const { isRefreshing } = this.state;

    return (
      <Container>
        {isRefreshing && (
          <LoaderOverlay>
            <Loader size="xl" />
          </LoaderOverlay>
        )}

        <ButtonsWrapper>
          <VariablesWrapper>
            <ResetButton
              danger
              onClick={() => {
                this.resetDesign(true);
              }}
            >
              {`${__('Vyčistiť editor')}`}
            </ResetButton>
          </VariablesWrapper>

          <VariablesWrapper>
            <SaveButton
              primary
              onClick={() => {
                this.saveDesign();
              }}
            >
              {`${__('Uložiť')}`}
            </SaveButton>
          </VariablesWrapper>
        </ButtonsWrapper>

        <EmailEditor
          ref={(editor) => {
            this.editor = editor;
          }}
          key={this.props.keyProps}
          onLoad={this.onLoad}
          minHeight={rem(window.innerHeight - 150)}
          onDesignLoad={this.onDesignLoad}
        />
      </Container>
    );
  }
}

const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
`;

const SaveButton = styled(Button)`
  margin: ${rem(8)};
  padding: ${rem(10)};
  min-width: 140px;
`;

const ResetButton = styled(SaveButton)`
  margin-left: 0;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const VariablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${rem(12)};
  padding: ${rem(8)};
`;

ProductDescriptionEditor.propTypes = {
  onSave: PropTypes.func.isRequired,
  keyProps: PropTypes.string,
  setEditor: PropTypes.func,
  lang: PropTypes.string,
  jsonData: PropTypes.string,
  onDesignLoad: PropTypes.func,
  hasUnsaved: PropTypes.func,
};

ProductDescriptionEditor.defaultProps = {
  keyProps: '',
  setEditor: () => {},
  lang: null,
  jsonData: null,
  onDesignLoad: null,
  hasUnsaved: () => {},
};

export default ProductDescriptionEditor;
