import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProductTypes from '../../../Containers/Translations';
import ProductTypeDetail from '../../../Containers/TranslationDetail';

function ProductsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={ProductTypeDetail} />
      <Route path={`${match.url}`} component={ProductTypes} />
    </Switch>
  );
}

ProductsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsCard;
