import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserDetail from '../../../Containers/UserDetail';
import Users from '../../../Containers/Users';

function ProductsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={UserDetail} />
      <Route path={`${match.url}`} component={Users} />
    </Switch>
  );
}

ProductsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsCard;
