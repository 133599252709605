import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
  },
  {
    name: __('Mesto'),
    clickable: false,
  },
  {
    name: __('Rok'),
    clickable: false,
  },
  {
    name: __('Krajina'),
    clickable: false,
  },
  {
    name: __('Stav'),
    clickable: false,
  },
  {
    name: __('Vlastník'),
    clickable: false,
  },
  {
    name: __('Objekt/priestor'),
    clickable: false,
  },
  {
    name: __('Dátum aktualizácie'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
  {
    name: __(''),
    otherProps: { maxWidth: 50 },
    clickable: false,
  },
];
