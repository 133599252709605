import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexBox, H2 } from '../ReusableComponents';
import ControlBarInfoBlock from './ControlBarInfoBlock';
import ControlbarSearch from './ControlBarSearch';

const Wrapper = styled(FlexBox)`
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${rem(5)} ${rem(15)};
  position: sticky;
  background-color: ${({ theme }) => theme.color.primary};
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  z-index: ${({ noOverFlow }) => (noOverFlow ? 0 : 4)};
  top: ${rem(51)};
  color: white;
`;

const Name = styled.div`
  display: ${({ display }) => (display ? 'inline-block' : 'none')};
  margin-bottom: ${rem(5)};
`;

class ControllBar extends React.Component {
  render() {
    const {
      justifyContent,
      name,
      onChange,
      noOverFlow,
      defaultValue,
    } = this.props;
    return (
      <Wrapper justifyContent={justifyContent} noOverFlow={noOverFlow}>
        <ControlBarInfoBlock
          history={this.props.history}
          right={!onChange}
          showLocation={this.props.showLocation}
        >
          <Name display={name}>
            <H2>{name}</H2>
          </Name>
        </ControlBarInfoBlock>
        {onChange && (
          <ControlbarSearch
            defaultValue={defaultValue}
            onChange={val => onChange(val)}
          />
        )}
        {this.props.children}
      </Wrapper>
    );
  }
}

ControllBar.propTypes = {
  justifyContent: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  showLocation: PropTypes.bool,
  noOverFlow: PropTypes.bool,
  defaultValue: PropTypes.string,
  history: PropTypes.object.isRequired, //eslint-disable-line
};

ControllBar.defaultProps = {
  justifyContent: 'flex-end',
  name: undefined,
  showLocation: true,
  onChange: null,
  noOverFlow: false,
  defaultValue: '',
};

export default ControllBar;
