import PropTypes from 'prop-types';
import BaseTable from '../BaseTable';
import API from '../../API';
import tableHeaders from '../../ContentConfig/synchronizationLogs';
import { __, formatDate } from '../../Utils';
import { connect } from '../../Store';

class SynchronizationLogs extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'type' });
    this.headers = tableHeaders;
    this.segmentName = __('Logy synchronizácie');
    this.accessors = ['type', 'text', 'created_date'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('type');
    this.state = {
      ...this.state,
    };
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter } = this.state;
      this.setState({
        loading: true,
      });

      const params = Object.assign({}, parameters, { sorter });
      const newData = await API.getSynchronizationsLogsAction(
        this.props.firm,
        this.props.syncId,
        params,
      );

      this.setState({
        content: this.normalizeColumns(newData.items),
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        type: _shapeData(this.getSyncLogTypeName(item.type)),
        text: _shapeData(item.text),
        created_date: _shapeData(formatDate(item.created_date)),
      });
      return acumulator;
    }, []);
    return result;
  }
  renderControlBar() {
    return '';
  }

  getSyncLogTypeName(type) {
    switch (type) {
      case 'info':
        return __('Informácia');
      case 'success':
        return __('Úspech');
      case 'succes':
        return __('Úspech');
      case 'error':
        return __('Chyba');
      default:
        return '';
    }
  }
  render() {
    return super.render();
  }
}
SynchronizationLogs.propTypes = {
  firm: PropTypes.string,
  syncId: PropTypes.string,
};
const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(SynchronizationLogs);
