import { __ } from '../Utils';

export default [
  {
    name: __('Tónovací systém'),
    clickable: true,
    sorterValue: 'name',
  },
  {
    name: __('Značka'),
    clickable: true,
    sorterValue: 'name_short',
  },
  {
    name: __('Počet farbív'),
    clickable: false,
  },
  { name: __('Báza'), clickable: false },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
];
