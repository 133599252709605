import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import 'react-dropzone-uploader/dist/styles.css';
import { rem } from 'polished';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Textarea,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import Select from 'oxyrion-ui/lib/Select';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  max-width: ${rem(500)};
  margin-bottom: ${rem(20)};
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  // margin-right: ${rem(16)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const CustomTextArea = styled(Textarea)`
  width: 96%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

class SecurityAlertsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saveLoading: false,
      success: false,
      activeLang: 'SK',
      types_codelist: [],
      data: {
        name: '',
        type: '',
        code: '',
        class: '',
      },
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getTranslatedData(data, lang) {
    if (!data) {
      return '';
    }

    const obj = data.find(d => d.lang === lang);
    return obj ? obj.value : '';
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const id = getIdFromProps(this.props);
    const { data } = this.state;

    if (id !== 'new') {
      await Promise.all([this.fetchMaterialData(id), this.fetchCodelist()]);
    } else {
      await this.fetchCodelist();
      this.setState({
        data,
        loading: false,
      });
    }
  }

  useQuery(props) {
    return queryString.parse(props.location.search);
  }

  async fetchMaterialData(id) {
    try {
      const data = await AdminAPI.getSecurityAlertAction(id);

      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Multimédium sa nenašlo'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    if (!data.type || data.type.length === 0) {
      isValid = false;
    }

    if (!data.code || data.code.length === 0) {
      isValid = false;
    }

    if (
      !data.descriptionTranslations ||
      data.descriptionTranslations.length === 0
    ) {
      isValid = false;
    }

    return isValid;
  }

  async saveData(showSucces = true) {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data)) {
      try {
        const params = {};
        params.body = Object.assign({
          name: data.name,
          type: data.type,
          code: data.code,
          class: data.class,
          descriptionTranslations: data.descriptionTranslations,
        });

        if (getIdFromProps(this.props) === 'new') {
          const result = await AdminAPI.postSecurityAlertAction(params);
          this.props.history.push(
            `/${this.props.firm}/security-alerts/${result._id}`,
          );

          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        } else {
          const result = await AdminAPI.putSecurityAlertAction(
            getIdFromProps(this.props),
            params,
          );

          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        error: __('Nie sú vyplnené všetky potrebné hodnoty'),
        saveLoading: false,
      });
    }
  }

  async fetchCodelist() {
    try {
      const result = await AdminAPI.getCodelist('security_alerts_types');

      this.setState({
        types_codelist: result.codelist || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  async deleteItem() {
    const { history, firm } = this.props;

    this.setState({
      deleteLoading: true,
    });

    try {
      await AdminAPI.deleteSecurityAlertAction(getIdFromProps(this.props));

      history.push(`/${firm}/security-alerts`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri odstránení sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    if (!data[field]) {
      data[field] = [];
    }

    if (!data[field].find(d => d.lang === lang)) {
      data[field].push({
        lang,
        value,
      });
    } else {
      data[field].find(d => d.lang === lang).value = value;
    }

    this.setState({
      data,
      error: false,
    });
  }

  async handleChange(field, value) {
    const { data } = this.state;

    data[field] = value;

    this.setState({ data, error: false });
  }

  handleChangeActiveLang(lang) {
    const { data } = this.state;

    this.setState({
      activeLang: lang,
      data,
    });

    return true;
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Detail')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
      activeLang,
      uploading,
      saveLoading,
      types_codelist,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__(
              'Naozaj si prajete odstániť bezpečnostné upozornenie ?',
            )}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteItem()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát sa vyskytla chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{ width: rem(450) }}>
                  <StyledLabel>{__('Typ*')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.type}
                    onChange={e => {
                      this.handleChange('type', e.target.value);
                    }}
                  >
                    {types_codelist && types_codelist.length > 0 ? (
                      types_codelist.map(c => {
                        return (
                          <option selected={c === data.type} value={c.value}>
                            {c.label}
                          </option>
                        );
                      })
                    ) : (
                      <option>{__('Neobsahuje možnosti na výber')}</option>
                    )}
                  </Select>
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{ width: rem(450) }}>
                  <StyledLabel>{__('Kód*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.code}
                    placeholder={'H 200'}
                    onChange={e => this.handleChange('code', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{ width: rem(450) }}>
                  <StyledLabel>{__('Trieda')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.class}
                    placeholder={'2.1'}
                    onChange={e => this.handleChange('class', e.target.value)}
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
            <Space />
          </Row>
          <div>
            <Row>
              <LanguageSwitch
                disabled={uploading}
                onChange={lang => this.handleChangeActiveLang(lang)}
                activeId={activeLang}
              />
            </Row>

            <Row>
              <SettingsBox addDisabled>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Popis')}</StyledLabel>
                    <CustomTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'descriptionTranslations',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={this.getTranslatedData(
                        data.descriptionTranslations,
                        activeLang,
                      )}
                      placeholder={__('Popis')}
                    />
                  </InputWrapper>
                </Row>
              </SettingsBox>
              <Space />
            </Row>
          </div>
        </ContentWrapper>

        <ButtonRelativeWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveData(true)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

SecurityAlertsDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(SecurityAlertsDetail);
