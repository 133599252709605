import { __ } from '../Utils';

export default [
  {
    key: 1,
    value: 'number',
    display_name: __('Číslo'),
  },
  {
    key: 2,
    value: 'string',
    display_name: __('Text'),
  },
  {
    key: 3,
    value: 'table',
    display_name: __('Tabuľka'),
  },
  {
    key: 4,
    value: 'codelist',
    display_name: __('Číselník'),
  },
  {
    key: 5,
    value: 'multiSelect',
    display_name: __('Multi-select'),
  },
  {
    key: 5,
    value: 'checkbox',
    display_name: __('Checkbox'),
  },
  {
    key: 6,
    value: 'checkboxesGroup',
    display_name: __('Zoznam s checkboxmi - Nát. systémy'),
  },
  {
    key: 7,
    value: 'selectGroup',
    display_name: __('Zoznam s číselníkmi'),
  },
  {
    key: 8,
    value: 'catalogProductsSelector',
    display_name: __('Vyberač z produktov'),
  },
  {
    key: 9,
    value: 'colorSelector',
    display_name: __('Vyberač z odtieňov'),
  },
  {
    key: 9,
    value: 'securityCertificateSelector',
    display_name: __('Vyberač z bezp. certifikátov'),
  },
];
