import { __ } from '../Utils';

export default [
  {
    name: __('Aplikácia'),
    clickable: false,
  },
  {
    name: __('Jazyk'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
  // {
  //   name: __('Dátum aktualizácie'),
  //   clickable: false,
  // },
];
