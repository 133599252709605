import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, ThemeAdmin } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import { LANGS } from '../../ContentConfig/languagesArray';

class LanguageSwitch extends React.Component {
  render() {
    const { onChange, activeId, values, disabled } = this.props;
    return (
      <React.Fragment>
        <Tabs
          key={activeId}
          color={ThemeAdmin.color.primary}
          onChange={(e) => {
            return !disabled && onChange(e.id);
          }}
          activeTab={{
            id: activeId,
          }}
        >
          {values.map((item) => {
            return (
              <Tabs.Tab
                key={item}
                id={item}
                title={__(item === 'xFarby' ? 'xPaint' : item)}
              >
                <div />
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </React.Fragment>
    );
  }
}

LanguageSwitch.propTypes = {
  onChange: PropTypes.func,
  activeId: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

LanguageSwitch.defaultProps = {
  activeId: 'SK',
  onChange: () => {},
  values: LANGS,
  disabled: false,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(LanguageSwitch);
