import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Input, Icon, Label, Checkbox } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import SearchableSelect from '../../Components/SearchableSelect';

const CheckBoxWrapper = styled.div`
  display: flex;
  margin-left: ${rem(20)};
`;

const PublicationWrapper = styled.div``;

const publishedChannels = ['xPaint', 'Portal'];
class AddSaleProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsToAdd: [],
      validProductsData: false,
      newSaleProducstSettings: {
        country: 'SK',
        valid_from: new Date(),
        valid_to: new Date(),
      },
      variant: null,
      variants: [],
      published: [],
    };
  }
  addSaleProduct(e) {
    const { productsToAdd, published } = this.state;

    if (!e) {
      return;
    }

    this.setState({ variant: e });
    if (productsToAdd.find((p) => p.product_nav_id === e.product.No)) {
      return;
    }

    if (e.product.prices.find((c) => c.firm === 'SK')) {
      const saleProductSK = {
        name: e.label,
        firm: 'SK',
        product_nav_id: e.product.No,
        deactivated: false,
        discount_percent: null,
        price: null,
        default_price: e.product.prices.find((c) => c.firm === 'SK')
          .Unit_Price_VAT,
        valid_until_sold_out: true,
        published,
      };

      productsToAdd.push(saleProductSK);
    }

    if (e.product.prices.find((c) => c.firm === 'CZ')) {
      const saleProductCZ = {
        name: e.label,
        firm: 'CZ',
        product_nav_id: e.product.No,
        deactivated: false,
        discount_percent: null,
        price: null,
        default_price: e.product.prices.find((c) => c.firm === 'CZ')
          .Unit_Price_VAT,
        valid_until_sold_out: true,
        published,
      };

      productsToAdd.push(saleProductCZ);
    }

    this.setState({ productsToAdd }, () => this.checkProductsValidity());
  }

  checkProductsValidity() {
    const { productsToAdd } = this.state;

    let valid = true;
    productsToAdd.forEach((p) => {
      if (!p.discount_percent && !p.price) {
        valid = false;
      }
    });
    this.setState({ validProductsData: valid });
    return valid;
  }

  fetchProducts(query) {
    try {
      return AdminAPI.getCatalogProductsVariantsAction({
        q: query || '',
        limit: 100,
        allCountries: true,
      }).then((res) => {
        const variants = res.variants.map((item) => ({
          value: item._id,
          label: item.name,
          product: item,
        }));
        this.setState({ variants });
        return variants;
      });
    } catch (e) {
      return [];
    }
  }

  cancelAddingSaleProducts() {
    this.setState({ productsToAdd: [] });
    this.props.hideShowAddNewProducts();
  }

  changePercentage(no, percent, firm) {
    const { productsToAdd } = this.state;
    if (percent > 100 || percent < 0) {
      return;
    }
    productsToAdd.find(
      (p) => p.product_nav_id === no && p.firm === firm,
    ).discount_percent = percent;
    this.setState({ productsToAdd }, () => this.checkProductsValidity());
  }

  // changeProductNumber(no, productNumber, firm) {
  //   const { productsToAdd } = this.state;
  //   if (productNumber === '') return;
  //   productsToAdd.find(
  //     p => p.product_nav_id === no && p.firm === firm,
  //   ).product_number = productNumber;
  //   this.setState({ productsToAdd }, () => this.checkProductsValidity());
  // }

  changePrice(no, price, firm) {
    const { productsToAdd } = this.state;
    if (price < 0) return;
    productsToAdd.find(
      (p) => p.product_nav_id === no && p.firm === firm,
    ).price = price;
    this.setState({ productsToAdd }, () => this.checkProductsValidity());
  }

  changeDefaultPrice(no, price, firm) {
    const { productsToAdd } = this.state;
    if (price < 0) return;
    productsToAdd.find(
      (p) => p.product_nav_id === no && p.firm === firm,
    ).default_price = price;
    this.setState({ productsToAdd }, () => this.checkProductsValidity());
  }

  // changeSaleValidity(no, firm) {
  //   const { productsToAdd } = this.state;
  //   productsToAdd.find(
  //     p => p.product_nav_id === no && p.firm === firm,
  //   ).valid_until_sold_out = !productsToAdd.find(
  //     p => p.product_nav_id === no && p.firm === firm,
  //   ).valid_until_sold_out;
  //   this.setState({ productsToAdd });
  // }

  removeSaleProduct(productNavId) {
    if (
      window.confirm(__(`Naozaj chcete vymazať produkt č. ${productNavId}`))
    ) {
      const { productsToAdd } = this.state;

      // Use filter to create a new array with elements that don't match the given productNavId
      const newProductsToAdd = productsToAdd.filter(
        (p) => p.product_nav_id !== productNavId,
      );

      this.setState({ productsToAdd: newProductsToAdd }, () =>
        this.checkProductsValidity(),
      );
    }
  }

  render() {
    const {
      newSaleProducstSettings,
      productsToAdd,
      validProductsData,
      variant,
      variants,
    } = this.state;

    let { published } = this.state;
    const { showAddNewProducts, addingSaleProductsLoading } = this.props;
    return (
      <PopUpWrapper
        display={showAddNewProducts}
        large
        titleZIndex={1000}
        customWidth={1200}
        noMinHeight={2500}
        message={__('Pridanie produktov do výpredaju')}
        onClose={() => this.props.hideShowAddNewProducts()}
        onEscClose={() => this.props.hideShowAddNewProducts()}
      >
        <PopUpContentWrapper>
          <CountryValidityWrapper>
            {/* <CountryInputWrapper>
              <StyledLabel>{__('Krajina')}</StyledLabel>
              <Select
                size="s"
                value={newSaleProducstSettings.country}
                onChange={e =>
                  this.setState({
                    newSaleProducstSettings: {
                      ...this.state.newSaleProducstSettings,
                      country: e.target.value,
                    },
                  })
                }
              >
                <option
                  selected={newSaleProducstSettings.country === 'SK'}
                  value="SK"
                >
                  {'SK'}
                </option>
                <option
                  selected={newSaleProducstSettings.country === 'CZ'}
                  value="CZ"
                >
                  {'CZ'}
                </option>
              </Select>
            </CountryInputWrapper> */}
            {/* <ValidityInputWrapper>
              <DatePickerWrapper>
                <StyledLabel>{__('Platný od')}</StyledLabel>
                <StyledDatePicker
                  placeholderText={__('Od')}
                  dateFormat="dd DD.MM.YYYY"
                  selected={moment(newSaleProducstSettings.valid_from)}
                  minDate={moment()}
                  onChange={e =>
                    this.setState({
                      newSaleProducstSettings: {
                        ...this.state.newSaleProducstSettings,
                        valid_from: e,
                      },
                    })
                  }
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <StyledLabel>{__('Platný do')}</StyledLabel>
                <StyledDatePicker
                  placeholderText={__('Do')}
                  dateFormat="dd DD.MM.YYYY"
                  selected={moment(newSaleProducstSettings.valid_to)}
                  minDate={moment(newSaleProducstSettings.valid_from)}
                  onChange={e =>
                    this.setState({
                      newSaleProducstSettings: {
                        ...this.state.newSaleProducstSettings,
                        valid_to: e,
                      },
                    })
                  }
                />
              </DatePickerWrapper>
            </ValidityInputWrapper> */}
          </CountryValidityWrapper>

          <PublicationWrapper>
            <Label> {__('Publikácia')}</Label>
            <CheckBoxWrapper>
              {publishedChannels.map((item) => {
                return (
                  <div>
                    <Checkbox
                      key={JSON.stringify(published)}
                      checked={published ? published.includes(item) : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (!published.includes(item)) {
                            published.push(item);
                          }
                        } else {
                          if (published.includes(item)) {
                            published = published.filter((p) => p !== item);
                          }
                        }
                        this.setState({
                          published,
                        });
                      }}
                    />
                    <Label> {item}</Label>
                  </div>
                );
              })}
            </CheckBoxWrapper>
          </PublicationWrapper>

          <SearchProductWrapper>
            <SearchableSelect
              value={variant}
              loadOptions={(query) => this.fetchProducts(query)}
              placeholder={__('Vyhľadať produkt')}
              name={null}
              handleOnChange={(e) => this.addSaleProduct(e)}
              defaultOptions={variants}
            />
          </SearchProductWrapper>
          {productsToAdd && productsToAdd.length > 0 ? (
            <ProductsToAddTable>
              <table>
                <tr>
                  <th>{__('Krajina')}</th>
                  <th>{__('Produkt pre výpredaj')}</th>
                  <th>{__('Číslo produktu')}</th>
                  <th>{__('Pôvodna cena')}</th>
                  <th>{__('Percentuálna zľava')}</th>
                  <th>{__('Pevná cena')}</th>
                  {/* <th>{__('Do vypredania zásob')}</th> */}
                  <th>{__('Odstrániť')}</th>
                </tr>
                {productsToAdd.map((p) => (
                  <tr>
                    <td>{p.firm}</td>
                    <td>{p.name}</td>
                    <td>
                      {/* <Input
                        value={p.product_number || ''}
                        placeholder={__('Číslo produktu')}
                        onChange={e =>
                          this.changeProductNumber(
                            p.product_nav_id,
                            e.target.value,
                            p.lang,
                          )
                        }
                      /> */}
                      <td>{p.product_nav_id}</td>
                    </td>
                    <td className="price-input-wrapper">
                      {' '}
                      <Input
                        type="number"
                        min={0}
                        value={p.default_price || ''}
                        placeholder={p.firm === 'SK' ? '€' : 'Kč'}
                        onChange={(e) =>
                          this.changeDefaultPrice(
                            p.product_nav_id,
                            e.target.value,
                            p.firm,
                          )
                        }
                      />
                    </td>
                    <td>
                      <div className="price-input-wrapper">
                        <Input
                          value={p.discount_percent || ''}
                          disabled={
                            p.price != null && p.price !== '' && p.price !== 0
                          }
                          min={0}
                          max={100}
                          type="number"
                          placeholder={__('%')}
                          onChange={(e) =>
                            this.changePercentage(
                              p.product_nav_id,
                              e.target.value,
                              p.firm,
                            )
                          }
                        />
                      </div>
                    </td>
                    <td className="price-input-wrapper">
                      {' '}
                      <Input
                        type="number"
                        disabled={
                          p.discount_percent != null &&
                          p.discount_percent !== '' &&
                          p.discount_percent !== 0
                        }
                        min={0}
                        value={p.price || ''}
                        placeholder={p.firm === 'SK' ? '€' : 'Kč'}
                        onChange={(e) =>
                          this.changePrice(
                            p.product_nav_id,
                            e.target.value,
                            p.firm,
                          )
                        }
                      />
                    </td>
                    {/* <td>
                      <Checkbox
                        onChange={() =>
                          this.changeSaleValidity(p.product_nav_id, p.firm)
                        }
                        checked={p.valid_until_sold_out}
                      />
                    </td> */}
                    <td>
                      <IconWrapper
                        onClick={() => this.removeSaleProduct(p.product_nav_id)}
                      >
                        <Icon size="md" name="close" color="#ee6500" />
                      </IconWrapper>
                    </td>
                  </tr>
                ))}
              </table>
            </ProductsToAddTable>
          ) : (
            <ProductsToAddTable />
          )}

          <ActionButtons>
            <Button
              loading={null}
              onClick={() => this.cancelAddingSaleProducts()}
              danger
            >
              {__('Zrušiť')}
            </Button>

            <Button
              loading={addingSaleProductsLoading}
              onClick={() => {
                this.setState({ productsToAdd: [] });
                this.props.addSaleProducts(
                  productsToAdd,
                  newSaleProducstSettings,
                );
              }}
              primary
              disabled={!validProductsData || productsToAdd.length === 0}
            >
              {__('Pridať')}
            </Button>
          </ActionButtons>
          {!validProductsData && (
            <ErrorMessage>
              {__(
                'Každý produkt musí mať uvedenú percentuálnu zľavu alebo pevnú cenu a obe hodnoty musia byť číslo.',
              )}
            </ErrorMessage>
          )}
        </PopUpContentWrapper>
      </PopUpWrapper>
    );
  }
}
AddSaleProducts.propTypes = {
  addSaleProducts: PropTypes.func.isRequired,
  hideShowAddNewProducts: PropTypes.func.isRequired,
  showAddNewProducts: PropTypes.bool,
  addingSaleProductsLoading: PropTypes.bool,
};

AddSaleProducts.defaultProps = {
  showAddNewProducts: false,
  addingSaleProductsLoading: false,
};

// const DatePickerWrapper = styled.div`
//   max-width: ${rem(150)};
//   margin-right: ${rem(10)};
// `;
// const StyledLabel = styled(Label)`
//   margin-right: ${rem(4)};
// `;
// const StyledDatePicker = styled(DatePicker)`
//   max-width: 90%;
// `;
const PopUpContentWrapper = styled.div`
  width: ${rem(1100)};
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
`;
const CountryValidityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rem(24)};
`;
const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(4)};
  font-size: ${rem(10)};
  color: red;
`;

// const ValidityInputWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
// `;
const SearchProductWrapper = styled.div`
  margin-bottom: ${rem(24)};
`;
const ProductsToAddTable = styled.div`
  margin-bottom: ${rem(24)};
  min-height: ${rem(400)};
  table {
    font-size: ${rem(12)};
    width: 100%;
  }
  th {
    padding: ${rem(10)};
    font-size: ${rem(14)};
  }
  td {
    padding: ${rem(5)};
  }
  .price-input-wrapper {
    padding-left: ${rem(15)};
    padding-right: ${rem(30)};
  }
  tr {
    padding-bottom: ${rem(10)};
  }
  tr:nth-child(even) {
    background: #f7f7f7;
  }

  .product-to-add-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: ${rem(8)};
    padding-bottom: ${rem(8)};
  }
  .product-to-add-wrapper:nth-child(2n) {
    background-color: lightgray;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

export default AddSaleProducts;
