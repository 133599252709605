import { __ } from '../Utils';

export default [
  {
    name: __('Meno'),
    clickable: false,
  },
  {
    name: __('Priezvisko'),
    clickable: false,
  },
  {
    name: __('Predajňa'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
  {
    name: __('Dátum úpravy'),
    clickable: false,
  },
];
