import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import Icon from 'oxyrion-ui/lib/Icon';
import { Tr, Td } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { ChromePicker } from 'react-color';
import { __ } from '../../Utils';
import SearchableSelect from '../../Components/SearchableSelect';
import { connect } from '../../Store';
import API2 from '../../API2';

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  margin-right: ${rem(10)};
`;

const StyledInputSmall = styled(StyledInput)`
  max-width: ${rem(40)};
  padding: 0;
`;

const DesktopButton = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.m`
  display: flex;
`};
`;

const SearchableSelectWrapper = styled.div`
  max-width: ${rem(200)};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex
    ${({ theme }) => theme.media.m`
  display: none;
`};
`;

const ColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 15px;
`;
const ColorPickerHolder = styled.div`
  position: absolute;
  cursor: pointer;
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  top: ${rem(5)};
  left: ${rem(2)};
  height: ${rem(36)};
  width: ${rem(36)};
`;
const SmallTd = styled(Td)`
  width: 7%;
  padding-left: ${rem(1)};
  padding-right: ${rem(1)};
`;

const RelativeTd = styled(SmallTd)`
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

class AddNewToning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coloring: '',
      shortcut: '',
      product: '',
      price: 0,
      volume: 0,
      RGB_R: undefined,
      RGB_G: undefined,
      RGB_B: undefined,
      showPicker: false,
    };
  }

  componentWillReceiveProps(props) {
    if (props.systemToEdit !== this.props.systemToEdit) {
      if (props.systemToEdit) {
        this.setState({
          coloring: props.systemToEdit.name,
          shortcut: props.systemToEdit.name_short,
          product: {
            value: props.systemToEdit.nav_product_id,
            label: props.systemToEdit.product_name,
            product: {
              _id: props.systemToEdit.nav_product_id,
              Unit_Price: props.systemToEdit.product_price,
              Unit_Volume: props.systemToEdit.product_volume,
              Description: props.systemToEdit.product_name,
            },
          },
          price: props.systemToEdit.product_price.amount,
          volume: props.systemToEdit.product_volume,
          RGB_R: props.systemToEdit.color.R,
          RGB_G: props.systemToEdit.color.G,
          RGB_B: props.systemToEdit.color.B,
        });
      }

      return true;
    }
  }

  async fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  checkData() {
    const { coloring, shortcut, price, volume, product } = this.state;
    let {
      coloringError,
      shortcutError,
      priceError,
      volumeError,
      productError,
    } = this.state;

    let valid = true;

    if (coloring.length <= 0) {
      valid = false;
      coloringError = true;
    }

    if (shortcut.length <= 0) {
      valid = false;
      shortcutError = true;
    }
    if (price.length <= 0) {
      valid = false;
      priceError = true;
    }
    if (volume.length <= 0) {
      valid = false;
      volumeError = true;
    }

    if (!product) {
      valid = false;
      productError = true;
    }

    this.setState({
      coloringError,
      shortcutError,
      productError,
      priceError,
      volumeError,
    });

    return valid;
  }

  submit() {
    const { onSubmit } = this.props;

    this.setState({
      coloringError: false,
      shortcutError: false,
      priceError: false,
      volumeError: false,
      productError: false,
    });

    const {
      coloring,
      shortcut,
      price,
      volume,
      RGB_R,
      RGB_G,
      RGB_B,
      product,
    } = this.state;

    if (this.checkData()) {
      onSubmit({
        coloring,
        shortcut,
        price,
        volume,
        product: product.product,
        RGB_B: RGB_B >= 0 ? RGB_B : 255,
        RGB_G: RGB_G >= 0 ? RGB_G : 255,
        RGB_R: RGB_R >= 0 ? RGB_R : 255,
      });

      this.setState({
        coloring: '',
        shortcut: '',
        product: '',
        price: 0,
        volume: 0,
        RGB_R: '',
        RGB_G: '',
        RGB_B: '',
        showPicker: false,
        coloringError: false,
        shortcutError: false,
        priceError: false,
        volumeError: false,
        productError: false,
      });
    }
  }

  togglePicker() {
    this.setState({ showPicker: !this.state.showPicker });
  }

  handleOnChange(field, value) {
    let {
      coloring,
      shortcut,
      price,
      volume,
      RGB_R,
      RGB_G,
      RGB_B,
      product,
    } = this.state;
    if (field === 'coloring') {
      coloring = value;
    } else if (field === 'shortcut') {
      shortcut = value;
    } else if (
      field === 'RGB_R' &&
      !isNaN(value) &&
      value.length <= 3 &&
      Number(value) < 256
    ) {
      RGB_R = value;
    } else if (
      field === 'RGB_G' &&
      !isNaN(value) &&
      value.length <= 3 &&
      Number(value) < 256
    ) {
      RGB_G = value;
    } else if (
      field === 'RGB_B' &&
      !isNaN(value) &&
      value.length <= 3 &&
      Number(value) < 256
    ) {
      RGB_B = value;
    } else if (field === 'color') {
      RGB_R = value.rgb.r;
      RGB_G = value.rgb.g;
      RGB_B = value.rgb.b;
    } else if (field === 'product') {
      product = value;
      if (value) {
        price = value.product ? value.product.Unit_Price.amount : 0;
        volume = value.product ? value.product.Unit_Volume : 0;
      } else {
        price = 0;
        volume = 0;
      }
    } else if (field === 'price') {
      price = value;
    } else if (field === 'volume') {
      volume = value;
    }

    this.setState({
      coloring,
      shortcut,
      price,
      volume,
      RGB_R,
      RGB_G,
      RGB_B,
      product,
    });
  }

  discard() {
    const { discard } = this.props;

    discard();

    this.setState({
      coloring: '',
      shortcut: '',
      product: '',
      price: 0,
      volume: 0,
      RGB_R: '',
      RGB_G: '',
      RGB_B: '',
      showPicker: false,
      coloringError: false,
      shortcutError: false,
      priceError: false,
      volumeError: false,
      productError: false,
    });
  }

  render() {
    const {
      coloring,
      shortcut,
      product,
      price,
      volume,
      RGB_R,
      RGB_G,
      RGB_B,
      RGBError,
      coloringError,
      shortcutError,
      priceError,
      volumeError,
      showPicker,
      productError,
    } = this.state;

    return (
      <Tr
        style={{
          border: this.props.systemToEdit ? '2px #1d71b7 solid' : 'none',
        }}
      >
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              error={coloringError}
              value={coloring}
              placeholder={__('Farbivo')}
              onChange={e => {
                this.handleOnChange('coloring', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              value={shortcut}
              placeholder={__('Skratka')}
              error={shortcutError}
              onChange={e => {
                this.handleOnChange('shortcut', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <SearchableSelectWrapper>
            <SearchableSelect
              value={product}
              loadOptions={query => {
                return this.fetchProducts(query);
              }}
              placeholder={__('Vyhľadať produkt')}
              name={product}
              error={productError}
              handleOnChange={e => this.handleOnChange('product', e)}
            />
          </SearchableSelectWrapper>
        </Td>
        <SmallTd>
          <ValueInputWrapper>
            <StyledInputSmall
              width="10%"
              type="number"
              value={price}
              disabled
              placeholder="0,00"
              error={priceError}
              onChange={e => {
                this.handleOnChange('price', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </SmallTd>
        <SmallTd>
          <ValueInputWrapper>
            <StyledInputSmall
              width="10%"
              type="number"
              value={volume}
              placeholder="0,00"
              disabled
              error={volumeError}
              onChange={e => {
                this.handleOnChange('volume', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </SmallTd>
        <SmallTd>
          <ValueInputWrapper>
            <StyledInputSmall
              width="10%"
              value={RGB_R}
              placeholder={__('R')}
              error={RGBError}
              onChange={e => {
                this.handleOnChange('RGB_R', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </SmallTd>
        <SmallTd>
          <ValueInputWrapper>
            <StyledInputSmall
              width="10%"
              value={RGB_G}
              placeholder={__('G')}
              error={RGBError}
              onChange={e => {
                this.handleOnChange('RGB_G', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </SmallTd>
        <SmallTd>
          <ValueInputWrapper>
            <StyledInputSmall
              width="10%"
              value={RGB_B}
              placeholder={__('B')}
              error={RGBError}
              onChange={e => {
                this.handleOnChange('RGB_B', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </SmallTd>
        <RelativeTd>
          <ColorPickerHolder
            style={{
              backgroundColor: `rgba(${RGB_R}, ${RGB_G}, ${RGB_B}, 1)`,
            }}
            onClick={() => this.togglePicker()}
          />
          {showPicker && (
            <ColorPickerWrapper onMouseLeave={() => this.togglePicker()}>
              <ChromePicker
                onChange={e => this.handleOnChange('color', e)}
                color={{
                  r: RGB_R || 0,
                  g: RGB_G || 0,
                  b: RGB_B || 0,
                  a: 1,
                }}
              />
            </ColorPickerWrapper>
          )}
        </RelativeTd>
        <Td>
          <ButtonsWrapper>
            <DesktopButton
              primary
              small
              loading={this.props.buttonLoading}
              onClick={() => this.submit()}
            >
              {this.props.systemToEdit ? __('Upraviť') : __('Pridať')}
              <StyledIcon
                name="plus"
                onClick={() => this.submit()}
                color="white"
              />
            </DesktopButton>

            {this.props.systemToEdit && (
              <DesktopButton
                small
                danger
                loading={this.props.buttonLoading}
                onClick={() => this.discard()}
              >
                {__('Zrušiť')}
                <StyledIcon name="plus" color="white" />
              </DesktopButton>
            )}
          </ButtonsWrapper>
        </Td>
      </Tr>
    );
  }
}

AddNewToning.propTypes = {
  buttonLoading: PropTypes.bool,
  systemToEdit: PropTypes.shape(),
  discard: PropTypes.func.isRequired,
};

AddNewToning.defaultProps = {
  buttonLoading: false,
  systemToEdit: undefined,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(AddNewToning);
