import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import Icon from 'oxyrion-ui/lib/Icon';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';

const DesktopButton = styled(Button)`
  margin-left: ${rem(10)};
  display: flex;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex
    ${({ theme }) => theme.media.m`
  display: none;
`};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(20)};
  min-width: ${rem(200)};
`;

class AddNewCodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      value: '',
    };
  }

  handelChange(e, lang) {
    const { values } = this.state;
    let { error } = this.state;

    if (!values.find(v => v.lang === lang)) {
      values.push({
        lang,
        value: e.target.value,
      });
    } else {
      values.find(v => v.lang === lang).value = e.target.value;
    }

    if (lang === 'SK') {
      error = false;
    }

    this.setState({
      values,
      error,
    });
  }

  submit() {
    const { onSubmit } = this.props;
    const { values, value } = this.state;

    const valueSK = values.find(v => v.lang === 'SK');

    if (valueSK && valueSK.value.length > 0) {
      const data = {
        label: valueSK.value,
        value,
        translations: values.map(v => {
          return {
            lang: v.lang,
            label: v.value,
            value,
          };
        }),
      };

      onSubmit(data);

      this.setState({
        values: [],
        value: '',
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    const { values, error } = this.state;
    const { langs } = this.props;

    return (
      <Row>
        <InputWrapper>
          <Input
            value={this.state.value || ''}
            placeholder={__('Editovatelné vývojarmi')}
            onChange={e => this.setState({ value: e.target.value })}
            disabled={this.props.email !== 'michal.hricisin@dejmark.com'}
          />
        </InputWrapper>
        {langs.map(lang => {
          const value = values.find(v => v.lang === lang) || {};

          return (
            <InputWrapper key={lang}>
              <Input
                value={value.value || ''}
                error={lang === 'SK' && error}
                placeholder={
                  values.find(v => v.lang === 'SK')
                    ? values.find(v => v.lang === 'SK').value
                    : __('Zadajte hodnotu')
                }
                onChange={e => this.handelChange(e, lang)}
              />
            </InputWrapper>
          );
        })}
        <DesktopButton primary small onClick={() => this.submit()}>
          {__('Pridať')}
          <StyledIcon name="plus" onClick={() => this.submit()} color="white" />
        </DesktopButton>
      </Row>
    );
  }
}

AddNewCodeList.propTypes = {
  langs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
};

AddNewCodeList.defaultProps = {
  email: '',
};

export default AddNewCodeList;
