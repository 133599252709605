import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../Store';
import {
  getIdFromProps,
  __,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import AddCampaign from '../../Containers/AddCampaign';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import CheckAccess from '../../ACL/CheckAccess';
import { Textarea } from 'oxyrion-ui/lib';
import ConfirmDialog from '../../Components/ConfirmDialog';

import API from '../../API';
import API2 from '../../API2';

const CustomTextArea = styled(Textarea)`
  width: 92%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const Wrapper = styled.div``;

const NotAllowedTitle = styled.div`
  font-size: ${rem(14)};
  margin-right: ${rem(20)};
`;

const TextareaWrapper = styled.div``;

const RejectedMessageWrapper = styled.div`
  padding: ${rem(20)};
  background: #f12b2bbf;
  color: white;
  margin: ${rem(10)};
  font-size: 14px;
`;

class CampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      showConfirmDialog: false,
      showConfirmDialogSendAprroval: false,
      description: '',
      showCopyCampaign: false,
    };
  }

  async componentDidMount() {
    await this.fetchCampaingData();
  }

  async fetchCampaingData() {
    try {
      if (getIdFromProps(this.props) !== 'new') {
        const { firm } = this.props;
        const campaign = await API2.getCampaignAction(
          firm,
          getIdFromProps(this.props),
        );

        this.setState({ campaign });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async sendForAprroval() {
    const { firm } = this.props;
    const { description } = this.state;
    this.setState({
      approvalLoading: true,
    });

    try {
      const params = {
        data: {
          status: 'pending',
          description,
        },
      };
      const campaign = await API.putCampaignApprovalRequestAction(
        firm,
        getIdFromProps(this.props),
        params,
      );

      this.setState({
        campaign,
        showConfirmDialogSendAprroval: false,
        showConfirmDialogAprroval: false,
        showConfirmDialogReject: false,
        approvalLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa odoslať zmenu statusu'),
        approvalLoading: false,
      });
    }
  }

  async sendAprrovalDecision(status) {
    const { firm } = this.props;
    const { description } = this.state;
    this.setState({
      approvalLoading: true,
    });

    try {
      const params = {
        data: {
          status,
          description,
        },
      };
      const campaign = await API.putCampaignApprovalRequestDecisionAction(
        firm,
        getIdFromProps(this.props),
        params,
      );

      this.setState({
        campaign,
        showConfirmDialogSendAprroval: false,
        showConfirmDialogAprroval: false,
        showConfirmDialogReject: false,
        approvalLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa odoslať zmenu statusu'),
        approvalLoading: false,
      });
    }
  }

  async deleteCampaign() {
    const { history, firm } = this.props;
    // const { firm } = this.props.userData;

    this.setState({
      deleteLoading: true,
    });

    try {
      await API.deleteCampaignAction(firm, getIdFromProps(this.props));
      history.push(`/${firm}/campaigns`);
    } catch (e) {
      this.setState({ error: __('Kampaň sa nepodarilo vymazať') });
    }

    this.setState({
      deleteLoading: false,
    });
  }

  showConfirmDialog() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  showConfirmDialogSendAprroval() {
    this.setState({
      showConfirmDialogSendAprroval: !this.state.showConfirmDialogSendAprroval,
    });
  }

  showConfirmDialogAprroval() {
    this.setState({
      showConfirmDialogAprroval: !this.state.showConfirmDialogAprroval,
    });
  }

  showConfirmDialogReject() {
    this.setState({
      showConfirmDialogReject: !this.state.showConfirmDialogReject,
    });
  }

  redirectToCopyCampaign(id) {
    this.props.history.push(`./${id}`);

    this.setState({
      newCampaingId: null,
    });
  }

  async createCampaignCopy() {
    const { campaign } = this.state;

    this.setState({
      copyLoading: true,
    });

    try {
      const result = await API.postCampaignCopyAction(
        this.props.firm,
        campaign._id,
      );

      this.setState({
        copyLoading: false,
        newCampaingId: result._id,
      });
    } catch (e) {
      console.log(e);
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { campaign, copyLoading } = this.state;

    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Kampaň')}>
          {campaign &&
            campaign.valid_to &&
            new Date(campaign.valid_to) >= new Date() && (
              <React.Fragment>
                {campaign &&
                  (!campaign.approval_request ||
                    campaign.approval_request.status === 'for_approval') && (
                    <ControlBarButton
                      small
                      icon="done"
                      onClick={() => this.showConfirmDialogSendAprroval()}
                    >
                      {__('Odoslať na schvalenie')}
                    </ControlBarButton>
                  )}
                <CheckAccess
                  operation="putCampaignApprovalRequestDecisionAction"
                  Component={
                    campaign &&
                    campaign.approval_request &&
                    campaign.approval_request.status === 'pending' ? (
                      <React.Fragment>
                        <ControlBarButton
                          small
                          icon="done"
                          onClick={() => this.showConfirmDialogAprroval()}
                        >
                          {__('Schváliť')}
                        </ControlBarButton>

                        <ControlBarButton
                          small
                          warning
                          icon="close"
                          onClick={() => this.showConfirmDialogReject()}
                        >
                          {__('Zamietnuť')}
                        </ControlBarButton>
                      </React.Fragment>
                    ) : (
                      <div />
                    )
                  }
                  ComponentNotAllowed={
                    campaign &&
                    campaign.approval_request &&
                    campaign.approval_request.status === 'pending' ? (
                      <NotAllowedTitle>
                        {__('Prebieha schvaľovanie ...')}
                      </NotAllowedTitle>
                    ) : (
                      <div />
                    )
                  }
                />
              </React.Fragment>
            )}

          {getIdFromProps(this.props) !== 'new' && (
            <ControlBarButton
              small
              onClick={() => this.createCampaignCopy()}
              icon="plus"
              loading={copyLoading}
            >
              {__('Vytvoriť kópiu')}
            </ControlBarButton>
          )}

          {getIdFromProps(this.props) !== 'new' && (
            <ControlBarButton
              small
              danger
              onClick={() => this.showConfirmDialog()}
              icon="delete"
            >
              {__('Odstrániť')}
            </ControlBarButton>
          )}
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      deleteLoading,
      approvalLoading,
      showConfirmDialog,
      showConfirmDialogSendAprroval,
      showConfirmDialogAprroval,
      showConfirmDialogReject,
      error,
      campaign,
      description,
      newCampaingId,
    } = this.state;

    return (
      <Wrapper>
        {this.renderControlBar()}
        {campaign &&
          campaign.approval_request &&
          campaign.approval_request.status === 'rejected' && (
            <RejectedMessageWrapper>
              {`${__('Kampaň bola zamietnuté dňa')} ${formatDate(
                moment(campaign.approval_request.updated_date),
                FORMAT_HUMANE_DATETIME,
              )}. ${__('Popis:')} ${
                campaign.approval_request.description
              }. Po zapracovaní zmien bude opätovne možné požiadať o schválenie.`}
            </RejectedMessageWrapper>
          )}
        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.showConfirmDialog()}
          statusName={showConfirmDialog}
          onEscClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Prajete si odstániť kampaň ?')}
            onDismiss={() => this.showConfirmDialog()}
            onConfirm={() => this.deleteCampaign()}
            dismissMessage={error}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showConfirmDialogSendAprroval}
          small
          onClose={() => this.showConfirmDialogSendAprroval()}
          statusName={showConfirmDialogSendAprroval}
          onEscClose={() =>
            this.setState({ showConfirmDialogSendAprroval: false })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj si prajete odoslať kampaň na schvalenie? Ďalšie úpravy už nebudú možné.',
            )}
            onDismiss={() => this.showConfirmDialogSendAprroval()}
            onConfirm={() => this.sendForAprroval()}
            dismissMessage={error}
            loading={approvalLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showConfirmDialogReject}
          small
          onClose={() => this.showConfirmDialogReject()}
          statusName={showConfirmDialogReject}
          onEscClose={() => this.setState({ showConfirmDialogReject: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete zamietnuť túto kampaň?')}
            onDismiss={() => this.showConfirmDialogReject()}
            additionalContent={
              <TextareaWrapper>
                <CustomTextArea
                  value={description}
                  placeholder={__('Dôvod zamietnutia')}
                  onChange={e =>
                    this.setState({
                      description: e.target.value,
                    })
                  }
                />
              </TextareaWrapper>
            }
            onConfirm={() => this.sendAprrovalDecision('rejected')}
            dismissMessage={error}
            loading={approvalLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showConfirmDialogAprroval}
          small
          onClose={() => this.showConfirmDialogAprroval()}
          statusName={showConfirmDialogAprroval}
          onEscClose={() => this.setState({ showConfirmDialogAprroval: false })}
        >
          <ConfirmDialog
            message={`${__('Naozaj si prajete schváliť túto kampaň?')} ${
              campaign && campaign.campaign_alternative === 'M'
                ? __('Budú vygenerované zľavové kódy.')
                : ''
            }`}
            onDismiss={() => this.showConfirmDialogAprroval()}
            onConfirm={() => this.sendAprrovalDecision('approved')}
            dismissMessage={error}
            loading={approvalLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={newCampaingId}
          message={__('Kópia kampane')}
          onClose={() => this.setState({ newCampaingId: null })}
          statusName={newCampaingId}
          onEscClose={() => this.setState({ newCampaingId: null })}
        >
          <ConfirmDialog
            message={__(
              'Kópia kampane bol vytvorená. Chcete prejsť na kópiu kampane?',
            )}
            onDismiss={() => this.setState({ newCampaingId: null })}
            onConfirm={() => this.redirectToCopyCampaign(newCampaingId)}
          />
        </PopUpWrapper>

        <AddCampaign
          firm={this.props.firm}
          history={this.props.history}
          callback={async () => await this.fetchCampaingData()}
          campaignId={getIdFromProps(this.props)}
          status={
            campaign && campaign.approval_request
              ? campaign.approval_request.status
              : 'for_approval'
          }
          readOnly={
            campaign &&
            campaign.approval_request &&
            campaign.approval_request.status === 'pending'
          }
          approved_by={
            campaign &&
            campaign.approval_request &&
            campaign.approval_request.approved_by
          }
          approved_date={
            campaign &&
            campaign.approval_request &&
            campaign.approval_request.updated_date
          }
        />
      </Wrapper>
    );
  }
}
CampaignDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
  userData: PropTypes.shape({}),
};

CampaignDetail.defaultProps = {
  userData: { firm: 'SK' },
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(state => mapStateToProps(state))(CampaignDetail);
