import React from 'react';
import PropTypes from 'prop-types';
import { Button, Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import {
  ButtonRelativeWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import productsCanals from '../../ContentConfig/productsCanals';
import CheckBoxesTree from '../../Components/CheckBoxesTree';
import TabsSwitch from '../../Components/LanguageSwitch';

class Publication extends React.Component {
  selectTree() {
    const { publications, selectedCanal } = this.props;

    const publicationChanel = publications.find(
      p => p.chanel_id === selectedCanal,
    );
    if (publicationChanel) {
      return publications.find(p => p.chanel_id === selectedCanal).items;
    }
    return [];
  }

  handleChange(id, lang) {
    const { handlePublicationChange, selectedCanal } = this.props;
    handlePublicationChange(id, lang, selectedCanal);
  }

  render() {
    const {
      changeLanguageForPublications,
      activeLanguage,
      saveCard,
      loading,
      switchChanel,
      selectedCanal,
    } = this.props;

    return (
      <React.Fragment>
        <TabsSwitch
          onChange={id => {
            return switchChanel(id);
          }}
          activeId={selectedCanal}
          values={productsCanals}
        />
        <TabsSwitch
          onChange={id => {
            return changeLanguageForPublications(id);
          }}
          activeId={activeLanguage}
        />
        {loading ? (
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        ) : (
          <CheckBoxesTree
            tree={this.selectTree(activeLanguage)}
            selected={
              this.props.selectedCategories
                .find(s => s.chanel === selectedCanal)
                .values.find(v => v.lang === activeLanguage) &&
              this.props.selectedCategories
                .find(s => s.chanel === selectedCanal)
                .values.find(v => v.lang === activeLanguage).selected
            }
            onChange={id => this.handleChange(id, activeLanguage)}
          />
        )}
        <ButtonRelativeWrapper>
          <Button onClick={() => saveCard()} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

Publication.propTypes = {
  handlePublicationChange: PropTypes.func.isRequired,
  changeLanguageForPublications: PropTypes.func,
  selectedCanal: PropTypes.string,
  loading: PropTypes.bool,
  saveCard: PropTypes.func.isRequired,
  activeLanguage: PropTypes.string,
  selectedCategories: PropTypes.shape({
    SK: PropTypes.arrayOf(PropTypes.string),
  }),
  publications: PropTypes.arrayOf({}).isRequired,
  switchChanel: PropTypes.func.isRequired,
};

Publication.defaultProps = {
  selectedCategories: [],
  changeLanguageForPublications: () => {},
  activeLanguage: 'SK',
  selectedCanal: 'CRM',
  loading: false,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Publication);
