import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDragListView from 'react-drag-listview';
import { rem } from 'polished';
import { Button, Icon } from 'oxyrion-ui/lib';
import TableV3 from '../../Components/TableV3';
import { connect } from '../../Store';
import { renderLink, __ } from '../../Utils';
import {
  ButtonRelativeWrapper,
  DHeader,
  P,
  ClickableIconWrapper,
} from '../../Components/ReusableComponents';
import SearchableSelect from '../../Components/SearchableSelect';
import { searchCatalogProducts } from '../../Utils/ApiSearchFunctions';
import API2 from '../../API2';

const SearchableSelectWrapper = styled.div`
  width: ${rem(500)};
`;

const AddNewRow = styled.div`
  margin-top: ${rem(20)};
  display: flex;
  flex-direction: row;
`;

const PlusWrapper = styled.div`
  height: ${rem(34)};
  width: ${rem(34)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

class RelatedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      productToAdd: undefined,
      productErrorRelatedProduct: false,
      tableData: this.props.relatedProducts || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.relatedProducts !== this.props.relatedProducts) {
      this.setState({
        tableData: this.props.relatedProducts,
      });
    }
  }

  fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 100,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handleOnChange(value) {
    let { productToAdd } = this.state;

    productToAdd = value;

    this.setState({ productToAdd, productErrorRelatedProduct: false });
  }

  createColumns() {
    return [
      {
        accessor: '',
        Header: () => <DHeader>{__('Zmena poradia')}</DHeader>,
        Cell: () => {
          return <P>{__('Presuň')}</P>;
        },
        width: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov produktu')}</DHeader>,
        Cell: props => {
          return (
            <P>{renderLink(`./products/${props.original._id}`, props.value)}</P>
          );
        },
      },
      {
        accessor: 'type',
        Header: () => <DHeader>{__('Typ výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'brand',
        Header: () => <DHeader>{__('Značka výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: '_id',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              className="ignore-draggable"
              onClick={() => this.props.deleteRelatedProduct(props.value)}
            >
              <Icon name="close" size="l" color="black" />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
    ];
  }

  addProduct() {
    const { addRelatedProduct } = this.props;
    const { productToAdd } = this.state;

    if (!productToAdd) {
      this.setState({
        productErrorRelatedProduct: __('Nevyplnená hodnota'),
      });
      return;
    }

    addRelatedProduct({
      name: productToAdd.label,
      _id: productToAdd.value,
      type: productToAdd.raw.type,
      brand: productToAdd.raw.brand,
    });

    this.setState({
      productToAdd: undefined,
    });
  }

  tableBackgroundColor(index) {
    if (index % 2 !== 0) {
      return 'white';
    }
    return '#eee';
  }

  render() {
    const { loading, saveCard } = this.props;
    const { productToAdd, productErrorRelatedProduct, tableData } = this.state;

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...tableData];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({
          tableData: data,
        });
      },
      nodeSelector: 'div.rt-tr-group',
      handleSelector: 'p',
      ignoreSelector: 'div.ignore-draggable',
    };

    return (
      <React.Fragment>
        <ReactDragListView {...dragProps}>
          <TableV3
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: this.tableBackgroundColor(rowInfo.index),
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
            columns={this.createColumns()}
            minWidth={10}
            loading={loading}
            noDataText={__('Žiadne súvisiace produkty')}
            data={tableData}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: () => {},
                };
              }
              return {};
            }}
            className="-highlight -striped"
          />
        </ReactDragListView>
        <AddNewRow>
          <SearchableSelectWrapper>
            <SearchableSelect
              value={productToAdd}
              keyProps={JSON.stringify(tableData)}
              loadOptions={query => searchCatalogProducts(query)}
              placeholder={__('Zadajte kód alebo názov tovaru')}
              name={productToAdd}
              error={productErrorRelatedProduct}
              handleOnChange={e => this.handleOnChange(e)}
            />
          </SearchableSelectWrapper>
          <PlusWrapper onClick={() => this.addProduct()}>
            <Icon name="plus" size="l" />
          </PlusWrapper>
        </AddNewRow>

        <ButtonRelativeWrapper>
          <Button onClick={() => saveCard(tableData)} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

RelatedProducts.propTypes = {
  firm: PropTypes.string,
  deleteRelatedProduct: PropTypes.func.isRequired,
  saveCard: PropTypes.func.isRequired,
  addRelatedProduct: PropTypes.func,
  loading: PropTypes.bool,
  relatedProducts: PropTypes.arrayOf({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
};

RelatedProducts.defaultProps = {
  loading: false,
  relatedProducts: [],
  addRelatedProduct: () => {},
  firm: 'SK',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(RelatedProducts);
