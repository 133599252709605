import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Tabs, ThemeAdmin } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import ColorCategories from './ColorCategories';
import ProductsLinker from './ProductsLinker';
import ProductsProperties from './ProductsProperties';

const MainWrapper = styled.div`
  padding-bottom: ${rem(100)};
`;

class InnovatintImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'tab2',
    };
  }

  renderControlBar() {
    const { history } = this.props;

    return <ControllBar history={history} name={__('Innovatint import')} />;
  }

  render() {
    const { loading, activeTab } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <MainWrapper>
        {this.renderControlBar()}
        <Tabs
          color={ThemeAdmin.color.primary}
          onChange={to => {
            this.setState({
              activeTab: to,
            });

            return true;
          }}
          activeTab={{
            id: activeTab,
          }}
        >
          <Tabs.Tab id="tab1" title={__('Kategórie odtieňov')}>
            <ColorCategories history={this.props.history} />
          </Tabs.Tab>
          <Tabs.Tab id="tab2" title={__('Linkovanie produktov')}>
            <ProductsLinker history={this.props.history} />
          </Tabs.Tab>
          <Tabs.Tab id="tab3" title={__('Vlastnosti produktov')}>
            <ProductsProperties history={this.props.history} />
          </Tabs.Tab>
        </Tabs>
      </MainWrapper>
    );
  }
}

InnovatintImport.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(InnovatintImport);
