import React from 'react';
import { Route } from 'react-router-dom';
import BrandPortfolio from './BrandPortfolio';
import CompanyAboutUsContact from '../../Containers/AboutUsContact';

function Dejmark(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.url}/brand-portfolio`} component={BrandPortfolio} />
      <Route path={`${match.url}/about-us`} component={CompanyAboutUsContact} />
    </React.Fragment>
  );
}

export default Dejmark;
