import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 300 },
  },
  {
    name: __('Marketingový zoznam	'),
    // clickable: true,
    // sorterValue: 'marketing_list',
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Vlastník'),
    clickable: true,
    sorterValue: 'owner',
    otherProps: { minWidth: 125 },
  },
  {
    name: __('Typ'),
    clickable: true,
    sorterValue: 'type',
  },
  {
    name: __('Status'),
    clickable: true,
    sorterValue: 'status',
    style: { backgroundColor: 'red' },
  },
  {
    name: __('Emaily'),
    sorterValue: 'number_of_emails',
  },
  {
    name: __('Čísla'),
    sorterValue: 'number_of_numbers',
  },
  {
    name: __('Otvorenia'),
    sorterValue: 'number_of_times_opened',
  },
  {
    name: __('Kliknutia'),
    sorterValue: 'number_of_times_clicked',
  },
  {
    name: __('Odhlásenia'),
    sorterValue: 'number_of_unsubscriptions',
  },
  {
    name: __('Naplánované odoslanie'),
    clickable: true,
    sorterValue: 'scheduled_date',
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Odoslané'),
    clickable: true,
    sorterValue: 'send_date',
    otherProps: { minWidth: 125 },
  },
  {
    name: __('Vytvorené'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: { minWidth: 125 },
  },
  {
    name: __('Upravené'),
    clickable: true,
    sorterValue: 'updated_date',
    otherProps: { minWidth: 125 },
  },
];