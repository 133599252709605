import { __ } from '../Utils';

export default [
  {
    key: 1,
    value: 'waterborne',
    display_name: __('Vodouriediteľná'),
  },
  {
    key: 2,
    value: 'solvent',
    display_name: __('Rozpúšťadlová'),
  },
  {
    key: 2,
    value: 'hybrid',
    display_name: __('Hybridná'),
  },
];
