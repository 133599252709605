import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'oxyrion-ui/lib/Icon';
import Button from 'oxyrion-ui/lib/Button';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import { rem } from 'polished';
import { Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: ${rem(12)};
`;

const TypeWrapper = styled.div`
  margin-bottom: ${rem(4)};
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${rem(30)};
  background: #eee;
  border-radius: ${rem(4)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${rem(12)};
  font-weight: bold;

  :hover {
    text-decoration: underline;
    background: #ddd;
  }

  .icon-wrapper {
    transform: ${props => (props.rotateIcon ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

const ParamsWrapper = styled.div`
  display: flex;
`;
const ParamWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(40)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};

  .name {
    width: ${rem(300)};
    display: flex;
    text-align: flex-start;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(20)};
`;

const MainParams = styled.div`
  width: 50%;
`;

const VariantParams = styled.div`
  width: 50%;
`;

const Title = styled.div`
  padding: ${rem(12)};
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
`;

class ProductTypesFarleskExportSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productTypes: [],
      openTypes: [],
      changedData: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  componentWillUnmount() {
    this.setState({
      productTypes: [],
      openTypes: [],
      changedData: [],
      saveLoading: false,
      loading: false,
    });
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const paramsResult = await AdminAPI.getProductTypesAction();

      this.setState({
        productTypes: paramsResult.product_types
          ? paramsResult.product_types.filter(
              p => p.params && p.params.length > 0,
            )
          : [],
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  }

  toggleType(id) {
    const { openTypes } = this.state;
    if (openTypes.includes(id)) {
      this.setState({
        openTypes: openTypes.filter(o => o !== id),
      });
    } else {
      openTypes.push(id);
      this.setState({
        openTypes,
      });
    }
  }

  handleParamChange(typeId, paramId, variantParam = false, value) {
    const { productTypes, changedData } = this.state;

    if (!variantParam) {
      productTypes
        .find(t => t._id === typeId)
        .params.find(p => p._id === paramId).export_to_farlesk = value;
    } else {
      productTypes
        .find(t => t._id === typeId)
        .variant_params.find(p => p._id === paramId).export_to_farlesk = value;
    }

    if (changedData.find(c => c.type_id === typeId && c.param_id === paramId)) {
      changedData.find(
        c => c.type_id === typeId && c.param_id === paramId,
      ).value = value;
    } else {
      changedData.push({
        type_id: typeId,
        param_id: paramId,
        variant_param: variantParam,
        value,
      });
    }

    this.setState({
      productTypes,
      changedData,
    });
  }

  async saveData() {
    const { changedData } = this.state;
    const { onSave } = this.props;

    this.setState({
      saveLoading: true,
    });

    await onSave(changedData);

    this.setState({
      saveLoading: false,
    });
  }

  dissmissChanges() {
    const { onDissmiss } = this.props;
    const { changedData } = this.state;

    onDissmiss(changedData);
  }

  render() {
    const {
      loading,
      productTypes,
      openTypes,
      changedData,
      saveLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <Wrapper>
          {productTypes.map(type => (
            <TypeWrapper key={type._id}>
              <NameWrapper
                onClick={() => this.toggleType(type._id)}
                rotateIcon={openTypes.includes(type._id)}
              >
                <div className="name">{type._id.replace('_', ' ')}</div>
                <div className="icon-wrapper">
                  <Icon name="arrow-down" />
                </div>
              </NameWrapper>
              {openTypes.includes(type._id) && (
                <ParamsWrapper>
                  <MainParams>
                    <Title>{__('Hlavné parametre')}</Title>
                    {type.params.map(param => (
                      <ParamWrapper key={param._id}>
                        <div className="name">{param.name}</div>
                        <div className="checkbox-wrapper">
                          <LabeledCheckbox
                            labelText={__('Exportovať')}
                            checked={param.export_to_farlesk}
                            onChange={e =>
                              this.handleParamChange(
                                type._id,
                                param._id,
                                false,
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                      </ParamWrapper>
                    ))}
                  </MainParams>
                  <VariantParams>
                    <Title>{__('Parametre variant')}</Title>
                    {type.variant_params.map(param => (
                      <ParamWrapper key={param._id}>
                        <div className="name">{param.name}</div>
                        <div className="checkbox-wrapper">
                          <LabeledCheckbox
                            labelText={__('Exportovať')}
                            checked={param.export_to_farlesk}
                            onChange={e =>
                              this.handleParamChange(
                                type._id,
                                param._id,
                                true,
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                      </ParamWrapper>
                    ))}
                  </VariantParams>
                </ParamsWrapper>
              )}
            </TypeWrapper>
          ))}
          <ButtonsWrapper>
            <Button onClick={() => this.dissmissChanges()} danger>
              {__('Zrušiť')}
            </Button>
            <Button
              disabled={changedData.length === 0}
              onClick={() => this.saveData()}
              loading={saveLoading}
              primary
            >
              {__('Uložiť')}
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

ProductTypesFarleskExportSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onDissmiss: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductTypesFarleskExportSettings);
