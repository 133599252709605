import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button, Icon } from 'oxyrion-ui/lib';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/saleProducts';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { __, formatDate, FORMAT_HUMANE_DATETIME } from '../../Utils';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { connect } from '../../Store';
import AddSaleProducts from '../../Components/AddSaleProducts';
import EditSaleProduct from '../../Components/EditSaleProduct';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';
import SearchableSelect from '../../Components/SearchableSelect';
import { searchCatalogProducts } from '../../Utils/ApiSearchFunctions';

const FilterTitle = styled.div`
  margin-left: ${rem(6)};
  margin-right: ${rem(6)};
`;

const ErrorMessage = styled.div`
  padding: ${rem(16)};
  margin-bottom: ${rem(16)};
  background-color: pink;
  color: red;
`;
const PopUpContentWrapper = styled.div`
  width: ${rem(1100)};
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  box-sizing: content-box;
  padding: ${rem(8)};
`;

const SearchProductWrapper = styled.div`
  margin-right: ${rem(40)};
  width: ${rem(250)};
`;
class SalesProductsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('');
    this.accessors = [
      'name',
      'product_nav_id',
      // 'valid_from',
      // 'valid_to',
      'deactivated',
      'pub_xpaint',
      'pub_portal',
      'discount_percent',
      'price',
      'edit',
    ];
    this.showBulkOperations = true;
    this.fixedHeader = true;
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      currentFirm: 'SK',
      active: 'Všetky',
      type: 'Všetky',
      lastSearch: '',
      showAddNewProducts: false,
      showDeactivateProducts: false,
      addingSaleProductsLoading: false,
      deactivatingSaleProductsLoading: false,
      editProductId: null,
      selectedVariant: null,
    };
  }
  async deactivateProducts() {
    const { checkedRows } = this.state;
    this.setState({ deactivatingSaleProductsLoading: true });
    const params = {};
    params.body = {
      ids: checkedRows,
    };
    const total = await AdminAPI.putSaleProductsDeactivateAction(params);
    this.setState({
      deactivatingSaleProductsLoading: false,
      showDeactivateProducts: false,
      productsToDeactivate: [],
      success: `${__('Bolo úspešne deaktivovaných ')} ${total || 0} ${__(
        'produktov.',
      )}`,
    });
    this.fetchData();
  }
  async addSaleProducts(productsToAdd, settings) {
    const newSaleProducts = productsToAdd.map((p) =>
      Object.assign(p, {
        discount_percent: parseFloat(p.discount_percent),
        default_price: parseFloat(p.default_price),
        price: parseFloat(p.price),
        firm: p.firm,
        // valid_from: settings.valid_from,
        // valid_to: settings.valid_to,
      }),
    );
    this.setState({ addingSaleProductsLoading: true });
    const params = {};
    params.body = {
      sale_products: newSaleProducts,
    };
    const total = await AdminAPI.postSaleProductsAction(params);
    this.setState({
      addingSaleProductsLoading: false,
      showAddNewProducts: false,
      success: `${__('Bolo úspešne vytvorených ')} ${total || 0} ${__(
        'produktov.',
      )}`,
    });
    this.fetchData();
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, lastSearch, currentFirm, active, selectedVariant, type } =
        this.state;
      this.setState({
        loading: true,
        checkedRows: [],
      });
      const params = Object.assign({}, parameters, { sorter });
      if (!params.q) {
        params.q = lastSearch;
      }
      params.firm = currentFirm;
      if (active === 'Všetky') {
        params.active = undefined;
      } else if (active === 'Aktívne') {
        params.active = true;
      } else if (active === 'Deaktivované') {
        params.active = false;
      }

      if (type === __('Výpredaj WH010')) {
        params.type = 'sale';
      } else if (type === __('Dopredaj')) {
        params.type = 'clearance';
      }

      if (selectedVariant && selectedVariant.value) {
        params.catalogProductId = selectedVariant.value;
      }
      const newData = await AdminAPI.getSaleProductsAction(params);
      this.setState({
        content: this.normalizeColumns(newData.items),
        items: newData.items,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        error: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  addProductToDeactivate(no) {
    const { productsToDeactivate } = this.state;
    if (productsToDeactivate.includes(no)) {
      productsToDeactivate.filter((p) => p !== no);
    } else {
      productsToDeactivate.push(no);
    }
    this.setState({ productsToDeactivate });
  }
  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }
  hideShowAddNewProducts() {
    this.setState({ showAddNewProducts: false });
  }
  hideShowEditProduct() {
    this.setState({ editProductId: null });
  }
  formatPrice(price, firm) {
    if (firm === 'SK') {
      return `${price || '-'} €`;
    }
    if (firm === 'CZ') {
      return `${price || '-'} Kč`;
    }
    if (firm === 'AT') {
      return `${price || '-'} €`;
    }
    if (firm === 'HU') {
      return `${price || '-'} Ft`;
    }
    return price;
  }
  formatPercentage(percentage) {
    return `${percentage || '-'} %`;
  }
  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || value === 0 ? value : placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const _editButton = (id, deactivated) => {
      return {
        value: (
          <ButtonWrapper>
            <Button
              loading={null}
              primary
              disabled={deactivated}
              small
              onClick={() => {
                this.setState({ editProductId: id });
              }}
            >
              {__('Upraviť')}
            </Button>
          </ButtonWrapper>
        ),
        handleOnClick: null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(item.name),
        product_nav_id: _shapeData(item.product_nav_id),
        // valid_from: _shapeData(
        //   formatDate(item.valid_from, FORMAT_HUMANE_DATETIME),
        // ),
        // valid_to: _shapeData(formatDate(item.valid_to, FORMAT_HUMANE_DATETIME)),
        deactivated: _shapeData(item.deactivated ? __('Áno') : __('Nie')),
        pub_xpaint: _shapeData(
          item.published && item.published.includes('xPaint') ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        pub_portal: _shapeData(
          item.published && item.published.includes('Portal') ? (
            <Icon name="done" color="green" />
          ) : (
            <Icon name="close" color="red" size="l" />
          ),
        ),
        price: _shapeData(this.formatPrice(item.price, item.firm)),
        discount_percent: _shapeData(
          this.formatPercentage(item.discount_percent),
        ),
        edit: _editButton(
          item._id,
          item.deactivated || item.catalog_product_id === null,
        ),
        created_date: _shapeData(
          formatDate(item.created_date, FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }
  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }
  handleFirmChange(newFirm) {
    this.setState(
      {
        currentFirm: newFirm,
        checkedRows: [],
      },
      () => {
        this.fetchData();
      },
    );
  }

  handleTypeChange(type, text) {
    this.setState(
      {
        type: __(text),
      },
      () => {
        this.fetchData();
      },
    );
  }

  handleActiveChange(status, text) {
    this.setState(
      {
        active: __(text),
      },
      () => {
        this.fetchData();
      },
    );
  }
  addNewProducts() {
    this.setState({ showAddNewProducts: true });
  }

  handleSelectedVariant(e) {
    this.setState({ selectedVariant: e }, () => {
      this.fetchData();
    });
  }

  async editSaleProduct(product) {
    const editedProduct = Object.assign({}, product);
    editedProduct.price = Number(product.price);
    editedProduct.default_price = Number(product.default_price);
    editedProduct.discount_percent = Number(product.discount_percent);
    editedProduct.published = product.published;
    const params = {};
    params.body = {
      ...editedProduct,
    };
    await AdminAPI.putSaleProductAction(this.state.editProductId, params);
    this.setState({ editProductId: null });
    this.fetchData();
  }
  renderControlBar() {
    const { history } = this.props;
    const {
      lastSearchValue,
      currentFirm,
      active,
      type,
      showDeactivateProducts,
      deactivatingSaleProductsLoading,
      checkedRows,
      selectedVariant,
    } = this.state;
    return (
      <div>
        <PopUpWrapper
          display={showDeactivateProducts}
          large
          titleZIndex={1000}
          customWidth={1200}
          noMinHeight={2500}
          message={__('Deaktivácia vypredajových produktov')}
          onClose={() =>
            this.setState({
              showDeactivateProducts: false,
            })
          }
          onEscClose={() =>
            this.setState({
              showDeactivateProducts: false,
            })
          }
        >
          <PopUpContentWrapper>
            <ErrorMessage>
              {`${__('Bude deaktivovaných')} ${checkedRows.length} ${__(
                'produktov vo výpredaji. Naozaj chcete pokračovať?',
              )}`}
            </ErrorMessage>

            <ActionButtons>
              <Button
                loading={null}
                onClick={() => this.setState({ showDeactivateProducts: false })}
                secondary
              >
                {__('Zrušiť')}
              </Button>
              <Button
                loading={deactivatingSaleProductsLoading}
                onClick={() => this.deactivateProducts()}
                primary
              >
                {__('Deaktivovať')}
              </Button>
            </ActionButtons>
          </PopUpContentWrapper>
        </PopUpWrapper>
        <AddSaleProducts
          addSaleProducts={(products, settings) =>
            this.addSaleProducts(products, settings)
          }
          country={this.props.firm}
          hideShowAddNewProducts={() => this.hideShowAddNewProducts()}
          showAddNewProducts={this.state.showAddNewProducts}
          addingSaleProductsLoading={this.state.addingSaleProductsLoading}
        />
        <EditSaleProduct
          editProductId={this.state.editProductId}
          editSaleProduct={(product) => this.editSaleProduct(product)}
          hideAndShowEditProduct={() => this.hideShowEditProduct()}
          // addingSaleProductsLoading={this.state.addingSaleProductsLoading}
        />
        <ControllBar
          history={history}
          name={__('Výpredajové produkty')}
          defaultValue={lastSearchValue}
          onChange={(val) => this.loadSearchResult(val)}
        >
          {checkedRows && checkedRows.length !== 0 && (
            <ControlBarButton
              primary
              small
              icon="close"
              onClick={() => this.setState({ showDeactivateProducts: true })}
            >
              {__('Deaktivovať vybrané')}
            </ControlBarButton>
          )}
          <SearchProductWrapper>
            <SearchableSelect
              value={selectedVariant}
              loadOptions={(query) => searchCatalogProducts(query)}
              placeholder={__('Vyhľadať produkt')}
              name={null}
              handleOnChange={(e) => this.handleSelectedVariant(e)}
              // defaultOptions={variants}
            />
          </SearchProductWrapper>
          <FilterTitle>{__('Krajina')}:</FilterTitle>
          <ControlButton name={currentFirm}>
            <LinkWrap>
              <div
                onClick={(e) => this.handleFirmChange('SK', e.target.innerHTML)}
              >
                {__('SK')}
              </div>
              <div
                onClick={(e) => this.handleFirmChange('CZ', e.target.innerHTML)}
              >
                {__('CZ')}
              </div>
              <div
                onClick={(e) => this.handleFirmChange('AT', e.target.innerHTML)}
              >
                {__('AT')}
              </div>
              <div
                onClick={(e) => this.handleFirmChange('HU', e.target.innerHTML)}
              >
                {__('HU')}
              </div>
            </LinkWrap>
          </ControlButton>
          <FilterTitle>{__('Status')}:</FilterTitle>
          <ControlButton name={active}>
            <LinkWrap>
              <div
                onClick={(e) =>
                  this.handleActiveChange(undefined, e.target.innerHTML)
                }
              >
                {__('Všetky')}
              </div>
              <div
                onClick={(e) =>
                  this.handleActiveChange(true, e.target.innerHTML)
                }
              >
                {__('Aktívne')}
              </div>
              <div
                onClick={(e) =>
                  this.handleActiveChange(false, e.target.innerHTML)
                }
              >
                {__('Deaktivované')}
              </div>
            </LinkWrap>
          </ControlButton>
          <FilterTitle>{__('Typ')}:</FilterTitle>
          <ControlButton name={type}>
            <LinkWrap>
              <div
                onClick={(e) =>
                  this.handleTypeChange('sale', e.target.innerHTML)
                }
              >
                {__('Výpredaj WH010')}
              </div>
              <div
                onClick={(e) =>
                  this.handleTypeChange('clearance', e.target.innerHTML)
                }
              >
                {__('Dopredaj')}
              </div>
              <div
                onClick={(e) => this.handleTypeChange(null, e.target.innerHTML)}
              >
                {__('Všetky')}
              </div>
            </LinkWrap>
          </ControlButton>
          <ControlBarButton
            primary
            small
            onClick={() => this.addNewProducts()}
            icon="plus"
          >
            {__('Vytvoriť')}
          </ControlBarButton>
        </ControllBar>
      </div>
    );
  }
  handleNewOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/products-packages/${id}`);
  }
  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(SalesProductsList);
