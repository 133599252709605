import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../Store';
import { __, getIdFromProps } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import styled from 'styled-components';
import {
  ErrorWrapper,
  LoaderWrapper,
  StyledInput,
  StyledLabel,
} from '../../Components/ReusableComponents';
import {
  Button,
  Checkbox,
  Loader,
  Message,
  Select,
  Textarea,
} from 'oxyrion-ui/lib';
import { rem } from 'polished';
import SearchableSelect from '../../Components/SearchableSelect';
import DateAndTimerPicker from '../../Components/DateAndTimePicker';
import moment from 'moment';
import API2 from '../../API2';
import AdminAPI from '../../AdminAPI';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';
import StyledDatePicker from '../../Components/DatePicker';

const Wrapper = styled.div`
  padding: ${rem(8)};
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

const InputWrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  gap: ${rem(12)};
  align-items: center;
  margin-top: ${rem(12)};
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(24)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(380)};
`;

class CRMNotificationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
      typesCodelist: [],
      codelistLoading: false,
      autoNotificationTypesCodelist: [],
      cyclicIntervalsCodelist: [],
      showConfirmDialog: false,
      deleteLoading: false,
      isCyclic: false,
      notificationData: {
        name: '',
        type: '',
        published_country: 'SK',
        users: [],
        text: '',
        send_date: new Date(),
        auto_notification_type: '',
        cyclicData: {
          cyclic_interval: '',
          end_cyclic_date: null,
        },
      },
    };
  }

  async componentWillMount() {
    await this.fetchTypes();
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      await this.fetchData(id);
    } else {
      this.setState({ loading: false });
    }
  }

  async fetchData(id) {
    this.setState({
      loading: true,
    });

    try {
      const data = await API2.getNotificationAction(this.props.firm, id);

      const normalizedData = await this.normalizeData(data);

      this.setState({
        notificationData: normalizedData,
      });
    } catch (e) {
      switch (e.response.status) {
        case 404:
          this.setState({
            error: __('Notifikáciu sa nepodarilo sa nenašla'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async normalizeData(data) {
    if (data.users && data.users.length > 0) {
      const normalizedUsers = [];

      for (const customer of data.users) {
        if (customer === 'all') {
          normalizedUsers.push({
            label: __('Všetci'),
            value: customer,
          });
        } else {
          const user = await API2.getUserAction(customer);

          normalizedUsers.push({
            label: user.display_name,
            value: user._id,
            raw: user,
          });
        }
      }

      data.users = normalizedUsers;
    }

    if (data.cyclicData) {
      this.setState({
        isCyclic: data.cyclicData.isCyclic,
      });
    }

    return data;
  }

  validateTextInputs(data) {
    const { isCyclic } = this.state;
    let filled = true;
    const requiredFields = [
      'name',
      'type',
      'published_country',
      'users',
      'text',
      data.type === 'Automatické' ? 'auto_notification_type' : 'send_date',
    ];

    const isInvalidField = (field) =>
      !field || field === '' || field === '-' || field.length === 0;

    if (isCyclic) {
      const { cyclicData } = data;

      if (!cyclicData || isInvalidField(cyclicData.cyclic_interval)) {
        filled = false;
      }
    }

    if (data.type === 'Automatické') {
      delete data.send_date;
    } else {
      delete data.auto_notification_type;
    }

    Object.keys(data)
      .filter((k) => requiredFields.includes(k))
      .map((key) => {
        if (isInvalidField(data[key])) {
          filled = false;
        }
        return true;
      });
    return filled;
  }

  normalizeDataForApi(notificationData) {
    const { isCyclic } = this.state;

    const isAllUsersSelected = notificationData.users.filter(
      (customer) => customer.value === 'all',
    );

    if (notificationData.type === 'Automatické') {
      delete notificationData.send_date;
    } else {
      delete notificationData.auto_notification_type;
    }

    if (isCyclic) {
      notificationData = {
        ...notificationData,
        cyclicData: {
          ...notificationData.cyclicData,
          isCyclic: isCyclic,
        },
      };
    } else {
      const { cyclicData, ...data } = notificationData;
      notificationData = data;
    }


    return {
      data: {
        ...notificationData,
        users:
          isAllUsersSelected.length > 0
            ? isAllUsersSelected.map((customer) => customer.value)
            : notificationData.users.map((customer) => customer.value),
      },
    };
  }

  async saveNotification() {
    const { notificationData } = this.state;
    const { history, firm } = this.props;

    this.setState({ loading: true });

    const params = {};

    if (!this.validateTextInputs(notificationData)) {
      this.setState({
        error: __('Musíte vyplniť všetky polia'),
        loading: false,
      });
      return;
    }
    params.body = this.normalizeDataForApi(notificationData);

    try {
      if (notificationData._id) {
        await API2.putNotificationAction(firm, notificationData._id, params);

        this.setState({
          loading: false,
          error: false,
          success: __('Notifikácia bola úspešne upravená'),
        });
      } else {
        const createNotification = await API2.postNotificationAction(
          firm,
          params,
        );

        history.push(`/${firm}/crm-notifications/${createNotification._id}`);

        this.setState({
          loading: false,
          error: false,
          success: __('Notifikácia bola úspešne vytvorená'),
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Notifikáciu sa nepodarilo upraviť alebo vytvoriť'),
        success: false,
        loading: false,
      });
    }
  }

  handleNotificationDataChange(e, field) {
    const { notificationData } = this.state;

    if (field === 'users') {
      notificationData.users = e;
    } else if (field === 'send_date') {
      notificationData.send_date = e;
    } else if (field === 'end_cyclic_date') {
      notificationData.cyclicData.end_cyclic_date = e;
    } else if (field === 'cyclic_interval') {
      notificationData.cyclicData.cyclic_interval = e.target.value;
    } else {
      notificationData[field] = e.target.value;
    }

    this.setState({
      notificationData,
    });
  }

  async fetchTypes() {
    this.setState({
      codelistLoading: true,
    });

    try {
      const [notificationTypes, autoNotificationTypes, cyclicIntervals] =
        await Promise.all([
          AdminAPI.getCodelist('notification_types'),
          AdminAPI.getCodelist('auto_notifications_types'),
          AdminAPI.getCodelist('cyclic_interval_notifications'),
        ]);

      this.setState({
        codelistLoading: false,
        typesCodelist: notificationTypes.codelist || [],
        cyclicIntervalsCodelist: cyclicIntervals.codelist || [],
        autoNotificationTypesCodelist: autoNotificationTypes.codelist || [],
      });
    } catch (e) {
      console.log(e);
      this.setState({
        codelistLoading: false,
        typesCodelist: [],
        autoNotificationTypesCodelist: [],
      });
    }
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 15,
      }).then((res) => {
        const users = res.users.map((item) => ({
          value: item._id,
          label: item.display_name,
          raw: item,
        }));

        return [{ value: 'all', label: __('Všetci') }, ...users];
      });
    } catch (e) {
      return [];
    }
  }

  async deleteNotification() {
    this.setState({
      deleteLoading: true,
    });

    try {
      await API2.deleteNotificationAction(
        this.props.firm,
        getIdFromProps(this.props),
      );
      this.setState({
        showConfirmDialog: false,
      });
      this.props.history.push(`/${this.props.firm}/crm-notifications`);
    } catch (e) {
      switch (e.response.status) {
        case 404:
          this.setState({
            error: __('Notifikáciu sa nepodarilo sa nenašla'),
          });
          break;
        case 403:
          this.setState({
            error: __('Nemáte dostatočné práva na mazanie notifikácií'),
          });
          break;
        default:
          this.setState({
            error: __('Pri mazaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        deleteLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Notifikácia')}>
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      loading,
      error,
      success,
      notificationData,
      typesCodelist,
      cyclicIntervalsCodelist,
      codelistLoading,
      autoNotificationTypesCodelist,
      showConfirmDialog,
      deleteLoading,
      isCyclic,
    } = this.state;

    const countryOptions = ['SK', 'CZ'];

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        {error && (
          <ErrorWrapper>
            <Message message={error} error />
          </ErrorWrapper>
        )}
        {success && (
          <ErrorWrapper>
            <Message message={success} />
          </ErrorWrapper>
        )}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() =>
            this.setState({
              showConfirmDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Prajete si odstániť túto notifikáciu ?')}
            onDismiss={() =>
              this.setState({
                showConfirmDialog: false,
              })
            }
            onConfirm={() => this.deleteNotification()}
            error={error}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          <InputWrapper>
            <StyledLabel> {__('Názov*')}</StyledLabel>
            <StyledInput
              placeholder={__('Zadajte názov')}
              onChange={(e) => this.handleNotificationDataChange(e, 'name')}
              value={notificationData.name}
            />
          </InputWrapper>

          <InputWrapper>
            <StyledLabel> {__('Typ*')}</StyledLabel>
            <SearchableSelectWrapper>
              <Select
                name={__('Typ')}
                size="s"
                onChange={(e) => this.handleNotificationDataChange(e, 'type')}
                selected={notificationData.type}
                loading={codelistLoading}
              >
                <option selected={!notificationData.type} value={undefined}>
                  {'-'}
                </option>
                {typesCodelist.map((type) => (
                  <option
                    key={type._id}
                    selected={type.value === notificationData.type}
                    value={type.value}
                  >
                    {type.label}
                  </option>
                ))}
              </Select>
            </SearchableSelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <StyledLabel> {__('Krajina*')}</StyledLabel>
            <SearchableSelectWrapper>
              <Select
                name={__('Krajina')}
                size="s"
                onChange={(e) => {
                  this.handleNotificationDataChange(e, 'published_country');
                }}
                selected={notificationData.published_country}
              >
                {countryOptions.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </Select>
            </SearchableSelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <StyledLabel> {__('Používatelia*')}</StyledLabel>
            <SearchableSelectWrapper>
              <SearchableSelect
                isMulti
                value={
                  notificationData.users &&
                  notificationData.users.filter(
                    (customer) => customer.value === 'all',
                  ).length > 0
                    ? notificationData.users.filter(
                        (customer) => customer.value === 'all',
                      )
                    : notificationData.users
                }
                loadOptions={(query) => this.fetchUsers(query)}
                placeholder={__('Vyhľadať používateľov')}
                handleOnChange={(e) =>
                  this.handleNotificationDataChange(e, 'users')
                }
              />
            </SearchableSelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <StyledLabel> {__('Text*')}</StyledLabel>
            <Textarea
              style={{
                width: rem(360),
                height: rem(80),
                resize: 'vertical',
                lineHeight: rem(18),
              }}
              placeholder={__('Zadajte text')}
              onChange={(e) => this.handleNotificationDataChange(e, 'text')}
              value={notificationData.text}
            />
          </InputWrapper>

          {notificationData.type === 'Automatické' ? (
            <InputWrapper>
              <StyledLabel> {__('Typ automatickej notifikácie*')}</StyledLabel>
              <SearchableSelectWrapper>
                <Select
                  name={__('Typ')}
                  size="s"
                  onChange={(e) =>
                    this.handleNotificationDataChange(
                      e,
                      'auto_notification_type',
                    )
                  }
                  selected={notificationData.auto_notification_type}
                  loading={codelistLoading}
                >
                  <option
                    selected={!notificationData.auto_notification_type}
                    value={undefined}
                  >
                    {'-'}
                  </option>
                  {autoNotificationTypesCodelist.map((type) => (
                    <option
                      key={type._id}
                      selected={
                        type.value === notificationData.auto_notification_type
                      }
                      value={type.value}
                    >
                      {type.label}
                    </option>
                  ))}
                </Select>
              </SearchableSelectWrapper>
            </InputWrapper>
          ) : (
            <>
              <InputWrapper>
                <StyledLabel> {__('Dátum a čas odoslania*')}</StyledLabel>
                <SearchableSelectWrapper>
                  <DateAndTimerPicker
                    datePlaceHolder={__('Dátum')}
                    timePlaceHolder={__('Čas')}
                    minDate={moment()}
                    value={
                      notificationData.send_date &&
                      moment(notificationData.send_date)
                    }
                    onChange={(e) =>
                      this.handleNotificationDataChange(e, 'send_date')
                    }
                  />
                </SearchableSelectWrapper>
              </InputWrapper>

              <CheckboxWrapper>
                <StyledLabel>{__('Cyklické opakovanie')}</StyledLabel>
                <Checkbox
                  checked={isCyclic}
                  onChange={(e) => this.setState({ isCyclic: !isCyclic })}
                />
              </CheckboxWrapper>

              {isCyclic && (
                <>
                  <InputWrapper>
                    <StyledLabel> {__('Interval opakovania*')}</StyledLabel>
                    <SearchableSelectWrapper>
                      <Select
                        name={__('Interval opakovania')}
                        size="s"
                        onChange={(e) => {
                          this.handleNotificationDataChange(
                            e,
                            'cyclic_interval',
                          );
                        }}
                        selected={
                          notificationData.cyclicData &&
                          notificationData.cyclicData.cyclic_interval
                        }
                      >
                        <option
                          selected={
                            notificationData.cyclicData &&
                            !notificationData.cyclicData.cyclic_interval
                          }
                          value={undefined}
                        >
                          {'-'}
                        </option>
                        {cyclicIntervalsCodelist.map((interval) => (
                          <option
                            key={interval._id}
                            selected={
                              notificationData.cyclicData &&
                              interval.value ===
                                notificationData.cyclicData.cyclic_interval
                            }
                            value={interval.value}
                          >
                            {interval.label}
                          </option>
                        ))}
                      </Select>
                    </SearchableSelectWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel> {__('Konečný dátum*')}</StyledLabel>
                    <SearchableSelectWrapper>
                      <StyledDatePicker
                        minDate={notificationData.send_date || moment()}
                        placeholderText={__('Konečný dátum')}
                        dateFormat="dd DD.MM.YYYY"
                        selected={
                          notificationData.cyclicData &&
                          notificationData.cyclicData.end_cyclic_date &&
                          moment(notificationData.cyclicData.end_cyclic_date)
                        }
                        onChange={(e) =>
                          this.handleNotificationDataChange(
                            e,
                            'end_cyclic_date',
                          )
                        }
                      />
                    </SearchableSelectWrapper>
                  </InputWrapper>
                </>
              )}
            </>
          )}

          <ButtonWrapper>
            <Button
              loading={loading}
              onClick={() => {
                this.saveNotification();
              }}
              primary
            >
              {getIdFromProps(this.props) === 'new'
                ? __('Uložiť')
                : __('Upraviť')}
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

CRMNotificationDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect((state) => mapStateToProps(state))(
  CRMNotificationDetail,
);
