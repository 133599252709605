import * as React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { rem } from 'polished';
import debounce from 'es6-promise-debounce';
import { __ } from '../../Utils';

const SelectWrapper = styled.div`
  width: 100%;
  color: black;
  .Select-control {
    border: ${({ theme }) => `solid ${rem(1)} ${theme.separatorColor}`};
  }
  .css-yk16xz-control {
    ${({ error }) => {
      if (error) {
        return css`
          border: solid 0.0625rem #e94141;
          background: rgba(255, 0, 0, 0.1);
        `;
      }
      return css`
        border: solid 0.0625rem rgba(0, 0, 0, 0.05);
        background: white;
      `;
    }}
  }

  // .css-151xaom-placeholder {
  //   color: #a0a4ae;
  //   font-size: 0.875rem;
  // }
  // .css-1rhbuit-multiValue {
  //   background: #cccccc;
  // }
`;

class SearchableSelect extends React.Component {
  normalizeValue(value) {
    if (Array.isArray(value)) {
      return value;
    }

    if (!value) {
      return [];
      // return value;
    }

    if (!value.value && value) {
      // return [];
      return {
        value,
        label: value,
      };
    }

    return value;
  }

  async resolve(query) {
    const result = await this.props.loadOptions(query);

    return result;
  }

  render() {
    const {
      name,
      placeholder,
      handleOnChange,
      value,
      disabled,
      isMulti,
      error,
      autoload,
      keyProps,
    } = this.props;

    const normalizedValue = this.normalizeValue(value);

    return (
      <SelectWrapper error={error}>
        <AsyncSelect
          isMulti={isMulti}
          name={name}
          value={normalizedValue}
          cacheOptions
          defaultOptions
          loadOptions={debounce(query => this.resolve(query), 600)}
          isDisabled={disabled}
          placeholder={placeholder}
          isClearable={!disabled}
          loadingMessage={() => __('Vyhľadávam')}
          noOptionsMessage={() => __('Nenašlo sa')}
          onChange={val => handleOnChange(val)}
          autoload={autoload}
          key={keyProps}
        />
      </SelectWrapper>
    );
  }
}

SearchableSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}) || PropTypes.arrayOf,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  error: PropTypes.bool,
  autoload: PropTypes.bool,
  keyProps: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
};

SearchableSelect.defaultProps = {
  value: [],
  name: '',
  placeholder: '',
  disabled: false,
  isMulti: false,
  error: false,
  autoload: false,
  keyProps: '',
};

export default SearchableSelect;
