import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
  },
  {
    name: __('Počet odtieňov'),
    clickable: false,
  },
  {
    name: __('Produkt NAV'),
    clickable: false,
    otherProps: { minWidth: 200 },
  },
  { name: __('Cena NAV'), clickable: false, sorterValue: 'Unit_Price' },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
  },
];
