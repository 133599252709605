import React from 'react';
import PropTypes from 'prop-types';

function PageInfo(props) {
  const { offset, limit, total } = props;
  return (
    <div>
      {total ? (
        <p>{`${offset + 1} - ${
          offset + limit > total ? total : offset + limit
        } of ${total || ''}`}</p>
      ) : (
        ''
      )}
    </div>
  );
}

PageInfo.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
};

PageInfo.defaultProps = {
  offset: 0,
  limit: 0,
  total: 0,
};

export default PageInfo;
