import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Select from 'oxyrion-ui/lib/Select';
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes';
import { Button, Message, Input, Label, Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __, renderLink } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import { LoaderWrapper, Center } from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import DatePicker from '../../Components/DatePicker';
import MarketingListsContacts from '../MarketingListsContacts';
import SearchableSelect from '../../Components/SearchableSelect';
import API2 from '../../API2';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const StyledReactMultiSelectCheckboxes = styled(ReactMultiselectCheckboxes)``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1550px) {
    flex-direction: row;
  }
`;

const Halfer = styled.div`
  width: 100%;
  margin-bottom: ${rem(20)};

  @media only screen and (min-width: 1550px) {
    width: auto;
    margin-right: ${rem(16)};
  }
`;

const InputWrapper = styled.div`
  width: ${rem(350)};
`;

const Row = styled.div`
  margin-bottom: ${rem(15)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(300)};
`;

const LinkWrapper = styled.div`
  margin-left: ${rem(6)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const MainLabel = styled(StyledLabel)`
  margin-right: ${rem(4)};
  font-size: ${rem(16)};
`;

const Space = styled.div`
  height: ${rem(4)};
  width: ${rem(4)};
`;

const StyledDatePicker = styled(DatePicker)`
  max-width: ${rem(180)};
`;

// const customerTypes = [
//   {
//     name: 'B2B',
//     type: 'company',
//     subtypes: ['Retail Shop', 'Wholeseller', 'Big Box', 'eshop'],
//   },
//   {
//     name: 'B2C-I',
//     type: 'company',
//     subtypes: ['Metal Industry', 'Wood Industry', 'Floor Industry'],
//   },
//   {
//     name: 'B2C-P',
//     type: 'company',
//     subtypes: ['Building Industry', 'Industry Applicator', 'Deco Applicator'],
//   },
//   {
//     name: 'B2C-C',
//     type: 'person',
//     subtypes: ['Consumer'],
//   },
// ];

const segments = ['A', 'B', 'C'];
const baseTypes = [
  { name: __('Text'), _id: 'text' },
  { name: __('Číslo'), _id: 'number' },
  { name: __('Vyhľadávač vlastníkov'), _id: 'owner_search' },
  { name: __('Dátum'), _id: 'date' },
];

const paramNames = [
  {
    name: __('Meno zákazníka'),
    _id: 'customer_name',
  },
  {
    name: __('Vlastník zákazníka'),
    _id: 'owner',
  },
  {
    name: __('Mesto zákazníka'),
    _id: 'customer_city',
  },
  {
    name: __('Aktuálny obrat'),
    _id: 'turnover',
  },
  {
    name: __('Iba hlavný kontakt'),
    _id: 'only_main',
  },

  {
    name: __('Meno kontaktu'),
    _id: 'contact_name',
  },

  {
    name: __('Priezvisko kontaktu'),
    _id: 'contact_surname',
  },
  {
    name: __('Pohlavie kontaktu'),
    _id: 'contact_sex',
  },
  {
    name: __('Dátum narodenia kontaktu'),
    _id: 'contact_birthday',
  },
];

const customersGroups = [
  {
    name: __('Potenciálni'),
    _id: 'leads',
  },
  {
    name: __('Aktuálni'),
    _id: 'actuals',
  },
];

class FilterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refreshTable: false,
      selectedAllCustomers: false,
      success: false,
      paramTypes: [],
      marketingListTypes: [],
      fetchedCodelistsForParams: [],
      customerTypes: [],
      data: {
        type: 'edited',
        firm: 'SK',
        customer_types: [],
        customer_subTypes: [],
        segments: [],
        groups: [],
        params: [],
        contacts: [],
        users: [],
        owner: {
          _id: '',
          display_name: '',
        },
      },
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      this.setState({ loading: true });
      await this.fetchListData(id);
    }

    this.fetchCodelists();
    this.fetchAllCodelists();
  }

  async fetchCodelists() {
    const { data } = this.state;

    const codelists = await Promise.all([
      await AdminAPI.getCodelist('typy_marketingoveho_zoznamu'),
      await AdminAPI.getCodelist('operandy'),
      await AdminAPI.getCustomerTypesAction(),
    ]);

    this.setState({
      marketingListTypes: codelists[0] ? codelists[0].codelist : [],
      operands: codelists[1] ? codelists[1].codelist : [],
      customerTypes: codelists[2] ? codelists[2].items : [],
      data: Object.assign(data, {
        type: data.type || codelists[0].codelist[0].value,
      }),
    });
  }

  async fetchAllCodelists() {
    try {
      const result = await AdminAPI.getCodelistsAction({
        entity: 'marketing_lists',
      });
      const codelists = result.codelists || [];

      this.setState({
        paramTypes: baseTypes.concat(codelists),
      });
    } catch (e) {}
  }

  async fetchListData(id) {
    try {
      const data = await AdminAPI.getMarketingListAction(id);
      data.contacts = data.users;

      if (!data.customer_types) {
        data.customer_types = [];
      }

      if (!data.customer_subTypes) {
        data.customer_subTypes = [];
      }

      if (!data.params) {
        data.params = [];
      }

      const codelists = data.params
        .filter(p => p.type.isCodelist)
        .map(c => c.type.value);

      const codelistResults = await Promise.all(
        codelists.map(c => {
          return AdminAPI.getCodelist(c);
        }),
      );

      const fetchedCodelistsForParams = codelistResults.map((c, index) => {
        return {
          _id: codelists[index],
          items: c.codelist,
        };
      });

      this.setState({
        data,
        selectedAllCustomers: this.areSelectedAllCustomerTypes(data),
        fetchedCodelistsForParams,
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie zoznamu nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Zoznam sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchCodelist(id) {
    let { fetchedCodelistsForParams } = this.state;

    if (
      fetchedCodelistsForParams &&
      fetchedCodelistsForParams.find(f => f._id === id)
    ) {
      return;
    }

    try {
      const result = await AdminAPI.getCodelist(id);

      const codelist = {
        _id: id,
        items: result.codelist || [],
      };

      if (!fetchedCodelistsForParams) {
        fetchedCodelistsForParams = [];
      }
      fetchedCodelistsForParams.push(codelist);
      this.setState({
        fetchedCodelistsForParams,
      });
    } catch (e) {
      console.log(e);
    }
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 10,
      }).then(res => {
        return res.users.map(item => ({
          value: item._id,
          label: item.display_name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async deleteList() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteMarketingListAction(getIdFromProps(this.props));
      history.push(`/${firm}/lists`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  normalizeValues(data) {
    if (!data) {
      return [];
    }

    return data
      .filter(d => d.label !== '-')
      .map(d => {
        return {
          label: d.label,
          value: d._id,
          show_in_filter: true,
          delete_from_catalog: false,
          default_value: false,
          translations: d.translations || [],
        };
      });
  }

  normalizeBrands(data) {
    return data.map(d => {
      return {
        label: d,
        value: d,
        show_in_filter: true,
        delete_from_catalog: false,
        default_value: false,
        translations: d.translations || [],
      };
    });
  }

  normalizeProductTypes(data) {
    return data.map(d => {
      return {
        label: d._id,
        value: d._id,
        show_in_filter: true,
        delete_from_catalog: false,
        default_value: false,
        translations: d.translations || [],
      };
    });
  }

  handleValuesChange(param, value) {
    const { data } = this.state;

    data.values.find(v => v.label === value.label)[param] = !data.values.find(
      v => v.label === value.label,
    )[param];

    this.setState({
      data,
    });
  }

  handleDataChange(variable, value) {
    const { data } = this.state;

    data[variable] = value;

    this.setState({
      data,
      notValidData: false,
    });
  }

  handleParamChange(index, field, value) {
    const { data } = this.state;

    if (!data.params[index]) {
      return;
    }

    if (field === 'date') {
      data.params[index].value = moment(value);
    } else if (field === 'owner_search') {
      data.params[index].value = value;
    } else if (field === 'regex') {
      data.params[index].value = value;
    } else if (field === 'value') {
      data.params[index].value = value;
    } else if (field === 'name') {
      if (value === '-') {
        data.params.splice(index, 1);
      } else {
        data.params[index].name = value;
        data.params[index]._id = paramNames.find(p => p.name === value)._id;
      }
    } else if (field === 'operand') {
      data.params[index].operand = value;
      if (value === 'regex') {
        data.params[index].type = {
          isCodelist: false,
          value: 'regex',
        };
      }
    }

    if (field === 'type') {
      const isFromCodelists = !(
        baseTypes.find(b => b._id === value) instanceof Object
      );

      data.params[index].type = {
        isCodelist: isFromCodelists,
        value,
      };

      data.params[index].value = '';

      if (isFromCodelists) {
        this.fetchCodelist(value);
      }
    }

    this.setState({
      data,
    });
  }

  areSelectedAllCustomerTypes(data) {
    if (data.customer_types.length !== 4) {
      return false;
    }

    if (data.customer_subTypes.length !== 11) {
      return false;
    }

    return true;
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    return isValid;
  }
  fetchInternalUsers(query) {
    const { data } = this.state;
    const usersIds = data.users ? data.users.map(u => u.user_id) : [];
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 100,
      }).then(res => {
        return res.users
          .filter(i => usersIds.indexOf(i._id) === -1)
          .map(item => ({
            value: item._id,
            label: `${item.display_name} - ${item.email}`,
          }));
      });
    } catch (e) {
      return [];
    }
  }
  fetchContacts(query) {
    const { data } = this.state;

    const contactIds = data.contacts
      ? data.contacts.map(c => c.contact_id)
      : [];

    try {
      return API2.getContactsAction(data.firm, {
        q: query,
        limit: 100,
        subscriberOnly: true,
      }).then(res => {
        return res.items
          .filter(i => contactIds.indexOf(i._id) === -1)
          .map(item => ({
            value: item._id,
            label: `${item.name} ${item.surname} - ${item.email}`,
          }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchCustomers(query) {
    const { data } = this.state;

    try {
      return API2.getCustomersAction(data.firm, { q: query }).then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
          customer: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async generateAutomaticList() {
    await this.saveData();

    this.setState({
      refreshTable: !this.state.refreshTable,
    });
  }

  async generateList() {
    const { data } = this.state;
    if (await this.saveData()) {
      if (data.contacts && data.contacts.length > 0) {
        this.setState({
          showGenerateConfirm: true,
        });
      } else {
        this.generateListWithConditions();
      }
      if (data.users && data.users.length > 0) {
        this.setState({
          showGenerateConfirm: true,
        });
      } else {
        this.generateListWithConditions();
      }
    }
  }

  async generateListWithConditions() {
    const { data } = this.state;

    this.setState({
      generateLoading: true,
    });

    try {
      await AdminAPI.postMarketingListContactsAction(data._id, {});
      data.contacts = [1];
    } catch (e) {
      console.log(e);
    }

    this.setState({
      generateLoading: false,
      showGenerateConfirm: false,
      data,
      refreshTable: !this.state.refreshTable,
    });
  }

  async saveData() {
    const { data, selectedAllCustomers, customerTypes } = this.state;
    let saved = false;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data)) {
      try {
        let subtypesToAdd = [];
        if (
          data.customer_types &&
          data.customer_types.length &&
          !selectedAllCustomers
        ) {
          subtypesToAdd = data.customer_types.map(c => {
            const { subtypes } = customerTypes.find(t => t.name === c);
            const sameSubtypes = data.customer_subTypes.map(
              s => subtypes.indexOf(s) > -1,
            );

            if (sameSubtypes.filter(ss => ss).length === 0) {
              return subtypes;
            }

            return [];
          });
        } else if (selectedAllCustomers) {
          data.customer_subTypes = [];
          data.customer_types = customerTypes.map(t => {
            data.customer_subTypes = data.customer_subTypes.concat(t.subtypes);

            return t.name;
          });
        }

        const dataToSend = {
          name: data.name,
          type: data.type,
          firm: data.firm,
          customer_subTypes: data.customer_subTypes.concat(
            subtypesToAdd.flat(),
          ),
          customer_types: data.customer_types,
          groups: data.groups,
          add_owners: data.add_owners,
          add_managment: data.add_managment,
          params: data.params.filter(
            p =>
              p.name !== '-' &&
              p.operand !== '' &&
              p.type &&
              p.type.value !== '-',
          ),
          segments: data.segments,
        };

        if (getIdFromProps(this.props) === 'new') {
          const params = {};
          params.body = dataToSend;
          const result = await AdminAPI.postMarketingListAction(params);
          this.props.history.push(`/${this.props.firm}/lists/${result._id}`);
          this.setState(
            {
              data: result,
              success: __('Dáta boli úspešne uložené'),
              selectedAllCustomers: this.areSelectedAllCustomerTypes(result),
            },
            () => {
              setTimeout(() => {
                this.setState({ success: false });
              }, 2000);
            },
          );
        } else {
          const params = {};
          params.body = dataToSend;
          const result = await AdminAPI.putMarketingListAction(
            getIdFromProps(this.props),
            params,
          );

          this.setState(
            {
              data: result,
              success: __('Dáta boli úspešne uložené'),
              selectedAllCustomers: this.areSelectedAllCustomerTypes(result),
            },
            () => {
              setTimeout(() => {
                this.setState({ success: false });
              }, 2000);
            },
          );
        }
        saved = true;
      } catch (e) {
        this.setState({
          saveLoading: true,
        });
      } finally {
        this.setState({
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        notValidData: true,
        saveLoading: false,
      });
    }

    return saved;
  }

  addCustomerType() {
    const { data } = this.state;
    if (!data.customer_types) {
      data.customer_types = [];
    }
    data.customer_types.push('');
    this.setState({
      data,
    });
  }

  async removeUser(userId) {
    const { data } = this.state;
    try {
      const marketingListId = getIdFromProps(this.props);

      await AdminAPI.deleteMarketingListUserAction(marketingListId, userId);

      if (!data.users) {
        data.users = [];
      }

      data.users = data.users.filter(u => u._id !== userId);

      this.setState({
        refreshTable: !this.state.refreshTable,
        tempUser: null,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async addUser(user) {
    const { data } = this.state;

    this.setState({
      tempUser: user,
    });
    try {
      let id = getIdFromProps(this.props);

      if (id === 'new') {
        await this.saveData();
        id = getIdFromProps(this.props);
      }

      await AdminAPI.postMarketingListUserAction(id, user.value);

      if (!data.users) {
        data.users = [];
      }

      data.users.push({
        user_id: user.value,
        active: true,
      });

      this.setState({
        refreshTable: !this.state.refreshTable,
        tempUser: null,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async addContact(contact) {
    const { data } = this.state;

    this.setState({
      tempContact: contact,
    });
    try {
      let id = getIdFromProps(this.props);

      if (id === 'new') {
        await this.saveData();
        id = getIdFromProps(this.props);
      }

      await AdminAPI.postMarketingListContactAction(id, contact.value);

      if (!data.contacts) {
        data.contacts = [];
      }

      data.contacts.push({
        contact_id: contact.value,
        active: true,
      });

      this.setState({
        refreshTable: !this.state.refreshTable,
        tempContact: null,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async addCustomer(customer) {
    const { data } = this.state;

    this.setState({
      tempCustomer: customer,
    });
    try {
      let id = getIdFromProps(this.props);

      if (id === 'new') {
        await this.saveData();
        id = getIdFromProps(this.props);
      }

      const contacts = await AdminAPI.postMarketingListCustomerAction(
        id,
        customer.value,
      );

      contacts.forEach(c => {
        data.contacts.push({
          contact_id: c,
          active: true,
        });
      });

      this.setState({
        refreshTable: !this.state.refreshTable,
        tempCustomer: null,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleCustomerTypeChange(type, index, value) {
    const { data, customerTypes } = this.state;

    const oldVal = data.customer_types[index];
    const oldValSubtypes = customerTypes.find(c => c.name === oldVal)
      ? customerTypes.find(c => c.name === oldVal).subtypes
      : [];

    if (type === 'type') {
      if (value === '-') {
        data.customer_types.splice(index, 1);
        data.customer_subTypes = data.customer_subTypes.filter(
          c => oldValSubtypes.indexOf(c) === -1,
        );
      } else {
        data.customer_types[index] = value;
      }
    } else if (type === 'subtype') {
      const newValues = (Array.isArray(value) ? value : [value]).map(
        v => v.value,
      );

      data.customer_subTypes = data.customer_subTypes.filter(
        c => oldValSubtypes.indexOf(c) === -1,
      );

      data.customer_subTypes = data.customer_subTypes.concat(newValues);
    }

    this.setState({ data });
  }

  handleSegmentChange(segment) {
    const { data } = this.state;

    if (!data.segments) {
      data.segments = [];
    }

    const indexOf = data.segments.indexOf(segment);

    if (indexOf > -1) {
      data.segments.splice(indexOf, 1);
    } else {
      data.segments.push(segment);
    }

    this.setState({
      data,
    });
  }

  handleGroupChange(group) {
    const { data } = this.state;

    if (!data.groups) {
      data.groups = [];
    }

    const indexOf = data.groups.indexOf(group);

    if (indexOf > -1) {
      data.groups.splice(indexOf, 1);
    } else {
      data.groups.push(group);
    }

    this.setState({
      data,
    });
  }

  selectAllSegments() {
    const { data } = this.state;

    data.segments = [...segments];

    this.setState({
      data,
    });
  }

  selectAllGroups() {
    const { data } = this.state;

    data.groups = customersGroups.map(c => c._id);

    this.setState({
      data,
    });
  }

  addNewCustomParam() {
    const { data } = this.state;

    if (!data.params) {
      data.params = [];
    }

    data.params.push({
      name: '',
      _id: '',
      operand: 'eq',
      type: {
        isCodelist: false,
        value: '',
      },
      value: '',
    });

    this.setState({
      data,
    });
  }

  async downloadFile(id) {
    try {
      const result = await AdminAPI.exportMarketingListContactsAction(id);

      this.setState({
        showDownloadDialog: true,
        downloadId: result.url,
      });
    } catch (e) {
      this.setState({
        error: __('Súbor sa nepodarilo stiahnúť'),
      });
    }
  }

  downloadFileWithId() {
    window.location.href = this.state.downloadId;
    this.setState({
      downloadId: undefined,
      showDownloadDialog: false,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Marketingový zoznam')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            primary
            showIcon={false}
            onClick={() => this.downloadFile(getIdFromProps(this.props))}
          >
            {__('Exportovať kontakty do excelu')}
          </ControlBarButton>
        )}
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }
  renderCustomerSegmentPart() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Row>
          {segments.map(s => (
            <Center>
              <Label>{__(s)}</Label>
              <Checkbox
                onChange={() => this.handleSegmentChange(s)}
                checked={data.segments && data.segments.indexOf(s) > -1}
              />
            </Center>
          ))}
        </Row>
      </React.Fragment>
    );
  }

  renderCustomerGroupsPart() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Row>
          {customersGroups.map(s => (
            <Center>
              <Label>{__(s.name)}</Label>
              <Checkbox
                onChange={() => this.handleGroupChange(s._id)}
                checked={data.groups && data.groups.indexOf(s._id) > -1}
              />
            </Center>
          ))}
        </Row>
      </React.Fragment>
    );
  }

  renderCustomerTypesPart() {
    const { data, selectedAllCustomers, customerTypes } = this.state;

    if (selectedAllCustomers) {
      return (
        <Row>
          <Label>{__('Všetky typy zákazníkov')}</Label>
        </Row>
      );
    }

    return data.customer_types.map((type, index) => {
      const subtypes = customerTypes.find(c => c.name === type)
        ? customerTypes.find(c => c.name === type).subtypes
        : [];

      const notAlreadyUsedTypes = customerTypes.filter(
        c => data.customer_types.indexOf(c.name) === -1 || type === c.name,
      );

      return (
        <Row>
          <InputWrapper>
            <Select
              disabled={selectedAllCustomers}
              size="s"
              value={type}
              onChange={e => {
                this.handleCustomerTypeChange('type', index, e.target.value);
              }}
            >
              <option selected={type === ''} value={null}>
                {'-'}
              </option>

              {notAlreadyUsedTypes ? (
                notAlreadyUsedTypes.map(c => {
                  return (
                    <option selected={c.name === type} value={c.name}>
                      {c.name}
                    </option>
                  );
                })
              ) : (
                <option>{__('Neobsahuje možnosti na výber')}</option>
              )}
            </Select>
          </InputWrapper>
          <Space />

          {type !== '' && (
            <InputWrapper>
              <StyledReactMultiSelectCheckboxes
                disabled={selectedAllCustomers}
                options={subtypes.map(s => ({ label: s, value: s }))}
                value={data.customer_subTypes
                  .filter(d => subtypes.indexOf(d) > -1)
                  .map(s => ({ label: s, value: s }))}
                placeholderButtonLabel={__('Všetky')}
                onChange={e =>
                  this.handleCustomerTypeChange('subtype', index, e)
                }
              />
            </InputWrapper>
          )}
        </Row>
      );
    });
  }

  renderCustomParamsPart() {
    const {
      operands,
      paramTypes,
      data,
      fetchedCodelistsForParams,
    } = this.state;

    return data.params ? (
      data.params.map((p, index) => {
        return (
          <Row>
            <InputWrapper style={{ width: rem(200) }}>
              <Select
                size="s"
                value={p.name}
                onChange={e => {
                  this.handleParamChange(index, 'name', e.target.value);
                }}
              >
                <option selected={!p.name || p.name === ''} value={null}>
                  {'-'}
                </option>

                {paramNames ? (
                  paramNames.map(c => {
                    return (
                      <option selected={c.name === p.name} value={c.name}>
                        {c.name}
                      </option>
                    );
                  })
                ) : (
                  <option>{__('Neobsahuje možnosti na výber')}</option>
                )}
              </Select>
            </InputWrapper>

            <InputWrapper style={{ width: rem(100), marginLeft: rem(10) }}>
              <Select
                size="s"
                value={p.operand}
                onChange={e => {
                  this.handleParamChange(index, 'operand', e.target.value);
                }}
              >
                {operands ? (
                  operands.map(c => {
                    return (
                      <option selected={c.value === p.operand} value={c.value}>
                        {c.label}
                      </option>
                    );
                  })
                ) : (
                  <option>{__('Neobsahuje možnosti na výber')}</option>
                )}
              </Select>
            </InputWrapper>

            {p.operand !== 'regex' ? (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <Select
                  size="s"
                  value={p.type && p.type.value}
                  onChange={e => {
                    this.handleParamChange(index, 'type', e.target.value);
                  }}
                >
                  <option
                    selected={!p.type || !p.type.value || p.type.value === ''}
                    value={null}
                  >
                    {'-'}
                  </option>

                  {paramTypes ? (
                    paramTypes.map(c => {
                      return (
                        <option
                          selected={p.type.value && c._id === p.type.value}
                          value={c._id}
                        >
                          {c.name}
                        </option>
                      );
                    })
                  ) : (
                    <option>{__('Neobsahuje možnosti na výber')}</option>
                  )}
                </Select>
              </InputWrapper>
            ) : (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <StyledInput
                  style={{ width: rem(180) }}
                  value="regex"
                  disabled
                  placeholder={__('Hodnota')}
                />
              </InputWrapper>
            )}

            {p.type && p.type.isCodelist && (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <Select
                  size="s"
                  onChange={e => {
                    this.handleParamChange(index, 'value', e.target.value);
                  }}
                >
                  <option
                    selected={!p.type || !p.type.value || p.type.value === ''}
                    value={null}
                  >
                    {'-'}
                  </option>

                  {fetchedCodelistsForParams &&
                  fetchedCodelistsForParams.find(
                    f => f._id === p.type.value,
                  ) ? (
                    fetchedCodelistsForParams
                      .find(f => f._id === p.type.value)
                      .items.map(c => {
                        return (
                          <option selected={p.value === c._id} value={c._id}>
                            {c.label}
                          </option>
                        );
                      })
                  ) : (
                    <Loader />
                  )}
                </Select>
              </InputWrapper>
            )}

            {p.type && !p.type.isCodelist && p.type.value === 'number' && (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <StyledInput
                  style={{ width: rem(180) }}
                  value={p.value}
                  type="number"
                  placeholder={__('Hodnota')}
                  onChange={e =>
                    this.handleParamChange(index, 'value', e.target.value)
                  }
                />
              </InputWrapper>
            )}

            {p.type && !p.type.isCodelist && p.type.value === 'text' && (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <StyledInput
                  style={{ width: rem(180) }}
                  value={p.value}
                  placeholder={__('Hodnota')}
                  onChange={e =>
                    this.handleParamChange(index, 'value', e.target.value)
                  }
                />
              </InputWrapper>
            )}

            {p.type && !p.type.isCodelist && p.operand === 'regex' && (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <StyledInput
                  style={{ width: rem(180) }}
                  value={p.value}
                  placeholder={__('Hodnota')}
                  onChange={e =>
                    this.handleParamChange(index, 'regex', e.target.value)
                  }
                />
              </InputWrapper>
            )}

            {p.type && !p.type.isCodelist && p.type.value === 'owner_search' && (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <SearchableSelect
                  value={p.value}
                  loadOptions={query => this.fetchUsers(query)}
                  placeholder={__('Vyhľadať používateľa')}
                  handleOnChange={e =>
                    this.handleParamChange(index, 'owner_search', e)
                  }
                />
              </InputWrapper>
            )}

            {p.type && !p.type.isCodelist && p.type.value === 'date' && (
              <InputWrapper style={{ width: rem(200), marginLeft: rem(10) }}>
                <StyledDatePicker
                  showMonthYearPicker={false}
                  forceShowMonthNavigation={false}
                  scrollableYearDropdown={false}
                  showYearDropdown={false}
                  scrollableMonthYearDropdown={false}
                  placeholderText={__('Dátum')}
                  dateFormat="DD.MM"
                  selected={p.value && moment(p.value)}
                  onChange={e => {
                    this.handleParamChange(index, 'date', e);
                  }}
                />
              </InputWrapper>
            )}
          </Row>
        );
      })
    ) : (
      <div />
    );
  }

  renderCustomTypeContactsSelector() {
    const { data, tempContact, tempCustomer } = this.state;
    return (
      <React.Fragment>
        <Row>
          <MainLabel>{__('Pridať kontakt')}</MainLabel>
        </Row>
        <Row style={{ maxWidth: rem(720) }}>
          <SettingsBox addDisabled title={__('Vyberte kontakt do zoznamu')}>
            <SearchableSelect
              keyProps={data.contacts && data.contacts.length + data.firm}
              value={tempContact}
              loadOptions={query => this.fetchContacts(query)}
              placeholder={__('Kontakt')}
              handleOnChange={e => this.addContact(e)}
            />
          </SettingsBox>
        </Row>

        <Row>
          <MainLabel>{__('Pridať kontakty konkrétneho zákaznika')}</MainLabel>
        </Row>
        <Row style={{ maxWidth: rem(720) }}>
          <SettingsBox addDisabled title={__('Vyberte zákazníka')}>
            <SearchableSelect
              keyProps={data.contacts && data.contacts.length + data.firm}
              value={tempCustomer}
              loadOptions={query => this.fetchCustomers(query)}
              placeholder={__('Zákazník')}
              handleOnChange={e => this.addCustomer(e)}
            />
          </SettingsBox>
        </Row>
      </React.Fragment>
    );
  }
  renderInternalTypeContactsSelector() {
    const { data, tempUser } = this.state;
    return (
      <React.Fragment>
        <Row>
          <MainLabel>{__('Pridať kontakt')}</MainLabel>
        </Row>
        <Row style={{ maxWidth: rem(720) }}>
          <SettingsBox addDisabled title={__('Vyberte kontakt do zoznamu')}>
            <SearchableSelect
              keyProps={data.users && data.users.length + data.firm}
              value={tempUser}
              loadOptions={query => this.fetchInternalUsers(query)}
              placeholder={__('Kontakt')}
              handleOnChange={e => this.addUser(e)}
            />
          </SettingsBox>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const {
      loading,
      data,
      notValidData,
      saveLoading,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      marketingListTypes,
      error,
      success,
      generateLoading,
      showGenerateConfirm,
      refreshTable,
      selectedAllCustomers,
      customerTypes,
      showDownloadDialog,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showDownloadDialog}
          small
          message={__('Sťiahnúť súbor')}
          onClose={() => this.setState({ showDownloadDialog: false })}
        >
          <ConfirmDialog
            message={__('Želáte si stiahnúť zvolený súbor ?')}
            onDismiss={() => this.setState({ showDownloadDialog: false })}
            onConfirm={() => this.downloadFileWithId()}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showGenerateConfirm}
          small
          onClose={() =>
            this.setState({
              showGenerateConfirm: false,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj si želáte pregenerovať zoznam kontaktov? Dáta z aktuálneho zoznamu budú prepísané.',
            )}
            onDismiss={() =>
              this.setState({
                showGenerateConfirm: false,
              })
            }
            onConfirm={() => this.generateListWithConditions()}
            dismissMessage={error}
            loading={generateLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť zoznam ?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteList()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
          <ContentWrapper>
            <Halfer style={{ marginTop: rem(12) }}>
              <Row style={{ justifyContent: 'space-between', maxWidth: 720 }}>
                <InputWrapper>
                  <StyledLabel>{__('Názov zoznamu*')}</StyledLabel>
                  <StyledInput
                    value={data.name}
                    placeholder={__('Zadajte výstižný názov')}
                    onChange={e =>
                      this.handleDataChange('name', e.target.value)
                    }
                  />
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Typ zoznamu*')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.type}
                    disabled={getIdFromProps(this.props) !== 'new'}
                    placeholder={__('Typ zoznamu')}
                    onChange={e => {
                      this.handleDataChange('type', e.target.value);
                    }}
                  >
                    {marketingListTypes ? (
                      marketingListTypes.map(item => {
                        return (
                          <option
                            selected={data.type === item._id}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })
                    ) : (
                      <option>{__('Neobsahuje možnosti na výber')}</option>
                    )}
                  </Select>
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Vlastník*')}</StyledLabel>
                  <LinkWrapper>
                    {data.owner &&
                      renderLink(
                        `../users/${data.owner._id}`,
                        data.owner.display_name,
                      )}
                  </LinkWrapper>
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Krajina*')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.firm}
                    disabled={getIdFromProps(this.props) !== 'new'}
                    placeholder={__('Typ zoznamu')}
                    onChange={e => {
                      this.handleDataChange('firm', e.target.value);
                    }}
                  >
                    {['SK', 'CZ'].map(item => {
                      return (
                        <option selected={data.firm === item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </InputWrapper>
              </Row>
              {data.type !== 'internal' && (
                <div>
                  <Row>
                    <Center>
                      <Checkbox
                        onChange={() =>
                          this.handleDataChange('add_owners', !data.add_owners)
                        }
                        checked={data.add_owners}
                      />
                      <Label>{__('Pridať vlastníkov zákazníkov')}</Label>
                    </Center>
                  </Row>
                  <Row>
                    <Center>
                      <Checkbox
                        onChange={() =>
                          this.handleDataChange(
                            'add_managment',
                            !data.add_managment,
                          )
                        }
                        checked={data.add_managment}
                      />
                      <Label>{__('Pridať manažment')}</Label>
                    </Center>
                  </Row>
                </div>
              )}
              {(() => {
                switch (data.type) {
                  case 'custom':
                    return this.renderCustomTypeContactsSelector();
                  case 'internal':
                    return this.renderInternalTypeContactsSelector();
                  default:
                    return (
                      <React.Fragment>
                        <Row>
                          <MainLabel>
                            {__('Podmienky filtrovania adries')}
                          </MainLabel>
                        </Row>

                        <Row>
                          <SettingsBox
                            title={__('Typ zákazníka')}
                            onSelectAll={() =>
                              this.setState({
                                selectedAllCustomers: !selectedAllCustomers,
                              })
                            }
                            selectedAll={selectedAllCustomers}
                            addDisabled={
                              customerTypes.filter(
                                c => data.customer_types.indexOf(c.name) === -1,
                              ).length === 0 ||
                              data.customer_types.indexOf('') > -1 ||
                              selectedAllCustomers
                            }
                            addMore={() => this.addCustomerType()}
                          >
                            {this.renderCustomerTypesPart()}
                          </SettingsBox>
                        </Row>

                        <Row>
                          <SettingsBox
                            addDisabled
                            onSelectAll={() => this.selectAllSegments()}
                            selectedAll={
                              data.segments &&
                              data.segments.length === segments.length
                            }
                            title={__('Kategória segmentu')}
                          >
                            {this.renderCustomerSegmentPart()}
                          </SettingsBox>
                        </Row>

                        <Row>
                          <SettingsBox
                            addDisabled
                            onSelectAll={() => this.selectAllGroups()}
                            selectedAll={
                              data.groups &&
                              data.groups.length === customersGroups.length
                            }
                            title={__('Skupina zákazníkov')}
                          >
                            {this.renderCustomerGroupsPart()}
                          </SettingsBox>
                        </Row>

                        <Row>
                          <SettingsBox
                            addMore={() => this.addNewCustomParam()}
                            title={__('Vlastné parametre')}
                          >
                            {this.renderCustomParamsPart()}
                          </SettingsBox>
                        </Row>
                      </React.Fragment>
                    );
                }
              })()}
              <Row
                style={{
                  justifyContent: 'space-between',
                  maxWidth: 720,
                  marginBottom: rem(40),
                  marginTop: rem(12),
                }}
              >
                <Button
                  loading={saveLoading}
                  onClick={() => this.saveData()}
                  primary
                >
                  {__('Uložiť')}
                </Button>

                {data.type === 'edited' && (
                  <Button
                    style={{ background: 'red' }}
                    onClick={() => this.generateList()}
                    loading={generateLoading}
                    disabled={
                      generateLoading || (data.type && data.type !== 'edited')
                    }
                    primary
                  >
                    {__('Generovať')}
                  </Button>
                )}

                {data.type === 'automatic' && (
                  <Button
                    style={{ background: 'red' }}
                    onClick={() => this.generateAutomaticList()}
                    loading={generateLoading}
                    primary
                  >
                    {__('Zobraziť aktuálny zoznam')}
                  </Button>
                )}
              </Row>
            </Halfer>

            {getIdFromProps(this.props) !== 'new' && (
              <Halfer
                style={{
                  width: '100%',
                }}
              >
                <MarketingListsContacts
                  showPager
                  small
                  readonly={
                    data.type === 'automatic' || data.type === 'internal'
                  }
                  onRemoveClicked={id => this.removeUser(id)}
                  showUpdateButton={data.type === 'edited'}
                  history={this.props.history}
                  saveConditions={() => this.saveData()}
                  id={getIdFromProps(this.props)}
                  refreshTable={refreshTable}
                />
              </Halfer>
            )}
          </ContentWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

FilterDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FilterDetail);
