import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import FilterDetail from '../../../Containers/FilterDetail';
import Filters from '../../../Containers/Filters';

function FiltersCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={FilterDetail} />
      <Route path={`${match.url}`} component={Filters} />
    </Switch>
  );
}

FiltersCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default FiltersCard;
