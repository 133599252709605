/* eslint-disable */
import 'isomorphic-fetch';

class API {
  constructor() {
    this.baseUrl = null;
    this.token = null;
  }

  serializeQueryParams(parameters) {
    const str = [];
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(
          `${encodeURIComponent(p)}=${encodeURIComponent(parameters[p])}`,
        );
      }
    }
    return str.join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function(part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    // contentTypeHeader[0] because nearly every header's value is set using array
    const contentTypeHeader = headers['Content-Type'];
    if (contentTypeHeader && contentTypeHeader[0] === 'multipart/form-data') {
      delete headers['Content-Type'];
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then(response => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          if (
            response.headers.get('Content-Type').includes('application/json')
          ) {
            return response.json();
          } else if (
            response.headers.get('Content-Type').includes('application/pdf')
          ) {
            return response.blob();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        return error.response.json().then(error_details => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  setAuthHeaders(headerParams) {
    let headers = headerParams ? headerParams : {};
    if (this.token) {
      headers['Authorization'] = 'Bearer ' + this.token;
    }
    return headers;
  }

  /**
   * Provides informations about cash register
   * @method
   * @param {object} parameters - method options and parameters
   */
  cashRegisterAction(parameters = {}) {
    let path = '/cash-register';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns list of available users
   * @method
   * @param {object} parameters - method options and parameters
   */
  cashRegisterUsersAction(parameters = {}) {
    let path = '/cash-register/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns catalog data
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.updateId - ID of update
   */
  cashRegisterCatalogAction(parameters = {}) {
    let path = '/cash-register/catalog';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['updateId'] !== undefined) {
      queryParameters['updateId'] = parameters['updateId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns products availability
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.stockId - IDs of stock
   * @param {array} parameters.productId - IDs of product
   */
  cashRegisterInventoryAction(parameters = {}) {
    let path = '/cash-register/inventory';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['stockId'] !== undefined) {
      queryParameters['stockId'] = parameters['stockId'];
    }

    if (parameters['productId'] !== undefined) {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns list of available stocks
   * @method
   * @param {object} parameters - method options and parameters
   */
  cashRegisterStocksAction(parameters = {}) {
    let path = '/cash-register/stocks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Creates bill
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The payment data.
   */
  cashRegisterBillCreateAction(parameters = {}) {
    let path = '/cash-register/bill-create';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Authorize the AD user over MS AD API.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.redirectUri -
   * @param {string} parameters.appId -
   * @param {array} parameters.scopes -
   * @param {string} parameters.state -
   * @param {string} parameters.loginHint -
   */
  oauthAuthorizeAdAction(parameters = {}) {
    let path = '/oauth/authorize/ad';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['redirectUri'] !== undefined) {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    if (parameters['redirectUri'] === undefined) {
      throw Error('Missing required  parameter: redirectUri');
    }

    if (parameters['appId'] !== undefined) {
      queryParameters['app_id'] = parameters['appId'];
    }

    if (parameters['appId'] === undefined) {
      throw Error('Missing required  parameter: appId');
    }

    if (parameters['scopes'] !== undefined) {
      queryParameters['scopes'] = parameters['scopes'];
    }

    if (parameters['scopes'] === undefined) {
      throw Error('Missing required  parameter: scopes');
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    if (parameters['loginHint'] !== undefined) {
      queryParameters['login_hint'] = parameters['loginHint'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Change logged user token.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - ID of user
   */
  oauthLoggedUserChange(userId, parameters = {}) {
    let path = '/oauth/change-user/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.code - Code from MS AD service
   * @param {string} parameters.state - State which was sent to MS AD service to identify action
   * @param {string} parameters.error - Error identifier in case of trouble
   * @param {string} parameters.errorDescription - Error description in case of trouble
   */
  oauthCallbackAdAction(parameters = {}) {
    let path = '/oauth/callback/ad';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['code'] !== undefined) {
      queryParameters['code'] = parameters['code'];
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    if (parameters['error'] !== undefined) {
      queryParameters['error'] = parameters['error'];
    }

    if (parameters['errorDescription'] !== undefined) {
      queryParameters['error_description'] = parameters['errorDescription'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get token informations.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  oauthTokenInfoAction(parameters = {}) {
    let path = '/oauth/tokeninfo';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Revoke the access token.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  oauthRevokeAction(parameters = {}) {
    let path = '/oauth/revoke';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get informations about user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.email - 
 */
  meAction(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['email'] !== undefined) {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get role permisions map.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getRolesPermisionsMap(parameters = {}) {
    let path = '/menu-permisions-map';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * et user info by email.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.email - 
 */
  getUserByEmailAction(parameters = {}) {
    let path = '/email-verification';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['email'] !== undefined) {
      queryParameters['email'] = parameters['email'];
    }

    if (parameters['email'] === undefined) {
      throw Error('Missing required  parameter: email');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get langs for app.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.app - 
 */
  getLanguagesAction(parameters = {}) {
    let path = '/langs';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['app'] !== undefined) {
      queryParameters['app'] = parameters['app'];
    }

    if (parameters['app'] === undefined) {
      throw Error('Missing required  parameter: app');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get translations for app.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.app - 
 */
  getTranslationsAction(parameters = {}) {
    let path = '/translations';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['app'] !== undefined) {
      queryParameters['app'] = parameters['app'];
    }

    if (parameters['app'] === undefined) {
      throw Error('Missing required  parameter: app');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update user settings.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - User appLang Settings Data.
 */
  putUserAppLangAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/user/activeAppLang';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List plans for logged user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
   * @param {string} parameters.cummulativeTo - Date
 */
  getTurnoverPlanAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/turnover-plan';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['cummulativeTo'] !== undefined) {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List turnover of shares by brand.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
   * @param {string} parameters.cummulativeTo - Date
 */
  getTurnoverByBrandAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['cummulativeTo'] !== undefined) {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get user plan in CSV file.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {array} parameters.sellerCodes - 
   * @param {string} parameters.fromDate - 
   * @param {string} parameters.toDate - 
 */
  getUserPlanCsvAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/user-plan/csv-export';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['sellerCodes'] !== undefined) {
      queryParameters['sellerCodes'] = parameters['sellerCodes'];
    }

    if (parameters['sellerCodes'] === undefined) {
      throw Error('Missing required  parameter: sellerCodes');
    }

    if (parameters['fromDate'] !== undefined) {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    if (parameters['fromDate'] === undefined) {
      throw Error('Missing required  parameter: fromDate');
    }

    if (parameters['toDate'] !== undefined) {
      queryParameters['toDate'] = parameters['toDate'];
    }

    if (parameters['toDate'] === undefined) {
      throw Error('Missing required  parameter: toDate');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List top customers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
   * @param {number} parameters.month - Month
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.cummulativeTo - Date
 */
  getTopCustomersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/top-customers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['cummulativeTo'] !== undefined) {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables per period.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
 */
  getReceivablesSummaryAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/receivables-summary';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Leads summary per year.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
 */
  getLeadsSummaryAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/leads-summary';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Opportunities summary per year.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
 */
  getOpportunitiesSummaryAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities-summary';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List owned customers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {number} parameters.year - Year
   * @param {string} parameters.q - 
   * @param {string} parameters.owner - 
   * @param {string} parameters.sorter - Define sort order
   * @param {boolean} parameters.active - 
   * @param {boolean} parameters.allCustomers - 
 */
  getCustomersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['owner'] !== undefined) {
      queryParameters['owner'] = parameters['owner'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    if (parameters['allCustomers'] !== undefined) {
      queryParameters['allCustomers'] = parameters['allCustomers'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Batch state update of customers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The status data
 */
  putCustomersBusinessConditionsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/businessConditions/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Batch state update of customers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The status data
 */
  putCustomersStatusAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers/status';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update customer details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The lead data.
 */
  putCustomerAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer photos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerPhotosAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload customer photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.photo - Photo to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  postCustomerPhotoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['photo'] !== undefined) {
      form['photo'] = parameters['photo'];
    }

    if (parameters['photo'] === undefined) {
      throw Error('Missing required  parameter: photo');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete customer photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to photo
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  deleteCustomerPhotoAction(fullPath, companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/photos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Turnover informations by brand for specified customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - Year
 */
  getCustomerTurnoverByBrandAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customer contacts.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerContactsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The contact data.
 */
  postCustomerContactAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables for customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - Year
 */
  getCustomerReceivablesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/receivables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Qualification customer request.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {boolean} parameters.forceNoDiscount - 
 */
  createQualificationRequest(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/qualification';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['forceNoDiscount'] !== undefined) {
      queryParameters['forceNoDiscount'] = parameters['forceNoDiscount'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete qualification operation.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  removeQualificationRequest(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/qualification';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Qualification operation.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} qualifyOperation - Qualification Operation
   * @param {string} parameters.qualificationDeclineText - Reasons for rejection
 */
  gualifyCustomer(companyId, customerId, qualifyOperation, parameters = {}) {
    let path =
      '/crm/{companyId}/customers/{customerId}/qualification/{qualifyOperation}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{qualifyOperation}', qualifyOperation);

    if (parameters['qualificationDeclineText'] !== undefined) {
      queryParameters['qualificationDeclineText'] =
        parameters['qualificationDeclineText'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get user ownership.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerOwnershipAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/ownership';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Ownership management.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - 
 */
  putCustomerOwnershipAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/ownership';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Bonuses management.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - 
 */
  putCustomerBonusesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/bonuses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Turnover plan informations for specified custome.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - Year
 */
  getCustomerTurnoverPlanAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/turnover-plan';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Modify customer status to active/inactive
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The status data
   */
  postCustomerStatus(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/status';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get customers machines.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   */
  getCustomerMachinesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/machines';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get user acl list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  getUserAcl(companyId, parameters = {}) {
    let path = '/crm/{companyId}/user-acl';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get user acl list.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BUserAcl(parameters = {}) {
    let path = '/b2b/user-acl';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of contacts.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.customersIds - 
   * @param {array} parameters.customersSubtypes - 
   * @param {boolean} parameters.active - 
   * @param {string} parameters.sorter - 
   * @param {string} parameters.q - 
 */
  getCustomersContactsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customers-contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['customersIds'] !== undefined) {
      queryParameters['customersIds'] = parameters['customersIds'];
    }

    if (parameters['customersSubtypes'] !== undefined) {
      queryParameters['customersSubtypes'] = parameters['customersSubtypes'];
    }

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of contacts.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {boolean} parameters.active - 
   * @param {boolean} parameters.subscriberOnly - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {boolean} parameters.deleted - 
   * @param {string} parameters.owner - 
   * @param {string} parameters.sorter - 
   * @param {string} parameters.q - 
 */
  getContactsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    if (parameters['subscriberOnly'] !== undefined) {
      queryParameters['subscriberOnly'] = parameters['subscriberOnly'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['deleted'] !== undefined) {
      queryParameters['deleted'] = parameters['deleted'];
    }

    if (parameters['owner'] !== undefined) {
      queryParameters['owner'] = parameters['owner'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get email logs for contact.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   */
  getContactEmailLogsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/email-logs';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
 */
  getContactAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - The contact data.
 */
  putContactAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
 */
  deleteContactAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update EAN code of contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - The contact data.
 */
  putContactEanAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/ean/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of leads.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.userId - 
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
   * @param {string} parameters.owner - 
   * @param {boolean} parameters.active - 
   * @param {string} parameters.q - 
 */
  getLeadsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/leads';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['owner'] !== undefined) {
      queryParameters['owner'] = parameters['owner'];
    }

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new lead.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The lead data.
 */
  postLeadsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/leads';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete lead.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  deleteLeadAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/leads/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of references.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getReferencesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/references';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new reference.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The detail of reference.
 */
  postReferenceAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/references';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create reference image.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.file - Image to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
 */
  postReferenceImageAction(companyId, referenceId, parameters = {}) {
    let path = '/crm/{companyId}/references/{referenceId}/images';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['file'] !== undefined) {
      form['file'] = parameters['file'];
    }

    if (parameters['file'] === undefined) {
      throw Error('Missing required  parameter: file');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get reference images

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
 */
  getReferenceImagesAction(companyId, referenceId, parameters = {}) {
    let path = '/crm/{companyId}/references/{referenceId}/images';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create reference.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
   * @param {string} fullPath - Escaped full path to attachment
 */
  deleteReferenceImageAction(
    companyId,
    referenceId,
    fullPath,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/references/{referenceId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update reference status
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.status -
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
   */
  sendReferenceForAprroveAction(companyId, referenceId, parameters = {}) {
    let path = '/crm/{companyId}/references/{referenceId}/sendForApprove';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['status'] = 'for_approval';

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete reference.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
 */
  deleteReferenceAction(companyId, referenceId, parameters = {}) {
    let path = '/crm/{companyId}/references/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get reference detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
   */
  getReferenceAction(companyId, referenceId, parameters = {}) {
    let path = '/crm/{companyId}/references/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update reference data
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The reference data.
   * @param {string} companyId - Shortcut of location
   * @param {string} referenceId - Id of reference
   */
  putReferenceAction(companyId, referenceId, parameters = {}) {
    let path = '/crm/{companyId}/references/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of inspirations.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getInspirationsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'created_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new inspiration.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The detail of inspiration.
 */
  postInspirationAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create inspiration image.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.file - Image to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
 */
  postInspirationImageAction(companyId, inspirationId, parameters = {}) {
    let path = '/crm/{companyId}/inpsirationss/{inspirationId}/images';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['file'] !== undefined) {
      form['file'] = parameters['file'];
    }

    if (parameters['file'] === undefined) {
      throw Error('Missing required  parameter: file');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get inspiration images

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
 */
  getInspirationImagesAction(companyId, inspirationId, parameters = {}) {
    let path = '/crm/{companyId}/inpsirationss/{inspirationId}/images';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete inspiration image.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
   * @param {string} fullPath - Escaped full path to attachment
 */
  deleteInspirationImageAction(
    companyId,
    inspirationId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/inspirations/{inspirationId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update inspiration status
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.status -
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
   */
  sendInspirationForAprroveAction(companyId, inspirationId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations/{inspirationId}/sendForApprove';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['status'] = 'for_approval';

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete inspiration.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
 */
  deleteInspirationAction(companyId, inspirationId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get inspiration detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
   */
  getInspirationAction(companyId, inspirationId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update inspiration data
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The inspiration data.
   * @param {string} companyId - Shortcut of location
   * @param {string} inspirationId - Id of inspiration
   */
  putInspirationAction(companyId, inspirationId, parameters = {}) {
    let path = '/crm/{companyId}/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create lead ownership change request
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The opportunity data.
   */
  postLeadOwnershipChangeRequest(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/leads/{customerId}/ownershipRequest';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create lead ownership change request
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} status - Customer id for which must be data loaded
   */
  putLeadOwnershipChangeRequest(
    companyId,
    customerId,
    status,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/leads/{customerId}/ownershipRequest/{status}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{status}', status);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} ico - ICO na vyhladanie
   */
  getBusinessInfo(companyId, ico, parameters = {}) {
    let path = '/crm/{companyId}/business-info/{ico}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ico}', ico);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of newsletters sent to contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} newsletterId - 
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
 */
  getNewsletterUnsubscribeAction(companyId, newsletterId, parameters = {}) {
    let path = '/crm/{companyId}/newsletter/{newsletterId}/logouts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Set unsubscribe for contact
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} newsletterId -
   * @param {string} contactId - ID ofcontact
   */
  putNewsletterUnsubscribeAction(
    companyId,
    newsletterId,
    contactId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/newsletter-unsubscribe/{newsletterId}/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List customer opportunity of calculation tables.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} opportunityId - ID of opprotunity
   * @param {string} parameters.status -
   * @param {string} parameters.q -
   */
  getCustomerOpportunityCalculationTablesAction(
    companyId,
    customerId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/customers/{customerId}/opportunities/{opportunityId}/calculating-tables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{customerId}', customerId);

    path = path.replace('{opportunityId}', opportunityId);

    /** set default value **/
    queryParameters['status'] = 'active';

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List customer of calculation tables.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} parameters.status -
   * @param {string} parameters.q -
   */
  getCustomerCalculationTablesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/calculating-tables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{customerId}', customerId);

    /** set default value **/
    queryParameters['status'] = 'active';

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List customer of opportunities.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {array} parameters.status -
   * @param {boolean} parameters.withCalculationTables -
   */
  getCustomerOpportunitiesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    path = path.replace('{customerId}', customerId);

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['withCalculationTables'] !== undefined) {
      queryParameters['withCalculationTables'] =
        parameters['withCalculationTables'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Creates new opportunity.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The opportunity data.
   */
  postCustomerOpportunitiesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of calculation tables

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} opportunityId - ID of opprotunity
   * @param {string} parameters.q - 
   * @param {string} parameters.status - 
   * @param {string} parameters.sorter - Define sort order
 */
  getOpportunityCalculationTablesAction(
    companyId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/opportunities/{opportunityId}/calculation-tables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{opportunityId}', opportunityId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of opportunities.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
   * @param {boolean} parameters.onlyOwned -
   * @param {string} parameters.owner -
   * @param {array} parameters.status -
   * @param {string} parameters.q -
   */
  getOpportunitiesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-created_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['owner'] !== undefined) {
      queryParameters['owner'] = parameters['owner'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Detail of opportunity.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
   */
  getOpportunityAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Updates opportunity.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
   * @param {} parameters.data - The opportunity data.
   */
  putOpportunityAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete opportunity.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
   */
  deleteOpportunityAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of sales for customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {boolean} parameters.active -
   */
  getCustomerSalesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/sales';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    /** set default value **/
    queryParameters['active'] = 'true';

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of opportunities linked to contact.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   */
  getContactOpportunitiesAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * "List of MS Graph Calendar events. There will be events from calendar with name 'CRM'. Few cases can occure:
- calendar with name 'CRM' not exists in MS graph - no events will be listed.
- calendar was removed from MS graph out of CRM - no events will be listed listed until new event will be created
- calendar was recreated outside of CRM - no event will be listed until new event will be created"

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.from - 
   * @param {string} parameters.to - 
 */
  getUserCalendarAction(parameters = {}) {
    let path = '/me/calendar';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * "Create new event in MS Graph calendar. When creating starts then this flow will be applied:
  - check if user object has stored ID of calendar with name 'CRM' in local DB
    * if ID exists then will be used to create event
    * if ID not exists, new calendar will be created, stored in local DB and used to create event
  - if request to create event returns error (no calendar exists) then new new calendar will be 
    created with name 'CRM', stored in local DB and used to create new event
  - otherwise evetn will be created"

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The MS Graph event data.
 */
  postUserCalendarEventAction(parameters = {}) {
    let path = '/me/calendar';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Detail of MS Graph Calendar event.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} eventId - ID of event
   */
  getUserCalendarEventAction(eventId, parameters = {}) {
    let path = '/me/calendar/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{eventId}', eventId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update event in MS Graph calendar.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} eventId - ID of event
   * @param {} parameters.data - The MS Graph event data.
   */
  putUserCalendarEventAction(eventId, parameters = {}) {
    let path = '/me/calendar/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{eventId}', eventId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete event in MS Graph calendar.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} eventId - ID of event
   */
  deleteUserCalendarEventAction(eventId, parameters = {}) {
    let path = '/me/calendar/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{eventId}', eventId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of MS Graph Calendar events for specified customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} parameters.from -
   * @param {string} parameters.to -
   */
  getCustomersCalendarEventsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/calendar';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create new event in MS Graph calendar for specified customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The MS Graph event data.
   */
  postCustomerCalendarEventAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/calendar';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of activities from MS graph linked to contact by customer ID.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {string} parameters.from - 
   * @param {string} parameters.to - 
 */
  getContactCalendarAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/calendar';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of newsletters sent to contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
 */
  getContactNewsletters(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of newsletters sent to customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
 */
  getCustomerNewsletters(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of activities from MS graph linked to contact by customer ID.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {string} parameters.from - 
   * @param {string} parameters.to - 
 */
  getContactActivities(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of MS Graph tasks.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.status -
   */
  getUserGraphTasksAction(parameters = {}) {
    let path = '/me/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create MS Graph task.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  postUserGraphTaskAction(parameters = {}) {
    let path = '/me/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Detail of MS Graph task.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} taskId - Id of task
   */
  getUserGraphTaskAction(taskId, parameters = {}) {
    let path = '/me/tasks/{taskId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{taskId}', taskId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update MS Graph task.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} taskId - Id of task
   * @param {} parameters.data -
   */
  putUserGraphTaskAction(taskId, parameters = {}) {
    let path = '/me/tasks/{taskId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{taskId}', taskId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete MS Graph task.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} taskId - Id of task
   */
  deleteUserGraphTaskAction(taskId, parameters = {}) {
    let path = '/me/tasks/{taskId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{taskId}', taskId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of MS Graph tasks for specified customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   */
  getCustomerGraphTasksAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create MS Graph task.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data -
   */
  postCustomerGraphTaskAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of tasks from MS graph linked to contact via customer ID.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {array} parameters.status - 
 */
  getContactGraphTasksAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of CRM To-Do.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.status -
   */
  getUserTodosAction(parameters = {}) {
    let path = '/me/todos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create todo.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  postUserTodoAction(parameters = {}) {
    let path = '/me/todos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Detail of Todo.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} todoId - ID of todo
   */
  getUserTodoAction(todoId, parameters = {}) {
    let path = '/me/todos/{todoId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{todoId}', todoId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update Todo.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} todoId - ID of todo
   * @param {} parameters.data -
   */
  putUserTodoAction(todoId, parameters = {}) {
    let path = '/me/todos/{todoId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{todoId}', todoId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Todo.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} todoId - ID of todo
   */
  deleteUserTodoAction(todoId, parameters = {}) {
    let path = '/me/todos/{todoId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{todoId}', todoId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of customer todos.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {array} parameters.status -
   */
  getCustomerTodosAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/todos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create MS Graph task.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data -
   */
  postCustomerTodoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/todos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customer's types.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerTypesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new type of customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - 
 */
  postCustomerTypeAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update of customer types.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - 
 */
  putCustomerTypesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of customer type.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} typeId - ID of user type
 */
  getCustomerTypeAction(companyId, customerId, typeId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types/{typeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update of customer type.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} typeId - ID of user type
   * @param {} parameters.data - 
 */
  putCustomerTypeAction(companyId, customerId, typeId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types/{typeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{typeId}', typeId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete of customer type.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} typeId - ID of user type
 */
  deleteCustomerTypeAction(companyId, customerId, typeId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/types/{typeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of competitors.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
   * @param {string} parameters.q - 
 */
  getCompetitorsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/competitors';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create competitor.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postCompetitorsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/competitors';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of competitor.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
 */
  getCompetitorAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update detail of competitor.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
   * @param {} parameters.data - 
 */
  putCompetitorAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete competitor.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
 */
  deleteCompetitorAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of competitor revenues

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
 */
  getCompetitorRevenuesAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}/revenues';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create competitor revenue

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
   * @param {} parameters.data - 
 */
  postCompetitorRevenueAction(companyId, competitorId, parameters = {}) {
    let path = '/crm/{companyId}/competitors/{competitorId}/revenues';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of competitor revenue

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
   * @param {string} competitorRevenueId - Competitor revenue ID
 */
  getCompetitorRevenueAction(
    companyId,
    competitorId,
    competitorRevenueId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/competitors/{competitorId}/revenues/{competitorRevenueId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    path = path.replace('{competitorRevenueId}', competitorRevenueId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update competitor revenue

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
   * @param {string} competitorRevenueId - Competitor revenue ID
   * @param {} parameters.data - 
 */
  putCompetitorRevenueAction(
    companyId,
    competitorId,
    competitorRevenueId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/competitors/{competitorId}/revenues/{competitorRevenueId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    path = path.replace('{competitorRevenueId}', competitorRevenueId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update competitor revenue

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} competitorId - Competitor ID
   * @param {string} competitorRevenueId - Competitor revenue ID
 */
  deleteCompetitorRevenueAction(
    companyId,
    competitorId,
    competitorRevenueId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/competitors/{competitorId}/revenues/{competitorRevenueId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{competitorId}', competitorId);

    path = path.replace('{competitorRevenueId}', competitorRevenueId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of services.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.status - 
   * @param {string} parameters.sorter - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getHeldpeskServicesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postHelpdeskServiceAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
   * @param {string} fullPath - Escaped full path to attachment
 */
  getHelpdeskServiceAttachmentDownloadAction(
    companyId,
    serviceId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/helpdesk-attachment/{serviceId}/download/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
   * @param {string} fullPath - Escaped full path to attachment
 */
  getB2BHelpdeskServiceAttachmentDownloadAction(
    serviceId,
    fullPath,
    parameters = {},
  ) {
    let path = '/b2b/helpdesk-attachment/{serviceId}/download/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Helpdesk service details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
 */
  getHelpdeskServiceAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
   * @param {} parameters.data - 
 */
  putHelpdeskServiceAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
 */
  deleteHelpdeskServiceAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Set helpdesk service status.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
   * @param {} parameters.data - 
 */
  putHelpdeskServiceStatusAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/status';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of logged user services.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.status - 
   * @param {string} parameters.sorter - 
   * @param {boolean} parameters.onlyOwned - 
 */
  getUserHelpdeskServicesAction(parameters = {}) {
    let path = '/me/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of attachments linked to helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
 */
  getHelpdeskServiceAttachmentsAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of attachments linked to helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.attachment - Attachment file.
   * @param {string} parameters.historyId - 
   * @param {string} parameters.descriptionFile - 
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
 */
  postHelpdeskServiceAttachmentAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['attachment'] !== undefined) {
      form['attachment'] = parameters['attachment'];
    }

    if (parameters['attachment'] === undefined) {
      throw Error('Missing required  parameter: attachment');
    }

    if (parameters['historyId'] !== undefined) {
      queryParameters['historyId'] = parameters['historyId'];
    }

    if (parameters['descriptionFile'] !== undefined) {
      queryParameters['descriptionFile'] = parameters['descriptionFile'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of attachments linked to helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to attachment
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
 */
  deleteHelpdeskServiceAttachmentAction(
    fullPath,
    companyId,
    serviceId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/attachments/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user settings.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getUserSettingsAction(parameters = {}) {
    let path = '/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Set user setting.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  postUserSettingAction(parameters = {}) {
    let path = '/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user settings.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} settingKey - 
 */
  getUserSettingAction(settingKey, parameters = {}) {
    let path = '/me/settings/{settingKey}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{settingKey}', settingKey);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user recent actions.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getUserRecentsAction(parameters = {}) {
    let path = '/me/recent';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create user recent action.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  postUserRecentAction(parameters = {}) {
    let path = '/me/recent';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update customer details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - 
 */
  putCustomerSegmentCategoryAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/segment';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Set contact to default.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
 */
  putContactDefaultAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/default';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Set contact to default.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  getUsersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user activities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.type - 
   * @param {string} parameters.sorter - 
   * @param {array} parameters.status - 
   * @param {string} parameters.from - 
   * @param {string} parameters.owner - 
   * @param {string} parameters.to - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getUserActivitiesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['owner'] !== undefined) {
      queryParameters['owner'] = parameters['owner'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create user activity.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {boolean} parameters.forced - 
   * @param {} parameters.data - 
 */
  postUserActivityAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update user activity.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The status data
 */
  putUserActivitiesStatusAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities/status';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of user activity.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} activityId - ID of user activity

   * @param {string} companyId - Shortcut of location
 */
  getUserActivityAction(activityId, companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities/{activityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{activityId}', activityId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update user activity.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   * @param {string} activityId - ID of user activity

   * @param {string} companyId - Shortcut of location
 */
  putUserActivityAction(activityId, companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities/{activityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{activityId}', activityId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete user activity.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} activityId - ID of user activity

   * @param {string} companyId - Shortcut of location
 */
  deleteUserActivityAction(activityId, companyId, parameters = {}) {
    let path = '/crm/{companyId}/activities/{activityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{activityId}', activityId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user followed messages (tasks) from office365.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getUserOffice365TasksAction(parameters = {}) {
    let path = '/me/office365/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail Office365 task.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} taskId - Id of task
 */
  getUserOffice365TaskAction(taskId, parameters = {}) {
    let path = '/me/office365/tasks/{taskId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{taskId}', taskId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List customer activities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {array} parameters.type - 
 */
  getCustomerActivitiesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * delete user ownership by userId.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {string} userId - 
 */
  deleteCustomerOwnershipAction(
    companyId,
    customerId,
    userId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/customers/{customerId}/ownership/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of services releated to customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerHeldpeskServicesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Vouchers for customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getCustomerVouchersAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {boolean} parameters.notify -
   * @param {} parameters.data -
   */
  postCustomerVoucherAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['notify'] !== undefined) {
      queryParameters['notify'] = parameters['notify'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of campaigns releated to customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
  
 */
  getCustomerCampaignsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['state'] = 'active';

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer softwares.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  getWarehouseSoftwareAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/warehouse-software';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Software update of customers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The status data
 */
  putCustomerWarehouseSoftwareAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/warehouse-software';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Software update of customers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The status data
 */
  putCustomerBasicInfoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/basic-info';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Comapany branches.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The status data
 */
  putCustomerBranchesAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/branches-company';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer videos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomerVideosAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/videos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload customer video.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.video - Video to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  postCustomerVideoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/videos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['video'] !== undefined) {
      form['video'] = parameters['video'];
    }

    if (parameters['video'] === undefined) {
      throw Error('Missing required  parameter: video');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete customer video.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to video
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  deleteCustomerVideoAction(fullPath, companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/videos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Opportunity videos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  getOpportunityVideosAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}/videos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload Opportunity video.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.video - Video to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  postOpportunityVideoAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}/videos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['video'] !== undefined) {
      form['video'] = parameters['video'];
    }

    if (parameters['video'] === undefined) {
      throw Error('Missing required  parameter: video');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete Opportunity video.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to video
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  deleteOpportunityVideoAction(
    fullPath,
    companyId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/opportunities/{opportunityId}/videos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Register XColor user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The customer data.
 */
  postOauthRegistration(parameters = {}) {
    let path = '/registration';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Opportunity photos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  getOpportunityPhotosAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload Opportunity photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.photo - Video to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  postOpportunityPhotoAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunities/{opportunityId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['photo'] !== undefined) {
      form['photo'] = parameters['photo'];
    }

    if (parameters['photo'] === undefined) {
      throw Error('Missing required  parameter: photo');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete Opportunity photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to video
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  deleteOpportunityPhotoAction(
    fullPath,
    companyId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/opportunities/{opportunityId}/photos/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} ico - ICO na vyhladanie
   */
  getPublicBusinessInfo(companyId, ico, parameters = {}) {
    let path = '/business-info/{companyId}/{ico}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ico}', ico);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} downloadId -
   */
  getDownloaderFile(downloadId, parameters = {}) {
    let path = '/downloader/{download_id}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = [
      'application/pdf, image/jpeg, image/png, image/gif, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/zip, application/postscript, video/quicktime, video/x-flv, video/mp4, video/avi, video/3gpp, video/x-msvideo, video/x-ms-wmv, image/svg+xml, application/xml, application/octet-stream',
    ];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{download_id}', downloadId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Pricelists.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
   * @param {string} companyId - Shortcut of location
 */
  getPricelistsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload pricelist.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.file - File to upload.
   * @param {string} parameters.name - Name of pricelist.
   * @param {string} parameters.dateFrom - Date of pricelist.
   * @param {string} companyId - Shortcut of location
 */
  postPricelistAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['file'] !== undefined) {
      form['file'] = parameters['file'];
    }

    if (parameters['file'] === undefined) {
      throw Error('Missing required  parameter: file');
    }

    if (parameters['name'] !== undefined) {
      form['name'] = parameters['name'];
    }

    if (parameters['name'] === undefined) {
      throw Error('Missing required  parameter: name');
    }

    if (parameters['dateFrom'] !== undefined) {
      form['dateFrom'] = parameters['dateFrom'];
    }

    if (parameters['dateFrom'] === undefined) {
      throw Error('Missing required  parameter: dateFrom');
    }

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Pricelists details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} pricelistId - Pricelist file object

 */
  getPricelistAction(companyId, pricelistId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists/{pricelistId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pricelistId}', pricelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete Pricelist.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} pricelistId - Pricelist file object

 */
  deletePricelistAction(companyId, pricelistId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists/{pricelistId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pricelistId}', pricelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get users.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter - Define sort order
   */
  getAllUsersAction(parameters = {}) {
    let path = '/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['companyId'] !== undefined) {
      queryParameters['companyId'] = parameters['companyId'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'display_name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload user photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} userId - ID of user
   * @param {file} parameters.image - Image to upload.
 */
  putUserProfilePictureAction(userId, parameters = {}) {
    let path = '/users/{userId}/profile-picture';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    path = path.replace('{userId}', userId);

    if (parameters['image'] !== undefined) {
      form['image'] = parameters['image'];
    }

    if (parameters['image'] === undefined) {
      throw Error('Missing required  parameter: image');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get user info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - ID of user
   * @param {} parameters.data -
   */
  putUserSettingsAction(userId, parameters = {}) {
    let path = '/users/{userId}/crm-settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{userId}', userId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get user info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - ID of user
   */
  getUserAction(userId, parameters = {}) {
    let path = '/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get user info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - ID of user
   * @param {} parameters.data -
   */
  putUserAction(userId, parameters = {}) {
    let path = '/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{userId}', userId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  getNavisionProducts(companyId, parameters = {}) {
    let path = '/{companyId}/products-sync';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Synchronizacion of turnover - full year
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  navisionSyncFullYearCustomersTurnoverAndBudgetEntryAction(
    companyId,
    parameters = {},
  ) {
    let path =
      '/{companyId}/navision-full-year-sync-customers-turnover-and-budget-entry';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Synchronizacion of turnover
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  navisionSyncCustomersTurnoverAndBudgetEntryAction(
    companyId,
    parameters = {},
  ) {
    let path = '/{companyId}/navision-sync-customers-turnover-and-budget-entry';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Synchronization of firm customers
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  syncNavisionCustomers(companyId, parameters = {}) {
    let path = '/{companyId}/customers-sync';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get synchronizations
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   * @param {string} parameters.status -
   * @param {string} parameters.type -
   */
  getSynchronizationsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/synchronizations';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get synchronization according to ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} syncId - ID of synchronization
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   */
  getSynchronizationsLogsAction(companyId, syncId, parameters = {}) {
    let path = '/crm/{companyId}/synchronizations/{syncId}/logs';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{syncId}', syncId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get synchronization according to ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} syncId - ID of synchronization
   */
  getSynchronizationAction(companyId, syncId, parameters = {}) {
    let path = '/crm/{companyId}/synchronizations/{syncId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{syncId}', syncId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products with prices and VAT.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.customerId -
   * @param {array} parameters.productIds -
   */
  getProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['productIds'] !== undefined) {
      queryParameters['productIds'] = parameters['productIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create discount.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} brandId - 
   * @param {} parameters.data - 
 */
  putBrandAction(companyId, brandId, parameters = {}) {
    let path = '/crm/{companyId}/products/brands/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete brand.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} brandId - 
 */
  deleteBrandAction(companyId, brandId, parameters = {}) {
    let path = '/crm/{companyId}/products/brands/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   */
  getBrandsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/brands';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create discount.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postBrandAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/brands';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete brand.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} brandId - 
 */
  deleteSubBrandAction(companyId, brandId, parameters = {}) {
    let path = '/crm/{companyId}/products/subbrands/{brandId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brandId}', brandId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   */
  getSubBrandsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/subbrands';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create discount.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postSubBrandAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/products/subbrands';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {boolean} parameters.everyPrices -
   */
  getProductAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (parameters['everyPrices'] !== undefined) {
      queryParameters['everyPrices'] = parameters['everyPrices'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   */
  getProductInventoryAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/products/{productId}/inventory';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of questions for product
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   */
  getProductQuestionsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products/questions';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Add response to question
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} responseId -
   * @param {string} questionId -
   * @param {} parameters.data -
   */
  putProductQuestionResponseAction(
    companyId,
    responseId,
    questionId,
    parameters = {},
  ) {
    let path =
      '/{companyId}/products/question/{questionId}/repsonse/{responseId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{responseId}', responseId);

    path = path.replace('{questionId}', questionId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Add response to question
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} questionId -
   * @param {} parameters.data -
   */
  postProductQuestionResponseAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Question detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} questionId -
   */
  getProductQuestionAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete question
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} questionId -
   */
  deleteProductQuestionAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Product reviews list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   * @param {string} parameters.productId -
   * @param {string} parameters.contactId -
   * @param {string} parameters.fromDate -
   * @param {string} parameters.toDate -
   * @param {number} parameters.rating -
   */
  getEshopProductsReviewsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products/reviews';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['productId'] !== undefined) {
      queryParameters['productId'] = parameters['productId'];
    }

    if (parameters['contactId'] !== undefined) {
      queryParameters['contactId'] = parameters['contactId'];
    }

    if (parameters['fromDate'] !== undefined) {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    if (parameters['toDate'] !== undefined) {
      queryParameters['toDate'] = parameters['toDate'];
    }

    if (parameters['rating'] !== undefined) {
      queryParameters['rating'] = parameters['rating'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get product review detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reviewId -
   */
  getEshopProductReviewDetailAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete product review
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reviewId -
   */
  deleteEshopProductReviewAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Aproval product review
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reviewId -
   * @param {string} parameters.approval -
   */
  putReviewAprovalRequestAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    if (parameters['approval'] !== undefined) {
      queryParameters['approval'] = parameters['approval'];
    }

    if (parameters['approval'] === undefined) {
      throw Error('Missing required  parameter: approval');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of discounts.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
   * @param {string} parameters.type - 
   * @param {boolean} parameters.onlyValid - 
 */
  getDiscountsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['onlyValid'] !== undefined) {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create discount.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postDiscountAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create/update multiple discounts.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  putMultipleDiscountsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Discount details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} discountId - ID of discount

 */
  getDiscountAction(companyId, discountId, parameters = {}) {
    let path = '/crm/{companyId}/discounts/{discountId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{discountId}', discountId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update discount details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} discountId - ID of discount

   * @param {} parameters.data - 
 */
  putDiscountAction(companyId, discountId, parameters = {}) {
    let path = '/crm/{companyId}/discounts/{discountId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{discountId}', discountId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete discount.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} discountId - ID of discount

 */
  deleteDiscountAction(companyId, discountId, parameters = {}) {
    let path = '/crm/{companyId}/discounts/{discountId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{discountId}', discountId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Campaigns list.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
   * @param {array} parameters.customerIds - 
   * @param {array} parameters.brandIds - 
   * @param {array} parameters.customerTypes - 
   * @param {array} parameters.productIds - 
   * @param {string} parameters.state - 
   * @param {boolean} parameters.onlyValid - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getCampaignsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['customerIds'] !== undefined) {
      queryParameters['customer_ids'] = parameters['customerIds'];
    }

    if (parameters['brandIds'] !== undefined) {
      queryParameters['brand_ids'] = parameters['brandIds'];
    }

    if (parameters['customerTypes'] !== undefined) {
      queryParameters['customer_types'] = parameters['customerTypes'];
    }

    if (parameters['productIds'] !== undefined) {
      queryParameters['product_ids'] = parameters['productIds'];
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    if (parameters['onlyValid'] !== undefined) {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create campaign.
-Alternative D - additional discount on everything. 
-Alternative G - Product for 0.01€ / 1 € with order. 
-Alternative H - Secont product with discount.
-Alternative I - Discount on selected products with order above certain price.
-Alternative J - Get selected product with order above certain price.
-Alternative K - Increase general discount for customer on selected products.
-Alternative L - Free product with order.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postCampaignAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create copy of campaign

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

 */
  postCampaignCopyAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Set validity of multiple campaigns.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  putCampainsValidityAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/validity';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Vouchers for campaign.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getCampaignVouchersAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Campaigns details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

 */
  getCampaignAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update campaign details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

   * @param {} parameters.data - 
 */
  putCampaignAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete campaign.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

 */
  deleteCampaignAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update campaign details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

   * @param {} parameters.data - 
 */
  putCampaignApprovalRequestDecisionAction(
    companyId,
    campaignId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/campaigns/{campaignId}/approval-request-decision';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update discount details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  putDiscountsApprovalRequestDecisionAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/discounts-approval-request-decision';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update campaign details to pending.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} campaignId - ID od campaign

   * @param {} parameters.data - 
 */
  putCampaignApprovalRequestAction(companyId, campaignId, parameters = {}) {
    let path = '/crm/{companyId}/campaigns/{campaignId}/approval-request';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{campaignId}', campaignId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Creates bill
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The in/out data.
   */
  cashRegisterMoneyInOutAction(parameters = {}) {
    let path = '/cash-register/money/inout';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get bills list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.shopId -
   * @param {string} parameters.fromDate -
   * @param {string} parameters.toDate -
   * @param {string} parameters.payments -
   * @param {string} parameters.contactId -
   * @param {string} parameters.status -
   * @param {string} parameters.sorter - Define sort order
   */
  getKasaBillsAction(companyId, parameters = {}) {
    let path = '/cash-register/{companyId}/bills/list';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['shopId'] !== undefined) {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    if (parameters['fromDate'] !== undefined) {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    if (parameters['toDate'] !== undefined) {
      queryParameters['toDate'] = parameters['toDate'];
    }

    if (parameters['payments'] !== undefined) {
      queryParameters['payments'] = parameters['payments'];
    }

    if (parameters['contactId'] !== undefined) {
      queryParameters['contact_id'] = parameters['contactId'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'createdDate';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get bill detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} billId -
   */
  getKasaBillAction(billId, parameters = {}) {
    let path = '/cash-register/bill/{billId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{billId}', billId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Re-send bill to navision
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} billId -
   */
  sendKasaBillToNavAction(companyId, billId, parameters = {}) {
    let path = '/cash-register/{companyId}/bill/{billId}/nav-send';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{billId}', billId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Machines list.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
   * @param {boolean} parameters.active - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.customerId - 
 */
  getMachinesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/machines';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new machine.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postMachineAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/machines';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Activate/deactivate multiple machines.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  putMachinesStatusAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/machines/status';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Machine detail.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} machineId - ID od machine

 */
  getMachineAction(companyId, machineId, parameters = {}) {
    let path = '/crm/{companyId}/machines/{machineId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{machineId}', machineId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update machine.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} machineId - ID od machine

   * @param {} parameters.data - 
 */
  putMachineAction(companyId, machineId, parameters = {}) {
    let path = '/crm/{companyId}/machines/{machineId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{machineId}', machineId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete machine.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} machineId - ID od machine

 */
  deleteMachineAction(companyId, machineId, parameters = {}) {
    let path = '/crm/{companyId}/machines/{machineId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{machineId}', machineId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customer discounts.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - 
 */
  getCustomerDiscountsAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/discounts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of service solvers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.type - Type of service
   * @param {string} parameters.subtype - Subtype of service
 */
  getHelpdeskServiceSolversAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk-solvers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['subtype'] !== undefined) {
      queryParameters['subtype'] = parameters['subtype'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of service activities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
   * @param {string} parameters.sorter - 
 */
  getHelpdeskServiceActivitiesAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of service history.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
 */
  getHelpdeskServiceHistory(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/history';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Add new item in helpdesk service history.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
   * @param {} parameters.data - 
   * @param {boolean} parameters.sendNotification - 
 */
  putHelpdeskHistoryAction(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/history';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    /** set default value **/
    queryParameters['sendNotification'] = 'true';

    if (parameters['sendNotification'] !== undefined) {
      queryParameters['sendNotification'] = parameters['sendNotification'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Change helpdesk service solver

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} serviceId - ID of helpdesk service
   * @param {} parameters.data - 
 */
  putHelpdeskServiceSolver(companyId, serviceId, parameters = {}) {
    let path = '/crm/{companyId}/helpdesk/{serviceId}/solver';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{serviceId}', serviceId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of defined colorcards

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q - 
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {boolean} parameters.removeDupliciteShades - 
 */
  getCatalogProductColorcardsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['removeDupliciteShades'] !== undefined) {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of defined colorcards

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.q - 
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {boolean} parameters.removeDupliciteShades - 
 */
  getB2BCatalogProductColorcardsAction(productId, parameters = {}) {
    let path = '/eshop/catalog-products/{productId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['removeDupliciteShades'] !== undefined) {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of defined colorcards

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q - 
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.toningSystemId - 
   * @param {boolean} parameters.onlyFavorit - 
   * @param {string} parameters.sorter - 
 */
  getColorcardsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['toningSystemId'] !== undefined) {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    if (parameters['onlyFavorit'] !== undefined) {
      queryParameters['onlyFavorit'] = parameters['onlyFavorit'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new colorcard

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postColorcardAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of colorcard shades

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} colorcardId - Colorcard ID
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
 */
  getColorcardShadesAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcard/{colorcardId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of colorcards

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} colorcardId - Colorcard ID
   * @param {boolean} parameters.removeDupliciteShades - 
   * @param {string} parameters.productId - 
   * @param {boolean} parameters.sendShades - 
 */
  getColorcardAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    if (parameters['removeDupliciteShades'] !== undefined) {
      queryParameters['removeDupliciteShades'] =
        parameters['removeDupliciteShades'];
    }

    if (parameters['productId'] !== undefined) {
      queryParameters['productId'] = parameters['productId'];
    }

    /** set default value **/
    queryParameters['sendShades'] = 'true';

    if (parameters['sendShades'] !== undefined) {
      queryParameters['sendShades'] = parameters['sendShades'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of colorcards

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} colorcardId - Colorcard ID
   * @param {boolean} parameters.forced - 
   * @param {} parameters.data - 
 */
  putColorcardAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete colorcard

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} colorcardId - Colorcard ID
   * @param {boolean} parameters.forced - 
 */
  deleteColorcardAction(companyId, colorcardId, parameters = {}) {
    let path = '/crm/{companyId}/colorcards/{colorcardId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{colorcardId}', colorcardId);

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload Opportunity photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.image - Image to upload.
   * @param {string} companyId - Shortcut of location
 */
  postColorCardTextureImageAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcardsTexture';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['image'] !== undefined) {
      form['image'] = parameters['image'];
    }

    if (parameters['image'] === undefined) {
      throw Error('Missing required  parameter: image');
    }

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete customer photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to photo
   * @param {string} companyId - Shortcut of location
 */
  deleteColorCardImageAction(fullPath, companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcardsTexture/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of defined toning systems

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
 */
  getToningSystemsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new toning system

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postToningSystemAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of toning system

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} systemId - Toning System ID
 */
  getToningSystemAction(companyId, systemId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems/{systemId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{systemId}', systemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of toning system

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} systemId - Toning System ID
   * @param {} parameters.data - 
   * @param {boolean} parameters.forced - 
 */
  putToningSystemAction(companyId, systemId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems/{systemId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{systemId}', systemId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete toning system

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} systemId - Toning System ID
   * @param {boolean} parameters.forced - 
 */
  deleteToningSystemAction(companyId, systemId, parameters = {}) {
    let path = '/crm/{companyId}/toning-systems/{systemId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{systemId}', systemId);

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of formulas

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
 */
  getFormulasAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/formulas';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new formula

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postFormulaAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/formulas';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of formula

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} formulaId - Formula ID
 */
  getFormulaAction(companyId, formulaId, parameters = {}) {
    let path = '/crm/{companyId}/formulas/{formulaId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{formulaId}', formulaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of toning system

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} formulaId - Formula ID
   * @param {} parameters.data - 
 */
  putFormulaAction(companyId, formulaId, parameters = {}) {
    let path = '/crm/{companyId}/formulas/{formulaId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{formulaId}', formulaId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete formula

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} formulaId - Formula ID
 */
  deleteFormulaAction(companyId, formulaId, parameters = {}) {
    let path = '/crm/{companyId}/formulas/{formulaId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{formulaId}', formulaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of of custom shades

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {array} parameters.bases - 
 */
  getCustomShadesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['bases'] !== undefined) {
      queryParameters['bases'] = parameters['bases'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new custom shade

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postCustomeShadeAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of formula

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customShadeId - Custom shade ID
 */
  getCustomShadeAction(companyId, customShadeId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of toning system

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customShadeId - Custom shade ID
   * @param {} parameters.data - 
 */
  putCustomShadeAction(companyId, customShadeId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete formula

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customShadeId - Custom shade ID
 */
  deleteCustomShadeAction(companyId, customShadeId, parameters = {}) {
    let path = '/crm/{companyId}/custom-shades/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of shade groups

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
 */
  getShadeGroups(parameters = {}) {
    let path = '/crm/shade-groups';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of shade groups

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  putShadeGroups(parameters = {}) {
    let path = '/crm/shade-groups';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Authorize B2B user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.redirectUri -
   * @param {string} parameters.appId -
   * @param {array} parameters.scopes -
   * @param {string} parameters.username -
   * @param {string} parameters.password -
   * @param {string} parameters.state -
   */
  oauthAuthorizeB2BAction(parameters = {}) {
    let path = '/oauth/authorize/b2b';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/x-www-form-urlencoded'];

    if (parameters['redirectUri'] !== undefined) {
      form['redirect_uri'] = parameters['redirectUri'];
    }

    if (parameters['redirectUri'] === undefined) {
      throw Error('Missing required  parameter: redirectUri');
    }

    if (parameters['appId'] !== undefined) {
      form['app_id'] = parameters['appId'];
    }

    if (parameters['appId'] === undefined) {
      throw Error('Missing required  parameter: appId');
    }

    if (parameters['scopes'] !== undefined) {
      form['scopes'] = parameters['scopes'];
    }

    if (parameters['scopes'] === undefined) {
      throw Error('Missing required  parameter: scopes');
    }

    if (parameters['username'] !== undefined) {
      form['username'] = parameters['username'];
    }

    if (parameters['username'] === undefined) {
      throw Error('Missing required  parameter: username');
    }

    if (parameters['password'] !== undefined) {
      form['password'] = parameters['password'];
    }

    if (parameters['password'] === undefined) {
      throw Error('Missing required  parameter: password');
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Authorize B2B user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.appId -
   * @param {array} parameters.scopes -
   * @param {string} parameters.username -
   * @param {string} parameters.password -
   * @param {string} parameters.state -
   */
  oauthAuthorizeB2BMobileAction(parameters = {}) {
    let path = '/oauth/authorize/b2b-mobile';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/x-www-form-urlencoded'];

    if (parameters['appId'] !== undefined) {
      form['app_id'] = parameters['appId'];
    }

    if (parameters['appId'] === undefined) {
      throw Error('Missing required  parameter: appId');
    }

    if (parameters['scopes'] !== undefined) {
      form['scopes'] = parameters['scopes'];
    }

    if (parameters['scopes'] === undefined) {
      throw Error('Missing required  parameter: scopes');
    }

    if (parameters['username'] !== undefined) {
      form['username'] = parameters['username'];
    }

    if (parameters['username'] === undefined) {
      throw Error('Missing required  parameter: username');
    }

    if (parameters['password'] !== undefined) {
      form['password'] = parameters['password'];
    }

    if (parameters['password'] === undefined) {
      throw Error('Missing required  parameter: password');
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthB2bRestorePasswordRequest(parameters = {}) {
    let path = '/oauth/b2b/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthXPaintRestorePasswordRequest(parameters = {}) {
    let path = '/oauth/xPaint/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get password reset token info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken -
   */
  oauthB2bRestorePasswordInfo(passwordToken, parameters = {}) {
    let path = '/oauth/b2b/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{passwordToken}', passwordToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken -
   * @param {} parameters.data -
   */
  oauthB2bRestorePassword(passwordToken, parameters = {}) {
    let path = '/oauth/b2b/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{passwordToken}', passwordToken);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of formula

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} opportunityId - ID of opprotunity
 */
  getOpportunityActivitiesAction(companyId, opportunityId, parameters = {}) {
    let path = '/crm/{companyId}/opportunies/{opportunityId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Turnover informations by brand..

 * @method
 * @param {object} parameters - method options and parameters
   * @param {number} parameters.year - Year
 */
  getB2BTurnoverByBrandAction(parameters = {}) {
    let path = '/b2b/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of contacts.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BContactsAction(parameters = {}) {
    let path = '/b2b/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables for user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {number} parameters.year - Year
 */
  getB2BReceivablesAction(parameters = {}) {
    let path = '/b2b/receivables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get informations about B2B user.

 * @method
 * @param {object} parameters - method options and parameters
 */
  b2bMeAction(parameters = {}) {
    let path = '/b2b/me';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get roles.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BRolesAction(parameters = {}) {
    let path = '/b2b/roles';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get role permisions map.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BRolesPermisionsMap(parameters = {}) {
    let path = '/b2b/menu-permisions-map';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get menu for user.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BUserMenuItems(parameters = {}) {
    let path = '/b2b/user-menu-items';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update contact credentials.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - 
   * @param {boolean} parameters.copyFromB2B - 
 */
  putContactXPaintCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials-xpaint';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    if (parameters['copyFromB2B'] !== undefined) {
      queryParameters['copyFromB2B'] = parameters['copyFromB2B'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update contact credentials.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
 */
  deleteContactXPaintCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials-xpaint';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get contact credentials.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
 */
  getContactCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update contact credentials.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - 
 */
  putContactCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update contact credentials.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
 */
  deleteContactCredentialsAction(companyId, contactId, parameters = {}) {
    let path = '/crm/{companyId}/contacts/{contactId}/credentials';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products with prices and VAT.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {array} parameters.campaignIds -
   * @param {boolean} parameters.onlyCampaignProducts -
   * @param {string} parameters.sorter -
   * @param {array} parameters.brands -
   */
  getB2bProductsAction(parameters = {}) {
    let path = '/b2b/products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['campaignIds'] !== undefined) {
      queryParameters['campaignIds'] = parameters['campaignIds'];
    }

    if (parameters['onlyCampaignProducts'] !== undefined) {
      queryParameters['onlyCampaignProducts'] =
        parameters['onlyCampaignProducts'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['brands'] !== undefined) {
      queryParameters['brands'] = parameters['brands'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List plans for logged B2B user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {number} parameters.year - Year
   * @param {string} parameters.cummulativeTo - Date
 */
  getB2BTurnoverPlanAction(parameters = {}) {
    let path = '/b2b/turnover-plan';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['cummulativeTo'] !== undefined) {
      queryParameters['cummulativeTo'] = parameters['cummulativeTo'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {number} parameters.year - Year
 */
  getB2BCustomerAction(parameters = {}) {
    let path = '/b2b/customer';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get carts.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getCartsAction(parameters = {}) {
    let path = '/eshop/cart';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new cart.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  createCartAction(parameters = {}) {
    let path = '/eshop/cart';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Add price offer items to cart.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {boolean} parameters.addToExistingItem - 
   * @param {} parameters.data - 
 */
  postPriceOffersProductsCartItemsAction(cartId, parameters = {}) {
    let path = '/eshop/cart-priceOffer/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['addToExistingItem'] !== undefined) {
      queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Add voucher to cart.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {boolean} parameters.addToExistingItem - 
   * @param {} parameters.data - 
 */
  postCartVoucherAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/apply-voucher';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['addToExistingItem'] !== undefined) {
      queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete cart voucher.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {} parameters.data - 
 */
  deleteCartVoucherAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/apply-voucher';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get cart info.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {boolean} parameters.cached - Return cart overview
 */
  getCartAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['cached'] !== undefined) {
      queryParameters['cached'] = parameters['cached'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Add item to cart.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {boolean} parameters.addToExistingItem - 
   * @param {} parameters.data - 
 */
  postCartItemAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['addToExistingItem'] !== undefined) {
      queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update cart metadata.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {} parameters.data - 
 */
  putCartAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete cart.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
 */
  deleteCartAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Add item to cart.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {} parameters.data - 
 */
  postCartConfigurationItemsAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/configuration';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete cart item.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {string} configurationId - ID of cart
   * @param {boolean} parameters.campaignProduct - 
   * @param {string} parameters.colorCode - 
 */
  deleteCartConfigurationProductAction(
    cartId,
    configurationId,
    parameters = {},
  ) {
    let path = '/eshop/cart/{cartId}/configuration/{configurationId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    path = path.replace('{configurationId}', configurationId);

    if (parameters['campaignProduct'] !== undefined) {
      queryParameters['campaignProduct'] = parameters['campaignProduct'];
    }

    if (parameters['colorCode'] !== undefined) {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Eshop order import.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.attachment - Attachment file.
   * @param {boolean} parameters.deleteActualItems - Delete actual items in cart.
 */
  postOrderImport(parameters = {}) {
    let path = '/eshop/cart/import';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['attachment'] !== undefined) {
      form['attachment'] = parameters['attachment'];
    }

    if (parameters['attachment'] === undefined) {
      throw Error('Missing required  parameter: attachment');
    }

    if (parameters['deleteActualItems'] !== undefined) {
      queryParameters['deleteActualItems'] = parameters['deleteActualItems'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create cart order.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
 */
  postCartOrderAction(cartId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/order';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update cart item.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {string} productId - ID of product
   * @param {} parameters.data - 
   * @param {string} parameters.colorCode - 
 */
  putCartProductAction(cartId, productId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['colorCode'] !== undefined) {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete cart item.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {string} productId - ID of product
   * @param {boolean} parameters.campaignProduct - 
   * @param {string} parameters.colorCode - 
   * @param {string} parameters.offerId - 
 */
  deleteCartProductAction(cartId, productId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (parameters['campaignProduct'] !== undefined) {
      queryParameters['campaignProduct'] = parameters['campaignProduct'];
    }

    if (parameters['colorCode'] !== undefined) {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    if (parameters['offerId'] !== undefined) {
      queryParameters['offerId'] = parameters['offerId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update cart item.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartId - ID of cart
   * @param {string} productId - ID of product
   * @param {} parameters.data - 
   * @param {string} parameters.colorCode - 
   * @param {string} parameters.offerId - 
 */
  putCartProductDescAction(cartId, productId, parameters = {}) {
    let path = '/eshop/cart/{cartId}/{productId}/description';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['colorCode'] !== undefined) {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    if (parameters['offerId'] !== undefined) {
      queryParameters['offerId'] = parameters['offerId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get b2b address.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BAddresses(parameters = {}) {
    let path = '/b2b/address';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Post new b2b address.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  postB2BAddress(parameters = {}) {
    let path = '/b2b/address';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update b2b address.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} addressId - ID of cart
 */
  putB2BDefaultAddress(addressId, parameters = {}) {
    let path = '/b2b/address-default/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update b2b address.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} addressId - ID of cart
   * @param {} parameters.data - 
 */
  putB2BAddress(addressId, parameters = {}) {
    let path = '/b2b/address/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Remove b2b address.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} addressId - ID of cart
 */
  deleteB2BAddress(addressId, parameters = {}) {
    let path = '/b2b/address/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return orders from NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   * @param {number} parameters.year - Year
   * @param {string} parameters.q -
   * @param {number} parameters.month -
   */
  getB2bOrdersAction(parameters = {}) {
    let path = '/b2b/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Post B2B Order.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  postB2BOrderAction(parameters = {}) {
    let path = '/b2b/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get B2B Order detail.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} orderId - ID of order
 */
  getB2BOrderAction(orderId, parameters = {}) {
    let path = '/b2b/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * "List of MS Graph Calendar events. There will be events from calendar with name 'CRM'. Few cases can occure:
- calendar with name 'CRM' not exists in MS graph - no events will be listed.
- calendar was removed from MS graph out of CRM - no events will be listed listed until new event will be created
- calendar was recreated outside of CRM - no event will be listed until new event will be created"

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} userId - ID of user
   * @param {string} parameters.from - 
   * @param {string} parameters.to - 
 */
  getBiUserCalendarAction(companyId, userId, parameters = {}) {
    let path = '/bi/{companyId}/users/{userId}/calendar';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of user services.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} userId - ID of user
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.status - 
   * @param {string} parameters.sorter - 
 */
  getBiUserHelpdeskServicesAction(companyId, userId, parameters = {}) {
    let path = '/bi/{companyId}/users/{userId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user followed messages (tasks) from office365.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} userId - ID of user
 */
  getBiUserOffice365TasksAction(companyId, userId, parameters = {}) {
    let path = '/bi/{companyId}/users/{userId}/tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List user activities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} userId - ID of user
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.type - 
   * @param {string} parameters.sorter - 
   * @param {array} parameters.status - 
   * @param {string} parameters.from - 
   * @param {string} parameters.to - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getBiUserActivitiesAction(companyId, userId, parameters = {}) {
    let path = '/bi/{companyId}/users/{userId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['from'] !== undefined) {
      queryParameters['from'] = parameters['from'];
    }

    if (parameters['to'] !== undefined) {
      queryParameters['to'] = parameters['to'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * CSV file user activities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} userId - ID of user
   * @param {array} parameters.type - 
   * @param {string} parameters.sorter - 
   * @param {array} parameters.status - 
   * @param {string} parameters.year - 
   * @param {boolean} parameters.onlyOwned - 
   * @param {string} parameters.q - 
 */
  getUserActivitiesCsvAction(companyId, userId, parameters = {}) {
    let path = '/crm/{companyId}/users/{userId}/activities-csv-export';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{userId}', userId);

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get business info of customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getCustomersBusinessInfoAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/business-info';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get business info of customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} codelistId - ID od codelist
 */
  getCodelistAction(codelistId, parameters = {}) {
    let path = '/crm/codelist/{codelistId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{codelistId}', codelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get codelist for B2B app.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} codelistId - ID od codelist
 */
  getB2BCodelistAction(codelistId, parameters = {}) {
    let path = '/b2b/codelist/{codelistId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{codelistId}', codelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get reports list.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - 
   * @param {string} parameters.type - 
   * @param {string} parameters.year - 
   * @param {string} parameters.owner - 
   * @param {string} parameters.q - 
 */
  getReportsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/reports';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['owner'] !== undefined) {
      queryParameters['owner'] = parameters['owner'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get business info of customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postReportAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/reports';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Report detail.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  getReportAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {} parameters.data - 
 */
  putReportAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  deleteReportAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  putUpdateReportReceivables(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/receivable-update';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} parameters.status - 
   * @param {} parameters.data - 
 */
  putChangeReportStatusAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/status-change';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of tunover for actual and previous year.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getReportMonthTurnoverAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/turnover';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of tunover by brand for actual and previous year.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
   * @param {boolean} parameters.monthStats - 
 */
  getReportMonthTurnoverByBrandAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/turnover-by-brand';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    if (parameters['monthStats'] !== undefined) {
      queryParameters['monthStats'] = parameters['monthStats'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customers for report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {number} parameters.year - 
   * @param {string} parameters.q - 
   * @param {number} parameters.month - 
   * @param {string} parameters.sorter - Define sort order
 */
  getReportCustomersAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/customers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'turnover_actual';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of opportunities for report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {number} parameters.year - 
   * @param {string} parameters.q - 
   * @param {number} parameters.month - 
 */
  getReportOpportunitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/opportunities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of customer for report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getReportCustomerDetailAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/reports/{reportId}/customers/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of customer for report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} opportunityId - ID of opprotunity
   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getReportOpportunityDetailAction(
    companyId,
    reportId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/opportunities/{opportunityId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{opportunityId}', opportunityId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of customer for report.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} opportunityId - ID of opprotunity
 */
  getReportOpportunitiesActivitiesAction(
    companyId,
    reportId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/opportunities/{opportunityId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Opportunity photos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} opportunityId - ID of opprotunity
 */
  getReportOpportunityPhotosAction(
    companyId,
    reportId,
    opportunityId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/opportunities/{opportunityId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{opportunityId}', opportunityId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of user activities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getReportCustomerActivitiesAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/customers/{customerId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of services releated to customer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getReportCustomerServicesAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/customers/{customerId}/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer photos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} customerId - Customer id for which must be data loaded
   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getReportCustomerPhotosAction(
    companyId,
    reportId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/customers/{customerId}/photos';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{customerId}', customerId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get B2B Order invoice file.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} orderId - ID of order
 */
  getB2BOrderInvoiceAction(orderId, parameters = {}) {
    let path = '/b2b/orders/{orderId}/invoice';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get CRM Order invoice file.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} orderId - ID of order
   * @param {string} companyId - Shortcut of location
 */
  getOrderInvoiceAction(orderId, companyId, parameters = {}) {
    let path = '/crm/{companyId}/orders/{orderId}/invoice';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get published news.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.lang - 
 */
  getNewsListAction(parameters = {}) {
    let path = '/b2b/news';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['lang'] !== undefined) {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get news detail.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} newsId - ID of news
 */
  getNewsAction(newsId, parameters = {}) {
    let path = '/b2b/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload customer video.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} newsId - ID of news
   * @param {file} parameters.video - Video to upload.
 */
  postNewsVideoAction(newsId, parameters = {}) {
    let path = '/b2b/news/{newsId}/video';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    path = path.replace('{newsId}', newsId);

    if (parameters['video'] !== undefined) {
      form['video'] = parameters['video'];
    }

    if (parameters['video'] === undefined) {
      throw Error('Missing required  parameter: video');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete news video.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to video
   * @param {string} newsId - ID of news
 */
  deleteNewsVideoAction(fullPath, newsId, parameters = {}) {
    let path = '/b2b/news/{newsId}/video/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Customer photos.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {number} parameters.year - 
   * @param {number} parameters.month - 
 */
  getMonthActivitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Check customer existance by ICO.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} regNr - ICO
 */
  checkCustomersRegNrExistsAction(companyId, regNr, parameters = {}) {
    let path = '/crm/{companyId}/customer-regnr-check/{regNr}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{regNr}', regNr);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Week turnover of user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  getWeekTurnoverAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/turnover-week';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Week activities summary of user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  getWeekSummeryActivitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/activities-summary-week';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * All activities of user for current week.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  getUserWeekActivitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/user-week-activities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * All opportunities of user for current week.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  getUserWeekOpportunitiesAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/user-week-opportunities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Current week leads information.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

 */
  getUserWeekLeadsAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/user-week-leads';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create receivable description

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {string} invoiceId - Id of invoice
   * @param {string} taskId - Id of task
   * @param {} parameters.data - 
 */
  putReceivableTaskStatusToFinishAction(
    companyId,
    reportId,
    invoiceId,
    taskId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/reports/{reportId}/invoices/{invoiceId}/tasks/{taskId}/finish';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    path = path.replace('{invoiceId}', invoiceId);

    path = path.replace('{taskId}', taskId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create receivable description

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} reportId - ID od report

   * @param {} parameters.data - 
 */
  postReceivableDescriptionsAction(companyId, reportId, parameters = {}) {
    let path = '/crm/{companyId}/reports/{reportId}/invoices-descriptions';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reportId}', reportId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customer discounts.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BDiscountsAction(parameters = {}) {
    let path = '/b2b/discounts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of campaigns for B2B.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
  
   * @param {string} parameters.sorter - 
 */
  getB2BCustomerCampaignsAction(parameters = {}) {
    let path = '/b2b/campaigns';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['state'] = 'active';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customer vouchers.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getB2BCustomerVouchersAction(parameters = {}) {
    let path = '/b2b/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of campaigns for B2B.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} campaignId - ID od campaign

 */
  getB2BCustomerCampaignVouchersAction(campaignId, parameters = {}) {
    let path = '/b2b/campaigns/{campaignId}/vouchers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{campaignId}', campaignId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of helpdesk services.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.status - 
   * @param {string} parameters.q - 
   * @param {string} parameters.filters - 
   * @param {array} parameters.type - Type of service
   * @param {string} parameters.sorter - 
 */
  getB2BHelpdeskServicesAction(parameters = {}) {
    let path = '/b2b/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  postB2BHelpdeskServiceAction(parameters = {}) {
    let path = '/b2b/helpdesk';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
 */
  getB2BHelpdeskServiceAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
   * @param {} parameters.data - 
 */
  putB2BHelpdeskServiceAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
 */
  deleteB2BHelpdeskServiceAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of attachments linked to helpdesk service.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
 */
  getB2BHelpdeskServiceAttachmentsAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create helpdesk attachment.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.attachment - Attachment file.
   * @param {string} parameters.historyId - 
   * @param {string} parameters.descriptionFile - 
   * @param {string} serviceId - ID of helpdesk service
 */
  postB2BHelpdeskServiceAttachmentAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['attachment'] !== undefined) {
      form['attachment'] = parameters['attachment'];
    }

    if (parameters['attachment'] === undefined) {
      throw Error('Missing required  parameter: attachment');
    }

    if (parameters['historyId'] !== undefined) {
      queryParameters['historyId'] = parameters['historyId'];
    }

    if (parameters['descriptionFile'] !== undefined) {
      queryParameters['descriptionFile'] = parameters['descriptionFile'];
    }

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete service attachment.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} fullPath - Escaped full path to attachment
   * @param {string} serviceId - ID of helpdesk service
 */
  deleteB2BHelpdeskServiceAttachmentAction(
    fullPath,
    serviceId,
    parameters = {},
  ) {
    let path = '/b2b/helpdesk/{serviceId}/attachments/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{fullPath}', fullPath);

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of B2B service history.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
 */
  getB2bHelpdeskServiceHistoryAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}/history';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Add new item in B2B helpdesk service history.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} serviceId - ID of helpdesk service
   * @param {} parameters.data - 
   * @param {boolean} parameters.sendNotification - 
 */
  putB2bHelpdeskHistoryAction(serviceId, parameters = {}) {
    let path = '/b2b/helpdesk/{serviceId}/history';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{serviceId}', serviceId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    /** set default value **/
    queryParameters['sendNotification'] = 'true';

    if (parameters['sendNotification'] !== undefined) {
      queryParameters['sendNotification'] = parameters['sendNotification'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get customers machines for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   */
  getB2BCustomerMachinesAction(parameters = {}) {
    let path = '/b2b/machines';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Duplicate B2B order.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} orderId - ID of order
 */
  postB2BOrderDuplicateAction(orderId, parameters = {}) {
    let path = '/b2b/orders/{orderId}/duplicate';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return product details by EAN.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.ean -
   */
  getB2bProductByEanAction(parameters = {}) {
    let path = '/b2b/product-by-ean';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['ean'] !== undefined) {
      queryParameters['ean'] = parameters['ean'];
    }

    if (parameters['ean'] === undefined) {
      throw Error('Missing required  parameter: ean');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * B2B payment types by firm.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BPaymentTypesAction(parameters = {}) {
    let path = '/b2b/payment-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * CRM payment types by firm.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  getPaymentTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/payment-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * B2B delivery types by customer firm.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getB2BDeliveryTypesAction(parameters = {}) {
    let path = '/b2b/delivery-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * CRM delivery types by customer firm.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  getDeliveryTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/delivery-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return catalog products for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   * @param {string} parameters.customerId -
   */
  getCatalogProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return catalog products for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   * @param {boolean} parameters.canBeToned -
   * @param {boolean} parameters.getFullLimit -
   */
  getLiteCatalogProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-lite';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['canBeToned'] !== undefined) {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    if (parameters['getFullLimit'] !== undefined) {
      queryParameters['getFullLimit'] = parameters['getFullLimit'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return catalog products for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   * @param {boolean} parameters.canBeToned -
   */
  getB2BCatalogProductsAction(parameters = {}) {
    let path = '/b2b/catalog-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['canBeToned'] !== undefined) {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return catalog products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   * @param {boolean} parameters.canBeToned -
   */
  getLiteB2BCatalogProductsAction(parameters = {}) {
    let path = '/b2b/catalog-products-lite';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['canBeToned'] !== undefined) {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return catalog products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   * @param {boolean} parameters.canBeToned -
   * @param {boolean} parameters.onlyForSpray -
   * @param {boolean} parameters.getFullLimit -
   */
  getUltraLiteB2BCatalogProductsAction(parameters = {}) {
    let path = '/b2b/catalog-products-ultra-lite';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['canBeToned'] !== undefined) {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    if (parameters['onlyForSpray'] !== undefined) {
      queryParameters['onlyForSpray'] = parameters['onlyForSpray'];
    }

    if (parameters['getFullLimit'] !== undefined) {
      queryParameters['getFullLimit'] = parameters['getFullLimit'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return catalog products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.customerId -
   * @param {string} parameters.filters -
   * @param {boolean} parameters.canBeToned -
   */
  getUltraLiteCatalogProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-ultra-lite';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['canBeToned'] !== undefined) {
      queryParameters['canBeToned'] = parameters['canBeToned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {string} parameters.customerId -
   */
  getCatalogProductAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   */
  getB2BCatalogProductAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Documentation of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   */
  getCatalogProductDocumentationAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Documentation of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   */
  getB2BCatalogProductDocumentationAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Variants of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   */
  getCatalogProductVariantsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Variants of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   */
  getB2BCatalogProductVariantsAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Price recalculation with custom shadeId for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customShadeId - Custom shade ID
   * @param {string} productId - ID of product
   * @param {string} parameters.toningSystemId -
   * @param {string} parameters.customerId -
   * @param {string} parameters.base -
   */
  getCatalogProductCustomShadePricesAction(
    companyId,
    customShadeId,
    productId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/catalog-products-custom-shade/{productId}/prices/{customShadeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customShadeId}', customShadeId);

    path = path.replace('{productId}', productId);

    if (parameters['toningSystemId'] !== undefined) {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['base'] !== undefined) {
      queryParameters['base'] = parameters['base'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Price recalculation with shadeId for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {string} parameters.shadeId -
   * @param {string} parameters.colorCardId -
   * @param {string} parameters.toningSystemId -
   * @param {string} parameters.customerId -
   * @param {string} parameters.base -
   * @param {boolean} parameters.applyDiscounts -
   */
  getCatalogProductPricesAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (parameters['shadeId'] !== undefined) {
      queryParameters['shadeId'] = parameters['shadeId'];
    }

    if (parameters['colorCardId'] !== undefined) {
      queryParameters['colorCardId'] = parameters['colorCardId'];
    }

    if (parameters['toningSystemId'] !== undefined) {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['base'] !== undefined) {
      queryParameters['base'] = parameters['base'];
    }

    if (parameters['applyDiscounts'] !== undefined) {
      queryParameters['applyDiscounts'] = parameters['applyDiscounts'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  getCatalogCombinedFiltersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/combined-filters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BCatalogCombinedFiltersAction(parameters = {}) {
    let path = '/b2b/combined-filters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {array} parameters.productTypes -
   */
  getCatalogFiltersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products-filters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['productTypes'] !== undefined) {
      queryParameters['productTypes'] = parameters['productTypes'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Filter for catalog products
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.productTypes -
   */
  getB2BCatalogFiltersAction(parameters = {}) {
    let path = '/b2b/catalog-products-filters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['productTypes'] !== undefined) {
      queryParameters['productTypes'] = parameters['productTypes'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Price recalculation with shadeId for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {string} parameters.shadeId -
   * @param {string} parameters.colorCardId -
   * @param {string} parameters.toningSystemId -
   * @param {string} parameters.base -
   * @param {boolean} parameters.applyDiscount -
   * @param {boolean} parameters.applyMargin -
   */
  getB2BCatalogProductPricesAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    if (parameters['shadeId'] !== undefined) {
      queryParameters['shadeId'] = parameters['shadeId'];
    }

    if (parameters['colorCardId'] !== undefined) {
      queryParameters['colorCardId'] = parameters['colorCardId'];
    }

    if (parameters['toningSystemId'] !== undefined) {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    if (parameters['base'] !== undefined) {
      queryParameters['base'] = parameters['base'];
    }

    if (parameters['applyDiscount'] !== undefined) {
      queryParameters['applyDiscount'] = parameters['applyDiscount'];
    }

    if (parameters['applyMargin'] !== undefined) {
      queryParameters['applyMargin'] = parameters['applyMargin'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get available spray filling configurations for selected product
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   */
  getB2BSprayFillingConfigurationAction(productId, parameters = {}) {
    let path = '/b2b/spray-configuration/{productId}/pieces';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Price recalculation with shadeId for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {string} parameters.shadeId -
   * @param {string} parameters.colorCardId -
   * @param {string} parameters.toningSystemId -
   * @param {string} parameters.base -
   * @param {number} parameters.quantity -
   */
  getB2BSprayConfigurationPricesAction(productId, parameters = {}) {
    let path = '/b2b/spray-configuration/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    if (parameters['shadeId'] !== undefined) {
      queryParameters['shadeId'] = parameters['shadeId'];
    }

    if (parameters['colorCardId'] !== undefined) {
      queryParameters['colorCardId'] = parameters['colorCardId'];
    }

    if (parameters['toningSystemId'] !== undefined) {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    if (parameters['base'] !== undefined) {
      queryParameters['base'] = parameters['base'];
    }

    if (parameters['quantity'] !== undefined) {
      queryParameters['quantity'] = parameters['quantity'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
   */
  getCustomerCampaignsPdfAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customers/{customerId}/campaigns-pdf';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/pdf'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return paint systems for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   */
  getPaintSystemsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return paint systems for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   */
  getB2BPaintSystemsAction(parameters = {}) {
    let path = '/b2b/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of catalog product for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} paintSystemId - ID of paint system
   */
  getPaintSystemAction(companyId, paintSystemId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} paintSystemId - ID of paint system
   */
  getB2BPaintSystemAction(paintSystemId, parameters = {}) {
    let path = '/b2b/paint-systems/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Products of paint system.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} paintSystemId - ID of paint system
   */
  getPaintSystemProductsAction(companyId, paintSystemId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems/{paintSystemId}/products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Products of paint system.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} paintSystemId - ID of paint system
   */
  getB2BPaintSystemProductsAction(paintSystemId, parameters = {}) {
    let path = '/b2b/paint-systems/{paintSystemId}/products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  generateFTS(companyId, parameters = {}) {
    let path = '/crm/{companyId}/ftsGenerator';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/pdf'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  getOrderSettings(parameters = {}) {
    let path = '/b2b/order-settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update for order settings.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The status data
 */
  putOrderSettings(parameters = {}) {
    let path = '/b2b/order-settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Download catalog product document.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {string} documentationId - DocumentationId
 */
  getProductCatalogDocumnetationFileAction(
    companyId,
    productId,
    documentationId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/catalog-products/{productId}/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Download catalog product with toning prices for specified colorcard

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {string} colorcardId - Colorcard ID
 */
  getProductCatalogTintingPricesFileAction(
    companyId,
    productId,
    colorcardId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/catalog-products/{productId}/colorcard/{colorcardId}/tinting-prices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{colorcardId}', colorcardId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Download catalog product document.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {string} documentationId - DocumentationId
 */
  getB2BProductCatalogDocumnetationFileAction(
    productId,
    documentationId,
    parameters = {},
  ) {
    let path =
      '/b2b/catalog-products/{productId}/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - The contact subscription data.
 */
  putContactNewsletterSubscriptionAction(
    companyId,
    contactId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/contacts/{contactId}/newsletter-subscribe';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get test emails list.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getTestEmailsList(parameters = {}) {
    let path = '/contacts/test-emails';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Chcek if email is from test emails list.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} email - Email for check
 */
  getCheckTestEmail(email, parameters = {}) {
    let path = '/contacts/test-email/{email}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{email}', email);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update contact newsletter subscription.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The contact subscription data.
 */
  putB2BContactNewsletterSubscriptionAction(parameters = {}) {
    let path = '/b2b/newsletter-subscribe';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customers invoices.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.year - 
   * @param {string} parameters.sorter - 
 */
  getInvoicesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/invoices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-due_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of invoice.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} invoiceId - Id of invoice
 */
  getInvoiceAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customer types.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  getCustomerTypes(companyId, parameters = {}) {
    let path = '/crm/{companyId}/customer-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Rows of invoice.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} invoiceId - Id of invoice
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
 */
  getInvoiceRowsAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}/rows';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The contact subscription data.
 */
  postOrderTemplateAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/order-template';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of order templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {boolean} parameters.onlyOwned -
   */
  getOrderTemplatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/order-template';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
   * @param {} parameters.data - The template update data.
 */
  putOrderTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/order-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of order templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
   */
  getOrderTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/order-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete order template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
 */
  deleteOrderTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/order-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return orders from NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} parameters.lastKey - Key off last result
   * @param {string} parameters.firsKey - Key off firs result
   * @param {string} parameters.customerNavId - Filter by customer nav id
   * @param {string} parameters.sorter -
   * @param {number} parameters.year - Year
   * @param {string} parameters.q -
   * @param {number} parameters.month -
   * @param {string} parameters.status -
   */
  getOrdersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['lastKey'] !== undefined) {
      queryParameters['lastKey'] = parameters['lastKey'];
    }

    if (parameters['firsKey'] !== undefined) {
      queryParameters['firsKey'] = parameters['firsKey'];
    }

    if (parameters['customerNavId'] !== undefined) {
      queryParameters['customerNavId'] = parameters['customerNavId'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['month'] !== undefined) {
      queryParameters['month'] = parameters['month'];
    }

    /** set default value **/
    queryParameters['status'] = 'historic';

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create cart from order.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - Order Data.
 */
  postOrderAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return orders from NAV.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   */
  getOrderAction(companyId, orderId, parameters = {}) {
    let path = '/crm/{companyId}/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Transfer order from NAV to EOM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} invoiceId - Id of invoice
   */
  transferNavOrderToEOMAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/nav-order-eom/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables list for logged user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {number} parameters.year - Year
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - Define sort order
 */
  getReceivablesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/receivables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-total';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables detail.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} receivableId - Receivable ID from Reporting Services
 */
  getReceivableAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables invoices rows.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} receivableId - Receivable ID from Reporting Services
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.year - 
   * @param {string} parameters.sorter - 
 */
  getReceivableInvoicesAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}/invoices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-due_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create a new recevable registry
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} receivableId - Receivable ID from Reporting Services
   * @param {} parameters.data - The register
   */
  postReceivableRegistryAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}/registries';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get receivable registries
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} receivableId - Receivable ID from Reporting Services
   */
  getReceivableRegistriesAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}/registries';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete a new recevable registry for customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} receivableId - Receivable ID from Reporting Services
   * @param {string} receivableRegistryId - Receivable registry id for which must be data loaded
   */
  deleteReceivableRegistryAction(
    companyId,
    receivableId,
    receivableRegistryId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/receivables/{receivableId}/registries/{receivableRegistryId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    path = path.replace('{receivableRegistryId}', receivableRegistryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get coloraunts by colorcard and color name,id.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} parameters.q - 
   * @param {array} parameters.colocardsIds - 
 */
  getB2BColorauntsAction(parameters = {}) {
    let path = '/b2b/coloraunts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['colocardsIds'] !== undefined) {
      queryParameters['colocardsIds'] = parameters['colocardsIds'];
    }

    if (parameters['colocardsIds'] === undefined) {
      throw Error('Missing required  parameter: colocardsIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get coloraunts by colorcard and color name,id.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q - 
   * @param {array} parameters.colocardsIds - 
 */
  getColorauntsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/coloraunts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['colocardsIds'] !== undefined) {
      queryParameters['colocardsIds'] = parameters['colocardsIds'];
    }

    if (parameters['colocardsIds'] === undefined) {
      throw Error('Missing required  parameter: colocardsIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get marketing details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} marketingListId - id of marketing list
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getMarketingListContactsAction(marketingListId, companyId, parameters = {}) {
    let path = '/crm/{companyId}/marketing-list/{marketingListId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{marketingListId}', marketingListId);

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get marketing materials for CRM.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.filters - 
 */
  getMarketingMaterialsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/marketing-materials';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get marketing materials for B2B.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.filters - 
 */
  getB2BMarketingMaterialsAction(parameters = {}) {
    let path = '/b2b/marketing-materials';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get marketing material for B2B.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} materialId - Marketing material Id
 */
  getB2BMarketingMaterialAction(materialId, parameters = {}) {
    let path = '/b2b/marketing-materials/{materialId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{materialId}', materialId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get marketing material for CRM.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} materialId - Marketing material Id
   * @param {string} companyId - Shortcut of location
 */
  getMarketingMaterialAction(materialId, companyId, parameters = {}) {
    let path = '/crm/{companyId}/marketing-materials/{materialId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{materialId}', materialId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia for B2B.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.type - Filter by type
   * @param {string} parameters.filters - 
   * @param {string} parameters.productId - 
 */
  getB2BMultimediaAction(parameters = {}) {
    let path = '/b2b/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['productId'] !== undefined) {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia for CRM.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.type - Filter by type
   * @param {string} parameters.filters - 
   * @param {string} parameters.productId - 
 */
  getMultimediaAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['productId'] !== undefined) {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia file to download as ZIP.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {array} parameters.multimediaIds - The multimedia data.
 */
  getAdminMultimediaDownloadZipAction(parameters = {}) {
    let path = '/admin/multimedia-download-zip';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['multimediaIds'] !== undefined) {
      queryParameters['multimediaIds'] = parameters['multimediaIds'];
    }

    if (parameters['multimediaIds'] === undefined) {
      throw Error('Missing required  parameter: multimediaIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} multimediaId - Multimedia ID
 */
  getAdminMultimediaDownloadAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Put translation entity.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} entityType - Entity type
   * @param {string} entityId - Entity ID
   * @param {string} attributeName - Attribute name
   * @param {} parameters.data - 
 */
  putEntityTranslationAction(
    entityType,
    entityId,
    attributeName,
    parameters = {},
  ) {
    let path =
      '/admin/translations/entity/{entityType}/{entityId}/{attributeName}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{entityType}', entityType);

    path = path.replace('{entityId}', entityId);

    path = path.replace('{attributeName}', attributeName);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Put translation entities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} entityType - Entity type
   * @param {string} attributeName - Attribute name
   * @param {} parameters.data - 
 */
  putEntityTranslationsAction(entityType, attributeName, parameters = {}) {
    let path = '/admin/translations/entities/{entityType}/{attributeName}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{entityType}', entityType);

    path = path.replace('{attributeName}', attributeName);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get translation entities.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} entityType - Entity type
   * @param {string} attributeName - Attribute name
   * @param {string} parameters.lang - Filter by lang
   * @param {string} parameters.missingLang - Filter by missing lang
   * @param {boolean} parameters.inChangeLog - Filter by entities in change log
   * @param {boolean} parameters.onlyEmptyAt - Filter by entities in change log
   * @param {boolean} parameters.isAvailable - Return only available products items
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
 */
  getEntityTranslationsAction(entityType, attributeName, parameters = {}) {
    let path = '/admin/translations/entities/{entityType}/{attributeName}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{entityType}', entityType);

    path = path.replace('{attributeName}', attributeName);

    if (parameters['lang'] !== undefined) {
      queryParameters['lang'] = parameters['lang'];
    }

    if (parameters['missingLang'] !== undefined) {
      queryParameters['missingLang'] = parameters['missingLang'];
    }

    if (parameters['inChangeLog'] !== undefined) {
      queryParameters['inChangeLog'] = parameters['inChangeLog'];
    }

    if (parameters['onlyEmptyAt'] !== undefined) {
      queryParameters['only_empty_at'] = parameters['onlyEmptyAt'];
    }

    if (parameters['isAvailable'] !== undefined) {
      queryParameters['isAvailable'] = parameters['isAvailable'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get translation entities list.

 * @method
 * @param {object} parameters - method options and parameters
 */
  getEntitiesForTranslations(parameters = {}) {
    let path = '/admin/translations/entities';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get translation entities log

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} entintyId - Entity type
   * @param {string} attributeId - Attribute name
   * @param {array} parameters.entityItemIds - Entity item ids
 */
  getEntintyTranslationsChangesLogAction(
    entintyId,
    attributeId,
    parameters = {},
  ) {
    let path = '/admin/entity/change-log/{entintyId}/{attributeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{entintyId}', entintyId);

    path = path.replace('{attributeId}', attributeId);

    if (parameters['entityItemIds'] !== undefined) {
      queryParameters['entity_item_ids'] = parameters['entityItemIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update translation entities log.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} entintyId - Entity type
   * @param {string} attributeId - Attribute name
   * @param {} parameters.data - 
 */
  putEntintyTranslationsChangesLogAction(
    entintyId,
    attributeId,
    parameters = {},
  ) {
    let path = '/admin/entity/change-log/{entintyId}/{attributeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{entintyId}', entintyId);

    path = path.replace('{attributeId}', attributeId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete translation entities log

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} entintyId - Entity type
   * @param {string} attributeId - Attribute name
   * @param {string} parameters.entityItemId - Entity item ids
 */
  deleteEntintyTranslationsChangesLogAction(
    entintyId,
    attributeId,
    parameters = {},
  ) {
    let path = '/admin/entity/change-log/{entintyId}/{attributeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{entintyId}', entintyId);

    path = path.replace('{attributeId}', attributeId);

    if (parameters['entityItemId'] !== undefined) {
      queryParameters['entity_item_id'] = parameters['entityItemId'];
    }

    if (parameters['entityItemId'] === undefined) {
      throw Error('Missing required  parameter: entityItemId');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} multimediaId - Multimedia ID
 */
  getB2BMultimediaDownloadAction(multimediaId, parameters = {}) {
    let path = '/b2b/multimedia/{multimediaId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get multimedia file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} multimediaId - Multimedia ID
 */
  getMultimediaDownloadAction(companyId, multimediaId, parameters = {}) {
    let path = '/crm/{companyId}/multimedia/{multimediaId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of documentations.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {array} parameters.publishedChanel -
   * @param {array} parameters.publishedLang -
   * @param {array} parameters.brand -
   * @param {array} parameters.type -
   * @param {array} parameters.lang -
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {array} parameters.productIds -
   * @param {string} parameters.sorter -
   */
  getDocumentationAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['publishedChanel'] !== undefined) {
      queryParameters['publishedChanel'] = parameters['publishedChanel'];
    }

    if (parameters['publishedChanel'] === undefined) {
      throw Error('Missing required  parameter: publishedChanel');
    }

    if (parameters['publishedLang'] !== undefined) {
      queryParameters['publishedLang'] = parameters['publishedLang'];
    }

    if (parameters['publishedLang'] === undefined) {
      throw Error('Missing required  parameter: publishedLang');
    }

    if (parameters['brand'] !== undefined) {
      queryParameters['brand'] = parameters['brand'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['lang'] !== undefined) {
      queryParameters['lang'] = parameters['lang'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['productIds'] !== undefined) {
      queryParameters['productIds'] = parameters['productIds'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-documentation.file_updated_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get documentation files list.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
 */
  getB2BDocumentationAction(parameters = {}) {
    let path = '/b2b/documentation';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get certificates files list.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
 */
  getB2BCertificatesAction(parameters = {}) {
    let path = '/b2b/certificates';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get documentation file to download as ZIP.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {array} parameters.fullPaths - The documentation fullPaths.
   * @param {array} parameters.documentationIds - The documentation IDs.
 */
  getDocumentationDownloadZipAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/documentation-download-zip';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['fullPaths'] !== undefined) {
      queryParameters['fullPaths'] = parameters['fullPaths'];
    }

    if (parameters['documentationIds'] !== undefined) {
      queryParameters['documentationIds'] = parameters['documentationIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get documentation file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} documentationId - DocumentationId
 */
  getDocumentationDownloadAction(companyId, documentationId, parameters = {}) {
    let path = '/crm/{companyId}/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get documentation file to download as ZIP.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {array} parameters.fullPaths - The documentation fullPaths.
   * @param {array} parameters.documentationIds - The documentation IDs.
 */
  getB2BDocumentationDownloadZipAction(parameters = {}) {
    let path = '/b2b/documentation-download-zip';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['fullPaths'] !== undefined) {
      queryParameters['fullPaths'] = parameters['fullPaths'];
    }

    if (parameters['documentationIds'] !== undefined) {
      queryParameters['documentationIds'] = parameters['documentationIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get documentation file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} documentationId - DocumentationId
 */
  getB2BDocumentationDownloadAction(documentationId, parameters = {}) {
    let path = '/b2b/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Download invocie PDF.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} invoiceId - Id of invoice
 */
  getInvoicePDFFileAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Rows of invoice.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} invoiceId - Id of invoice
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
 */
  getB2BInvoiceRowsAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}/rows';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{invoiceId}', invoiceId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Download invocie PDF.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} invoiceId - Id of invoice
 */
  getB2BInvoicePDFFileAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Method for 2 orders merging.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} cartIdToMerge - Id of cart, which will be merge
   * @param {string} cartId - Id of cart
 */
  putOrderMergeAction(cartIdToMerge, cartId, parameters = {}) {
    let path = '/eshop/merge-orders/{cartId}/{cartIdToMerge}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{cartIdToMerge}', cartIdToMerge);

    path = path.replace('{cartId}', cartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of invoice.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} invoiceId - Id of invoice
 */
  getB2BInvoiceAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of customers invoices.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.year - 
   * @param {string} parameters.sorter - 
 */
  getB2BInvoicesAction(parameters = {}) {
    let path = '/b2b/invoices';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-due_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get download ID of XML file generated from invoice.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} invoiceId - Id of invoice
  
 */
  getInvoiceXMLFileAction(companyId, invoiceId, parameters = {}) {
    let path = '/crm/{companyId}/invoices/{invoiceId}/export-xml';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters['type'] = 'pohoda';

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of contacts (users).

 * @method
 * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.sorter - 
   * @param {string} parameters.filters - 
 */
  getB2BUsersAction(parameters = {}) {
    let path = '/b2b/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new contact.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - The contact data.
 */
  postB2BUserAction(parameters = {}) {
    let path = '/b2b/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Details of b2b user

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} contactId - ID ofcontact
 */
  getB2BUserAction(contactId, parameters = {}) {
    let path = '/b2b/users/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of b2b user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - The contact data.
 */
  putB2BUserAction(contactId, parameters = {}) {
    let path = '/b2b/users/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update details of b2b user.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} contactId - ID ofcontact
   * @param {} parameters.data - The contact data.
 */
  putB2BMyAccountAction(contactId, parameters = {}) {
    let path = '/b2b/my-account/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{contactId}', contactId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update contact credentials.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} contactId - ID ofcontact
   * @param {string} status - status ofcontact
   * @param {} parameters.data - 
 */
  putB2BUserCredentialsAction(contactId, status, parameters = {}) {
    let path = '/b2b/contacts/{contactId}/credentials/{status}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{contactId}', contactId);

    path = path.replace('{status}', status);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  postEmptyCalculationTableAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/empty-calculation';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} calculationTableId - ID of calc. table
 */
  postCalculationTableCopyAction(
    companyId,
    calculationTableId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/copy-calculation/{calculationTableId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postCalculationTablesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of calculation tables

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.customerId - 
   * @param {string} parameters.opportunityId - 
   * @param {string} parameters.q - 
   * @param {string} parameters.status - 
   * @param {string} parameters.sorter - Define sort order
 */
  getCalculationTablesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['opportunityId'] !== undefined) {
      queryParameters['opportunityId'] = parameters['opportunityId'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} calculationTableId - ID of calc. table
   * @param {} parameters.data - 
 */
  putCalculationTablesAction(companyId, calculationTableId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables/{calculationTableId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} calculationTableId - ID of calc. table
 */
  deleteCalculationTableAction(companyId, calculationTableId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables/{calculationTableId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} calculationTableId - ID of calc. table
 */
  getCalculationTableAction(companyId, calculationTableId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables/{calculationTableId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The contact subscription data.
 */
  postCalculationTableTemplateAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-table-template';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of calculation table templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {boolean} parameters.onlyOwned -
   */
  getCalculationTableTemplatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-table-template';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
   * @param {} parameters.data - The template update data.
 */
  putCalculationTableTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of ordcalculation table templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
   */
  getCalculationTableTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete calculation table template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
 */
  deleteCalculationTableTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/calculation-tables-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update B2B user settings.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - B2B User Settings Data.
 */
  b2bPutMeAppLangAction(parameters = {}) {
    let path = '/b2b/me/activeAppLang';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get B2B user settings.

 * @method
 * @param {object} parameters - method options and parameters
 */
  b2bMeSettingsAction(parameters = {}) {
    let path = '/b2b/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update B2B user settings.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - B2B User Settings Data.
 */
  b2bPutMeSettingsAction(parameters = {}) {
    let path = '/b2b/me/settings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get download ID of XML file generated from invoice.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} invoiceId - Id of invoice
  
 */
  getB2BInvoiceXMLFileAction(invoiceId, parameters = {}) {
    let path = '/b2b/invoices/{invoiceId}/export-xml';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters['type'] = 'pohoda';

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products from innovatint/temaspeed db.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} systemId - Toning System ID
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getToningSystemExternalProductsAction(systemId, parameters = {}) {
    let path = '/codelist/toning-systems/{systemId}/external-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{systemId}', systemId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'PRODUCTNAME';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products innovatint db.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getAvatintProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/avatint-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'PRODUCTNAME';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of avatint product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   */
  getAvatintProductAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/avatint-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of actual bonuses.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getBonusesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/bonuses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of actual bonuses.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getB2BBonusesAction(parameters = {}) {
    let path = '/b2b/bonuses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload customer photo.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.contract - File to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  postCustomerContractFileAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/contract-file';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['contract'] !== undefined) {
      form['contract'] = parameters['contract'];
    }

    if (parameters['contract'] === undefined) {
      throw Error('Missing required  parameter: contract');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Download contract file.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  getContractFileAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/contract-file';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete contract file.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} customerId - Customer id for which must be data loaded
 */
  deleteContractFileAction(companyId, customerId, parameters = {}) {
    let path = '/crm/{companyId}/customer/{customerId}/contract-file';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new price offer

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - 
 */
  postPriceOfferAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of calculation tables

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.customerId - 
   * @param {string} parameters.opportunityId - 
   * @param {string} parameters.status - 
   * @param {string} parameters.sorter - Define sort order
 */
  getPriceOffersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['opportunityId'] !== undefined) {
      queryParameters['opportunityId'] = parameters['opportunityId'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new price offer

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  postEmptyPriceOfferAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/empty-price-offer';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update price offer

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
   * @param {} parameters.data - 
 */
  putPriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete price offer

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
 */
  deletePriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of price offer

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
 */
  getPriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {} parameters.data - The contact subscription data.
 */
  postPriceOfferTemplateAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers-template';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of price offer templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {boolean} parameters.onlyOwned -
   */
  getPriceOfferTemplatesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers-template';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
   * @param {} parameters.data - The template update data.
 */
  putPriceOfferTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of price offers templates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
   */
  getPriceOfferTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete price offer template.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} templateId - id of template
 */
  deletePriceOfferTemplateAction(companyId, templateId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers-template/{templateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{templateId}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Create new calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
 */
  postPriceOfferCopyAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/copy-price-offer/{priceOfferId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update calculation table

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} calculationTableId - ID of calc. table
 */
  getCalculatedCalculationTableAction(
    companyId,
    calculationTableId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/calculation-tables/{calculationTableId}/calculation';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update campaign details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
   * @param {} parameters.data - 
 */
  putPriceOfferRequestDecisionAction(companyId, priceOfferId, parameters = {}) {
    let path =
      '/crm/{companyId}/price-offers/{priceOfferId}/approval-request-decision';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update campaign details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
   * @param {} parameters.data - 
 */
  putSendForAprrovalOfferRequestDecisionAction(
    companyId,
    priceOfferId,
    parameters = {},
  ) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/send-for-approval';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update campaign details.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
   * @param {} parameters.data - 
 */
  sendPriceOfferAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/send-offer';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Price offer pdf file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} priceOfferId - id of price offer
  
 */
  getPriceOfferDownloadAction(companyId, priceOfferId, parameters = {}) {
    let path = '/crm/{companyId}/price-offers/{priceOfferId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters['type'] = 'pdf';

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of delivery addresses.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getB2BDeliveryAddressesAction(parameters = {}) {
    let path = '/b2b/delivery-addresses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create delivery address.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  postB2BDeliveryAddressAction(parameters = {}) {
    let path = '/b2b/delivery-addresses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return detail of delivery address.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} addressId -
   */
  getB2BDeliveryAddressAction(addressId, parameters = {}) {
    let path = '/b2b/delivery-addresses/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update detail of delivery address.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} addressId -
   * @param {} parameters.data -
   */
  putB2BDeliveryAddressAction(addressId, parameters = {}) {
    let path = '/b2b/delivery-addresses/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete delivery address.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} addressId -
   */
  deleteB2BDeliveryAddressAction(addressId, parameters = {}) {
    let path = '/b2b/delivery-addresses/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} newsletterId -
   */
  getNewsletterPdfAction(companyId, newsletterId, parameters = {}) {
    let path = '/crm/{companyId}/newsletters/{newsletterId}/newsletters-pdf';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/pdf'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} newsletterId -
   * @param {string} fullPath - Escaped full path to attachment
   */
  getNewsletterAttachmentAction(
    companyId,
    newsletterId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/newsletters/{newsletterId}/newsletters-attachment/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/pdf'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} newsId -
   * @param {string} fullPath - Escaped full path to attachment
   */
  getNewsAttachmentAction(newsId, fullPath, parameters = {}) {
    let path = '/crm/news/{newsId}/news-attachment/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/pdf'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{newsId}', newsId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} newsletterId -
   */
  getNewsletterAction(companyId, newsletterId, parameters = {}) {
    let path = '/crm/{companyId}/newsletters/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsletterId}', newsletterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  getAllImagesPathsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/all-images';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products Temaspeed db.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getTemaspeedProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/temaspeed-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'PRODUCTNAME';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of Temaspeed product.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   */
  getTemaspeedProductAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/temaspeed-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of shades of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {string} parameters.colorcardId -
   * @param {string} parameters.q -
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {boolean} parameters.onlyFinishedUsedShades -
   */
  getCatalogProductShadesAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (parameters['colorcardId'] !== undefined) {
      queryParameters['colorcardId'] = parameters['colorcardId'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['onlyFinishedUsedShades'] !== undefined) {
      queryParameters['onlyFinishedUsedShades'] =
        parameters['onlyFinishedUsedShades'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of shades for catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {string} parameters.colorcardId -
   * @param {boolean} parameters.onlyFinishedUsedShades -
   * @param {string} parameters.q -
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   */
  getB2BCatalogProductShadesAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    if (parameters['colorcardId'] !== undefined) {
      queryParameters['colorcardId'] = parameters['colorcardId'];
    }

    if (parameters['onlyFinishedUsedShades'] !== undefined) {
      queryParameters['onlyFinishedUsedShades'] =
        parameters['onlyFinishedUsedShades'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get tasks.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter - Define sort order
   */
  getReporTasksAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/report-tasks';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'name';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Inspirations of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
   */
  getB2BCatalogProductInspirationsAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-created_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * List of defined colorcards

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q - 
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
 */
  getCatalogProductInspirationsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-created_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * References of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
   */
  getB2BCatalogProductReferencesAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/references';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-created_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * References of catalog product for CRM

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q - 
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter - Define sort order
 */
  getCatalogProductReferencesAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/references';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-created_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return paint systems of catalog product for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   */
  getB2BCatalogProductPaintSystemsAction(productId, parameters = {}) {
    let path = '/b2b/catalog-products/{productId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Return paint systems of catalog product for CRM

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - 
   * @param {string} parameters.filters - 
 */
  getCatalogProductPaintSystemsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/catalog-products/{productId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of customers price offers for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.status -
   * @param {string} parameters.sorter - Define sort order
   * @param {array} parameters.offerIds -
   */
  getB2BPriceOffersAction(parameters = {}) {
    let path = '/b2b/price-offers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    /** set default value **/
    queryParameters['sorter'] = '-create_date';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['offerIds'] !== undefined) {
      queryParameters['offerIds'] = parameters['offerIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Detail of price offer.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} priceOfferId - id of price offer
 */
  getB2BPriceOfferAction(priceOfferId, parameters = {}) {
    let path = '/b2b/price-offers/{priceOfferId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{priceOfferId}', priceOfferId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of customers price offers products for B2B.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {array} parameters.offerIds -
   */
  getB2BPriceOffersProductsAction(parameters = {}) {
    let path = '/b2b/price-offers-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['offerIds'] !== undefined) {
      queryParameters['offerIds'] = parameters['offerIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Calculation table PDF file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} calculationTableId - ID of calc. table
  
 */
  getCalculationTableDownloadAction(
    companyId,
    calculationTableId,
    parameters = {},
  ) {
    let path =
      '/crm/{companyId}/calculation-tables/{calculationTableId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationTableId}', calculationTableId);

    queryParameters['type'] = 'pdf';

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products external DB by TS.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.systemId -
   * @param {array} parameters.productsIds -
   */
  getExternalProductsAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/external-products';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'PRODUCTNAME';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['systemId'] !== undefined) {
      queryParameters['systemId'] = parameters['systemId'];
    }

    if (parameters['systemId'] === undefined) {
      throw Error('Missing required  parameter: systemId');
    }

    if (parameters['productsIds'] !== undefined) {
      queryParameters['productsIds'] = parameters['productsIds'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return products external DB by TS.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} companyId - Shortcut of location
   * @param {string} productId - ID of product
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   */
  getProductExternalProductsAction(companyId, productId, parameters = {}) {
    let path = '/crm/{companyId}/external-products/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    /** set default value **/
    queryParameters['sorter'] = 'PRODUCTNAME';

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Generate EAN13 image from code in Base64
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.eanCode - EAN code
   */
  getEANImageAction(parameters = {}) {
    let path = '/crm/ean/image';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['eanCode'] !== undefined) {
      queryParameters['EANCode'] = parameters['eanCode'];
    }

    if (parameters['eanCode'] === undefined) {
      throw Error('Missing required  parameter: eanCode');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Generate EAN13 code
   * @method
   * @param {object} parameters - method options and parameters
   */
  getGenerateEANAction(parameters = {}) {
    let path = '/crm/ean/generate-code';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Paint system PDF file to download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} paintSystemId - ID of paint system
  
 */
  getPaintSystemDownloadAction(companyId, paintSystemId, parameters = {}) {
    let path = '/crm/{companyId}/paint-systems/{paintSystemId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters['type'] = 'pdf';

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * PDF of paint system.
 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} paintSystemId - ID of paint system
  
 */
  getB2BPaintSystemDownloadAction(paintSystemId, parameters = {}) {
    let path = '/b2b/paint-systems/{paintSystemId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters['type'] = 'pdf';

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return newsletters for CRM.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filters -
   * @param {string} parameters.sorter -
   */
  getNewslettersAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filters'] !== undefined) {
      queryParameters['filters'] = parameters['filters'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Receivables download.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} receivableId - Receivable ID from Reporting Services
   * @param {string} parameters.type - 
 */
  getReceivableDownloadAction(companyId, receivableId, parameters = {}) {
    let path = '/crm/{companyId}/receivables/{receivableId}/download';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{receivableId}', receivableId);

    /** set default value **/
    queryParameters['type'] = 'pdf';

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['type'] === undefined) {
      throw Error('Missing required  parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Authorize xColor user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.redirectUri -
   * @param {string} parameters.appId -
   * @param {array} parameters.scopes -
   * @param {string} parameters.username -
   * @param {string} parameters.password -
   * @param {string} parameters.state -
   * @param {string} parameters.cartId -
   */
  oauthAuthorizeXColorAction(parameters = {}) {
    let path = '/oauth/authorize/eshop';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/x-www-form-urlencoded'];

    if (parameters['redirectUri'] !== undefined) {
      form['redirect_uri'] = parameters['redirectUri'];
    }

    if (parameters['redirectUri'] === undefined) {
      throw Error('Missing required  parameter: redirectUri');
    }

    if (parameters['appId'] !== undefined) {
      form['app_id'] = parameters['appId'];
    }

    if (parameters['appId'] === undefined) {
      throw Error('Missing required  parameter: appId');
    }

    if (parameters['scopes'] !== undefined) {
      form['scopes'] = parameters['scopes'];
    }

    if (parameters['scopes'] === undefined) {
      throw Error('Missing required  parameter: scopes');
    }

    if (parameters['username'] !== undefined) {
      form['username'] = parameters['username'];
    }

    if (parameters['username'] === undefined) {
      throw Error('Missing required  parameter: username');
    }

    if (parameters['password'] !== undefined) {
      form['password'] = parameters['password'];
    }

    if (parameters['password'] === undefined) {
      throw Error('Missing required  parameter: password');
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    if (parameters['state'] === undefined) {
      throw Error('Missing required  parameter: state');
    }

    if (parameters['cartId'] !== undefined) {
      queryParameters['cartId'] = parameters['cartId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get daily stats from NBS
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.currency - Mena
   */
  getNbsDailyStatsAction(parameters = {}) {
    let path = '/nbs/stats-daily';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['currency'] = 'CZK';

    if (parameters['currency'] !== undefined) {
      queryParameters['currency'] = parameters['currency'];
    }

    if (parameters['currency'] === undefined) {
      throw Error('Missing required  parameter: currency');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return tintable types of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  getTintingTypesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/tinting-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return tindable types of catalog products.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getB2BTintingTypesAction(parameters = {}) {
    let path = '/b2b/tinting-types';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return fees list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.type -
   */
  getServiceFeesAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/fees';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get warranty claim file download id.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} claimId - ID of order
   * @param {string} fullPath - Escaped full path to attachment
 */
  getEshopWarrantyClaimFileDownloadIdAction(
    companyId,
    claimId,
    fullPath,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}/files/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload warranty claim file

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.attachment - File to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} claimId - ID of order
 */
  postEshopWarrantyClaimFileAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}/files';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['attachment'] !== undefined) {
      form['attachment'] = parameters['attachment'];
    }

    if (parameters['attachment'] === undefined) {
      throw Error('Missing required  parameter: attachment');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Insert claim note value.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} claimId - ID of order
   * @param {} parameters.data - data.
   */
  postEshopWarrantyClaimNoteAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}/notes';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return warranty claim detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} claimId - ID of order
   */
  getEshopWarrantyClaimAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Update warranty claim status

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} claimId - ID of order
   * @param {} parameters.data - data.
 */
  putEshopWarrantyClaimStateAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Storno warranty claim

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} claimId - ID of order
 */
  cancelWarrantyClaimAction(companyId, claimId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims/storno/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return warranty claims list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.createdDateFrom -
   * @param {string} parameters.resultSolution -
   * @param {string} parameters.customerId -
   * @param {string} parameters.state -
   * @param {string} parameters.type -
   * @param {string} parameters.result -
   */
  getEshopWarrantyClaimsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/warranty-claims';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['createdDateFrom'] !== undefined) {
      queryParameters['createdDateFrom'] = parameters['createdDateFrom'];
    }

    if (parameters['resultSolution'] !== undefined) {
      queryParameters['resultSolution'] = parameters['resultSolution'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    if (parameters['result'] !== undefined) {
      queryParameters['result'] = parameters['result'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Export orders to csv file.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.orderNo -
   * @param {string} parameters.orderState -
   * @param {string} parameters.orderDateFrom -
   * @param {string} parameters.orderDateTo -
   * @param {number} parameters.priceFrom -
   * @param {number} parameters.priceTo -
   * @param {string} parameters.paymentType -
   * @param {string} parameters.deliveryType -
   * @param {string} parameters.originShop -
   * @param {string} parameters.customerId -
   */
  exportEshopOrdersToCSVAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-export-csv';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['orderNo'] !== undefined) {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    if (parameters['orderState'] !== undefined) {
      queryParameters['orderState'] = parameters['orderState'];
    }

    if (parameters['orderDateFrom'] !== undefined) {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    if (parameters['orderDateTo'] !== undefined) {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    if (parameters['priceFrom'] !== undefined) {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    if (parameters['priceTo'] !== undefined) {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    if (parameters['paymentType'] !== undefined) {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    if (parameters['deliveryType'] !== undefined) {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    if (parameters['originShop'] !== undefined) {
      queryParameters['originShop'] = parameters['originShop'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return orders messages list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.orderNo -
   * @param {string} parameters.orderState -
   * @param {string} parameters.customerId -
   * @param {string} parameters.createdBy -
   */
  getEshopMessagesAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/messages';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['orderNo'] !== undefined) {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    if (parameters['orderState'] !== undefined) {
      queryParameters['orderState'] = parameters['orderState'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['createdBy'] !== undefined) {
      queryParameters['createdBy'] = parameters['createdBy'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return orders list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.orderNo -
   * @param {string} parameters.orderState -
   * @param {string} parameters.eomState -
   * @param {string} parameters.orderDateFrom -
   * @param {string} parameters.orderDateTo -
   * @param {number} parameters.priceFrom -
   * @param {number} parameters.priceTo -
   * @param {string} parameters.paymentType -
   * @param {string} parameters.deliveryType -
   * @param {string} parameters.originShop -
   * @param {string} parameters.customerId -
   * @param {string} parameters.voucherCode -
   * @param {boolean} parameters.excludeTest -
   */
  getEshopOrdersAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['orderNo'] !== undefined) {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    if (parameters['orderState'] !== undefined) {
      queryParameters['orderState'] = parameters['orderState'];
    }

    if (parameters['eomState'] !== undefined) {
      queryParameters['eomState'] = parameters['eomState'];
    }

    if (parameters['orderDateFrom'] !== undefined) {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    if (parameters['orderDateTo'] !== undefined) {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    if (parameters['priceFrom'] !== undefined) {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    if (parameters['priceTo'] !== undefined) {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    if (parameters['paymentType'] !== undefined) {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    if (parameters['deliveryType'] !== undefined) {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    if (parameters['originShop'] !== undefined) {
      queryParameters['originShop'] = parameters['originShop'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['voucherCode'] !== undefined) {
      queryParameters['voucherCode'] = parameters['voucherCode'];
    }

    if (parameters['excludeTest'] !== undefined) {
      queryParameters['excludeTest'] = parameters['excludeTest'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return orders list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.year - year of stats to get
   */
  getEshopOrdersStatsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-stats';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return daily sales statistics of year
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.year - year of stats to get
   */
  getDailySalesDataAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/daily-sales-stats';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['year'] !== undefined) {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of used vouchers in orders.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.q -
   */
  getEshopUsedVouchersAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders/used-vouchers';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return list of stats used vouchers.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {array} parameters.voucherCodes -
   * @param {string} parameters.fromDate -
   * @param {string} parameters.toDate -
   */
  getEshopUserVouchersStatsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders/used-vouchers-stats';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['voucherCodes'] !== undefined) {
      queryParameters['voucherCodes'] = parameters['voucherCodes'];
    }

    if (parameters['fromDate'] !== undefined) {
      queryParameters['fromDate'] = parameters['fromDate'];
    }

    if (parameters['toDate'] !== undefined) {
      queryParameters['toDate'] = parameters['toDate'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get email logs for order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   */
  getEshopOrderEmailLogsAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/email-logs';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send order confimation email to customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {boolean} parameters.forced -
   * @param {string} parameters.email -
   */
  sendEshopOrderConfirmationEmailAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/confirmation-email';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    if (parameters['email'] !== undefined) {
      queryParameters['email'] = parameters['email'];
    }

    if (parameters['email'] === undefined) {
      throw Error('Missing required  parameter: email');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send 123Courier confimation email to customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {boolean} parameters.forced -
   */
  sendEshopOrder123CourierEmailAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/courier-confirmation-email';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['forced'] !== undefined) {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return order detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   */
  getEshopOrderAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update internal order state.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - Data to update.
   */
  putEshopOrderAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/internal-state';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update payment status.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   */
  putEshopOrderPaymentConfirmAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/confirm-payment';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update eshop order eom state.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - New state to set.
   */
  putEshopOrderStateAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/change-state';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update billing address of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - Address to update.
   */
  putEshopOrderBillingAddressAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/billing-address';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update delivery address of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - Address to update.
   */
  putEshopOrderDeliveryAddressAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/delivery-address';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update internal note of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - Data to update.
   */
  putEshopOrderInternalNoteAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/internal-note';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update company info of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - DAta to update.
   */
  putEshopOrderCompanyInfoAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/company-data';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update estimate delivery date of eshop order.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data - Data to update.
   */
  putEshopOrderDeliveryDateAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/estimate-delivery-date';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return order comments.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   */
  getEshopOrderCommentsAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/comment-item';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create eshop order comment item.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {boolean} parameters.notifyUserByEmail -
   * @param {boolean} parameters.notifyUserByMessage -
   * @param {} parameters.data - Data to update.
   */
  postEshopOrderCommentAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/comment-item';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['notifyUserByEmail'] !== undefined) {
      queryParameters['notifyUserByEmail'] = parameters['notifyUserByEmail'];
    }

    if (parameters['notifyUserByMessage'] !== undefined) {
      queryParameters['notifyUserByMessage'] =
        parameters['notifyUserByMessage'];
    }

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get all 123 curier packages.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {number} parameters.state -
   * @param {string} parameters.orderNo -
   * @param {string} parameters.orderState -
   * @param {string} parameters.orderDateFrom -
   * @param {string} parameters.orderDateTo -
   * @param {number} parameters.priceFrom -
   * @param {number} parameters.priceTo -
   * @param {string} parameters.paymentType -
   * @param {string} parameters.deliveryType -
   * @param {string} parameters.customerId -
   * @param {string} parameters.originShop -
   * @param {string} parameters.sorter -
   */
  get123CurierPackagesAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-packages';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    if (parameters['orderNo'] !== undefined) {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    if (parameters['orderState'] !== undefined) {
      queryParameters['orderState'] = parameters['orderState'];
    }

    if (parameters['orderDateFrom'] !== undefined) {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    if (parameters['orderDateTo'] !== undefined) {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    if (parameters['priceFrom'] !== undefined) {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    if (parameters['priceTo'] !== undefined) {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    if (parameters['paymentType'] !== undefined) {
      queryParameters['paymentType'] = parameters['paymentType'];
    }

    if (parameters['deliveryType'] !== undefined) {
      queryParameters['deliveryType'] = parameters['deliveryType'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['originShop'] !== undefined) {
      queryParameters['originShop'] = parameters['originShop'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete order 123Curier package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {string} curierPackageId - ID curier pacakge
   */
  delete123CourierPackageAction(
    companyId,
    orderId,
    curierPackageId,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/orders/{orderId}/packages/{curierPackageId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{curierPackageId}', curierPackageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Request pickup 123Curier package.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {string} curierPackageId - ID curier pacakge
   */
  post123CourierRequestPickupAction(
    companyId,
    orderId,
    curierPackageId,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/orders/{orderId}/packages/{curierPackageId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{curierPackageId}', curierPackageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return order 123Curier packages.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   */
  getOrder123CurierPackagesAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/packages';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create eshop oorder package to 123Courier.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {} parameters.data -
   */
  post123CourierPackageAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/packages';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get EOM order file download id.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {string} fullPath - Escaped full path to attachment
 */
  getEomOrderFileDownloadIdAction(
    companyId,
    orderId,
    fullPath,
    parameters = {},
  ) {
    let path = '/eom/{companyId}/orders/{orderId}/files/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Delete EOM order file

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {string} fullPath - Escaped full path to attachment
 */
  deleteEomOrderFileAction(companyId, orderId, fullPath, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/files/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Upload EOM order file

 * @method
 * @param {object} parameters - method options and parameters
   * @param {file} parameters.attachment - File to upload.
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
 */
  postEomOrderFileAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders/{orderId}/files';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['multipart/form-data'];

    if (parameters['attachment'] !== undefined) {
      form['attachment'] = parameters['attachment'];
    }

    if (parameters['attachment'] === undefined) {
      throw Error('Missing required  parameter: attachment');
    }

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Request 123courier orders pickup.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {array} parameters.packagesIds -
   * @param {string} parameters.packagesId -
   */
  post123CourierRequestPickupsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packages-request-pickups';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['packagesIds'] !== undefined) {
      queryParameters['packagesIds'] = parameters['packagesIds'];
    }

    if (parameters['packagesId'] !== undefined) {
      queryParameters['packagesId'] = parameters['packagesId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return order comments.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.packagesId -
   * @param {array} parameters.packagesIds -
   * @param {number} parameters.position -
   */
  get123CurierTicketsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packages-tickets';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['packagesId'] !== undefined) {
      queryParameters['packagesId'] = parameters['packagesId'];
    }

    if (parameters['packagesIds'] !== undefined) {
      queryParameters['packagesIds'] = parameters['packagesIds'];
    }

    if (parameters['position'] !== undefined) {
      queryParameters['position'] = parameters['position'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return package tracking.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   */
  get123CurierDeliveryRedirectAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/{orderId}/delivery-redirect';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return packages daily summary.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} parameters.dateOfDay -
   */
  get123CurierDailySummaryAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/packages/daily-summary';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    if (parameters['dateOfDay'] !== undefined) {
      queryParameters['dateOfDay'] = parameters['dateOfDay'];
    }

    if (parameters['dateOfDay'] === undefined) {
      throw Error('Missing required  parameter: dateOfDay');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return order ratings list.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.orderNo -
   * @param {string} parameters.customerId -
   * @param {string} parameters.rating -
   * @param {string} parameters.orderDateFrom -
   * @param {string} parameters.orderDateTo -
   */
  getEshopOrderRatingsAction(companyId, parameters = {}) {
    let path = '/eom/{companyId}/orders-ratings';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['orderNo'] !== undefined) {
      queryParameters['orderNo'] = parameters['orderNo'];
    }

    if (parameters['customerId'] !== undefined) {
      queryParameters['customerId'] = parameters['customerId'];
    }

    if (parameters['rating'] !== undefined) {
      queryParameters['rating'] = parameters['rating'];
    }

    if (parameters['orderDateFrom'] !== undefined) {
      queryParameters['orderDateFrom'] = parameters['orderDateFrom'];
    }

    if (parameters['orderDateTo'] !== undefined) {
      queryParameters['orderDateTo'] = parameters['orderDateTo'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return order rating.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} orderId - ID of order
   * @param {string} parameters.type -
   */
  getEshopOrderRatingAction(companyId, orderId, parameters = {}) {
    let path = '/eom/{companyId}/orders-rating/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return Google shopping Feed XML.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  updateProductsFeedsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products-feeds-export';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants exported to json file.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getAlgoliaProductsFileAction(parameters = {}) {
    let path = '/products-export-algolia';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants exported to json file.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  updateFilterSearchCollectionAction(companyId, parameters = {}) {
    let path = '/{companyId}/products-export-filter-search';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants exported to json file.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   */
  updatePaintSystemsSearchCollectionAction(companyId, parameters = {}) {
    let path = '/{companyId}/paint-systems-export-filter-search';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Finds all the products regardless of time and populates the algolia index.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} algoliaIndexType - Algolia index type which to update
   */
  updateAllAlgoliaProducts(companyId, algoliaIndexType, parameters = {}) {
    let path = '/{companyId}/update-algolia-products-all/{algoliaIndexType}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{algoliaIndexType}', algoliaIndexType);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Finds the products changed in the previous 30 minutes and updates the algolia index.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} algoliaIndexType - Algolia index type which to update
   */
  updateLatestAlgoliaProducts(companyId, algoliaIndexType, parameters = {}) {
    let path = '/{companyId}/update-algolia-products-latest/{algoliaIndexType}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{algoliaIndexType}', algoliaIndexType);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants exported to json file.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getProductsFileAction(parameters = {}) {
    let path = '/products-export-to-file';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get shops list

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
 */
  getShopsAction(companyId, parameters = {}) {
    let path = '/{companyId}/shops';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants in json.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   */
  getObnovaProductsExportAction(parameters = {}) {
    let path = '/obnova-products-export';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants in json.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   */
  getSEKOProductsExportAction(parameters = {}) {
    let path = '/seko-products-export';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Return dejmark products with variants in json.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   */
  getProductsExportAction(parameters = {}) {
    let path = '/products-export';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '10';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get shop by id

 * @method
 * @param {object} parameters - method options and parameters
   * @param {string} companyId - Shortcut of location
   * @param {string} shopId - Shop id
 */
  getShopAction(companyId, shopId, parameters = {}) {
    let path = '/{companyId}/shop/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new API();
