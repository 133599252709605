import { __ } from '../Utils';

export default [
  {
    name: __('Dokumentácia staršia ako 2 roky'),
    value: 'old',
    key: 1,
  },
  {
    name: __('Neúplná dokumentácia'),
    value: 'incomplete',
    key: 2,
  },
];
