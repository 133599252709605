import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import { FlexBoxStart } from '../ReusableComponents';
import { location, base } from '../../ContentConfig/locations';

const CustomLinkWrapper = styled.div`
  displa: flex;
  align-items: center;
  a {
    color: #fff;
    font-size: ${rem(11)};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const NoLink = styled.p`
  display: inline-block
  color: #fff;
  font-size: ${rem(11)};
  margin: 0;
`;

const CustomIcon = styled(Icon)`
  margin: 0 ${rem(5)};
`;

const CustomFlexBoxStart = styled(FlexBoxStart)`
  margin-bottom: ${rem(5)};
  align-items: baseline;
`;

class ControlBarLocation extends React.Component {
  render() {
    const { history } = this.props;
    const { pathname } = history ? history.location : {};
    const keys = Object.keys(location);

    let locations = [];
    if (pathname) {
      for (let i = 0; i < keys.length; i++) {
        if (pathname.match(keys[i])) {
          locations = location[keys[i]];
        }
      }
    }

    locations = [...base, ...locations];

    return (
      <CustomFlexBoxStart>
        {locations.map((l, index, array) => {
          // return array.length !== index + 1 ? (
          return (
            <CustomLinkWrapper key={l.name}>
              {l.path || l.path === '' ? (
                <React.Fragment>
                  <Link to={l.path}>{l.name}</Link>
                  {array.length !== index + 1 && (
                    <CustomIcon name="arrow-right" color="#fff" size="xs" />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NoLink>{l.name}</NoLink>
                  {array.length !== index + 1 && (
                    <CustomIcon name="arrow-right" color="#fff" size="xs" />
                  )}
                </React.Fragment>
              )}
            </CustomLinkWrapper>
          );
          // ) : (
          //   <NoLink key={l.name}>{l.name}</NoLink>
          // );
        })}
      </CustomFlexBoxStart>
    );
  }
}

ControlBarLocation.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
};

export default ControlBarLocation;
