import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 300 },
  },
  {
    name: __('Identifikátor'),
    clickable: true,
    sorterValue: 'template_id',
    otherProps: { minWidth: 300 },
  },
  {
    name: __('Vytvorené'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: { minWidth: 125 },
  },
  {
    name: __('Upravené'),
    clickable: true,
    sorterValue: 'updated_date',
    otherProps: { minWidth: 125 },
  },
  {
    name: __('Tag'),
    clickable: true,
    sorterValue: 'tags',
    otherProps: { minWidth: 125 },
  },
];
