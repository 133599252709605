import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
  },
  {
    name: __('Kategória'),
    clickable: true,
    sorterValue: 'category',
  },
  {
    name: __('Aktualizácia'),
    clickable: true,
    sorterValue: 'updated_date',
  },
];
