import React from 'react';
import { Checkbox, Label } from 'oxyrion-ui/lib';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../Store';

const Row = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: ${props => props.marginLeft};
`;

class CheckBoxesTree extends React.Component {
  handleChange(id) {
    const { onChange } = this.props;

    onChange(id);
  }

  generateLeaf(leaf, level) {
    const { selected } = this.props;
    return leaf.map(item => {
      return (
        <React.Fragment>
          <Row marginLeft={rem(level * 25)}>
            <Checkbox
              checked={selected.indexOf(item._id) > -1}
              label={item.name}
              onChange={() => {
                this.handleChange(item._id);
              }}
            />
            <Label>{item.name}</Label>
          </Row>
          {item.categories && this.generateLeaf(item.categories, level + 1)}
        </React.Fragment>
      );
    });
  }

  render() {
    const { tree } = this.props;

    return <React.Fragment>{this.generateLeaf(tree, 0)}</React.Fragment>;
  }
}

CheckBoxesTree.propTypes = {
  selected: PropTypes.arrayOf({}),
  onChange: PropTypes.func,
  tree: PropTypes.arrayOf({}),
};

CheckBoxesTree.defaultProps = {
  selected: [],
  tree: [],
  onChange: () => {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CheckBoxesTree);
