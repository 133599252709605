import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
  },
  {
    name: __('Dátum a čas od'),
    clickable: false,
  },
  {
    name: __('Dátum a čas do'),
    clickable: false,
  },
  {
    name: __('Publikované pre'),
    clickable: false,
  },
  {
    name: __('Dátum aktualizácie'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
];
