import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import MarketingMaterials from '../../../Containers/MarketingMaterials';
import MarketingMaterialDetail from '../../../Containers/MarketingMaterialDetail';

function MarketingListsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={MarketingMaterialDetail} />
      <Route path={`${match.url}`} component={MarketingMaterials} />
    </Switch>
  );
}

MarketingListsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default MarketingListsCard;
