import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Input, Icon, Loader, Button } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import AddNewProductGroup from '../../Components/AddNewProductGroup';
import SearchableSelect from '../../Components/SearchableSelect';
import { searchBrands } from '../../Utils/ApiSearchFunctions';
import AdminAPI from '../../AdminAPI';

const Wrapper = styled.div`
  display: flex;
  /* overflow: auto; */
  padding: ${rem(4)};
  margin-bottom: ${rem(100)};
  /* padding-bottom: ${rem(500)}; */
`;

const Col = styled.div`
  margin-right: ${rem(24)};
`;

const DeleteRow = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(10)};
  background: #eb4142;
  border-radius: ${rem(2)};
  :hover {
    background: red;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};
  justify-content: space-between;
`;

const GroupWrapper = styled.div`
  border-top: solid ${rem(1)} #ddd;
  border-bottom: solid ${rem(1)} #ddd;
  padding: ${rem(2)};
  width: ${rem(400)};
`;

const BrandsWrapper = styled.div`
  margin-left: ${rem(4)};
  margin-bottom: ${rem(12)};
`;

const SearchableSelectWrapper = styled.div`
  z-index: 1000;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InputWrapper = styled.div`
  margin-bottom: ${rem(2)};
  margin-right: ${rem(20)};
  width: ${rem(200)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

class ProductsGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      loading: true,
      groups: [],
      readyToSave: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const result = await AdminAPI.getProductsGroupsAction();

      this.setState({
        groups: this.normalizeGroups(result.groups || []),
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __(
              'Na zobrazenie produktových skupín nemáte potrebné práva.',
            ),
          });
          break;
        case 404:
          this.setState({
            error: __('Produktové skupiny sa nenašieli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  normalizeGroups(groups) {
    return groups.map(g =>
      Object.assign(g, {
        brands: g.brands.map(b => ({
          label: b.brand_name,
          value: b.brand_id,
        })),
      }),
    );
  }

  handleGroupsChange(index, value, id) {
    const { groups, readyToSave } = this.state;

    if (!groups[index]) {
      return;
    }

    if (!groups[index].brands) {
      groups[index].brands = [];
    }

    groups[index].brands = value;

    readyToSave.push(id);

    this.setState({
      groups,
      readyToSave,
    });
  }

  async saveGroup(id) {
    const { groups, readyToSave } = this.state;

    this.setState({
      savingId: id,
    });

    const groupToSave = groups.find(g => g._id === id);

    if (!groupToSave) {
      return;
    }

    try {
      const params = {};

      params.body = {
        name: groupToSave.name,
        brands: groupToSave.brands.map(b => ({
          brand_id: b.value,
          brand_name: b.label,
        })),
      };

      await AdminAPI.putProductGroupAction(id, params);

      this.setState({
        readyToSave: readyToSave.filter(r => r !== id),
        savingId: '',
      });
    } catch (e) {
      this.setState({
        error: __('Produktovú skupinu sa nepodarilouložiť'),
      });
    }
  }

  async deleteRow(id) {
    const { groups } = this.state;
    this.setState({
      deleteLoadingId: id,
    });
    try {
      await AdminAPI.deleteProductGroupAction(id);
      this.setState({
        groups: groups.filter(g => g._id !== id),
        deleteLoadingId: '',
      });
    } catch (e) {
      this.setState({
        error: __('Produktovú skupinu sa nepodarilo odstrániť'),
      });
    }
  }

  async addGroup(newGroup) {
    const { groups } = this.state;
    this.setState({
      saveLoading: true,
    });
    try {
      const params = {};

      params.body = {
        name: newGroup,
        brands: [],
      };

      const result = await AdminAPI.postProductGroupAction(params);

      groups.push(result);

      this.setState({
        groups,
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Produktovú skupinu sa nepodarilo uložiť'),
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Produktové skupiny')} />;
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      groups,
      loading,
      savingId,
      readyToSave,
      deleteLoadingId,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <Col>
            {groups.map((item, index) => {
              return (
                <GroupWrapper>
                  <Row key={item.value}>
                    <InputWrapper key={item._id}>
                      <Input value={item.name} disabled />
                    </InputWrapper>

                    <DeleteRow onClick={() => this.deleteRow(item._id)}>
                      {deleteLoadingId === item._id ? (
                        <Loader size="xs" />
                      ) : (
                        <Icon name="delete" color="white" />
                      )}
                    </DeleteRow>
                  </Row>
                  <BrandsWrapper>
                    <SearchableSelectWrapper>
                      <SearchableSelect
                        value={item.brands}
                        isMulti
                        isClearable={false}
                        loadOptions={query => {
                          return searchBrands(
                            query,
                            this.props.firm,
                            groups.map(g => g.brands.map(b => b.label)).flat(),
                          );
                        }}
                        placeholder={__('Vyhľadať značky')}
                        handleOnChange={e =>
                          this.handleGroupsChange(index, e, item._id)
                        }
                      />
                    </SearchableSelectWrapper>
                    <Space />
                    {readyToSave.indexOf(item._id) > -1 && (
                      <ButtonWrapper>
                        <Button
                          loading={savingId === item._id}
                          onClick={() => this.saveGroup(item._id)}
                          small
                          primary
                        >
                          {__('Uložiť')}
                        </Button>
                      </ButtonWrapper>
                    )}
                  </BrandsWrapper>
                </GroupWrapper>
              );
            })}
            <Space />
            <AddNewProductGroup
              loading={saveLoading}
              onSubmit={item => this.addGroup(item)}
            />
          </Col>
        </Wrapper>
      </React.Fragment>
    );
  }
}

ProductsGroups.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductsGroups);
