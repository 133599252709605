import { __ } from '../Utils';

export default [
  {
    name: __('Názov entity'),
    clickable: false,
    otherProps: { minWidth: 200, maxWidth: 210 },
  },
  // {
  //   name: __('Názov atribútu'),
  //   clickable: false,
  //   otherProps: { minWidth: 140, maxWidth: 150 },
  // },
  {
    name: __('SK preklad'),
    _id: 'SK',
    clickable: false,
    otherProps: { minWidth: 200, show: true },
  },
  {
    name: __('CZ preklad'),
    _id: 'CZ',
    clickable: false,
    otherProps: { minWidth: 180, show: false },
  },
  {
    name: __('EN preklad'),
    _id: 'EN',
    clickable: false,
    otherProps: { minWidth: 180, show: false },
  },
  {
    name: __('HU preklad'),
    _id: 'HU',
    clickable: false,
    otherProps: { minWidth: 180, show: false },
  },
  {
    name: __('AT preklad'),
    _id: 'AT',
    clickable: false,
    otherProps: { minWidth: 180, show: true },
  },
  {
    name: __(''),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
];
