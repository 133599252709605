import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Varianty'),
    clickable: false,
    sorterValue: 'has_all_variants',
    otherProps: { maxWidth: 60 },
  },
  {
    name: __('Všetky obrázky'),
    clickable: false,
    sorterValue: 'has_all_images',
    otherProps: { maxWidth: 60 },
  },
  {
    name: __('Titulný obrázok'),
    clickable: false,
    sorterValue: 'has_title_image',
    otherProps: { maxWidth: 150 },
  },
  {
    name: __('Meta desc. pod 160'),
    clickable: false,
    sorterValue: 'has_meta_description_over_160',
    otherProps: { maxWidth: 150 },
  },
  {
    name: __('Uzavreté varianty'),
    clickable: false,
    otherProps: { maxWidth: 150 },
  },
  {
    name: __('Dokumentácia'),
    clickable: false,
    otherProps: { maxWidth: 110 },
  },
  {
    name: __('Výpredaj WH010'),
    clickable: false,
    otherProps: { maxWidth: 115 },
  },
  {
    name: __('Dopredaj'),
    clickable: false,
    otherProps: { maxWidth: 75 },
  },
  {
    name: __('Exp. Dok'),
    clickable: false,
    otherProps: { maxWidth: 75 },
  },
  {
    name: __('Typ'),
    clickable: true,
    sorterValue: 'type',
  },
  {
    name: __('Skupina'),
    clickable: true,
    sorterValue: 'brand',
  },
  {
    name: __('Podskupina'),
    clickable: true,
    sorterValue: 'sub_brand',
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
  },
];
