import React from 'react';
import uniqid from 'uniqid';
import { Input, Label, Select } from 'oxyrion-ui/lib';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../Store';
import { __, translate, translateCodelist } from '../../Utils';
import { NumberInput } from '../../Components/ReusableComponents';
import CheckboxesFormGroup from '../CheckboxesFormGroup';
import SelectsFormGroug from '../SelectsFormGroup';
import SearchableSelect from '../SearchableSelect';
import API2 from '../../API2';

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.noMargin ? rem(0) : rem(5))};
`;

const StyledLabel = styled(Label)`
  width: ${rem(150)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${rem(5)};
  font-weight: 500;
`;

const StyledLabelTable = styled(Label)`
  width: ${rem(132)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${rem(5)};
  font-weight: 500;
`;

const Unit = styled.div`
  color: #b7b7b7;
  margin-left: ${rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MultiUnit = styled(Unit)`
  margin-left: 0;
`;

const GroupWrapper = styled.div`
  margin-bottom: ${rem(30)};
`;

const InputWrapper = styled.div``;

const StyledTr = styled(Tr)`
  border: 0;
`;

const StyledTh = styled(Th)`
  text-align: center;
  padding-bottom: 0;
`;

const TableName = styled(Th)`
  text-align: start;
  padding-bottom: 0;
  padding-left: 0;
`;

const StyledTd = styled(Th)`
  padding-left: 0;
`;

const UnitRadioGroup = styled.div`
  display: flex;
  margin-left: ${rem(5)};
`;

const RadioWrapper = styled.div`
  display: flex;
  margin-right: ${rem(5)};
`;

const StyledInput = styled(Input)`
  margin-top: ${rem(10)};
  width: calc(100% - 30px);
  max-width: ${rem(300)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(300)};
`;

const ColorItem = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
`;

const Color = styled.div`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(8)};
`;

const ColorName = styled.div``;

class AdminGroupForm extends React.Component {
  generateGroup(group, indexGroup) {
    return (
      <GroupWrapper>
        <Label>{__(group.group)}</Label>
        {group.params.map((field, index) => {
          return this.generateInputsByType(field, indexGroup, index);
        })}
      </GroupWrapper>
    );
  }

  fetchCodelistData(query, items) {
    return items.filter(i =>
      i.label.toUpperCase().includes(query.toUpperCase()),
    );
  }

  normalizeMultiselectValue(values) {
    return (Array.isArray(values) ? values : [values]).map(value => {
      if (value && value.label && value.value) {
        return value;
      }

      if (value && value.value && value._id) {
        return {
          value: value._id,
          label: value.value,
        };
      }

      return value;
    });
  }

  async fetchProducts(query) {
    try {
      return API2.getCatalogProductsAction(this.props.firm, {
        q: query,
        // filters: JSON.stringify({
        //   filter: [{ id: 'type', values: ['FARBA'], logic: 'OR' }],
        // }),
        limit: 20,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchColors(query) {
    try {
      return API2.getColorauntsAction(this.props.firm, {
        colocardsIds: this.props.additionalProps.colocardsIds || [],
        q: query,
        limit: 20,
      }).then(res => {
        return res.items.map(item => ({
          value: { name: item.color_code, colorCardId: item.colorCardId },
          label: (
            <ColorItem>
              <Color
                style={{
                  backgroundColor: `rgba(${item.color.R},${item.color.G},${item.color.B},1)`,
                }}
              />
              <ColorName>{`${item.color_code}-${item.color_name}`}</ColorName>
            </ColorItem>
          ),
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchSecurityCertificates(query) {
    try {
      return API2.getSecurityCertificatesAction(this.props.firm).then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  generateInputsByType(
    field,
    indexGroup,
    indexField,
    rowIndex,
    columnIndex,
    isTable = false,
  ) {
    const { onChange } = this.props;

    const translatedName = translate(
      this.props.firm,
      field.translations,
      field.name || field._id,
    );

    if (field.type === 'value+-') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <Input
            marginLeft={columnIndex}
            disabled={field.disabled}
            error={field.error}
            placeholder={translatedName}
            value={field.value}
            onChange={e =>
              this.valuePlusMinusCheck(
                e,
                indexGroup,
                indexField,
                rowIndex,
                columnIndex,
              )
            }
          />
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'ratio') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <Input
            marginLeft={columnIndex}
            disabled={field.disabled}
            error={field.error}
            placeholder={translatedName}
            value={field.value}
            onChange={e =>
              this.valueRatioCheck(
                e,
                indexGroup,
                indexField,
                rowIndex,
                columnIndex,
              )
            }
          />
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'number') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <NumberInput
            marginLeft={columnIndex}
            marginRight={!(columnIndex || columnIndex === 0)}
            disabled={field.disabled}
            error={field.error}
            placeholder={translatedName}
            value={field.value || ''}
            onChange={e =>
              this.valueNumberCheck(
                e,
                indexGroup,
                indexField,
                rowIndex,
                columnIndex,
              )
            }
          />
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'codelist') {
      let codelists = [];

      if (field.values && field.values.codelist) {
        codelists = field.values.codelist;
      } else if (field.values.length) {
        codelists = field.values;
      }

      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <Select
            style={{ width: '300px' }}
            marginLeft={columnIndex}
            size="s"
            disabled={field.disabled}
            error={field.error}
            onChange={e => {
              onChange(
                { value: e.target.value, codelist: field.values.codelist },
                indexGroup,
                indexField,
                rowIndex,
                columnIndex,
              );
            }}
          >
            {codelists.length > 0 && (
              <option
                selected={!field.value || !field.value.length}
                value={undefined}
              >
                -
              </option>
            )}
            {codelists.length > 0 ? (
              codelists.map(item => {
                return (
                  <option selected={field.value === item._id} value={item._id}>
                    {translateCodelist(
                      this.props.firm,
                      item.translations,
                      item.label,
                    )}
                  </option>
                );
              })
            ) : (
              <option>{__('Neobsahuje možnosti na výber')}</option>
            )}
          </Select>
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'multiSelect') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <SearchableSelectWrapper>
            <SearchableSelect
              value={this.normalizeMultiselectValue(field.value)}
              isMulti
              loadOptions={query => {
                return this.fetchCodelistData(
                  query,
                  field.values && field.values.codelist
                    ? field.values.codelist
                    : [],
                );
              }}
              isClearable={false}
              disabled={field.disabled}
              placeholder={field.name}
              handleOnChange={e =>
                onChange(e, indexGroup, indexField, rowIndex, columnIndex)
              }
            />
          </SearchableSelectWrapper>

          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'checkboxesGroup') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {/* {field.name && <StyledLabel> {translatedName}</StyledLabel>} */}
          <CheckboxesFormGroup
            options={
              field.values && field.values.codelist ? field.values.codelist : []
            }
            value={field.value}
            disabled={field.disabled}
            placeholderButtonLabel={field.name}
            onChange={e => {
              onChange(e, indexGroup, indexField, rowIndex, columnIndex);
            }}
          />
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'checkbox') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <Checkbox
            onChange={e =>
              onChange(
                e.target.checked,
                indexGroup,
                indexField,
                rowIndex,
                columnIndex,
              )
            }
            checked={field.value}
          />
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'selectGroup') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {/* {field.name && <StyledLabel> {translatedName}</StyledLabel>} */}
          <SelectsFormGroug
            values={
              field.values && field.values.codelist ? field.values.codelist : []
            }
            value={field.value}
            placeholderButtonLabel={field.name}
            disabled={field.disabled}
            onChange={e => {
              onChange(e, indexGroup, indexField, rowIndex, columnIndex);
            }}
          />
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'catalogProductsSelector') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <SearchableSelectWrapper>
            <SearchableSelect
              value={field.value}
              isMulti
              loadOptions={query => {
                return this.fetchProducts(query);
              }}
              disabled={field.disabled}
              placeholder={field.name}
              handleOnChange={e =>
                onChange(e, indexGroup, indexField, rowIndex, columnIndex)
              }
            />
          </SearchableSelectWrapper>
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'colorSelector') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <SearchableSelectWrapper>
            <SearchableSelect
              value={field.value && field.value.name}
              loadOptions={query => {
                return this.fetchColors(query);
              }}
              disabled={field.disabled}
              placeholder={field.name}
              handleOnChange={e =>
                onChange(e, indexGroup, indexField, rowIndex, columnIndex)
              }
            />
          </SearchableSelectWrapper>
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'securityCertificateSelector') {
      return (
        <InputRow noMargin={rowIndex > 0}>
          {field.name && <StyledLabel> {translatedName}</StyledLabel>}
          <SearchableSelectWrapper>
            <SearchableSelect
              value={field.value}
              isMulti
              loadOptions={query => {
                return this.fetchSecurityCertificates(query);
              }}
              disabled={field.disabled}
              placeholder={field.name}
              handleOnChange={e =>
                onChange(e, indexGroup, indexField, rowIndex, columnIndex)
              }
            />
          </SearchableSelectWrapper>
          {this.generateUnitField(
            field.unit,
            field.selectedUnit,
            field.name || uniqid(),
            indexGroup,
            indexField,
            rowIndex,
            columnIndex,
          )}
        </InputRow>
      );
    } else if (field.type === 'table') {
      const values = Array.isArray(field.value) ? field.value : [field.value];
      return (
        <React.Fragment>
          <Table style={{ maxWidth: rem(400), marginTop: rem(15) }}>
            {field.headers && (
              <Thead>
                <StyledTr>
                  <TableName>{field.name && translatedName}</TableName>
                  {field.headers.map(header => {
                    return <StyledTh>{header.name}</StyledTh>;
                  })}
                  <StyledTh />
                </StyledTr>
              </Thead>
            )}
            <Tbody>
              {values.map((row, indexRow) => {
                return (
                  <StyledTr>
                    <StyledTd>
                      {row.name ? (
                        <StyledLabelTable> {__(row.name)}</StyledLabelTable>
                      ) : (
                        <StyledLabelTable />
                      )}
                    </StyledTd>
                    {row.columns &&
                      row.columns.map((cell, indexCell) => {
                        return (
                          <Td>
                            {this.generateInputsByType(
                              cell,
                              indexGroup,
                              indexField,
                              indexRow,
                              indexCell,
                              true,
                            )}
                          </Td>
                        );
                      })}
                    <Td>{row.unit}</Td>
                  </StyledTr>
                );
              })}
            </Tbody>
          </Table>
        </React.Fragment>
      );
    }

    return (
      <InputRow noMargin={rowIndex > 0}>
        {field.name && <StyledLabel> {__(field.name)}</StyledLabel>}
        <InputWrapper isTable={isTable}>
          <StyledInput
            disabled={field.disabled}
            error={field.error}
            placeholder={field.name}
            value={field.value}
            onChange={e =>
              onChange(
                e.target.value,
                indexGroup,
                indexField,
                rowIndex,
                columnIndex,
              )
            }
          />
        </InputWrapper>
        {this.generateUnitField(
          field.unit,
          field.selectedUnit,
          field.name || uniqid(),
          indexGroup,
          indexField,
          rowIndex,
          columnIndex,
        )}
      </InputRow>
    );
  }

  valuePlusMinusCheck(e, indexGroup, indexField, rowIndex, columnIndex) {
    const { onChange } = this.props;
    let newValue = e.target.value;
    if (Number(newValue.replace('±', ''))) {
      if (newValue.length === 1) {
        newValue += '±';
      } else {
        newValue += '';
      }
      onChange(`${newValue}`, indexGroup, indexField, rowIndex, columnIndex);
    } else {
      onChange(``, indexGroup, indexField, rowIndex, columnIndex);
    }
  }

  valueRatioCheck(e, indexGroup, indexField, rowIndex, columnIndex) {
    const { onChange } = this.props;
    let newValue = e.target.value;
    if (Number(newValue.replace(':', ''))) {
      if (newValue.length === 1) {
        newValue += ':';
      } else {
        newValue += '';
      }
      onChange(`${newValue}`, indexGroup, indexField, rowIndex, columnIndex);
    } else {
      onChange(``, indexGroup, indexField, rowIndex, columnIndex);
    }
  }

  valueNumberCheck(e, indexGroup, indexField, rowIndex, columnIndex) {
    const { onChange } = this.props;
    const newValue = e.target.value.replace(',', '.');
    if (!Number.isNaN(Number(newValue))) {
      onChange(`${newValue}`, indexGroup, indexField, rowIndex, columnIndex);
    } else if (newValue.length === 0) {
      onChange(``, indexGroup, indexField, rowIndex, columnIndex);
    }
  }

  generateUnitField(
    unit,
    selectedUnit,
    fieldName,
    indexGroup,
    indexField,
    rowIndex,
    columnIndex,
  ) {
    const { handleUnitChange } = this.props;

    if (!unit) {
      return <React.Fragment />;
    }
    if (!Array.isArray(unit)) {
      return <Unit>{unit.value ? unit.value : unit}</Unit>;
    }
    if (unit.length === 1 && unit[0]) {
      return <Unit>{unit[0].value}</Unit>;
    }
    if (unit.length > 1) {
      const selected = selectedUnit || unit[0].value;

      return (
        <UnitRadioGroup name={fieldName}>
          {unit.map(u => (
            <RadioWrapper key={uniqid()}>
              <Checkbox
                key={uniqid()}
                checked={u && u.value === selected}
                value={u.value || ''}
                name="unit"
                onChange={() =>
                  handleUnitChange(
                    indexGroup,
                    indexField,
                    rowIndex,
                    columnIndex,
                    u.value,
                  )
                }
              />
              <MultiUnit>{u.label || ''}</MultiUnit>
            </RadioWrapper>
          ))}
        </UnitRadioGroup>
      );
    }

    return <React.Fragment />;
  }

  render() {
    const { groups } = this.props;
    return (
      <GroupWrapper>
        {groups.map((group, index) => {
          return this.generateGroup(group, index);
        })}
      </GroupWrapper>
    );
  }
}

AdminGroupForm.propTypes = {
  onChange: PropTypes.func,
  handleUnitChange: PropTypes.func,
  additionalProps: PropTypes.shape({}),
  groups: PropTypes.arrayOf({
    groupName: PropTypes.string,
    params: PropTypes.arrayOf({
      name: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      units: PropTypes.string,
    }),
  }).isRequired,
};

AdminGroupForm.defaultProps = {
  onChange: () => {},
  handleUnitChange: () => {},
  additionalProps: {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(AdminGroupForm);
