import React from 'react';
import { Route } from 'react-router-dom';
import Filters from './Filters';

function CatalogSettings(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.url}/filters`} component={Filters} />
    </React.Fragment>
  );
}

export default CatalogSettings;
