import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
  },
  {
    name: __('Vlastník'),
    clickable: false,
  },
  {
    name: __('Krajina'),
    clickable: false,
  },
  {
    name: __('Počet zákazníkov'),
    clickable: false,
  },
  {
    name: __('Počet emailov'),
    clickable: false,
  },
  {
    name: __('Počet čísel'),
    clickable: false,
  },
  {
    name: __('Typ'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
  },

  {
    name: __('Dátum poslednej úpravy'),
    clickable: false,
  },
];
