import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from 'oxyrion-ui/lib/Loader';
import FeeRow from './FeeRow';
import { LoaderWrapper } from '../ReusableComponents';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

class FeesSettings extends React.Component {
  render() {
    const {
      fees,
      loading,
      onFeeSave,
      onFeeDelete,
      showAddPayments,
      lang,
      saveLoading,
      type,
    } = this.props;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper>
        {fees.map(fee => (
          <FeeRow
            onSave={data => onFeeSave(data)}
            onDelete={id => onFeeDelete(id)}
            key={fee._id || 'new'}
            fee={fee}
            showAddPayments={showAddPayments}
            saveLoading={saveLoading}
            lang={lang}
            type={type}
          />
        ))}
      </Wrapper>
    );
  }
}

FeesSettings.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.shape({})),
  onFeeSave: PropTypes.func.isRequired,
  onFeeDelete: PropTypes.func.isRequired,
  loading:PropTypes.bool,
  lang: PropTypes.string,
  showAddPayments: PropTypes.bool,
  saveLoading: PropTypes.bool,
};

FeesSettings.defaultProps = {
  fees: [],
  loading: false,
  lang: 'SK',
  showAddPayments: false,
  saveLoading: false,
};

export default FeesSettings;
