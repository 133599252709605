import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Icon } from 'oxyrion-ui/lib';
import DropZone from 'react-dropzone';
import Loader from 'oxyrion-ui/lib/Loader';
import playImg from './playImg.png';

const Wrapper = styled.div`
  width: ${rem(300)};
  background: #e6e6e6;
  height: ${rem(233)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(5)};
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: ${rem(300)};
  height: ${rem(233)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(5)};
`;

const PlayImage = styled.img`
  width: ${rem(110)};
  position: absolute;
  opacity: 0.7;
  z-index: 3;
`;

class ImageLoader extends React.Component {
  render() {
    const { path, video, loading, onChange } = this.props;

    return (
      <Wrapper>
        {loading ? (
          <Loader size="xl" />
        ) : (
          <React.Fragment>
            {video && <PlayImage src={playImg} />}
            <DropZone
              onDrop={accepted => onChange(accepted[0])}
              acceptedFiles="image/*"
              activeStyle={{
                outlineWidth: rem(5),
              }}
            >
              {!path ? (
                <Icon name="plus" color="#999" size="xl" />
              ) : (
                <StyledImage
                  src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${path}`}
                />
              )}
            </DropZone>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

ImageLoader.propTypes = {
  path: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  video: PropTypes.bool,
  loading: PropTypes.bool,
};

ImageLoader.defaultProps = {
  path: null,
  video: false,
  loading: true,
};

export default ImageLoader;
