import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexBoxStart } from '../ReusableComponents';
import GlobalSearchList from '../GlobalSearchList';

const Wrapper = styled(FlexBoxStart)`
  display: ${({ display }) => (display ? 'flex' : 'none')}
  position: absolute;
  min-height: 20vh;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0 0 4px 4px ${({ theme }) => theme.separatorColor};
  align-items: baseline;
  flex-wrap: wrap;
  max-height: 70vh;
  overflow: auto;
  ${({ theme }) => theme.media.l`
    max-height: 50vh;
  `}
`;

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { display, data, onItemClick } = this.props;
    return (
      <Wrapper display={display}>
        {data.map((item, index) => {
          if (!item.loading && !item.data.length) {
            return <React.Fragment />;
          }
          return (
            <GlobalSearchList
              key={`${item.header}-${index.toString()}`}
              data={item.data}
              path={item.path}
              header={item.header}
              loading={item.loading}
              error={item.error}
              onItemClick={() => onItemClick()}
            />
          );
        })}
      </Wrapper>
    );
  }
}

SearchResults.propTypes = {
  display: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.string,
      loading: PropTypes.bool,
      header: PropTypes.string.isRequired,
      paht: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

SearchResults.defaultProps = {
  display: false,
};

export default SearchResults;
