import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CashRegisterSettings from '../../../Containers/CashRegisterSettings';

function CashRegisters(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={CashRegisterSettings} />
    </Switch>
  );
}

CashRegisters.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CashRegisters;
