import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label, Checkbox } from 'oxyrion-ui/lib';
import { translate } from '../../Utils';

const Wrapper = styled.div`
  margin-left: ${rem(15)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

class CheckboxesFormGroup extends Component {
  handleClick(e) {
    const { value, onChange } = this.props;

    if (Array.isArray(value)) {
      value.push(e);

      onChange(value);
    } else {
      onChange([e]);
    }
  }

  render() {
    const { options, value } = this.props;

    return (
      <Wrapper>
        {options &&
          options.map(o => {
            const isChecked =
              value && Array.isArray(value)
                ? value.find(v => v._id === o._id)
                : value._id === o._id;

            const valueToAdd = {
              label: o.label,
              _id: o._id,
            };

            return (
              <Row>
                <Checkbox
                  onChange={() => this.handleClick(valueToAdd)}
                  checked={isChecked}
                />
                <Label onClick={() => this.handleClick(valueToAdd)}>
                  {translate('SK', o.translations, o.label)}
                </Label>
              </Row>
            );
          })}
      </Wrapper>
    );
  }
}

CheckboxesFormGroup.propTypes = {
  value: PropTypes.arrayOf({}),
  onChange: PropTypes.func,
};

CheckboxesFormGroup.defaultProps = {
  value: [],
  onChange: () => {},
};

export default CheckboxesFormGroup;
