import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Message,
  Loader,
  Tabs,
  ThemeAdmin,
  Icon,
  Button,
} from 'oxyrion-ui/lib';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import { connect } from '../../Store';
import { formatColorToRGB, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import PopUpWrapper from '../../Components/PopUpWrapper';
import GreyBox from '../../Components/GreyBox';
import SearchableSelect from '../../Components/SearchableSelect';
import Categorization from './Categorization';
import AdminAPI from '../../AdminAPI';
import API2 from '../../API2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding: ${rem(4)};
  margin-bottom: ${rem(100)};
  min-height: ${rem(800)};
`;

const ColorCardWrapper = styled.div`
  width: ${rem(400)};
  margin: ${rem(12)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(300)};
  margin-top: ${rem(24)};
`;

const SortSelectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${rem(600)};

  .select-colorcard-title {
    margin-bottom: ${rem(24)};
    margin-top: ${rem(24)};
  }

  .select-colorcard-label {
    font-weight: bold;
    margin-bottom: ${rem(24)};
  }
`;

const SelectedShadeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${rem(200)};

  .category-name {
    font-size: 10px;
    font-weight: bold;
  }

  .color-holder {
    background: ${props => props.color};
    width: ${rem(400)};
    height: ${rem(400)};
    background-image: ${props => `url(${props.imageFullPath})`};
    border: 1px solid #eee;
    margin: ${rem(24)};
  }

  .color-name {
    margin-top: 24px;
    font-weight: bold;
    font-size: 30px;
  }

  .color-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .color-categories-wrapper {
    display: flex;
  }

  .arrow-left-wrapper,
  .arrow-right-wrapper {
    cursor: pointer;
  }
`;

const ColorCategory = styled.div`
  background: ${props => props.color};
  width: ${rem(80)};
  height: ${rem(80)};
  margin: ${rem(8)};
  border-radius: ${rem(80)};
  border: 5px solid ${props => (props.preSelected ? 'red' : '#eee')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  cursor: pointer;

  :hover {
    border: 5px solid red;
  }
`;

const ColorCategoryLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${rem(12)};

  .color {
    background: ${props => props.color};
    width: ${rem(80)};
    height: ${rem(40)};
    margin-right: ${rem(8)};
    border: 1px solid #eee;
  }
`;

class ShadeCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTab: 'tab1',
      colorCategories: [],
      showShadeSorter: false,
      shades: [],
      limit: 100,
      offset: 0,
      onlyFavorit: true,
      categoryPositionToAdd: 0,
    };
  }

  async componentDidMount() {
    document.addEventListener('keydown', props => this.handleKey(props));

    try {
      const colorCategoriesResult = await AdminAPI.getColorCategoriesAction();

      this.setState({
        colorCategories: colorCategoriesResult.categories.filter(
          c => !c.ancestors || c.ancestors.length === 0,
        ),
      });
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa načítať farebné skupiny'),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', props => this.handleKey(props));
  }

  setSelectedShade(colorCode, toSave) {
    const { shades, selectedColorCardForSort } = this.state;
    const shadeToSort = shades.find(s => s.color_code === colorCode);
    const indexOf = shades.map(s => s.color_code).indexOf(colorCode);
    const prevShade = shades[indexOf - 1];
    const shadesColorCodes = shades.map(s => s.color_code);
    shadeToSort.prev_id = shades[indexOf - 1] && shades[indexOf - 1].color_code;
    shadeToSort.next_id = shades[indexOf + 1] && shades[indexOf + 1].color_code;
    shadeToSort.position = shadesColorCodes.indexOf(shadeToSort.color_code) + 1;

    if (prevShade) {
      try {
        const params = {};
        params.body = {
          color_code: toSave.color_code,
          category_ids: toSave.categories,
        };

        AdminAPI.putShadeCategorization(selectedColorCardForSort.value, params);
      } catch (e) {
        console.log(e);
      }
    }

    this.setState({
      shadeToSort,
      categoryPositionToAdd: 0,
    });
  }

  handleKey(props) {
    if (props.key === 'Enter') {
      this.handleAKey();
    } else if (props.key === 'Shift') {
      this.handleSpace();
    } else if (props.key === 'ArrowLeft') {
      this.navigateCategoryLeft();
    } else if (props.key === 'ArrowRight') {
      this.navigateCategoryRight();
    }
  }

  async saveMultipleData(data = []) {
    const { selectedColorCard } = this.state;

    if (data.length === 0) {
      return;
    }

    this.setState({
      saveLoading: true,
    });

    try {
      const params = {};
      params.body = {
        categories: data,
      };

      await AdminAPI.putShadesCategorization(selectedColorCard.value, params);

      this.handleSelectedColorcardChange(selectedColorCard);
      this.setState({
        success: __('Dáta úspešne uložené'),
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  finishSorting(toSave) {
    const { selectedColorCardForSort } = this.state;

    const oldselectedColorCardForSort = Object.assign(
      {},
      selectedColorCardForSort,
    );

    try {
      const params = {};
      params.body = {
        color_code: toSave.color_code,
        category_ids: toSave.categories,
      };

      AdminAPI.putShadeCategorization(selectedColorCardForSort.value, params);

      this.setState(
        {
          shadeToSort: undefined,
          selectedColorCardForSort: undefined,
        },
        () =>
          this.handleSelectedColorcardChange({
            value: oldselectedColorCardForSort.value,
            label: oldselectedColorCardForSort.label,
            showShadeSorter: false,
          }),
      );
    } catch (e) {
      console.log(e);
    }
  }

  async handleSelectedColorcardChange(e) {
    const { colorCategories, limit, offset } = this.state;

    try {
      const { shades } = await AdminAPI.getColorcardShadesCategorizations(
        e.value,
        {
          limit,
          offset,
        },
      );

      this.setState({
        shades: shades.map(s =>
          Object.assign(s, {
            categories: s.categories
              ? s.categories
                  .map(c => {
                    return c.category_ids.map(id => {
                      const fullCategory = colorCategories.find(
                        category => category._id === id,
                      );

                      return {
                        value: fullCategory._id,
                        label: (
                          <ColorCategoryLabel
                            color={formatColorToRGB(fullCategory.rgb)}
                          >
                            <div className="color" />
                            <div className="name">{fullCategory.name}</div>
                          </ColorCategoryLabel>
                        ),
                        color: fullCategory.rgb,
                      };
                    });
                  })
                  .flat()
              : [],
          }),
        ),
      });
    } catch (error) {
      console.log(error);
      this.setState({
        error: __('Odtiene sa nepodarilo načítať'),
      });
    }

    if (e) {
      this.setState({
        selectedColorCard: e,
      });
    } else {
      this.setState({
        selectedColorCard: null,
      });
    }
  }

  async handleSelectedColorcardForSortingChange(e) {
    try {
      const { shades } = await AdminAPI.getColorcardShadesCategorizations(
        e.value,
        {
          limit: 10000,
          offset: 0,
        },
      );

      this.setState({
        shades: shades.map(s =>
          Object.assign(s, {
            categories: s.categories
              ? s.categories.map(c => c.category_ids).flat()
              : [],
          }),
        ),
      });

      if (e) {
        this.setState({
          selectedColorCardForSort: e,
          shadeToSort:
            shades &&
            Object.assign(shades[0], {
              prev_id: null,
              next_id: shades[1] && shades[1].color_code,
              position: 1,
            }),
        });
      } else {
        this.setState({
          selectedColorCardForSort: null,
          shadeToSort: null,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        error: __('Odtiene sa nepodarilo načítať'),
      });
    }
  }

  toggleShadeCategory(categoryId) {
    const { shadeToSort } = this.state;

    if (!shadeToSort.categories) {
      shadeToSort.categories = [];
    }

    if (shadeToSort.categories.includes(categoryId)) {
      shadeToSort.categories = shadeToSort.categories.filter(
        c => c !== categoryId,
      );
    } else {
      shadeToSort.categories.push(categoryId);
    }

    this.setState({ shadeToSort });
  }

  fetchColorCards(query) {
    const { onlyFavorit } = this.state;
    try {
      return API2.getColorcardsAction(this.props.firm, {
        q: query,
        limit: 100,
        onlyFavorit,
      }).then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
          shades: item.shades,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  startSorting() {
    this.setState({
      showShadeSorter: true,
    });
  }

  handleSpace() {
    const { selectedColorCardForSort, shadeToSort } = this.state;

    if (selectedColorCardForSort && shadeToSort && shadeToSort.next_id) {
      this.setSelectedShade(shadeToSort.next_id, shadeToSort);
    }
  }

  handleAKey = () => {
    const {
      selectedColorCardForSort,
      shadeToSort,
      categoryPositionToAdd,
      colorCategories,
    } = this.state;

    if (selectedColorCardForSort && shadeToSort) {
      this.toggleShadeCategory(colorCategories[categoryPositionToAdd]._id);
    }
  };

  navigateCategoryRight() {
    const {
      categoryPositionToAdd,
      colorCategories,
      selectedColorCardForSort,
      shadeToSort,
    } = this.state;
    if (selectedColorCardForSort && shadeToSort) {
      if (colorCategories[categoryPositionToAdd + 1]) {
        this.setState({
          categoryPositionToAdd: categoryPositionToAdd + 1,
        });
      } else {
        this.setState({
          categoryPositionToAdd: 0,
        });
      }
    }
  }

  navigateCategoryLeft() {
    const {
      categoryPositionToAdd,
      colorCategories,
      shadeToSort,
      selectedColorCardForSort,
    } = this.state;
    if (selectedColorCardForSort && shadeToSort) {
      if (colorCategories[categoryPositionToAdd - 1]) {
        this.setState({
          categoryPositionToAdd: categoryPositionToAdd - 1,
        });
      } else {
        this.setState({
          categoryPositionToAdd: colorCategories.length - 1,
        });
      }
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Triedenie odtieňov')}>
        <ControlBarButton
          icon="assignment"
          primary
          small
          onClick={() => this.startSorting()}
        >
          {__('Sprievodca triedením odtieňov')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      error,
      success,
      loading,
      showShadeSorter,
      activeTab,
      selectedColorCard,
      colorCategories,
      selectedColorCardForSort,
      shadeToSort,
      onlyFavorit,
      shades,
      saveLoading,
      categoryPositionToAdd,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {showShadeSorter && (
          <PopUpWrapper
            display={showShadeSorter}
            maxWidth={1600}
            // customWidth={1200}
            message={__('Triedenie odtieňov')}
            onClose={() => {
              this.setState({
                showShadeSorter: false,
              });

              if (selectedColorCard) {
                this.handleSelectedColorcardChange(selectedColorCard);
              }
            }}
          >
            {!selectedColorCardForSort && (
              <SortSelectCardWrapper>
                <div className="select-colorcard-title">
                  {__('Triedenie prejde všetky odtiene zvoleného vzorkovníka')}
                </div>

                <div className="select-colorcard-label">
                  {__('Zvolte si vzorkovník, ktorý chcete triediť')}
                </div>

                <LabeledCheckbox
                  checked={onlyFavorit}
                  labelText={__('Iba obľubené')}
                  onChange={e =>
                    this.setState({
                      onlyFavorit: e.target.checked,
                    })
                  }
                />

                <SearchableSelectWrapper>
                  <SearchableSelect
                    isClearable={false}
                    value={selectedColorCardForSort}
                    loadOptions={query => {
                      return this.fetchColorCards(query);
                    }}
                    keyProps={`main_${onlyFavorit}`}
                    placeholder={__('Vzorkovník')}
                    handleOnChange={e =>
                      this.handleSelectedColorcardForSortingChange(e)
                    }
                  />
                </SearchableSelectWrapper>
              </SortSelectCardWrapper>
            )}
            {selectedColorCardForSort && shadeToSort && (
              <SelectedShadeWrapper
                color={formatColorToRGB(shadeToSort.color)}
                imageFullPath={`https://api.oxyrion.com/v1/images/400x0/${
                  shadeToSort.image_path
                }`}
              >
                <div className="color-wrapper">
                  {shadeToSort.prev_id && (
                    <div
                      className="arrow-left-wrapper"
                      onClick={() =>
                        this.setSelectedShade(shadeToSort.prev_id, shadeToSort)
                      }
                    >
                      <Icon name="arrow-left" size="xl" />
                    </div>
                  )}
                  <div>
                    <div className="color-name">{shadeToSort.color_code}</div>
                    <div className="color-holder" />
                  </div>
                  {shadeToSort.next_id ? (
                    <div
                      className="arrow-right-wrapper"
                      onClick={() =>
                        this.setSelectedShade(shadeToSort.next_id, shadeToSort)
                      }
                    >
                      <Icon name="arrow-right" size="xl" />
                    </div>
                  ) : (
                    <Button
                      onClick={() => this.finishSorting(shadeToSort)}
                      primary
                    >
                      {__('Ukončiť')}
                    </Button>
                  )}
                </div>
                <div className="color-categories-wrapper">
                  {colorCategories.map((c, index) => (
                    <div>
                      <div className="category-name">{c.name}</div>
                      <ColorCategory
                        selected={
                          shadeToSort.categories &&
                          shadeToSort.categories.includes(c._id)
                        }
                        preSelected={categoryPositionToAdd === index}
                        color={formatColorToRGB(c.rgb)}
                        onClick={() => this.toggleShadeCategory(c._id)}
                      >
                        {shadeToSort.categories &&
                          shadeToSort.categories.includes(c._id) && (
                            <Icon name="done" color="#80ff00" size="l" />
                          )}
                      </ColorCategory>
                    </div>
                  ))}
                </div>
                <div className="shade-position">
                  {`${shadeToSort.position}/${shades.length}`}
                </div>
              </SelectedShadeWrapper>
            )}
          </PopUpWrapper>
        )}

        {this.renderControlBar()}
        <Wrapper>
          <ColorCardWrapper>
            <GreyBox title={__('Vzorkovník')}>
              <LabeledCheckbox
                checked={onlyFavorit}
                labelText={__('Iba obľubené')}
                onChange={e =>
                  this.setState({
                    onlyFavorit: e.target.checked,
                  })
                }
              />
              <SearchableSelectWrapper>
                <SearchableSelect
                  isClearable={false}
                  value={selectedColorCard}
                  loadOptions={query => {
                    return this.fetchColorCards(query);
                  }}
                  keyProps={`main_1_${onlyFavorit}`}
                  placeholder={__('Vzorkovník')}
                  handleOnChange={e => this.handleSelectedColorcardChange(e)}
                />
              </SearchableSelectWrapper>
            </GreyBox>
          </ColorCardWrapper>

          {selectedColorCard && (
            <Tabs
              color={ThemeAdmin.color.primary}
              onChange={to => {
                this.setState({
                  activeTab: to,
                });

                return true;
              }}
              activeTab={{
                id: activeTab,
              }}
            >
              <Tabs.Tab id="tab1" title={__('Triedenie')}>
                <Categorization
                  shades={shades}
                  colorCategories={colorCategories}
                  saveData={data => this.saveMultipleData(data)}
                  saveLoading={saveLoading}
                />
              </Tabs.Tab>
              {/* <Tabs.Tab id="tab2" title={__('Zoradzovanie')}>
                <Sorting />
              </Tabs.Tab> */}
            </Tabs>
          )}
        </Wrapper>
      </React.Fragment>
    );
  }
}

ShadeCategories.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ShadeCategories);
