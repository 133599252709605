import React, { Component } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { Icon } from 'oxyrion-ui/lib';

const Wrapper = styled.div`
  outline: none;
  color: ${({ sorter, theme }) => sorter && theme.color.primary};
  font-size: ${rem(12)};
`;
const SpaceWrapper = styled.span`
  margin-left: ${({ left, value }) => left && rem(value || 10)}};
  margin-right: ${({ right, value }) => right && rem(value || 10)}};
  margin-top: ${({ top, value }) => top && rem(value || 10)}};
  margin-bottom: ${({ bottom, value }) => bottom && rem(value || 10)}};
`;

const Bold = styled.span`
  font-weight: ${({ weight }) => weight || 'bold'};
`;

const PositionContent = styled.div`
  text-align: ${({ at }) => at || 'center'};
`;

class IconTd extends Component {
  render() {
    const { sorter } = this.props;
    return (
      <Wrapper {...this.props}>
        <PositionContent at="left">
          <Bold>{this.props.children}</Bold>
          <SpaceWrapper left>
            <Icon
              name={
                sorter && sorter.indexOf('-') === 0 ? 'arrow-down' : 'arrow-top'
              }
              size="xs"
            />
          </SpaceWrapper>
        </PositionContent>
      </Wrapper>
    );
  }
}

IconTd.propTypes = {
  sorter: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
};

IconTd.defaultProps = {
  sorter: null,
};

export default IconTd;
