import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PriceList from '../../../Containers/PriceLists';

function CustomersCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={PriceList} />
    </Switch>
  );
}

CustomersCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
};

export default CustomersCard;
