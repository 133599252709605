import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Button, Loader, Input } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import GreyBox from '../../Components/GreyBox';
import API from '../../API';
import AdminAPI from '../../AdminAPI';

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: ${rem(16)};
  margin-bottom: ${rem(100)};
  max-width: ${rem(500)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  right: ${rem(24)};
  padding: ${rem(24)};
  bottom: 0px;
`;

const InputWrapper = styled.div`
  width: 80%;
`;

class PurchasePricesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      loading: true,
      CZKCurrencyRate: '',
      coefficient: '',
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async getNewRate() {
    this.setState({
      rateLoading: true,
    });

    try {
      const result = await API.getNbsDailyStatsAction({
        currency: 'CZK',
      });
      this.setState(
        {
          CZKCurrencyRate: result && Number(result.amount),
        },
        () => this.saveData(),
      );
    } catch (e) {
      this.setState({
        error: __('Kurz sa nepodarilo načítať'),
        rateLoading: false,
      });
    }
  }

  async fetchData() {
    try {
      const settings = await AdminAPI.getPurchaseSettingsAction();

      this.setState({
        coefficient: settings.coefficient || 0,
        CZKCurrencyRate: settings.CZKCurrencyRate || 0,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nastavení cien nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Nastavenia cien sa nenašieli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async saveData() {
    const { coefficient, CZKCurrencyRate } = this.state;

    try {
      const params = {};

      params.body = {
        CZKCurrencyRate: Number(CZKCurrencyRate) || 0,
        coefficient: Number(coefficient) || 0,
      };

      const settings = await AdminAPI.putPurchaseSettingsAction(params);

      this.setState({
        coefficient: settings.coefficient || 0,
        CZKCurrencyRate: settings.CZKCurrencyRate || 0,
        saveLoading: false,
        rateLoading: false,
        success: __('Dáta boli aktualizované'),
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nastavení cien nemáte potrebné práva.'),
            saveLoading: false,
            rateLoading: false,
          });
          break;
        case 404:
          this.setState({
            error: __('Nastavenia cien sa nenašieli'),
            saveLoading: false,
            rateLoading: false,
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
            saveLoading: false,
            rateLoading: false,
          });
          break;
      }
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Nákupné ceny')} />;
  }

  render() {
    const {
      error,
      success,
      loading,
      saveLoading,
      coefficient,
      CZKCurrencyRate,
      rateLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <GreyBox title={__('Hodnota koeficientu pre CZ')}>
            <InputWrapper>
              <Input
                type="number"
                placeholder={__('Hodnota koeficientu pre CZ')}
                value={coefficient}
                onChange={e =>
                  this.setState({
                    coefficient: e.target.value,
                  })
                }
              />
            </InputWrapper>
          </GreyBox>
          <Space />
          <GreyBox title={__('Nastavenie kurzu CZK')}>
            <InputWrapper>
              <Input
                type="number"
                placeholder={__('Nastavenie kurzu CZK')}
                value={CZKCurrencyRate}
                onChange={e =>
                  this.setState({
                    CZKCurrencyRate: e.target.value,
                  })
                }
              />
            </InputWrapper>
          </GreyBox>

          <ButtonWrapper>
            <Button
              primary
              onClick={() => this.getNewRate()}
              loading={rateLoading}
            >
              {__('Aktualizovať kurz')}
            </Button>
            <Space />
            <Button
              primary
              onClick={() => {
                this.setState(
                  {
                    saveLoading: true,
                  },
                  () => this.saveData(),
                );
              }}
              loading={saveLoading}
            >
              {__('Uložiť')}
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

PurchasePricesSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PurchasePricesSettings);
