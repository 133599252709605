// export default {
//   B2B: ['Retail Shop', 'Wholeseller', 'Big Box', 'eshop'],
//   'B2C-I': ['Metal Industry', 'Wood Industry', 'Floor Industry'],
//   'B2C-P': ['Building Industry', 'Industry Applicator', 'Deco Applicator'],
//   'B2C-C': ['Consumer'],
// };

const customerTypes = [
  {
    name: 'B2B',
    type: 'company',
    subtypes: ['Retail Shop', 'Wholeseller', 'Big Box', 'eshop'],
  },
  {
    name: 'B2C-I',
    type: 'company',
    subtypes: ['Metal Industry', 'Wood Industry', 'Floor Industry'],
  },
  {
    name: 'B2C-P',
    type: 'company',
    subtypes: ['Building Industry', 'Industry Applicator', 'Deco Applicator'],
  },
  {
    name: 'B2C-C',
    type: 'person',
    subtypes: ['Consumer'],
  },
];

export default customerTypes;
