import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
// import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/inspirations';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';

import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';

class Inspirations extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Inšpirácie');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'objects',
      'surfaces',
      'state',
      'owner',
      'updated_date',
      'created_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      states: [
        {
          label: __('Na spracovanie'),
          value: 'for_approval',
        },
        {
          label: __('Spracovaná'),
          value: 'all',
        },
      ],
      selectedState: { label: __('Všetky stavy'), value: null },
      downloadSelectedLoading: false,
      commonMessage: { label: __('Všetky'), value: null },
      lastSearch: '',
      types: [],
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/inspirations/${id}`);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  handleCommonMessage(value, name) {
    this.setState({ commonMessage: { label: name, value } }, () => {
      this.fetchData({ category: value });
    });
  }

  handleStateChange(value, name) {
    this.setState({ selectedState: { label: name, value } }, () => {
      this.fetchData();
    });
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, lastSearch, selectedState } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedState && selectedState.value) {
        params.state = selectedState.value;
      }

      const newData = await AdminAPI.getInspirationsAction(params);

      const content = this.normalizeColumns(newData.inspirations);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  getStateData(state) {
    if (state === 'for_approval') {
      return __('Na spracovanie');
    }
    return __('Spracovaná');
  }

  normalizeColumns(data) {
    const { types } = this.state;

    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(`./inspirations/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        category: _shapeData(
          types.find(t => t.value === item.category) &&
            types.find(t => t.value === item.category).label,
        ),
        objects: _shapeData(item.objects && item.objects.join(',')),
        surfaces: _shapeData(item.surfaces && item.surfaces.join(',')),
        owner: _shapeData(item.owner && item.owner.display_name),
        state: _shapeData(this.getStateData(item.status)),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { selectedState, states } = this.state;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Inšpirácie')}
      >
        <ControlButton name={selectedState.label}>
          <LinkWrap>
            <div
              onClick={() =>
                this.handleObjectChange(undefined, __('Všetky stavy'))
              }
            >
              {__('Všetky stavy')}
            </div>
            {states.map(c => {
              return (
                <div onClick={() => this.handleStateChange(c.value, c.label)}>
                  {c.label}
                </div>
              );
            })}
          </LinkWrap>
        </ControlButton>

        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať inšpiráciu')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Inspirations);
