import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import MarketingMaterialDetail from '../../../Containers/MultimediaDetail';
// import MarketingMaterialDetail from '../../../Containers/MarketingMaterialDetail';

function MultimediaCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route
        path={`${match.url}/:id/:type?`}
        component={MarketingMaterialDetail}
      />
    </Switch>
  );
}

MultimediaCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default MultimediaCard;
