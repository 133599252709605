import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Checkbox, Input, Label } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';

// const DatePickerWrapper = styled.div`
//   max-width: ${rem(150)};
//   margin-right: ${rem(10)};
// `;
// const StyledLabel = styled(Label)`
//   margin-right: ${rem(4)};
// `;
// const StyledDatePicker = styled(DatePicker)`
//   max-width: 90%;
// `;
const PopUpContentWrapper = styled.div`
  width: ${rem(1100)};
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(24)} ${rem(24)} ${rem(24)};
`;
const CountryValidityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rem(24)};
`;
// const CountryInputWrapper = styled.div`
//   width: 45%;
// `;
const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(4)};
  font-size: ${rem(10)};
  color: red;
`;

// const ValidityInputWrapper = styled.div`
//   width: 45%;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
// `;
const ProductsToAddTable = styled.div`
  margin-bottom: ${rem(24)};
  min-height: ${rem(400)};
  table {
    font-size: ${rem(12)};
    width: 100%;
  }
  th {
    padding: ${rem(10)};
    font-size: ${rem(14)};
  }
  td {
    padding: ${rem(5)};
  }
  .price-input-wrapper {
    padding-left: ${rem(15)};
    padding-right: ${rem(30)};
  }
  tr {
    padding-bottom: ${rem(10)};
  }
  tr:nth-child(even) {
    background: #f7f7f7;
  }

  .product-to-add-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: ${rem(8)};
    padding-bottom: ${rem(8)};
  }
  .product-to-add-wrapper:nth-child(2n) {
    background-color: lightgray;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  margin-left: ${rem(20)};
`;

const PublicationWrapper = styled.div``;

const publishedChannels = ['xPaint', 'Portal'];

class EditSaleProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validProductsData: true,
      product: null,
    };
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.editProductId !== this.props.editProductId) {
      if (this.props.editProductId !== null) {
        const saleProduct = await AdminAPI.getSaleProductAction(
          this.props.editProductId,
        );
        this.setState({
          product: saleProduct,
        });
      }
    }
  }
  checkProductValidity() {
    const { product } = this.state;
    let valid = true;
    if (!product.discount_percent && !product.price) {
      valid = false;
    }
    this.setState({ validProductsData: valid });
    return valid;
  }
  cancelEditingSaleProduct() {
    this.setState({ product: null });
    this.props.hideAndShowEditProduct();
  }
  changePercentage(percent) {
    const { product } = this.state;
    if (percent > 100 || percent < 0) {
      return;
    }
    product.discount_percent = percent;
    this.setState({ product }, () => this.checkProductValidity());
  }
  // changeProductNumber(no, productNumber) {
  //   const { product } = this.state;
  //   if (productNumber === '') return;
  //   product.product_number = productNumber;
  //   this.setState({ product }, () => this.checkProductValidity());
  // }
  changePrice(price) {
    const { product } = this.state;
    if (price < 0) return;
    product.price = price;
    this.setState({ product }, () => this.checkProductValidity());
  }
  changeDefaultPrice(price) {
    const { product } = this.state;
    if (price < 0) return;
    product.default_price = price;
    this.setState({ product }, () => this.checkProductValidity());
  }

  render() {
    const { product, validProductsData } = this.state;
    const { editProductId, editingSaleProductLoading } = this.props;

    if (!product) {
      return <div></div>;
    }
    return (
      <PopUpWrapper
        display={editProductId}
        large
        titleZIndex={1000}
        customWidth={1200}
        noMinHeight={2500}
        message={__('Úprava výpredajového produktu')}
        onClose={() => this.props.hideAndShowEditProduct()}
        onEscClose={() => this.props.hideAndShowEditProduct()}
      >
        <PopUpContentWrapper>
          <CountryValidityWrapper>
            {/* <CountryInputWrapper>
              <StyledLabel>{__('Krajina')}</StyledLabel>
              <Select
                size="s"
                value={product && product.firm}
                onChange={e =>
                  this.setState({
                    product: {
                      ...this.state.product,
                      firm: e.target.value,
                    },
                  })
                }
              >
                <option selected={product && product.firm === 'SK'} value="SK">
                  {'SK'}
                </option>

                <option selected={product && product.firm === 'CZ'} value="CZ">
                  {'CZ'}
                </option>
              </Select>
            </CountryInputWrapper> */}
            {/* <ValidityInputWrapper>
              <DatePickerWrapper>
                <StyledLabel>{__('Platný od')}</StyledLabel>
                <StyledDatePicker
                  placeholderText={__('Od')}
                  dateFormat="dd DD.MM.YYYY"
                  selected={product && moment(product.valid_from)}
                  minDate={moment()}
                  onChange={e =>
                    this.setState({
                      product: {
                        ...this.state.product,
                        valid_from: e,
                      },
                    })
                  }
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <StyledLabel>{__('Platný do')}</StyledLabel>
                <StyledDatePicker
                  placeholderText={__('Do')}
                  dateFormat="dd DD.MM.YYYY"
                  selected={product && moment(product.valid_to)}
                  minDate={product && moment(product.valid_from)}
                  onChange={e =>
                    this.setState({
                      product: {
                        ...this.state.product,
                        valid_to: e,
                      },
                    })
                  }
                />
              </DatePickerWrapper>
            </ValidityInputWrapper> */}
          </CountryValidityWrapper>

          <PublicationWrapper>
            <Label> {__('Publikácia')}</Label>
            <CheckBoxWrapper>
              {publishedChannels.map((item) => {
                return (
                  <div>
                    <Checkbox
                      key={JSON.stringify(product.published)}
                      checked={
                        product.published
                          ? product.published.includes(item)
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (!product.published.includes(item)) {
                            product.published.push(item);
                          }
                        } else {
                          if (product.published.includes(item)) {
                            product.published = product.published.filter(
                              (p) => p !== item,
                            );
                          }
                        }
                        this.setState({
                          product,
                        });
                      }}
                    />
                    <Label> {item}</Label>
                  </div>
                );
              })}
            </CheckBoxWrapper>
          </PublicationWrapper>
          {product ? (
            <ProductsToAddTable>
              <table>
                <tr>
                  <th>{__('Krajina')}</th>
                  <th>{__('Produkt pre výpredaj')}</th>
                  <th>{__('Číslo produktu')}</th>
                  <th>{__('Pôvodna cena')}</th>
                  <th>{__('Percentuálna zľava')}</th>
                  <th>{__('Pevná cena')}</th>
                  {/* <th>{__('Do vypredania zásob')}</th> */}
                </tr>
                <tr>
                  <td>{product && product.firm}</td>
                  <td>{product && product.name}</td>
                  <td>
                    {/* {' '}
                    <Input
                      value={product.product_number || ''}
                      placeholder={__('Číslo produktu')}
                      onChange={e =>
                        this.changeProductNumber(
                          product.product_nav_id,
                          e.target.value,
                        )
                      }
                    /> */}
                    {product && product.product_nav_id}
                  </td>
                  <td className="price-input-wrapper">
                    {' '}
                    <Input
                      type="number"
                      min={0}
                      value={(product && product.default_price) || ''}
                      placeholder={
                        product && product.firm === 'SK' ? '€' : 'Kč'
                      }
                      onChange={(e) => this.changeDefaultPrice(e.target.value)}
                    />
                  </td>
                  <td>
                    <div className="price-input-wrapper">
                      <Input
                        value={product.discount_percent || ''}
                        disabled={
                          product.price != null &&
                          product.price !== '' &&
                          product.price !== 0
                        }
                        min={0}
                        max={100}
                        type="number"
                        placeholder={__('%')}
                        onChange={(e) => this.changePercentage(e.target.value)}
                      />
                    </div>
                  </td>
                  <td className="price-input-wrapper">
                    {' '}
                    <Input
                      type="number"
                      disabled={
                        product &&
                        product.discount_percent != null &&
                        product.discount_percent !== '' &&
                        product.discount_percent !== 0
                      }
                      min={0}
                      value={(product && product.price) || ''}
                      placeholder={
                        product && product.firm === 'SK' ? '€' : 'Kč'
                      }
                      onChange={(e) => this.changePrice(e.target.value)}
                    />
                  </td>
                  {/* <td>
                    <Checkbox
                      onChange={() => this.changeSaleValidity()}
                      checked={product && product.valid_until_sold_out}
                    />
                  </td> */}
                </tr>
              </table>
            </ProductsToAddTable>
          ) : (
            <ProductsToAddTable />
          )}

          <ActionButtons>
            <Button
              loading={null}
              onClick={() => this.cancelEditingSaleProduct()}
              danger
            >
              {__('Zrušiť')}
            </Button>

            <Button
              loading={editingSaleProductLoading}
              onClick={() => {
                this.setState({ product: null });
                this.props.editSaleProduct(product);
              }}
              primary
              disabled={!validProductsData}
            >
              {__('Upraviť')}
            </Button>
          </ActionButtons>
          {!validProductsData && (
            <ErrorMessage>
              {__(
                'Každý produkt musí mať uvedenú percentuálnu zľavu alebo pevnú cenu a obe hodnoty musia byť číslo.',
              )}
            </ErrorMessage>
          )}
        </PopUpContentWrapper>
      </PopUpWrapper>
    );
  }
}
EditSaleProduct.propTypes = {
  editSaleProduct: PropTypes.func.isRequired,
  hideAndShowEditProduct: PropTypes.func.isRequired,
  editProductId: PropTypes.string || null,
  editingSaleProductLoading: PropTypes.bool,
};

EditSaleProduct.defaultProps = {
  editProductId: null,
  editingSaleProductLoading: false,
};

export default EditSaleProduct;
