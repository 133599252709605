import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button, ThemeAdmin } from 'oxyrion-ui/lib';
import TableV3 from '../../Components/TableV3';
import { connect } from '../../Store';
import {
  formatPrice,
  getCurrencyByFirm,
  getDiscountPercents,
  getPriceWithoutVat,
  getPriceWithVat,
  __,
} from '../../Utils';
import {
  ButtonRelativeWrapper,
  DHeader,
  P,
} from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import OwnStateInput from '../../Components/OwnStateInput';
import AdminAPI from '../../AdminAPI';

const PriceWrapper = styled.div`
  display: flex;
`;

const Price = styled.div`
  margin-left: ${rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(12)};
`;

class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: 'SK',
      variants: [],
      saveLoading: false,
    };
  }

  componentWillReceiveProps(props) {
    console.log('props.clearances---', props.clearances);
    console.log('props.variants----', props.variants);

    this.setState({
      variants: this.normalizeData(props.variants, props.clearances),
    });
  }

  normalizeData(data, salesData) {
    return data.map(item => {
      return Object.assign(item, {
        translations: item.translations.map(trans => {
          const { clearances, inventory } =
            salesData.find(s => s._id === item._id) &&
            salesData.find(s => s._id === item._id).translations[trans.lang];

          console.log('clearances-----', clearances);

          return Object.assign(trans, {
            price_wd_vat: {
              amount:
                Number(clearances.default_price) ||
                getPriceWithVat(trans.lang, trans.variantPrice.amount),
              currency: trans.variantPrice.currency,
            },
            price_wd: {
              amount:
                getPriceWithoutVat(
                  trans.lang,
                  Number(clearances.default_price),
                ) || trans.variantPrice.amount,
              currency: trans.variantPrice.currency,
            },
            discount:
              getDiscountPercents(
                trans.variantPrice.amount,
                getPriceWithoutVat(
                  trans.lang,
                  Number(clearances.default_price),
                ),
              ) || 0,
            quantity: inventory.quantity || 0,
            salesId: clearances._id ? clearances._id : null,
          });
        }),
      });
    });
  }

  createColumns() {
    const { activeLanguage } = this.state;

    return [
      {
        accessor: 'No',
        Header: () => <DHeader>{__('Číslo výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pôvodná cena bez DPH')}</DHeader>,
        Cell: props => {
          return (
            <PriceWrapper>
              <OwnStateInput
                type="number"
                style={{ width: rem(100) }}
                placeholder={__('0')}
                onChange={() => {}}
                onBlur={e =>
                  this.handlePriceChange(e, 'price_wd', props.original._id)
                }
                value={
                  props.value.find(t => t.lang === activeLanguage) &&
                  props.value.find(t => t.lang === activeLanguage).price_wd
                    ? props.value.find(t => t.lang === activeLanguage).price_wd
                        .amount
                    : 0
                }
              />

              <Price>{getCurrencyByFirm(activeLanguage)}</Price>
            </PriceWrapper>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pôvodná cena s DPH')}</DHeader>,
        Cell: props => {
          return (
            <PriceWrapper>
              <OwnStateInput
                type="number"
                style={{ width: rem(100) }}
                placeholder={__('0')}
                onChange={() => {}}
                onBlur={e =>
                  this.handlePriceChange(e, 'price_wd_vat', props.original._id)
                }
                value={
                  props.value.find(t => t.lang === activeLanguage) &&
                  props.value.find(t => t.lang === activeLanguage).price_wd_vat
                    ? props.value.find(t => t.lang === activeLanguage)
                        .price_wd_vat.amount
                    : 0
                }
              />

              <Price>{getCurrencyByFirm(activeLanguage)}</Price>
            </PriceWrapper>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pevná cena bez DPH')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {formatPrice(
                props.value.find(t => t.lang === activeLanguage)
                  ? props.value.find(t => t.lang === activeLanguage)
                      .variantPrice
                  : 0,
              )}
            </P>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pevná cena s DPH')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {props.value.find(t => t.lang === activeLanguage)
                ? formatPrice({
                    amount: getPriceWithVat(
                      activeLanguage,
                      props.value.find(t => t.lang === activeLanguage)
                        .variantPrice.amount,
                    ),
                    currency: props.value.find(t => t.lang === activeLanguage)
                      .variantPrice.currency,
                  })
                : 0}
            </P>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Percentuálna zľava')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {`${
                props.value.find(t => t.lang === activeLanguage)
                  ? props.value.find(t => t.lang === activeLanguage).discount
                  : 0
              } %`}
            </P>
          );
        },
        maxWidth: 150,
      },
    ];
  }

  changeLanguageForVariants(id) {
    this.setState({
      activeLanguage: id,
    });

    return true;
  }

  async saveSaleProducts() {
    const { variants, activeLanguage } = this.state;

    this.setState({
      saveLoading: true,
    });

    const postSalesProducts = variants.reduce((acumulator, variant) => {
      return acumulator.concat(
        variant.translations
          .filter(f => parseFloat(f.discount) > 0 && !f.salesId)
          .map(trans => ({
            product_nav_id: variant._id,
            name: variant.name,
            type: 'clearance',
            discount_percent: null,
            default_price: parseFloat(trans.price_wd_vat.amount),
            price: getPriceWithVat(activeLanguage, trans.variantPrice.amount),
            firm: trans.lang,
          })),
      );
    }, []);

    const params = {};

    const putSalesProducts = variants.reduce((acumulator, variant) => {
      return acumulator.concat(
        variant.translations
          .filter(f => parseFloat(f.discount) > 0 && f.salesId)
          .map(trans => ({
            _id: trans.salesId,
            product_nav_id: variant._id,
            name: variant.name,
            type: 'clearance',
            discount_percent: null,
            default_price: parseFloat(trans.price_wd_vat.amount),
            price: getPriceWithVat(activeLanguage, trans.variantPrice.amount),
            firm: trans.lang,
          })),
      );
    }, []);

    try {
      if (postSalesProducts && postSalesProducts.length > 0) {
        params.body = {
          sale_products: postSalesProducts,
        };

        params.type = 'clearance';
        await AdminAPI.postSaleProductsAction(params);
      }

      if (putSalesProducts && putSalesProducts.length > 0) {
        params.body = {
          sale_products: putSalesProducts,
        };
        await AdminAPI.putSalesProductAction(params);
      }

      // this.props.onSuccesSave();
      this.setState({
        saveLoading: false,
      });
    } catch (e) {
      // this.props.onErrorSave();
    }
  }

  handlePriceChange(e, label, rowId) {
    const { variants, activeLanguage } = this.state;

    if (label === 'price_wd' || label === 'price_wd_vat') {
      const _variants = [...variants];

      const varinatsIdx = _variants.findIndex(i => i._id === rowId);
      const transIdx = _variants[varinatsIdx].translations.findIndex(
        t => t.lang === activeLanguage,
      );

      if (label === 'price_wd') {
        if (!_variants[varinatsIdx].translations[transIdx][label]) {
          _variants[varinatsIdx].translations[transIdx][label] = {};
        }
        _variants[varinatsIdx].translations[transIdx][label].amount = Number(
          e.target.value,
        );

        if (Number(e.target.value) > 0) {
          _variants[varinatsIdx].translations[transIdx].price_wd_vat = {
            amount: getPriceWithVat(activeLanguage, Number(e.target.value)),
            currency: getCurrencyByFirm(transIdx),
          };

          _variants[varinatsIdx].translations[
            transIdx
          ].discount = getDiscountPercents(
            _variants[varinatsIdx].translations[transIdx].variantPrice.amount,
            Number(e.target.value),
          );
        }
      } else if (label === 'price_wd_vat') {
        if (!_variants[varinatsIdx].translations[transIdx][label]) {
          _variants[varinatsIdx].translations[transIdx][label] = {};
        }

        _variants[varinatsIdx].translations[transIdx][label].amount = Number(
          e.target.value,
        );

        if (Number(e.target.value) > 0) {
          _variants[varinatsIdx].translations[transIdx].price_wd = {
            amount: getPriceWithoutVat(activeLanguage, Number(e.target.value)),
            currency: getCurrencyByFirm(transIdx),
          };

          _variants[varinatsIdx].translations[
            transIdx
          ].discount = getDiscountPercents(
            _variants[varinatsIdx].translations[transIdx].variantPrice.amount,
            getPriceWithoutVat(activeLanguage, Number(e.target.value)),
          );
        }
      }

      this.setState({
        variants: _variants,
      });
    }
  }

  tableBackgroundColor(index, selected) {
    if (index === selected) {
      return ThemeAdmin.color.primary;
    }
    if (index % 2 === 0) {
      return 'white';
    }
    return '#eee';
  }

  render() {
    const { loading } = this.props;

    const { variants, activeLanguage, saveLoading } = this.state;

    return (
      <React.Fragment>
        <LanguageSwitch
          onChange={id => {
            return this.changeLanguageForVariants(id);
          }}
          activeId={activeLanguage}
        />
        {variants && variants.length > 0 && (
          <TableV3
            columns={this.createColumns()}
            minWidth={10}
            loading={loading}
            noDataText={__('Žiadne varianty')}
            data={variants}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: () => {},
                };
              }
              return {};
            }}
            className="-highlight -striped"
          />
        )}
        <ButtonRelativeWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveSaleProducts()}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

Sales.propTypes = {
  // changeSelectedVariant: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  clearances: PropTypes.object,
  onSuccesSave: PropTypes.func.isRequired,
  onErrorSave: PropTypes.func.isRequired,
  variants: PropTypes.arrayOf({
    name: PropTypes.string,
    No: PropTypes.string,
    published: PropTypes.shape({
      CRM: PropTypes.bool,
      B2B: PropTypes.bool,
      xFarby: PropTypes.bool,
      xBarvy: PropTypes.bool,
      xFarben: PropTypes.bool,
      xFestek: PropTypes.bool,
    }),
  }),
};

Sales.defaultProps = {
  loading: false,
  sales: {},
  variants: [],
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Sales);
