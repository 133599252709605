import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Typ'),
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Status'),
    clickable: false,
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Čas začiatku'),
    clickable: true,
    sorterValue: 'start_date_time',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Trvanie'),
    otherProps: { minWidth: 250 },
  },
];
