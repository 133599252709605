import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name'
  },
  {
    name: __('Dátum zobrazenia'),
    clickable: true,
    sorterValue: 'send_date'
  },
  {
    name: __('Krajina'),
    clickable: false,
  },
  {
    name: __('Počet používateľov'),
    clickable: false,
  },
  {
    name: __('Počet zobrazení'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date'
  },
  {
    name: __('Vytvoril'),
    clickable: false,
  },
];
