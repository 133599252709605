import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'oxyrion-ui/lib/Icon';
import Select from 'oxyrion-ui/lib/Select';
import styled from 'styled-components';
import { rem } from 'polished';
import { __, translateCodelist } from '../../Utils';

const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectGroupItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(4)};
`;

const DeleteWrapper = styled.div`
  width: ${rem(24)};
  height: ${rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: ${rem(3)};
  cursor: pointer;
  margin-left: ${rem(5)};
`;

const AddMore = styled.div`
  height: 30px;
  display: flex;
  align-items: center;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

class SelectFormGroup extends Component {
  handleClick(id, values, index) {
    const { value, onChange } = this.props;

    const item = values.find(v => v._id === id);

    const valueToAdd = {
      _id: item._id,
      label: item.label,
    };

    value[index] = valueToAdd;
    onChange(value);
  }

  addValue() {
    const { value, onChange } = this.props;

    const e = {};

    if (Array.isArray(value)) {
      value.push(e);

      onChange(value);
    } else {
      const newArray = [value];
      newArray.push(e);
      onChange(newArray);
    }
  }

  deleteValue(index) {
    const { value, onChange } = this.props;

    if (Array.isArray(value)) {
      value.splice(index, 1);
      onChange(value);
    } else {
      onChange([]);
    }
  }

  render() {
    const { value, values } = this.props;

    return (
      <SelectsWrapper>
        {value &&
          (Array.isArray(value) ? value : [value]).map((f, index) => {
            return (
              <SelectGroupItemWrapper>
                <Select
                  style={{ minWidth: '200px' }}
                  size="s"
                  value={f._id}
                  onChange={e => {
                    this.handleClick(e.target.value, values, index);
                  }}
                >
                  {values && (
                    <option
                      selected={!f._id || !f._id.length}
                      value={undefined}
                    >
                      -
                    </option>
                  )}
                  {values ? (
                    values.map(item => {
                      return (
                        <option selected={f._id === item._id} value={item._id}>
                          {translateCodelist(
                            this.props.firm,
                            item.translations,
                            item.label,
                          )}
                        </option>
                      );
                    })
                  ) : (
                    <option>{__('Neobsahuje možnosti na výber')}</option>
                  )}
                </Select>
                <DeleteWrapper onClick={() => this.deleteValue(index)}>
                  <Icon name="delete" size="m" color="#fff" />
                </DeleteWrapper>
              </SelectGroupItemWrapper>
            );
          })}
        <AddMore onClick={() => this.addValue()}>{__('+ Pridať')}</AddMore>
      </SelectsWrapper>
    );
  }
}

SelectFormGroup.propTypes = {
  value: PropTypes.arrayOf({}),
  onChange: PropTypes.func,
};

SelectFormGroup.defaultProps = {
  value: [],
  onChange: () => {},
};

export default SelectFormGroup;
