import React from 'react';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import LanguageSwitch from '../../Components/LanguageSwitch';
import AdminAPI from '../../AdminAPI';
import ProductDescriptionEditor from '../../Components/Editor/productDescriptionEditor';
import { LANGS } from '../../ContentConfig/languagesArray';
import { Button, Message } from 'oxyrion-ui/lib';
import styled from 'styled-components';
import { rem } from 'polished';

const initData = () => {
  return {
    html_data: '',
    json_data: null,
  };
};

class ProductDescription extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;

    this.state = {
      activeLanguage: null,
      newData: null,
      enableCopy: false,
      hasUnsaved: false,
      error: undefined,
      success: undefined,
      currentCopy: null,
    };
  }

  async componentDidMount() {
    const o = {};
    LANGS.forEach((lang) => {
      if (lang) {
        o[lang] = initData();
      }
    });

    this.setState({
      translations: this.props.data ? this.props.data.translations : o,
      currentTranslationData: initData(),
    });
  }

  handleLangChange(lang, first) {
    const { translations, activeLanguage, currentTranslationData } = this.state;

    if (activeLanguage === lang) {
      return;
    }

    const newTranslations = translations;

    newTranslations[activeLanguage] = currentTranslationData;

    this.setState({
      translations: newTranslations,
      activeLanguage: lang,
      currentTranslationData: translations[lang],
      currentCopy: undefined,
      newData: newTranslations[lang].json_data,
      enableCopy: false,
      success: null,
      hasUnsaved: first,
    });

    return true;
  }

  copyLangTemplateData() {
    const { translations, currentTranslationData, currentCopy } = this.state;

    const lang = currentCopy;
    const newTranslations = translations;
    newTranslations[currentCopy] = Object.assign({}, currentTranslationData);

    this.setState(
      {
        translations: newTranslations,
        currentCopy: null,
      },
      () => {
        this.handleLangChange(lang, true);
      },
    );
  }

  async saveCurrentJson(json, html) {
    const { translations, activeLanguage } = this.state;

    const newTranslations = translations;

    if (json && html) {
      newTranslations[activeLanguage].json_data = json;
      newTranslations[activeLanguage].html_data = html;
    }

    this.setState(
      {
        translations: newTranslations,
      },
      () => {
        this.saveData();
      },
    );
  }

  async saveData() {
    const { translations } = this.state;

    const params = {};

    params.body = {
      translations,
    };

    try {
      const res = await AdminAPI.putProductExtendedDescription(
        this.props.productId,
        params,
      );

      this.setState({
        translations: res.data,
        success: __('Popis produktu bol úspešne uložený'),
        error: undefined,
        enableCopy: true,
        hasUnsaved: false,
      });
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    }
  }

  render() {
    const {
      activeLanguage,
      newData,
      error,
      success,
      hasUnsaved,
      enableCopy,
      currentCopy,
    } = this.state;

    return (
      <React.Fragment>
        <LanguageSwitch
          onChange={(id) =>
            hasUnsaved
              ? window.confirm(
                  'Máte neuložené zmeny, prajete si ich zahodiť?',
                ) && window.location.reload()
              : this.handleLangChange(id, false)
          }
          activeId={activeLanguage}
        />

        {error && (
          <MessageWrapper>
            <Message message={error} error />
          </MessageWrapper>
        )}

        {success && (
          <MessageWrapper>
            <Message message={success} />
          </MessageWrapper>
        )}

        {hasUnsaved && (
          <MessageWrapper>
            <div className="unsaved">{__('Neuložené zmeny')}</div>
          </MessageWrapper>
        )}

        {enableCopy && (
          <CopyWrapper>
            <select
              onChange={(e) => {
                this.setState({ currentCopy: e.target.value });
              }}
            >
              {[
                <option selected value>
                  -
                </option>,
              ].concat(
                LANGS.map((l) => {
                  if (activeLanguage !== l) {
                    return <option value={l}>{l}</option>;
                  }
                  return <div />;
                }),
              )}
            </select>
            <Button
              primary
              small
              disabled={!LANGS.includes(currentCopy)}
              onClick={() => enableCopy && this.copyLangTemplateData()}
            >
              {`${__('Kopírovať')}`}
            </Button>
          </CopyWrapper>
        )}

        <ProductDescriptionEditor
          jsonData={newData}
          setEditor={(e) => {
            this.editor = e;
          }}
          lang={activeLanguage}
          onSave={(json, html) => this.saveCurrentJson(json, html)}
          onDesignLoad={() => {
            if (activeLanguage === null) {
              this.handleLangChange('SK', false);
            }
          }}
          hasUnsaved={(updates) =>
            this.setState({
              hasUnsaved: true,
              enableCopy: false,
              success: null,
            })
          }
        />
      </React.Fragment>
    );
  }
}

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(24)};

  .unsaved {
    font-size: 14px;
    color: gray;
  }
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
  margin-left: ${rem(8)};

  select {
    margin-right: 8px;
  }
`;

ProductDescription.propTypes = {};

ProductDescription.defaultProps = {
  firm: 'SK',
  productId: null,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductDescription);
