import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import { Icon } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import { __, formatPrice } from '../../../../Utils';

const Wrapper = styled.div``;

const ProductName = styled.div`
  font-size: ${rem(12)};
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(10)};
  color: #ee6500;
`;

const IconWrapper = styled.div`
  margin-right: ${rem(5)};
`;

const BenefitWrapper = styled.div``;

const PercentWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
`;
class ProductNameLabel extends React.Component {
  render() {
    const { productName, benefitData } = this.props;
    return (
      <Wrapper>
        <ProductName>{productName}</ProductName>
        {benefitData && (
          <BenefitWrapper>
            <ProductWrapper>
              {benefitData.product && !benefitData.purchase_value && (
                <React.Fragment>
                  <IconWrapper>
                    <Icon
                      name="gift"
                      size="m"
                      color={baseTheme.color.primary}
                    />
                  </IconWrapper>
                  {`${benefitData.product.product_name} ${
                    benefitData.discount > 0
                      ? `${__('so')} ${benefitData.discount}% ${'zľavou'}`
                      : benefitData.discount === 'free'
                      ? `${__('zadarmo')}`
                      : `${__('za')} ${formatPrice(benefitData.discount)}`
                  }`}
                </React.Fragment>
              )}
              {!benefitData.product && !benefitData.purchase_value && (
                <React.Fragment>
                  <PercentWrapper>%</PercentWrapper>
                  {`${__('Zľava')} - ${benefitData.discount} %`}
                </React.Fragment>
              )}

              {!benefitData.product && benefitData.purchase_value && (
                <React.Fragment>
                  <PercentWrapper>%</PercentWrapper>
                  {`${__('Zľava')} - ${benefitData.discount} % ${__(
                    'pri nákupe skupiny produktov nad',
                  )} ${formatPrice(benefitData.purchase_value)}`}
                </React.Fragment>
              )}

              {benefitData.product && benefitData.purchase_value && (
                <React.Fragment>
                  <IconWrapper>
                    <Icon
                      name="gift"
                      size="m"
                      color={baseTheme.color.primary}
                    />
                  </IconWrapper>
                  {`${benefitData.product.product_name} ${__(
                    'zdarma pri nákupe skupiny produktov nad',
                  )} ${formatPrice(benefitData.purchase_value)}`}
                </React.Fragment>
              )}
            </ProductWrapper>
          </BenefitWrapper>
        )}
      </Wrapper>
    );
  }
}

ProductNameLabel.propTypes = {
  productName: PropTypes.string,
  benefitData: PropTypes.shape({
    parent_product_id: PropTypes.string,
    parent_brand_id: PropTypes.string,
    product: PropTypes.shape({
      _id: PropTypes.string,
      product_name: PropTypes.string,
    }),
    discount: PropTypes.shape({}) || PropTypes.number,
    purchase_value: PropTypes.shape({}),
  }),
};

ProductNameLabel.defaultProps = {
  productName: '',
  benefitData: null,
};

export default ProductNameLabel;
