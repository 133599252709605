import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import { rem } from 'polished';
import { Label } from 'oxyrion-ui/lib';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/marketingListsContacts';
import { __, renderLink, renderLinkOnNewTab } from '../../Utils';
import { connect } from '../../Store';
import API2 from '../../API2';

const MainLabel = styled(Label)`
  margin-right: ${rem(10)};
  font-size: ${rem(16)};
`;

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: ${rem(10)};
  justify-content: space-between;
`;

const OwnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

class CustomersContactsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: null });
    this.headers = tableHeaders;
    this.segmentName = __('Kontakty marketingového zoznamu');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'surname',
      'email',
      'phone',
      'company',
      'city',
      'owners',
      'active',
    ];
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
      showRefreshDialog: false,
      shouldUpdate: false,
      rawContent: [],
      updatedItems: {
        deleted_items: [],
        added_items: [],
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.customersSubtypes.length !==
        nextProps.customersSubtypes.length ||
      this.props.customersIds.length !== nextProps.customersIds.length
    ) {
      this.setState({
        loading: true,
      });

      this.fetchData({
        customersSubtypes: nextProps.customersSubtypes,
        customersIds: nextProps.customersIds,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.customersSubtypes.length !== nextProps.customersSubtypes ||
      this.props.customersIds.length !== nextProps.customersIds
    ) {
      return true;
    }

    return false;
  }

  handleContactsOnClick(id) {
    const { history, firm } = this.props;
    history.push(`../${firm}/contacts/${id}`);
  }

  handleCompanyOnClick(id) {
    const { history, firm } = this.props;
    history.push(`./${firm}/customer/${id}`);
  }

  handleUserClick(id) {
    const { history, firm } = this.props;
    history.push(`./${firm}/users/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active } = this.state;
      const { customersIds, customersSubtypes, firm } = this.props;

      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      params.limit = this.props.limit;
      params.sorter = 'name';

      if (!params.customersIds) {
        params.customersIds = customersIds;
      }

      if (!params.customersSubtypes) {
        params.customersSubtypes = customersSubtypes;
      }

      if (
        params.customersIds.length === 0 &&
        params.customersSubtypes.length === 0
      ) {
        this.setState({
          loading: false,
        });

        return;
      }

      const newData = await API2.getCustomersContactsAction(firm, params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        rawContent: newData.items,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(item.name),
        surname: _shapeData(
          renderLinkOnNewTab(
            `${process.env.REACT_APP_CRM_DOMAIN}/${this.props.firm}/contacts/${
              item._id
            }`,
            `${item.surname || '-'}`,
          ),
          () => this.handleContactsOnClick(item._id),
        ),
        email: _shapeData(item.email),
        phone: _shapeData(item.phone),
        city: _shapeData(item.address && item.address.city),
        company: _shapeData(
          item.company &&
            renderLinkOnNewTab(
              `${process.env.REACT_APP_CRM_DOMAIN}/${
                this.props.firm
              }/customers/${item.customer_id}`,
              `${item.customer_name || '-'}`,
            ),
          () => this.handleCompanyOnClick(item.item.customer_id),
        ),
        owners: _shapeData(
          <OwnersWrapper>
            {item.ownership &&
              item.ownership.map(o => {
                return (
                  <div>
                    {renderLink(
                      `../users/${o._id}`,
                      `${o.display_name || '-'}`,
                    )}
                  </div>
                );
              })}
          </OwnersWrapper>,
        ),
        active: _shapeData(
          <Checkbox
            checked={item.active}
            disabled={this.props.readonly}
            onChange={() => {
              this.updateActiveState(item._id);
            }}
          />,
        ),
        _id: item._id,
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    return <div />;
  }

  render() {
    return (
      <Wrapper>
        <Row>
          <MainLabel>{this.props.title}</MainLabel>
        </Row>
        {super.render()}
      </Wrapper>
    );
  }
}

CustomersContactsList.propTypes = {
  saveConditions: PropTypes.func.isRequired,
  refreshTable: PropTypes.bool,
  customersIds: PropTypes.arrayOf(PropTypes.string),
  customersSubtypes: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  limit: PropTypes.number,
};

CustomersContactsList.defaultProps = {
  refreshTable: false,
  showUpdateButton: true,
  customersIds: [],
  customersSubtypes: [],
  title: __('Zoznam adries'),
  limit: 30,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CustomersContactsList);
