import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Dropzone from 'react-dropzone-uploader';
import { Button, Loader, Input, Label, Message, Select } from 'oxyrion-ui/lib';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import AdminAPI from '../../AdminAPI';
import {
  __,
  getIdFromProps,
  formatBytes,
  getPageIdFromProps,
} from '../../Utils';
import { connect } from '../../Store';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import DatePicker from '../../Components/DatePicker';
import GreyBox from '../../Components/GreyBox';
import SettingsBox from '../../Components/SettingsBox';
import LanguageSwitch from '../../Components/LanguageSwitch';
import { LANGS } from '../../ContentConfig/languagesArray';

export const PUBLISH_APPS = ['xFarby', 'Portal', 'Dejmark'];

const acceptedMimeTypesViewImage = [
  'image/webp',
  // 'image/jpg',
  // 'image/jpeg',
  // 'image/svg+xml',
];
const baseURL = `https://api.oxyrion.com/v1/images`;
// const baseURL = `http://localhost:9999/v1/images`;

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/banners/{pageId}/{bannerId}/image`;
const mobileFileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/banners/{pageId}/{bannerId}/image?mobile=true`;

const initData = () => {
  return {
    name: '',
    description: '',
    url: '',
    priority: 0,
  };
};
class EshopBannersDetail extends React.Component {
  constructor(props) {
    super(props);
    const translations = {};
    LANGS.forEach((lang) => {
      translations[lang] = initData();
    });

    this.state = {
      loading: true,
      banner: {
        name: '',
        published_apps: [],
        country: [],
        link: '',
        type: 'carousel',
        published_from: new Date(),
        published_to: null,
        image: null,
        mobileImage: null,
        translations,
      },
      activeLang: 'SK',
      showConfirmDialog: false,
      saveLoading: false,
    };

    this.pageId = getPageIdFromProps(this.props);
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);
    if (id === 'new') {
      this.setState({
        loading: false,
      });
    } else {
      await this.fetchBanner();
    }
  }

  async onImageUpload({ file }) {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);
    return {
      url: fileUploadURL
        .replace('{pageId}', this.pageId)
        .replace('{bannerId}', this.state.banner._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  }

  async onMobileImageUpload({ file }) {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);

    return {
      url: mobileFileUploadURL
        .replace('{pageId}', this.pageId)
        .replace('{bannerId}', this.state.banner._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  }

  changePublishedApps(app) {
    const { banner } = this.state;
    const index = banner.published_apps.indexOf(app);

    if (index !== -1) {
      banner.published_apps.splice(index, 1);
    } else {
      banner.published_apps.push(app);
    }

    this.setState({ banner });
  }

  changeCountry(country) {
    const { banner } = this.state;
    const index = banner.country.indexOf(country);

    if (index !== -1) {
      banner.country.splice(index, 1);
    } else {
      banner.country.push(country);
    }

    this.setState({ banner });
  }

  handleChangeStatus = async ({ remove }, status) => {
    const { banner } = this.state;
    if (status === 'done') {
      const { image } = await AdminAPI.getBannerImageAction(
        this.pageId,
        banner._id,
      );
      banner.image = image;
      remove();
      this.setState(banner);
    }
  };

  handleChangeMobileStatus = async ({ remove }, status) => {
    const { banner } = this.state;
    if (status === 'done') {
      const { mobileImage } = await AdminAPI.getBannerImageAction(
        this.pageId,
        banner._id,
        {
          mobile: true,
        },
      );
      banner.mobileImage = mobileImage;
      remove();
      this.setState(banner);
    }
  };

  handleLocalChange(field, lang, value) {
    const { banner } = this.state;

    const newTranslations = {
      ...banner.translations,
      [lang]: {
        ...banner.translations[lang],
        [field]: field === 'priority' ? Number(value) : value,
      },
    };

    const newBanner = {
      ...banner,
      translations: newTranslations,
    };

    this.setState({
      banner: newBanner,
    });
  }

  async saveBanner(force = false) {
    const { banner } = this.state;
    this.setState({ saveLoading: true });
    if (!this.validateTextInputs()) {
      this.setState({
        error: __('Musíte vyplniť všetky polia'),
        saveLoading: false,
      });
      return;
    }

    const params = {};
    params.body = {
      banner,
    };
    params.force = force;

    let createdbanner;

    try {
      if (banner._id) {
        createdbanner = await AdminAPI.putBannerAction(
          this.pageId,
          banner._id,
          params,
        );
      } else {
        createdbanner = await AdminAPI.postBannerAction(
          this.pageId,
          params,
          force,
        );
      }

      const successMessage = banner._id
        ? __('Banner úspešne upravený')
        : __('Banner úspešne vytvorený');
      this.setState({
        banner: createdbanner,
        saveLoading: false,
        error: false,
        success: successMessage,
      });
      this.redirectToBanner(createdbanner._id);
    } catch (e) {
      if (e.response && e.response.status && e.response.status === 409) {
        if (
          window.confirm(
            'Za zvolené obdobie existuje publikovaný banner, po potvrdení sa uloží a zobrazí baner v zvolenom období ktorý je najnovší',
          )
        ) {
          this.saveBanner(true);
          return;
        }
      }

      this.setState({
        error: __('Banner sa nepodarilo upraviť alebo vytvoriť'),
        success: false,
        saveLoading: false,
      });
    }
  }
  validateTextInputs() {
    const { banner } = this.state;
    let filled = true;
    const requiredFields = ['name', 'type', 'published_from'];
    Object.keys(banner)
      .filter((k) => requiredFields.includes(k))
      .map((key) => {
        if (!banner[key] || banner[key] === '') {
          filled = false;
        }
        return true;
      });
    return filled;
  }

  redirectToBanner(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/banners/${this.pageId}/${id}`);
  }
  async deleteBanner() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteBannerAction(
        this.pageId,
        getIdFromProps(this.props),
      );
      history.push(`/${firm}/banners/${this.pageId}`);
    } catch (e) {
      this.setState({
        error: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  async fetchBanner() {
    const { banner } = this.state;

    this.setState({
      loading: true,
    });

    const id = getIdFromProps(this.props);
    const response = await AdminAPI.getBannerAction(this.pageId, id);

    const newBanner = Object.assign(banner, response);

    this.setState({
      banner: newBanner,
      loading: false,
    });
  }

  changeAttribute(attribute, value) {
    const newbanner = Object.assign({}, this.state.banner);

    switch (attribute) {
      case 'name':
        newbanner.name = value;
        break;
      case 'type':
        newbanner.type = value;
        break;
      case 'published_from':
        newbanner.published_from = value;
        break;
      case 'published_to':
        newbanner.published_to = value;
        break;
      default:
        break;
    }
    this.setState({
      banner: newbanner,
    });
  }
  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={`${__('Banner')} ${this.pageId}`}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }
  render() {
    const {
      loading,
      banner,
      deleteLoading,
      saveLoading,
      showConfirmDialog,
      deleteError,
      error,
      success,
      activeLang,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <PopUpWrapper
            display={showConfirmDialog}
            small
            onClose={() => this.setState({ showConfirmDialog: false })}
          >
            <ConfirmDialog
              message={__('Naozaj si prajete odstrániť banner?')}
              onDismiss={() => this.setState({ showConfirmDialog: false })}
              onConfirm={() => this.deleteBanner()}
              error={deleteError}
              loading={deleteLoading}
            />
          </PopUpWrapper>
          <ContentWrapper>
            <ColumnWrapper>
              <SettingsBox addDisabled title={__('Základné informácie')}>
                <InputWrapper>
                  <StyledLabel>{__('Názov banneru*')}</StyledLabel>
                  <StyledInput
                    value={banner.name && banner.name}
                    placeholder={__('Zadajte názov banneru')}
                    onChange={(e) =>
                      this.changeAttribute('name', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Typ banneru*')}</StyledLabel>
                  <StyledSelect
                    value={banner.type && banner.type}
                    placeholder={__('Typ banneru')}
                    onChange={(e) =>
                      this.changeAttribute('type', e.target.value)
                    }
                  >
                    <option value="carousel">Carousel</option>
                    <option value="1to1">1:1</option>
                  </StyledSelect>
                </InputWrapper>
                {banner.link && (
                  <InputWrapper>
                    <StyledLabel>{__('Link')}</StyledLabel>
                    <StyledInput value={banner.link && banner.link} disabled />
                  </InputWrapper>
                )}
                <PublishedCountriesWrapper>
                  <InputWrapper>
                    <StyledLabel>{__('Publikácie pre aplikáciu')}</StyledLabel>

                    <Row>
                      {PUBLISH_APPS.map((l) => {
                        return (
                          <div style={{ marginRight: rem(16) }}>
                            <LabeledCheckbox
                              checked={
                                banner.published_apps &&
                                banner.published_apps.indexOf(l) !== -1
                              }
                              labelText={l}
                              onChange={() => this.changePublishedApps(l)}
                            />
                          </div>
                        );
                      })}
                    </Row>
                  </InputWrapper>
                </PublishedCountriesWrapper>
                <PublishedCountriesWrapper>
                  <InputWrapper>
                    <StyledLabel>{__('Publikácie')}</StyledLabel>

                    <Row>
                      {LANGS.map((l) => {
                        return (
                          <div style={{ marginRight: rem(16) }}>
                            <LabeledCheckbox
                              checked={
                                banner.country &&
                                banner.country.indexOf(l) !== -1
                              }
                              labelText={l}
                              onChange={() => this.changeCountry(l)}
                            />
                          </div>
                        );
                      })}
                    </Row>
                  </InputWrapper>
                </PublishedCountriesWrapper>
                <PublishedDatesWrapper>
                  <DatePickerWrapper>
                    <StyledLabel>{__('Publikovaný od*')}</StyledLabel>
                    <StyledDatePicker
                      placeholderText={__('Od')}
                      dateFormat="dd DD.MM.YYYY"
                      selected={moment(banner.published_from)}
                      minDate={moment()}
                      onChange={(e) =>
                        this.changeAttribute('published_from', e)
                      }
                    />
                  </DatePickerWrapper>
                  <DatePickerWrapper>
                    <StyledLabel>{__('Publikovaný do')}</StyledLabel>
                    <StyledDatePicker
                      placeholderText={__('Do')}
                      dateFormat="dd DD.MM.YYYY"
                      selected={
                        banner.published_to && moment(banner.published_to)
                      }
                      minDate={moment(banner.published_from)}
                      onChange={(e) => this.changeAttribute('published_to', e)}
                    />
                  </DatePickerWrapper>
                </PublishedDatesWrapper>
              </SettingsBox>

              <div>
                <Row>
                  <LanguageSwitch
                    onChange={(lang) => {
                      this.setState({
                        activeLang: lang,
                      });
                      return true;
                    }}
                    activeId={activeLang}
                  />
                </Row>

                <Row>
                  <SettingsBox addDisabled>
                    <InputWrapper style={{ width: rem(350) }}>
                      <StyledLabel>{__('Názov v lokálnom jazyku')}</StyledLabel>
                      <StyledInput
                        style={{
                          width: rem(330),
                        }}
                        value={banner.translations[activeLang].name}
                        placeholder={__('Názov')}
                        onChange={(e) =>
                          this.handleLocalChange(
                            'name',
                            activeLang,
                            e.target.value,
                          )
                        }
                      />
                    </InputWrapper>

                    <InputWrapper style={{ width: rem(350) }}>
                      <StyledLabel>{__('Popis')}</StyledLabel>
                      <StyledInput
                        style={{
                          width: rem(330),
                        }}
                        value={banner.translations[activeLang].description}
                        placeholder={__('Popis')}
                        onChange={(e) =>
                          this.handleLocalChange(
                            'description',
                            activeLang,
                            e.target.value,
                          )
                        }
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <StyledLabel>{__('Cesta')}</StyledLabel>
                      <StyledInput
                        style={{ width: rem(330) }}
                        value={banner.translations[activeLang].url}
                        placeholder={__('URL')}
                        onChange={(e) =>
                          this.handleLocalChange(
                            'url',
                            activeLang,
                            e.target.value,
                          )
                        }
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <StyledLabel>{__('Priorita')}</StyledLabel>
                      <StyledInput
                        style={{ width: rem(330) }}
                        value={banner.translations[activeLang].priority || 0}
                        placeholder={0}
                        onChange={(e) =>
                          this.handleLocalChange(
                            'priority',
                            activeLang,
                            Number(e.target.value),
                          )
                        }
                        type="number"
                        min={0}
                      />
                    </InputWrapper>
                  </SettingsBox>
                  <Space />
                </Row>
              </div>

              <Space />
              {getIdFromProps(this.props) !== 'new' && (
                <GreyBox
                  title={
                    banner.type && banner.type === '1to1'
                      ? __('Banner 512x512')
                      : __('Banner 1248x446')
                  }
                >
                  <Dropzone
                    files={banner.image ? [banner.image] : []}
                    getUploadParams={(e) => this.onImageUpload(e)}
                    onChangeStatus={this.handleChangeStatus}
                    accept={acceptedMimeTypesViewImage.join(',')}
                    multiple={false}
                    inputContent={
                      banner && banner.image
                        ? __('Nahrať iný obrázok')
                        : __('Nahrať obrázok')
                    }
                    canCancel={false}
                    initialFiles={banner.image ? [banner.image] : []}
                    styles={{
                      inputLabel: { color: '#ee6500' },
                    }}
                  />
                  <div>
                    <StyledLabel>{__('Náhľad')}</StyledLabel>
                    <ImagesWrapper>
                      <div className="image-wrapper">
                        {banner &&
                        banner.image &&
                        banner.type === 'carousel' ? (
                          <img
                            className="banner-image"
                            src={`${baseURL}/1248x412/${banner.image.fullPath}`}
                            alt="banner"
                            height="412"
                            width="1248"
                          />
                        ) : (
                          <div className="banner-image" />
                        )}
                      </div>
                      {banner && banner.image && banner.type === '1to1' ? (
                        <img
                          className="banner-1to1-image"
                          src={`${baseURL}/512x512/${banner.image.fullPath}`}
                          alt="banner"
                          height="512"
                          width="512"
                        />
                      ) : (
                        <div className="banner-1to1-image" />
                      )}
                    </ImagesWrapper>
                    {banner.image && (
                      <div>
                        <Label>{__('Typ súboru')}</Label>
                        <ImageInfo>{banner.image.mimeType}</ImageInfo>
                        <Space />

                        <Label>{__('Veľkosť súboru')}</Label>
                        <ImageInfo>{formatBytes(banner.image.size)}</ImageInfo>
                      </div>
                    )}
                  </div>
                </GreyBox>
              )}
              <Space />

              {getIdFromProps(this.props) !== 'new' &&
                banner.type &&
                banner.type !== '1to1' && (
                  <GreyBox title={__('Banner pre mobilnú verziu - 460x260')}>
                    <Dropzone
                      files={banner.mobileImage ? [banner.mobileImage] : []}
                      getUploadParams={(e) => this.onMobileImageUpload(e)}
                      onChangeStatus={this.handleChangeMobileStatus}
                      accept={acceptedMimeTypesViewImage.join(',')}
                      multiple={false}
                      inputContent={
                        banner && banner.mobileImage
                          ? __('Nahrať iný obrázok')
                          : __('Nahrať obrázok')
                      }
                      canCancel={false}
                      initialFiles={
                        banner.mobileImage ? [banner.mobileImage] : []
                      }
                      styles={{
                        inputLabel: { color: '#ee6500' },
                        width: 300,
                      }}
                    />

                    {banner && banner.mobileImage && (
                      <div>
                        <StyledLabel>{__('Náhľad banneru')}</StyledLabel>
                        <ImagesMobileWrapper>
                          <div className="mobile-image-wrapper">
                            <img
                              className="mobile-banner-image"
                              src={`${baseURL}/460x260/${banner.mobileImage.fullPath}`}
                              alt="banner"
                              height="240"
                              width="360"
                            />
                          </div>
                        </ImagesMobileWrapper>
                        <Label>{__('Typ súboru')}</Label>
                        <ImageInfo>{banner.image.mimeType}</ImageInfo>
                        <Space />

                        <Label>{__('Veľkosť súboru')}</Label>
                        <ImageInfo>{formatBytes(banner.image.size)}</ImageInfo>
                      </div>
                    )}
                  </GreyBox>
                )}
            </ColumnWrapper>

            <ButtonWrapper>
              <Button
                loading={saveLoading}
                onClick={() => {
                  this.saveBanner();
                }}
                primary
              >
                {getIdFromProps(this.props) === 'new'
                  ? __('Uložiť')
                  : __('Upraviť')}
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div``;
const DatePickerWrapper = styled.div`
  max-width: ${rem(150)};
  margin-right: ${rem(10)};
`;
const StyledDatePicker = styled(DatePicker)`
  max-width: 90%;
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .link-button-wrapper {
    position: absolute;
    right: ${rem(32)};
    bottom: ${rem(32)};
  }

  .banner-image {
    width: ${rem(1248)};
    height: 100%;
    object-fit: cover;
    border-radius: ${rem(24)} ${rem(24)} 0 0;
    background-color: gray;
  }

  .image-wrapper {
    height: ${rem(446)};
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .mobile-image-wrapper {
    height: ${rem(240)};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .mobile-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${rem(24)};
    background-color: gray;
  }

  .banner-1to1-image {
    min-width: ${rem(512)};
    max-width: ${rem(512)};
    height: ${rem(512)};
    border-radius: ${rem(16)};
    margin-left: ${rem(32)};
    background-color: rgba(54, 55, 64, 0.08);
    display: flex;
    overflow: hidden;
  }
`;

const ImagesMobileWrapper = styled(ImagesWrapper)`
  width: ${rem(360)};
`;
const ImageInfo = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(15)};
  display: flex;
`;

const Space = styled.div`
  width: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const InputWrapper = styled.div`
  width: ${rem(350)};
`;
const PublishedDatesWrapper = styled.div`
  width: ${rem(350)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PublishedCountriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledInput = styled(Input)`
  width: ${rem(350)};
`;

const StyledSelect = styled(Select)`
  width: ${rem(350)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;
const ContentWrapper = styled.div``;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(12)};
`;

EshopBannersDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
EshopBannersDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopBannersDetail);
