import { __ } from '../Utils';

const prices = { name: __('Ceny') };
const marketing = { name: __('Marketing') };
const newsletters = { name: __('Newsletters'), path: '../' };
const settings = { name: __('Nastavenia') };
const productsCatalog = { name: __('Produktový katalóg'), path: '../products' };
const marketingMaterials = {
  name: __('Marketingové materiály'),
  path: '../marketing-materials',
};

export const location = {
  '/products': [
    {
      name: __('Produkty'),
    },
  ],
  '/products/(.*)': [
    {
      name: __('Produkty'),
    },
    productsCatalog,
  ],
  '/colourCards': [
    {
      name: __('Produkty'),
    },
  ],
  '/colourCards/(.*)': [
    {
      name: __('Produkty'),
    },
    {
      name: __('Vzorkovníky'),
      path: '../colourCards',
    },
  ],
  '/toningSystems': [
    {
      name: __('Produkty'),
    },
  ],
  '/toningSystems/(.*)': [
    {
      name: __('Produkty'),
    },
    {
      name: __('Tónovacie systémy'),
      path: '../toningSystems',
    },
  ],
  '/recipes': [
    {
      name: __('Produkty'),
    },
  ],
  '/recipes/(.*)': [
    {
      name: __('Produkty'),
    },
    {
      name: __('Receptúry'),
      path: '../recipes',
    },
  ],
  '/paint-systems': [
    {
      name: __('Produkty'),
    },
  ],
  '/paint-systems/(.*)': [
    {
      name: __('Produkty'),
    },
    {
      name: __('Náterové systémy'),
      path: '../paint-systems',
    },
  ],
  '/discounts': [prices],
  '/margins': [prices],
  '/campaigns': [
    {
      name: __('Marketing'),
    },
  ],
  '/campaigns/(.*)': [
    { name: 'Marketing' },
    {
      name: __('Kampane'),
      path: '../campaigns',
    },
  ],
  '/marketing-materials': [marketing],
  '/marketing-materials/(.*)': [marketing, marketingMaterials],
  '/graphics': [marketing],
  '/graphics/(.*)': [
    marketing,
    {
      name: __('Grafika'),
      path: '../graphics',
    },
  ],
  '/prints': [marketing],
  '/prints/(.*)': [
    marketing,
    {
      name: __('Tlačoviny'),
      path: '../prints',
    },
  ],
  '/videos': [marketing],
  '/videos/(.*)': [
    marketing,
    {
      name: __('Videá'),
      path: '../videos',
    },
  ],

  '/newsletter': [newsletters],
  '/newsletter/(.*)': [newsletters],
  '/newsletter/(.*)/logout': [
    newsletters,
    {
      name: __('Newsletter detail'),
      path: './',
    },
  ],

  '/inspirations': [marketing],
  '/inspirations/(.*)': [
    marketing,
    {
      name: __('Inšpirácie'),
      path: '../inspirations',
    },
  ],

  '/inspirations-objects': [marketing],
  '/inspirations-objects/(.*)': [
    marketing,
    {
      name: __('Inšpirácie - Objekty/Miestnosti'),
      path: '../inspirations-objects',
    },
  ],

  '/references': [marketing],
  '/references/(.*)': [
    marketing,
    {
      name: __('Referencie'),
      path: '../references',
    },
  ],

  '/lists': [marketing],
  '/lists/(.*)': [
    marketing,
    {
      name: __('Marketingové zoznamy'),
      path: '../lists',
    },
  ],

  '/codelists': [settings],
  '/CodeLists/(.*)': [
    settings,
    {
      name: __('Číselníky'),
      path: '../codelists',
    },
  ],

  '/brands': [settings],
  '/products-groups': [settings],
  '/categories': [settings],

  '/productTypes': [settings],
  '/productTypes/(.*)': [
    settings,
    {
      name: __('Typy produktov'),
      path: '../productTypes',
    },
  ],

  '/customerTypes': [settings],
  '/customerTypes/(.*)': [
    settings,
    {
      name: __('Typy zákazníkov'),
      path: '../customerTypes',
    },
  ],

  '/order-settings': [settings],

  '/filters': [settings],
  '/filters/(.*)': [
    settings,
    {
      name: __('Filtre'),
      path: '../filters',
    },
  ],

  '/users': [settings],
  '/users/(.*)': [
    settings,
    {
      name: __('Používatelia'),
      path: '../users',
    },
  ],

  '/security-alerts': [settings],
  '/security-alerts/(.*)': [
    settings,
    {
      name: __('Bezpečnostné upozornenia'),
      path: '../security-alerts',
    },
  ],
};

export const base = [
  {
    name: __('Domov'),
    path: '/',
  },
];
