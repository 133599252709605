import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

const StyledImg = styled.img`
  width: ${rem(35)};
  height: ${rem(35)};
  border-radius: ${rem(3)}
  position: absolute;
  cursor: pointer;
`;

const ColorPickerFocus = styled.div`
  width: ${rem(120)};
  height: ${rem(120)};
  z-index: 10;
  border: solid ${rem(1)} #eee;
  border-radius: ${rem(5)};
  margin-bottom: ${rem(50)};
  position: fixed;
  top: ${rem(190)};
  right: ${rem(125)};
`;

const StyledFocusImg = styled(StyledImg)`
  width: ${rem(120)};
  height: ${rem(120)};
  z-index: 10;
  border-radius: ${rem(5)};
`;

const Wrapper = styled.div`
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  height: ${rem(36)};
  width: ${rem(36)};
`;

const Image = styled.img`
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  height: ${rem(36)};
  width: ${rem(36)};
  cursor: pointer;
`;

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
    return (
      <div>
        {this.props.imgColor ? (
          <React.Fragment>
            <Image
              onClick={() => {
                this.setState({
                  visible: !this.state.visible,
                });
              }}
              onMouseOutCapture={() => {
                this.setState({
                  visible: false,
                });
              }}
              src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${this.props.imgColor}`}
            />
            {this.state.visible && (
              <ColorPickerFocus
                style={{
                  backgroundColor: 'none',
                }}
              >
                {this.props.imgColor && (
                  <StyledFocusImg
                    src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${this.props.imgColor}`}
                  />
                )}
              </ColorPickerFocus>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.props.img && (
              <StyledImg
                onClick={() => {
                  this.setState({
                    visible: !this.state.visible,
                  });
                }}
                onMouseOutCapture={() => {
                  this.setState({
                    visible: false,
                  });
                }}
                src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${this.props.img}`}
              />
            )}

            <Wrapper
              style={{
                backgroundColor: this.props.color,
              }}
            />
            {this.state.visible && (
              <div style={{ position: '' }}>
                <ColorPickerFocus
                  style={{
                    backgroundColor: this.props.color,
                  }}
                >
                  {this.props.img && (
                    <StyledFocusImg
                      src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${this.props.img}`}
                    />
                  )}
                </ColorPickerFocus>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  img: PropTypes.string,
  imgColor: PropTypes.string,
};

ColorPicker.defaultProps = {
  color: '',
  img: '',
  imgColor: '',
};

export default ColorPicker;
