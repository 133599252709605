import React from 'react';
import moment from 'moment';
import BaseTable from '../../BaseTable';
import tableHeaders from '../../../ContentConfig/cashiers';
import AdminAPI from '../../../AdminAPI';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
} from '../../../Utils';
import ControllBar from '../../../Components/ControllBar';
import { connect } from '../../../Store';
import ControlBarButton from '../../../Components/ControllBar/ControlBarButton';
import ControlButton from '../../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper } from '../../../Components/ReusableComponents';

class CashiersList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Cashiers');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'surname',
      'primary_eshop_id',
      'created_date',
      'updated_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      states: [],
      lastSearch: '',
      limit: 100,
      shopIds: [],
      commonMessage: __('Všetky'),
    };
  }

  handleItemClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/cashiers/${id}`);
  }

  async componentWillMount() {
    const shops = await AdminAPI.getShopsAction().then(res => {
      return res.items.map(item => ({
        value: item._id,
        label: item.name,
        raw: item,
      }));
    });

    this.setState({ shopIds: shops });
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, limit } = this.state;
      this.setState({ loading: true });

      const params = Object.assign({}, parameters, { sorter, limit });
      params.firm = this.props.firm;

      const newData = await AdminAPI.getCashiersAction(params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const { shopIds } = this.state;

    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      const primaryEshop = shopIds.find(s => s.value === item.primary_eshop_id);

      acumulator.push({
        name: _shapeData(
          renderLink(`./cashiers/${item._id}`, `${item.name || '-'}`),
          () => this.handleItemClick(item._id),
        ),
        surname: _shapeData(item.surname),
        primary_eshop_id: _shapeData(
          (primaryEshop && primaryEshop.label) || '',
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  handleCommonMessage(value, name) {
    this.setState({ commonMessage: name }, () => {
      const params = { shopId: value };
      this.fetchData(value && params);
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { commonMessage, shopIds } = this.state;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Pokladníci')}
      >
        <ControlButton name={commonMessage}>
          <RouteLinkWraper>
            <div onClick={() => this.handleCommonMessage(null, __('Všetky'))}>
              {__('Všetky')}
            </div>
            {shopIds.map(c => {
              return (
                <div
                  onClick={e =>
                    this.handleCommonMessage(c.value, e.target.innerHTML)
                  }
                >
                  {c.label}
                </div>
              );
            })}
          </RouteLinkWraper>
        </ControlButton>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleItemClick('new')}
          icon="plus"
        >
          {__('Pridať pokladníka')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashiersList);
