import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PresetEmailTemplateDetail from '../../../Containers/PresetEmailTemplateDetail';
import PresetEmailTemplateList from '../../../Containers/PresetEmailTemplateList';

function PresetEmailTemplates(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={PresetEmailTemplateDetail} />
      <Route path={`${match.url}`} component={PresetEmailTemplateList} />
    </Switch>
  );
}

PresetEmailTemplates.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default PresetEmailTemplates;
