import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Icon, PopUpList } from 'oxyrion-ui/lib';
import { Link as RouterLink } from 'react-router-dom';
import uniqid from 'uniqid';
import { FlexBoxStart, FlexBox } from '../ReusableComponents';
import CheckAccess from '../../ACL/CheckAccess';
import { connect } from '../../Store';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Item = styled(FlexBoxStart)`
  align-items: flex-start;
  flex-direction: column;
  margin: 0 ${rem(2)};
  padding: ${rem(10)} 0 ${rem(0)};
  position: relative;
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSIze || '16px'};

  img:nth-of-type(2) {
    display: none;
  }

  ${({ theme }) => theme.media.l`
    margin: 0 ${rem(2)};
    padding: ${rem(20)} 0;
    position: relative;
    display: inline-block;
    color:  ${theme.textColor};
    flex: ;
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSIze || '16px'};
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &:hover {
      color: ${theme.color.primary}
      ul:first-of-type {
        display: block;
      }

      img:first-of-type {
        display: none;
      }

      img:nth-of-type(2) {
        display: inline-block;
      }
    }
  `};
`;

const ItemName = styled.p`
  margin: 0 ${rem(5)} 0 ${rem(15)};
  cursor: pointer;
  white-space: nowrap;

  img {
    vertical-align: middle;
    margin: 0 ${rem(4)};
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: ${rem(18)};
  margin-right: ${({ right }) => (right ? '' : 'auto')};
  ${({ theme }) => theme.media.l`
    display: none;
  `};
`;

const NavigationMenu = styled.div`
  position: absolute;
  transition: left 0.3s ease-out;
  ${({ display }) =>
    display ? 'left: 0' : 'transition: left 0.5s ease-out; left: -100%'}};
  flex-direction: column;
  border-right: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  background-color: ${({ theme }) => theme.color.primaryBg};
  height: 100vh;
  overflow: scroll;
  justify-content: flex-start;
  z-index: 3;
  min-width: 235px;
  ${({ theme }) => theme.media.s`
  width: 210px;
  `};
  ${({ theme }) => theme.media.m`
  width: 210px;
  `};
  ${({ theme }) => theme.media.l`
    display: flex
    ${({ wasSearched, search }) => {
      if (wasSearched && !search) {
        return css`
          animation: ${fadeIn} 0.2s linear;
        `;
      } else if (!wasSearched && !search) {
        return css`
          display: flex;
        `;
      }
      return css`
        display: none;
      `;
    }}
    flex-shrink: 2;
    position: relative
    left: 0
    z-index: 0;
    width: 210px;
    flex-direction: row;
    align-items: stretch;
    border: none;
    height: auto;
    margin-right: auto;
    overflow: inherit;
  `};
`;

const Logo = styled(FlexBox)`
  justify-content: space-between;
  flex-direction: row;
  margin: 0;
  padding-left: ${rem(20)};
  height: ${rem(50)};
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.color.primaryBg};
  z-index: 3;
  ${({ theme }) => theme.media.l`
    display: none;
  `};
`;

const Ul = styled.ul`
  display: ${({ display }) => (display ? 'block' : 'none')}
  width: 100%;
  margin: 0;
  list-style: none;
  padding: ${rem(5)} 0 0 ${rem(15)};
  font-size: ${({ theme }) => theme.fontSIze || '16px'};

  ${({ theme }) => theme.media.l`
    display: none;
  `}
`;

const Li = styled.li`
  cursor: pointer;
  a {
    color: ${({ theme }) => theme.color.base};
    font-size: ${({ theme }) => theme.fontSIze || '16px'};
    display: block;
    text-decoration: none;
    margin: ${rem(10)};
  }
  a:visited {
      color: ${({ theme }) => theme.color.base};
  }
  a:hover {
    color: ${({ theme }) => theme.color.primary};
  }
  }
`;

const DropDownLi = styled.li`
  list-style: none;
  padding: 0;
  cursor: pointer;
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSIze || '14px'};
  text-align: left;
  white-space: nowrap;
  a {
    display: block;
    text-decoration: none;
    padding: ${rem(5)} ${rem(10)};
    color: ${({ theme }) => theme.color.base};
  }
  a:visited {
      color: ${({ theme }) => theme.fontColor || theme.color.base};
  }
  a:hover {
      color: ${({ theme }) => theme.color.primary};
  }

  }
`;

const SecondLevelHeader = styled.div`
  margin-left: ${rem(10)};
`;

class ControlMenu extends React.Component {
  constructor(props) {
    super(props);
    const content = this.props.content.map(element => ({
      name: element.name,
      expanded: element.expanded || false,
      checkAccess: element.checkAccess,
      dropdown: element.dropdown,
    }));

    this.state = {
      expanded: false,
      content,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', e => this.handleOutsideClick(e));
  }

  handleOutsideClick(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ expanded: false });
    }
  }

  handleItemOnclick(index) {
    const { content } = this.state;
    content[index].expanded = !content[index].expanded;
    this.setState({ content });
  }

  closeSideNavBar() {
    this.setState({ expanded: false });
  }

  generateMenu(element, index) {
    const { top, match, userData } = this.props;

    if (
      userData.urlList.length === 0 ||
      (element.dropdown &&
        element.dropdown.find(f => userData.urlList.includes(f.href)))
    ) {
      const menuItem = (
        <Item key={uniqid()} onClick={() => this.handleItemOnclick(index)}>
          <ItemName>
            {element.name}
            <img src="/images/icons/arrow-down.svg" height="4px" alt="arrow" />
            <img src="/images/icons/arrow-up.svg" height="4px" alt="arrow" />
          </ItemName>
          <PopUpList display={false} top={top} bordered minWidth="120px">
            {element.dropdown.map(dropdownItem => {
              if (
                userData.urlList.length === 0 ||
                userData.urlList.includes(dropdownItem.href)
              ) {
                if (dropdownItem.dropdown) {
                  return (
                    <React.Fragment>
                      <SecondLevelHeader>
                        {' '}
                        {dropdownItem.name}
                      </SecondLevelHeader>
                      {dropdownItem.dropdown.map(secondLevelDropdown => {
                        if (
                          userData.urlList.length === 0 ||
                          userData.urlList.includes(secondLevelDropdown.href)
                        ) {
                          return (
                            <DropDownLi key={uniqid()}>
                              {secondLevelDropdown.checkAccess ? (
                                <CheckAccess
                                  operation={secondLevelDropdown.checkAccess}
                                  Component={
                                    <RouterLink
                                      to={`${match.url}${secondLevelDropdown.href}`}
                                    >
                                      {secondLevelDropdown.name}
                                    </RouterLink>
                                  }
                                />
                              ) : (
                                <RouterLink
                                  to={`${match.url}${secondLevelDropdown.href}`}
                                >
                                  {secondLevelDropdown.name}
                                </RouterLink>
                              )}
                            </DropDownLi>
                          );
                        }
                        return '';
                      })}
                    </React.Fragment>
                  );
                }
                return (
                  <DropDownLi key={uniqid()}>
                    {dropdownItem.checkAccess ? (
                      <CheckAccess
                        operation={dropdownItem.checkAccess}
                        Component={
                          <RouterLink to={`${match.url}${dropdownItem.href}`}>
                            {dropdownItem.name}
                          </RouterLink>
                        }
                      />
                    ) : (
                      <RouterLink to={`${match.url}${dropdownItem.href}`}>
                        {dropdownItem.name}
                      </RouterLink>
                    )}
                  </DropDownLi>
                );
              }
              return '';
            })}
          </PopUpList>
          <Ul display={!!element.expanded}>
            {element.dropdown.map(item => (
              <Li key={uniqid()}>
                <RouterLink
                  onClick={() => this.closeSideNavBar()}
                  to={`${match.url}${item.href}`}
                >
                  {item.name}
                </RouterLink>
              </Li>
            ))}
          </Ul>
        </Item>
      );

      return element.checkAccess ? (
        <CheckAccess operation={element.checkAccess} Component={menuItem} />
      ) : (
        menuItem
      );
    }
    return <React.Fragment />;
  }

  render() {
    const { search, wasSearched } = this.props;
    const { content, expanded } = this.state;

    return (
      <React.Fragment>
        <IconWrapper onClick={() => this.setState({ expanded: true })}>
          <img src="/images/icons/menu.svg" alt="menu" height="20vh" />
        </IconWrapper>
        <NavigationMenu
          search={search}
          wasSearched={wasSearched}
          id="nav-bar"
          display={!!expanded}
          innerRef={ref => {
            this.wrapperRef = ref;
          }}
        >
          <Logo>
            <img src="/images/Login/crm.svg" alt="Oxyrion logo" height="30vh" />
            <IconWrapper
              right
              onClick={() => this.setState({ expanded: false })}
            >
              <Icon name="close" />
            </IconWrapper>
          </Logo>
          {content.map((element, index) => this.generateMenu(element, index))}
        </NavigationMenu>
      </React.Fragment>
    );
  }
}

ControlMenu.propTypes = {
  top: PropTypes.string,
  search: PropTypes.bool,
  wasSearched: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      dropdown: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  match: PropTypes.object.isRequired, //eslint-disable-line
};

ControlMenu.defaultProps = {
  search: true,
  top: rem(43),
};

const mapStateToProps = state => {
  return { userData: state };
};

export default connect(state => mapStateToProps(state))(ControlMenu);
