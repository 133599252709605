import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { formatColorToRGB, getHighlightedText } from '../index';
import AdminAPI from '../../AdminAPI';
import API2 from '../../API2';

const ColorRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 365px;
`;

const NameAndBaseWrapper = styled.div``;

const ColorName = styled.div`
  font-weight: 800;
`;

const ColorHolder = styled.div`
  background: ${props => props.color};
  width: ${rem(64)};
  height: ${rem(32)};
`;

export const searchShops = async (query, firm) => {
  try {
    const filter = {
      limit: 50,
      q: query,
      firm,
    };

    return AdminAPI.getShopsAction(filter).then(res => {
      return res.items.map(item => ({
        value: item._id,
        label: item.name,
        raw: item,
      }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchMarketingLists = async (query, firm) => {
  try {
    const filter = {
      limit: 20,
      q: query,
      firm,
    };

    return AdminAPI.getMarketingListsAction(filter).then(res => {
      return res.items.map(item => ({
        value: item._id,
        label: item.name,
        raw: item,
      }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchShops = () => {
  try {
    return AdminAPI.getShopsAction().then(res => {
      return res.items.map(item => ({
        value: item._id,
        label: item.name,
        raw: item,
      }));
    });
  } catch (e) {
    return [];
  }
};

export const searchUltraLiteCatalogProducts = async (
  query,
  filters,
  firm = 'SK',
  canBeToned = null,
) => {
  try {
    const filter = {
      limit: 20,
      q: query,
    };

    if (filters) {
      filter.filters = filters;
    }

    if (canBeToned) {
      filter.canBeToned = canBeToned;
    }

    return API2.getUltraLiteCatalogProductsAction(firm, filter).then(res => {
      return res.products.map(item => ({
        value: item._id,
        label: item.name,
        type: item.type,
      }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchCatalogProductVariants = async (
  query,
  catalogProductId,
  filters = {
    addedIds: [],
  },
  firm = 'SK',
) => {
  try {
    return API2.getCatalogProductVariantsAction(firm, catalogProductId, {
      q: query,
      limit: 20,
    }).then(res => {
      const items = res.variants.filter(
        v => filters.addedIds.indexOf(v._id) === -1,
      );
      return items.map(item => ({
        value: item._id,
        label: item.name,
        hasMore: items.length > 1,
        raw: item,
      }));
    });
  } catch (e) {
    return [];
  }
};

export const fetchMultimedia = async (query, usedIds = []) => {
  try {
    return AdminAPI.getGraphicsPrintsVideosAction({
      q: query,
      type: 'photos',
      limit: 20,
    }).then(res => {
      return res.items
        .filter(i => usedIds.indexOf(i._id) === -1)
        .map(item => ({
          value: item._id,
          label: item.name,
          raw: item,
        }));
    });
  } catch (e) {
    return [];
  }
};

export const fetchGraphics = async (query, usedIds = []) => {
  try {
    return AdminAPI.getGraphicsPrintsVideosAction({
      q: query,
      type: 'graphic',
      limit: 20,
    }).then(res => {
      return res.items
        .filter(i => usedIds.indexOf(i._id) === -1)
        .map(item => ({
          value: item._id,
          label: item.name,
          raw: item,
        }));
    });
  } catch (e) {
    return [];
  }
};

export const searchCatalogProducts = async (query, usedIds = []) => {
  try {
    return AdminAPI.getCatalogProductsAction({
      q: query,
    }).then(res => {
      return res.items
        .filter(item => usedIds.indexOf(item._id) === -1)
        .map(item => ({
          value: item._id,
          raw: item,
          label: item.name,
        }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchCatalogProductShades = async (
  query,
  catalogProductId,
  usedShades = [],
  firm,
) => {
  try {
    return API2.getCatalogProductShadesAction(firm, catalogProductId, {
      q: query,
    }).then(res => {
      return res.shades
        .filter(item => usedShades.indexOf(item.color_code) === -1)
        .map(item => ({
          value: item.color_code,
          raw: item,
          label: (
            <ColorRow>
              <NameAndBaseWrapper>
                <ColorName>
                  {getHighlightedText(
                    item.color_base_name || item.color_code,
                    query,
                  )}
                </ColorName>
              </NameAndBaseWrapper>
              <ColorHolder color={formatColorToRGB(item.color)} />
            </ColorRow>
          ),
        }));
    });
  } catch (e) {
    return [];
  }
};

export const searchBrands = async (query, firm = 'SK', usedBrands = []) => {
  try {
    return API2.getBrandsAction(firm, {
      q: query,
      limit: 20,
    }).then(res => {
      return res.fullBrands
        .filter(f => usedBrands.indexOf(f.brand) === -1)
        .map(item => ({
          value: item._id,
          label: item.brand,
        }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchStocks = async query => {
  try {
    return AdminAPI.getStocksAction({
      q: query,
      limit: 20,
    }).then(res => {
      return res.items.map(item => ({
        value: item._id,
        label: `${item._id} - ${item.name}`,
      }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchInLocalArray = async (array = [], searchByValue, query) => {
  if (!array) {
    return [];
  }

  try {
    return array
      .filter(
        a =>
          a[searchByValue] &&
          a[searchByValue].toUpperCase().includes(query.toUpperCase()),
      )
      .map(a => ({
        label: a[searchByValue],
        value: a._id,
        raw: a,
      }));
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchCategories = async (channelId, lang, query, excludedId) => {
  try {
    return AdminAPI.getCategoriesAction(channelId, {
      q: query,
      lang,
    }).then(res => {
      return res.categories
        .filter(c => c._id !== excludedId)
        .map(item => ({
          value: item._id,
          label: item.name,
        }));
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchColorCards = (query, firm) => {
  try {
    return API2.getColorcardsAction(firm, {
      q: query,
      limit: 10,
    }).then(res => {
      return res.items.map(item => ({
        value: item._id,
        label: item.name,
      }));
    });
  } catch (e) {
    return [];
  }
};

export const isEmptyArray = arrayOrString => arrayOrString.length === 0;
