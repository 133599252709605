import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { ChromePicker } from 'react-color';
import { Message, Input, Loader, Icon, Button } from 'oxyrion-ui/lib';
import { connect } from '../../../Store';
import { __ } from '../../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../../Components/ReusableComponents';
import AdminAPI from '../../../AdminAPI';
import PopUpWrapper from '../../../Components/PopUpWrapper';
import ConfirmDialog from '../../../Components/ConfirmDialog';

const SubCategoryButtonWrapper = styled.div`
  font-size: ${rem(12)};
  margin-bottom: ${rem(8)};
  width: ${rem(110)};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  overflow: auto;
  padding: ${rem(4)};
  margin-bottom: ${rem(100)};
  min-height: ${rem(600)};
`;

const Col = styled.div`
  /* width: ${rem(500)}; */
`;

const ColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 15px;
`;

const PlusWrapper = styled.div`
  height: ${rem(34)};
  width: ${rem(34)};
  margin-left: ${rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ColorPickerHolder = styled.div`
  position: absolute;
  cursor: pointer;
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  left: ${rem(0)};
  height: ${rem(36)};
  width: ${rem(36)};
`;

const ColorWrapper = styled.div`
  background: red;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryWrapper = styled.div``;
const CategoryNameWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${rem(4)};
`;
const CategoryName = styled.div`
  position: relative;
  margin-left: ${rem(40)};
`;
const CategoryButton = styled.div`
  margin-left: ${rem(30)};
  width: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditButton = styled.div`
  width: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 11px;
`;

const CategoriesWrapper = styled.div`
  margin-left: ${rem(4)};
  margin-top: ${rem(8)};
`;

const SaveButtonWrapper = styled.div`
  margin-left: ${rem(32)};
  width: ${rem(220)};
  height: ${rem(38)};
  display: flex;
  justify-content: space-between;
`;

const CategoryMainWrapper = styled.div``;

const NewCategoryWrapper = styled.div`
  margin-bottom: ${rem(16)};
  display: flex;
`;

class CategoriesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      loading: true,
      categoriesTree: [],
      openedCategories: [],
      colorTemp: {},
      categoryToEditId: '',
      newCategoriesTemp: {
        name: '',
        rgb: { r: 255, g: 255, b: 255 },
      },
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const categoriesTree = await AdminAPI.getColorCategoriesTreeAction({});

      this.setState({
        categoriesTree:
          categoriesTree.categories[0] && categoriesTree.categories[0].items,
        saveLoading: false,
        deleteLoading: false,
        categoryIdToDelete: undefined,
        nameTemp: '',
        colorTemp: {},
        categoryToEditId: '',
        newCategoriesTemp: {
          name: '',
          rgb: {
            r: 255,
            g: 255,
            b: 255,
          },
        },
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie kategórií nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Kategórie sa nenašieli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  //   async deleteCategory(id) {
  //     try {
  //     } catch (e) {
  //       this.setState({
  //         error: __('Podskupinu sa nepodarilo odstrániť'),
  //       });
  //     }
  //   }

  addSubCategory(parentId) {
    const { categoriesTree, openedCategories } = this.state;

    categoriesTree
      .find(c => c._id === parentId)
      .categories.push({
        parentId,
        _id: 'new',
        name: '',
      });

    openedCategories.push(parentId);
    this.setState({
      categoriesTree,
      openedCategories,
      categoryToEditId: 'new',
    });
  }

  async deleteCategory() {
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteColorCategoryAction(this.state.categoryIdToDelete);
      this.fetchData();
    } catch (e) {
      this.setState({
        deleteError: __('Kategóriu sa nepodarilo odstrániť'),
        deleteLoading: false,
      });
    }
  }

  async addCategory(level, parentId) {
    const { newCategoriesTemp } = this.state;

    if (
      !newCategoriesTemp[level] ||
      newCategoriesTemp[level].name.length === 0
    ) {
      return;
    }

    try {
      const params = {};
      params.body = {
        name: newCategoriesTemp[level].name,
        rgb: newCategoriesTemp[level].rgb,
        parent: parentId,
      };

      await AdminAPI.postColorCategoryAction(params);

      this.fetchData();
    } catch (e) {
      this.setState({
        error: __('Kategóriu sa nepodarilo uložiť'),
      });
    }
  }

  handleCategoryClicked(opened, id) {
    const { openedCategories } = this.state;

    if (!opened) {
      openedCategories.push(id);
    } else if (opened) {
      openedCategories.splice(openedCategories.indexOf(id), 1);
    }

    this.setState({
      openedCategories,
    });
  }

  handleEditChange(id, name, rgb) {
    const { categoryToEditId } = this.state;

    if (id === categoryToEditId) {
      this.setState({
        categoryToEditId: '',
        nameTemp: '',
      });
      return;
    }

    this.setState({
      categoryToEditId: id,
      nameTemp: name,
      colorTemp: rgb,
    });
  }

  handleNewCategoryChange(value, level, field) {
    const { newCategoriesTemp } = this.state;

    if (!newCategoriesTemp[level]) {
      newCategoriesTemp[level] = {
        name: '',
        rgb: { r: 255, g: 255, b: 255 },
      };
    }

    newCategoriesTemp[level][field] = value;

    this.setState({
      newCategoriesTemp,
    });
  }

  async saveCategory(id, name, rgb, parentId) {
    this.setState({
      saveLoading: true,
    });

    if (id === 'new') {
      try {
        const params = {};
        params.body = {
          name,
          rgb,
          parent: parentId,
        };

        await AdminAPI.postColorCategoryAction(params);

        this.fetchData();
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const params = {};
        params.body = {
          name,
          rgb,
        };

        await AdminAPI.putColorCategoryAction(id, params);

        this.fetchData();
      } catch (e) {
        console.log(e);
      }
    }
  }

  togglePicker(level) {
    this.setState({ showPicker: level });
  }

  renderCategory(categories, level, parentId) {
    const {
      openedCategories,
      categoryToEditId,
      nameTemp,
      colorTemp,
      saveLoading,
      newCategoriesTemp,
      showPicker,
    } = this.state;

    return (
      <CategoryMainWrapper>
        {categories.map(category => {
          const isCategoryOpened = openedCategories.indexOf(category._id) > -1;

          return (
            <CategoryWrapper style={{ marginLeft: rem(16 * level) }}>
              <ColorWrapper>
                <ColorPickerHolder
                  style={{
                    backgroundColor:
                      categoryToEditId === category._id
                        ? `rgba(${colorTemp.r}, ${colorTemp.g}, ${
                            colorTemp.b
                          }, 1)`
                        : `rgba(${category.rgb &&
                            category.rgb.r}, ${category.rgb &&
                            category.rgb.g}, ${category.rgb &&
                            category.rgb.b}, 1)`,
                  }}
                  onClick={() =>
                    categoryToEditId === category._id &&
                    this.togglePicker(level)
                  }
                />
                {showPicker === level && categoryToEditId === category._id && (
                  <ColorPickerWrapper
                    onMouseLeave={() =>
                      categoryToEditId === category._id && this.togglePicker(-1)
                    }
                  >
                    <ChromePicker
                      onChange={e =>
                        this.setState({
                          colorTemp: e.rgb,
                        })
                      }
                      color={
                        categoryToEditId === category._id
                          ? colorTemp
                          : {
                              r: (category.rgb && category.rgb.r) || 0,
                              g: (category.rgb && category.rgb.g) || 0,
                              b: (category.rgb && category.rgb.b) || 0,
                              a: 1,
                            }
                      }
                    />
                  </ColorPickerWrapper>
                )}
              </ColorWrapper>
              <CategoryNameWrapper>
                <CategoryName>
                  <Input
                    value={
                      categoryToEditId === category._id
                        ? nameTemp
                        : category.name
                    }
                    onChange={e =>
                      this.setState({
                        nameTemp: e.target.value,
                      })
                    }
                    disabled={categoryToEditId !== category._id}
                  />
                  {categoryToEditId.length === 0 ? (
                    <EditButton
                      onClick={() =>
                        this.handleEditChange(
                          category._id,
                          category.name,
                          category.rgb,
                        )
                      }
                    >
                      <Icon name="edit" size="m" />
                    </EditButton>
                  ) : (
                    <div />
                  )}
                </CategoryName>

                {category.categories && category.categories.length ? (
                  <CategoryButton
                    onClick={() =>
                      this.handleCategoryClicked(isCategoryOpened, category._id)
                    }
                  >
                    <Icon
                      name={isCategoryOpened ? 'arrow-top' : 'arrow-down'}
                      size="s"
                    />
                  </CategoryButton>
                ) : (
                  <CategoryButton
                    onClick={() =>
                      this.setState({
                        categoryIdToDelete: category._id,
                      })
                    }
                  >
                    <Icon name="delete" size="l" />
                  </CategoryButton>
                )}
                {categoryToEditId === category._id ? (
                  <SaveButtonWrapper>
                    <Button
                      onClick={() =>
                        this.saveCategory(
                          category._id,
                          nameTemp,
                          colorTemp,
                          category.parentId,
                        )
                      }
                      small
                      loading={saveLoading}
                      primary
                    >
                      {__('Uložiť')}
                    </Button>

                    <Button
                      onClick={() =>
                        this.setState({
                          categoryToEditId: '',
                          nameTemp: '',
                        })
                      }
                      small
                      loading={saveLoading}
                      danger
                    >
                      {__('Zrušiť')}
                    </Button>
                  </SaveButtonWrapper>
                ) : (
                  <div />
                )}
              </CategoryNameWrapper>
              {category.categories &&
              category.categories.length &&
              isCategoryOpened
                ? this.renderCategory(
                    category.categories,
                    level + 1,
                    category._id,
                  )
                : level <= 0 &&
                  !category.categories.length && (
                    <SubCategoryButtonWrapper
                      onClick={() => this.addSubCategory(category._id, level)}
                    >
                      {__('Pridať podkategóriu')}
                    </SubCategoryButtonWrapper>
                  )}
            </CategoryWrapper>
          );
        })}
        {!categoryToEditId && (
          <NewCategoryWrapper
            style={{
              height: showPicker === level && rem(300),
              marginLeft: rem(16 * level),
            }}
          >
            <ColorWrapper>
              <ColorPickerHolder
                style={{
                  backgroundColor: `rgba(${newCategoriesTemp[level] &&
                    newCategoriesTemp[level].rgb &&
                    newCategoriesTemp[level].rgb.r}, ${newCategoriesTemp[
                    level
                  ] &&
                    newCategoriesTemp[level].rgb &&
                    newCategoriesTemp[level].rgb.g}, ${newCategoriesTemp[
                    level
                  ] &&
                    newCategoriesTemp[level].rgb &&
                    newCategoriesTemp[level].rgb.b}, 1)`,
                }}
                onClick={() => this.togglePicker(level)}
              />
              {showPicker === level && (
                <ColorPickerWrapper onMouseLeave={() => this.togglePicker(-1)}>
                  <ChromePicker
                    onChange={e =>
                      this.handleNewCategoryChange(e.rgb, level, 'rgb')
                    }
                    color={{
                      r:
                        newCategoriesTemp[level] && newCategoriesTemp[level].rgb
                          ? newCategoriesTemp[level].rgb.r
                          : 0,
                      g:
                        newCategoriesTemp[level] && newCategoriesTemp[level].rgb
                          ? newCategoriesTemp[level].rgb.g
                          : 0,
                      b:
                        newCategoriesTemp[level] && newCategoriesTemp[level].rgb
                          ? newCategoriesTemp[level].rgb.b
                          : 0,
                      a: 1,
                    }}
                  />
                </ColorPickerWrapper>
              )}
            </ColorWrapper>
            <CategoryName>
              <Input
                value={
                  newCategoriesTemp[level] ? newCategoriesTemp[level].name : ''
                }
                onChange={e =>
                  this.handleNewCategoryChange(e.target.value, level, 'name')
                }
                placeholder={__('Nová skupina')}
              />
            </CategoryName>

            <PlusWrapper onClick={() => this.addCategory(level, parentId)}>
              <Icon name="plus" size="m" />
            </PlusWrapper>
          </NewCategoryWrapper>
        )}
      </CategoryMainWrapper>
    );
  }

  render() {
    const {
      error,
      success,
      loading,
      categoriesTree,
      categoryIdToDelete,
      deleteLoading,
      deleteError,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <PopUpWrapper
          display={categoryIdToDelete}
          small
          onClose={() => this.setState({ categoryIdToDelete: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť skupinu ?')}
            onDismiss={() => this.setState({ categoryIdToDelete: false })}
            onConfirm={() => this.deleteCategory()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          <Row>
            <Col>
              <CategoriesWrapper>
                {this.renderCategory(categoriesTree, 0)}
              </CategoriesWrapper>
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  }
}

CategoriesSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CategoriesSettings);
