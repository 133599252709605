import React from 'react';
import { __ } from '../../Utils';
import B2CDiscounts from '../B2CDiscount';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';

class Discounts extends React.Component {
  render() {
    const { history } = this.props;

    return (
      <div>
        <ControllBar history={history} name={__('Zľavy - B2C')} />
        <B2CDiscounts />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Discounts);
