import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';
import SearchableSelect from '../SearchableSelect';
import { fetchGraphics } from '../../Utils/ApiSearchFunctions';

const DesktopButton = styled(Button)`
  margin-left: ${rem(10)};
  display: flex;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-right: ${rem(20)};
  margin-bottom: ${rem(4)};
  margin-top: ${rem(12)};
  min-width: ${rem(200)};
`;

const SelectWrapper = styled.div`
  max-width: ${rem(220)};
`;

class AddNewBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      multimedia_id: '',
    };
  }

  handleChange(e, field) {
    this.setState({
      [field]: e,
    });
  }

  async submit() {
    const { onSubmit } = this.props;
    const { value, multimedia_id } = this.state;

    await onSubmit(value, multimedia_id);

    this.setState({ value: '', multimedia_id: '' });
  }

  render() {
    return (
      <>
        <Row>
          <InputWrapper>
            <Input
              value={this.state.value || ''}
              placeholder={__('Nová značka')}
              onChange={(e) => this.handleChange(e.target.value, 'value')}
            />
          </InputWrapper>

          <DesktopButton
            loading={this.props.loading}
            primary
            small
            disabled={this.state.value.length < 1}
            onClick={() => this.submit()}
          >
            {__('Pridať')}
          </DesktopButton>
        </Row>
        {this.props.showSelectLogo && (
          <SelectWrapper>
            <SearchableSelect
              loadOptions={(query) => {
                return fetchGraphics(query);
              }}
              handleOnChange={(e) => this.handleChange(e, 'multimedia_id')}
              value={this.state.multimedia_id}
              placeholder={__('Logo značky')}
            />
          </SelectWrapper>
        )}
      </>
    );
  }
}

AddNewBrand.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AddNewBrand;
