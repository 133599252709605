import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeliveryTerms from '../../../Containers/DeliveryTerms';

// import MarketingMaterialDetail from '../../../Containers/MarketingMaterialDetail';

function DeliveryTermsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={DeliveryTerms} />
    </Switch>
  );
}

DeliveryTermsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default DeliveryTermsCard;
