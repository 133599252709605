import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Input, Button, Icon } from 'oxyrion-ui/lib';
import PropTypes from 'prop-types';
import { ColumnFlexBox, FlexBoxStart } from '../ReusableComponents';
import { __ } from '../../Utils';

const IconWrpper = styled.div`
  cursor: pointer;
  margin-left: auto;
  padding-right: 0;
  ${({ theme }) => theme.media.m`
  padding-right: 2%;
  `};
`;

const CustomInput = styled(Input)`
  width: ${({ right }) => (right ? '80%' : '90%')};
  margin: ${rem(5)} 0;
  margin-right: ${({ right }) => (right ? `${rem(30)}` : '0')};
`;

const CustomFlexBoxStart = styled(FlexBoxStart)`
  width: 100%;
`;

class MultipleInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      value: '',
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (Array.isArray(value)) {
      this.setState({
        values: this.props.value,
      });
    }
  }

  changeValue(value) {
    this.setState({ value });
  }
  addValue() {
    const { values, value } = this.state;
    const { onChange } = this.props;
    values.push(value);
    this.setState({ values, value: '' });
    onChange(values);
  }

  editValue(index, value) {
    const { values } = this.state;
    const { onChange } = this.props;
    values[index] = value;
    this.setState({ values });
    onChange(values);
  }

  removeValue(index) {
    const { values } = this.state;
    const { onChange } = this.props;
    values.splice(index, 1);
    this.setState({ values });
    onChange(values);
  }

  render() {
    const { values } = this.state;
    const { disabled, placeholder } = this.props;

    return (
      <ColumnFlexBox>
        {values.map((v, index) => (
          <CustomFlexBoxStart key={`${index.toString()}`}>
            <CustomInput
              value={v}
              onChange={e => this.editValue(index, e.target.value)}
              placeholder={placeholder}
            />
            <IconWrpper onClick={() => this.removeValue(index)}>
              <Icon name="close" size="s" />
            </IconWrpper>
          </CustomFlexBoxStart>
        ))}
        <CustomFlexBoxStart>
          <CustomInput
            placeholder={placeholder}
            disabled={disabled}
            right
            value={this.state.value}
            onChange={e => this.changeValue(e.target.value)}
          />
          <Button
            disabled={disabled}
            primary
            small
            onClick={e => this.addValue(e.target.value)}
            icon="plus"
          >
            {__('Pridať')}
          </Button>
        </CustomFlexBoxStart>
      </ColumnFlexBox>
    );
  }
}

MultipleInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};

MultipleInput.defaultProps = {
  disabled: false,
  placeholder: '',
  value: [],
};

export default MultipleInput;
