import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import References from '../../../Containers/References';
import ReferenceDetail from '../../../Containers/ReferenceDetail';

function ReferencesCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={ReferenceDetail} />
      <Route path={`${match.url}`} component={References} />
    </Switch>
  );
}

ReferencesCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ReferencesCard;
