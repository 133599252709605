import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EshopNotifications from '../../../Containers/EshopNotifications';
import EshopNotificationDetail from '../../../Containers/EshopNotificationDetail';

// import MarketingMaterialDetail from '../../../Containers/MarketingMaterialDetail';

function EshopNotificationsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={EshopNotificationDetail} />
      <Route path={`${match.url}`} component={EshopNotifications} />
    </Switch>
  );
}

EshopNotificationsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default EshopNotificationsCard;
