import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import playImg from './playImg.png';
import { translate, __ } from '../../../Utils';

const StyledImage = styled.img`
  width: ${rem(500)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayImage = styled.img`
  width: ${rem(80)};
  position: absolute;
  opacity: 0.5;
  z-index: 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(500)};
  margin: ${rem(30)}
  position: relative;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.div`
  position: absolute;
  top: ${rem(30)};
  color: ${props => props.color && props.color};
  font-size: ${rem(24)};
  font-weight: 500;
  left: ${rem(20)};
  z-index: 2;
`;

const Perex = styled.div`
  position: absolute;
  top: ${rem(90)};
  color: ${props => props.color && props.color};
  z-index: 2;
  max-width: ${rem(380)};
  right: ${rem(45)};
  text-align: end;
`;

const ReadMore = styled.div`
  position: absolute;
  color: ${props => props.color && props.color};
  right: 30px;
  bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

class ArticleCard extends React.Component {
  render() {
    const { video_label, name, perex, thumbnail, color, firm } = this.props;

    return (
      <Wrapper>
        <Title color={color}>
          {translate(firm, name.translations, name.value)}
        </Title>
        <Perex color={color}>
          {translate(firm, perex.translations, perex.value)}
        </Perex>
        {video_label && <PlayImage src={playImg} />}
        {thumbnail && (
          <StyledImage
            src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/500x0/${thumbnail.fullPath}`}
          />
        )}
        <ReadMore color={color}>{__('Čítať viac')}</ReadMore>
      </Wrapper>
    );
  }
}

ArticleCard.propTypes = {
  name: PropTypes.shape({
    value: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  perex: PropTypes.shape({
    value: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  text: PropTypes.shape({
    value: PropTypes.string,
    translations: PropTypes.arrayOf({
      lang: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  color: PropTypes.string,
  thumbnail: PropTypes.shape({
    fullPath: PropTypes.string,
  }),
  video_label: PropTypes.bool,
  firm: PropTypes.string,
};

ArticleCard.defaultProps = {
  name: null,
  perex: null,
  text: null,
  thumbnail: null,
  video_label: false,
  firm: 'SK',
  color: 'black',
};

export default ArticleCard;
