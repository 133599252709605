import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Label } from 'oxyrion-ui/lib';
import { Table, Tbody, Tr, Td, Thead, Th } from 'oxyrion-ui/lib/Table';
import { rem } from 'polished';
import uniqid from 'uniqid';
import { renderLinkOnNewTab, __ } from '../../Utils';
import { AnimatedFormMessageWrapper } from '../ReusableComponents';
import { connect } from '../../Store';
import SearchableSelect from '../SearchableSelect';
import ConfirmDialog from '../ConfirmDialog';
import API2 from '../../API2';

const Wrapper = styled.div`
  padding: ${rem(10)};
  width: 90%;
  margin: auto;
`;

class CustomersOwnerShipMove extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromUserSelect: null,
      fromUserSelectError: null,
      toUserSelect: null,
      toUserSelectError: null,
      showCustomersTable: false,
      ownershipChangeLoading: false,
      error: null,
    };
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 15,
      }).then(res => {
        return res.users.map(item => ({
          value: item._id,
          label: item.display_name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async handleOwnershipCustomerChange() {
    const { firm, fromUser, data, onClose } = this.props;
    const { fromUserSelect, toUserSelect } = this.state;

    if (data.total === 0) {
      return;
    }

    if (!fromUser && !fromUserSelect) {
      this.setState({
        fromUserSelectError: __('Povinny údaj'),
      });

      return;
    }

    if (!toUserSelect) {
      this.setState({
        toUserSelectError: __('Povinny údaj'),
      });
    } else {
      this.setState({ ownershipChangeLoading: true });
      try {
        await API2.putCustomersOwnershipsAction(
          firm,
          fromUser.value || fromUserSelect.value,
          {
            force: true,
            body: {
              toUserId: toUserSelect.value,
            },
          },
        );
        onClose(true);
        this.setState({ ownershipChangeLoading: false });
      } catch (e) {
        this.setState({
          ownershipChangeLoading: false,
          // error: __('Vyskytla sa neočakavana chyba'),
        });
      }
    }
  }

  handleParamChange(field, e) {
    let { fromUserSelect, toUserSelect } = this.state;

    if (field === 'fromUser') {
      fromUserSelect = e;
    } else if (field === 'toUser') {
      toUserSelect = e;
    }

    this.setState({ fromUserSelect, toUserSelect });
  }

  render() {
    const { data, firm, fromUser, onClose } = this.props;
    const {
      fromUserSelect,
      toUserSelect,
      showCustomersTable,
      ownershipChangeLoading,
      error,
      toUserSelectError,
      fromUserSelectError,
    } = this.state;

    return (
      <Wrapper>
        <Label>{__('Z používateľa: ')}</Label>
        <SearchableSelect
          value={fromUser || fromUserSelect}
          loadOptions={query => this.fetchUsers(query)}
          placeholder={__('Vyhľadať používateľa')}
          disabled={fromUser && fromUser.value}
          handleOnChange={e => this.handleParamChange('fromUser', e)}
          error={fromUserSelectError}
        />
        <Label>{__('Na používateľa: ')}</Label>
        <SearchableSelect
          value={toUserSelect}
          loadOptions={query => this.fetchUsers(query)}
          placeholder={__('Vyhľadať používateľa')}
          handleOnChange={e => this.handleParamChange('toUser', e)}
          error={toUserSelectError}
        />

        <ConfirmDialog
          message={__(
            `Zmena sa dotke ${data.total} zákaznikov. Preniesť zákazníkov ?`,
          )}
          onDismiss={() => onClose()}
          onConfirm={() => this.handleOwnershipCustomerChange()}
          loading={ownershipChangeLoading}
          error={error}
        />

        <Button
          onClick={() =>
            this.setState({
              showCustomersTable: !showCustomersTable,
            })
          }
          primary
          small
          disabled={data.total === 0}
        >
          {showCustomersTable
            ? __('Skryť zoznam zákazníkov')
            : __('Zobraziť zoznam zákazníkov')}
        </Button>

        <AnimatedFormMessageWrapper display={showCustomersTable}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Meno zákazníka')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.customers.map(row => (
                <Tr key={uniqid()}>
                  <Td>
                    {renderLinkOnNewTab(
                      `${process.env.REACT_APP_CRM_DOMAIN}/${firm}/customers/${row._id}`,
                      `${row.name || '-'}`,
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AnimatedFormMessageWrapper>
      </Wrapper>
    );
  }
}

CustomersOwnerShipMove.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    customers: PropTypes.object,
  }).isRequired,
  firm: PropTypes.string,
  fromUser: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

CustomersOwnerShipMove.defaultProps = {
  firm: 'SK',
};

const mapStateToProps = state => {
  return {
    firm: state.firm,
  };
};

export default connect(mapStateToProps)(CustomersOwnerShipMove);
