import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Input, Icon, Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';
import AddNewBrand from '../../Components/AddNewBrand';
import SearchableSelect from '../../Components/SearchableSelect';
import { fetchGraphics } from '../../Utils/ApiSearchFunctions';

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  padding: ${rem(4)};
  margin-bottom: ${rem(100)};
`;

const Col = styled.div`
  margin-right: ${rem(24)};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  margin-left: 4px;
  font-weight: 700;
`;

const DeleteRow = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(10)};
  background: #eb4142;
  border-radius: ${rem(2)};
  :hover {
    background: red;
  }
`;

const RowWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

const SelectWrapper = styled.div`
  max-width: ${rem(220)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-bottom: ${rem(2)};
  margin-right: ${rem(20)};
  min-width: ${rem(200)};
`;

class BrandsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      loading: true,
      brands: [],
      subBrands: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const brands = await API2.getBrandsAction(this.props.firm);
      const subBrands = await API2.getSubBrandsAction(this.props.firm);
      this.setState({
        brands: brands.fullBrands || [],
        subBrands: subBrands.fullBrands || [],
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Značky sa nenašieli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteRow(id) {
    try {
      await API2.deleteBrandAction(this.props.firm, id);
      this.fetchData();
    } catch (e) {
      this.setState({
        error: __('Značku sa nepodarilo odstrániť'),
      });
    }
  }

  async deleteSubRow(id) {
    try {
      await API2.deleteSubBrandAction(this.props.firm, id);
      this.fetchData();
    } catch (e) {
      this.setState({
        error: __('Podskupinu sa nepodarilo odstrániť'),
      });
    }
  }

  async addBrand(newBrand, multimedia_id) {
    this.setState({
      saveLoading: true,
    });
    try {
      await API2.postBrandAction(this.props.firm, {
        body: {
          brand: newBrand,
          multimedia_id: multimedia_id.value,
        },
      });

      this.fetchData();

      this.setState({
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Značku sa nepodarilo uložiť'),
      });
    }
  }

  async addSubBrand(newBrand) {
    this.setState({
      saveLoading: true,
    });
    try {
      await API2.postSubBrandAction(this.props.firm, {
        data: {
          brand: newBrand,
        },
      });

      this.fetchData();

      this.setState({
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Podskupinu sa nepodarilo uložiť'),
      });
    }
  }

  async updateBrand(id, data) {
    try {
      await API2.putBrandAction(this.props.firm, id, {
        body: data,
      });
      this.fetchData();
    } catch (e) {
      this.setState({
        error: __('Značku sa nepodarilo upraviť'),
      });
    }
  }

  handleMultimediaChange(e, rowData) {
    const { _id, ...data } = rowData;

    const newData = Object.assign(data, {
      multimedia_id: e.value,
    });

    this.updateBrand(_id, newData);
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Skupiny, podskupiny')} />;
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      brands,
      loading,
      subBrands,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <Col>
            <Title>{__('Skupiny')}</Title>
            {brands.map(item => {
              return (
                <RowWrapper>
                  <Row key={item.value}>
                    <InputWrapper key={item._id}>
                      <Input value={item.brand} disabled />
                    </InputWrapper>

                    <DeleteRow onClick={() => this.deleteRow(item._id)}>
                      <Icon name="delete" color="white" />
                    </DeleteRow>
                  </Row>
                  <SelectWrapper>
                    <SearchableSelect
                      loadOptions={query => {
                        return fetchGraphics(query);
                      }}
                      handleOnChange={e => this.handleMultimediaChange(e, item)}
                      value={item.multimedia_id}
                      placeholder={__('Logo značky')}
                    />
                  </SelectWrapper>
                </RowWrapper>
              );
            })}
            <AddNewBrand
              loading={saveLoading}
              onSubmit={(name, multimedia_id) =>
                this.addBrand(name, multimedia_id)
              }
              showSelectLogo={true}
            />
          </Col>

          <Col>
            <Title>{__('Podskupiny')}</Title>
            {subBrands.map(item => {
              return (
                <Row key={item.value}>
                  <InputWrapper key={item._id}>
                    <Input value={item.brand} disabled />
                  </InputWrapper>

                  <DeleteRow onClick={() => this.deleteSubRow(item._id)}>
                    <Icon name="delete" color="white" />
                  </DeleteRow>
                </Row>
              );
            })}
            <AddNewBrand
              loading={saveLoading}
              onSubmit={item => this.addSubBrand(item)}
            />
          </Col>
        </Wrapper>
      </React.Fragment>
    );
  }
}

BrandsSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BrandsSettings);
