import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Loader, Message, Label, Input, Select } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import { LANGS } from '../../ContentConfig/languagesArray';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(15)};
`;

const Row = styled.div`
  padding: ${rem(4)};
  display: flex;
  background: ${props => (props.grey ? '#efefef' : 'white')};
`;

const Letter = styled.div`
  width: ${rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.selected ? '#ee6500' : 'black')};
  cursor: pointer;
  &:hover {
    background: #eee;
  }
`;

const LettersWrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(12)};
`;

const StyledInput = styled(Input)`
  width: ${rem(300)};
`;

const InputWrapper = styled.div`
  width: ${rem(90)};
  margin-bottom: ${rem(8)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
  width: ${rem(400)};
`;

const Space = styled.div`
  width: ${rem(40)};
  height: ${rem(12)};
`;

const aplications = ['CRM', 'B2B', 'LANDING_PAGE', 'EOM'];

class TransaltionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTranslation: {
        label: '',
        value: '',
      },
      firstLetters: [],
      data: {
        app: '',
        lang: '',
        translations: [],
      },
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);

    if (id !== 'new') {
      const data = await this.fetchData(id);

      const translations = (data.translations
        ? Object.keys(data.translations).map(key => {
            const value = data.translations[key];

            return {
              label: key,
              value,
            };
          })
        : []
      ).sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }

        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });

      const firstLetters = translations
        .filter(t => t.label && t.label[0])
        .map(t => t.label[0].toUpperCase())
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      this.setState({
        selectedLetter: firstLetters[0] || 'A',
        firstLetters,
        data: Object.assign({}, data, {
          translations,
        }),
      });
    }

    this.setState({
      loading: false,
    });
  }

  async fetchData(id) {
    try {
      const paintSystem = await AdminAPI.getTranslationAction(id);
      return paintSystem;
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __(
              'Na zobrazenie náterového systému nemáte potrebné práva.',
            ),
          });
          break;
        case 404:
          this.setState({
            error: __('Náterový systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
    return {};
  }

  checkData() {
    const valid = true;

    return valid;
  }

  handleTransChange(key, value) {
    const { data } = this.state;

    data.translations.find(t => t.label === key).value = value;

    this.setState({
      data,
    });
  }

  addNewTranslation() {
    const { newTranslation, data } = this.state;

    if (data.translations.find(t => t.label === newTranslation.label)) {
      this.setState({
        error: __('Preklad sa už nachádza v tabuľke'),
        newTranslation: {
          value: '',
          label: '',
        },
      });

      window.scrollTo(0, 0);
      return;
    }

    data.translations.push(newTranslation);

    const firstLetters = data.translations
      .filter(t => t.label && t.label[0])
      .map(t => t.label[0].toUpperCase())
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

    this.setState({
      data,
      showAddNewTranslation: false,
      firstLetters,
      selectedLetter: newTranslation.label[0].toUpperCase(),
      newTranslation: {
        value: '',
        label: '',
      },
    });
  }

  translateAppName(app) {
    if (app === 'B2B') {
      return 'Portal';
    }
    if (app === 'LANDING_PAGE') {
      return 'Landing page';
    }

    if (app === 'CRM') {
      return 'Crm';
    }

    if (app === 'EOM') {
      return 'Eom';
    }

    return app;
  }

  handleDataChange(field, value) {
    const { data } = this.state;

    data[field] = value;

    this.setState({
      data,
    });
  }

  async saveTranslations() {
    const { data } = this.state;
    const { history, firm } = this.props;

    this.setState({
      saveLoading: true,
      success: undefined,
      error: undefined,
    });
    if (this.checkData()) {
      const dataToSend = Object.assign({}, data);

      const transObject = {};

      dataToSend.translations.forEach(d => {
        transObject[d.label] = d.value;
      });

      dataToSend.translations = transObject;
      try {
        let result = data;
        const params = {};
        params.body = dataToSend;
        if (data._id) {
          result = await AdminAPI.putTranslationAction(data._id, params);
        } else {
          result = await AdminAPI.postTranslationAction(params);
          if (result && getIdFromProps(this.props) === 'new') {
            history.push(`/${firm}/translations/${result._id}`);
          }
        }

        this.setState({
          // data: result,
          success: __('Preklady boli úspešne uložené'),
        });
      } catch (e) {
        this.setState({
          error: __('Pri ukladaní dát sa vyskytla chyba'),
        });
      }
    }
    this.setState({
      saveLoading: false,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;
    return (
      <React.Fragment>
        <ControllBar
          history={history}
          name={`${__('Preklad')} - ${this.translateAppName(
            data.app || '',
          )} ${data.lang || ''}`}
        >
          <ControlBarButton
            primary
            small
            onClick={() =>
              this.setState({
                showAddNewTranslation: true,
              })
            }
            icon="plus"
          >
            {__('Pridať preklad')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      error,
      success,
      data,
      loading,
      saveLoading,
      showAddNewTranslation,
      newTranslation,
      firstLetters,
      selectedLetter,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <PopUpWrapper
          display={showAddNewTranslation}
          customWidth={800}
          message={__('Nový preklad')}
          onClose={() =>
            this.setState({
              showAddNewTranslation: false,
              newTranslation: {
                label: '',
                value: '',
              },
            })
          }
        >
          <Row>
            <StyledInput
              value={newTranslation.label}
              placeholder={__('SK hodnota použitá v aplikácií')}
              onChange={e => {
                newTranslation.label = e.target.value;
                this.setState(newTranslation);
              }}
            />
            <Space />

            <StyledInput
              value={newTranslation.value}
              placeholder={__('Pridajte preklad')}
              onChange={e => {
                newTranslation.value = e.target.value;
                this.setState(newTranslation);
              }}
            />
          </Row>
          <Row>
            <Button
              disabled={!newTranslation.label || !newTranslation.value}
              primary
              small
              onClick={() => this.addNewTranslation()}
            >
              {__('Vytvoriť')}
            </Button>
          </Row>
        </PopUpWrapper>

        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>
        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <Row>
            <InputWrapper style={{ width: rem(230) }}>
              <Label>{__('Aplikácia')}</Label>
              <Select
                style={{
                  width: rem(200),
                }}
                disabled={getIdFromProps(this.props) !== 'new'}
                size="s"
                onChange={e => {
                  this.handleDataChange('app', e.target.value);
                }}
              >
                <option value={null} selected={!data.app}>
                  -
                </option>
                {aplications.map(i => (
                  <option key={i} value={i} selected={i === data.app}>
                    {this.translateAppName(i)}
                  </option>
                ))}
              </Select>
            </InputWrapper>
            <Space style={{ width: rem(12) }} />
            <InputWrapper>
              <Label>{__('Jazyk')}</Label>
              <Select
                style={{
                  width: rem(100),
                }}
                size="s"
                disabled={getIdFromProps(this.props) !== 'new'}
                onChange={e => {
                  this.handleDataChange('lang', e.target.value);
                }}
              >
                <option value={null} selected={!data.lang}>
                  -
                </option>
                {LANGS.map(i => (
                  <option key={i} value={i} selected={i === data.lang}>
                    {i}
                  </option>
                ))}
              </Select>
            </InputWrapper>
          </Row>
          <Space />

          <LettersWrapper>
            {firstLetters.map(l => (
              <Letter
                onClick={() =>
                  this.setState({
                    selectedLetter: l,
                  })
                }
                selected={l === selectedLetter}
              >
                {l}
              </Letter>
            ))}
          </LettersWrapper>

          {data.translations
            .filter(t => t.label.toUpperCase().startsWith(selectedLetter))
            .map((r, i) => (
              <Row grey={i % 2 === 0}>
                <StyledLabel>{r.label}</StyledLabel>
                <StyledInput
                  onChange={e =>
                    this.handleTransChange(r.label, e.target.value)
                  }
                  value={r.value}
                  placeholder={__('Pridajte preklad')}
                />
              </Row>
            ))}
        </MainWrapper>

        <ButtonRelativeWrapper>
          <Button
            onClick={() => this.saveTranslations()}
            primary
            loading={saveLoading}
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

TransaltionDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TransaltionDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TransaltionDetail);
