import { __ } from '../Utils';

export default [
  {
    name: __('Meno'),
    clickable: false,
  },
  {
    name: __('Priezvisko'),
    clickable: false,
  },
  {
    name: __('Email'),
    clickable: false,
  },
  {
    name: __('Mobil'),
    clickable: false,
  },
  {
    name: __('Spoločnosť'),
    clickable: false,
  },
  {
    name: __('Mesto'),
    clickable: false,
  },

  {
    name: __('Vlastník'),
    clickable: false,
  },
  {
    name: __(''),
    clickable: false,
    otherProps: { maxWidth: 100 },
  },
  {
    name: __(''),
    clickable: false,
    otherProps: { maxWidth: 40 },
  },
];
