import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Checkbox } from 'oxyrion-ui/lib';
import FileInput2 from '../FileInput';
import { __ } from '../../Utils';
import { fetchMultimedia } from '../../Utils/ApiSearchFunctions';
import SearchableSelect from '../SearchableSelect';

const Wrapper = styled.div``;
const MultimediaRow = styled.div`
  padding: ${rem(16)};
  border-bottom: ${rem(1)} solid black;
`;

const Label = styled.div`
  font-size: ${rem(12)};
  font-weight: bold;
  margin-right: ${rem(8)};
`;
const Value = styled.div`
  font-size: ${rem(12)};
`;

const Name = styled.a`
  font-size: ${rem(12)};
  color: ${props => props.theme.color.primary};
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(8)};
`;

const FileWrapper = styled.div``;

const ImageWrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DeleteImageWrapper = styled.div`
  font-size: 12px;
  color: red;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const AddImageRow = styled.div`
  padding: ${rem(4)};
  width: ${rem(500)};
`;

const photoBasePath = `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0`;

class MultimediaImagesWidget extends React.Component {
  state = {
    multimediumToAdd: null,
  };

  handleOnChange(e) {
    this.props.onMultimediumAdd(e);
  }

  render() {
    const { multimedia, titleImage, changeMainImage, firm } = this.props;
    const { multimediumToAdd } = this.state;
    const { variant } = this.props;

    return (
      <Wrapper>
        {multimedia.map(m => (
          <MultimediaRow>
            <Row>
              <Label>{__('Názov:')}</Label>
              <Name
                href={`${process.env.REACT_APP_ADMIN_UI_DOMAIN}/${firm}/multimedia/${m._id}`}
                target="_blank"
              >
                {m.name}
              </Name>
            </Row>
            <Row>
              <Label>{__('Značka:')}</Label>
              <Value>{m.brand}</Value>
            </Row>
            <Row>
              <Label>{__('Obrázky:')}</Label>
            </Row>
            <Row>
              <Label>{__('Skryť:')}</Label>
              <Checkbox
                checked={variant.hide_multimedia || false}
                onChange={() =>
                  this.props.hideMultimedia(
                    variant._id,
                    !variant.hide_multimedia,
                  )
                }
              />
            </Row>
            <Row>
              <FileWrapper>
                <ImageWrapper>
                  <FileInput2
                    disabled
                    showDate={false}
                    files={m.files.map(f =>
                      Object.assign(f.value, { name: `${m.name} - ${f.lang}` }),
                    )}
                    acceptedFiles="image/*"
                    imagePath={photoBasePath}
                  />
                </ImageWrapper>
              </FileWrapper>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
              {changeMainImage && (
                <CheckboxWrapper>
                  <Checkbox
                    checked={titleImage === m._id}
                    onChange={() => changeMainImage(m._id)}
                  />
                  <Label>{__('Titulný obrázok')}</Label>
                </CheckboxWrapper>
              )}

              <DeleteImageWrapper
                onClick={() => this.props.removeMultimedium(m._id)}
              >
                {__('Odobrať obrázok')}
              </DeleteImageWrapper>
            </Row>
          </MultimediaRow>
        ))}
        <AddImageRow>
          <SearchableSelect
            value={multimediumToAdd}
            loadOptions={query => {
              return fetchMultimedia(
                query,
                multimedia.map(m => m._id),
              );
            }}
            placeholder={__('Pridať ďalší obrázok obrázok')}
            handleOnChange={e => this.handleOnChange(e)}
          />
        </AddImageRow>
      </Wrapper>
    );
  }
}

MultimediaImagesWidget.propTypes = {
  multimedia: PropTypes.arrayOf({}),
  titleImage: PropTypes.string,
  changeMainImage: PropTypes.func,
  onMultimediumAdd: PropTypes.func,
  removeMultimedium: PropTypes.func,
  firm: PropTypes.string,
  variant: PropTypes.shape({}),
  hideMultimedia: PropTypes.func,
};

MultimediaImagesWidget.defaultProps = {
  multimedia: [],
  titleImage: '',
  changeMainImage: null,
  onMultimediumAdd: () => {},
  removeMultimedium: () => {},
  firm: 'SK',
  variant: {},
  hideMultimedia: () => {},
};

export default MultimediaImagesWidget;
