import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Select from 'oxyrion-ui/lib/Select';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Textarea,
} from 'oxyrion-ui/lib';
import productsCanals from '../../ContentConfig/productsCanals';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import SearchableSelect from '../../Components/SearchableSelect';
import FileInput2 from '../../Components/FileInput';
import MetaDescriptionTextArea from '../../Components/MetaDescriptionTextArea';
import API2 from '../../API2';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${rem(20)};
  max-width: ${rem(500)};
  margin-bottom: ${rem(200)};
`;

const InputWrapper = styled.div`
  width: ${rem(450)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(350)};
`;

const CustomTextArea = styled(Textarea)`
  width: 96%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

const ChannelName = styled.div`
  font-size: 14px;
`;

const photoBasePath = `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0`;

class FilterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      categories: [],
      activeLang: 'SK',
      data: {
        name: '',
      },
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      this.setState({ loading: true });
      await this.fetchMaterialData(id);
    }

    this.fetchCodelists();
  }

  async fetchCodelists() {
    try {
      const result = await AdminAPI.getCodelist('marketing_material_types');

      this.setState({
        categories: result.codelist || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: `${item._id} - ${item.Description}`,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchMaterialData(id) {
    try {
      const data = await AdminAPI.getMarketingMaterialAction(id);

      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie materiálu nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Materiál sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchCodelist(id) {
    let { fetchedCodelistsForParams } = this.state;

    if (
      fetchedCodelistsForParams &&
      fetchedCodelistsForParams.find(f => f._id === id)
    ) {
      return;
    }

    try {
      const result = await AdminAPI.getCodelist(id);

      const codelist = {
        _id: id,
        items: result.codelist || [],
      };

      if (!fetchedCodelistsForParams) {
        fetchedCodelistsForParams = [];
      }
      fetchedCodelistsForParams.push(codelist);
      this.setState({
        fetchedCodelistsForParams,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async deleteList() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteMarketingMaterialAction(getIdFromProps(this.props));
      history.push(`/${firm}/marketing-materials`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri mazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    if (!data.category || data.category.length === 0 || data.category === '-') {
      isValid = false;
    }

    return isValid;
  }

  async saveData() {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data)) {
      try {
        if (getIdFromProps(this.props) === 'new') {
          const params = {};
          params.body = data;

          const result = await AdminAPI.postMarketingMaterialAction(params);
          this.props.history.push(
            `/${this.props.firm}/marketing-materials/${result._id}`,
          );
          this.setState({
            data: result,
            success: __('Dáta úspešne uložené'),
            saveLoading: false,
          });
        } else {
          const params = {};
          params.body = data;
          const result = await AdminAPI.putMarketingMaterialAction(
            getIdFromProps(this.props),
            params,
          );
          this.setState({
            data: result,
            success: __('Dáta úspešne uložené'),
            saveLoading: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  handleChange(field, value) {
    const { data } = this.state;

    data[field] = value;

    this.setState(data);
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    if (!data[field]) {
      data[field] = [];
    }

    if (!data[field].find(d => d.lang === lang)) {
      data[field].push({
        lang,
        value,
      });
    } else {
      data[field].find(d => d.lang === lang).value = value;
    }

    this.setState({
      data,
    });
  }

  handleProductChange(value) {
    const { data } = this.state;

    if (!data.product) {
      data.product = {};
    }

    data.product = value;

    this.setState({
      data,
    });
  }

  handlePublicationChange(value) {
    const { data } = this.state;

    if (!data.publication) {
      data.publication = [];
    }

    if (!data.publication.find(p => p.channel === value)) {
      data.publication.push({
        channel: value,
        value: true,
      });
    } else {
      data.publication.find(
        p => p.channel === value,
      ).value = !data.publication.find(p => p.channel === value);
    }

    this.setState({
      data,
    });
  }

  async remove() {
    const { data, activeLang } = this.state;
    try {
      await AdminAPI.deleteMarketingMaterialsImageAction(data._id, activeLang);

      data.images = data.images.filter(i => i.lang !== activeLang);

      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async upload(accepted) {
    const { data, activeLang } = this.state;
    if (accepted) {
      if (getIdFromProps(this.props) === 'new') {
        await this.saveData();
      }

      accepted.map(async i => {
        const reader = new FileReader();
        reader.readAsDataURL(i);
        reader.onload = () => {
          const newFile = {
            name: i.name,
            mimeType: i.type,
            base64: reader.result.replace('data:application/pdf;base64,', ''),
          };

          this.addImage({ value: newFile, lang: activeLang });

          this.setState({
            data,
          });
        };
        reader.onerror = error => {
          console.log('Error: ', error);
        };
        return i;
      });
    }
  }

  async addImage(image) {
    const { data } = this.state;

    try {
      const params = {};
      params.body = image;
      const images = await AdminAPI.postMarketingMaterialImageAction(
        data._id,
        params,
      );
      data.images = images;
      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  getTranslatedData(data, lang) {
    if (!data) {
      return '';
    }

    const obj = data.find(d => d.lang === lang);
    return obj ? obj.value : '';
  }

  getImageForLang(images, lang, hasSame) {
    if (!images) {
      return [];
    }
    const langLocal = hasSame ? 'SK' : lang;

    const image = images.find(i => i.lang === langLocal);
    if (image) {
      return [image.value];
    }
    return [];
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Marketingový materiál')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      saveLoading,
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
      categories,
      activeLang,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť marketingový materiál ?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteList()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(280) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{ width: rem(300) }}>
                  <StyledLabel>{__('Kategória*')}</StyledLabel>
                  <Select
                    size="s"
                    value={data.category}
                    onChange={e => {
                      this.handleChange('category', e.target.value);
                    }}
                  >
                    <option
                      selected={!data.category || data.category === ''}
                      value={null}
                    >
                      -
                    </option>

                    {categories ? (
                      categories.map(c => {
                        return (
                          <option
                            selected={c.value === data.category}
                            value={c.value}
                          >
                            {c.label}
                          </option>
                        );
                      })
                    ) : (
                      <option>{__('Neobsahuje možnosti na výber')}</option>
                    )}
                  </Select>
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Publikácia')}</StyledLabel>
                  <Row style={{ margin: 0 }}>
                    {productsCanals.map(c => (
                      <CheckboxWrapper>
                        <Checkbox
                          onChange={() => this.handlePublicationChange(c)}
                          checked={
                            data.publication &&
                            data.publication.find(p => p.channel === c) &&
                            data.publication.find(p => p.channel === c).value
                          }
                        />
                        <ChannelName>{c}</ChannelName>
                      </CheckboxWrapper>
                    ))}
                  </Row>
                </InputWrapper>
              </Row>
            </SettingsBox>
          </Row>
          <Row>
            <LanguageSwitch
              onChange={lang => {
                this.setState({
                  activeLang: lang,
                });
                return true;
              }}
              activeId={activeLang}
            />
          </Row>
          <Row>
            <SettingsBox addDisabled>
              <Row>
                <InputWrapper style={{ width: rem(430) }}>
                  <StyledLabel>{__('Názov v lokálnom jazyku')}</StyledLabel>
                  <StyledInput
                    value={this.getTranslatedData(
                      data.name_translations,
                      activeLang,
                    )}
                    placeholder={__('Názov')}
                    onChange={e =>
                      this.handleLocalChange(
                        'name_translations',
                        activeLang,
                        e.target.value,
                      )
                    }
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Krátky popis')}</StyledLabel>
                  <CustomTextArea
                    onChange={e =>
                      this.handleLocalChange(
                        'short_description_translations',
                        activeLang,
                        e.target.value,
                      )
                    }
                    value={this.getTranslatedData(
                      data.short_description_translations,
                      activeLang,
                    )}
                    placeholder={__('Krátky popis')}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Rozsiahly popis')}</StyledLabel>
                  <CustomTextArea
                    onChange={e =>
                      this.handleLocalChange(
                        'long_description_translations',
                        activeLang,
                        e.target.value,
                      )
                    }
                    value={this.getTranslatedData(
                      data.long_description_translations,
                      activeLang,
                    )}
                    placeholder={__('Rozsiahly popis')}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Meta title')}</StyledLabel>
                  <CustomTextArea
                    onChange={e =>
                      this.handleLocalChange(
                        'meta_title_translations',
                        activeLang,
                        e.target.value,
                      )
                    }
                    value={this.getTranslatedData(
                      data.meta_title_translations,
                      activeLang,
                    )}
                    placeholder={__('Meta title')}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Meta description')}</StyledLabel>
                  <MetaDescriptionTextArea
                    onChange={e =>
                      this.handleLocalChange(
                        'meta_description_translations',
                        activeLang,
                        e.target.value,
                      )
                    }
                    value={this.getTranslatedData(
                      data.meta_description_translations,
                      activeLang,
                    )}
                    placeholder={__('Meta description')}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Produkt')}</StyledLabel>
                  <SearchableSelect
                    value={data.product}
                    loadOptions={query => this.fetchProducts(query)}
                    placeholder={__('Vyhľadať produkt')}
                    handleOnChange={e => this.handleProductChange(e)}
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
          </Row>
          <Row>
            <SettingsBox addDisabled title={__('Obrázky')}>
              <Row>
                <InputWrapper>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={this.getTranslatedData(
                      data.image_alt_text_translations,
                      activeLang,
                    )}
                    placeholder={__('Alternatívny text')}
                    onChange={e =>
                      this.handleLocalChange(
                        'image_alt_text_translations',
                        activeLang,
                        e.target.value,
                      )
                    }
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Label>{__('Obrázok je rovnaký pre všetky krajiny')}</Label>
                    <Checkbox
                      onChange={() =>
                        this.handleChange('same_image', !data.same_image)
                      }
                      checked={data.same_image}
                    />
                  </div>
                </InputWrapper>
              </Row>
              <Row>
                <FileInput2
                  onDrop={newFiles => this.upload(newFiles)}
                  removeItem={fullPath => this.remove(fullPath)}
                  files={this.getImageForLang(
                    data.images,
                    activeLang,
                    data.same_image,
                  )}
                  acceptedFiles="image/*"
                  imagePath={photoBasePath}
                />
              </Row>
            </SettingsBox>
          </Row>
        </ContentWrapper>

        <ButtonRelativeWrapper>
          <Button loading={saveLoading} onClick={() => this.saveData()} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

FilterDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FilterDetail);
