import { __ } from '../Utils';

const campaignLabels = [
  {
    name: __('Akciová dodatočná zľava na všetko'),
    type: 'D',
    onlyMarketingList: false,
  },
  {
    name: __('Kampaň na vybrané produkty'),
    type: 'F',
    onlyMarketingList: false,
  },
  {
    name: __(
      'Dočasné zvýšenie základnej zľavy A pre zákazníka na vybrané produkty',
    ),
    type: 'K',
    onlyMarketingList: false,
  },
  {
    name: __('Kampaň výrobok k objednávke za 1 cent / 1 €'),
    type: 'G',
    onlyMarketingList: false,
  },
  {
    name: __('Kampaň kúp jeden výrobok a dostaneš iný so zľavou'),
    type: 'H',
    onlyMarketingList: false,
  },
  {
    name: __('Kampaň výrobok k objednávke zadarmo'),
    type: 'L',
    onlyMarketingList: false,
  },
  {
    name: __('Kampaň na vybrané produkty za minimálnu hodnotu, získaj zľavu'),
    type: 'I',
    onlyMarketingList: false,
  },
  {
    name: __('Kampaň na vybrané produkty za minimálnu hodnotu, získaj produkt'),
    type: 'J',
    onlyMarketingList: false,
  },
  {
    name: __('Kupónová kampaň'),
    type: 'M',
    onlyMarketingList: true,
  },
  {
    name: __('Kampaň n+1'),
    type: 'N',
    onlyMarketingList: false,
  },
  {
    name: __('Kupónová kampaň xFarby - Doprava zadarmo'),
    type: 'O',
  },
  {
    name: __('Kupónová kampaň xFarby - Všeobecná'),
    type: 'P',
  },
];

export default campaignLabels;
