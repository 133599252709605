import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table, Tbody, Tr, Td } from 'oxyrion-ui/lib/Table';
import { rem } from 'polished';
import uniqid from 'uniqid';
import { __ } from '../../Utils';
import { H3 } from '../ReusableComponents';

const BoldTd = styled(Td)`
  font-weight: bold;
`;

const Wrapper = styled.div`
  border-left: ${({ theme, left }) =>
    left && `solid ${rem(1)} ${theme.separatorColor}`};
  border-right: ${({ theme, right }) =>
    right && `solid ${rem(1)} ${theme.separatorColor}`};
  padding: ${rem(10)} 0;
  width: ${({ width }) => width || 'unset'};
  margin: ${({ margin }) => margin || 'unset'};
`;

const CustomH3 = styled(H3)`
  padding: ${rem(10)} ${rem(15)};
`;

const CustomTd = styled(Td)`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
  color: ${({ clickable, theme }) =>
    clickable ? theme.color.primary : 'unset'};
  :hover {
    text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'unset')};
  }
  height: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

class InfoTable extends React.Component {
  controlValue(value) {
    return value || value === 0 ? value : '-';
  }

  isClickable(item) {
    const { click } = this.props;
    return click || item.click;
  }

  controlClickHandler(item) {
    const { click } = this.props;
    if (click) {
      return click.handleOnClick();
    } else if (item && item.click) {
      return item.click();
    }

    return null;
  }

  render() {
    const { data, header, left, right, margin, width } = this.props;

    return (
      <Wrapper left={left} right={right} width={width} margin={margin}>
        <CustomH3>{__(header)}</CustomH3>
        <Table>
          <Tbody>
            {data.filter(r => !r.hidden).map(row => {
              return (
                <Tr key={uniqid()}>
                  <BoldTd>{row.name}</BoldTd>
                  <CustomTd
                    clickable={row.handleOnClick}
                    onClick={
                      row.handleOnClick ? () => row.handleOnClick() : null
                    }
                  >
                    {row.Component ? (
                      <row.Component />
                    ) : (
                      this.controlValue(row.value)
                    )}
                  </CustomTd>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Wrapper>
    );
  }
}

InfoTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    }),
  ).isRequired,
  header: PropTypes.string,
  click: PropTypes.shape({
    field: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func.isRequired,
  }),
  left: PropTypes.bool,
  right: PropTypes.bool,
  width: PropTypes.string,
  margin: PropTypes.string,
};

InfoTable.defaultProps = {
  click: null,
  header: '',
  left: false,
  right: false,
  width: 'unset',
  margin: 'unset',
};

export default InfoTable;
