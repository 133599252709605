import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Message, Input, Label, Button, Icon } from 'oxyrion-ui/lib';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import { connect } from '../../Store';
import { __, formatPrice } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';

const StyledInput = styled(Input)`
  width: ${rem(150)};
`;

const DeleteLine = styled(Td)`
  width: ${rem(20)};
`;

const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
`;

const ValueHolder = styled(Label)`
  width: ${rem(150)};
`;

const Wrapper = styled.div`
  padding: ${rem(8)};
  max-width: ${rem(600)};
`;

const InputWrapper = styled.div`
  margin-right: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(16)};
`;

class BonusesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bonuses: [],
    };
  }

  async componentDidMount() {
    await this.fetchBonuses();
  }

  setBonusForEdit(bonus) {
    this.setState({
      nameTemp: bonus.name,
      valueSKTemp: bonus.value_SK.amount,
      valueCZTemp: bonus.value_CZ.amount,
      vouchersCount: bonus.vouchers_count,
      personsCount: bonus.persons_count,
      bonusEditId: bonus._id,
    });
  }

  async fetchBonuses() {
    try {
      const bonuses = await AdminAPI.getBonusesAction({});
      this.setState({
        bonuses: bonuses.items,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Bonusy sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleSettingsChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  async deleteBonus() {
    try {
      this.setState({ deleteBonusLoading: true });
      const { bonuses, deleteBonusId } = this.state;

      if (deleteBonusId) {
        await AdminAPI.deleteBonusAction(deleteBonusId);
      }

      bonuses.splice(
        bonuses.indexOf(bonuses.find(f => f.id === deleteBonusId)),
        1,
      );
      this.setState({
        bonuses,
        deleteBonusLoading: false,
        deleteBonusId: false,
      });
    } catch (e) {
      if (e.resposne) {
        switch (e.response.type) {
          case 403:
            this.setState({
              error: __('Nemáte potrebné práva pre vymazanie bonusov.'),
            });
            break;
          case 404:
            this.setState({
              error: __('Zvolený bonus sa nenašiel'),
            });
            break;
          default:
            this.setState({
              error: __('Pri vymazávaní zľavy sa vykytla neočakávaná chyba.'),
            });
        }
      } else {
        this.setState({
          error: __('Pri vymazávaní zľavy sa vykytla neočakávaná chyba.'),
        });
      }
    }
  }

  async saveBonus() {
    try {
      this.setState({
        error: false,
        success: false,
        saveLoading: true,
      });

      const {
        bonuses,
        nameTemp,
        valueSKTemp,
        valueCZTemp,
        vouchersCount,
        personsCount,
        bonusEditId,
      } = this.state;

      const data = {};

      data[''] = {
        name: nameTemp,
        value_SK: {
          amount: Number(valueSKTemp),
          currency: 'EUR',
        },
        value_CZ: {
          amount: Number(valueCZTemp),
          currency: 'CZK',
        },
        vouchers_count: Number(vouchersCount),
        persons_count: Number(personsCount),
      };

      if (bonusEditId) {
        await AdminAPI.putBonusAction(bonusEditId, data);
      } else {
        await AdminAPI.postBonusAction(data);
      }

      this.setState(
        {
          success: __('Bonusy uložené'),
          saveLoading: false,
          bonuses,
          nameTemp: '',
          valueSKTemp: '',
          valueCZTemp: '',
          vouchersCount: '',
          personsCount: '',
          bonusEditId: undefined,
        },
        () => this.fetchBonuses(),
      );
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Bonusy')} />

        <PopUpWrapper
          small
          display={this.state.deleteBonusId}
          onClose={() =>
            this.setState({
              deleteBonusId: undefined,
            })
          }
          onEscClose={() =>
            this.setState({
              deleteBonusId: undefined,
            })
          }
        >
          <ConfirmDialog
            error={false}
            message={__('Prajete si vymazať bonus?')}
            onConfirm={() => this.deleteBonus()}
            onDismiss={() =>
              this.setState({
                deleteBonusId: undefined,
              })
            }
            loading={this.state.deleteBonusLoading}
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }

  render() {
    const {
      loading,
      error,
      success,
      nameTemp,
      valueCZTemp,
      valueSKTemp,
      vouchersCount,
      personsCount,
      saveLoading,
      bonuses,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <ValueHolder>{__('Názov')}</ValueHolder>
                </Th>
                <Th>
                  <ValueHolder>{__('Obrat SK')}</ValueHolder>
                </Th>
                <Th>
                  <ValueHolder>{__('Obrat CZ')}</ValueHolder>
                </Th>
                <Th>
                  <ValueHolder>{__('Počet poukazov')}</ValueHolder>
                </Th>
                <Th>
                  <ValueHolder>{__('Počet osôb')}</ValueHolder>
                </Th>
                <Th />
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {bonuses.map(b => (
                <Tr>
                  <Td>{b.name}</Td>
                  <Td>{formatPrice(b.value_SK)}</Td>
                  <Td>{formatPrice(b.value_CZ)}</Td>
                  <Td>{b.vouchers_count}</Td>
                  <Td>{b.persons_count}</Td>

                  <DeleteLine>
                    <IconWrapper
                      onClick={() =>
                        this.setState({
                          deleteBonusId: b._id,
                        })
                      }
                    >
                      <Icon name="close" color="black" />
                    </IconWrapper>
                  </DeleteLine>

                  <DeleteLine>
                    <IconWrapper onClick={() => this.setBonusForEdit(b)}>
                      <Icon name="edit" color="black" />
                    </IconWrapper>
                  </DeleteLine>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Row>
            <InputWrapper>
              <StyledInput
                value={nameTemp}
                placeholder={__(`Názov`)}
                onChange={e =>
                  this.handleSettingsChange('nameTemp', e.target.value)
                }
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                value={valueSKTemp}
                type="number"
                placeholder={__(`Obrat SK`)}
                onChange={e =>
                  this.handleSettingsChange('valueSKTemp', e.target.value)
                }
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                value={valueCZTemp}
                type="number"
                placeholder={__(`Obrat CZ`)}
                onChange={e =>
                  this.handleSettingsChange('valueCZTemp', e.target.value)
                }
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                value={vouchersCount}
                type="number"
                placeholder={__(`Počet poukazov`)}
                onChange={e =>
                  this.handleSettingsChange('vouchersCount', e.target.value)
                }
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                value={personsCount}
                type="number"
                placeholder={__(`Počet osôb`)}
                onChange={e =>
                  this.handleSettingsChange('personsCount', e.target.value)
                }
              />
            </InputWrapper>
            <Button
              loading={saveLoading}
              onClick={() => this.saveBonus()}
              small
              primary
            >
              {__('Uložiť')}
            </Button>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  }
}

BonusesSettings.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BonusesSettings);
