import React from 'react';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/newsletter';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  translateNewsletterStatus,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';

class NewsLetterList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'send_date' });
    this.headers = tableHeaders;
    this.showBulkOperations = false;
    this.segmentName = __('Newsletter');
    this.accessors = [
      'name',
      'marketing_list',
      'owner',
      'type',
      'status',
      'number_of_emails',
      'number_of_numbers',
      'number_of_times_opened',
      'number_of_times_clicked',
      'number_of_unsubscriptions',
      'scheduled_date',
      'send_date',
      'created_date',
      'updated_date',
    ];
    this.setSorter('send_date');
    this.state = {
      ...this.state,
      lastSearch: '',
      activeMessage: 'Všetky',
      activeCountry: 'SK',
      showAlert: true,
    };
  }
  async fetchData(parameters = {}) {
    try {
      const {
        sorter,
        active,
        lastSearch,
        activeMessage,
        activeCountry,
      } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (activeMessage === 'Odoslané') {
        params.status = 'sent';
      } else if (activeMessage === 'Nenaplánované') {
        params.status = 'not_scheduled';
      } else if (activeMessage === 'Naplánované odoslanie') {
        params.status = 'scheduled';
      } else if (activeMessage === 'Neodoslané') {
        params.status = 'not_sent';
      }

      const newData = await AdminAPI.getNewslettersAction(
        activeCountry,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  handleNewsOnClick(id) {
    const { history, firm } = this.props;
    const { activeCountry } = this.state;

    history.push(`/${firm}/newsletter/${id}?lang=${activeCountry}`);
  }

  handleLogoutsClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/newsletter/${id}/logouts`);
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `/${this.props.firm}/newsletter/${item._id}?lang=${this.state.activeCountry}`,
            item.name,
          ),
          () => this.handleNewsOnClick(item._id),
        ),
        marketing_list: _shapeData(
          renderLink(
            `./lists/${item.marketing_list._id}`,
            item.marketing_list.display_name,
          ),
          () => this.handleNewsOnClick(item._id),
        ),
        owner: _shapeData(
          renderLink(`./users/${item.owner._id}`, item.owner.display_name),
          () => this.handleNewsOnClick(item._id),
        ),
        type: _shapeData(item.type),
        status: _shapeData(translateNewsletterStatus(item.status)),
        number_of_emails: _shapeData(item.emails_count),
        number_of_numbers: _shapeData(item.phone_number_count),
        number_of_times_opened: _shapeData(item.opens),
        number_of_times_clicked: _shapeData(item.clicks),
        number_of_unsubscriptions: _shapeData(
          renderLink(`./newsletter/${item._id}/logouts`, item.logouts),
          () => this.handleLogoutsClick(item._id),
        ),
        scheduled_date: _shapeData(
          item.scheduled_date &&
            formatDate(item.scheduled_date, FORMAT_HUMANE_DATETIME),
        ),
        send_date: _shapeData(
          item.send_date && formatDate(item.send_date, FORMAT_HUMANE_DATETIME),
        ),
        created_date: _shapeData(
          formatDate(item.created_date, FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(item.updated_date, FORMAT_HUMANE_DATETIME),
        ),
        closed: item.status === 'sent',
      });
      return acumulator;
    }, []);
    return result;
  }

  handleTypeChange(status, activeMessage) {
    this.setState(
      {
        active: status,
        sorter: 'created_date',
        activeMessage: __(activeMessage),
      },
      () => {
        this.setSorter('created_date');
        this.fetchData();
      },
    );
  }

  handleCountryChange(activeCountry) {
    this.setState(
      {
        activeCountry: __(activeCountry),
      },
      () => {
        this.fetchData();
      },
    );
  }

  renderControlBar() {
    const { history } = this.props;
    const { activeMessage, activeCountry } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Zoznam newsletterov')}
        onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlButton name={activeMessage}>
          <LinkWrap>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Všetky')}
            </div>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Odoslané')}
            </div>
            <div
              onClick={e => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Nenaplánované')}
            </div>
            <div
              onClick={e => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Naplánované odoslanie')}
            </div>
            <div
              onClick={e => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Neodoslané')}
            </div>
          </LinkWrap>
        </ControlButton>

        <ControlButton name={activeCountry}>
          <LinkWrap>
            <div onClick={e => this.handleCountryChange(e.target.innerHTML)}>
              {__('SK')}
            </div>
            <div onClick={e => this.handleCountryChange(e.target.innerHTML)}>
              {__('CZ')}
            </div>
          </LinkWrap>
        </ControlButton>

        <ControlBarButton
          primary
          small
          onClick={() => this.handleNewsOnClick('new')}
          icon="plus"
        >
          {__('Vytvoriť nový')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(NewsLetterList);
