import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Loader, Button } from 'oxyrion-ui/lib';
import ControllBar from '../../../Components/ControllBar';
import {
  ButtonRelativeWrapper,
  InputWrapper,
  LoaderWrapper,
  StyledInput,
  StyledLabel,
} from '../../../Components/ReusableComponents';
import { getIdFromProps, __ } from '../../../Utils';
import { connect } from '../../../Store';
import AdminAPI from '../../../AdminAPI';
import SearchableSelect from '../../../Components/SearchableSelect';
import ControlBarButton from '../../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../../Components/PopUpWrapper';
import ConfirmDialog from '../../../Components/ConfirmDialog';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  max-width: ${rem(1000)};
  margin-bottom: ${rem(20)};
  flex-direction: column;
`;

const EanButtonWrapper = styled.div`
  padding-top: ${rem(15)};
`;

const roles = [
  {
    value: 'seller',
    label: 'Predavač',
  },
  {
    value: 'head_seller',
    label: 'Vedúci predajne',
  },
];

class CashierDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      deleteLoading: false,
      eanLoading: false,
      cashier: {},
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const id = getIdFromProps(this.props);

    if (id !== 'new') {
      await this.fetchCashier(id);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchCashier(id) {
    try {
      const params = {
        firm: this.props.firm,
      };

      const cashier = await AdminAPI.getCashierAction(id, params);
      const { items } = await AdminAPI.getShopsAction();
      let shopObj = null;
      if (cashier.primary_eshop_id) {
        const shop = items.find(i => i._id === cashier.primary_eshop_id);
        if (shop) {
          shopObj = {
            value: shop._id,
            label: shop.name,
          };
        }
      }

      const newCashier = Object.assign({}, cashier, {
        primary_eshop_id: shopObj,
        cash_register_id: cashier.cash_register_id.map(c => {
          return { value: c, label: c };
        }),
        role: cashier.role && roles.find(r => r.value === cashier.role),
      });

      this.setState({
        cashier: newCashier,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleChange(e, field) {
    const { cashier } = this.state;

    const newData = Object.assign({}, cashier);

    newData[field] = e;

    this.setState({
      cashier: newData,
    });
  }

  fetchShops() {
    try {
      return AdminAPI.getShopsAction().then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
          raw: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchCashiersRoles() {
    return roles;
  }

  fetchCashRegisters() {
    try {
      return AdminAPI.getCashRegistersAction().then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item._id,
          raw: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async saveCashier() {
    const { cashier } = this.state;
    const { history, firm } = this.props;

    this.setState({ loading: true });
    // if (!this.validateTextInputs()) {
    //   this.setState({
    //     error: __('Musíte vyplniť všetky polia'),
    //     saveLoading: false,
    //   });
    //   return;
    // }

    const newCashier = Object.assign({}, cashier, {
      primary_eshop_id:
        (cashier.primary_eshop_id && cashier.primary_eshop_id.value) || null,
      cash_register_id:
        cashier.cash_register_id && cashier.cash_register_id.map(c => c.value),
      role: cashier.role && cashier.role.value,
    });

    const params = {};
    params.body = {
      cashier: newCashier,
    };

    let createdCashier;

    try {
      if (cashier._id) {
        createdCashier = await AdminAPI.putCashierAction(cashier._id, params);
      } else {
        createdCashier = await AdminAPI.postCashierAction(params);
      }

      const successMessage = cashier._id
        ? __('Pokladník úspešne upravený')
        : __('Pokladník úspešne vytvorený');
      this.setState({
        loading: false,
        error: false,
        success: successMessage,
      });

      history.push(`/${firm}/cashiers/${createdCashier._id}`);

      this.fetchCashier(createdCashier._id);
    } catch (e) {
      this.setState({
        error: __('Pokladníka sa nepodarilo upraviť alebo vytvoriť'),
        success: false,
        loading: false,
      });
    }
  }

  async deleteCashier() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteCashierAction(getIdFromProps(this.props));
      history.push(`/${firm}/cashiers`);
    } catch (e) {
      this.setState({
        error: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  async saveEan() {
    this.setState({ eanLoading: true });
    const { cashier } = this.state;

    const params = {};
    params.body = {
      eanPassword: cashier.eanCode,
    };

    try {
      await AdminAPI.putCashierEanPasswordAction(cashier._id, params);
      this.setState({
        success: __('Ean bol zmenený'),
        eanLoading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        error: __('Ean sa nepodarilo zmeniť'),
        eanLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Pokladník')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      loading,
      notValidData,
      deleteError,
      error,
      success,
      cashier,
      eanLoading,
      showConfirmDialog,
      deleteLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
          <PopUpWrapper
            display={showConfirmDialog}
            small
            onClose={() => this.setState({ showConfirmDialog: false })}
          >
            <ConfirmDialog
              message={__('Naozaj si prajete odstrániť pokladníka?')}
              onDismiss={() => this.setState({ showConfirmDialog: false })}
              onConfirm={() => this.deleteCashier()}
              error={deleteError}
              loading={deleteLoading}
            />
          </PopUpWrapper>
        </Wrapper>

        <ContentWrapper>
          <InputWrapper>
            <StyledLabel> {__('Meno')}</StyledLabel>
            <StyledInput
              placeholder={__('Meno')}
              onChange={e => this.handleChange(e.target.value, 'name')}
              value={cashier.name}
            />
          </InputWrapper>
          <InputWrapper>
            <StyledLabel> {__('Priezvisko')}</StyledLabel>
            <StyledInput
              placeholder={__('Priezvisko')}
              onChange={e => this.handleChange(e.target.value, 'surname')}
              value={cashier.surname}
            />
          </InputWrapper>
          <InputWrapper>
            <StyledLabel> {__('Meno na doklade (bez diakritiky)')}</StyledLabel>
            <StyledInput
              placeholder={__('Meno na doklade ')}
              onChange={e => this.handleChange(e.target.value, 'receipt_name')}
              value={cashier.receipt_name}
            />
          </InputWrapper>
          <InputWrapper>
            <StyledLabel> {__('Predajňa')}</StyledLabel>
            <SearchableSelect
              loadOptions={() => {
                return this.fetchShops();
              }}
              value={cashier.primary_eshop_id}
              placeholder={__('Obchod')}
              handleOnChange={e => this.handleChange(e, 'primary_eshop_id')}
            />
          </InputWrapper>
          <InputWrapper>
            <StyledLabel> {__('Pokladňa')}</StyledLabel>
            <SearchableSelect
              loadOptions={() => {
                return this.fetchCashRegisters();
              }}
              value={cashier.cash_register_id}
              placeholder={__('Pokladňa')}
              handleOnChange={e => this.handleChange(e, 'cash_register_id')}
              isMulti
            />
          </InputWrapper>

          <InputWrapper>
            <StyledLabel> {__('Rola')}</StyledLabel>
            <SearchableSelect
              loadOptions={() => {
                return this.fetchCashiersRoles();
              }}
              value={cashier.role}
              placeholder={__('Rola pokladníka')}
              handleOnChange={e => this.handleChange(e, 'role')}
            />
          </InputWrapper>
          {cashier._id && (
            <InputWrapper>
              <StyledLabel>{__('Ean')}</StyledLabel>
              <StyledInput
                placeholder={__('Ean')}
                onChange={e => this.handleChange(e.target.value, 'eanCode')}
                value={cashier.eanCode}
              />
              <EanButtonWrapper>
                <Button
                  primary
                  small
                  loading={eanLoading}
                  onClick={() => this.saveEan()}
                >
                  {__('Zmeniť EAN')}
                </Button>
              </EanButtonWrapper>
            </InputWrapper>
          )}
        </ContentWrapper>
        <ButtonRelativeWrapper>
          <Button
            loading={loading}
            onClick={() => {
              this.saveCashier();
            }}
            primary
          >
            {getIdFromProps(this.props) === 'new'
              ? __('Uložiť')
              : __('Upraviť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

CashierDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashierDetail);
