import { __ } from '../Utils';

export default [
  {
    name: __('Vzorkovník'),
    clickable: true,
    sorterValue: 'Ean',
  },
  {
    name: __('Značka'),
    clickable: true,
    sorterValue: 'Description',
  },
  {
    name: __('Tónovací systém'),
    clickable: true,
    sorterValue: 'Brand_Code',
  },
  { name: __('Rozsah Receptúr'), clickable: false, sorterValue: 'Unit_Price' },
  {
    name: __('Počet oddtieňov'),
    clickable: true,
    sorterValue: 'Brand_Code',
  },
];
