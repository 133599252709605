import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'oxyrion-ui/lib/Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(2)};
  margin-right: ${rem(2)};
  cursor: pointer;
`;

const Number = styled.div`
  margin-right: ${rem(5)};
  margin-left: ${rem(5)};
  padding: ${rem(4)};
  background: ${props => (props.selected ? '#ee6500' : 'none')};
  border-radius: ${rem(3)};
  color: ${props => (props.selected ? 'white' : 'black')};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  font-size: ${rem(14)};
`;

const NumbersWrapper = styled.div`
  display: flex;
`;

const Pages = styled.div`
  display: flex;
`;

const Total = styled.div`
  font-size: ${rem(12)};

  text-align: end;
`;

const Dots = styled.div`
  padding: ${rem(4)};
  margin-left: ${rem(2)};
  margin-right: ${rem(2)};
`;

class CatalogPager extends React.Component {
  render() {
    const {
      actualPage,
      total,
      limit,
      onPageUp,
      onPageDown,
      onPageClick,
    } = this.props;

    const firstPage = 1;
    const lastPage = Math.ceil(total / limit);
    const isActualFirst = actualPage === firstPage;
    const isActualLast = actualPage === lastPage;

    return (
      <Wrapper>
        {!(total < limit && actualPage === 1) && (
          <Pages>
            {actualPage > firstPage && (
              <IconWrapper onClick={() => onPageDown()}>
                <Icon name="arrow-left" size="s" />
              </IconWrapper>
            )}
            <NumbersWrapper>
              {isActualFirst && !isActualLast && (
                <NumbersWrapper>
                  <Number
                    selected={actualPage === firstPage}
                    onClick={() => onPageClick(firstPage)}
                  >
                    {firstPage}
                  </Number>
                  {lastPage > 1 && (
                    <Number
                      onClick={() => onPageClick(firstPage + 1)}
                      selected={actualPage === firstPage + 1}
                    >
                      {firstPage + 1}
                    </Number>
                  )}
                  {lastPage > 2 && (
                    <Number
                      onClick={() => onPageClick(firstPage + 2)}
                      selected={actualPage === firstPage + 2}
                    >
                      {firstPage + 2}
                    </Number>
                  )}
                  {lastPage > 3 && (
                    <Number
                      onClick={() => onPageClick(firstPage + 3)}
                      selected={actualPage === firstPage + 3}
                    >
                      {firstPage + 3}
                    </Number>
                  )}
                  {lastPage > 4 && <Dots>...</Dots>}
                </NumbersWrapper>
              )}

              {isActualLast && !isActualFirst && (
                <NumbersWrapper>
                  {lastPage - 4 > firstPage && <Dots>...</Dots>}
                  {lastPage - 3 > firstPage && (
                    <Number
                      onClick={() => onPageClick(lastPage - 3)}
                      selected={actualPage === lastPage - 3}
                    >
                      {lastPage - 3}
                    </Number>
                  )}
                  {lastPage - 2 > firstPage && (
                    <Number
                      onClick={() => onPageClick(lastPage - 2)}
                      selected={actualPage === lastPage - 2}
                    >
                      {lastPage - 2}
                    </Number>
                  )}
                  {lastPage - 1 > firstPage && (
                    <Number
                      onClick={() => onPageClick(lastPage - 1)}
                      selected={actualPage === lastPage - 1}
                    >
                      {lastPage - 1}
                    </Number>
                  )}
                  <Number
                    onClick={() => onPageClick(lastPage)}
                    selected={actualPage === lastPage}
                  >
                    {lastPage}
                  </Number>
                </NumbersWrapper>
              )}

              {isActualLast && isActualFirst && (
                <NumbersWrapper>
                  <Number onClick={() => onPageClick(actualPage)} selected>
                    {actualPage}
                  </Number>
                </NumbersWrapper>
              )}

              {!isActualLast && !isActualFirst && (
                <NumbersWrapper>
                  {actualPage - 1 === firstPage ? (
                    <Number
                      onClick={() => onPageClick(firstPage)}
                      selected={actualPage === firstPage}
                    >
                      {firstPage}
                    </Number>
                  ) : (
                    <React.Fragment>
                      <Number
                        onClick={() => onPageClick(firstPage)}
                        selected={actualPage === firstPage}
                      >
                        {firstPage}
                      </Number>{' '}
                      <Dots>...</Dots>
                      <Number
                        onClick={() => onPageClick(actualPage - 1)}
                        selected={actualPage === actualPage - 1}
                      >
                        {actualPage - 1}
                      </Number>
                    </React.Fragment>
                  )}

                  <Number onClick={() => onPageClick(actualPage)} selected>
                    {actualPage}
                  </Number>

                  {actualPage + 1 === lastPage ? (
                    <Number
                      onClick={() => onPageClick(lastPage)}
                      selected={actualPage === lastPage}
                    >
                      {lastPage}
                    </Number>
                  ) : (
                    <React.Fragment>
                      <Number
                        onClick={() => onPageClick(actualPage + 1)}
                        selected={actualPage === actualPage + 1}
                      >
                        {actualPage + 1}
                      </Number>
                      <Dots>...</Dots>
                      <Number
                        onClick={() => onPageClick(lastPage)}
                        selected={actualPage === lastPage}
                      >
                        {lastPage}
                      </Number>
                    </React.Fragment>
                  )}
                </NumbersWrapper>
              )}
            </NumbersWrapper>

            {actualPage < lastPage && (
              <IconWrapper onClick={() => onPageUp()}>
                <Icon name="arrow-right" size="s" />
              </IconWrapper>
            )}
          </Pages>
        )}
        <Total>{`${(actualPage - 1) * limit + 1} - ${
          total < limit ? total : actualPage * limit
        } z ${total}`}</Total>
      </Wrapper>
    );
  }
}

CatalogPager.propTypes = {
  actualPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageUp: PropTypes.func,
  onPageDown: PropTypes.func,
  onPageClick: PropTypes.func,
  limit: PropTypes.number.isRequired,
};

CatalogPager.defaultProps = {
  onPageUp: () => {},
  onPageDown: () => {},
  onPageClick: () => {},
};
export default CatalogPager;
