import React from 'react';
import cookie from 'react-cookies';
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
import { Loader } from 'oxyrion-ui/lib';
import styled from 'styled-components';
import AdminAPI from '../AdminAPI';
import API from '../API';
import API2 from '../API2';
import { actions } from '../Store';
import { __, parseHash } from '../Utils';
import cookieConfig from '../ContentConfig/cookie';
import AclList from '../ACL';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 29% 0;
`;

AdminAPI.setBaseUrl(process.env.REACT_APP_ADMIN_DOMAIN);


API.setBaseUrl(process.env.REACT_APP_DOMAIN);
API.onResponseUnauthorized = () => {
  window.location.href = `${process.env.REACT_APP_LANDING_PAGE}?redirectUrl=${encodeURIComponent(window.location.href)}`;
};

API2.setBaseUrl(process.env.REACT_APP_DOMAIN2);
API2.onResponseUnauthorized = () => {
  window.location.href = `${process.env.REACT_APP_LANDING_PAGE}?redirectUrl=${encodeURIComponent(window.location.href)}`;
};

const authorize = Component => {
  return class Auth extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        authorized: false,
      };
    }

    async componentDidMount() {
      const query = parseHash(window.location.hash);
      const accessQueryToken = query && query.token;

      if (accessQueryToken) {
        this.setCookie(accessQueryToken);
      }

      const accessToken = this.getCookie();
      if (accessToken && accessToken !== 'null' && accessToken !== null) {
        this.login(accessToken);
      } else {
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE}?redirectUrl=${encodeURIComponent(window.location.href)}`;
      }
    }

    getCookie() {
      return cookie.load(cookieConfig.name);
    }
    setCookie(token, expirationDate = Date.now() + 3600, maxAge = 3600) {
      cookie.save(cookieConfig.name, token, {
        path: '/',
        expires: new Date(expirationDate),
        maxAge,
      });
    }

    async getuserHistory() {
      try {
        const history = await API2.getUserRecentsAction({ limit: 10 });
        actions.setHistory(history.recents);
      } catch (e) {
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE}?redirectUrl=${encodeURIComponent(window.location.href)}`;

        throw new Error(__('Nepodarilo sa načítať históriu'));
      }
    }

    async login(token) {
      try {
        this.setState({ isLoading: true });

        AdminAPI.setToken(token);
        API.setToken(token);
        API2.setToken(token);

        let user = null;
        try {
          user = await AdminAPI.meAction();
        } catch (e) {
          if (e.response.status === 401) {
            alert(__('Do tejto aplikácie nemáte prístup'));
          } else if (e.response.status === 401) {
            alert(__('Pri načítaní aplikácie sa vyskytol problém'));
          }
          window.location.href = `${process.env.REACT_APP_LANDING_PAGE}?redirectUrl=${encodeURIComponent(window.location.href)}`;
        }
        await this.getuserHistory();
        const result = await API2.getUserSettingsAction();
        actions.setUser(user);
        actions.setSettings(result.settings);

        actions.setFirm(user.companies[0].companyId);

        if (user.accessTokenExp) {
          this.setCookie(token, new Date(user.accessTokenExp), null);
        }

        // actions.setAppLang(user.appLang || firm);

        // const { items: translations } = await API.getTranslationsAction({
        //   app: 'CRM',
        // });

        // const resources = {};

        // translations.forEach(t => {
        //   resources[t.lang] = {
        //     translation: t.translations,
        //   };
        // });

        // i18n
        //   .use(initReactI18next) // passes i18n down to react-i18next
        //   .init({
        //     resources,
        //     lng: user.appLang || firm,
        //     keySeparator: false, // we do not use keys in form messages.welcome

        //     interpolation: {
        //       escapeValue: false, // react already safes from xss
        //     },
        //   });

        actions.setYear(
          Number(cookie.load('year')) || new Date().getFullYear(),
        );
        window.location.hash = '';
        this.setState({ isLoading: false, authorized: true });
      } catch (e) {
        console.log(e);
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE}?redirectUrl=${encodeURIComponent(window.location.href)}`;
      }
    }

    render() {
      if (this.state.isLoading) {
        return (
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        );
      }

      if (this.state.authorized) {
        return <AclList component={Component} />;
      }

      return <div />;
    }
  };
};

export default authorize;
