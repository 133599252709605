import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import DropZone from 'react-dropzone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon, Label } from 'oxyrion-ui/lib';
import { __ } from '../../../Utils';
import { LANGS } from '../../../ContentConfig/languagesArray';

const Wrapper = styled.div`
  padding: ${rem(20)};
  display: flex;
  justify-content: start;
  flex-direction: column;
  max-width: ${rem(1200)};
  overflow: auto;
  ${({ theme }) => theme.media.m`
  flex-direction: row;
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-right: ${rem(15)};
`;

const Holder = styled.div`
  min-height: ${rem(100)};
`;

const Image = styled.img`
  width: ${rem(100)};
`;

const Delete = styled.div`
  width: ${rem(20)};
  height: ${rem(20)};
  background: #c1bebe;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(10)};
  position: absolute;
  left: ${rem(-7)};
  top: ${rem(-7)};
  cursor: pointer;
`;

const AddNew = styled.div`
  width: ${rem(100)};
  height: ${rem(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  border-radius: ${rem(6)};
  margin-left: ${rem(10)};
  cursor: pointer;
`;

const CopyButton = styled.div`
  margin-top: ${rem(5)};
  font-size: ${rem(12)};
  background: #f7f7f7;
  padding: ${rem(2)};
  border-radius: ${rem(2)};
  border: ${rem(1)} solid #eee;
  cursor: pointer;
  max-width: ${rem(100)};
`;

const Alert = styled.div`
  position: fixed;
  top: ${rem(157)};
  left: 0px;
  background: #00800094;
  padding: ${rem(20)};
  color: white;
  font-size: ${rem(14)};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(10)};
  cursor: pointer;
`;

const CheckboxesWrapper = styled.div`
  display: flex;
`;

const StyledLabel = styled(Label)`
  cursor: pointer;
  font-size: ${rem(16)};
`;

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
    };
  }
  showAlert() {
    this.setState({
      showAlert: true,
    });

    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 1500);
  }
  render() {
    const { images, deleteImage, addImages, selectLangImage } = this.props;

    return (
      <Wrapper>
        {this.state.showAlert && <Alert>{__('Odkaz skopírovaný')}</Alert>}
        {images &&
          images.map(i => {
            return (
              <ImageWrapper key={i.fullPath}>
                <Delete onClick={() => deleteImage(i.fullPath)}>
                  <Icon name="close" color="white" />
                </Delete>
                <Holder>
                  <Image
                    src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${i.fullPath}`}
                  />
                </Holder>
                <CopyToClipboard
                  onCopy={() => {
                    this.showAlert();
                  }}
                  text={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${i.fullPath}`}
                >
                  <CopyButton>{__('Kopírovať')}</CopyButton>
                </CopyToClipboard>

                <CheckboxesWrapper>
                  {LANGS.map(l => (
                    <CheckBoxWrapper
                      key={l}
                      onClick={() => selectLangImage(l, i.fullPath)}
                    >
                      <StyledLabel
                        style={{
                          color:
                            i.firms && i.firms.indexOf(l) > -1 && '#ee6500',
                        }}
                      >
                        {l}
                      </StyledLabel>
                    </CheckBoxWrapper>
                  ))}
                </CheckboxesWrapper>
              </ImageWrapper>
            );
          })}
        <AddNew>
          <DropZone
            onDrop={accepted => addImages(accepted)}
            acceptedFiles="image/*"
            activeStyle={{
              outlineWidth: rem(5),
            }}
          >
            <Icon name="plus" color="#999" size="xl" />
          </DropZone>
        </AddNew>
      </Wrapper>
    );
  }
}

Images.propTypes = {
  deleteImage: PropTypes.func,
  addImages: PropTypes.func,
  selectLangImage: PropTypes.func,
  images: PropTypes.arrayOf({
    fullPath: PropTypes.string,
  }),
};

Images.defaultProps = {
  images: [],
  deleteImage: () => {},
  addImages: () => {},
  selectLangImage: () => {},
};

export default Images;
