import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProductsGroups from '../../../Containers/ProductsGroups';

function ProductsGroupsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={ProductsGroups} />
    </Switch>
  );
}

ProductsGroupsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ProductsGroupsCard;
