import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrderSettings from '../../../Containers/OrderSettings';

function OrderSetting(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={OrderSettings} />
    </Switch>
  );
}

OrderSetting.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default OrderSetting;
