import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import styled from 'styled-components';

const CustomCheckbox = styled(Checkbox)`
  position: unset;

  input {
    display: none;
  }

  span {
    position: unset;
  }
`;

class OwnStateCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(oldProps) {
    if (oldProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handleOnChange() {
    const { onChange } = this.props;
    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        onChange();
      },
    );
  }

  render() {
    const { checked } = this.state;
    return (
      <CustomCheckbox
        checked={checked}
        onChange={() => {
          this.handleOnChange();
        }}
      />
    );
  }
}

OwnStateCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

OwnStateCheckbox.defaultProps = {
  checked: false,
};

export default OwnStateCheckbox;
