import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Graphics from '../../../Containers/GPV/Graphics';
// import MarketingMaterialDetail from '../../../Containers/MarketingMaterialDetail';

function GraphicCard(props) {
  const { match } = props;
  return (
    <Switch>
      {/* <Route path={`${match.url}/:id`} component={MarketingMaterialDetail} /> */}
      <Route path={`${match.url}`} component={Graphics} />
    </Switch>
  );
}

GraphicCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default GraphicCard;
