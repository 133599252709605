import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewsDetail from '../../../Containers/NewsDetail';
import NewsListBase from '../../../Containers/NewsListBase';

function News(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:pageId/:id`} component={NewsDetail} />
      <Route path={`${match.url}/:pageId`} component={NewsListBase} />
    </Switch>
  );
}

News.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default News;
