import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Button } from 'oxyrion-ui/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import LanguageSwitch from '../LanguageSwitch';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  padding: ${rem(20)};
`;

const StyledInput = styled(Input)`
  width: ${rem(400)};
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${rem(10)};
`;

const DeleteWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  cursor: pointer;
`;

const LanguageWrapper = styled.div`
  max-width: ${rem(400)};
  margin-bottom: ${rem(10)};
`;

class GroupManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      error: false,
      activeLanguage: 'SK',
    };
  }

  addNewGroup() {
    const { name, activeLanguage } = this.state;
    const { addNew } = this.props;

    if (name.length > 0) {
      addNew({
        name,
        // _id: name,
        translations: [
          {
            lang: activeLanguage,
            value: name,
          },
        ],
      });

      this.setState({
        name: '',
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  handleChange(e) {
    let { name } = this.state;
    name = e.target.value;
    this.setState({
      name,
    });
  }

  changeLanguage(lang) {
    this.setState({
      activeLanguage: lang,
    });

    return true;
  }

  render() {
    const {
      items,
      deleteItem,
      handleChange,
      saveGroups,
      groupsSaving,
    } = this.props;
    const { name, error, activeLanguage } = this.state;
    return (
      <Wrapper>
        <LanguageWrapper>
          <LanguageSwitch
            onChange={id => {
              return this.changeLanguage(id);
            }}
            activeId={activeLanguage}
          />
        </LanguageWrapper>
        {items.map((item, index) => {
          const translateName =
            item.translations &&
            item.translations.find(t => t.lang === activeLanguage)
              ? item.translations.find(t => t.lang === activeLanguage).value
              : item.name;

          return (
            <Row>
              <StyledInput
                value={translateName}
                onChange={e => handleChange(e, activeLanguage, index)}
              />
              <DeleteWrapper onClick={() => deleteItem(index)}>
                <Icon name="close" size="l" />
              </DeleteWrapper>
            </Row>
          );
        })}
        <Row>
          <StyledInput
            value={name}
            error={error}
            onChange={e => this.handleChange(e)}
          />
          <DeleteWrapper onClick={() => this.addNewGroup()}>
            <Icon name="plus" size="l" />
          </DeleteWrapper>
        </Row>
        <Row>
          <Button loading={groupsSaving} primary onClick={() => saveGroups()}>
            {__('Uložiť')}
          </Button>
        </Row>
      </Wrapper>
    );
  }
}

GroupManager.propTypes = {
  groupsSaving: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      display_name: PropTypes.string,
    }),
  ),
  addNew: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  saveGroups: PropTypes.func.isRequired,
};

GroupManager.defaultProps = {
  items: [],
  groupsSaving: false,
};

export default GroupManager;
