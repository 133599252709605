import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';

const DesktopButton = styled(Button)`
  margin-left: ${rem(10)};
  display: flex;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-right: ${rem(20)};
  min-width: ${rem(200)};
`;

class AddNewProductGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  handelChange(e) {
    this.setState({
      value: e,
    });
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' && this.state.value.length) {
      this.submit();
    }
  }

  async submit() {
    const { onSubmit } = this.props;
    const { value } = this.state;

    await onSubmit(value);

    this.setState({ value: '' });
  }

  render() {
    return (
      <Row onKeyDown={e => this.handleKeyDown(e)}>
        <InputWrapper>
          <Input
            value={this.state.value || ''}
            placeholder={__('Nová produktová skupina')}
            onChange={e => this.setState({ value: e.target.value })}
          />
        </InputWrapper>

        <DesktopButton
          loading={this.props.loading}
          primary
          small
          disabled={this.state.value.length < 1}
          onClick={() => this.submit()}
        >
          {__('Pridať')}
        </DesktopButton>
      </Row>
    );
  }
}

AddNewProductGroup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AddNewProductGroup;
