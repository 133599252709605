import React from 'react';
import styled from 'styled-components';
import cookie from 'react-cookies';
import { Loader } from 'oxyrion-ui/lib';
import { connect, actions } from '../Store';
import { __ } from '../Utils';
import cookieConfig from '../ContentConfig/cookie';
import API2 from '../API2';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 29% 0;
`;

class AclList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    await this.getAclList();
  }
  async getAclList() {
    try {
      const { firm } = this.props;
      const aclList = await API2.getUserAcl(firm);
      actions.setAclList(aclList.actions);
      actions.setUrlList(aclList.url_permissions || []);
      this.setState({ loading: false });
    } catch (e) {
      this.aclNotFound();
    }
  }
  removeCookie() {
    cookie.remove(cookieConfig.name, {
      path: '/',
    });
  }
  aclNotFound() {
    this.removeCookie();
    window.location.href = '/';
    alert(__('Nepodarilo sa načítať potrebné práva.'));
  }
  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }
    const Component = this.props.component;
    return <Component />;
  }
}

export default connect(state => state)(AclList);
