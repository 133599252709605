import React from 'react';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/codelists';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { __, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import codelistsEntities from '../../ContentConfig/codelistsEntities';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';

class CodeLists extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Číselníky');
    this.showBulkOperations = false;
    this.accessors = ['name'];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
      commonMessage: __('Všetky'),
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/CodeLists/${id}`);
  }

  async fetchData(parameters = {}, type = undefined) {
    try {
      this.setState({ loading: true });

      const newData = await AdminAPI.getCodelistsAction({ entity: type });

      const content = this.normalizeColumns(newData.codelists);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./CodeLists/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleCommonMessage(value, name) {
    this.setState({ commonMessage: name }, () => {
      this.fetchData({}, value !== 'all' ? value : undefined);
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { commonMessage } = this.state;

    return (
      <ControllBar history={history} name={__('Zoznam číselníkov')}>
        <ControlButton name={commonMessage}>
          <LinkWrap>
            {codelistsEntities.map(c => {
              return (
                <div
                  onClick={e =>
                    this.handleCommonMessage(c.value, e.target.innerHTML)
                  }
                >
                  {c.name}
                </div>
              );
            })}
          </LinkWrap>
        </ControlButton>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať číselník')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CodeLists);
