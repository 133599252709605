import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Synchronization from './Synchronization';

function System(props) {
  const { match } = props;
  return (
    <Switch>
      <Route
        path={`${match.url}/synchronizations`}
        component={Synchronization}
      />
    </Switch>
  );
}

System.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default System;
