import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Button,
  Message,
  Input,
  Label,
  Icon,
  Loader,
  Checkbox,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import AddNewCodeList from '../../Components/AddNewCodeList';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';
import codelistsEntities from '../../ContentConfig/codelistsEntities';
import { LANGS } from '../../ContentConfig/languagesArray';
import OwnStateInput from '../../Components/OwnStateInput';

const Wrapper = styled.div`
  overflow: scroll;
`;

const DeleteRow = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(10)};
  background: #eb4142;
  border-radius: ${rem(2)};
  :hover {
    background: red;
  }
`;

const UndoWrapper = styled.div`
  width: ${rem(60)};
  height: ${rem(30)};
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(10)};
  background: #0e961b;
  border-radius: ${rem(2)};
  :hover {
    background: green;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(20)};
  min-width: ${rem(200)};
`;

const MainNameWrapper = styled.div`
  max-width: ${rem(200)};
  margin-left: ${rem(20)};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const CheckBoxesWrapper = styled.div`
  display: flex;
`;

const PositionInputWrapper = styled.div`
  margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(20)};
  min-width: ${rem(60)};
`;

class CodeListDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      codelist: [],
      deletedCodelist: [],
      loading: false,
      codelistName: '',
      codelistLabel: '',
      selectedFor: [],
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      this.setState({ loading: true });
      await this.fetchCodelistData(id);
    }
  }

  async fetchCodelistData(id) {
    try {
      const codelist = await AdminAPI.getCodelist(id);
      this.setState({
        codelist: codelist.codelist || [],
        codelistName: getIdFromProps(this.props),
        codelistLabel: codelist.name,
        selectedFor: codelist.selectedFor || [],
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie číselníka nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Číselník systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async saveCodelist() {
    const {
      codelist,
      deletedCodelist,
      codelistName,
      codelistLabel,
      selectedFor,
    } = this.state;
    this.setState({
      saveLoading: true,
      success: false,
      codelist,
      loading: true,
    });

    const codeListsToSave = codelist.filter((c) => c.wasChanged);
    const newCodeLists = codelist.filter((c) => !c._id);
    const deletedCodelistNormalized = deletedCodelist.filter((c) => c._id);
    let codelistType = getIdFromProps(this.props);
    try {
      if (codelistType === 'new') {
        // najprv vytvor taky codelist
        const idToInsert = codelistName.replace(' ', '_').toLowerCase();
        const params = {};
        params.body = {
          _id: idToInsert,
          name: codelistName,
          selectedFor,
          translations: [],
        };
        await AdminAPI.postMainCodelistAction(params);
        this.props.history.push(`/${this.props.firm}/Codelists/${idToInsert}`);
        codelistType = idToInsert;
      }

      const mainBodyParams = {};
      mainBodyParams.body = {
        name: codelistLabel,
        selectedFor,
      };

      await AdminAPI.putMainCodelistAction(
        getIdFromProps(this.props),
        mainBodyParams,
      );

      await Promise.all(
        newCodeLists.map((c) => {
          const params = {};
          params.body = {
            ...c,
            type: codelistType,
            value: c.label,
          };
          return AdminAPI.postCodelistAction(params);
        }),
      );
      await Promise.all(
        codeListsToSave
          .filter((c) => c.value && c.value.length > 0)
          .map((c) => {
            const params = {};
            params.body = c;
            return AdminAPI.putCodelistAction(c._id, params);
          }),
      );
      await Promise.all(
        deletedCodelistNormalized.map((c) =>
          AdminAPI.deleteCodelistAction(c._id),
        ),
      );

      this.fetchCodelistData(getIdFromProps(this.props));

      this.setState({
        success: __('Číselník bol úspešne uložený'),
        previousCodelist: undefined,
        deletedCodelist: [],
      });
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        saveLoading: false,
        loading: false,
      });

      window.scrollTo(0, 0);
    }
  }

  handleTechnicalValueChange(value, index) {
    const { codelist } = this.state;

    codelist[index].value = value;

    this.setState({ codelist });
  }

  handleCodelistChange(e, value, lang, index) {
    const { codelist } = this.state;
    const selectedCodelist = codelist[index];
    if (!selectedCodelist.translations) {
      selectedCodelist.translations = [];
    }
    if (!selectedCodelist.translations.find((l) => l.lang === lang)) {
      selectedCodelist.translations.push({
        lang,
        label: '',
        value,
      });
    }
    if (lang === 'SK') {
      selectedCodelist.label = e.target.value;
    }
    selectedCodelist.translations.find((l) => l.lang === lang).label =
      e.target.value;

    if (selectedCodelist.value !== value) {
      selectedCodelist.translations.find((l) => l.lang === lang).value = value;
    }

    selectedCodelist.wasChanged = true;

    this.setState({
      codelist,
      success: false,
    });
  }

  deleteRow(index) {
    const { codelist, deletedCodelist } = this.state;
    const saveCodelist = codelist.slice(0);

    deletedCodelist.push(codelist[index]);

    codelist.splice(index, 1);

    this.setState({
      codelist,
      previousCodelist: saveCodelist,
      deletedCodelist,
    });
  }

  undo() {
    const { deletedCodelist } = this.state;
    deletedCodelist.splice(deletedCodelist.length, 1);

    const codelist = this.state.previousCodelist;

    this.setState({
      codelist,
      previousCodelist: undefined,
    });
  }

  addCodelist(item) {
    const { codelist } = this.state;
    codelist.push(item);
    this.setState({
      codelist,
    });
  }

  handleCheckboxesChange(value) {
    const { selectedFor } = this.state;

    const indexOf = selectedFor.indexOf(value);
    if (indexOf > -1) {
      selectedFor.splice(indexOf, 1);
    } else {
      selectedFor.push(value);
    }

    this.setState({
      selectedFor,
    });
  }

  changeOrder(index, value) {
    const { codelist } = this.state;
    const selectedCodelist = codelist[index];
    selectedCodelist.order = value;

    selectedCodelist.wasChanged = true;

    this.setState({
      codelist,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Číselník')} />;
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      codelist,
      previousCodelist,
      loading,
      codelistName,
      codelistLabel,
      selectedFor,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <MainNameWrapper>
            <Label>{__('Názov v systéme (id)')}</Label>
            <Input
              value={codelistName}
              disabled={getIdFromProps(this.props) !== 'new'}
              onChange={(e) =>
                this.setState({
                  codelistName: e.target.value,
                })
              }
            />
            <Label>{__('Názov v aplikácií')}</Label>
            <Input
              value={codelistLabel}
              onChange={(e) =>
                this.setState({
                  codelistLabel: e.target.value,
                })
              }
            />
            <Label>{__('Použiť pre')}</Label>
            <CheckBoxesWrapper>
              {codelistsEntities.map((c) => {
                return (
                  <CheckBoxWrapper>
                    <Checkbox
                      disabled={
                        c.value !== 'all' && selectedFor.indexOf('all') > -1
                      }
                      onChange={() => this.handleCheckboxesChange(c.value)}
                      checked={selectedFor.indexOf(c.value) > -1}
                    />
                    <Label>{c.name}</Label>
                  </CheckBoxWrapper>
                );
              })}
            </CheckBoxesWrapper>
          </MainNameWrapper>
          <Row key="1">
            {/* <InputWrapper>
              <Label>{__('Systemová hodnota')}</Label>
            </InputWrapper> */}
            {LANGS.map((lang) => {
              return (
                <InputWrapper>
                  <Label>
                    {__('Hodnoty ')} {lang} {lang === 'SK' && '*'}
                  </Label>
                </InputWrapper>
              );
            })}
            <InputWrapper>
              <Label>{__('Poradie')}</Label>
            </InputWrapper>
            {previousCodelist && (
              <UndoWrapper onClick={() => this.undo()}>
                {__('Vrátiť')}
              </UndoWrapper>
            )}
          </Row>
          {codelist.map((item, index) => {
            return (
              <Row key={item.value}>
                {/* <InputWrapper key={index}>
                  <Input
                    value={item._id}
                    disabled={
                      this.props.user.email !== 'michal.hricisin@dejmark.com'
                    }
                    // onChange={e =>
                    //   this.handleTechnicalValueChange(e.target.value, index)
                    // }
                  />
                </InputWrapper> */}
                {LANGS.map((lang) => {
                  const langValue =
                    item.translations &&
                    item.translations.find((t) => t.lang === lang)
                      ? item.translations.find((t) => t.lang === lang).label
                      : '';
                  if (lang === 'SK' && langValue.length === 0) {
                    return (
                      <InputWrapper key={lang}>
                        <Input
                          value={item.label}
                          onChange={(e) =>
                            this.handleCodelistChange(
                              e,
                              item.value,
                              lang,
                              index,
                            )
                          }
                        />
                      </InputWrapper>
                    );
                  }
                  return (
                    <InputWrapper>
                      <Input
                        value={langValue}
                        placeholder={item.label}
                        onChange={(e) =>
                          this.handleCodelistChange(e, item.value, lang, index)
                        }
                      />
                    </InputWrapper>
                  );
                })}
                <PositionInputWrapper>
                  <OwnStateInput
                    type="number"
                    min="0"
                    onChange={(value) => {
                      this.changeOrder(index, Number(value));
                    }}
                    placeholder="0"
                    value={item.order}
                  />
                </PositionInputWrapper>
                <div>
                  <DeleteRow onClick={() => this.deleteRow(index)}>
                    <Icon name="delete" color="white" />
                  </DeleteRow>
                </div>
              </Row>
            );
          })}
          <AddNewCodeList
            langs={LANGS}
            onSubmit={(item) => this.addCodelist(item)}
            email={this.props.user.email}
          />
        </Wrapper>

        <ButtonRelativeWrapper>
          <Button
            onClick={() => this.saveCodelist()}
            primary
            loading={saveLoading}
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

CodeListDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CodeListDetail);
