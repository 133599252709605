import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/toningSystems';
import { __, formatDate, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

class ToningSystems extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Tonovacie systémy');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'brand',
      'count_of_colors',
      'base',
      'created_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/toningSystems/${id}`);
  }

  fetchCustomers(query) {
    try {
      const { firm } = this.props;
      return API2.getCustomersAction(firm, { q: query }).then(res => ({
        options: res.items.map(item => ({
          value: item._id,
          label: item.name,
        })),
      }));
    } catch (e) {
      return [];
    }
  }

  async loadSearchRrsult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearch: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearch: value.toLowerCase() });
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, selectedCustomer, lastSearch } = this.state;
      const { firm } = this.props;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await API2.getToningSystemsAction(firm, params);

      const content = this.normalizeColumns(newData.systems);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./toningSystems/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        brand: _shapeData(item.nav_brand_id),
        count_of_colors: _shapeData(
          item.colourants ? item.colourants.length : 0,
        ),
        base: _shapeData(item.base),
        created_date: _shapeData(
          item.created_date ? formatDate(moment(item.created_date)) : '',
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        history={history}
        name={__('Zoznam tónovacích systémov')}
        defaultValue={this.state.lastSearchValue}
        onChange={val => this.loadSearchRrsult(val)}
      >
        {/* <CheckAccess
          operation="postColorCardAction"
          Component={ */}
        <ControlBarButton
          small
          primary
          basic
          onClick={() => this.handleAddNewClick()}
        >
          {__('Vytvoriť tónovací systém')}
        </ControlBarButton>
        {/* }
        /> */}
      </ControllBar>
    );
  }

  handleAddNewClick() {
    const { history, firm } = this.props;
    history.push(`/${firm}/toningSystems/new`);
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ToningSystems);
