import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Synchronizations from '../../../Containers/Synchronizations';
import SynchronizationDetail from '../../../Containers/SynchronizationDetail';

function Synchronization(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={SynchronizationDetail} />
      <Route path={`${match.url}`} component={Synchronizations} />
    </Switch>
  );
}

Synchronization.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default Synchronization;
