import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
// import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/references';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';

import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getYears,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';
import ControlButton from '../../Components/ReusableComponents/ControlButton';

const years = getYears(2000, moment().year());

class References extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Referencie');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'city',
      'year',
      'country',
      'state',
      'owner',
      'object',
      'updated_date',
      'created_date',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      downloadSelectedLoading: false,
      lastSearch: '',
      types: [],
      surfaces: [],
      objects: [],
      countries: [],
      states: [
        {
          label: __('Na spracovanie'),
          value: 'for_approval',
        },
        {
          label: __('Spracovaná'),
          value: 'all',
        },
      ],
      selectedObject: { label: __('Všetky priestory'), value: null },
      selectedSurface: { label: __('Všetky povrchy'), value: null },
      selectedCountry: { label: __('Všetky krajiny'), value: null },
      selectedState: { label: __('Všetky stavy'), value: null },
      selectedYear: { label: __('Všetky roky'), value: null },
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/references/${id}`);
  }

  async componentDidMount() {
    super.componentDidMount();

    try {
      const result = await Promise.all([
        AdminAPI.getCodelist('povrch_ns'),
        AdminAPI.getCodelist('objekt_priestor'),
        AdminAPI.getCodelist('countries'),
      ]);

      this.setState({
        surfaces: result[0].codelist,
        objects: result[1].codelist,
        countries: result[2].codelist,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleSurfaceChange(value, name) {
    this.setState({ selectedSurface: { label: name, value } }, () => {
      this.fetchData();
    });
  }

  handleObjectChange(value, name) {
    this.setState({ selectedObject: { label: name, value } }, () => {
      this.fetchData();
    });
  }

  handleCountryChange(value, name) {
    this.setState({ selectedCountry: { label: name, value } }, () => {
      this.fetchData();
    });
  }

  handleStateChange(value, name) {
    this.setState({ selectedState: { label: name, value } }, () => {
      this.fetchData();
    });
  }

  handleYearChange(value, name) {
    this.setState({ selectedYear: { label: name, value } }, () => {
      this.fetchData();
    });
  }

  getStateData(state) {
    if (state === 'for_approval') {
      return __('Na spracovanie');
    }
    return __('Spracovaná');
  }

  async fetchData(parameters = {}) {
    try {
      const {
        sorter,
        active,
        lastSearch,
        selectedCountry,
        // selectedObject,
        selectedSurface,
        selectedYear,
        selectedState,
      } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCountry && selectedCountry.value) {
        params.country = selectedCountry.value;
      }

      if (selectedYear && selectedYear.value) {
        params.year = selectedYear.value;
      }

      if (selectedSurface && selectedSurface.value) {
        params.country = selectedCountry.value;
      }

      if (selectedState && selectedState.value) {
        params.state = selectedState.value;
      }

      const newData = await AdminAPI.getReferencesAction(params);

      const content = this.normalizeColumns(newData.references);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const { types } = this.state;

    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(`./references/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        category: _shapeData(
          types.find(t => t.value === item.category) &&
            types.find(t => t.value === item.category).label,
        ),
        city: _shapeData(item.city),
        year: _shapeData(item.year),
        country: _shapeData(item.country),
        state: _shapeData(this.getStateData(item.status)),
        owner: _shapeData(item.owner && item.owner.display_name),
        object: _shapeData(item.objects && item.objects.join(',')),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const {
      // surfaces,
      // selectedSurface,
      // selectedObject,
      // objects,
      selectedCountry,
      countries,
      states,
      selectedState,
      selectedYear,
    } = this.state;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Referencie')}
      >
        {/* <ControlButton name={selectedSurface.label}>
          <LinkWrap>
            <a
              onClick={() =>
                this.handleSurfaceChange(undefined, __('Všetky povrchy'))
              }
            >
              {__('Všetky povrchy')}
            </a>
            {surfaces.map(c => {
              return (
                <a onClick={() => this.handleSurfaceChange(c.value, c.label)}>
                  {c.label}
                </a>
              );
            })}
          </LinkWrap>
        </ControlButton> */}

        {/* <ControlButton name={selectedObject.label}>
          <LinkWrap>
            <a
              onClick={() =>
                this.handleObjectChange(undefined, __('Všetky priestory'))
              }
            >
              {__('Všetky priestory')}
            </a>
            {objects.map(c => {
              return (
                <a onClick={() => this.handleObjectChange(c.value, c.label)}>
                  {c.label}
                </a>
              );
            })}
          </LinkWrap>
        </ControlButton> */}

        <ControlButton name={selectedState.label}>
          <LinkWrap>
            <div
              onClick={() =>
                this.handleObjectChange(undefined, __('Všetky stavy'))
              }
            >
              {__('Všetky stavy')}
            </div>
            {states.map(c => {
              return (
                <div onClick={() => this.handleStateChange(c.value, c.label)}>
                  {c.label}
                </div>
              );
            })}
          </LinkWrap>
        </ControlButton>

        <ControlButton name={selectedCountry.label}>
          <LinkWrap>
            <div
              onClick={() =>
                this.handleObjectChange(undefined, __('Všetky krajiny'))
              }
            >
              {__('Všetky krajiny')}
            </div>
            {countries.map(c => {
              return (
                <div onClick={() => this.handleCountryChange(c.value, c.label)}>
                  {c.label}
                </div>
              );
            })}
          </LinkWrap>
        </ControlButton>

        <ControlButton name={selectedYear.label}>
          <LinkWrap>
            <div
              onClick={() =>
                this.handleYearChange(undefined, __('Všetky roky'))
              }
            >
              {__('Všetky roky')}
            </div>
            {years.map(c => {
              return <div onClick={() => this.handleYearChange(c, c)}>{c}</div>;
            })}
          </LinkWrap>
        </ControlButton>

        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať referenciu')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(References);
