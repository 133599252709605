import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'oxyrion-ui/lib';
import { rem } from 'polished';

const StyledInput = styled(Input)`
  margin-right: ${rem(10)};
`;

const Wrapper = styled.div`
  display: flex;
`;

const HintWrapper = styled.div`
  border: 1px #eee solid;
  position: fixed;
  background: white;
  width: ${rem(152)};
`;

const Hint = styled.div`
  height: ${rem(20)};
  padding: ${rem(5)};
  border-bottom: ${rem(1)} #eee solid;
  display: flex;
  font-size: ${rem(14)};
  cursor: pointer;
  :hover {
    background: #eee;
  }
`;

const Add = styled.div`
  cursor: pointer;
  width: ${rem(40)};
  height: ${rem(36)};
  background: #1c71b7;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: ${rem(2)};
  margin-left: ${rem(14)};
`;

const InputWrapper = styled.div``;

class QuickSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      hints: [],
    };
  }

  async handleText(value) {
    this.setState(
      {
        text: value,
      },
      () => this.saveHints(value),
    );
  }

  async saveHints(value) {
    const { search } = this.props;
    const hints = value.length > 0 ? await search(value) : [];

    this.setState({ hints });
  }

  selectHint(hint) {
    this.setState({
      text: hint,
      hints: [],
    });
  }

  submit() {
    const { submit } = this.props;
    const { text } = this.state;

    submit(text);

    this.setState({
      text: '',
      hints: [],
    });
  }

  render() {
    const { placeholder } = this.props;

    const { text, hints } = this.state;

    return (
      <Wrapper>
        <div>
          <InputWrapper>
            <StyledInput
              value={text}
              placeholder={placeholder}
              onChange={e => this.handleText(e.target.value)}
            />
          </InputWrapper>
          {hints.length > 0 && (
            <HintWrapper>
              {hints.map(hint => {
                return (
                  <Hint onClick={() => this.selectHint(hint)}>{hint}</Hint>
                );
              })}
            </HintWrapper>
          )}
        </div>

        <Add onClick={() => this.submit()}>+</Add>
      </Wrapper>
    );
  }
}

QuickSearch.propTypes = {
  placeholder: PropTypes.string,
  search: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

QuickSearch.defaultProps = {
  placeholder: '',
};

export default QuickSearch;
