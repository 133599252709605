import React from 'react';
import { Route } from 'react-router-dom';
import CashiersCard from './CashiersList';
import CashRegistersCard from './List';

function CashRegisters(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route
        path={`${match.url}/cash-registers`}
        component={CashRegistersCard}
      />
      <Route path={`${match.url}/cashiers`} component={CashiersCard} />
    </React.Fragment>
  );
}

export default CashRegisters;
