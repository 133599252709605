import React from 'react';
import moment from 'moment';
import validator from 'validator';
import styled from 'styled-components';
import { extendMoment } from 'moment-range';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { RouteLinkWraper, IFrame } from '../Components/ReusableComponents';
import MailLink from '../Components/MailLink';
import AdminAPI from '../AdminAPI';

const Moment = extendMoment(moment);

export const FORMAT_HUMANE_DATETIME = 'DD.MM.YYYY HH:mm';
export const FORMAT_HUMANE_DATE = 'DD.MM.YYYY';
export const FORMAT_HUMANE_TIME = 'HH.mm';
export const SK_PHONE_PREFIX = '+421';
export const CZ_PHONE_PREFIX = '+420';
export const LOCALE = ['sk-SK', 'cs-CZ'];
export const __ = text => text;

export const translate = (lang, translations, defaultValue) => {
  if (
    translations &&
    translations.find(t => t.lang === lang) &&
    translations.find(t => t.lang === lang).value.length > 0
  ) {
    return translations.find(t => t.lang === lang).value;
  }
  return defaultValue;
};

export const updateTranslation = (_object = {}, lang, value) => {
  let object = _object;

  if (!object) {
    object = {};
  }

  const temObject = object || {};

  if (!object || !object.translations || object.translations.length === 0) {
    return Object.assign(object, {
      translations: [
        {
          lang,
          value,
        },
      ],
      value,
    });
  }
  if (!object.translations.find(t => t.lang === lang)) {
    temObject.translations.push({
      lang,
      value,
    });
    if (lang === 'SK') {
      temObject.value = value;
    }
    return temObject;
  }
  if (object.translations.find(t => t.lang === lang)) {
    temObject.translations.find(t => t.lang === lang).value = value;
    if (lang === 'SK') {
      temObject.value = value;
    }

    return temObject;
  }
};

export const translateFieldType = value => {
  if (value === 'string') {
    return __('Text');
  } else if (value === 'number') {
    return __('Číslo');
  } else if (value === 'table') {
    return __('Tabuľka');
  } else if (value === 'codelist') {
    return __('Číselník');
  }
  return value;
};

export const translateCodelist = (lang, translations, defaultValue) => {
  if (
    translations &&
    translations.find(t => t.lang === lang) &&
    translations.find(t => t.lang === lang).label &&
    translations.find(t => t.lang === lang).label.length > 0
  ) {
    return translations.find(t => t.lang === lang).label;
  }
  return defaultValue;
};

export const formatPhoneNumber = (phone, firm) => {
  if (phone.startsWith(0)) {
    const c = phone.split(' ').join('');
    const formatedPhone = c.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, '$2 $3 $4');
    switch (firm) {
      case 'SK':
        return `${SK_PHONE_PREFIX} ${formatedPhone}`;
      case 'CZ':
        return `${CZ_PHONE_PREFIX} ${formatedPhone}`;
      default:
        return phone;
    }
  }
  return phone;
};

export const parseHash = hash => {
  let content = hash;
  if (hash.indexOf('#') >= 0) {
    content = hash.substring(hash.indexOf('#') + 1);
  }
  const result = content.split('&').reduce((acum, item) => {
    const [key, value] = item.split('=');
    const r = Object.assign({}, acum);
    r[key] = value;
    return r;
  }, {});

  return result;
};

export const formatNumber = (n, c = 2, d = ',', t = ' ') => {
  const s = n < 0 ? '-' : '';
  const b = Math.abs(+n || 0);
  const i = String(parseInt(b.toFixed(c), 10));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(b - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const handleServiceStataus = status => {
  switch (status) {
    case 'new':
      return __('Nová');
    case 'solved':
      return __('Vyriešená');
    case 'confirmed':
      return __('Potvrdená');
    case 'closed':
      return __('Uzavtretá');
    default:
      return status;
  }
};

export const formatPrice = (
  { amount, currency = 'EUR' },
  c = 2,
  d = ',',
  t = '\u00a0',
) => {
  if (currency === 'CZK') {
    return `${formatNumber(amount, c, d, t, c)}\u00a0Kč`;
  } else if (currency === 'EUR') {
    return `${formatNumber(amount, c, d, t, c)}\u00a0€`;
  } else if (currency === 'HUF') {
    return `${formatNumber(amount, c, d, t, c)}\u00a0€`;
  }

  return `${formatNumber(amount, c, d, t, c)}\u00a0${currency}`;
};

export const redirect = redirectUrl => {
  window.location.href = redirectUrl;
};

export const handlePriceForTable = priceData => {
  if (!priceData) {
    return null;
  }
  return formatPrice(priceData);
};

export const formatDate = (date, format = FORMAT_HUMANE_DATE) => {
  return moment(date).format(format);
};

export const getMonthByNumber = number => {
  switch (number) {
    case 1:
      return __('Január');
    case 2:
      return __('Február');
    case 3:
      return __('Marec');
    case 4:
      return __('Apríl');
    case 5:
      return __('Máj');
    case 6:
      return __('Jún');
    case 7:
      return __('Júl');
    case 8:
      return __('August');
    case 9:
      return __('September');
    case 10:
      return __('Október');
    case 11:
      return __('November');
    case 12:
      return __('December');
    default:
      return number;
  }
};

export const formatProbability = value => {
  switch (value) {
    case 'Low':
      return __('Nízka');
    case 'Medium':
      return __('Stredná');
    case 'High':
      return __('Vysoká');
    default:
      return value;
  }
};

export const toIsoDate = s => {
  return moment(s, FORMAT_HUMANE_DATE).toISOString();
};

export const renderLink = (to, name, noMargin = true, fontSize = rem(11)) => {
  return (
    <RouteLinkWraper fontSize={fontSize} noMargin={noMargin}>
      <Link to={to}>{name}</Link>
    </RouteLinkWraper>
  );
};

export const renderLinkOnNewTab = (
  to,
  name,
  noMargin = true,
  fontSize = rem(11),
) => {
  return (
    <RouteLinkWraper fontSize={fontSize} noMargin={noMargin}>
      <a target="_blank" href={to} rel="noopener noreferrer">
        {name}
      </a>
    </RouteLinkWraper>
  );
};

export const defaultCustomertype = values => {
  if (values && values.length) {
    return `${values[0].type} - ${values[0].subtype}`;
  }
  return '';
};

export const formatStatus = value => {
  switch (value) {
    case 'active':
      return __('Aktívny');
    case 'inactive':
      return __('Neaktívny');
    default:
      return value;
  }
};

export const normalizeAddressData = data => {
  if (!data) {
    return [];
  }

  const address = [
    {
      name: __('Krajina'),
      value: data.country,
    },
    {
      name: __('Mesto'),
      value: data.city,
    },
    {
      name: __('Ulica'),
      value: data.street,
    },
    {
      name: __('Poštové číslo'),
      value: data.postal_code,
    },
  ];
  return address;
};

export const getAddressLabel = data => {
  if (data) {
    return `${data.street && `${data.street}, `}${data.postal_code &&
      data.postal_code}`;
  }
  return '';
};

export const normalizeFirmInfoData = (
  data,
  handleNameOnClick = undefined,
  path,
) => {
  if (!data) {
    return [];
  }

  const firmInfo = [
    {
      name: __('Meno'),
      value: path
        ? renderLink(`${path}/${data._id}`, data.name, true)
        : data.name,
      handleOnClick: handleNameOnClick && (() => handleNameOnClick(data._id)),
    },
    {
      name: __('Email'),
      Component: () => <MailLink mail={data.email} />,
    },
    {
      name: __('IČO'),
      value: data.registration_no,
    },
    {
      name: __('Vlastník'),
      value: data.ownership
        ? data.ownership.map(o => o.display_name).join(', ')
        : '-',
    },
    {
      name: __('Kategória segmentu'),
      value: data.segment_category,
      hidden: true,
    },
  ];
  return firmInfo;
};

export const normalizeCustomerBasicInfo = (data, address) => {
  const addressData = !address ? [] : normalizeAddressData(address);
  const basicData = !data ? [] : normalizeFirmInfoData(data, null, null);
  return [
    ...basicData.filter(
      f => f.name !== __('IČO') && f.name !== __('Kategória segmentu'),
    ),
    ...addressData,
    ...basicData.filter(f => f.name === __('IČO')),
    ...basicData.filter(f => f.name === __('Kategória segmentu')),
  ];
};

export const narmalizeOpportunities = (
  opportunities,
  handleOpportunityOnClick,
  path,
) => {
  if (!opportunities) {
    return [];
  }
  return opportunities.reduce((acumulator, item) => {
    const row = [
      {
        value: item.topic,
        handleOnClick: handleOpportunityOnClick
          ? () => handleOpportunityOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: item.status,
      },
      {
        value: formatDate(item.actual_date),
      },
      {
        value: formatPrice(item.actual_profit),
      },
      {
        value: formatDate(item.estimated_date),
      },
      {
        value: formatPrice(item.estimated_profit),
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeActivities = (
  activities,
  handleActivityOnClcik = null,
  path = null,
) => {
  if (!activities) {
    return [];
  }

  return activities.reduce((acumulator, item) => {
    const row = [
      {
        value: moment(item.to_date).format(FORMAT_HUMANE_DATETIME),
      },
      {
        value: item.subject,
        handleOnClick: handleActivityOnClcik
          ? () => handleActivityOnClcik(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: item.user_name || '_',
      },
      {
        value: item.summary || '_',
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeTasks = (tasks, handleTaskOnClick, path) => {
  if (!tasks) {
    return [];
  }
  return tasks.reduce((acumulator, item) => {
    const row = [
      {
        value: formatDate(item.date),
      },
      {
        value: item.subject,
        handleOnClick: handleTaskOnClick
          ? () => handleTaskOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: moment(item.from_date).format(FORMAT_HUMANE_DATE),
      },
      {
        value: moment(item.to_date).format(FORMAT_HUMANE_DATE),
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};

export const normalizeContactInfoData = (data, firm = 'CZ') => {
  if (!data) {
    return [];
  }
  return [
    {
      name: __('Meno'),
      value: data.name,
    },
    {
      name: __('Priezvisko'),
      value: data.surname,
    },
    {
      name: __('Pracovná pozícia'),
      value: data.position,
    },
    {
      name: __('Email'),
      Component: () => <MailLink mail={data.email} />,
    },
    {
      name: __('Mobil'),
      value: data.mobile,
    },
    {
      name: __('Telefón'),
      value: formatPhoneNumber(data.phone, firm),
    },
    {
      name: __('Web'),
      value: data.web,
    },
    {
      name: __('Vlastník'),
      value: data.ownership
        ? data.ownership.map(e => e.display_name).join(', ')
        : '-',
    },
  ];
};

export const normalizeContactsPersonalData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Pohlavie'),
      value:
        (data.sex === 'M' && __('Muž')) || (data.sex === 'F' && __('Žena')),
    },
    {
      name: __('Narodeniny'),
      value: data.birthdate && formatDate(data.birthdate),
    },
  ];
};

export const formatActivityBody = body => {
  const match = /<body>\r\n(.*)/;
  return body.match(match) ? body.match(match)[1] : '';
};

export const formatImportance = importance => {
  switch (importance) {
    case 'low':
      return __('Nízka');
    case 'normal':
      return __('Normálna');
    case 'high':
      return __('Vysoká');
    default:
      return importance;
  }
};

export const injectStyleToIframeBody = (styles, htmlContent) => {
  if (htmlContent) {
    const match = htmlContent.match('<body>');

    if (match) {
      const array = htmlContent.split('');
      array.splice(match.index + 5, 0, ` style="${styles}"`);
      return array.join('');
    }
  } else {
    return htmlContent;
  }
  return htmlContent;
};

export const normalizeCalendarEventData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Názov'),
      value: data.subject,
    },
    {
      name: __('Popis'),
      Component: () => (
        <React.Fragment>
          <IFrame
            title="body"
            srcDoc={injectStyleToIframeBody(
              `font-size: ${rem(11)}; font-family: ${
                baseTheme.fontFamily
              }; margin: ${rem(5)} 0; color: ${baseTheme.textColor}`,
              data.body,
            )}
          />
        </React.Fragment>
      ),
    },
    {
      name: __('od'),
      value: data.from_date
        ? moment(data.from_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Do'),
      value: data.to_date
        ? moment(data.to_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Priorita'),
      value: formatImportance(data.priority),
    },
    {
      name: __('Zrušená'),
      value: data.is_cancelled ? __('áno') : __('nie'),
    },
    {
      name: __('Vytvorená'),
      value: data.created_date
        ? moment(data.created_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Upravená'),
      value: data.updated_date
        ? moment(data.updated_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Zákazník'),
      value: data.customer_name,
    },
  ];
};

export const normalizeActivityData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Názov'),
      value: data.subject,
    },
    {
      name: __('Popis'),
      value: data.body,
    },
    {
      name: __('od'),
      value: data.from_date
        ? moment(data.from_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Do'),
      value: data.to_date
        ? moment(data.to_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Priorita'),
      value: formatImportance(data.priority),
    },
    {
      name: __('Zrušená'),
      value: data.is_cancelled ? __('áno') : __('nie'),
    },
    {
      name: __('Vytvorená'),
      value: data.created_date
        ? moment(data.created_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Upravená'),
      value: data.updated_date
        ? moment(data.updated_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Zákazník'),
      value: data.customer_name,
    },
  ];
};

export const normalizeTaskData = data => {
  if (!data) {
    return [];
  }

  return [
    {
      name: __('Názov'),
      value: data.subject,
    },
    {
      name: __('Dôležitosť'),
      value: formatImportance(data.importance),
    },
    {
      name: __('Od'),
      value: data.start_date
        ? moment(data.start_date).format(FORMAT_HUMANE_DATE)
        : '',
    },
    {
      name: __('Do'),
      value: data.due_date
        ? moment(data.due_date).format(FORMAT_HUMANE_DATE)
        : '',
    },
    {
      name: __('Vytvorená'),
      value: data.created_date
        ? moment(data.created_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Upravená'),
      value: data.updated_date
        ? moment(data.updated_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Dokončená'),
      value: data.completed_date
        ? moment(data.completed_date).format(FORMAT_HUMANE_DATETIME)
        : '',
    },
    {
      name: __('Zákazník'),
      value: data.customer_name,
    },
  ];
};

export const normalizeLeads = (leads, path) => {
  if (!leads) {
    return [];
  }
  return leads.map(item => [
    {
      value: item.name,
      path: `${path}${item._id}`,
    },
    {
      value: item.estimated_revenue ? formatPrice(item.estimated_revenue) : '',
    },
    {
      value: formatProbability(item.probability),
    },
    {
      value: item.revenue ? formatPrice(item.revenue) : '',
    },
    {
      value: item.profit ? formatPrice(item.profit) : '',
    },
    {
      value: item.billing_address ? item.billing_address.city : '',
    },
    {
      value:
        item.crm_type && item.crm_type.length
          ? defaultCustomertype(item.crm_type)
          : '',
    },
  ]);
};

export const normalizeServices = (services, handleServiceOnClick, path) => {
  if (!services) {
    return [];
  }

  return services.reduce((acumulator, item) => {
    const row = [
      {
        value: formatDate(item.date),
      },
      {
        value: item.name,
        handleOnClick: handleServiceOnClick
          ? () => handleServiceOnClick(item._id)
          : null,
        path: path ? `${path}/${item._id}` : null,
      },
      {
        value: item.user_name || __('Nezadaný'),
      },
      {
        value: handleServiceStataus(item.status),
      },
      {
        value: item.updated_date
          ? formatDate(item.updated_date, FORMAT_HUMANE_DATETIME)
          : '',
      },
    ];
    acumulator.push(row);
    return acumulator;
  }, []);
};
export const getContactFullName = contact => {
  if (contact) {
    const restNames = [];
    if (contact.name && contact.name !== '') {
      restNames.push(contact.name);
    }
    if (contact.surname && contact.surname !== '') {
      restNames.push(contact.surname);
    }
    return restNames.join(' ');
  }
  return null;
};

export const isEmptyValidation = {
  validate: value => validator.isEmpty(String(value)),
  validWhen: false,
  errorMessage: __('Nevyplnená hodnota'),
};

export const isEmailValidation = {
  validate: value => validator.isEmail(value),
  validWhen: true,
  errorMessage: __('Nesprávne zadaný email'),
};

export const isNotNullEmailValidation = {
  validate: value =>
    !validator.isEmpty(value) ? validator.isEmail(value) : true,
  validWhen: true,
  errorMessage: __('Nesprávne zadaný email'),
};

export const isPhoneValidation = {
  validate: value =>
    validator.isMobilePhone(value, LOCALE, { strictMode: true }),
  validWhen: true,
  errorMessage: __('Nesprávne zadané telefónne čislo'),
};

export const isNotNullPhoneValidation = {
  validate: value =>
    !validator.isEmpty(value)
      ? validator.isMobilePhone(value, LOCALE, { strictMode: true })
      : true,
  validWhen: true,
  errorMessage: __('Nesprávne zadané telefónne čislo'),
};

// format DD.MM.YYYY
export const isDateValidation = {
  validate: value =>
    value.match(
      /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/,
    ),
  validWhen: !null,
  errorMessage: __('Nesprávne zadaný dátum'),
};

export const isMomentDateValidation = {
  validate: value => value && value.isValid(),
  validWhen: true,
  errorMessage: __('Nesprávne zadaný dátum'),
};

// format DD.MM.YYYY
export const isNotNullDateValidation = {
  validate: value =>
    !validator.isEmpty(value)
      ? value.match(
          /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/,
        )
      : true,
  validWhen: !null,
  errorMessage: __('Nesprávne zadaný dátum'),
};

export const isNumberValidation = {
  validate: value => validator.isNumeric(String(value)),
  validWhen: true,
  errorMessage: __('Nesprávne zadaná číselná hodnota'),
};

export const isNumberGreaterThanZeroValidation = {
  validate: value => validator.isNumeric(String(value)) && value > 0,
  validWhen: true,
  errorMessage: __('Nesprávne zadaná číselná hodnota'),
};

export const validateFormItems = items => {
  const newErrors = [];
  const data = items.map(item => {
    if (item.validation) {
      const e = item.validation.reduce((acumulator, validators) => {
        if (item.notRequired && (item.value === '' || item.value === null)) {
          return acumulator;
        }
        if (!validators.validate(item.value) === validators.validWhen) {
          acumulator.push(validators.errorMessage);
          return acumulator;
        }
        return acumulator;
      }, []);

      if (e.length) {
        for (let i = 0; i < e.length; i++) {
          if (!newErrors.includes(e[i])) {
            newErrors.push(e[i]);
          }
        }
        return { ...item, error: true };
      }
      return { ...item, error: false };
    }
    return item;
  });
  return [newErrors, data];
};

export const getFirmPrice = (firm, value) => {
  const d = {
    amount: value ? Number(value) : 0,
  };
  if (firm === 'CZ') {
    return Object.assign({}, d, { currency: 'CZK' });
  }
  if (firm === 'HU') {
    return Object.assign({}, d, { currency: 'HUF' });
  }

  return Object.assign({}, d, { currency: 'EUR' });
};

export const buildQuery = query => {
  const keys = Object.keys(query);
  const queryItems = keys.reduce((acum, key) => {
    const q = `${key}=${query[key]}`;
    acum.push(q);
    return acum;
  }, []);
  return queryItems.join('&');
};

export const parseQuery = query => {
  let q;
  if (query.startsWith('?')) {
    q = query.slice(1);
  } else {
    q = query;
  }
  const separated = q.split('&');
  const result = separated.reduce((acum, item) => {
    const splitted = item.split('=');
    if (!splitted.length || splitted.length !== 2) {
      return acum;
    }
    const [name, value] = splitted;
    return { ...acum, [name]: value };
  }, {});

  return result;
};

export const getRangeOfDates = (
  start,
  end,
  key = 'day',
  format = 'YYYY-MM-DD',
) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  if (endMoment.diff(startMoment) < 0) return [];

  return Array.from(Moment.range(startMoment, endMoment).by(key)).map(date =>
    date.format(format),
  );
};

export const generateWeek = (date, events = []) => {
  const day = moment(date);
  const startDate = moment(day.startOf('isoWeek'));
  const endDate = moment(day.endOf('isoWeek'));

  return getRangeOfDates(startDate, endDate).reduce((acum, item) => {
    return { ...acum, [item]: events[item] || [] };
  }, {});
};

export const handleRedirect = url => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else if (url.startsWith('www')) {
    return `http://${url}`;
  }
  return url;
};

export const handleLoadOptions = (query = '', callback) => {
  if (query !== '') {
    return callback();
  }
  return new Promise(resolve => resolve({ options: [] }));
};

export const replaceObjectInArray = (
  data,
  value,
  identificator = '_id',
  valueIdentificator = '_id',
) => {
  return data.map(i => {
    if (i[identificator] === value[valueIdentificator]) {
      return value;
    }
    return i;
  });
};

export const capitalizeFirstletter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatPriceForApi = (price = {}) => {
  return {
    amount: Number(price.amount) || null,
    currency: price.currency || null,
  };
};

export const goBack = async history => {
  if (window.history.length) {
    history.goBack();
  } else {
    history.push('../calendar');
  }
};

export const removeFirmFromUrl = pathName => {
  const url = `/${pathName
    .slice(1)
    .split('/')
    .slice(1)
    .join('/')}`;
  return url;
};

export const getLastPartOfUrl = url => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export const findInRecents = (pathname, recents) => {
  const keys = Object.keys(recents);
  for (let i = 0; i < keys.length; i++) {
    if (pathname.match(keys[i])) {
      return recents[keys[i]];
    }
  }
  return null;
};

export const formatOpportunityStatus = status => {
  switch (status) {
    case 'won':
      return __('Získaná');
    case 'lost':
      return __('Stratená');
    case 'new':
      return __('Nová');
    case 'cancelled':
      return __('Zrušená');
    default:
      return '';
  }
};

export const getIdFromProps = props => {
  if (props.match.params.id) {
    return props.match.params.id;
  }
  return null;
};

export const getPageIdFromProps = props => {
  if (props.match.params.pageId) {
    return props.match.params.pageId;
  }
  return null;
};

export const getPercentOf = (x, y) => {
  if (x && y) {
    return ((x / y) * 100).toFixed(3);
  }

  return 0;
};

export const roleDecoder = role => {
  return role;
};

export const getYearFromDate = date => {
  const dateObject = new Date(date);
  if (typeof dateObject.getFullYear === 'function') {
    return dateObject.getFullYear();
  }
  return null;
};

export const formatPaymentTerms = terms => {
  if (!terms) {
    return terms;
  }

  if (terms.startsWith('P')) {
    return `${terms.substr(1)} ${__('dní')}`;
  }
  if (terms === 'DOB') {
    return __('Dobierka');
  }
  if (terms === 'HOT') {
    return __('Hotovosť');
  }
  return terms;
};

export const formatActivityType = type => {
  switch (type) {
    case 'call':
      return __('Telefonát');
    case 'mail':
      return __('Email');
    case 'task':
      return __('Úloha');
    default:
      return __('Stretnutie');
  }
};

export const formatActivityStatus = status => {
  switch (status) {
    case 'waitingOnOthers':
      return __('Čakajúca');
    case 'completed':
      return __('Hotová');
    default:
      return __('Otvorená');
  }
};

export const getAvailableYears = () => {
  const years = [];
  for (let y = 2018; y <= new Date().getFullYear(); y++) {
    years.push(y);
  }
  return years;
};

export const normalizeCodelist = (codelist, lang = 'SK') => {
  if (codelist) {
    return codelist.map((item, index) => {
      return {
        key: index,
        value: item.value,
        display_name: translateCodelist(lang, item.translations, item.label),
      };
    });
  }

  return [];
};

export const getVariantParmasByProducttype = type => {
  if (type === 'FARBA') {
    return [
      {
        name: 'Báza',
        type: 'codelist',
        translations: [],
      },
      {
        name: 'Koeficient tónovania',
        type: 'string',
        value: 1,
        translations: [],
      },
      {
        name: 'Balenie',
        type: 'codelist',
        unit: 'kg/l',
        translations: [],
      },
      {
        name: 'Plnenie',
        type: 'codelist',
        unit: 'kg/l',
        translations: [],
      },
    ];
  } else if (type === 'SPREJ') {
    return [
      {
        name: 'Balenie',
        type: 'string',
        unit: 'L',
        translations: [],
      },
    ];
  }
  return [];
};

export const translateLayer = layer => {
  if (layer === 0) {
    return __('Impregnácia');
  } else if (layer === 1) {
    return __('1. vrstva');
  } else if (layer === 2) {
    return __('2. vrstva');
  } else if (layer === 3) {
    return __('3. vrstva');
  } else if (layer === 4) {
    return __('4. vrstva');
  }

  return layer;
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getCurrencyByFirm = currency => {
  if (currency === 'SK' || currency === 'AT') {
    return '€';
  } else if (currency === 'CZ') {
    return 'Kč';
  } else if (currency === 'EN') {
    return '£';
  } else if (currency === 'HU') {
    return 'Ft';
  }

  return currency;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (typeof bytes !== 'number') {
    return bytes;
  }
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const translateNewsletterStatus = status => {
  if (status === 'sent') {
    return __('Odoslané');
  }
  if (status === 'to_send') {
    return __('Nenaplánované');
  }

  if (status === 'scheduled') {
    return __('Naplánované');
  }

  return status;
};

export const translateUserPosition = role => {
  if (role === 'managing_director') {
    return __('Generálny riaditeľ');
  }
  if (role === 'marketing_manager') {
    return __('Marketingový manažér');
  }
  if (role === 'sales_representative') {
    return __('Obchodný zástupca');
  }
  if (role === 'sales_director') {
    return __('Obchodný riaditeľ');
  }
  if (role === 'sales_manager') {
    return __('Manažér predaja');
  }
  if (role === 'sales_assistant') {
    return __('Asisten predaja');
  }
  if (role === 'service_technician') {
    return __('Servisný technik');
  }
  if (role === 'service_technician') {
    return __('Aplikačný technik');
  }
  if (role === 'logistic_manager') {
    return __('Manažér logistiky');
  }
  if (role === 'it_manager') {
    return __('IT manažér');
  }
  if (role === 'marketing_assistant') {
    return __('Asistent marketingu');
  }

  if (role === 'customer') {
    return __('Zákazník');
  }

  return role;
};

export const fetchCodelistByQuery = async (query, codelistName) => {
  try {
    return await AdminAPI.getCodelist(codelistName).then(res => {
      return res.codelist
        .filter(c => c.label.toUpperCase().includes(query.toUpperCase()))
        .map(item => ({
          value: item.value || item._id,
          label: item.label,
          _id: item._id,
        }));
    });
  } catch (e) {
    return [];
  }
};

const Bold = styled.b`
  font-weight: ${props => props.weight};
`;

const Normal = styled.b`
  font-weight: ${props => props.weight};
`;

export const getHighlightedText = (
  text,
  highlight,
  normalWeight = 400,
  highlightedWeight = 800,
) => {
  // Split text on highlight term, include term itself into parts, ignore case

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <span>
      {parts.map(part =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <Bold weight={highlightedWeight}>{part}</Bold>
        ) : (
          <Normal weight={normalWeight}>{part}</Normal>
        ),
      )}
    </span>
  );
};

export const formatColorToRGB = color => {
  if (!color) {
    return `rgb(0,0,0)`;
  }

  return `rgb(${color.R || color.r},${color.G || color.g},${color.B ||
    color.b})`;
};

export const getYears = (from = 2010, to = 2019) => {
  const years = [];
  let year = from;
  while (year <= to) {
    years.push(year);
    year += 1;
  }

  return years;
};

export const getTranslatedData = (
  defaultValue,
  transArray,
  lang,
  fieldName,
) => {
  // toto pouzivaj, ak ti to data z API nepovoluju kontaktuj toho co robil API
  if (lang === 'SK') {
    return defaultValue || '';
  }
  if (!transArray) {
    return '';
  }
  const transObj = transArray.find(t => t.lang === lang);
  if (!transObj || !transObj[fieldName]) {
    return '';
  }
  return transObj[fieldName];
};

export const transDataDecode = (field, trans, value, lang) => {
  const data = {
    trans,
  };

  if (lang === 'SK') {
    data[field] = value;
  } else {
    if (!data.trans) {
      data.trans = [];
    }

    if (!data.trans.find(t => t.lang === lang)) {
      data.trans.push({
        lang,
      });
    }
    data.trans.find(t => t.lang === lang)[field] = value;
  }

  return data;
};
export const translateCampaignStatus = (status, dateTo) => {
  let transStatus = status || '';
  if (status === 'approved') {
    transStatus = __('Schválená');
  } else if (status === 'rejected') {
    transStatus = __('Zamietnutá');
  } else if (status === 'pending') {
    transStatus = __('Čaká na schvalenie');
  } else if (status === 'for_approval') {
    transStatus = __('Na schválenie');
  }

  if (new Date() >= new Date(dateTo)) {
    transStatus = `${transStatus} - ${__('Ukončená')}`;
  }

  return transStatus;
};

export const flatDeep = (arr, d = 1) => {
  return d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
        [],
      )
    : arr.slice();
};

export const getWordShapes = (number, shapes) => {
  if (number === 1) {
    return shapes[0];
  }

  if (number <= 4) {
    return shapes[1];
  }

  return shapes[2] || shapes[1];
};

export const getSyncNameType = type => {
  switch (type) {
    case 'customers':
      return 'Zákazníci';
    case 'products':
      return 'Produkty';
    case 'products-names':
      return 'Názvy produktov';
    case 'turnoverAndPlan':
      return 'Obraty';
    case 'colorCentrumInvoices':
      return 'Color Centrum Faktúry';
    case 'xpaint_catalog_products':
      return 'Katalogové produkty';
    case 'eshopOrdersStatus':
      return 'Stavy objednávok';
    case 'heureka-products':
      return 'Heuréka feed';
    case 'google-shopping-products':
      return 'Google shopping feed';
    case 'meta-pixel-products':
      return 'Meta pixel feed';
    case 'warratnyClaimsCheck':
      return 'Reklamácie a vrátenia notifkácie';
    case 'algolia-products':
      return 'Algolia - Produkty';
    case 'algolia-categories':
      return 'Algolia - Kategórie';
    case 'algolia-inspirations':
      return 'Algolia - Inšpirácie';
    case 'algolia-news':
      return 'Algolia - Rady a tipy';
    case 'flugger-products':
      return 'Algolia - Flugger';
    default:
      return '-';
  }
};
export const getSyncNameStatus = status => {
  switch (status) {
    case 'running':
      return 'Bežiace';
    case 'success':
      return 'Úspešné';
    case 'failed':
      return 'Neúspešné';
    default:
      return '-';
  }
};
export const getSyncNameByType = (type, syncDate, firm = null) => {
  switch (type) {
    case 'customers':
      return `${__('Synchronizácia zákazníkov')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'products':
      return `${__('Synchronizácia produktov')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'products-names':
      return `${__('Synchronizácia názov produktov')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'turnoverAndPlan':
      return `${__('Synchronizácia obratov')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'colorCentrumInvoices':
      return `${__('Synchronizácia faktúr pre Color Centrum')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'xpaint_catalog_products':
      return `${__('Synchronizácia produktov v eshope')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'eshopOrdersStatus':
      return `${__('Stavy objednávok')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'heureka-products':
      return `${__('Heuréka feed aktualizácia')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'google-shopping-products':
      return `${__('Google shopping feed aktualizácia')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'meta-pixel-products':
      return `${__('Meta pixel feed aktualizácia')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'warratnyClaimsCheck':
      return `${__('Notifkácie pre reklamácíe')} - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'algolia-products':
      return `${__('Algolia - Produkty')} [${firm}] - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'algolia-categories':
      return `${__('Algolia - Kategórie')} [${firm}] - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'algolia-inspirations':
      return `${__('Algolia - Inšpirácie')} [${firm}] - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'algolia-news':
      return `${__('Algolia - Rady a tipy')} [${firm}] - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    case 'flugger-products':
      return `${__('Algolia - Flugger')} [${firm}] - ${formatDate(
        moment(syncDate),
        FORMAT_HUMANE_DATETIME,
      )}`;
    default:
      return '';
  }
};

export const getDurationFromDates = (startTime, endTime) => {
  let duration = moment(endTime) - moment(startTime);
  if (duration < 0) {
    return '0ms';
  }
  let days = '';
  let hours = '';
  let minutes = '';
  let seconds = '';
  let milliseconds = '';

  if (Math.floor(duration / (24 * 60 * 60 * 1000)) > 1) {
    const daysNum = Math.floor(duration / (24 * 60 * 60 * 1000));
    duration -= daysNum * (24 * 60 * 60 * 1000);
    days = `${daysNum}d `;
  }
  if (Math.floor(duration / (60 * 60 * 1000)) > 1) {
    const hoursNum = Math.floor(duration / (60 * 60 * 1000));
    duration -= hoursNum * (60 * 60 * 1000);
    hours = `${hoursNum}h `;
  }
  if (Math.floor(duration / (60 * 1000)) > 1) {
    const minutesNum = Math.floor(duration / (60 * 1000));
    duration -= minutesNum * (60 * 1000);
    minutes = `${minutesNum}min `;
  }
  if (Math.floor(duration / 1000) > 1) {
    const secondsNum = Math.floor(duration / 1000);
    duration -= secondsNum * 1000;
    seconds = `${secondsNum}s `;
  }
  if (duration > 0) {
    const millisecondsNum = duration;
    milliseconds = `${millisecondsNum}ms`;
  }
  const result = days + hours + minutes + seconds + milliseconds;
  return result;
};

export const validateYouTubeUrl = url => {
  const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
};

export function getFrimByCurrency(currency) {
  if (currency === 'CZK') {
    return 'CZ';
  }
  return 'SK';
}

export const getVatRateByCompanyId = firm => {
  switch (firm) {
    case 'CZ':
    case 'CR':
      return 21;
    default:
      return 20;
  }
};

export const addVatToPrice = price => {
  if (!price || !price.amount) {
    return null;
  }

  const { amount, currency } = price;

  const firm = getFrimByCurrency(currency);
  const vat = getVatRateByCompanyId(firm) / 100;

  const priceWithVat = amount + amount * vat;

  return {
    amount: priceWithVat,
    currency,
  };
};

export const roundToTwoDecimals = num => {
  return Math.round(num * 100) / 100;
};

export const getPriceWithVat = (firm, price) => {
  return roundToTwoDecimals(
    ((100 + getVatRateByCompanyId(firm)) / 100) * price,
  );
};

export const getPriceWithoutVat = (firm, price) => {
  return roundToTwoDecimals(
    price / ((100 + getVatRateByCompanyId(firm)) / 100),
  );
};

export const getDiscountPercents = (price, priceWD) => {
  if (priceWD === 0 || price === 0) {
    return 0;
  }

  return roundToTwoDecimals(100 - (100 * price) / priceWD);
};
