export const signature = [
  {
    cells: [1],
    columns: [
      {
        contents: [
          {
            type: 'text',
            values: {
              containerPadding: '10px',
              _meta: {
                htmlID: 'u_content_text_2',
                htmlClassNames: 'u_content_text',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              color: '#000000',
              textAlign: 'left',
              lineHeight: '140%',
              linkStyle: {
                inherit: true,
                linkColor: '#0000ee',
                linkHoverColor: '#0000ee',
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              hideMobile: false,
              text:
                '<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 14px; line-height: 19.6px;">{{nice_day_label}},</span></p>',
            },
          },
        ],
        values: {
          backgroundColor: '',
          padding: '0px',
          border: {},
          _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: '',
      columnsBackgroundColor: '',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      padding: '0px',
      hideDesktop: false,
      hideMobile: false,
      noStackMobile: false,
      _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      id: '__currentSignature',
    },
  },
  {
    cells: [1, 1],
    columns: [
      {
        contents: [
          {
            type: 'image',
            values: {
              containerPadding: '20px',
              _meta: {
                htmlID: 'u_content_image_1',
                htmlClassNames: 'u_content_image',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              src: {
                url: '{{senderImage}}',
                width: 200,
                height: 200,
                autoWidth: false,
                maxWidth: '60%',
              },
              textAlign: 'center',
              altText: 'Image',
              action: { name: 'web', values: { href: '', target: '_blank' } },
              hideDesktop: false,
              hideMobile: false,
            },
          },
        ],
        values: {
          backgroundColor: '',
          padding: '0px',
          border: {},
          _meta: { htmlID: 'u_column_2', htmlClassNames: 'u_column' },
        },
      },
      {
        contents: [
          {
            type: 'text',
            values: {
              containerPadding: '10px',
              _meta: {
                htmlID: 'u_content_text_3',
                htmlClassNames: 'u_content_text',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              color: '#000000',
              textAlign: 'left',
              lineHeight: '140%',
              linkStyle: {
                inherit: true,
                linkColor: '#0000ee',
                linkHoverColor: '#0000ee',
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              hideMobile: false,
              text:
                '<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 14px; line-height: 19.6px;">{{senderName}}</span></p>\n<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 12px; line-height: 16.8px;">{{senderRole}}</span></p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 12px; line-height: 16.8px;">tel: {{senderPhone}}</span></p>\n<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 12px; line-height: 16.8px;">e-mail: {{senderEmail}}</span></p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 12px; line-height: 16.8px;">{{dejmarkSlogan}}</span></p>\n<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 12px; line-height: 16.8px;">{{domain}}</span></p>',
            },
          },
        ],
        values: {
          backgroundColor: '',
          padding: '0px',
          border: {},
          _meta: { htmlID: 'u_column_3', htmlClassNames: 'u_column' },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: '',
      columnsBackgroundColor: '',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      padding: '0px',
      hideDesktop: false,
      hideMobile: false,
      noStackMobile: false,
      _meta: { htmlID: 'u_row_2', htmlClassNames: 'u_row' },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      id: '__currentSignature',
    },
  },
  // {
  //   cells: [1, 1, 1],
  //   columns: [
  //     {
  //       contents: [
  //         {
  //           type: 'image',
  //           values: {
  //             containerPadding: '10px',
  //             _meta: {
  //               htmlID: 'u_content_image_6',
  //               htmlClassNames: 'u_content_image',
  //             },
  //             selectable: true,
  //             draggable: true,
  //             duplicatable: true,
  //             deletable: true,
  //             src: {
  //               url: 'https://via.placeholder.com/100x100?text=IMAGE',
  //               width: 100,
  //               height: 100,
  //               autoWidth: false,
  //               maxWidth: '30%',
  //             },
  //             textAlign: 'right',
  //             altText: 'Image',
  //             action: { name: 'web', values: { href: '', target: '_blank' } },
  //             hideDesktop: false,
  //             hideMobile: false,
  //           },
  //         },
  //       ],
  //       values: {
  //         backgroundColor: '',
  //         padding: '0px',
  //         border: {},
  //         _meta: { htmlID: 'u_column_4', htmlClassNames: 'u_column' },
  //       },
  //     },
  //     {
  //       contents: [
  //         {
  //           type: 'image',
  //           values: {
  //             containerPadding: '10px',
  //             _meta: {
  //               htmlID: 'u_content_image_5',
  //               htmlClassNames: 'u_content_image',
  //             },
  //             selectable: true,
  //             draggable: true,
  //             duplicatable: true,
  //             deletable: true,
  //             src: {
  //               url: 'https://via.placeholder.com/100x100?text=IMAGE',
  //               width: 100,
  //               height: 100,
  //               autoWidth: false,
  //               maxWidth: '30%',
  //             },
  //             textAlign: 'center',
  //             altText: 'Image',
  //             action: { name: 'web', values: { href: '', target: '_blank' } },
  //             hideDesktop: false,
  //             hideMobile: false,
  //           },
  //         },
  //       ],
  //       values: {
  //         backgroundColor: '',
  //         padding: '0px',
  //         border: {},
  //         _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' },
  //       },
  //     },
  //     {
  //       contents: [
  //         {
  //           type: 'image',
  //           values: {
  //             containerPadding: '10px',
  //             _meta: {
  //               htmlID: 'u_content_image_4',
  //               htmlClassNames: 'u_content_image',
  //             },
  //             selectable: true,
  //             draggable: true,
  //             duplicatable: true,
  //             deletable: true,
  //             src: {
  //               url: 'https://via.placeholder.com/100x100?text=IMAGE',
  //               width: 100,
  //               height: 100,
  //               autoWidth: false,
  //               maxWidth: '30%',
  //             },
  //             textAlign: 'left',
  //             altText: 'Image',
  //             action: { name: 'web', values: { href: '', target: '_blank' } },
  //             hideDesktop: false,
  //             hideMobile: false,
  //           },
  //         },
  //       ],
  //       values: {
  //         backgroundColor: '',
  //         padding: '0px',
  //         border: {},
  //         _meta: { htmlID: 'u_column_6', htmlClassNames: 'u_column' },
  //       },
  //     },
  //   ],
  //   values: {
  //     displayCondition: null,
  //     columns: false,
  //     backgroundColor: '#f8f8f8',
  //     columnsBackgroundColor: '',
  //     backgroundImage: {
  //       url: '',
  //       fullWidth: true,
  //       repeat: false,
  //       center: true,
  //       cover: false,
  //     },
  //     padding: '0px',
  //     hideDesktop: false,
  //     hideMobile: false,
  //     noStackMobile: false,
  //     _meta: { htmlID: 'u_row_3', htmlClassNames: 'u_row' },
  //     selectable: true,
  //     draggable: true,
  //     duplicatable: true,
  //     deletable: true,
  //     id: '__currentSignature',
  //   },
  // },
  {
    cells: [1],
    columns: [
      {
        contents: [
          {
            type: 'text',
            values: {
              containerPadding: '10px',
              _meta: {
                htmlID: 'u_content_text_4',
                htmlClassNames: 'u_content_text',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              color: '#000000',
              textAlign: 'left',
              lineHeight: '140%',
              linkStyle: {
                inherit: true,
                linkColor: '#0000ee',
                linkHoverColor: '#0000ee',
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              hideMobile: false,
              text:
                '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 12px; line-height: 16.8px; color: #95a5a6;">{{footer_text}}</span></p>\n<p style="font-size: 14px; line-height: 140%; text-align: center;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%; text-align: center;">&nbsp;</p>',
            },
          },
        ],
        values: {
          backgroundColor: '',
          padding: '0px',
          border: {},
          _meta: { htmlID: 'u_column_7', htmlClassNames: 'u_column' },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: '#f8f8f8',
      columnsBackgroundColor: '',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      padding: '0px',
      hideDesktop: false,
      hideMobile: false,
      noStackMobile: false,
      _meta: { htmlID: 'u_row_4', htmlClassNames: 'u_row' },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      id: '__currentSignature',
    },
  },
];
