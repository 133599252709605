import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'oxyrion-ui/lib/Icon';
import { __ } from '../../../../Utils';

const SearchWrapper = styled.div`
  width: ${rem(260)};
  height: ${rem(32)};
  /* border: ${rem(1)} solid #ee6500; */
  background: white;
  margin-left: ${rem(8)};
  border-radius: ${rem(2)};
  display: flex;
`;

const SearchButtonMainPartWrapper = styled.div`
  display: flex;
  width: ${rem(250)};
  height: ${rem(32)};
`;

const SearchButton = styled.div`
  width: ${rem(90)};
  height: ${rem(32)};
  background: #ee6500;
  color: white;
  font-size: ${rem(14)};
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border-radius: ${rem(0)} ${rem(3)} ${rem(3)} ${rem(0)};
`;

const StyledInput = styled.input`
  width: ${rem(160)};
  height: ${rem(30)};
  border: 0;
  outline: 0;
  font-size: ${rem(12)};
  color: #434245;
  ::placeholder {
    color: #f0f0f0;
    font-size: ${rem(12)};
    opacity: 1;
  }
`;

const IconWrapper = styled.div`
  height: ${rem(32)};
  width: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

class AsyncSearchInput extends React.Component {
  state = {
    searchText: '',
  };

  componentWillReceiveProps(nextProps) {
    const { defaultText } = this.props;

    if (defaultText !== nextProps.defaultText) {
      this.setState({
        searchText: nextProps.defaultText,
      });
    }
  }

  render() {
    const { searchText } = this.state;
    const { loadOptions } = this.props;

    return (
      <SearchWrapper>
        <SearchButtonMainPartWrapper>
          <IconWrapper>
            <Icon name="search" color="#F0F0F0" />
          </IconWrapper>
          <StyledInput
            placeholder={__('Vyhľadať')}
            value={searchText}
            onChange={e => {
              const v = e.target.value;
              this.setState({
                searchText: v,
              });

              window.clearTimeout(this.currentFetchTimeout);
              this.currentFetchTimeout = window.setTimeout(() => {
                loadOptions(v);
              }, 600);
            }}
          />
        </SearchButtonMainPartWrapper>
        <SearchButton onClick={() => loadOptions(searchText)}>
          {__('Hľadať')}
        </SearchButton>
      </SearchWrapper>
    );
  }
}

AsyncSearchInput.propTypes = {
  defaultText: PropTypes.string,
  loadOptions: PropTypes.func,
};

AsyncSearchInput.defaultProps = {
  defaultText: '',
  loadOptions: () => {},
};
export default AsyncSearchInput;
