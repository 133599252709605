import React from 'react';
import styled from 'styled-components';
import { Icon } from 'oxyrion-ui/lib';
import { __, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import MarginEdit from '../../Components/MarginEditor';

const IconWrapper = styled.div`
  cursor: pointer;
`;

class Margins extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = [];
    this.segmentName = __('Marže');
    this.accessors = [];
    this.showBulkOperations = false;
    this.setSorter('name');
    this.state = {
      ...this.state,
      idToEdit: null,
    };
  }

  handleProductOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/users/${id}`);
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter } = this.state;
      this.setState({ loading: true });
      const params = Object.assign({}, parameters, {
        sorter,
        companyId: this.props.firm,
      });
      const newData = await AdminAPI.getCustomerMarginsAction(params);
      const content = this.normalizeColumns(newData.customer_margins);
      const headers = this.normalizeHeaders(newData.customer_margins[0]);

      this.headers = headers;

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        idToEdit: null,
        lastSearchValue: params.q || '',
        rawData: newData.customer_margins,

        // headers,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeHeaders(data) {
    const h = [
      {
        name: __('Meno zákazníka'),
        clickable: true,
        sorterValue: 'name',
        handleOnClick: (sorter, ascending) => this.sort(sorter, ascending),
        accessor: 'customer_name',
      },
    ];

    if (!data || !data.margins) {
      return h;
    }

    if (data.margins.product_groups) {
      data.margins.product_groups.forEach(g => {
        h.push({
          name: `${g.name} (%)`,
          accessor: g._id,
          clickable: false,
          handleOnClick: null,
          otherProps: {
            maxWidth: 150,
          },
        });
      });
    }

    if (data.margins.toning_systems) {
      data.margins.toning_systems.forEach(t => {
        h.push({
          name: `${t.name} (%)`,
          accessor: t._id,
          clickable: false,
          handleOnClick: null,
          otherProps: {
            maxWidth: 150,
          },
        });
      });
    }

    h.push({
      name: __('Upraviť'),
      accessor: 'edit',
      clickable: false,
      handleOnClick: null,
      otherProps: {
        maxWidth: 130,
      },
    });

    return h;
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumn(item, data) {
    const { idToEdit } = this.state;

    const _shapeData = (value, handleOnClick = null) => {
      return {
        value: value || 0,
        handleOnClick: handleOnClick || null,
      };
    };

    const obj = {
      customer_id: item.customer_id,
      customer_name: _shapeData(
        renderLink(`./users/${item._id}`, `${item.customer_name}`),
        () => this.handleProductOnClick(item._id),
      ),
    };

    if (item.margins) {
      if (item.margins.product_groups) {
        item.margins.product_groups.forEach(g => {
          obj[g._id] = _shapeData(g.value);
        });
      }
      if (item.margins.toning_systems) {
        item.margins.toning_systems.forEach(t => {
          obj[t._id] = _shapeData(t.value);
        });
      }

      if (idToEdit === null) {
        obj.edit = {
          value: (
            <IconWrapper
              onClick={() => {
                this.setState(
                  {
                    idToEdit: item.customer_id,
                    loading: true,
                  },
                  () => {
                    this.setState({
                      content: this.normalizeColumns(data),
                      loading: false,
                    });
                  },
                );
              }}
            >
              <Icon name="edit" />
            </IconWrapper>
          ),
        };
      }
    }

    return obj;
  }

  normalizeColumns(data) {
    const result = data.reduce((acumulator, item) => {
      const obj = this.normalizeColumn(item, data);

      acumulator.push(obj);

      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar
        history={history}
        name={__('Marže')}
        defaultValue={this.state.lastSearchValue}
        onChange={val => this.loadSearchRrsult(val)}
      />
    );
  }

  render() {
    const { idToEdit } = this.state;

    return (
      <React.Fragment>
        <PopUpWrapper
          small
          customWidth={450}
          display={this.state.idToEdit}
          onClose={() =>
            this.setState({
              idToEdit: undefined,
            })
          }
          message={__('Úprava marže')}
          onEscClose={() =>
            this.setState({
              idToEdit: undefined,
            })
          }
        >
          <MarginEdit
            customerId={idToEdit}
            callback={() =>
              this.setState({ idToEdit: null }, () => {
                this.fetchData({
                  q: this.state.lastSearchValue,
                });
              })
            }
          />
        </PopUpWrapper>
        {super.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Margins);
