import { __ } from '../Utils';

export default [
  {
    name: __('Popis'),
    clickable: false,
    otherProps: { minWidth: 300 },
  },
  {
    name: __('Kód'),
    clickable: true,
    sorterValue: 'code',
  },
  {
    name: __('Typ'),
    clickable: true,
    sorterValue: 'type',
  },
  {
    name: __('Trieda'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
    otherProps: { maxWidth: 130 },
  },
];
