import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: false,
  },
  {
    name: __('Podtypy'),
    clickable: false,
  },
  {
    name: __('Dátum úpravy'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
];
