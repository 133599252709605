import { __ } from '../Utils';

export default [
  {
    name: __('Produkt'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 400 },
  },
  {
    name: __('Číslo produktu'),
    clickable: true,
    sorterValue: 'product_nav_id',
    otherProps: { minWidth: 150 },
  },
  // {
  //   name: __('Platný od'),
  //   clickable: true,
  //   sorterValue: 'valid_from',
  //   otherProps: { minWidth: 150 },
  // },
  // {
  //   name: __('Platný do'),
  //   clickable: true,
  //   sorterValue: 'valid_to',
  //   otherProps: { minWidth: 150 },
  // },
  {
    name: __('Deaktivovaný'),
    clickable: false,
    otherProps: { minWidth: 100 },
  },
  {
    name: __('Publikovane pre E-shop'),
    clickable: false,
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Publikovane pre Portal'),
    clickable: false,
    otherProps: { minWidth: 150 },
  },
  {
    name: __('Percentuálna zľava'),
    sorterValue: 'discount_percent',
    otherProps: { minWidth: 100 },
  },
  {
    name: __('Pevná cena'),
    sorterValue: 'price',
    otherProps: { minWidth: 100 },
  },
  {
    name: __('Editovať'),
    otherProps: { minWidth: 200 },
  },
];
