import React from 'react';
import PropTypes from 'prop-types';
import { actions } from '../Store';

class StoreSynchorizer extends React.Component {
  componentDidMount() {
    actions.setFirm(this.props.firm);
    // actions.setYear(this.props.year);
  }
  render() {
    return this.props.children;
  }
}

StoreSynchorizer.propTypes = {
  firm: PropTypes.string.isRequired,
  // year: PropTypes.number.isRequired,
};

export default StoreSynchorizer;
