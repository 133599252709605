import { __ } from '../Utils';

export default [
  {
    key: 1,
    value: 'white',
    display_name: __('Biela'),
  },
  {
    key: 2,
    value: 'black',
    display_name: __('Čierna'),
  },
  {
    key: 3,
    value: 'red',
    display_name: __('Červená'),
  },
  {
    key: 16,
    value: 'yellow',
    display_name: __('Žltá'),
  },
  {
    key: 4,
    value: 'purple',
    display_name: __('Fialová'),
  },
  {
    key: 5,
    value: 'brown',
    display_name: __('Hnedá'),
  },
  {
    key: 6,
    value: 'blue',
    display_name: __('Modrá'),
  },
  {
    key: 7,
    value: 'grey',
    display_name: __('Šedá'),
  },
  {
    key: 8,
    value: 'green',
    display_name: __('Zelená'),
  },
  {
    key: 9,
    value: 'orange',
    display_name: __('Oranžová'),
  },
  {
    key: 10,
    value: 'turquoise',
    display_name: __('Tyrkysová'),
  },
  {
    key: 11,
    value: 'beige',
    display_name: __('Béžová'),
  },
  {
    key: 12,
    value: 'silver',
    display_name: __('Strieborná'),
  },
  {
    key: 13,
    value: 'gold',
    display_name: __('Zlatá'),
  },
  {
    key: 14,
    value: 'copper',
    display_name: __('Medená'),
  },
  {
    key: 15,
    value: 'pearly',
    display_name: __('Perleťová'),
  },
];
