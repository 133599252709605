import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
  },
  {
    name: __('Kategória'),
    clickable: false,
  },
  {
    name: __('Typ súboru'),
    clickable: false,
  },
  {
    name: __('Veľkosť súboru'),
    clickable: false,
  },
  {
    name: __('Aktualizácia'),
    clickable: true,
    sorterValue: 'updated_date',
  },
  {
    name: __(''),
    otherProps: { maxWidth: 50 },
    clickable: false,
  },
];
