import React from 'react';
import PropTypes from 'prop-types';
// import cookie from 'react-cookies';
import styled from 'styled-components';
import { Button, Icon } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import Table from '../../../../Components/TableV2';
import { __, formatPrice } from '../../../../Utils';
import { normalizeVariantsAndCheckSelected } from '../../CatalogUtils';
import ProductNameLabel from '../ProductNameLabel';
import Checkbox from 'oxyrion-ui/lib/Checkbox';

const MainWrapper = styled.div`
  padding-right: ${rem(8)};
`;

const StyledHeader = styled.div`
  display: flex;
  margin-left: 1px;
  font-size: 11px;
  color: #434245;
  margin-top: 15px;
`;

const HeaderUnderText = styled.div`
  font-size: 11px;
  text-align: start;
  color: #a9a9a9;
  margin-left: 2px;
`;

const HeaderWrapper = styled.div``;

const StyledTd = styled.div`
  display: flex;
  margin-left: ${({ isComp }) => (isComp ? '8px' : '2px')};
  /* height: ${({ isComp, hasComplementars, nomComplementars }) =>
    isComp || hasComplementars || nomComplementars ? '15px' : '28px'}; */
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 11px;
  color: ${({ isComp }) => (isComp ? '#ee6500' : '#434245')};
  font-weight: ${({ isComp }) => (isComp ? '700' : '500')};
`;

const AddToCardWrapper = styled.div`
  height: ${rem(32)};
  width: ${rem(32)};
  border-radius: ${rem(2)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ShowComp = styled.div`
  /* position: absolute; */
  font-size: 12px;
  position: absolute;
  left: 4px;
  font-size: 12px;
  position: absolute;
  top: 20px;
  color: #1d70b8;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const NameWrapper = styled.div`
  position: relative;
  font-size: ${rem(10)};
`;

const TdAndIconWrapper = styled.div`
  display: flex;
`;

const IconWrapperCustom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(8)};
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
  display: flex;
  justify-content: flex-end;
`;

class CatalogProductVariants extends React.Component {
  state = {
    displayedComplementarsFor: [],
    displayedColumns: {},
    selectedVariants: [],
  };

  componentDidMount() {
    let displayedColumns = this.props.settings && this.props.settings.products;

    if (!displayedColumns) {
      displayedColumns = {
        price: true,
        price_vat: true,
        price_wd: false,
        price_wd_vat: false,
        disableSave: false,
        ean: true,
        volume: true,
        inventory: false,
      };
    }

    this.setState({
      displayedColumns,
    });
  }

  componentWillReceiveProps(nextProps) {
    let displayedColumns = nextProps.settings && nextProps.settings.products;

    const selectedVariants = [];

    nextProps.data.forEach(d => {
      selectedVariants.push(d._id);

      if (d.complementarsProducts && d.complementarsProducts.length) {
        d.complementarsProducts.forEach(c => {
          selectedVariants.push(c._id);
        });
      }
    });

    if (!displayedColumns) {
      displayedColumns = {
        price: true,
        price_vat: true,
        price_wd: false,
        price_wd_vat: false,
        disableSave: false,
        ean: true,
        volume: true,
        inventory: false,
      };
    }

    this.setState({
      displayedColumns,
      selectedVariants,
    });
  }

  showComProducts(variantId) {
    const { displayedComplementarsFor } = this.state;
    displayedComplementarsFor.push(variantId);

    this.setState({
      displayedComplementarsFor,
    });
  }

  insertVariants() {
    const { selectedVariants } = this.state;
    const { data } = this.props;

    const complementarsToAdd = data
      .map(d =>
        d.complementarsProducts.filter(
          c => selectedVariants.indexOf(c._id) > -1,
        ),
      )
      .flat()
      .map(c => ({
        label: c.name,
        value: c._id,
        product: c,
      }));

    const dataToSend = data
      .filter(d => selectedVariants.indexOf(d._id) > -1)
      .map(d => ({
        label: d.name,
        value: d._id,
        product: d,
      }));

    this.props.insertVariants(dataToSend.concat(complementarsToAdd));
  }

  createColumns() {
    const { hasToningPrice } = this.props;
    const hasVolume = this.props.data.find(d => d.volume);
    const hasInternalDiameter = this.props.data.find(d => d.internal_diameter);
    const hasWidth = this.props.data.find(d => d.width);
    const hasBase = this.props.data.find(
      d => d.base && d.base !== 'NEDEFINOVANÁ',
    );
    const hasThickness = this.props.data.find(d => d.thickness);
    const hasBristlesLength = this.props.data.find(d => d.bristles_length);
    const hasFrameSize = this.props.data.find(d => d.frame_size);
    const hasFiberHeight = this.props.data.find(d => d.fiber_height);
    const hasDimension = this.props.data.find(d => d.dimension);
    const hasLength = this.props.data.find(d => d.length);

    const { displayedColumns } = this.state;
    const result = [
      {
        accessor: '_id',
        Header: () => <StyledHeader>{__('Číslo')}</StyledHeader>,
        maxWidth: 90,
        Cell: props => {
          return (
            <TdAndIconWrapper>
              {props.original.type === 'compProduct' && (
                <IconWrapperCustom title={__('Komplementárny produkt')}>
                  <Icon name="plus" color="#ee6500" />
                </IconWrapperCustom>
              )}

              <StyledTd
                nomComplementars={
                  !props.original.complementarsProducts ||
                  props.original.complementarsProducts.length === 0
                }
                hasComplementars={
                  props.original.complementarsProducts &&
                  props.original.complementarsProducts.length &&
                  this.state.displayedComplementarsFor.indexOf(
                    props.original._id,
                  ) > -1
                }
                isComp={props.original.type === 'compProduct'}
              >
                {props.value}
              </StyledTd>
            </TdAndIconWrapper>
          );
        },
      },
    ];

    if (displayedColumns.ean) {
      result.push({
        accessor: 'ean',
        Header: () => <StyledHeader>{__('Ean')}</StyledHeader>,
        maxWidth: 120,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value}
            </StyledTd>
          );
        },
      });
    }

    result.push({
      accessor: 'name',
      Header: () => <StyledHeader>{__('Názov')}</StyledHeader>,
      minWidth: window.innerWidth > 1200 ? 180 : 80,
      Cell: props => {
        return (
          <NameWrapper
            onMouseEnter={() => this.props.onVariantSelect(props.index)}
            style={{
              marginBottom:
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) < 0 &&
                rem(15),
            }}
          >
            <ProductNameLabel
              productName={
                <StyledTd
                  style={{
                    marginBottom: props.original.benefit_data && rem(0),
                    marginTop: props.original.benefit_data && rem(2),
                  }}
                  nomComplementars={
                    !props.original.complementarsProducts ||
                    props.original.complementarsProducts.length === 0
                  }
                  hasComplementars={
                    props.original.complementarsProducts &&
                    props.original.complementarsProducts.length &&
                    this.state.displayedComplementarsFor.indexOf(
                      props.original._id,
                    ) > -1
                  }
                  isComp={props.original.type === 'compProduct'}
                >
                  {`${props.value} ${
                    props.original.finished_shade
                      ? `-${__('hotový odieň')} - ${
                          props.original.finished_shade
                        }`
                      : ''
                  }`}
                </StyledTd>
              }
              benefitData={props.original.benefit_data}
            />
            {props.original.complementarsProducts &&
            props.original.complementarsProducts.length &&
            this.state.displayedComplementarsFor.indexOf(props.original._id) <
              0 ? (
              <ShowComp
                style={{
                  top: props.original.benefit_data ? rem(32) : rem(12),
                }}
                onClick={() =>
                  this.showComProducts(props.original._id || props.original._id)
                }
              >{`${__('Zobraziť')} ${
                props.original.complementarsProducts.length
              } ${
                props.original.complementarsProducts.length !== 0
                  ? __('komplementárny produkt')
                  : __('komplementárne produkty')
              }`}</ShowComp>
            ) : (
              ''
            )}
          </NameWrapper>
        );
      },
    });

    if (hasVolume && displayedColumns.volume) {
      result.push({
        accessor: 'volume_with_unit',
        Header: () => <StyledHeader>{__('Objem')}</StyledHeader>,
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasInternalDiameter) {
      result.push({
        accessor: 'internal_diameter',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Priemer')}</StyledHeader>
            <HeaderUnderText>{__('vnútorný')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasBase) {
      result.push({
        accessor: 'base',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Báza')}</StyledHeader>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value && props.value !== 'NEDEFINOVANÁ'
                ? props.value
                : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasWidth) {
      result.push({
        accessor: 'width',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Šírka')}</StyledHeader>
            <HeaderUnderText>{`(${__('priemer')})`}</HeaderUnderText>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasThickness) {
      result.push({
        accessor: 'thickness',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Hrúbka')}</StyledHeader>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasBristlesLength) {
      result.push({
        accessor: 'bristles_length',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Dľžka')}</StyledHeader>
            <HeaderUnderText>{__('štetín')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasFrameSize) {
      result.push({
        accessor: 'frame_size',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Rám')}</StyledHeader>
            <HeaderUnderText>{__('priemer')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasFiberHeight) {
      result.push({
        accessor: 'fiber_height',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Výška')}</StyledHeader>
            <HeaderUnderText>{__('vlákna')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasDimension) {
      result.push({
        accessor: 'dimension',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Rozmer')}</StyledHeader>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (hasLength) {
      result.push({
        accessor: 'length',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Dľžka')}</StyledHeader>
          </HeaderWrapper>
        ),
        width: 85,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value ? props.value : 'N/A'}
            </StyledTd>
          );
        },
      });
    }

    if (displayedColumns.inventory) {
      result.push({
        accessor: 'on_stock',
        maxWidth: 65,
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Zásoby')}</StyledHeader>
            <HeaderUnderText>{__('k dátumu')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value}
            </StyledTd>
          );
        },
      });
    }

    if (displayedColumns.price && this.props.role !== 'b2b_user') {
      result.push({
        accessor: 'price',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Nákupná cena')}</StyledHeader>
            <HeaderUnderText>{__('bez DPH')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        minWidth: 80,
        maxWidth: 95,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {formatPrice(props.value)}
            </StyledTd>
          );
        },
      });
    }

    if (displayedColumns.price_vat && this.props.role !== 'b2b_user') {
      result.push({
        accessor: 'price_vat',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Nákupná cena')}</StyledHeader>
            <HeaderUnderText>{__('s DPH')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        minWidth: 80,
        maxWidth: 95,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {formatPrice(props.value)}
            </StyledTd>
          );
        },
      });
    }

    if (displayedColumns.price_wd) {
      result.push({
        accessor: 'price_wd',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Predajná cena')}</StyledHeader>
            <HeaderUnderText>{__('bez DPH')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        minWidth: 80,
        maxWidth: 95,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {formatPrice(props.value)}
            </StyledTd>
          );
        },
      });
    }

    if (displayedColumns.price_wd_vat) {
      result.push({
        accessor: 'price_wd_vat',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Predajná cena')}</StyledHeader>
            <HeaderUnderText>{__('s DPH')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        minWidth: 80,
        maxWidth: 95,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {formatPrice(props.value)}
            </StyledTd>
          );
        },
      });
    }

    if (hasToningPrice) {
      result.push({
        accessor: 'toningPrice',
        Header: () => (
          <HeaderWrapper>
            <StyledHeader>{__('Cena')}</StyledHeader>
            <HeaderUnderText>{__('s tónovaním')}</HeaderUnderText>
          </HeaderWrapper>
        ),
        minWidth: 80,
        maxWidth: 95,
        Cell: props => {
          return (
            <StyledTd
              nomComplementars={
                !props.original.complementarsProducts ||
                props.original.complementarsProducts.length === 0
              }
              hasComplementars={
                props.original.complementarsProducts &&
                props.original.complementarsProducts.length &&
                this.state.displayedComplementarsFor.indexOf(
                  props.original._id,
                ) > -1
              }
              isComp={props.original.type === 'compProduct'}
            >
              {props.value && props.value.amount
                ? formatPrice(props.value)
                : props.value}
            </StyledTd>
          );
        },
      });
    }

    result.push({
      accessor: '_id',
      minWidth: 32,
      maxWidth: 32,
      Header: () => <div />,
      Cell: props => {
        return (
          <React.Fragment>
            {/* {cartItem ? (
              <NumberInCart>{`${cartItem.quantity || 0} ks`}</NumberInCart>
            ) : ( */}
            <AddToCardWrapper
              onClick={() =>
                this.props.multiple
                  ? this.handleVariantSelect(props.original._id)
                  : this.props.insertVariants({
                      label: props.original.name,
                      value: props.original._id,
                      product: props.original,
                    })
              }
            >
              {this.props.multiple ? (
                <Checkbox
                  checked={
                    this.state.selectedVariants === 'all' ||
                    this.state.selectedVariants.indexOf(props.original._id) > -1
                  }
                />
              ) : (
                <Icon name="plus" color="#ee6500" />
              )}
            </AddToCardWrapper>
            {/* )} */}
          </React.Fragment>
        );
      },
    });

    return result;
  }

  handleVariantSelect(id) {
    let { selectedVariants } = this.state;

    if (selectedVariants.indexOf(id) > -1) {
      selectedVariants.splice(selectedVariants.indexOf(id), 1);
    } else {
      selectedVariants.push(id);
    }

    this.setState({
      selectedVariants,
    });
  }

  render() {
    const {
      data,
      onVariantSelect,
      selectedVariantIndex,
      loading,
      selectable,
    } = this.props;

    const { displayedComplementarsFor } = this.state;

    const normalizedData = normalizeVariantsAndCheckSelected(
      data,
      displayedComplementarsFor,
    );

    return (
      <MainWrapper>
        <Table
          columns={this.createColumns()}
          minWidth={10}
          loading={loading}
          loadingColor="#ee6500"
          style={{
            border: 'unset',
          }}
          data={normalizedData}
          pageSize={normalizedData.length}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => onVariantSelect(rowInfo.index),
                style: selectable
                  ? {
                      border:
                        rowInfo.index === selectedVariantIndex
                          ? '#ee6500 solid 1px'
                          : rowInfo.index % 2 === 0
                          ? 'white'
                          : '#eee',

                      cursor: 'pointer',
                    }
                  : {},
              };
            }
            return {};
          }}
          getTdProps={() => {
            return { style: { padding: 0, margin: 'auto', border: 0 } };
          }}
          getTheadThProps={(state, rowInfo, column) => {
            return {
              style: { border: 0 },
            };
          }}
          className="-highlight -striped"
          offset={120}
        />
        {this.props.multiple && (
          <ButtonWrapper>
            <Button small primary onClick={() => this.insertVariants()}>
              {__('Vložiť vybrané varianty do kampane')}
            </Button>
          </ButtonWrapper>
        )}
      </MainWrapper>
    );
  }
}

CatalogProductVariants.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      ean: PropTypes.string,
      name: PropTypes.string,
      volume: PropTypes.string,
      on_stock: PropTypes.number,
      price: PropTypes.shape({}),
      complementarsProducts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  onVariantSelect: PropTypes.func,
  addToCart: PropTypes.func,
  hasToningPrice: PropTypes.bool,
  loading: PropTypes.bool,
  variantLoadingId: PropTypes.number,
  selectedVariantIndex: PropTypes.number,
  selectable: PropTypes.bool,
  role: PropTypes.string,
  settings: PropTypes.shape({}),
  insertVariants: PropTypes.func,
};

CatalogProductVariants.defaultProps = {
  data: [],
  onVariantSelect: () => {},
  addToCart: () => {},
  loading: false,
  selectedVariantIndex: -1,
  variantLoadingId: -1,
  selectable: true,
  hasToningPrice: false,
  role: '',
  settings: null,
  insertVariants: () => {},
};

export default CatalogProductVariants;
