import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'name',
    otherProps: { minWidth: 250 },
  },
  {
    name: __('Skupina'),
    clickable: true,
    sorterValue: 'brand',
  },
  {
    name: __('Podskupina'),
    clickable: true,
    sorterValue: 'sub_brand',
  },
  {
    name: __('Preferované poradie'),
    clickable: true,
    sorterValue: 'preferred_order',
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: true,
    sorterValue: 'created_date',
  },
];
