import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { rem } from 'polished';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import { Button, Select, Loader, Message } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import SearchableSelect from '../../Components/SearchableSelect';
import PopUpWrapper from '../../Components/PopUpWrapper';
import QuickSearch from '../../Components/QuickSearch';
import colorTypes from '../../ContentConfig/colorTypes';
// import properties from '../../ContentConfig/properties';
import shades from '../../ContentConfig/shades';
import ConfirmDialog from '../../Components/ConfirmDialog';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import CustomNumberInput from '../../Components/CustomNumberInput/CustomNumberInput';
import API2 from '../../API2';

const CustomSelect = styled(Select)`
  width: 100%;
  margin: ${rem(10)} 0;
  padding: 0;
  min-width: ${rem(200)};
`;

const TableWrapper = styled.div`
  padding: ${rem(20)};
  margin-right: ${rem(10)};
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: ${rem(120)};
`;

const ButtonsArea = styled.div`
  padding: ${rem(20)} ${rem(20)} ${rem(20)} ${rem(0)};
  justify-content: start;
  display: flex;
`;

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  width: 90%;
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(300)};
  padding-top: ${rem(11)};
  margin-right: ${rem(7)};
  margin-left: ${rem(30)};
`;

const SmallTd = styled(Td)`
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};
`;

const SmallTh = styled(Th)`
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};
`;

const ColorPicker = styled.div`
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  height: ${rem(30)};
  width: ${rem(30)};
`;

const Add = styled.div`
  cursor: pointer;
  width: ${rem(24)};
  height: ${rem(24)};
  background: #1c71b7;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: ${rem(2)};
  margin-left: ${rem(2)};
`;

const StyledReactMultiSelectCheckboxes = styled(ReactMultiSelectCheckboxes)`
  z-index: 100;
`;

const FormulasWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(2)};
`;

const Formula = styled.div`
  height: ${rem(20)};
  margin-right: ${rem(2)};
  background: #f39a48;
  color: white;
  font-size: ${rem(12)};
  padding: ${rem(2)} ${rem(4)} ${rem(2)} ${rem(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(2)};
`;

const FormulaDelete = styled.div`
  padding: ${rem(6)};
  font-size: ${rem(12)};
  cursor: pointer;
`;

const PopUpInsideWrapper = styled.div`
  padding: ${rem(12)};
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  color: #808079;
  font-size: 13px;
  padding-bottom: 4px;
`;

class RecipeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      showConfirmDialog: false,
      showSaveDialog: false,
      saveLoading: false,
      colorCardError: false,
      toningSystemError: false,
      saveDisabled: true,
      recipe: {},
      update: false,
      toningSystem: {},
      colourCard: {},
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    const bases = await AdminAPI.getCodelist('base');
    const properties = await AdminAPI.getShadePropertiesAction();
    if (id !== 'new') {
      this.setState({ loading: true });
      const recipe = await this.fetchRecipeData(id);
      if (recipe) {
        const toningSystem = await this.fetchToningSystemData(recipe.system_id);
        const colourCard = await this.fetchColourCardData(recipe.card_id);
        await this.updateRecipe(recipe, toningSystem, colourCard);
      }
    }

    this.setState({
      loading: false,
      bases: bases.codelist || [],
      properties: properties.codelist || [],
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.update !== this.state.update) {
      return false;
    }
    return true;
  }

  updateRecipe(recipe, toningSystem, colourCard) {
    let recipeTemp = recipe;
    if (toningSystem && colourCard) {
      recipeTemp = {
        shades:
          colourCard !== null && colourCard && colourCard.shades
            ? colourCard.shades.map(shade => {
                const recipeShades = recipe.shades.find(
                  s => s._id === shade.color_code,
                );
                if (recipeShades) {
                  return {
                    _id: recipeShades._id,
                    base: recipeShades.base || [],
                    properties: recipeShades.properties || [],
                    shade_of_base: recipeShades.shade_of_base || '',
                    colourants:
                      toningSystem !== null &&
                      toningSystem &&
                      toningSystem.colourants
                        ? toningSystem.colourants.map(system => {
                            const recipeSystem =
                              recipeShades &&
                              recipeShades.colourants.find(
                                c => c._id === system.name_short,
                              );
                            if (recipeSystem) {
                              return {
                                _id: recipeSystem._id,
                                value: recipeSystem.value,
                              };
                            }
                            return {
                              _id: system.name_short,
                              value: 0.0,
                            };
                          })
                        : [],
                    range_of_formulas: recipeShades.range_of_formulas || [],
                  };
                }
                return {
                  _id: shade.color_code,
                  base: [],
                  properties: [],
                  shade_of_base: '',
                  colourants:
                    toningSystem !== null &&
                    toningSystem &&
                    toningSystem.colourants
                      ? toningSystem.colourants.map(system => {
                          return {
                            _id: system.name_short,
                            value: 0,
                          };
                        })
                      : [],
                  range_of_formulas: [],
                };
              })
            : [],
      };

      const recipeNew = recipe;

      recipeNew.shades = recipeTemp.shades;

      this.setState({
        recipe: recipeNew,
        colourCard: {
          value: colourCard._id,
          label: colourCard.name,
          colorCard: colourCard,
        },
        toningSystem: {
          value: toningSystem._id,
          label: toningSystem.name,
          toningSystem,
        },
      });
    } else {
      this.setState({
        recipe: recipeTemp,
      });
    }
  }

  async fetchRecipeData(id) {
    const { firm } = this.props;
    try {
      const recipe = await API2.getFormulaAction(firm, id);
      return recipe;
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie receptúry nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Receptúra sa nenašla'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
    return {};
  }

  async fetchColourCardData(id) {
    const { firm } = this.props;
    try {
      const colorCard = await API2.getColorcardAction(firm, id);
      return colorCard;
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie vzorkovníka nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Vzorkovník sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }

    return {};
  }

  async fetchToningSystemData(id) {
    const { firm } = this.props;
    try {
      const toningSystem = await API2.getToningSystemAction(firm, id);
      return toningSystem;
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __(
              'Na zobrazenie tónovacieho systému nemáte potrebné práva.',
            ),
          });
          break;
        case 404:
          this.setState({
            error: __('Tónovací systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  checkData() {
    const { toningSystem, colourCard } = this.state;
    let { colorCardError, toningSystemError } = this.state;
    let valid = true;

    if (!toningSystem.toningSystem) {
      valid = false;
      toningSystemError = true;
    }
    if (!colourCard.colorCard) {
      valid = false;
      colorCardError = true;
    }
    if (!valid) {
      this.setState({
        colorCardError,
        toningSystemError,
      });
    }

    return valid;
  }

  async saveRecipe() {
    let { recipe } = this.state;
    const { colourCard } = this.state;
    const { history, firm } = this.props;

    recipe.shades.map(shade => {
      return (shade.colourants = shade.colourants.map(color => {
        return {
          _id: color._id,
          value: parseFloat(color.value),
        };
      }));
    });

    this.setState({
      saveLoading: true,
      colorCardError: false,
      toningSystemError: false,
      success: undefined,
      error: undefined,
    });
    if (this.checkData()) {
      try {
        if (recipe._id) {
          const data = {
            shades: recipe.shades,
          };
          await API2.putFormulaAction(firm, recipe._id, { body: data });
        } else {
          const data = {
            card_id: colourCard.colorCard._id,
            system_id: colourCard.colorCard.system_id,
            shades: recipe.shades,
          };
          const result = await API2.postFormulaAction(firm, { body: data });
          if (result && getIdFromProps(this.props) === 'new') {
            history.push(`/${firm}/recipes/${result._id}`);
            recipe = result;
          }
        }

        this.setState({
          saveDisabled: true,
          recipe,
          success: __('Receptúra bola úspešne uložená'),
        });
      } catch (e) {
        this.setState({
          error: __('Pri ukladaní dát sa vyskytla chyba'),
        });
      }
    }
    this.setState({
      saveLoading: false,
    });
  }

  async deleteRecipe() {
    const { recipe } = this.state;
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await API2.deleteFormulaAction(firm, recipe._id);
      history.push(`/${firm}/recipes/`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  showConfirmDialog() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  showSaveDialog() {
    this.setState({
      showSaveDialog: !this.state.showSaveDialog,
    });
  }

  async changeValue(value, field) {
    let { colourCard, toningSystem } = this.state;
    if (field === 'colourCard') {
      colourCard = value;

      this.setState({
        tableLoading: true,
      });

      if (value && value.colorCard.system_id) {
        const result = await API2.getToningSystemAction(
          this.props.firm,
          value.colorCard.system_id,
        );
        toningSystem = {
          value: result._id,
          label: result.name,
          toningSystem: result,
        };

        this.refreshRecipe(colourCard, toningSystem);
      }
    } else if (field === 'toningSystem') {
      toningSystem = value;
    }

    if (value !== null) {
      this.refreshRecipe(colourCard, toningSystem);
    } else if (field === 'colourCard') {
      this.setState({
        colourCard,
        saveDisabled: true,
      });
    } else {
      this.setState({
        toningSystem,
        saveDisabled: true,
      });
    }
  }

  refreshRecipe(colourCard, toningSystem) {
    let { recipe } = this.state;

    recipe = {};

    recipe = {
      shades:
        colourCard !== null &&
        colourCard.colorCard &&
        colourCard.colorCard.shades
          ? colourCard.colorCard.shades.map(shade => {
              return {
                _id: shade.color_code,
                base: shade.basecodes || [],
                properties: [],
                shade_of_base: '',
                colourants:
                  toningSystem !== null &&
                  toningSystem.toningSystem &&
                  toningSystem.toningSystem.colourants
                    ? toningSystem.toningSystem.colourants.map(system => {
                        return {
                          _id: system.name_short,
                          value: 0,
                        };
                      })
                    : [],
                range_of_formulas: [],
              };
            })
          : [],
    };

    this.setState({
      recipe,
      toningSystem,
      colourCard,
      tableLoading: false,
      saveDisabled: !(colourCard && toningSystem),
    });
  }

  tableChangeValues(shadeId, colourantId, value) {
    const { recipe } = this.state;
    recipe.shades
      .find(r => r._id === shadeId)
      .colourants.find(c => c._id === colourantId).value = value;

    if (this.state.saveDisabled) {
      this.setState({
        saveDisabled: false,
      });
      this.setState({ recipe, saveDisabled: false });
    } else {
      this.setState({ recipe, update: !this.state.update });
    }
  }

  findColorCard(query) {
    try {
      return API2.getColorcardsAction(this.props.firm, { q: query }).then(
        res => {
          return res.items.map(item => ({
            value: item._id,
            label: item.name,
            colorCard: item,
          }));
        },
      );
    } catch (e) {
      return [];
    }
  }

  findToningSystem(query) {
    try {
      return API2.getToningSystemsAction(this.props.firm, { q: query }).then(
        res => {
          return res.systems.map(item => ({
            value: item._id,
            label: item.name,
            toningSystem: item,
          }));
        },
      );
    } catch (e) {
      return [];
    }
  }

  selectHandler(type, value, colorId) {
    const { recipe } = this.state;
    if (type === 'base') {
      recipe.shades.find(s => s._id === colorId).base = value.map(v => {
        return v.value;
      });
    } else if (type === 'props') {
      recipe.shades.find(s => s._id === colorId).properties = value.map(v => {
        return v.value;
      });
    } else if (type === 'shade') {
      recipe.shades.find(s => s._id === colorId).shade_of_base =
        value.target.value;
    }

    this.setState({
      recipe,
      saveDisabled: false,
    });
  }

  showAddPopup(id = null, open = true) {
    this.setState({
      showAdd: open,
      formulasTempId: id,
    });
  }

  async addFormulas(text) {
    const { recipe, formulasTempId } = this.state;

    recipe.shades
      .find(r => r._id === formulasTempId)
      .range_of_formulas.push(text);

    this.setState({
      recipe,
      showAdd: false,
      saveDisabled: false,
    });

    try {
      await API2.putShadeGroups({ data: { shade_group: text } });
    } catch (e) {
      console.log(e);
    }
  }

  searchForFormulas(query) {
    try {
      return API2.getShadeGroups({ q: query, limit: 5 }).then(res => {
        return res.items;
      });
    } catch (e) {
      return [];
    }
  }

  deleteFormula(id, formula) {
    const { recipe } = this.state;

    const indexOfFormula = recipe.shades
      .find(r => r._id === id)
      .range_of_formulas.indexOf(formula);

    recipe.shades
      .find(r => r._id === id)
      .range_of_formulas.splice(indexOfFormula, 1);

    this.setState({
      recipe,
      saveDisabled: false,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Receptúra')}>
          <ControlBarButton
            small
            danger
            onClick={() => this.showConfirmDialog()}
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      deleteLoading,
      showConfirmDialog,
      error,
      success,
      colourCard,
      toningSystem,
      showSaveDialog,
      saveLoading,
      saveDisabled,
      recipe,
      colorCardError,
      deleteError,
      toningSystemError,
      loading,
      showAdd,
      bases,
      properties,
      tableLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        {this.renderControlBar()}
        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.showConfirmDialog()}
        >
          <ConfirmDialog
            message={__('Prajete si odstániť receptúru ?')}
            onDismiss={() => this.showConfirmDialog()}
            onConfirm={() => this.deleteRecipe()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showSaveDialog}
          small
          onClose={() => this.showSaveDialog()}
        >
          <ConfirmDialog
            message={__('Prajete si uložiť zmeny ?')}
            onDismiss={() => this.showSaveDialog()}
            onConfirm={() => this.saveRecipe()}
            error={deleteError}
            loading={saveLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showAdd}
          small
          message={__('Pridať')}
          onClose={() => this.showAddPopup(null, false)}
        >
          <PopUpInsideWrapper>
            <QuickSearch
              placeholder={__('Zvoľte kategóriu')}
              submit={text => this.addFormulas(text)}
              search={query => this.searchForFormulas(query)}
            />
          </PopUpInsideWrapper>
        </PopUpWrapper>

        <BasicInfoWrapper>
          <SearchableSelectWrapper>
            <Label>{__('Vzorkovník')}</Label>
            <SearchableSelect
              value={colourCard !== null && colourCard.colorCard && colourCard}
              loadOptions={query => this.findColorCard(query)}
              placeholder={__('Vyhľadať vzorkovník')}
              error={colorCardError}
              name={colourCard !== null && colourCard.colorCard}
              disabled={getIdFromProps(this.props) !== 'new'}
              handleOnChange={e => this.changeValue(e, 'colourCard')}
            />
          </SearchableSelectWrapper>

          <SearchableSelectWrapper>
            <Label>{__('Tónovací systém')}</Label>
            <SearchableSelect
              value={
                toningSystem !== null &&
                toningSystem.toningSystem &&
                toningSystem
              }
              disabled
              loadOptions={query => this.findToningSystem(query)}
              placeholder={__('Vyhľadať tónovací systém')}
              name={toningSystem != null && toningSystem.toningSystem}
              error={toningSystemError}
              handleOnChange={e => this.changeValue(e, 'toningSystem')}
            />
          </SearchableSelectWrapper>
        </BasicInfoWrapper>
        {tableLoading ? (
          <LoaderWrapper>
            <Loader size="l" />
          </LoaderWrapper>
        ) : (
          <TableWrapper>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('ID / odtieň')}</Th>
                  <Th>{__('RGB')}</Th>
                  <SmallTh />
                  <Th>{__('Báza')}</Th>
                  <Th>{__('Vlastnosti')}</Th>
                  <Th>{__('Farebná skupina')}</Th>
                  <Th>{__('Odtieň základu')}</Th>
                  {toningSystem !== null &&
                    toningSystem.toningSystem &&
                    toningSystem.toningSystem.colourants.map(system => {
                      return <SmallTh>{system.name_short}</SmallTh>;
                    })}
                </Tr>
              </Thead>
              <Tbody>
                {colourCard !== null &&
                  colourCard.colorCard &&
                  colourCard.colorCard.shades.map(color => {
                    const recipeShades = recipe.shades.find(
                      s => s._id === color.color_code,
                    );
                    const result = [];
                    result.push(
                      <Tr style={{ borderTop: 'solid 1px #ee6500' }}>
                        <Td style={{ padding: '2px' }}>{`${
                          color.color_code
                        } ${`${color.color_name &&
                          `(${color.color_name})`} `}`}</Td>
                        <Td style={{ padding: '2px' }}>{`${color.color &&
                          color.color.R} ${color.color &&
                          color.color.G} ${color.color && color.color.B}`}</Td>
                        <SmallTd style={{ padding: '2px' }}>
                          <ColorPicker
                            style={{
                              backgroundColor: `rgba(${color.color &&
                                color.color.R},${color.color &&
                                color.color.G},${color.color &&
                                color.color.B},1)`,
                            }}
                          />
                        </SmallTd>
                        <Td style={{ padding: '2px' }}>
                          <StyledReactMultiSelectCheckboxes
                            options={bases}
                            value={
                              recipeShades &&
                              recipeShades.base.map(base => {
                                return {
                                  value: base,
                                  label: base,
                                };
                              })
                            }
                            placeholderButtonLabel={__('Báza')}
                            onChange={e =>
                              this.selectHandler('base', e, color.color_code)
                            }
                          />
                        </Td>
                        <Td style={{ padding: '2px' }}>
                          <StyledReactMultiSelectCheckboxes
                            options={properties}
                            value={
                              recipeShades &&
                              recipeShades.properties.map(prop => {
                                return {
                                  value: prop,
                                  label: prop,
                                };
                              })
                            }
                            placeholderButtonLabel={__('Vlastnosti')}
                            onChange={e =>
                              this.selectHandler('props', e, color.color_code)
                            }
                          />
                        </Td>
                        <Td>
                          {colorTypes.find(
                            c => c.value === color.color_group,
                          ) &&
                            colorTypes.find(c => c.value === color.color_group)
                              .display_name}
                        </Td>
                        <Td>
                          <CustomSelect
                            name={__('Odtieň')}
                            size="s"
                            value={recipeShades && recipeShades.shape_of_base}
                            onChange={e => {
                              this.selectHandler('shade', e, color.color_code);
                            }}
                          >
                            <option key="" value="">
                              {' - '}
                            </option>
                            {shades.map(s => {
                              return (
                                <option
                                  key={s.key}
                                  value={s.value}
                                  selected={
                                    s.value === recipeShades.shade_of_base
                                      ? recipeShades.shade_of_base
                                      : ''
                                  }
                                >
                                  {s.display_name}
                                </option>
                              );
                            })}
                          </CustomSelect>
                        </Td>
                        {toningSystem !== null &&
                          toningSystem.toningSystem &&
                          toningSystem.toningSystem.colourants.map(system => {
                            const recipeSystem =
                              recipeShades &&
                              recipeShades.colourants.find(
                                c => c._id === system.name_short,
                              );
                            return (
                              <SmallTd>
                                <ValueInputWrapper>
                                  {/* <StyledInputSmall */}
                                  <CustomNumberInput
                                    width="100%"
                                    type="number"
                                    value={recipeSystem && recipeSystem.value}
                                    onChange={value => {
                                      this.tableChangeValues(
                                        recipeShades._id,
                                        recipeSystem._id,
                                        value,
                                      );
                                    }}
                                  />
                                </ValueInputWrapper>
                              </SmallTd>
                            );
                          })}
                      </Tr>,
                    );
                    result.push(
                      <Tr style={{ borderBottom: '0' }}>
                        <FormulasWrapper>
                          {recipeShades.range_of_formulas &&
                            recipeShades.range_of_formulas.map(formula => {
                              return (
                                <Formula>
                                  {formula}
                                  <FormulaDelete
                                    onClick={() =>
                                      this.deleteFormula(
                                        recipeShades._id,
                                        formula,
                                      )
                                    }
                                  >
                                    x
                                  </FormulaDelete>
                                </Formula>
                              );
                            })}
                          <Add
                            size="s"
                            small
                            onClick={() => this.showAddPopup(recipeShades._id)}
                          >
                            +
                          </Add>
                        </FormulasWrapper>
                      </Tr>,
                    );

                    return result;
                  })}
              </Tbody>
            </Table>
            <ButtonsArea>
              <Button
                primary
                loading={saveLoading}
                disabled={saveDisabled}
                onClick={() => this.saveRecipe()}
              >
                {__('Uložiť')}
              </Button>
            </ButtonsArea>
          </TableWrapper>
        )}
      </React.Fragment>
    );
  }
}

RecipeDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RecipeDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(RecipeDetail);
