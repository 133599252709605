import React from 'react';
import { Route } from 'react-router-dom';
import EshopPaymentMethodsSettings from '../../Containers/EshopPaymentsMethodsSettings';
import EshopDeliveryMethodsSettings from '../../Containers/EshopDeliveryMethodsSettings';
import EshopShopsCard from './EshopShopsCard';
import EshopBannersCard from './EshopBannersCard';
import SalesCard from './SalesCard';
import DeliveryTermsCard from './DeliveryTermsCard';
import EshopNotificationsCard from './EshopNotificationsCard';

function EshopSettings(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route
        path={`${match.url}/payment-methods`}
        component={EshopPaymentMethodsSettings}
      />
      <Route
        path={`${match.url}/delivery-methods`}
        component={EshopDeliveryMethodsSettings}
      />
      <Route
        path={`${match.url}/eshop-notifications`}
        component={EshopNotificationsCard}
      />
      <Route path={`${match.url}/eshop-shops`} component={EshopShopsCard} />
      <Route
        path={`${match.url}/delivery-terms`}
        component={DeliveryTermsCard}
      />
      <Route path={`${match.url}/sales`} component={SalesCard} />
      <Route path={`${match.url}/banners`} component={EshopBannersCard} />
    </React.Fragment>
  );
}

export default EshopSettings;
