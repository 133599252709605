import React from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from 'styled-components';
// import 'react-pdf/dist/Page/AnnotationLayer.css';
import { rem } from 'polished';
import PageNavigator from 'oxyrion-ui/lib/PageNavigator';
import { isNumber } from 'lodash';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
`;

const StyledPageNavigator = styled.div`
  border-top-left-radius: ${rem(10)};
  border-top-right-radius: ${rem(10)};
  position: fixed;
  bottom: 0;
  background: white;
  padding: ${rem(10)};
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
`;

const Text = styled.div`
  font-weight: 200;
  font-size: ${rem(10)};
  padding: ${rem(5)};
`;

export default class FileViewDownload extends React.Component {
  constructor() {
    super();
    this.state = {
      numPages: null,
      pageNumber: 1,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onPageClick(page) {
    if (isNumber(page)) {
      this.setState({ pageNumber: page });
    }
  }

  onDecrease() {
    const { pageNumber } = this.state;
    this.setState({ pageNumber: pageNumber - 1 });
  }

  onIncrease() {
    const { pageNumber } = this.state;
    this.setState({ pageNumber: pageNumber + 1 });
  }

  render() {
    const { numPages, pageNumber } = this.state;
    const { fileUrl, name } = this.props.location.state;

    return (
      <Wrapper>
        <Text>{name}</Text>
        <Document file={fileUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={1000} />
        </Document>
        {numPages && (
          <StyledPageNavigator>
            <PageNavigator
              activePage={pageNumber}
              end={numPages}
              onPageClick={page => this.onPageClick(page)}
              onDecrease={() => this.onDecrease()}
              onIncrease={() => this.onIncrease()}
            />
          </StyledPageNavigator>
        )}
      </Wrapper>
    );
  }
}
