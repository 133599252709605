import { __ } from '../Utils';

export default [
  {
    name: __('Product No'),
    clickable: false,
    otherProps: { maxWidth: 120 },
  },
  {
    name: __('Názov'),
    clickable: false,
  },
  { name: __('Názov SK'), clickable: false },
  { name: __('Názov CZ'), clickable: false },
  { name: __('Názov AT'), clickable: false },
  {
    name: __('Skladba názvu podľa heureky'),
    clickable: false,
  },
];
