import { __ } from '../Utils';

export default {
  userItems: [
    {
      name: __('Nastavenia'),
      href: '/settings',
    },
  ],
  firm: ['SK', 'CZ'],
  flags: {
    CZ: '/images/flags/cz_flag.svg',
    SK: '/images/flags/sk_flag.svg',
  },
};
